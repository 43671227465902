
export const userKycValidation = (propsData) => {
  const validate = 
  propsData?.individualUser?.genderId === null ||
  propsData?.individualUser?.maritalStatus === null ||
  propsData?.individualUser?.maritalStatus === "" ||
  propsData?.phone === null ||
  propsData?.phone === "" ||
  propsData?.individualUser?.contactAddress?.secondaryPhoneNumber === null ||
  propsData?.individualUser?.contactAddress?.secondaryPhoneNumber === "" ||
  propsData?.individualUser?.bvn === null ||
  propsData?.individualUser?.bvn === "" ||
  propsData?.individualUser?.contactAddress?.homeAddress === null ||
  propsData?.individualUser?.individualUser?.contactAddress === "" ||
  propsData?.individualUser?.contactAddress?.country === "" ||
  propsData?.individualUser?.contactAddress?.streetAddress === null ||
  propsData?.individualUser?.contactAddress?.state === null ||
  propsData?.individualUser?.contactAddress?.city === null ||
  propsData?.individualUser?.contactAddress?.city === "" ||
  propsData?.individualUser?.contactAddress?.nationality === null ||
  propsData?.individualUser?.contactAddress?.nationality === "" ||
  ((propsData?.userType==="Salary Earner (Owns a Side Business)" ||
  propsData?.userType==="Salary Earner (With No Side Business)") ?
  propsData?.individualUser?.employmentDetail?.employerName === null ||
  propsData?.individualUser?.employmentDetail?.employerName === "" ||
  propsData?.individualUser?.employmentDetail?.sector === null ||
  (propsData?.individualUser?.employmentDetail.sector==="PRIVATE_SECTOR" &&
  (propsData?.individualUser?.employmentDetail?.employmentId==="" ||
  propsData?.individualUser?.employmentDetail?.employmentId===null)) ||
  // (propsData?.individualUser?.employmentDetail.sector==="PUBLIC_SECTOR" &&
  // (propsData?.individualUser?.employmentDetail?.payrollHandler==="" ||
  // propsData?.individualUser?.employmentDetail?.payrollHandler===null)) ||
  // (propsData?.individualUser?.employmentDetail.payrollHandler==="Others" ?
  // (propsData?.individualUser?.employmentDetail?.payrollHandlerDetails==="" ||
  // propsData?.individualUser?.employmentDetail?.payrollHandlerDetails===null):false) ||
  // propsData?.individualUser?.employmentDetail?.industry==="" ||
  // propsData?.individualUser?.occupation?.businessStatus===null ||
  (propsData?.individualUser?.employmentDetail.sector==="PUBLIC_SECTOR" ?
  (propsData?.individualUser?.employmentDetail?.payrollHandler===null &&
  propsData?.individualUser?.employmentDetail?.payrollHandlerDetails==="")
  : false) ||
  (propsData?.individualUser?.occupation?.occupation==="" &&
  propsData?.individualUser?.occupation?.occupationDetails==="") ||
  (propsData?.individualUser?.employmentDetail?.erpSectorId===null) ||
  (propsData?.userType==="Salary Earner (Owns a Side Business)" ?
  propsData?.individualUser?.occupation?.businessStatus===null : false) : 
  (propsData?.userType==="Student" ||
  propsData?.userType === "Student (Post-secondary)") ? 
  propsData?.individualUser?.occupation?.schoolName === null ||
  propsData?.individualUser?.occupation?.schoolAddress === null ||
  propsData?.individualUser?.occupation?.city === null ||
  propsData?.individualUser?.occupation?.state === null : false) 
  
  console.log("valid", propsData);

  // console.log("kkkjj", validate);
  return validate

}

