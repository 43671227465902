import { Paper, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Buttons from '../../components/Buttons/index'
import React from 'react';
import Spinner from "../Spinner";
import useScreenSize from '../ScreenSize';


export default function FooterCheckout({
    returnBackButton,
    proceedInfoButton,
    route,
    width,
    backroute,
    disabled,
    isLoading,
    handleClose,
}) {

    const functionPass = () => {
        return;
    };
    const { width:screenWidth } = useScreenSize();
    return (
        <div>
            <Paper elevation={2} sx={{
                height: { xs: '15%', lg: '10%' },
                // width: { xs: '92%', sm: '100%', lg: '87%' },
                width: { width },
                margin: '1rem',
                maxwidth: '100%'

            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: {xs: "center",sm: "center",md: 'flex-end',lg: "flex-end"},
                    flexWrap: 'wrap',
                    marginLeft: { xs: 'auto', sm: '2rem', md: 'auto', lg: 'auto' },
                    padding: '1rem',
                    maxWidth: '100%'
                }}>
                    {/* <Link to={backroute ? route : "/"}>
                        <Button  sx={{
                            background: '#F2F2F2',
                            width: '328px',
                            height: '54px',
                            color: '#111E6C',
                            marginBottom: '1rem'
                        }}>
                            {returnBackButton}
                        </Button>

                    </Link> */}

                    <Link to={route ? route : '#'}>
                        <Buttons
                            color="primary"
                            width={screenWidth>700?'328px':'280px'}
                            disabled={disabled}
                            onClick={handleClose ? () => handleClose() : () => functionPass()}
                        >
                            {isLoading ? <Spinner /> : proceedInfoButton}
                        </Buttons>

                    </Link>





                </Box>
            </Paper>
        </div>
    )
}
