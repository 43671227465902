import React, { useState, useEffect } from "react";
import { Section, textheading, container } from "./style";
import ProfileLayout from "../../../../../components/ProfileLayout";
import BlockSpacer from "../../../../../components/BlockSpacer";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import FormLabel from "../../../../../components/Forms/Label";
import CorperateCompanyForm from "../../../../../components/Forms/Profile/CorperateCompanyForms/ContactPersonalForm";
import { Box, Typography, Grid, TextField, IconButton } from "@mui/material";
import SelectField from "../../../../../components/Core/SelectField";
import FooterCheckout from "../../../../../components/FooterCheckoutNoLink";
import { COMPANY_STATUS } from "../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  getUsers,
  getCountries,
  sendCompanyOtp,
  closeOtpModal,
  validateOtp,
  updateUserKyc,
  getKycDetails,
  getStates,
  getCompanyInclusion,
  getErpSectors,
} from "../../../../../redux/slices/profileSlice";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import Buttons from "../../../../../components/Buttons";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import { toast } from "react-toastify";

function CompanyInfo() {
  const dispatch = useDispatch();
  const [currentEdit, setCurrentEdit] = useState();
  const [showEdit, setShowEdit] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [userkycerror, setUserkycerror] = useState("");
  const [status, setStatus] = useState();
  const { width } = useScreenSize();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getKycDetails());
    dispatch(getStates(1));
    dispatch(getCompanyInclusion());
    dispatch(getErpSectors());
  }, []);

  const {
    users,
    kycData,
    showEmailOtpModal,
    states,
    otp,
    validateEmailOtp,
    companyIncl,
  } = useSelector((state) => state.profile);

  const compInclValues = companyIncl?.content[0]?.companyInclMainCategories;

  const addEdit = (id) => {
    console.log("add");
    if (!showEdit.includes(id)) {
      setShowEdit((current) => [...current, id]);
    }
  };

  const removeEdit = (id) => {
    console.log("remove");
    if (showEdit.includes(id)) {
      setShowEdit(showEdit?.filter((item) => item !== id));
    }
  };

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleSendOtp = () => {
    dispatch(sendCompanyOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      addEdit(currentEdit);
    }
  }, [validateEmailOtp]);

  const [formData, setFormData] = useState({
    isAssited: false,
    isNewsLetters: false,
    phone: "",
    userType: "",
    role: "COMPANY",
    usage: "CREDIT",
    status: "ACTIVE",
    isKyc: true,
    company: {
      businessType: null,
      companyAddress: null,
      contactFirstName: null,
      contactLastName: null,
      contactMiddleName: null,
      dateOfInco: null,
      name: null,
      natureOfBusiness: null,
      rcNumber: null,
    },
    businessDetails: {
      address: null,
      businessType: null,
      city: null,
      countryId: 1,
      dateOfRegisteration: null,
      lgaId: null,
      name: null,
      registrationNumber: null,
      stateId: null,
    },
    contactPersonDetails: {
      address: null,
      annualTurnover: null,
      boardMemberGovernance: null,
      businessStatus: null,
      companySize: 0,
      countryId: 1,
      customerId: null,
      email: null,
      firstName: null,
      lastName: null,
      lgaId: null,
      middleName: null,
      operationStructure: null,
      ownershipStructure: null,
      phone: null,
      stateId: null,
    },
  });

  useEffect(() => {
    setFormData({
      ...formData,
      isAssited: false,
      isNewsLetters: false,
      phone: users?.phone,
      userType: users?.userType,
      role: users?.role,
      usage: users?.usage,
      status: users?.status,
      isKyc: true,
      company: {
        ...formData.company,
        companyAddress: users?.company?.companyAddress,
        contactFirstName: users?.company?.contactFirstName,
        contactLastName: users?.company?.contactLastName,
        contactMiddleName: users?.company?.contactMiddleName,
        dateOfInco: users?.company?.dateOfInco,
        name: users?.company?.name,
        natureOfBusiness: users?.company?.natureOfBusiness,
        rcNumber: users?.company?.rcNumber,
      },
      businessDetails: {
        ...formData.businessDetails,
        // address: null,
        city: users?.company?.city,
        countryId: 1,
        // dateOfRegisteration: null,
        // lgaId: null,
        name: users?.company?.name,
        registrationNumber: null,
        stateId: states?.find(
          (item) => item?.name === users?.company?.companyState
        )?.id,
      },
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        annualTurnover: users?.company?.annualTurnover,
        businessStatus: users?.company?.businessStatus,
        companySize: users?.company?.companySize,
        countryId: 1,
        customerId: users?.customerId,
        email: users?.company?.contactPersonEmail,
        firstName: users?.company?.contactFirstName,
        lastName: users?.company?.contactLastName,
        phone: users?.company?.contactPersonPhoneNo,
        contactPersonPhoneNo: users?.company?.contactPersonPhoneNo,
        middleName: users?.company?.contactMiddleName,
        contactPersonAddress: users?.company?.contactPersonAddress,
        contactPersonBvn: users?.company?.contactPersonBvn,
        contactPersonCity: users?.company?.contactPersonCity,
        contactPersonStateId: users?.company?.contactPersonState?.id,
        operationStructure: users?.company?.operationStructure,
        ownershipStructure: users?.company?.ownershipStructure,
        stateId: states?.find(
          (item) => item?.name === users?.company?.companyState
        )?.id,
      },
    });
  }, [users]);

  const [defaultState, setDefaultState] = useState(
    users?.company?.companyState
  );

  const statesOptions = states ? states?.map((item) => item?.name) : [];

  useEffect(() => {
    setDefaultState(users?.company?.companyState);
  }, [users]);

  const handleStateChange = (event) => {
    setDefaultState(event.target.value);
    setFormData((prev) => ({
      ...prev,
      businessDetails: {
        ...prev.businessDetails,
        stateId: states?.find((item) => item?.name === event.target.value)?.id,
      },
    }));
  };

  const handleUserCompanyInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      company: {
        ...formData.company,
        [name]: value,
      },
    });
  };

  const handleUserContactInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        [name]: value,
      },
    });
  };

  const handleBusinessInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      businessDetails: {
        ...formData.businessDetails,
        [name]: value,
      },
    });
  };

  const boardMemGovList =
    compInclValues?.find((item) => item?.title === "BOARD_MEMBER_GOVERNANCE")
      ?.criteriaList || [];
  const boardMemGovOptions =
    boardMemGovList?.map((item) => item?.criteriaName) || [];
  // handle board member governance changes
  const handleBoardMemGovChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        boardMemberGovernance: value,
      },
    });
  };

  const busOpStructList =
    compInclValues?.find(
      (item) => item?.title === "BUSINESS_OPERATION_STRUCTURE"
    )?.criteriaList || [];
  const busOpStructOptions =
    busOpStructList?.map((item) => item?.criteriaName) || [];
  // handle business operation structure
  const handleBusOpStructureChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        operationStructure: value,
      },
    });
  };

  const compSizeList =
    compInclValues?.find((item) => item?.title === "COMPANY_SIZE")
      ?.criteriaList || [];
  const compSizeOptions = compSizeList?.map((item) => item?.criteriaName) || [];
  // handle company size
  const handleCompSizeChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        companySize: compSizeList?.find((item) => item?.criteriaName === value)
          ?.weight,
      },
    });
  };
  // const alertUser = (e) => {
  //   e.preventDefault();
  //   alert("Are you sure you leaving?")
  // }
  useEffect(() => {
    const handler = (event) => {
      event.returnValue = "ss";
    };

    if (showEdit.length > 0) {
      window.addEventListener("beforeunload", (e) => handler(e));
      window.addEventListener("popstate", (e) => handler(e));
      return () => {
        window.removeEventListener("beforeunload", (e) => handler(e));
        window.removeEventListener("popstate", (e) => handler(e));
      };
    }
  }, []);

  const annualTurnoverList =
    compInclValues?.find((item) => item?.title === "ANNUAL_TURNOVER")
      ?.criteriaList || [];
  const annualTurnoverOptions =
    annualTurnoverList?.map((item) => item?.criteriaName) || [];
  // handle annual turnover changes
  const handleAnnualTurnoverChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        annualTurnover: value,
      },
    });
  };

  const compOwnStructList =
    compInclValues?.find(
      (item) => item?.title === "COMPANY_OWNERSHIP_STRUCTURE"
    )?.criteriaList || [];
  const compOwnStructOptions =
    compOwnStructList?.map((item) => item?.criteriaName) || [];
  // handle annual turnover changes
  const handleCompOwnStructChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contactPersonDetails: {
        ...formData.contactPersonDetails,
        ownershipStructure: value,
      },
    });
  };

  const busStatusList =
    compInclValues?.find((item) => item?.title === "COMPANY_STATUS")
      ?.criteriaList || [];
  const busStatusOptions =
    busStatusList?.map((item) => item?.criteriaName) || [];
  // handle business status changes
  const handleStatusChange = (event) => {
    const { value } = event.target;
    setStatus(value);
    setFormData((prev) => ({
      ...prev,
      contactPersonDetails: {
        ...prev.contactPersonDetails,
        businessStatus: event.target.value,
      },
    }));
  };

  const SubmutKyc = async () => {
    // let userType;
    // if(users?.userType==="SOLE_PROPRIETOR_REGISTERED" ||
    // users?.userType==="SOLE_PROPRIETOR_UNREGISTERED") {
    //   userType="SOLE_PROPRIETORSHIP"
    // } else if(users?.userType==="CORPORATE_LISTED" ||
    // users?.userType==="CORPORATE_UNLISTED") {
    //   userType="CORPORATE_LIMITED"
    // }

    const submitData = {
      ...formData,
      company: {
        ...formData.company,
        // businessType: users?.userType
      },
    };
    setLoading(true);
    toast.info("Saving...");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.put(
        `https://api-credit.optisoft.com.ng:8090/auth/users`,
        submitData,
        config
      );
      console.log(res);
      toast.success("Company details updated");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error(err?.response?.data?.message);
      setUserkycerror(err?.response?.data?.message);
    }
  };

  const submitHandler = () => {
    SubmutKyc();
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <ProfileLayout>
            <Box sx={container}>
              <Box
                sx={{
                  display: width > 700 ? "none" : "flex",
                  mb: 3,
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Grid item xs={2} sm={2}>
                  <Link to="/profile">
                    <ArrowBackIosNew />
                  </Link>
                </Grid>
                <Grid item xs={10} sm={10}>
                  <Typography sx={textheading}>Company Info</Typography>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={textheading}>Company Details</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Box>
                    {!showEdit.includes(1) ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                          sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}
                        >
                          <Buttons
                            color="primary"
                            width={width <= 700 ? "60px" : "72px"}
                            fontSize={width <= 700 && "14px"}
                            onClick={() => {
                              handleSendOtp();
                              setCurrentEdit(1);
                            }}
                          >
                            Edit
                          </Buttons>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                          sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}
                        >
                          <Buttons
                            color="tertiary"
                            width={width <= 700 ? "60px" : "72px"}
                            fontSize={width <= 700 && "14px"}
                            onClick={() => {
                              removeEdit(1);
                              submitHandler();
                            }}
                          >
                            Save
                          </Buttons>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Box>
              <BlockSpacer mt={50} />
              {/* First Grid */}
              <Grid container sx={{ alignItems: "flex-end" }} spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Business / Company Name"} />
                    <TextField
                      placeholder="ekiyee"
                      type="text"
                      required
                      InputProps={{
                        readOnly: true,
                      }}
                      value={formData?.company?.name}
                      disabled={!showEdit.includes(1)}
                      name="name"
                      onChange={handleUserCompanyInput}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Registration Number"} />
                    <TextField
                      placeholder="bilaowei"
                      type="text"
                      required
                      disabled={!showEdit.includes(1)}
                      value={formData?.company?.rcNumber}
                      // name="registrationNumber"
                      name="rcNumber"
                      onChange={handleUserCompanyInput}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Registration Date"} />
                    <TextField
                      placeholder="bilaowei"
                      type="date"
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                      value={moment(
                        formData?.company?.dateOfInco,
                        "DD-MM-YYYY"
                      ).format("YYYY-MM-DD")}
                      // name="dateOfRegistration"
                      name="dateOfInco"
                      onChange={handleUserCompanyInput}
                    />
                  </FormAligner>
                </Grid>
              </Grid>

              <BlockSpacer mt={40} />
              {/* second Grid */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Customer ID Number"} />
                    <TextField
                      placeholder=""
                      type="text"
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      value={users?.customerId}
                      name="customerId"
                      onChange={handleUserContactInput}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Business Type"} />
                    <TextField
                      placeholder="(+234)7088957111"
                      type="text"
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                      value={formData?.userType}
                      name="businessType"
                      onChange={handleUserCompanyInput}
                    />
                  </FormAligner>
                </Grid>
                {(users?.userType === "CORPORATE_LISTED" ||
                  users?.userType === "CORPORATE_UNLISTED") && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SelectField
                      name={"Company Status"}
                      disabled={!showEdit.includes(1)}
                      value={formData?.contactPersonDetails?.businessStatus}
                      items={busStatusOptions}
                      method={formData?.contactPersonDetails?.businessStatus}
                      changeAction={handleStatusChange}
                    />
                  </Grid>
                )}
              </Grid>
              {/* third Grid */}
              {(users?.userType === "CORPORATE_LISTED" ||
                users?.userType === "CORPORATE_UNLISTED") && (
                <>
                  <BlockSpacer mt={40} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormAligner>
                        <SelectField
                          name={"Company Ownership Structure"}
                          value={
                            formData.contactPersonDetails.ownershipStructure
                          }
                          items={compOwnStructOptions}
                          disabled={!showEdit.includes(1)}
                          method={
                            formData.contactPersonDetails.ownershipStructure
                          }
                          // styles={customStyles}
                          changeAction={handleCompOwnStructChange}
                        />
                      </FormAligner>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormAligner>
                        <SelectField
                          name={"Company Size"}
                          value={
                            compSizeList?.find(
                              (item) =>
                                item?.weight ===
                                formData?.contactPersonDetails?.companySize
                            )?.criteriaName
                          }
                          items={compSizeOptions}
                          disabled={!showEdit.includes(1)}
                          method={
                            compSizeList?.find(
                              (item) =>
                                item?.weight ===
                                formData?.contactPersonDetails?.companySize
                            )?.criteriaName
                          }
                          changeAction={handleCompSizeChange}
                        />
                      </FormAligner>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormAligner>
                        <SelectField
                          name={"Business Operation Structure"}
                          value={
                            formData?.contactPersonDetails?.operationStructure
                          }
                          items={busOpStructOptions}
                          disabled={!showEdit.includes(1)}
                          method={
                            formData?.contactPersonDetails?.operationStructure
                          }
                          changeAction={handleBusOpStructureChange}
                        />
                      </FormAligner>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* fourth Grid */}

              <BlockSpacer mt={40} />
              <Grid container spacing={2}>
                {(users?.userType === "CORPORATE_LISTED" ||
                  users?.userType === "CORPORATE_UNLISTED") && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormAligner>
                        <SelectField
                          name={"Board Member Governance"}
                          value={
                            formData?.contactPersonDetails
                              ?.boardMemberGovernance
                          }
                          items={boardMemGovOptions}
                          method={
                            formData?.contactPersonDetails
                              ?.boardMemberGovernance
                          }
                          disabled={!showEdit.includes(1)}
                          changeAction={handleBoardMemGovChange}
                        />
                      </FormAligner>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormAligner>
                        <SelectField
                          name={"Annual Turnover"}
                          value={formData?.contactPersonDetails?.annualTurnover}
                          items={annualTurnoverOptions}
                          disabled={!showEdit.includes(1)}
                          method={
                            formData?.contactPersonDetails?.annualTurnover
                          }
                          changeAction={handleAnnualTurnoverChange}
                        />
                      </FormAligner>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Residing Country"} />
                    <TextField
                      placeholder=""
                      type="text"
                      value={"Nigeria"}
                      required
                      disabled
                      name="country"
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"City"} />
                    <TextField
                      placeholder="City"
                      type="text"
                      required
                      disabled={!showEdit.includes(1)}
                      value={formData?.businessDetails?.city}
                      name="city"
                      onChange={handleBusinessInput}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <SelectField
                      name={"State"}
                      method={defaultState}
                      changeAction={handleStateChange}
                      placeholder="Select State"
                      disabled={!showEdit.includes(1)}
                      items={statesOptions}
                      asterisk
                    />
                  </FormAligner>
                </Grid>
              </Grid>

              <BlockSpacer mt={30} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormAligner>
                    <FormLabel text={"Company Address"} />
                    <TextField
                      placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                      type="text"
                      value={formData?.company?.companyAddress}
                      required
                      disabled={!showEdit.includes(1)}
                      name="companyAddress"
                      onChange={handleUserCompanyInput}
                    />
                  </FormAligner>
                </Grid>
              </Grid>

              <BlockSpacer mt={150} />
              <CorperateCompanyForm
                handleSendOtp={() => handleSendOtp()}
                setCurrentEdit={(id) => setCurrentEdit(id)}
                showEdit={showEdit}
                submitHandler={submitHandler}
                removeEdit={(id) => removeEdit(id)}
                formData={formData}
                setFormData={setFormData}
              />
            </Box>

            <BlockSpacer mt={150} />
            {/* <FooterCheckout 
              proceedInfoButton={"Save"} 
              handleClose={() => submitHandler()}
              disabled={showEdit.length>0}
              isLoading={loading}
            /> */}
          </ProfileLayout>
          {showEmailOtpModal && (
            <OtpVerificationEmailModal
              open={showEmailOtpModal}
              handleClose={handleOTPModalClose}
              handleOtpChange={(otp) => createOtp(otp)}
              otp={token}
              resendOtp={() => {
                dispatch(sendCompanyOtp());
              }}
              otpToken={otp?.data}
              handleSuccessOpen={() => handleEmailOtpSubmit()}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default CompanyInfo;
