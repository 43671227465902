import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1215px) {
    margin-top: 30%;
    padding-left: 1.5rem;
  }
`;

export const headingText = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineheight: '24px',
  textAlign: 'right',
  textTransform: 'capitalize',
  mt: {xs: 0, lg: 5}
}
