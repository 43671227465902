import React from "react";
import Button from "../../Buttons";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";

function SuccessMessage({
  icon,
  title,
  subTextStart,
  subTextHighlighted,
  subTextEnd,
  route,
  handleClose,
  handleSource,
}) {
  const functionPass = () => {
    handleSource();
  };
  return (
    <div className={style.container}>
      <BlockSpacer mt={42} />
      <div>{icon}</div>
      {title && (
        <>
          <BlockSpacer mt={49} />
          <div className={style.title}>{title}</div>
        </>
      )}

      <BlockSpacer mt={21} />
      <div className={style.subText}>
        {subTextStart}{" "}
        <span className={style.highlighted}>{subTextHighlighted} </span>
        {subTextEnd}
      </div>
      <BlockSpacer mt={54} />

      <Button
        color={"primary"}
        width={"328px"}
        onClick={handleClose ? () => handleClose() : () => functionPass()}
      >
        Continue
      </Button>

      <BlockSpacer mt={54} />
    </div>
  );
}

export default SuccessMessage;
