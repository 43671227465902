import React, { useEffect, useState } from "react";
import {
  Section,
  Title,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
  requirement,
  styles,
} from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import FormLabel from "../../../components/Forms/Label/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import FormAligner from "../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../components/Buttons/index.jsx";
import NoteModal from "../../../components/Modals/NoteModal/index.jsx";
import TextField from "@mui/material/TextField";
// import Loanvalue from "../../../components/Modals/Loanvalue";
import Loanvalue from "../../../components/Modals/PersonalLoanValue/index.jsx";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoanProducts,
  getLoanReasons,
  initializeLoanProcessing,
  cancelInitialization,
  getAllVehicles,
  getAllRegistrations,
} from "../../../redux/slices/getCreditSlice.js";
import { ClipLoader } from "react-spinners";
import { formatInputWithCommas } from "../../../functions/numberValidity.js";

function SoleProprietorVehicle() {
  const items = ["Business", "Others"];
  const [tenors, setTenors] = useState([]);
  const [selectedTenor, setSelectedTenor] = useState("");
  const [userLoanReason, setUserLoanReason] = useState("");
  const [payday, setPayday] = useState("");
  const [salaryInfo, setSalaryInfo] = useState({
    netSalary: "",
    monthlyLoanObligation: "",
    worthOfVehicle: "",
  });
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [vehicleLocate, setVehicleLocate] = useState("");
  const [vehicleAgeOpt, setVehicleAgeOpt] = useState([]);
  const [vehicleAge, setVehicleAge] = useState("");
  const [vehicleId, setVehicleId] = useState("");

  const [monthly, setMonthly] = useState({
    apr: "",
    aug: "",
    dec: "",
    feb: "",
    jan: "",
    jul: "",
    jun: "",
    mar: "",
    may: "",
    nov: "",
    oct: "",
    sep: "",
  });

  const [method, setMethod] = useState("");

  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    products,
    reasons,
    loading,
    initializedLoan,
    allVehicles,
    registeredServices,
  } = useSelector((state) => state.getCredit);

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(getLoanReasons());
    dispatch(getAllVehicles());
    dispatch(getAllRegistrations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedVehicle !== "" && vehicleStatus !== "") {
      let chosenBrand = allVehicles?.content?.find(
        (vehicle) => vehicle?.vehicleBrand === selectedVehicle
      );
      if (vehicleStatus === "Tokunbo") {
        let age = [];
        for (var i = 1; i <= chosenBrand?.maximumTenorTokunbo; i++) {
          age.push(i === 1 ? "1 year" : `${i} years`);
        }
        setVehicleAgeOpt(age);
      } else if (vehicleStatus === "Nigerian Used") {
        let age = [];
        for (var j = 1; j <= chosenBrand?.maximumTenorNigerianUsed; j++) {
          age.push(j === 1 ? "1 year" : `${j} years`);
        }
        setVehicleAgeOpt(age);
      }
    }
    // eslint-disable-next-line
  }, [selectedVehicle, vehicleStatus]);

  useEffect(() => {
    let product = products?.find((item) => item.id === parseInt(id));
    getRange(product?.minimumTenor, product?.maximumTenor);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (initializedLoan) {
      handleLoan();
    }
  }, [initializedLoan]);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const navigate = useNavigate();
  //Loan Value && Generate Payment

  const [loanValue, setLoanValue] = useState(false);
  const handleLoan = () => setLoanValue(true);
  const handleLoanClose = () => setLoanValue(false);

  const reasonOpt = reasons ? reasons?.map((item) => item.loanReason) : [];
  const reasonOptions = [...reasonOpt, "Others"];

  const getRange = (min, max) => {
    var range = [];
    for (var i = min; i <= max; i++) {
      range.push(i === 1 ? "1 month" : `${i} months`);
    }
    setTenors(range);
  };

  const selectedProduct = products?.find((item) => item.id === parseInt(id));

  const handleMonthly = (e) => {
    const { name, value } = e.target;

    setMonthly({
      ...monthly,
      [name]: value,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setSalaryInfo({
      ...salaryInfo,
      [name]: value,
    });
  };

  const vehicleUsage = registeredServices?.content?.map(
    (location) => `${location?.location}-${location?.assetStatus}`
  );

  const cancelLoan = () => {
    navigate("/get-credit/loans");
    dispatch(cancelInitialization());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      initializeLoanProcessing({
        assetType: "Vehicle",
        existingCashBackedLoan: 0,
        landedPropertyLocation: "string",
        loanReasonId:
          method === "Others"
            ? null
            : reasons?.find((item) => item.loanReason === method).id,
        month: {
          apr: parseFloat(monthly?.apr?.replace(/,/g, "")),
          aug: parseFloat(monthly?.aug?.replace(/,/g, "")),
          dec: parseFloat(monthly?.dec?.replace(/,/g, "")),
          feb: parseFloat(monthly?.feb?.replace(/,/g, "")),
          jan: parseFloat(monthly?.jan?.replace(/,/g, "")),
          jul: parseFloat(monthly?.jul?.replace(/,/g, "")),
          jun: parseFloat(monthly?.jun?.replace(/,/g, "")),
          mar: parseFloat(monthly?.mar?.replace(/,/g, "")),
          may: parseFloat(monthly?.may?.replace(/,/g, "")),
          nov: parseFloat(monthly?.nov?.replace(/,/g, "")),
          oct: parseFloat(monthly?.oct?.replace(/,/g, "")),
          sep: parseFloat(monthly?.sep?.replace(/,/g, "")),
        },
        netMonthlySalary: 0,
        otherMonthlyObligation: parseFloat(
          salaryInfo.monthlyLoanObligation?.replace(/,/g, "")
        ),
        paymentStructure: "string",
        productId: parseInt(id),
        salaryPaymentDay: 0,
        tenor: parseInt(selectedTenor?.split(" ")[0]),
        treasuryFundValue: 0,
        userLoanReason: userLoanReason,
        vehicleBrandDetails: "string",
        vehicleId: allVehicles?.content?.find(
          (item) => item?.vehicleBrand === selectedVehicle
        )?.id,
        vehicleRegistrationId: 0,
        worthOfLandedProperty: 0,
        worthOfVehicle: parseFloat(
          salaryInfo?.worthOfVehicle?.replace(/,/g, "")
        ),
      })
    );
  };

  const updateVehicle = (e) => {
    setVehicleLocate(e.target.value);
    let vehicleSplit = e.target.value?.split("-");
    let selectedlocate = registeredServices?.content?.find(
      (item) =>
        item?.location === vehicleSplit[0] &&
        item?.assetStatus === vehicleSplit[1]
    );
    setVehicleStatus(selectedlocate?.assetStatus);
    setVehicleId(selectedlocate?.id);
  };

  const verifySubmit = () => {
    if (
      salaryInfo.monthlyLoanObligation !== "" &&
      selectedTenor !== "" &&
      method !== "" &&
      selectedVehicle !== "" &&
      vehicleLocate !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <Typography variant="h6" sx={Title}>
            {selectedProduct?.productName}
          </Typography>
          <Box sx={contentContent}>
            {selectedProduct?.productSummary?.split(".")?.map((item, id) => (
              <Typography sx={paragraphtitle} key={id}>
                {item}
              </Typography>
            ))}
          </Box>
          <BlockSpacer mt={20} />
          <Typography sx={requirements}>
            Product Request Requirements
          </Typography>
          <BlockSpacer mt={10} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <FormAligner>
                    <SelectField
                      name={"Asset Usage Location"}
                      items={vehicleUsage}
                      changeAction={(e) => updateVehicle(e)}
                      method={vehicleLocate}
                      maxWidth="100%"
                    />
                  </FormAligner>
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <SelectField
                    name={"Vehicle Brand"}
                    items={allVehicles?.content?.map(
                      (item) => item?.vehicleBrand
                    )}
                    changeAction={(e) => setSelectedVehicle(e.target.value)}
                    method={selectedVehicle}
                    label="Select Brand"
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <FormLabel text={"Vehicle's Worth"} />
                  <TextField
                    placeholder="Input Worth"
                    value={formatInputWithCommas(salaryInfo?.worthOfVehicle)}
                    InputProps={{
                      style: {
                        color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                {/* <BlockSpacer mt={12} />
                <FormAligner>
                  <SelectField
                    name={"Salary Payment Day"}
                    items={[...Array(31).keys()].map((item) => item + 1)}
                    method={payday}
                    changeAction={(e) => setPayday(e.target.value)}
                  />
                </FormAligner> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <BlockSpacer mt={10} /> */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginTop: "-1rem",
                }}
              >
                <FormAligner>
                  <SelectField
                    name={"Vehicle Status"}
                    method={vehicleStatus}
                    disabled
                    // items={vehicleStatusOpt}
                    asterisk
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <SelectField
                    name={"Vehicle Age"}
                    method={vehicleAge}
                    disabled
                    changeAction={(e) => setVehicleAge(e.target.value)}
                    items={vehicleAgeOpt}
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                {/* <FormAligner>
                  <FormLabel text={"Net Monthly Salary"} />
                  <TextField
                    placeholder="Input net month salary"
                    name="netSalary"
                    value={salaryInfo.netSalary}
                    onChange={handleInput}
                    InputProps={{
                      style: {
                        // color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={20} /> */}
                <FormAligner>
                  <FormLabel text={"Other monthly Loan Obligation"} />
                  <TextField
                    placeholder="Input Monthly Loan Obligation"
                    name="monthlyLoanObligation"
                    value={formatInputWithCommas(
                      salaryInfo.monthlyLoanObligation
                    )}
                    onChange={handleInput}
                    InputProps={{
                      style: {
                        // color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                  />
                </FormAligner>
              </Box>
            </Grid>
          </Grid>
          <BlockSpacer my={30} />
          <Typography sx={requirement}>
            Monthly Business Net Turnover
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <TextField
                    placeholder="January"
                    value={formatInputWithCommas(monthly?.jan)}
                    name="jan"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="May"
                    required
                    value={formatInputWithCommas(monthly?.may)}
                    name="may"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="September"
                    required
                    value={formatInputWithCommas(monthly?.sep)}
                    name="sep"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <TextField
                    required
                    placeholder="February"
                    value={formatInputWithCommas(monthly?.feb)}
                    name="feb"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    required
                    placeholder="June"
                    value={formatInputWithCommas(monthly?.jun)}
                    name="jun"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    required
                    placeholder="October"
                    value={formatInputWithCommas(monthly?.oct)}
                    name="oct"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <TextField
                    placeholder="March"
                    required
                    value={formatInputWithCommas(monthly?.mar)}
                    name="mar"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="July"
                    required
                    value={formatInputWithCommas(monthly?.jul)}
                    name="jul"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="November"
                    required
                    value={formatInputWithCommas(monthly?.nov)}
                    name="nov"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <TextField
                    placeholder="April"
                    value={formatInputWithCommas(monthly?.apr)}
                    name="apr"
                    onChange={handleMonthly}
                    required
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="August"
                    required
                    value={formatInputWithCommas(monthly?.aug)}
                    name="aug"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
                <BlockSpacer mt={10} />
                <FormAligner>
                  <TextField
                    placeholder="December"
                    required
                    value={formatInputWithCommas(monthly?.dec)}
                    name="dec"
                    onChange={handleMonthly}
                    InputProps={{
                      style: {
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                </FormAligner>
              </Box>
            </Grid>
            <BlockSpacer mt={20} />
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <SelectField
                    name={"Tenor"}
                    method={selectedTenor}
                    changeAction={(e) => setSelectedTenor(e.target.value)}
                    items={tenors}
                    asterisk
                  />
                </FormAligner>
                {method === "Others" && (
                  <FormAligner>
                    <FormLabel text={"Input Reason here"} asterisk />

                    <TextField
                      placeholder="State reason here"
                      InputProps={{
                        style: {
                          color: "grey",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                        },
                      }}
                      value={userLoanReason}
                      onChange={(e) => setUserLoanReason(e.target.value)}
                    />
                  </FormAligner>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <SelectField
                    name={"Reason for Loan"}
                    items={reasonOptions}
                    changeAction={changeAction}
                    method={method}
                    asterisk
                  />
                </FormAligner>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "87%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "18px",
                lineHeight: "21px",
              }}
              type="button"
              onClick={cancelLoan}
            >
              Back
            </Button>

            <Buttons
              color="primary"
              width="328px"
              onClick={(e) => handleSubmit(e)}
              disabled={verifySubmit()}
            >
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Profile Me"
              )}
            </Buttons>
          </Box>
        </Paper>
        {loanValue && (
          <Loanvalue open={loanValue} handleClose={handleLoanClose} />
        )}
      </>
    </>
  );
}

export default SoleProprietorVehicle;
