export const textheading = {
    fontFamily: 'Montserrat',
   fontStyle: 'normal',
   fontWeight: 600,
   fontSize: '20px',
   lineHeight: '24px',
   textTransform: 'capitalize',
   color: '#222222'
}


export const smallText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '-0.01em',
    color: '#828282'
}