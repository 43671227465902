import React from "react";
import {
  Section,
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  Title,
  contentTitle,
  subTitle,
} from "./style.js";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import TopBar from "../../../components/GetCreditTopBar";

function PersionerCredit() {
  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={Title}>
          Loan
        </Typography>
        <Typography variant="p">(Pensioner)</Typography>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>
                    <Typography sx={contentTitle}>
                      Collateral Loans
                    </Typography>
                    <Typography sx={subTitle}>
                      Request a Collateral Loan
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <Paper
                    elevation={0.5}
                    sx={{
                      width: { xs: "448px", lg: "448px" },
                      maxWidth: "100%",
                      height: { sx: "400px", lg: "213px" },
                      boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                    }}
                  >
                    <Typography sx={QuickNanoLoan}>
                      Cash-Backed Loan
                    </Typography>
                    <Box sx={callToActionCard}>
                      <Box>
                        <NanoImage />
                      </Box>
                      <div>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Link to="/cash-back/pensioner">
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              marginTop: "1rem",
                              width: "144px",
                              height: "42px",
                              color: "#111E6C",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              lineHeight: "17px",
                            }}
                          >
                            Request
                          </Button>
                        </Link>
                      </div>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
              <BlockSpacer mt={30} />

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>
                    <Typography sx={contentTitle}>
                      Non-Collateral Loan
                    </Typography>
                    <Typography sx={subTitle}>
                      Request a Non-Collateral Loan
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <Paper
                    elevation={0.5}
                    sx={{
                      width: { xs: "448px", lg: "448px" },
                      maxWidth: "100%",
                      height: { sx: "400px", lg: "213px" },
                      boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                    }}
                  >
                    <Typography sx={QuickNanoLoan}>
                      Quick Nano Loan
                    </Typography>
                    <Box sx={callToActionCard}>
                      <Box>
                        <NanoImage />
                      </Box>
                      <div>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Link to="/quick-loan/pensioner">
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              marginTop: "1rem",
                              width: "144px",
                              height: "42px",
                              color: "#111E6C",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "capitalize",
                              lineHeight: "17px",
                            }}
                          >
                            Request
                          </Button>
                        </Link>
                      </div>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
        <BlockSpacer mt={450} />
        <Paper
          sx={{
            height: "96px",
            width: { xs: "100%", lg: "90%" },
            maxWidth: "100%",
            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          }}
          elevation={1}
        >
          <Link to="/get-credit">
            <Button
              sx={{
                background: "#F2F2F2",
                marginTop: "1rem",
                width: { xs: "250px", lg: "328px" },
                height: "42px",
                color: "#111E6C",
                textTransform: "capitalize",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "15px",
                marginLeft: "1rem",
              }}
            >
              Back
            </Button>
          </Link>
        </Paper>
      </Section>
    </>
  );
}

export default PersionerCredit;
