import React, { useEffect, useState } from "react";
import DotLine from "../../../../components/Hocs/DotLine";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import Buttons from "../../../../components/Buttons/index.jsx";
import Cards from "../../../../components/Cards/SummaryCard/index.jsx";
import { Box } from "@mui/system";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Section,
  AccountNumber,
  processingStage,
  Number,
  Tenor,
  Two,
  Reason,
  Detail,
  summaryTitle,
  Quickloan,
  Requested,
  Stenor,
  figure,
  days,
  Interest,
  Value,
  Fee,
  Digit,
  Repay,
  Loan,
  Integer,
  Date,
  button,
} from "./style";
import BlockSpacer from "../../../../components/BlockSpacer";
import WithdrawRequestModal from "../../../../components/Modals/WithdrawRequestModal/WithdrawRequestModal";
import SuccessWithdraw from "../../../../components/Modals/WithdrawSuccessModal";
import TopBar from "../../../../components/MyCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWithdraw,
  withdrawLoanRequest,
} from "../../../../redux/slices/myCreditSlice";
import useGetPendingTransactionQuery from "../../../../api/services/transactions/get-pending-transaction.api.js";

function ProcessingStage({ title }) {
  // Withdraw request
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { withdrawRequest, withdrawRequestErr, pendingTransactions, loading } =
    useSelector((state) => state.myCredit);

  const { data } = useGetPendingTransactionQuery(id);
  const selectedLoan = data?.data;
  const { bankDetails, bankDetailsError } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (withdrawRequest) {
      handleSuccessWithdraw();
      dispatch(clearWithdraw());
    }
    // eslint-disable-next-line
  }, [withdrawRequest]);

  const submitWithdraw = () => {
    dispatch(
      withdrawLoanRequest({ transactionId: selectedLoan?.transactionId })
    );
  };

  const [openWithdrawNote, setWithdrawNoteOpen] = useState(false);
  const handleWithdrawInfoOpen = () => setWithdrawNoteOpen(true);
  const handleWithdrawInfoClose = () => setWithdrawNoteOpen(false);

  //Withdraw success

  const [openSuccessWithdraw, setOpenSuccessWithdraw] = useState(false);

  const handleSuccessWithdraw = () => {
    setOpenSuccessWithdraw(true);
    setWithdrawNoteOpen(false);
  };

  const hanldeSuccessWithdrawClose = () => {
    setOpenSuccessWithdraw(false);
    navigate("/pending-transaction");
  };
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container spacing={9} sx={{ overflow: { xs: "hidden" } }}>
            {/* First Card grid */}
            <Grid item xs={12} xxs={12} lg={6} xlg={6}>
              <Box>
                <Paper
                  elevation={1}
                  sx={{
                    mt: { xs: "0", lg: "-8" },
                    width: { xs: "400px", lg: "428px" },
                    maxWidth: "100%",
                    height: { xs: "250px", lg: "293px" },
                    borderRadius: "10px",
                    overflow: { xs: "hidden" },
                    position: "relative",
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
                  }}
                >
                  <Typography sx={AccountNumber}>Account Number</Typography>
                  <Typography sx={Number}>
                    {bankDetails?.accountNumber}
                  </Typography>
                  <Typography sx={Tenor}>Tenor</Typography>
                  <Typography sx={Two}>
                    {selectedLoan?.loanSummary?.tenor}{" "}
                    {selectedLoan?.product?.feePaymentType === "WEEKLY"
                      ? "Day(s)"
                      : "Month(s)"}
                  </Typography>
                  <Typography sx={Reason}>Reason For Loan</Typography>
                  <Typography sx={processingStage}>
                    Processing(Stage)
                  </Typography>
                  <Typography sx={Detail}>
                    {selectedLoan?.loanReasonMgt
                      ? selectedLoan?.loanReasonMgt?.loanReason
                      : selectedLoan?.userLoanReason}
                  </Typography>
                </Paper>
              </Box>
            </Grid>

            {/* Second Card grid */}
            <Grid
              item
              xs={12}
              xxs={12}
              lg={6}
              xlg={6}
              style={{
                background: "rgba(28, 68, 141, 0.03)",
                width: "503px",
                height: "789px",
              }}
            >
              <Card
                sx={{
                  width: { xs: "380px", md: "350px", lg: "428.84px" },
                  height: "328.5px",
                  background: "rgba(28, 68, 141, 0.03)",
                  position: "relative",
                  overflow: { xs: "hidden" },
                  border: 0,
                  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
                }}
              >
                <CardContent>
                  <Typography sx={Quickloan}>
                    {selectedLoan?.product?.productName} Summary
                  </Typography>
                  <Typography sx={Requested}>Requested Loan Amount</Typography>
                  <Typography sx={Stenor}>Tenor</Typography>
                  <Typography sx={figure}>
                    {" "}
                    &#8358;
                    {parseFloat(
                      selectedLoan?.loanSummary?.requestedLoanAmount ?? 0
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography sx={days}>
                    {selectedLoan?.loanSummary?.tenor ?? 0}{" "}
                    {selectedLoan?.product?.feePaymentType === "WEEKLY"
                      ? "Day(s)"
                      : "Month(s)"}
                  </Typography>
                  <Box
                    style={{
                      border: "1.2px dashed#EFEFEF",
                      position: "relative",
                      top: "118px",
                    }}
                  />
                  <Typography sx={Interest}>Interest Payable</Typography>
                  <Typography sx={Value}>
                    &#8358;
                    {parseFloat(
                      selectedLoan?.loanSummary?.interestPayable ?? 0
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography sx={Fee}>Fee Payable</Typography>
                  {/* Request for actual value */}
                  <Typography
                    sx={{ ...Digit, pr: "3rem" }}
                    style={{ textAlign: "right" }}
                  >
                    &#8358;
                    {parseFloat(
                      selectedLoan?.loanSummary?.feePayable ?? 0
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>

                  <Box
                    style={{
                      border: "1.2px dashed#EFEFEF",
                      position: "absolute",
                      top: "210px",
                    }}
                  ></Box>

                  <Typography sx={Repay}>Total Repayment</Typography>
                  <Typography sx={Loan}>Loan Due Date</Typography>
                  <Typography sx={Integer}>
                    &#8358;
                    {parseFloat(
                      selectedLoan?.loanSummary?.totalRepayment ?? 0
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography sx={Date}>
                    {selectedLoan?.loanSummary?.loanDueDate
                      ?.slice(0, 10)
                      ?.replaceAll("-", "/")}
                  </Typography>
                </CardContent>
              </Card>
              <BlockSpacer />
              <Box sx={button}>
                <Buttons
                  color="primary"
                  width="328px"
                  onClick={() =>
                    navigate(
                      `/pending-upload-loan-documents/${selectedLoan?.transactionId}`
                    )
                  }
                  disabled={!selectedLoan?.transactionId}
                >
                  Document Checklist
                </Buttons>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/pending-transaction">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "#111E6C",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                color: "#111E6C",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              onClick={() => handleWithdrawInfoOpen()}
              disabled={!selectedLoan?.transactionId}
            >
              <>Withdraw Request</>
            </Button>
          </Box>
        </Paper>
        {openWithdrawNote && (
          <WithdrawRequestModal
            open={openWithdrawNote}
            handleClose={handleWithdrawInfoClose}
            handleSuccessWithdraw={submitWithdraw}
          />
        )}

        {openSuccessWithdraw && (
          <SuccessWithdraw
            open={openSuccessWithdraw}
            handleClose={hanldeSuccessWithdrawClose}
          />
        )}
      </main>
    </>
  );
}

export default ProcessingStage;
