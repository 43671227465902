import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import React, { useEffect ,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
import SummaryCard from "../../../components/Cards/LeaseSummaryCard";
import Buttons from "../../../components/Buttons";
import { Section, documentStyle, responsonive } from "./style";
import DocumentIcon from "../../../components/IconSvgs/Document";
import CheckBox from "../../../components/Checkboxs";
import Checkbox from "@mui/material/Checkbox";
import document from "../../../components/Assets/images/document.png";
import SuccessLoan from "../../../components/Modals/Success(l)";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  submitLoanProcessing,
  getRequiredDoc,
} from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

function LeaseTermsCondition() {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess(true);
  const [term, setTerm] = useState(false);
  const handleSuccessClose = () => setSuccess(false);
  const navigate = useNavigate();

  const { initializedLoan, submitted, repayment, loading, requiredDocs } =
    useSelector((state) => state.getCredit);

  useEffect(() => {
    if (submitted) {
      if (
        submitted?.object?.body?.body?.message ===
        "Loan submitted for processing"
      ) {
        dispatch(getRequiredDoc(submitted?.object?.body?.body?.transactionId));
        toast.success(submitted?.object?.body?.body?.message);
      } else {
        toast.error(submitted?.object?.body?.body?.message);
      }
      // dispatch(clearLoan)
    }
    // eslint-disable-next-line
  }, [submitted]);

  useEffect(() => {
    if (requiredDocs) {
      navigate("/upload-loan-documents");
      // dispatch(clearLoan)
    }
    // eslint-disable-next-line
  }, [requiredDocs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitLoanProcessing({
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };
  
  return (
    <>
      <TopBar />
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <SummaryCard
            title={"Finance Lease"}
            currencyRequested={"Accessible Lease Value"}
            currency={`₦${parseFloat(
              repayment?.object?.body?.body?.accessibleLeaseAmount
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            UpfrontPayment={"Upfront Payment"}
            hundredThousand={`₦${parseFloat(
              repayment?.object?.body?.body?.upfrontPayment
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            PercentageOfleaseValue={"Percentage of Lease Value"}
            Percentagelease={`${repayment?.object?.body?.body?.percentageOfLeaseValue}%`}
            tenor={"Tenor"}
            days={`${repayment?.object?.body?.body?.tenor} Month(s)`}
            TotalRepayment={"Total Repayment"}
            RepaymentAmount={`₦${parseFloat(
              repayment?.object?.body?.body?.totalRepayment
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            TotalDebtWeight={"Total Debt Weight"}
            DebtAmount={`${repayment?.object?.body?.body?.totalDebtWeight}%`}
            monthlyRepayments={
              repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
            }
          />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={documentStyle}>
              <img src={document} alt="document" style={responsonive} />

              <BlockSpacer mt={20} />

              <Typography
                  sx={{
                    marginLeft: "5rem",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  <Checkbox
                    size={"small"}
                    sx={{
                      color: "#111E6C",
                      "&.Mui-checked": {
                        color: "#111E6C",
                      },
                    }}
                    value={term}
                    checked={term}
                    onChange={() => setTerm(!term)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  I agree to the term
                </Typography>
            </Box>
          </Grid>
        </Grid>
        <BlockSpacer mt={150} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "89%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: { xs: "300px", lg: "328px" },
                height: "54px",
                color: "#111E6C",
                maxWidth: "100%",
                marginBottom: { xs: "1rem" },
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "22px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Buttons
              color="primary"
              width="328px"
              onClick={handleSubmit}
              disabled={!term}
            >
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Process Lease"
              )}
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {success && (
        <SuccessLoan open={success} handleClose={handleSuccessClose} />
      )}
    </>
  );
}

export default LeaseTermsCondition;
