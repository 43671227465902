import React, { useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../components/Buttons";
import BlockSpacer from "../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../components/Cards/LandLoanCard";
import { Link } from "react-router-dom";
import OfferSuccessModal from "../../../components/Modals/ConsumerOfferModal/index.jsx";
import SourceAccountModal from "../../../components/Modals/CollateralLoan/SourceAccountMode";
import Note from "../../../components/Modals/CollateralLoan/Notics";
import ApproveBankModal from "../../../components/Modals/CollateralLoan/ApproveBankLand/index";
import TopBar from "../../../components/GetCreditTopBar";

const LandloanSummary = (props) => {
  // Acceptance Loan Offer
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const handleOfferOpen = () => setOpenOfferModal(true);
  const handleOfferClose = () => setOpenOfferModal(false);

  //Source Account Modal
  const [openSource, setOpenSource] = useState(false);
  const handleSource = () => {
    setOpenSource(true);
    setOpenOfferModal(false);
  };
  const handleSourceClose = () => setOpenSource(false);

  //Note Ticket

  const [openTicket, setOpenTicket] = useState(false);
  const handleNoteTicketOpen = () => {
    setOpenTicket(true);
    setOpenSource(false);
  };

  const handleOpenTicketClose = () => {
    setOpenTicket(false);
  };
  //Open Approve Bank

  const [openApprove, setOpenApprove] = useState(false);
  const handleApprovalOpen = () => {
    setOpenApprove(true);
    setOpenTicket(false);
  };
  const handleApprovalClose = () => {
    setOpenApprove(false);
  };
  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={5} />
          <Typography variant="h5" component="h5" sx={contentTitle}>
            Loan Summary
          </Typography>
          <SummaryCard
            title={"Landed Property Asset Cash Loan"}
            currencyRequested={"Accessible Loan Amount"}
            currency={"2,000,000"}
            tenor={"Tenor"}
            days={"Two Months"}
            interestRate={"Interest Rate"}
            interestRatePercentage={"20.00%"}
            interestPayable={"Interest Payable"}
            feePayableCurrency={"2,000,000"}
            ProcessingfeeRate={"Processing Fee Rate"}
            ProcessingfeePayable={"Processsing Fee Payable"}
            LegalFeeRate={"Legal Fee Rate"}
            LegalFeePercentage={"20.00%"}
            LegalFeePayable={"Legal Fee Payable"}
            LegalFeeAmount={"2,000,000"}
            StampDutyFeeRate={"Stamp Duty Fee Rate"}
            StampDutyFeePercentage={"20.00%"}
            StampDutyFeePayable={"Stamp Duty Fee Payable"}
            StampFeeAmount={"2,000,000"}
            ManagementFeeRate={"Management Fee Rate"}
            ManagementFeePercentage={"20.00%"}
            ManagementFeePayable={"Management Fee Payable"}
            ManagementFeeAmount={"2,000,000"}
            TotalRepayment={"Total Repayment"}
            hundredThousand={"100,000"}
            firstMonthRepayment={"First Monthly Repayment"}
            firstMonthFee={"100,000"}
            DueDate={"Due Date"}
            dueDate={"12/08/2022"}
            secondMonthRepayment={"Second Monthly Repayment"}
            secondMonthFee={"100,000"}
          />
        </Section>
        <BlockSpacer mt={40} />
        <Paper
          elevation={1}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "100%" },
            margin: "1rem",
            marginLeft: { lg: "-2rem" },
            marginRight: { lg: "auto" },
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/landed-asset-loan">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: { xs: "300px", lg: "328px" },
                  height: "54px",
                  color: "#111E6C",
                  maxWidth: "100%",
                  marginBottom: { xs: "1rem" },
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "22px",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
            </Link>

            <Buttons
              color="primary"
              width="328px"
              onClick={() => handleOfferOpen()}
            >
              Accept Offer
            </Buttons>
          </Box>
        </Paper>
      </>
      {openOfferModal && (
        <OfferSuccessModal
          open={openOfferModal}
          handleClose={handleOfferClose}
          handleSource={handleSource}
        />
      )}
      {openSource && (
        <SourceAccountModal
          open={openSource}
          handleClose={handleSourceClose}
          handleNoteTicketOpen={handleNoteTicketOpen}
        />
      )}
      {openTicket && (
        <Note
          open={openTicket}
          handleClose={handleOpenTicketClose}
          handleApprovalOpen={handleApprovalOpen}
        />
      )}
      {openApprove && (
        <ApproveBankModal
          open={openApprove}
          handleClose={handleApprovalClose}
        />
      )}
    </>
  );
};

export default LandloanSummary;
