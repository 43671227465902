import React from "react";
import DoubleRegisterButtons from "../../GridFlex/DoubleRegisterButtons";
import Logo from "../../Logo";
import TwirlArrow from "../../StylishSvgs/TwirlArrow";
import LargeText from "../../Texts/LargeText";
import SmallText from "../../Texts/SmallText";
import style from "./style.module.css";

function SplitScreenLeft({ allowBtns, individualSignup, setIndividualSignup }) {
  return (
    <div className={style.container}>
      <div>
        <Logo />
        <div className={style.middle}>
          <div>
            <LargeText text={"Catch smart investments for start-ups"} />
            <SmallText text={"Reach your \n Financial goal "} />
          </div>
          <div className={style.arrow}>
            <TwirlArrow />
          </div>
        </div>
      </div>
      {allowBtns && (
        <DoubleRegisterButtons
          individualSignup={individualSignup}
          setIndividualSignup={setIndividualSignup}
        />
      )}
    </div>
  );
}

export default SplitScreenLeft;
