import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import SelectField from "../Core/SelectField";
import FormAligner from "../Hocs/FormAligner";
import FormLabel from "../Forms/Label";
import Buttons from "../Buttons";
import { smallText, Section } from "./style";
import { LIST_OF_UTILITY_BILL } from "../../constants";
import FileuploadIcon from "../IconSvgs/FileuploadIcon";
import BlockSpacer from "../BlockSpacer";
import FooterCheckout from "../FooterCheckout";
import OtpVerificationEmailModal from "../Modals/OtpVerificationEmailModal";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import ClearIcon from "@mui/icons-material/Clear";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { updateCreditDirector } from "../../redux/slices/updateProfileSlice";
import axios from "axios";
import moment from "moment";
import BvnSuccess from "../Modals/BvnSuccess";
import useScreenSize from "../ScreenSize";
import { CircularProgress } from "@mui/material";
import { Verified } from "@mui/icons-material";
import { toast } from "react-toastify";

function AddDirector({ director, existingDirector, idNum, toggleAd }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const handleVerifyOpen = () => setOpen(true);
  const handleVerifyClose = () => setOpen(false);
  const [verifed, setVerifed] = useState(false);
  const [verifedLoading, setVerifedLoading] = useState(false);
  const [bvnError, setBvnError] = useState("");
  const [openBvn, setOpenbvn] = React.useState(false);
  const { width } = useScreenSize();

  const handleBvnClose = () => setOpenbvn(false);
  const { idTypes } = useSelector((state) => state.profile);
  const { loading, directorMsg } = useSelector((state) => state.updateProfile);
  const idOptions = idTypes ? idTypes?.map((item) => item.name) : [];

  const [idType, setIdType] = useState(director?.idType?.name);
  const handleIdType = (e) => {
    setIdType(e.target.value);
  };

  const [billType, setBillType] = useState(director?.utilityBillType);
  const handleBillType = (e) => {
    setBillType(e.target.value);
    setFormData({
      ...formData,
      utilityBillType: e.target.value,
    });
  };

  const initialData = {
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    idNumber: "",
    idTypeId: "",
    email: "",
    address: "",
    bvn: "",
    utilityBillPeriod: "",
    utilityBillType: "",
    utilityBillTypeDetails: "",
    dateOfBirth: "",
    city: "",
    state: "",
  };

  const [formData, setFormData] = useState(initialData);

  const [frontEncodeProgress, setFrontEncodeProgress] = useState(0);
  const [backEncodeProgress, setBackEncodeProgress] = useState(0);
  const [utilityEncodeProgress, setUtilityEncodeProgress] = useState(0);
  const [utilityEncodeBackProgress, setUtilityEncodeBackProgress] = useState(0);

  const initErr = {
    frontEncodedString: false,
    backEncodedString: false,
    utilityEncodedString: false,
    utilityEncodedStringBack: false,
    photoEncodedString: false,
  };
  const [fileErr, setFileErr] = useState(initErr);

  const initialFile = {
    frontEncodedString: null,
    backEncodedString: null,
    utilityEncodedString: null,
    utilityEncodedStringBack: null,
    photoEncodedString: null,
  };

  const [base64File, setBase64File] = useState(initialFile);

  const frontEncodedRef = useRef();
  const backEncodedRef = useRef();
  const utilityEncodedRef = useRef();
  const utilityEncodedBackRef = useRef();
  const photoEncodedRef = useRef();

  const handleFileSelect = (e, reference) => {
    e.preventDefault();
    reference.current.click();
  };

  const handleFileChange = (e, name) => {
    const { files } = e.target;

    if (name === "frontEncodedString") {
      setFrontEncodeProgress(0);
    } else if (name === "backEncodedString") {
      setBackEncodeProgress(0);
    } else if (name === "utilityEncodedString") {
      setUtilityEncodeProgress(0);
    } else if (name === "utilityEncodedStringBack") {
      setUtilityEncodeBackProgress(0);
    }

    // console.log(files[0]);

    const encodedFileBase64 = (file) => {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setBase64File({
            ...base64File,
            [name]: reader.result.split("base64,")[1],
          });
        };
        console.log(reader);
        reader.onerror = (error) => {
          console.log("error", error);
        };
      }
    };

    if (
      files[0]?.size <= 2000000 &&
      (files[0]?.type === "image/jpeg" ||
        files[0]?.type === "application/pdf" ||
        files[0]?.type === "image/png")
    ) {
      encodedFileBase64(files[0]);
      setFileErr({
        ...fileErr,
        [name]: false,
      });
    } else {
      setFileErr({
        ...fileErr,
        [name]: true,
      });
    }
    e.target.value = null;
  };

  const verifyBvn = async () => {
    const bvnData = {
      id: formData.bvn,
      firstName: formData.firstName,
      lastName: formData.lastName,
      isSubjectConsent: true,
      phoneNumber: formData?.phone,
      dateOfBirth: moment(formData.dateOfBirth, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
    };

    setVerifedLoading(true);
    try {
      const res = await axios.post(
        `https://api-credit.optisoft.com.ng:8090/auth/verify-bvn/credit`,
        bvnData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res?.data);
      localStorage.setItem("BVNDATA", JSON.stringify(res?.data));

      if (res?.data?.data?.status === "found") {
        setOpenbvn(true);
        setBvnError("");
        setVerifed(true);
      }

      setVerifedLoading(false);
      // setBvn("");
    } catch (error) {
      console.log(error);
      setBvnError(error?.response?.data?.message);
      setVerifedLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // id front file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setFrontEncodeProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.frontEncodedString]);
  // id back file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setBackEncodeProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.backEncodedString]);
  // utility file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setUtilityEncodeProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.utilityEncodedString]);
  // utility back file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setUtilityEncodeBackProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.utilityEncodedStringBack]);

  // const handleSendOtp = (e) => {
  //   e.preventDefault();
  //   dispatch(sendOtp());
  // };

  // const handleOTPModalClose = () => {
  //   dispatch(closeOtpModal());
  //   setToken("");
  // };

  // const handleEmailOtpSubmit = async () => {
  //   dispatch(validateOtp(token));
  // };

  // useEffect(() => {
  //   if (validateEmailOtp) {
  //     handleOTPModalClose();
  //     toggleEdit();
  //   }
  // }, [validateEmailOtp]);

  const LIST_OF_UTILITY_BILL_OPTIONS = [...LIST_OF_UTILITY_BILL, "Others"];

  useEffect(() => {
    setFormData(initialData);
    setBase64File(initialFile);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directorMsg]);

  const handleSubmit = () => {
    const {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      address,
      bvn,
      utilityBillPeriod,
      utilityBillType,
      utilityBillTypeDetails,
    } = formData;

    const {
      frontEncodedString,
      photoEncodedString,
      backEncodedString,
      utilityEncodedStringBack,
      utilityEncodedString,
    } = base64File;

    const submitData = [
      {
        firstName,
        lastName,
        middleName,
        phone,
        email,
        // idNumber: "43543",
        bvn,
        address,
        utilityBillPeriod,
        idNumber: "656787896",
        utilityBillType: utilityBillType === "Others" ? null : utilityBillType,
        utilityBillTypeDetails,
        idTypeId: idTypes?.find((item) => item.name === idType)?.id,
        idDocumentImage: {
          encodedUpload: frontEncodedString,
          name: "ID Image",
        },
        idDocumentImage_Back: {
          encodedUpload: backEncodedString,
          name: "ID Image Back",
        },
        passportImage: {
          encodedUpload: photoEncodedString,
          name: "Passport",
        },
        utilityBillImage: {
          encodedUpload: utilityEncodedString,
          name: "Utility Bill",
        },
        utilityBillImage_Back: {
          encodedUpload: utilityEncodedStringBack,
          name: "ID Image",
        },
        // utilityBillTypeDetails: "added"
      },
    ];

    dispatch(updateCreditDirector(submitData));
  };

  //set max and min utility period date
  const currentDay = moment().format("YYYY-MM-DD");
  const utilityBillLimit = moment().subtract(3, "months").format("YYYY-MM-DD");

  return (
    <Section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mt: 5,
            ml: { xs: 3, lg: 0 },
            display: "flex",
            alignItems: "center",
            width: 330,
          }}
        >
          <div style={{ position: "relative" }}>
            {base64File.photoEncodedString !== null ? (
              <img
                className="image-frame"
                style={{
                  borderRadius: "50%",
                  border: "2px solid #FFFFFF",
                  width: width <= 700 ? "72px" : "116px",
                  height: width <= 700 ? "72px" : "116px",
                }}
                src={
                  base64File.photoEncodedString
                    ? `data:image/jpeg;base64,${base64File.photoEncodedString}`
                    : director?.passportImage?.imageUrl
                    ? director?.passportImage?.imageUrl
                    : null
                }
                alt="User"
                width={width <= 700 ? "72px" : "116px"}
                height={width <= 700 ? "72px" : "116px"}
              />
            ) : (
              <Avatar
                sx={{
                  width: width <= 700 ? "72px" : "116px",
                  height: width <= 700 ? "72px" : "116px",
                }}
              />
            )}

            <div className="banner"></div>

            <input
              type="file"
              className="file file-input"
              accept="image/jpeg,image/png"
              // disabled={showEdit}
              ref={photoEncodedRef}
              onChange={(e) => handleFileChange(e, "photoEncodedString")}
              // disabled={showEdit}
            />
            <CameraAltIcon
              className="camera"
              onClick={(e) => handleFileSelect(e, photoEncodedRef)}
            />
          </div>
          <Box sx={{ ml: ".5rem" }}>
            <Typography>Upload Passport Photo</Typography>
            <Typography sx={smallText}>jpg,png, 2MB</Typography>
          </Box>
        </Box>
        {/* <Box sx={{ mt: "2rem" }}>
          <Button
            sx={{
              height: "34px",
              background: "#F2F2F2",
              borderRadius: "5px",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "13px",
              color: "#828282",
            }}
          >
            Choose file
          </Button>
        </Box> */}
      </Box>
      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"First Name"} />
            <TextField
              placeholder=""
              type="text"
              required
              InputProps={{ readOnly: verifed }}
              name="firstName"
              value={formData?.firstName}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Middle Name"} />
            <TextField
              placeholder=""
              type="text"
              required
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Last Name"} />
            <TextField
              placeholder=""
              type="text"
              required
              InputProps={{ readOnly: verifed }}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
      </Grid>
      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormAligner>
            <FormLabel text={"Address"} />
            <TextField
              placeholder=""
              type="text"
              required
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Email Address"} />
            <TextField
              placeholder=""
              type="email"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Date of Birth"} />
            <TextField
              placeholder=""
              type="date"
              required
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              // disabled
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Phone Number"} />
            <TextField
              placeholder=""
              type="text"
              required
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </FormAligner>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "11px",
              color: "#828282",
            }}
          >
            Please provide the phone number tied to your BVN
          </Typography>
        </Grid>
      </Grid>
      <BlockSpacer mt={50} />
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <FormAligner>
            <FormLabel text={"BVN"} />
            <TextField
              placeholder=""
              type="text"
              required
              name="bvn"
              value={formData.bvn}
              onChange={handleChange}
            />
          </FormAligner>
          {bvnError?.length > 0 && (
            <p
              style={{
                color: "red",
              }}
            >
              {bvnError}
            </p>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Box sx={{ mt: width > 700 ? "2rem" : "1rem" }}>
            <Buttons
              color={"primary"}
              fontSize={width <= 700 && "14px"}
              onClick={() => verifyBvn()}
            >
              {verifedLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                "Verify"
              )}
            </Buttons>
          </Box>
        </Grid>
      </Grid>
      {/* <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"City"} />
            <TextField 
              placeholder="" 
              type="text" 
              required 
              name="city"
              value={formData.city}
              disabled
              onChange={handleChange} 
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"State"} />
            <TextField 
              placeholder="" 
              type="text" 
              required 
              name="state"
              disabled
              value={formData.state} 
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
      </Grid> */}
      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <SelectField
            name={"ID Type"}
            items={idOptions}
            method={idType}
            changeAction={handleIdType}
            asterisk
          />
        </Grid>
      </Grid>
      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FileuploadIcon />
            <Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Upload ID (Front)
              </Typography>
              {base64File?.frontEncodedString === null ? (
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    letterSpacing: "-0.04em",
                    color: "#333333",
                  }}
                >
                  jpg, png. PDF 2 MB
                </Typography>
              ) : (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Box sx={{ width: "150px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={frontEncodeProgress}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ClearIcon
                      onClick={() => {
                        setBase64File({
                          ...base64File,
                          frontEncodedString: null,
                        });
                        frontEncodeProgress(0);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {fileErr.frontEncodedString && (
                <Typography style={{ color: "#FF0000" }}>
                  File must be less than 2MB and JPG, PNG or PDF format
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {director?.idDocumentImage?.imageUrl && showEdit ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <a
                href={director?.idDocumentImage?.imageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: "150px",
                    height: "34px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    textAlign: "right",
                    letterSpacing: "-0.04em",
                    color: "#828282",
                  }}
                >
                  View
                </Button>
              </a>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <input
                type="file"
                className="file"
                accept="application/pdf,image/jpeg,image/png"
                ref={frontEncodedRef}
                // disabled={showEdit}
                onChange={(e) => handleFileChange(e, "frontEncodedString")}
              />
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "150px",
                  height: "34px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  textAlign: "right",
                  letterSpacing: "-0.04em",
                  color: "#828282",
                }}
                onClick={(e) => handleFileSelect(e, frontEncodedRef)}
              >
                Choose file
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FileuploadIcon />
            <Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Upload ID (Back)
              </Typography>
              {base64File?.backEncodedString === null ? (
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    letterSpacing: "-0.04em",
                    color: "#333333",
                  }}
                >
                  jpg, png. PDF 2 MB
                </Typography>
              ) : (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Box sx={{ width: "150px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={backEncodeProgress}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ClearIcon
                      onClick={() => {
                        setBase64File({
                          ...base64File,
                          backEncodedString: null,
                        });
                        backEncodeProgress(0);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {fileErr.backEncodedString && (
                <Typography style={{ color: "#FF0000" }}>
                  File must be less than 2MB and JPG, PNG or PDF format
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {director?.idDocumentImage_Back?.imageUrl && showEdit ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <a
                href={director?.idDocumentImage_Back?.imageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: "150px",
                    height: "34px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    textAlign: "right",
                    letterSpacing: "-0.04em",
                    color: "#828282",
                  }}
                >
                  View
                </Button>
              </a>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <input
                type="file"
                className="file"
                accept="application/pdf,image/jpeg,image/png"
                ref={backEncodedRef}
                // disabled={showEdit}
                onChange={(e) => handleFileChange(e, "backEncodedString")}
              />
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "150px",
                  height: "34px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  textAlign: "right",
                  letterSpacing: "-0.04em",
                  color: "#828282",
                }}
                onClick={(e) => handleFileSelect(e, backEncodedRef)}
              >
                Choose file
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <SelectField
            name={"Utility Bill type"}
            items={LIST_OF_UTILITY_BILL_OPTIONS}
            method={billType}
            changeAction={handleBillType}
            asterisk
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormAligner>
            <FormLabel text={"Utility Bill Period"} />
            <TextField
              placeholder=""
              type="date"
              inputProps={{ min: utilityBillLimit, max: currentDay }}
              required
              name="utilityBillPeriod"
              value={formData.utilityBillPeriod}
              onChange={handleChange}
            />
          </FormAligner>
        </Grid>
        {formData.utilityBillType === "Others" && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormAligner>
              <FormLabel text={"Utility Bill Type Details"} />
              <TextField
                placeholder=""
                type="text"
                required
                name="utilityBillTypeDetails"
                value={formData.utilityBillTypeDetails}
                onChange={handleChange}
              />
            </FormAligner>
          </Grid>
        )}
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FileuploadIcon />
            <Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Utility Bill (front)
              </Typography>
              {base64File?.utilityEncodedString === null ? (
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    letterSpacing: "-0.04em",
                    color: "#333333",
                  }}
                >
                  jpg, png. PDF 2 MB
                </Typography>
              ) : (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Box sx={{ width: "150px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={utilityEncodeProgress}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ClearIcon
                      onClick={() => {
                        setBase64File({
                          ...base64File,
                          utilityEncodedString: null,
                        });
                        utilityEncodeProgress(0);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {fileErr.utilityEncodedString && (
                <Typography style={{ color: "#FF0000" }}>
                  File must be less than 2MB and JPG, PNG or PDF format
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {director?.utilityBillImage?.imageUrl && showEdit ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <a
                href={director?.utilityBillImage?.imageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: "150px",
                    height: "34px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    textAlign: "right",
                    letterSpacing: "-0.04em",
                    color: "#828282",
                  }}
                >
                  View
                </Button>
              </a>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <input
                type="file"
                className="file"
                accept="application/pdf,image/jpeg,image/png"
                ref={utilityEncodedRef}
                // disabled={showEdit}
                onChange={(e) => handleFileChange(e, "utilityEncodedString")}
              />
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "150px",
                  height: "34px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  textAlign: "right",
                  letterSpacing: "-0.04em",
                  color: "#828282",
                }}
                onClick={(e) => handleFileSelect(e, utilityEncodedRef)}
              >
                Choose file
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FileuploadIcon />
            <Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Utility Bill (Back)
              </Typography>
              {base64File?.utilityEncodedStringBack === null ? (
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    letterSpacing: "-0.04em",
                    color: "#333333",
                  }}
                >
                  jpg, png. PDF 2 MB
                </Typography>
              ) : (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Box sx={{ width: "150px" }}>
                      <LinearProgress
                        variant="determinate"
                        value={utilityEncodeBackProgress}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ClearIcon
                      onClick={() => {
                        setBase64File({
                          ...base64File,
                          utilityEncodedStringBack: null,
                        });
                        utilityEncodeBackProgress(0);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {fileErr.utilityEncodedStringBack && (
                <Typography style={{ color: "#FF0000" }}>
                  File must be less than 2MB and JPG, PNG or PDF format
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {director?.utilityBillImage_Back?.imageUrl && showEdit ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <a
                href={director?.utilityBillImage_Back?.imageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: "150px",
                    height: "34px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    textAlign: "right",
                    letterSpacing: "-0.04em",
                    color: "#828282",
                  }}
                >
                  View
                </Button>
              </a>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
              <input
                type="file"
                className="file"
                accept="application/pdf,image/jpeg,image/png"
                ref={utilityEncodedBackRef}
                // disabled={showEdit}
                onChange={(e) =>
                  handleFileChange(e, "utilityEncodedStringBack")
                }
              />
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "150px",
                  height: "34px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  textAlign: "right",
                  letterSpacing: "-0.04em",
                  color: "#828282",
                }}
                onClick={(e) => handleFileSelect(e, utilityEncodedBackRef)}
              >
                Choose file
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <BlockSpacer mt={100} />
      <FooterCheckout
        proceedInfoButton={"Save"}
        // disabled={showEdit}
        // handleOpenSuccessMesage={handleOpenSuccessMesage}
        handleClose={() => handleSubmit()}
        isLoading={loading}
      />

      {open && (
        <OtpVerificationEmailModal
          open={open}
          handleClose={handleVerifyClose}
        />
      )}
      {openBvn && (
        <BvnSuccess
          open={openBvn}
          handleClose={handleBvnClose}
          handleChange={(firstName, lastName) =>
            setFormData({
              ...formData,
              firstName: firstName,
              lastName: lastName,
            })
          }
        />
      )}
    </Section>
  );
}

export default AddDirector;
