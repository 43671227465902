import Box from "@mui/material/Box";
import React from "react";
import SuccessMessage from "../../../../components/Cards/SuccessMessages";
import ConfettiIcon from "../../../../components/IconSvgs/ConfettiIcon";
import style from "./style.module.css";
import { useDispatch } from "react-redux";
import { clearSignUp } from "../../../../redux/slices/authSlice";

function SignupSuccess() {
  const dispatch = useDispatch();
  const Email = JSON.parse(localStorage.getItem("email"));
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <Box
          sx={{
            minWidth: {xs:312,sm:312,md:400,lg:400},
            maxWidth: 792,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 30px rgba(225, 234, 254, 0.62)",
            borderRadius: "20px",
          }}
        >
          <SuccessMessage
            title="Congratulations!"
            subTextStart={
              "Your account was created successfully. Please take a moment to verify your email address. We sent an email with a verification link to"
            }
            subTextHighlighted={Email}
            subTextEnd={
              "if you did not receive this in your inbox, please check your spam folder."
            }
            icon={<ConfettiIcon />}
            handleClose={()=>dispatch(clearSignUp())}
            route={"/auth/login"}
          />
        </Box>
      </div>
    </div>
  );
}

export default SignupSuccess;
