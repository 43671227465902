import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetLeaseProductTypeQuery = ({ productId }) => {
  const getLeaseProductType = async () => {
    const request =
      await axiosInstance.get(`/users${productId}/lease_product_type
    `);
    const response = request.data;
    return response;
  };
  const query = useQuery(
    ["LEASE_PRODUCT_TYPE", productId],
    getLeaseProductType
  );
  return query;
};

export default useGetLeaseProductTypeQuery;
