import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    EditTicket : {
    messageTitle: "",
    messageDescription: "",
    status:" "
    }


};


const editTicketSlice = createSlice({
    name:"editTicket",

    initialState,

    reducers:{
        editTicketActions: (state, action) => {
            state.EditTicket = action.payload
        },
    }





});

export const {editTicketActions} = editTicketSlice.actions;

export default editTicketSlice.reducer