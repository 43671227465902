import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
// import { COMPANY_USER_TYPES, LIST_OF_STATE } from "../../../constants";
import axios from "axios";
import dateFormat, { masks } from "dateformat";
import BlockSpacer from "../../BlockSpacer";
import FormAligner from "../../Hocs/FormAligner";
import BvnSuccessVerifcation from "../../Modals/BvnsucessVerifcation";
import OtpSuccessVerificationModal from "../../Modals/OtpSuccessVerificationModal";
import OtpVerificationModal from "../../Modals/OtpVerificationModal";
import SelectField from "../../Core/SelectField";
import FormLabel from "../Label";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../redux/slices/profileSlice";
import moment from "moment";
import { COMPANY_STATUS } from "../../../constants";

function CompanyDetailForm({
  defaultUserMethod,
  setDefaultUserMethod,
  info,
  setKycInfo,
  itemOptions,
}) {
  console.log(info);
  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };
  const dispatch = useDispatch();
  const { countries, states, companyIncl } = useSelector(
    (state) => state.profile
  );

  const compInclValues = companyIncl?.content[0]?.companyInclMainCategories;

  const [status, setStatus] = useState();
  const [boardMemGov, setBoardMemGov] = useState();
  const [busOpStructure, setBusOpStructure] = useState();
  const [compSize, setCompSize] = useState();
  const [annualTurnover, setAnnualTurnover] = useState();
  const [compOwnStructure, setCompOwnStructure] = useState();

  useEffect(() => {
    setKycInfo({
      ...info,
      company: {
        ...info.company,
        businessType: defaultUserMethod,
      },
      businessDetails: {
        ...info,
        businessType: defaultUserMethod,
      },
    });
  }, []);

  // const localState = JSON.parse(localStorage.getItem("states"));

  //Marital status
  console.log(states);
  const handleStateChange = (event) => {
    setDefaultState(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      businessDetails: {
        ...prev.businessDetails,
        stateId: states?.find((item) => item?.name === event.target.value)?.id,
      },
    }));
  };

  const boardMemGovList =
    compInclValues?.find((item) => item?.title === "BOARD_MEMBER_GOVERNANCE")
      ?.criteriaList || [];
  const boardMemGovOptions =
    boardMemGovList?.map((item) => item?.criteriaName) || [];
  // handle board member governance changes
  const handleBoardMemGovChange = (event) => {
    const { value } = event.target;
    setBoardMemGov(value);
    setKycInfo({
      ...info,
      contactPersonDetails: {
        ...info.contactPersonDetails,
        boardMemberGovernance: value,
      },
    });
  };

  const busOpStructList =
    compInclValues?.find(
      (item) => item?.title === "BUSINESS_OPERATION_STRUCTURE"
    )?.criteriaList || [];
  const busOpStructOptions =
    busOpStructList?.map((item) => item?.criteriaName) || [];
  // handle business operation structure
  const handleBusOpStructureChange = (event) => {
    const { value } = event.target;
    setBusOpStructure(value);
    setKycInfo({
      ...info,
      contactPersonDetails: {
        ...info.contactPersonDetails,
        operationStructure: value,
      },
    });
  };

  const compSizeList =
    compInclValues?.find((item) => item?.title === "COMPANY_SIZE")
      ?.criteriaList || [];
  const compSizeOptions = compSizeList?.map((item) => item?.criteriaName) || [];
  // handle company size
  const handleCompSizeChange = (event) => {
    const { value } = event.target;
    setCompSize(value);
    setKycInfo({
      ...info,
      contactPersonDetails: {
        ...info.contactPersonDetails,
        companySize: compSizeList?.find((item) => item?.criteriaName === value)
          ?.weight,
      },
    });
  };

  const annualTurnoverList =
    compInclValues?.find((item) => item?.title === "ANNUAL_TURNOVER")
      ?.criteriaList || [];
  const annualTurnoverOptions =
    annualTurnoverList?.map((item) => item?.criteriaName) || [];
  // handle annual turnover changes
  const handleAnnualTurnoverChange = (event) => {
    const { value } = event.target;
    setAnnualTurnover(value);
    setKycInfo({
      ...info,
      contactPersonDetails: {
        ...info.contactPersonDetails,
        annualTurnover: value,
      },
    });
  };

  const compOwnStructList =
    compInclValues?.find(
      (item) => item?.title === "COMPANY_OWNERSHIP_STRUCTURE"
    )?.criteriaList || [];
  const compOwnStructOptions =
    compOwnStructList?.map((item) => item?.criteriaName) || [];
  // handle annual turnover changes
  const handleCompOwnStructChange = (event) => {
    const { value } = event.target;
    setCompOwnStructure(value);
    setKycInfo({
      ...info,
      contactPersonDetails: {
        ...info.contactPersonDetails,
        ownershipStructure: value,
      },
    });
  };

  const busStatusList =
    compInclValues?.find((item) => item?.title === "COMPANY_STATUS")
      ?.criteriaList || [];
  const busStatusOptions =
    busStatusList?.map((item) => item?.criteriaName) || [];
  // handle business status changes
  const handleStatusChange = (event) => {
    const { value } = event.target;
    setStatus(value);
    setKycInfo((prev) => ({
      ...prev,
      contactPersonDetails: {
        ...prev.contactPersonDetails,
        businessStatus: event.target.value,
      },
    }));
  };

  //OTP
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [otp, setOtp] = React.useState("");

  const [defaultState, setDefaultState] = useState("");
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  const handleOpen = () => {
    setOpen(true);
    setOpenSuccess(false);
  };

  // const Options = localState.map((item) => {
  //   return { value: item.id, label: item.name };
  // });

  //Nationality
  // const handleNationalityChange = (event) => {
  //   setDefaultNationalityMethod(event.target.value);
  //   setKycInfo((prevState) => ({
  //     ...prevState,
  //     businessDetails: {
  //       ...prevState.businessDetails,
  //       countryId: countries?.find(item=> item?.name===defaultNationalityMethod)?.id
  //     },
  //   }));
  // };

  React.useEffect(() => {
    dispatch(getStates(1));
  }, []);

  const countryOptions = countries ? countries?.map((item) => item?.name) : [];

  const statesOptions = states ? states?.map((item) => item?.name) : [];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#E0E0E0" : "#E0E0E0",
      height: "55px",
      width: "160px",
      marginTop: "30px",
      border: "2px solid #E0E0E0",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#E0E0E0" : "#E0E0E0",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: "160px",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      width: "160px",
    }),
  };

  //function to handle user input from the previous values in the info object
  const handleUserInputs = (e) => {
    const { name, value } = e.target;
    if (name === "registrationNumber") {
      setKycInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          rcNumber: value,
        },
        businessDetails: {
          ...prev.businessDetails,
          [name]: value,
        },
      }));
    } else if (name === "address") {
      setKycInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          companyAddress: value,
        },
        businessDetails: {
          ...prev.businessDetails,
          [name]: value,
        },
      }));
    } else {
      setKycInfo((prev) => ({
        ...prev,
        businessDetails: {
          ...prev.businessDetails,
          [name]: value,
        },
      }));
    }
  };
  const handleDateInput = (e) => {
    setKycInfo((prev) => ({
      ...prev,
      businessDetails: {
        ...prev.businessDetails,
        dateOfRegisteration: moment(e.target.value, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),
      },
      company: {
        ...prev.company,
        dateOfInco: moment(e.target.value, "YYYY-MM-DD").format("DD-MM-YYYY"),
      },
    }));
  };

  //OTP Success
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  const handleSuccessOpen = () => {
    setOpenSuccess(true);
    setOpen(false);
  };

  const [openBvn, setOpenbvn] = React.useState(false);
  const handleBvnClose = () => setOpenbvn(false);

  console.log(info);
  return (
    <>
      <div>
        <div>
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"User Type"} />
                <TextField
                  type="text"
                  required
                  name="usertype"
                  sx={{ input: { background: "#e9ecef" } }}
                  value={info?.userType ?? ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8, alignItems: "flex-end" }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Company/Business Name"} />
                <TextField
                  placeholder="Optisoft"
                  type="text"
                  required
                  name="name"
                  sx={{ input: { background: "#e9ecef" } }}
                  value={info?.company?.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Registration Number"} />
                <TextField
                  placeholder="12345678"
                  type="text"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  required
                  name="registrationNumber"
                  value={info?.businessDetails?.registrationNumber}
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Date of Registration"} />
                <TextField
                  placeholder="Doe"
                  type="date"
                  required
                  name="dateOfRegisteration"
                  value={info?.businessDetails?.dateOfRegisteration}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                  onChange={handleDateInput}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Residing Country"} />
                <TextField
                  placeholder="Nigeria"
                  type="text"
                  required
                  name="country"
                  sx={{ input: { background: "#e9ecef" } }}
                  value={"Nigeria"}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Business Address"} asterisk />
                <TextField
                  placeholder=""
                  type=""
                  required
                  name="address"
                  value={info?.businessDetails?.address}
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"City"} />
                <TextField
                  placeholder=""
                  type="text"
                  required
                  name="city"
                  value={info?.businessDetails?.city}
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <SelectField
                  name={"State"}
                  method={defaultState}
                  changeAction={handleStateChange}
                  placeholder="Select State"
                  styles={customStyles}
                  items={statesOptions}
                  asterisk
                />
              </FormAligner>
            </Grid>
            {(info?.userType === "CORPORATE_LISTED" ||
              info?.userType === "CORPORATE_UNLISTED") && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SelectField
                  name={"Company Status"}
                  value={status}
                  items={busStatusOptions}
                  placeholder="Select Status"
                  method={status}
                  styles={customStyles}
                  changeAction={handleStatusChange}
                />
              </Grid>
            )}
          </Grid>
          {(info?.userType === "CORPORATE_LISTED" ||
            info?.userType === "CORPORATE_UNLISTED") && (
            <>
              <BlockSpacer />
              <Grid
                container
                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                columnSpacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <SelectField
                      name={"Annual Turnover"}
                      value={annualTurnover}
                      items={annualTurnoverOptions}
                      placeholder="Select Annual Turnover"
                      method={annualTurnover}
                      styles={customStyles}
                      changeAction={handleAnnualTurnoverChange}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <SelectField
                      name={"Company Size"}
                      value={compSize}
                      items={compSizeOptions}
                      placeholder="Select Company Size"
                      method={compSize}
                      styles={customStyles}
                      changeAction={handleCompSizeChange}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <SelectField
                      name={"Business Operation Structure"}
                      value={busOpStructure}
                      items={busOpStructOptions}
                      placeholder="Select Business Operation Structure"
                      method={busOpStructure}
                      styles={customStyles}
                      changeAction={handleBusOpStructureChange}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
            </>
          )}
          {(info?.userType === "CORPORATE_LISTED" ||
            info?.userType === "CORPORATE_UNLISTED") && (
            <>
              <BlockSpacer />
              <Grid
                container
                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                columnSpacing={2}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <SelectField
                      name={"Board Member Governance"}
                      value={boardMemGov}
                      items={boardMemGovOptions}
                      placeholder="Select Board Member governance"
                      method={boardMemGov}
                      styles={customStyles}
                      changeAction={handleBoardMemGovChange}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <SelectField
                      name={"Company Ownership Structure"}
                      value={compOwnStructure}
                      items={compOwnStructOptions}
                      placeholder="Select Company Ownership Structure"
                      method={compOwnStructure}
                      styles={customStyles}
                      changeAction={handleCompOwnStructChange}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
            </>
          )}
          <BlockSpacer />
        </div>
      </div>

      {open && (
        <OtpVerificationModal
          open={open}
          handleClose={handleClose}
          handleOtpChange={handleOtpChange}
          handleOpen={handleOpen}
          otp={otp}
          handleSuccessOpen={handleSuccessOpen}
        />
      )}
      {openSuccess && (
        <OtpSuccessVerificationModal
          open={openSuccess}
          handleClose={handleSuccessClose}
        />
      )}

      {openBvn && (
        <BvnSuccessVerifcation open={openBvn} handleClose={handleBvnClose} />
      )}
    </>
  );
}

export default CompanyDetailForm;
