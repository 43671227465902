import React from "react";
import Grid from "@mui/material/Grid";
import { Section } from "./style.js";
import TransactionCard from "../../components/TransactionCard";
import NotifationCard from "../../components/NotificationCard";
import AccountHight from "../../components/AccountHight";
import ReferalLink from "../../components/ReferalLink";
// import DashboardCompnentLayout from '../../components/Dashboard/Dashboard';
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/slices/profileSlice";
import TopBar from "../../components/TopBar/index.js";
import {
  switchPlatform,
  updateCreditUserType,
} from "../../redux/slices/switchPlatformSlice.js";

function Dashboard() {
  const dispatch = useDispatch();
  const buttonToggle = () => {
    dispatch(switchPlatform());
    // dispatch(updateCreditUserType());
  };
  const { login } = useSelector((state) => state?.auth);
  return (
    <>
      <TopBar />
      <Section>
        <h4 className="platformHeader">Rosabon Credit</h4>
        <div className="switchPlatformContain">
          <p>Start creating investment plans</p>
          <a
            href="https://rosabon-treasury.optisoft.com.ng/"
            // target="_blank"
            // rel="noreferrer"
          >
            <button>Switch to Treasury</button>
          </a>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TransactionCard />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NotifationCard />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <AccountHight />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ReferalLink />
          </Grid>
        </Grid>
      </Section>
    </>
  );
}

export default Dashboard;
