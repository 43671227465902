import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { 
  loginService,
  registerService,
  getUserTypesService,
  getCorporateTypesService,
  resetPasswordService,
} from "../../services/authServices";

const initialState = {
  loading: false,
  login: null,
  loginError: null,
	isAuth: null,
	isLoggedIn: false,
  register: null,
  isSignedUp: false,
  registerError: null,
  userTypes: null,
  userTypesError: null,
  corporateTypes: null,
  corporateTypesError: null,
  emailForgotPassword: null,
  resetPasswordMsg: null,
  resetPasswordErrMsg: null,
  // email: null
};

export const login = createAsyncThunk('auth/login', (formData, { rejectWithValue }) => {
  return loginService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const userSignup = createAsyncThunk('auth/userSignup', (formData, { rejectWithValue }) => {
  return registerService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
});

export const getUserTypes = createAsyncThunk('auth/getUserTypes',(payload, { rejectWithValue }) => {
  return getUserTypesService()
  .then(response=>response.data)
  .catch(error=> rejectWithValue(error.response))
});

export const getCorporateTypes = createAsyncThunk('auth/getCorporateTypes',(payload, { rejectWithValue }) => {
  return getCorporateTypesService()
  .then(response=>response.data)
  .catch(error=> rejectWithValue(error.response))
});

export const resetPassword = createAsyncThunk('auth/resetPassword',(payload, { rejectWithValue }) => {
  return resetPasswordService()
  .then(response=>response.data)
  .catch(error=> rejectWithValue(error.response))
});


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearSignUp(state) {
      state.isSignedUp = false
    },
    userLogout(state){
      state.isAuth = false
    },
    forgotPasswordEmail(state,email) {
      state.emailForgotPassword = email
    },
  },
  extraReducers: builder => {
    // User login pending
    builder.addCase(login.pending, state => {
      state.loading = true
      state.isAuth = false
      state.isLoggedIn = false
      state.login = null
      state.loginError = null
    })
    // User login success
    builder.addCase(login.fulfilled, (state, action) => {
      localStorage.setItem("token", action.payload?.token);
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("loggedInUser", JSON.stringify(action.payload?.email));
      toast.success("Login Successful");
      state.loading = false
      state.isAuth = true
      state.isLoggedIn = true
      state.login = action.payload
      state.loginError = null
    })
    // User login fail
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.isAuth = false
      state.isLoggedIn = false
      state.login = null
      state.loginError = action.payload?.data
      toast.error(action.payload?.data?.message);
    })
    // User sign up pending
    builder.addCase(userSignup.pending, (state) => {
      state.loading = true
      state.register = null
      state.isSignedUp = false
      state.registerError = null
    })
    // User signup success
    builder.addCase(userSignup.fulfilled, (state, action) => {
      state.loading = false
      state.register = action.payload
      state.isSignedUp = true
      state.registerError = null
      localStorage.setItem("email", JSON.stringify(action.payload?.email));
      toast.success("Registration successful");
    })
    // User signup failed
    builder.addCase(userSignup.rejected, (state, action) => {
      state.loading = false
      state.register = null
      state.isSignedUp = false
      state.registerError = action?.error
      console.log("some", action)
      toast.error(action.payload?.data?.message);
    })
    // get user types pending
    builder.addCase(getUserTypes.pending, (state) => {
      state.loading = true
    })
    // get user types success
    builder.addCase(getUserTypes.fulfilled, (state, action) => {
      state.loading = false
      state.userTypes = action.payload
      state.userTypesError = null
    })
    // get user types failed
    builder.addCase(getUserTypes.rejected, (state, action) => {
      state.loading = false
      state.userTypes = null
      state.userTypesError = action.error
    })
    // get corporate types pending
    builder.addCase(getCorporateTypes.pending, (state) => {
      state.loading = true
    })
    // get corporate types success
    builder.addCase(getCorporateTypes.fulfilled, (state, action) => {
      state.loading = false
      state.corporateTypes = action.payload
      state.corporateTypesError = null
    })
    // get corporate types failed
    builder.addCase(getCorporateTypes.rejected, (state, action) => {
      state.loading = false
      state.corporateTypes = null
      state.corporateTypesError = action.error
    })
    // reset password pending
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true
    })
    // reset password success
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false
      state.resetPasswordMsg = action.payload
      state.resetPasswordErrMsg = null
    })
    // reset password failed
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false
      state.resetPasswordMsg = null
      state.resetPasswordErrMsg = action.error
    })
  }
});

export default authSlice.reducer
export const { clearSignUp, userLogout, forgotPasswordEmail } = authSlice.actions;