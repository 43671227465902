import React from "react";

function NanoImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="94"
      fill="none"
      viewBox="0 0 95 94"
    >
      <rect width="95" height="93" y="0.246" fill="#D9D9D9" rx="5"></rect>
      <path
        fill="#B5B5B5"
        d="M65.434 57.848c-18.842-12.899-12.764-6.756-36.468 17.2C18.554 85.57 13.773 89.66 11.948 90.633L94 92.246V64.605c-3.242 2.047-13.493 3.563-28.566-6.757z"
      ></path>
    </svg>
  );
}

export default NanoImage;