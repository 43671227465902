import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getWalletBalanceService,
  getWalletTransactionsService,
  getMyReferralsService,
  getMyReferralActivitiesService,
  fundByBankAccountService,
  fundByDebitCardService,
  getReferralRedeemThresholdService,
  redeemReferralBonusService,
  redeemSpecialEarningService,
  getSpecialEarningActivitiesService,
  getTotalEarningService,
  getTotalRedeemedEarningService,
  withdrawToBankService,
  getWithdrawalReasonsService,
  transferToTreasuryService,
} from "../../services/walletServices";

const initialState = {
  loading: false,
  walletBalance: null,
  walletBalanceError: null,
  walletTransactions: null,
  walletTransError: null,
  withdrawMsg: null,
  withdrawMsgError: null,
  transaction: null,
  transactionError: null,
  myReferrals: null,
  myReferralsError: null,
  transferMsg: null,
  transferMsgError: null,
  pokeMsg: null,
  pokeMsgError: null,
  refActivities: null,
  refActivitiesError: null,
  refRedeemMsg: null,
  refRedeemError: null,
  depositActivites: null,
  depositActivitesError: null,
  referralThreshold: null,
  referralThresholdError: null,
  redeemEarningMsg: null,
  redeemEarningError: null,
  specialEarnings: null,
  specialEarningsError: null,
  totalEarning: null,
  totalEarningError: null,
  totalRedeemedEarning: null,
  totalRedeemedEarningError: null,
  debitFund: null,
  debitFundErr: null,
  bankFund: null,
  bankFundErr: null,
  withdrawReasons: null,
  withdrawReasonsErr: null,
  treasuryTransferMsg: null,
  treasuryTransferErr: null,
};

export const getWalletBalance = createAsyncThunk(
  "wallet/getWalletBalance",
  (formData, { rejectWithValue }) => {
    return getWalletBalanceService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getWalletTransactions = createAsyncThunk(
  "wallet/getWalletTransactions",
  (formData, { rejectWithValue }) => {
    return getWalletTransactionsService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getMyReferrals = createAsyncThunk(
  "wallet/getMyReferrals",
  (formData, { rejectWithValue }) => {
    return getMyReferralsService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getMyReferralActivities = createAsyncThunk(
  "wallet/getMyReferralActivities",
  (formData, { rejectWithValue }) => {
    return getMyReferralActivitiesService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const fundByBankAccount = createAsyncThunk(
  "wallet/fundByBankAccount",
  (payload, { rejectWithValue }) => {
    return fundByBankAccountService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const fundByDebitCard = createAsyncThunk(
  "wallet/fundByDebitCard",
  (formData, { rejectWithValue }) => {
    return fundByDebitCardService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getReferralRedeemThreshold = createAsyncThunk(
  "wallet/getReferralRedeemThreshold",
  (formData, { rejectWithValue }) => {
    return getReferralRedeemThresholdService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const redeemReferralBonus = createAsyncThunk(
  "wallet/redeemReferralBonus",
  (formData, { rejectWithValue }) => {
    return redeemReferralBonusService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const redeemSpecialEarning = createAsyncThunk(
  "wallet/redeemSpecialEarning",
  (formData, { rejectWithValue }) => {
    return redeemSpecialEarningService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getSpecialEarningActivities = createAsyncThunk(
  "wallet/getSpecialEarningActivities",
  (formData, { rejectWithValue }) => {
    return getSpecialEarningActivitiesService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getTotalEarning = createAsyncThunk(
  "wallet/getTotalEarning",
  (formData, { rejectWithValue }) => {
    return getTotalEarningService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getTotalRedeemedEarning = createAsyncThunk(
  "wallet/getTotalRedeemedEarning",
  (_, { rejectWithValue }) => {
    return getTotalRedeemedEarningService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const withdrawToBank = createAsyncThunk(
  "wallet/withdrawToBank",
  (formData, { rejectWithValue }) => {
    return withdrawToBankService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getWithdrawalReasons = createAsyncThunk(
  "wallet/getWithdrawalReasons",
  (formData, { rejectWithValue }) => {
    return getWithdrawalReasonsService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const transferToTreasury = createAsyncThunk(
  "wallet/transferToTreasury",
  (formData, { rejectWithValue }) => {
    return transferToTreasuryService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearWithdrawMsgs(state) {
      state.withdrawMsg = null;
      state.withdrawMsgError = null;
    },
    clearTransferMsgs(state) {
      state.treasuryTransferMsg = null;
      state.treasuryTransferErr = null;
    },
  },
  extraReducers: (builder) => {
    // get wallet balance pending
    builder.addCase(getWalletBalance.pending, (state) => {
      state.loading = true;
    });
    // get wallet balance success
    builder.addCase(getWalletBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.walletBalance = action.payload;
      state.walletBalanceError = null;
    });
    // get wallet balance failed
    builder.addCase(getWalletBalance.rejected, (state, action) => {
      state.loading = false;
      state.walletBalance = null;
      state.walletBalanceError = action.error;
    });
    // get wallet transactions pending
    builder.addCase(getWalletTransactions.pending, (state) => {
      state.loading = true;
    });
    // get wallet transactions success
    builder.addCase(getWalletTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.walletTransactions = action.payload;
      state.walletTransError = null;
    });
    // get wallet transactions failed
    builder.addCase(getWalletTransactions.rejected, (state, action) => {
      state.loading = false;
      state.walletTransactions = null;
      state.walletTransError = action.error;
    });
    // get my referrals pending
    builder.addCase(getMyReferrals.pending, (state) => {
      state.loading = true;
    });
    // get my referrals success
    builder.addCase(getMyReferrals.fulfilled, (state, action) => {
      state.loading = false;
      state.myReferrals = action.payload;
      state.myReferralsError = null;
    });
    // get my referrals failed
    builder.addCase(getMyReferrals.rejected, (state, action) => {
      state.loading = false;
      state.myReferrals = null;
      state.myReferralsError = action.error;
    });
    // get my referrals activities pending
    builder.addCase(getMyReferralActivities.pending, (state) => {
      state.loading = true;
    });
    // get my referrals activities success
    builder.addCase(getMyReferralActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.refActivities = action.payload;
      state.refActivitiesError = null;
    });
    // get my referrals activities failed
    builder.addCase(getMyReferralActivities.rejected, (state, action) => {
      state.loading = false;
      state.refActivities = null;
      state.refActivitiesError = action.error;
    });
    // fund by bank account pending
    builder.addCase(fundByBankAccount.pending, (state) => {
      state.loading = true;
    });
    // fund by bank account success
    builder.addCase(fundByBankAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.bankFund = action.payload;
      state.bankFundErr = null;
    });
    // fund by bank account failed
    builder.addCase(fundByBankAccount.rejected, (state, action) => {
      state.loading = false;
      state.bankFund = null;
      state.bankFundErr = action.error;
    });
    // fund by debit card pending
    builder.addCase(fundByDebitCard.pending, (state) => {
      state.loading = true;
    });
    // fund by debit card success
    builder.addCase(fundByDebitCard.fulfilled, (state, action) => {
      state.loading = false;
      state.debitFund = action.payload;
      state.debitFundErr = null;
    });
    // fund by debit card failed
    builder.addCase(fundByDebitCard.rejected, (state, action) => {
      state.loading = false;
      state.debitFund = null;
      state.debitFundErr = action.error;
    });
    // get referral redeem threshold pending
    builder.addCase(getReferralRedeemThreshold.pending, (state) => {
      state.loading = true;
    });
    // get referral redeem threshold success
    builder.addCase(getReferralRedeemThreshold.fulfilled, (state, action) => {
      state.loading = false;
      state.referralThreshold = action.payload;
      state.referralThresholdError = null;
    });
    // get referral redeem threshold failed
    builder.addCase(getReferralRedeemThreshold.rejected, (state, action) => {
      state.loading = false;
      state.referralThreshold = null;
      state.referralThresholdError = action.error;
    });
    // redeem referral bonus pending
    builder.addCase(redeemReferralBonus.pending, (state) => {
      state.loading = true;
    });
    // redeem referral bonus success
    builder.addCase(redeemReferralBonus.fulfilled, (state, action) => {
      state.loading = false;
      state.refRedeemMsg = action.payload;
      state.refRedeemError = null;
    });
    // redeem referral bonus failed
    builder.addCase(redeemReferralBonus.rejected, (state, action) => {
      state.loading = false;
      state.refRedeemMsg = null;
      state.refRedeemError = action.error;
    });
    // redeem special earning pending
    builder.addCase(redeemSpecialEarning.pending, (state) => {
      state.loading = true;
    });
    // redeem special earning success
    builder.addCase(redeemSpecialEarning.fulfilled, (state, action) => {
      state.loading = false;
      state.redeemEarningMsg = action.payload;
      state.redeemEarningError = null;
    });
    // redeem special earning failed
    builder.addCase(redeemSpecialEarning.rejected, (state, action) => {
      state.loading = false;
      state.redeemEarningMsg = null;
      state.redeemEarningError = action.error;
    });
    // get special earning activities pending
    builder.addCase(getSpecialEarningActivities.pending, (state) => {
      state.loading = true;
    });
    // get special earning activities success
    builder.addCase(getSpecialEarningActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.specialEarnings = action.payload;
      state.specialEarningsError = null;
    });
    // get special earning activities failed
    builder.addCase(getSpecialEarningActivities.rejected, (state, action) => {
      state.loading = false;
      state.specialEarnings = null;
      state.specialEarningsError = action.error;
    });
    // get total earnings pending
    builder.addCase(getTotalEarning.pending, (state) => {
      state.loading = true;
    });
    // get total earnings success
    builder.addCase(getTotalEarning.fulfilled, (state, action) => {
      state.loading = false;
      state.totalEarning = action.payload;
      state.totalEarningError = null;
    });
    // get total earnings failed
    builder.addCase(getTotalEarning.rejected, (state, action) => {
      state.loading = false;
      state.totalEarning = null;
      state.totalEarningError = action.error;
    });
    // get total redeemed earning pending
    builder.addCase(getTotalRedeemedEarning.pending, (state) => {
      state.loading = true;
    });
    // get total redeemed earning success
    builder.addCase(getTotalRedeemedEarning.fulfilled, (state, action) => {
      state.loading = false;
      state.totalRedeemedEarning = action.payload;
      state.totalRedeemedEarningError = null;
    });
    // get total redeemed earning failed
    builder.addCase(getTotalRedeemedEarning.rejected, (state, action) => {
      state.loading = false;
      state.totalRedeemedEarning = null;
      state.totalRedeemedEarningError = action.error;
    });
    // withdraw to bank pending
    builder.addCase(withdrawToBank.pending, (state) => {
      state.loading = true;
    });
    // withdraw to bank success
    builder.addCase(withdrawToBank.fulfilled, (state, action) => {
      state.loading = false;
      state.withdrawMsg = action.payload;
      state.withdrawMsgError = null;
    });
    // withdraw to bank failed
    builder.addCase(withdrawToBank.rejected, (state, action) => {
      state.loading = false;
      state.withdrawMsg = null;
      state.withdrawMsgError =
        action.payload.data.errorMessage ?? action.payload.data.message;
    });
    // get withdrawal reasons pending
    builder.addCase(getWithdrawalReasons.pending, (state) => {
      state.loading = true;
    });
    // get withdrawal reasons success
    builder.addCase(getWithdrawalReasons.fulfilled, (state, action) => {
      state.loading = false;
      state.withdrawReasons = action.payload;
      state.withdrawReasonsErr = null;
    });
    // get withdrawal reasons failed
    builder.addCase(getWithdrawalReasons.rejected, (state, action) => {
      state.loading = false;
      state.withdrawReasons = null;
      state.withdrawReasonsErr = action.error;
    });
    // transfer to treasury pending
    builder.addCase(transferToTreasury.pending, (state) => {
      state.loading = true;
    });
    // transfer to treasury success
    builder.addCase(transferToTreasury.fulfilled, (state, action) => {
      state.loading = false;
      state.treasuryTransferMsg = action.payload;
      state.treasuryTransferErr = null;
    });
    // transfer to treasury failed
    builder.addCase(transferToTreasury.rejected, (state, action) => {
      state.loading = false;
      state.treasuryTransferMsg = null;
      state.treasuryTransferErr = action.error;
      console.log("ippees", action.payload);
      toast.error("Transfer not successfull");
    });
  },
});

export default walletSlice.reducer;
export const { clearWithdrawMsgs, clearTransferMsgs } = walletSlice.actions;
