import React, { useEffect } from "react";
import {
  Section,
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  Title,
  subTitle,
  contentTitle,
} from "./style.js";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoan,
  getLoanProducts,
} from "../../../redux/slices/getCreditSlice.js";

function LeaseCredit() {
  const { users } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const userType = users?.userType;
  const userSector = users?.individualUser?.creditEmploymentDetail?.sector;

  const { products } = useSelector((state) => state.getCredit);

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(clearLoan());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={Title}>
          Lease
        </Typography>
        <Typography variant="p" sx={subTitle}>
          {userType === "Salary Earner (Owns a Side Business)"
            ? "Salary Earner (Owns a Side Business)"
            : userType === "Pensioner"
            ? "Pensioner"
            : userType === "Student"
            ? "Student (Post-secondary)"
            : userType === "Salary Earner (With No Side Business)"
            ? "Salary Earner (No Side Business)"
            : userType === "SOLE_PROPRIETOR_REGISTERED" ||
              userType === "SOLE_PROPRIETOR_UNREGISTERED"
            ? "(Sole Proprietor)"
            : userType === "CORPORATE_LISTED" ||
              userType === "CORPORATE_UNLISTED"
            ? "(Corporate Public Listed / Unlisted)"
            : ""}
        </Typography>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>
                    <Typography sx={contentTitle}>Finance Lease</Typography>
                  </div>
                </AccordionSummary>
                {products &&
                products?.filter(
                  (item) =>
                    item?.creditProductLine === "LEASE" &&
                    (item?.productCode === "FINANCE_LEASE_INDIVIDUAL" ||
                      item?.productCode === "FINANCE_LEASE_SOLE_PROPRIETOR" ||
                      item?.productCode === "FINANCE_LEASE_LISTED_UNLISTED") &&
                    item?.targetMarkets?.some((item) => item.name === userType)
                )?.length > 0 ? (
                  products?.map(
                    (item, id) =>
                      item?.creditProductLine === "LEASE" &&
                      (item?.productCode === "FINANCE_LEASE_INDIVIDUAL" ||
                        item?.productCode === "FINANCE_LEASE_SOLE_PROPRIETOR" ||
                        item?.productCode ===
                          "FINANCE_LEASE_LISTED_UNLISTED") &&
                      item?.targetMarkets?.some(
                        (item) => item.name === userType
                      ) && (
                        <Paper
                          elevation={0.5}
                          key={id}
                          sx={{
                            width: { xs: "448px", lg: "448px" },
                            maxWidth: "100%",
                            minHeight: { sx: "400px", lg: "213px" },
                            boxShadow:
                              " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                            marginTop: 0,
                            marginBottom: "2rem",
                            marginLeft: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "95%",
                            }}
                          >
                            <Box>
                              <Typography sx={QuickNanoLoan}>
                                {item?.productName}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={callToActionCard}>
                            <Box>
                              <NanoImage />
                            </Box>
                            <div>
                              {item?.productSummary
                                ?.split(".")
                                ?.map((describe, id) => (
                                  <Typography sx={paragraphTitle} key={id}>
                                    {describe}
                                  </Typography>
                                ))}

                              <Link
                                to={
                                  userType === "SOLE_PROPRIETOR_REGISTERED" ||
                                  userType === "SOLE_PROPRIETOR_UNREGISTERED"
                                    ? `/finance-lease/${item?.id}?erpProductId=${item.erpProductId}`
                                    : `/finance-lease/${item?.id}?erpProductId=${item.erpProductId}`
                                }
                              >
                                <Button
                                  sx={{
                                    background: "#F2F2F2",
                                    marginTop: "1rem",
                                    width: "144px",
                                    height: "42px",
                                    color: "#111E6C",
                                    fontFamily: "Montserrat",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    letterSpacing: "-0.03em",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Request
                                </Button>
                              </Link>
                            </div>
                          </Box>
                        </Paper>
                      )
                  )
                ) : (
                  <Paper
                    elevation={0.5}
                    sx={{
                      width: { xs: "448px", lg: "448px" },
                      maxWidth: "100%",
                      height: { sx: "100px", lg: "100px" },
                      boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "95%",
                      }}
                    >
                      <Box>
                        <Typography sx={QuickNanoLoan}>
                          No Product Available
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                )}
                {/* <Paper
                  elevation={0.5}
                  sx={{
                    width: { xs: "448px", lg: "448px" },
                    maxWidth: "100%",
                    height: { sx: "400px", lg: "174px" },
                    boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                    borderRadius: "8px",
                    margin: "1rem",
                  }}
                >
                  <Box sx={callToActionCard}>
                    <Box style={{ paddingTop: "1rem" }}>
                      <NanoImage />
                    </Box>
                    <div style={{ paddingTop: "1rem" }}>
                      <Typography sx={paragraphTitle}>
                        . Lorem ipsum dolor sit amet consectetur.
                      </Typography>
                      <Typography sx={paragraphTitle}>
                        . Lorem ipsum dolor sit amet consectetur.
                      </Typography>
                      <Typography sx={paragraphTitle}>
                        . Lorem ipsum dolor sit amet consectetur.
                      </Typography>
                      <Link to="/finance-lease">
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            marginTop: "1rem",
                            width: "144px",
                            height: "42px",
                            color: "#111E6C",
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Request
                        </Button>
                      </Link>
                    </div>
                  </Box>
                </Paper> */}
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Section>
      <BlockSpacer mt={80} />
      <Paper
        elevation={0.5}
        sx={{
          height: { xs: "15%", lg: "15%" },
          width: { xs: "92%", sm: "100%", lg: "100%" },
          margin: "1rem",
          marginLeft: { lg: "-2rem" },
          marginRight: { lg: "auto" },
          maxwidth: "100%",
          boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
        }}
      >
        {" "}
        <Link to="/get-credit">
          <Button
            sx={{
              background: "#111E6C",
              width: { xs: "300px", lg: "328px" },
              height: "54px",
              color: "white",
              maxWidth: "100%",
              marginBottom: { xs: "1rem" },
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "22px",
              textTransform: "capitalize",
            }}
          >
            Back
          </Button>
        </Link>
      </Paper>
    </>
  );
}

export default LeaseCredit;
