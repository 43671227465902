import React from "react";
import Button from "../../Buttons";
import style from "./style.module.css";
function DoubleRegisterButtons({
  individualSignup,
  setIndividualSignup,
  wrap,
}) {
  return (
    <div
      className={style.button}
      style={{
        flexWrap: wrap ? "wrap" : "nowrap",
        justifyContent: wrap ? "center" : "",

      }}
    >
      <Button
        color={
          wrap
            ? individualSignup
              ? "primary"
              : "tertiary"
            : individualSignup
              ? "tertiary"
              : "neutral"
        }
        width={"248px"}
        height={"54px"}
        onClick={setIndividualSignup}

      >
        Register as an Individual
      </Button>
      <Button
        color={
          wrap
            ? individualSignup
              ? "tertiary"
              : "primary"
            : individualSignup
              ? "neutral"
              : "tertiary"
        }
        width={"248px"}
        height={"54px"}
        onClick={setIndividualSignup}
      >
        Register as a Corporate
      </Button>
    </div>
  );
}

export default DoubleRegisterButtons;
