import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import HomeIcon from "../Assets/icons/Category.svg";
import CreditIcon from "../IconSvgs/CreditIcon.jsx";
import HelpmeIcon from "../IconSvgs/helpmeIcon.jsx";
import MoneyIcon from "../IconSvgs/MoneyIcon.jsx";
import TombupIcon from "../IconSvgs/TombupIcons.jsx";
import WalletIcon from "../IconSvgs/WalletIcon.jsx";
import { Section } from "./style.js";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

function SideBar() {
  const [currentLink, setCurrentLink] = useState(1);
  const [openFeedback, setOpenFeedback] = useState(false);
  return (
    <>
      <Section>
        <div className="top">
          <div className="toggle"></div>
          <div className="links">
            <ul>
              <li>
                <NavLink to="/dashboard" className="nav-item">
                  <img src={HomeIcon} alt="" srcset="" />
                  <span> Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/get-credit" className="nav-item">
                  <CreditIcon />
                  <span> Get Credit</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-credit" className="nav-item">
                  <MoneyIcon />
                  <span> My Credit</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/wallet" className="nav-item">
                  <WalletIcon />
                  <span> Wallet</span>
                </NavLink>
              </li>
              <li
                onClick={() => {
                  setOpenFeedback(!openFeedback);
                }}
                style={{boxShadow:"none"}}
              >
                <Accordion 
                  sx={{ 
                    outline: "none", 
                    boxShadow: "none", 
                    border: "none", 
                    padding:  0, 
                    margin: 0,
                    width: "100%",
                  }}
                  style={{boxShadow:"none"}}
                >
                  <AccordionSummary
                    sx={{ padding: 0, margin: 0, width: "100%",boxShadow: "none"}}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <NavLink 
                      to="/feedback" 
                      className="feedback-nav-item"
                    >
                      <TombupIcon />
                      <span> Feedback</span>
                    </NavLink>
                  </AccordionSummary>
                  <AccordionDetails sx={{paddingLeft: "0", marginLeft:0, boxShadow: "none", }} >
                    <ul>
                      <li style={{marginBottom:10}} >
                        <Link to="/feedback/my-tickets" className="feedback-nav-item">
                          <span>My Tickets</span>
                        </Link>
                      </li>
                      <li style={{marginBottom:10}} >
                        <Link to="/feedback/open-tickets" className="feedback-nav-item">
                          <span>My Open Tickets</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/feedback/closed-tickets" className="feedback-nav-item">
                          <span>My Closed Tickets</span>
                        </Link>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                
              </li>
              
              <li>
                <NavLink to="/help" className="nav-item">
                  <HelpmeIcon />
                  <span> Help</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </>
  );
}

export default SideBar;
