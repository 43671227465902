import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { useFormik } from "formik";
import { textheading, smallText } from "./style";
import { useDispatch, useSelector } from "react-redux";
import BlockSpacer from "../../../BlockSpacer";
import SelectField from "../../../Core/SelectField";
import FormAligner from "../../../Hocs/FormAligner";
import Buttons from "../../../Buttons";
import FormLabel from "../../Label";
import {
  verifyPhone,
  closeModal,
} from "../../../../redux/slices/updateProfileSlice";
import { getStates } from "../../../../redux/slices/profileSlice";
import OtpVerificationModal from "../../../Modals/OtpVerificationModal";
import OtpSuccessVerificationModal from "../../../Modals/OtpSuccessVerificationModal";
import useScreenSize from "../../../ScreenSize";

function ProfileContactDetails({
  profileForm,
  handleForm,
  handleSendOtp,
  setCurrentEdit,
  showEdit,
  removeEdit,
  handleSubmit
}) {
  const dispatch = useDispatch();
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [defaultCountryMethod, setDefaultCountryMethod] = useState("Nigeria");
  const [defaultState, setDefaultState] = useState("");
  const { values, errors, touched, handleChange, handleBlur } = useFormik({
    initialValues: {
      houseNoAddress: "",
      state: "",
      secondaryPhoneNumber: "",
      country: "",
      countryId: "",
      city: "",
    },
    // validationSchema: signUpIndividualValidation,
    onSubmit: (values) => {},
  });
  const { width } = useScreenSize();

  useEffect(() => {
    setDefaultState(profileForm?.individualUser.contactAddress.state);
  }, []);

  const { showPhoneOtpModal, phoneMsg, validatePhone } = useSelector(
    (state) => state.updateProfile
  );

  const { states } = useSelector((state) => state.profile);

  React.useEffect(() => {}, [showPhoneOtpModal]);

  const statesOptions = states ? states?.map((item) => item?.name) : [];
  //State Changes
  const handleStateChange = (event) => {
    setDefaultState(event.target.value);
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        contactAddress: {
          ...profileForm?.individualUser?.contactAddress,
          state: event.target.value,
        },
      },
    });
  };

  React.useEffect(() => {
    dispatch(getStates(parseInt(1)));
  }, []);

  const closeSuccessModal = () => {
    setPhoneVerified(false);
    dispatch(closeModal());
  };

  React.useEffect(() => {
    if (validatePhone?.status && showPhoneOtpModal) {
      setPhoneVerified(true);
    }
  }, [validatePhone]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "green" : "green",
      height: "55px",
      width: "160px",
      marginTop: "30px",
      border: "2px solid green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "green" : "green",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: "160px",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      width: "160px",
    }),
  };

  const handleUserInput = (e) => {
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm.individualUser,
        contactAddress: {
          ...profileForm?.individualUser?.contactAddress,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleOTPModalClose = () => {
    dispatch(closeModal());
  };

  const addressDet = profileForm?.individualUser?.contactAddress;

  const verifyPhoneNumber = () => {
    // console.log("val", JSON.stringify(values.secondaryPhoneNumber));
    // let secondPhone = JSON.stringify(values.secondaryPhoneNumber).startsWith("0")
    // ? JSON.stringify(values.secondaryPhoneNumber).trim()
    // : JSON.stringify(values.secondaryPhoneNumber).startsWith("234")
    // ? JSON.stringify(values.secondaryPhoneNumber).replace(/234/, "0")
    // : "0" + JSON.stringify(values.secondaryPhoneNumber)
    dispatch(verifyPhone(addressDet?.secondaryPhoneNumber));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignItems="center">
          <Typography sx={textheading}>Contact Details</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} alignItems="center">
          <Box>
            {!showEdit.includes(2) ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width <= 700 ? "60px" : "72px"}
                    fontSize={width <= 700 && "14px"}
                    onClick={() => {
                      handleSendOtp();
                      setCurrentEdit(2);
                    }}
                  >
                    Edit
                  </Buttons>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="tertiary"
                    width={width <= 700 ? "60px" : "72px"}
                    fontSize={width <= 700 && "14px"}
                    onClick={() => {
                      removeEdit(2);
                      handleSubmit();
                    }}
                  >
                    Save
                  </Buttons>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
      <BlockSpacer />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormAligner>
            <FormLabel text={"Secondary Phone Number"} asterisk />
            <TextField
              placeholder=""
              type="text"
              required
              name="secondaryPhoneNumber"
              disabled={!showEdit.includes(2)}
              onBlur={handleBlur}
              value={addressDet?.secondaryPhoneNumber}
              onChange={(e) => handleUserInput(e)}
            />
          </FormAligner>
          <Typography sx={smallText}>
            Please provide your most active phone number here in case this is
            different from your primary phone number
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          {width > 700 && <BlockSpacer mt={31} />}
          <Buttons
            color="primary"
            onClick={verifyPhoneNumber}
            fontSize={width <= 700 && "14px"}
            disabled={!showEdit.includes(2)}
          >
            Verify
          </Buttons>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* <SelectField 
            name={"Country of Residence"} 
            method={defaultCountryMethod}
            disabled
          /> */}
          <FormAligner>
            <FormLabel text={"Country of Residence"} />
            <TextField
              type="text"
              required
              sx={{ input: { background: "#e9ecef" } }}
              name="country of residence"
              value={`Nigeria`}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormAligner>
          {/* <FormAligner>
            <FormLabel text={"Middle name"} />
            <TextField
                placeholder="bilaowei"
                type="text"
                required
                name="name"
            />
        </FormAligner> */}
        </Grid>
      </Grid>

      <BlockSpacer mt={35} />

      <Grid conatainer spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormLabel text={"Home Address"} asterisk />
          <FormAligner>
            <TextField
              placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
              type="text"
              required
              disabled={!showEdit.includes(2)}
              name="homeAddress"
              value={addressDet?.homeAddress}
              onChange={(e) => handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <SelectField
              name={"State"}
              method={defaultState}
              changeAction={handleStateChange}
              disabled={!showEdit.includes(2)}
              placeholder="Select State"
              styles={customStyles}
              items={statesOptions}
              asterisk
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormLabel text={"City"} asterisk />
          <FormAligner>
            <TextField
              placeholder="City"
              type="text"
              disabled={!showEdit.includes(2)}
              required
              name="city"
              value={addressDet?.city}
              onChange={(e) => handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormLabel text={"Nationality"} asterisk />
          <FormAligner>
            <TextField
              placeholder="Nationality"
              type="text"
              disabled={!showEdit.includes(2)}
              required
              name="nationality"
              value={addressDet?.nationality}
              onChange={(e) => handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={35} />
      <Grid conatainer spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormLabel text={"Street Address"} asterisk />
          <FormAligner>
            <TextField
              placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
              type="text"
              required
              disabled={!showEdit.includes(2)}
              name="streetAddress"
              value={addressDet?.streetAddress}
              onChange={(e) => handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
      </Grid>
      <OtpVerificationModal
        open={showPhoneOtpModal}
        handleClose={handleOTPModalClose}
        secondPhone={profileForm?.secondaryPhoneNumber}
        handleOtpChange
        otpData={phoneMsg?.data?.otp}
        otp
        // handleSuccessOpen={}
      />
      <OtpSuccessVerificationModal
        open={phoneVerified}
        handleClose={closeSuccessModal}
      />
    </>
  );
}

export default ProfileContactDetails;
