import React,{ useEffect } from "react";
import style from "../style.module.css";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  styled,
  tableCellClasses
} from "@mui/material";
import { Section } from "./style";
import { useState } from "react";
import BlockSpacer from "../../../components/BlockSpacer";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { 
  getSpecialEarningActivities,
  redeemSpecialEarning,
  getTotalEarning,
  getTotalRedeemedEarning 
} from "../../../redux/slices/walletSlice";
import TopBar from "../../../components/WalletTopBar";
import Paginate from "../../../components/Paginate";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";

function Rosabon() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { width } = useScreenSize();

  const handleClick = () => {
    dispatch(redeemSpecialEarning());
  };

  useEffect(() => {
    dispatch(getSpecialEarningActivities());
    dispatch(getTotalEarning());
    dispatch(getTotalRedeemedEarning());
  }, [dispatch]);

  const nextPage = () => {
    setCurrentPage(currentPage+1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage-1);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.grey,
      color: theme.palette.common.black,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const { specialEarnings, totalEarning, totalRedeemedEarning } = useSelector(
    (state) => state.wallet
  );
  const earningsActivities = specialEarnings?.entities
    ? specialEarnings?.entities
    : [];

  const totalTransactions = Math.ceil((earningsActivities?.length)/10);
  const currentTransactions = currentPage === totalTransactions ? 
  earningsActivities?.slice(((currentPage-1)*10),earningsActivities?.length) :
  earningsActivities?.slice(((currentPage-1)*10), (currentPage*10))

  const navigate = useNavigate();

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              mb:2,
              mt:1,
              display:"flex",
              alignItems: "center",
              gap: 2,
              paddingLeft: {xs:"1rem",sm:"1rem",md:0,lg:0}
            }}
          >
            <Box sx={{display:{md:"none",lg:"none",xs:"flex",sm:"flex"}}} >
              <Link to="/wallet" >
                <ArrowBackIosNew />
              </Link>
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "20px",
                lettSpacing: "-0.03em",
              }}
            >
              Rosabon Special Earnings
            </Typography>
          </Box>
          <BlockSpacer mt={30} />
          <Box
            display={"flex"}
            sx={{ 
              justifyContent: "space-between", 
              maxWidth: {xs:"100%",sm:"100%",md:"72%",lg:"72%"},
              flexDirection: {xs:"column",sm:"column",md:"row",lg:"row"},
              gap: "18px",
            }}
          >
            <Paper
              elevation={0.5}
              sx={{
                width: "270px",
                height: "157px",
                boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                borderRadius: "8px",
                background: "#FFFFFF",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  lineHeight: "17.07px",
                  letterSpacing: "-0.01em",
                  padding: "1.5rem",
                }}
              >
                Total Redeemed Earnings :
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "17px",
                  fontFamily: "Montserrat",
                  lineHeight: "21px",
                  letterSpacing: "-0.04em",
                  padding: "1.5rem",
                  position: "absolute",
                  top: "1.5rem",
                }}
              >
                ₦{" "} {totalRedeemedEarning ? totalRedeemedEarning.toFixed(2) : "0.00"}
              </Typography>
            </Paper>
            <Paper
              elevation={0.5}
              sx={{
                width: "337px",
                height: "157px",
                boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                borderRadius: "8px",
                background: "#FFFFFF",
                position: "relative",
              }}
            >
              <Box
                display={"flex"}
                sx={{ justifyContent: "space-between", maxWidth: "90%" }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    lineHeight: "17.07px",
                    letterSpacing: "-0.01em",
                    padding: "1.5rem",
                  }}
                >
                  Total Earnings :
                </Typography>
                <button className={style.but} onClick={handleClick}>
                  Redeem
                </button>
              </Box>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "17px",
                  fontFamily: "Montserrat",
                  lineHeight: "21px",
                  letterSpacing: "-0.04em",
                  padding: "1.5rem",
                  position: "absolute",
                  top: "1.5rem",
                }}
              >
                ₦{" "} {totalEarning ? totalEarning?.toFixed(2) : "0.00"}
              </Typography>
            </Paper>
          </Box>
          <BlockSpacer mt={width>700?60:35} />
          <Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "17px",
                fontFamily: "Montserrat",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                padding: "1.5rem",
              }}
            >
              Latest Activity
            </Typography>
          </Box>
          <BlockSpacer mt={30} />
          <Box>
            <TableContainer 
              component={Paper} 
              sx={{maxWidth:980,display:{xs:"none",sm:"none",lg:"block", md:"block"}}}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >ID</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Date</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Description</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {currentTransactions?.map((row) => (
                    <StyledTableRow
                      key={row?.transactionId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row"
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >
                        {row?.transactionId}
                      </StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >{row?.dateOfTransaction}</StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >{row?.description}</StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >₦{row?.amount}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>{
              currentTransactions?.map((row) => (
                <Box 
                  key={row.transactionId} 
                  sx={{
                    display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                    py:2,
                    px:2,
                    justifyContent:"space-between",
                    alignItems: "center",
                    gap:2
                  }} 
                >
                  <Box>
                    <Typography className={style.bonusDate} >{row?.dateOfTransaction} </Typography>
                    <Typography className={style.referralName} >{row?.transactionId}</Typography>
                    <Typography className={style.bonusDetail} >{row?.description}</Typography>
                  </Box>
                  <Box>
                    <Typography className={style.bonusAmount} >₦{row?.amount}</Typography>
                  </Box>
                </Box>
              ))
            }
            {/* <Box
              display={"flex"}
              sx={{
                width: "987px",
                maxWidth: "92%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1.5rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  letterSpacing: "-0.01em",
                }}
              >
                N0_1947034
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  letterSpacing: "-0.01em",
                }}
              >
                Apr 28, 2022
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  letterSpacing: "-0.01em",
                }}
              >
                Part withdrawal
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  letterSpacing: "-0.01em",
                }}
              >
                ₦1,000,000
              </Typography>
            </Box> */}
          </Box>
        </Section>
        <BlockSpacer mt={width>700&&80} />
        <Paginate 
          current={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          total={totalTransactions}
        />
        <BlockSpacer mt={width>700?240:120} />
        <Button
          sx={{
            background: "#111E6C",
            width: "328px",
            height: "54px",
            color: "#FFFFFF",
            marginBottom: "1rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            textTransform: "capitalize",
            fontSize: "18px",
            display: {xs:"none",md:"block"},
            "&:hover": {
              backgroundColor: "#BDBDBD",
              color: "#111E6C",
            },
          }}
          onClick={() => {
            navigate("/wallet");
          }}
        >
          Back
        </Button>
        <BlockSpacer mt={20} />
      </main>
    </>
  );
}

export default Rosabon;
