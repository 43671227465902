import { createAsyncThunk } from "@reduxjs/toolkit";
import { feedbackCategoryService } from "../../services/feedbackServices";

import { createSlice } from "@reduxjs/toolkit";
import { toUnitless } from "@mui/material/styles/cssUtils";

const initialState = {
  Id: {
    loading: false,
    key: null,
    error: "",
  },
};

export const categoryIdSlice = createSlice({
  name: "id",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchId.fulfilled, (state, action) => {
      state.loading = false;
      state.key = action.payload;
      state.error = "";
    });

    builder.addCase(fetchId.rejected, (state, action) => {
      state.loading = false;
      state.key = null;
      state.error = action.error.message;
    });
  },
});

export const fetchId = createAsyncThunk("id/fetchId", async () => {
  return feedbackCategoryService()
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.message);
    });
});

export default categoryIdSlice.reducer;
