import styled from "@emotion/styled";
import { letterSpacing } from "@mui/system";

export const Section = styled.section`
  margin-top: 15%;
  height: 100%;
  @media (max-width: 1215px) {
    margin-top: 25%;
    padding: 1rem;
  }
`;
export const paperContainer = {
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
};

export const Account = {
  fontFamily: "Montserrat",
  fontSize: "12.2959px",
  lineHeight: "15px",
  color: "#242424",
  fontWeight: "300",
  padding: "1rem",
  letterSpacing: "-0.01em",
};

export const Number = {
  fontFamily: "Montserrat",
  fontSize: "12.2959px",
  fontWeight: "600",
  lineHeight: "15px",
  padding: "1rem",
  marginTop: "-20px",
  letterSpacing: "-0.01em",
};

export const left = {
  position: "absolute",
};

export const right = {
  position: "absolute",
  left: { xs: "20px", lg: "300px" },
};
