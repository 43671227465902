import React, { useState } from "react";
import { 
  Card, 
  CardContent,
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Divider,
  Checkbox,
  Snackbar
} from "@mui/material";
import BlockSpacer from "../../../components/BlockSpacer";
import style from "../style.module.css";
import "../stye.css";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import masterCard from "../../../components/Assets/icons/master.svg";
import Ussd from "../../../components/Assets/icons/ussd.svg";
import wallet from "../../../components/Assets/icons/wallet.svg";
import Copy from "../../../components/Assets/icons/copy.svg";

export const FundWallet = ({
  label,
  fundMethod,
  fundWithDebit,
  openChange,
  opChange,
  xchange,
  change,
  handleTransfer,
  dChange,
  handleUssd,
  handleDebit,
  close
}) => {
  const { width } = useScreenSize();
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: {xs:"100vw",sm:"100vw",md:"503px",lg:"503px"},
        height: {xs:"100vh",sm:"100vh",md:"870px",lg:"870px"},
        position: {md:"absolute",lg:"absolute"},
        right: "0px",
        top: "0px",
      }}
    >
      <Box
        sx={{
          display: {xs:"flex",md:"none"},
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem"
        }}
      >
        <IconButton onClick={()=>close()} >
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width>700?160:50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: {md:"430px",lg:"430px"},
          height: "529px",
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "17px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              padding: {xs: "1rem",sm:"1rem",md:"2rem",lg:"2rem"},
            }}
          >
            Fund Wallet
          </Typography>
        </CardContent>
        <BlockSpacer mt={20} />

        <Box
          display={"flex"}
          sx={{
            justifyContent: "space-between",
            margin: "2rem",
          }}
        >
          <Box display={"flex"} sx={{ marginLeft: "15px" }}>
            <img src={masterCard} alt="logo" />
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "13.52px",
                fontFamily: "Montserrat",
                marginLeft: "20px",
                width: "105px",
                paddingTop: "11px",
              }}
            >
              Debit Card
            </Typography>
          </Box>
          <Box>
            <Checkbox 
              {...label} 
              checked={fundMethod==="Debit"}
              onChange={()=>{fundWithDebit()}}
            />
          </Box>
        </Box>
        <Divider
          style={{
            width: "422.62px",
            marginLeft: "auto",
            marginLRight: "auto",
          }}
          variant="middle"
          light
        />
        <Box
          display={"flex"}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            margin: "2rem",
          }}
        >
          <Box display={"flex"} sx={{ marginLeft: "15px" }}>
            <img src={wallet} alt="logo" />
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "13.52px",
                fontFamily: "Montserrat",
                marginLeft: "20px",
                width: "105px",
                paddingTop: "8px",
              }}
            >
              Transfer
            </Typography>
          </Box>
          <Box>
            <Checkbox
              {...label}
              onChange={() => {
                openChange();
              }}
              checked={fundMethod==="Transfer"}
            />
          </Box>
        </Box>
        <Divider
          style={{
            width: "422.62px",
            marginLeft: "auto",
            marginLRight: "auto",
          }}
          variant="middle"
          light
        />
        <Box
          display={"flex"}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            margin: "2rem",
          }}
        >
          <Box display={"flex"} sx={{ marginLeft: "15px" }}>
            <img src={Ussd} alt="logo" />
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "13.52px",
                fontFamily: "Montserrat",
                marginLeft: "20px",
                width: "105px",
                paddingTop: "6px",
              }}
            >
              USSD
            </Typography>
          </Box>
          <Box>
            <Checkbox
              {...label}
              onChange={() => {
                opChange();
              }}
              checked={fundMethod==="USSD"}
            />
          </Box>
        </Box>
      </Card>
      <BlockSpacer mt={40} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {!change && !xchange && (!dChange) && (
          <Button
            sx={{
              width: "328px",
              textTransform: "capitalize",
              background: "#111E6C",
              borderRadius: "10px",
              height: "54px",
              color: "#FFFFFF",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "18px",
              "&:hover": {
                backgroundColor: "#BDBDBD",
                color: "#111E6C",
              },
            }}
          >
            Pay
          </Button>
        )}
        {change && (
          <Button
            sx={{
              width: "328px",
              textTransform: "capitalize",
              background: "#111E6C",
              borderRadius: "10px",
              height: "54px",
              color: "#FFFFFF",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "18px",
              "&:hover": {
                backgroundColor: "#BDBDBD",
                color: "#111E6C",
              },
            }}
            onClick={() => handleTransfer()}
          >
            Continue
          </Button>
        )}
        {xchange && (
          <Button
            sx={{
              width: "328px",
              textTransform: "capitalize",
              background: "#111E6C",
              borderRadius: "10px",
              height: "54px",
              color: "#FFFFFF",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "18px",
              "&:hover": {
                backgroundColor: "#BDBDBD",
                color: "#111E6C",
              },
            }}
            onClick={() => handleUssd()}
          >
            Continue
          </Button>
        )}
        {dChange && (
          <Button
            sx={{
              width: "328px",
              textTransform: "capitalize",
              background: "#111E6C",
              borderRadius: "10px",
              height: "54px",
              color: "#FFFFFF",
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "18px",
              "&:hover": {
                backgroundColor: "#BDBDBD",
                color: "#111E6C",
              },
            }}
            onClick={() => handleDebit()}
          >
            Continue
          </Button>
        )}
      </Box>
    </Box>
  )
}

export const TransferFund = ({
  fullName,
  bankFund,
  close
}) => {
  const [open, setOpen] = useState(false);
  const { width } = useScreenSize();
  const copyTransferDetails = (accountNumber, name) => {
    setOpen(true);
    navigator.clipboard.writeText(`
      Account Number: ${accountNumber},
      Account Name: ${name},
      Bank Name: Providus
    `)
  }
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: {xs:"100vw",sm:"100vw",md:"503px",lg:"503px"},
        height: {xs:"100vh",sm:"100vh",md:"870px",lg:"870px"},
        position: {md:"absolute",lg:"absolute"},
        right: "0px",
        top: "0px",
      }}
    >
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Box
        sx={{
          display: {xs:"flex",md:"none"},
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem"
        }}
      >
        <IconButton onClick={()=>close()} >
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Fund Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width>700?160:50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: {md:"430px",lg:"430px"},
          height: {md:"436px",lg:"436px"},
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              padding: {xs: "1rem",sm:"1rem",md:"2rem",lg:"2rem"},
            }}
          >
            Bank Details
          </Typography>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13.52px",
                fontFamily: "Montserrat",
                marginLeft: "20px",
                width: "303px",
                paddingLeft: "1rem",
              }}
            >
              Hi, {" "}{fullName}, Kindly make payment into the displayed
              account details
            </Typography>
          </Box>
          <Box
            display={"flex"}
            style={{
              justifyContent: "space-between",
              margin: "1.5rem",
            }}
          >
            <Box sx={{ marginLeft: ".5rem" }}>
              <Typography
                sx={{
                  fontWeight: "300",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "17px",
                }}
              >
                Account Number
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  letterSpacing: "-0.01em",
                  lineHeight: "17px",
                  paddingTop: "10px",
                }}
              >
                {bankFund?.data?.accountNumber}
              </Typography>
            </Box>
            <Box>
              <img
                src={Copy}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  copyTransferDetails(bankFund?.data?.accountNumber,bankFund?.data?.accountName)
                }
              />
            </Box>
          </Box>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography
              sx={{
                fontWeight: "300",
                fontFamily: "Montserrat",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "17px",
              }}
            >
              Account Name
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "17px",
                paddingTop: "10px",
                paddingBottom: "16px",
              }}
            >
              {bankFund?.data?.accountName}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography
              sx={{
                fontWeight: "300",
                fontFamily: "Montserrat",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "17px",
              }}
            >
              Bank Name
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "14px",
                letterSpacing: "-0.01em",
                lineHeight: "17px",
                paddingTop: "10px",
              }}
            >
              Providus
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: "300",
              fontFamily: "Montserrat",
              fontSize: "14px",
              letterSpacing: "-0.01em",
              lineHeight: "17px",
              paddingLeft: "2rem",
              width: "317px",
              paddingTop: "20px",
            }}
          >
            Account details expires in 48 hours, kindly endeavour to
            make transfer before 09/07/2022, 09 : 00
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export const UssdFund = ({ close }) => {
  const { width } = useScreenSize();
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: {xs:"100vw",sm:"100vw",md:"503px",lg:"503px"},
        height: {xs:"100vh",sm:"100vh",md:"870px",lg:"870px"},
        position: {md:"absolute",lg:"absolute"},
        right: "0px",
        top: "0px",
      }}
    >
      <Box
        sx={{
          display: {xs:"flex",md:"none"},
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem"
        }}
      >
        <IconButton onClick={()=>close()} >
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Fund Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width>700?160:50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: {md:"430px",lg:"430px"},
          height: {md:"436px",lg:"436px"},
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              padding: {xs: "1rem",sm:"1rem",md:"2rem",lg:"2rem"},
            }}
          >
            USSD Details
          </Typography>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13.52px",
                fontFamily: "Montserrat",
                marginLeft: "20px",
                width: "303px",
                height: "40px",

                paddingLeft: "1rem",
                letterSpacing: "-0.15px",
                lineHeight: "150%",
              }}
            >
              How to make transfer into my Rosabon bank account
              using USSD
            </Typography>
          </Box>
          <Box
            display={"flex"}
            style={{
              alignItems: "center",
              marginLeft: "2rem",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                paddingBottom: "3.2rem",
                paddingLeft: "-1.8rem",
                width: "6px",
                height: "6px",
                color: " #111E6C",
                fontWeight: "1000",
                fontFamily: "Montserrat",
              }}
            >
              .
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                width: "320px",
                lineHeight: "20.58px",
                fontFamily: "Montserrat",
                paddingLeft: ".5rem",
                letterSpacing: "-0.15px",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Sed, eum.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            style={{
              alignItems: "center",
              marginLeft: "2rem",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                paddingBottom: "3.2rem",
                paddingLeft: "-1.8rem",
                width: "6px",
                height: "6px",
                color: " #111E6C",
                fontWeight: "1000",
                fontFamily: "Montserrat",
              }}
            >
              .
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                width: "320px",
                lineHeight: "20.58px",
                fontFamily: "Montserrat",
                paddingLeft: ".5rem",
                letterSpacing: "-0.15px",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Sed, eum.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            style={{
              alignItems: "center",
              marginLeft: "2rem",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                paddingBottom: "3.2rem",
                paddingLeft: "-1.8rem",
                width: "6px",
                height: "6px",
                color: " #111E6C",
                fontWeight: "1000",
                fontFamily: "Montserrat",
              }}
            >
              .
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                width: "320px",

                fontFamily: "Montserrat",
                paddingLeft: ".5rem",
                letterSpacing: "-0.15px",
                lineHeight: "171.5%",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Sed, eum.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export const DebitCardFund = ({
  sendAmount,
  setSendAmount,
  fundWalletDebit,
  close
}) => {
  const { width } = useScreenSize();
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: {xs:"100vw",sm:"100vw",md:"503px",lg:"503px"},
        height: {xs:"100vh",sm:"100vh",md:"870px",lg:"870px"},
        // height: "270px",
        position: {md:"absolute",lg:"absolute"},
        right: "0px",
        top: "0px",
      }}
    >
      <Box
        sx={{
          display: {xs:"flex",md:"none"},
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem"
        }}
      >
        <IconButton onClick={()=>close()} >
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Fund Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width>700?160:50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: {md:"430px",lg:"430px"},
          height: {md:"436px",lg:"436px"},
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              padding: {xs: "1rem",sm:"1rem",md:"2rem",lg:"2rem"},
            }}
          >
            Enter Amount To Fund Wallet
          </Typography>
          <Box>
            <form className={style.TopRequest}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="Top Request"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "block",
                  }}
                >
                  Enter Amount To Fund
                </label>
                <input
                  type="number"
                  name="sendAmount"
                  id="pley"
                  value={sendAmount}
                  onChange={(e)=>setSendAmount(e.target.value)}
                  placeholder="  ₦ 1,500,000"
                  style={{
                    width: "321px",
                    height: "48px",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "inlineBlock",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxSizing: "borderBox",
                  }}
                />
              </div>
              <BlockSpacer />
              <Button
                sx={{
                  width: "328px",
                  textTransform: "capitalize",
                  background: "#111E6C",
                  borderRadius: "10px",
                  height: "54px",
                  color: "#FFFFFF",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "#BDBDBD",
                    color: "#111E6C",
                  },
                }}
                onClick={() => {fundWalletDebit()}}
              >
                Proceed
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}