import {
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../../hooks/use-media-query";
// import { setSignUp } from "../../../redux/reducers/auth";
import { updateCreditUserType } from "../../../redux/slices/switchPlatformSlice";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import FormAligner from "../../Hocs/FormAligner";
import Title from "../../Texts/Title";
import FormLabel from "../Label";
import {
  Wrapper,
} from "./style";
import style from "./style.module.css";
import { getUserTypes } from "../../../redux/slices/authSlice";
import { toast } from "react-toastify";

function UpdateIndividualUser() {
  const dispatch = useDispatch();
  const { userTypes } = useSelector((state) => state.auth);
  const { creditUserType, loading } = useSelector((state) => state.switchPlatform);

  const [defaultUserMethod, setDefaultUserMethod] = useState("");
  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };

  const breakPoint = useMediaQuery("(min-width: 1215px)");
  const router = useNavigate();

  useEffect(() => {
    if(creditUserType) {
      if(creditUserType?.statusCode==="OK") {
        toast.success(creditUserType?.message)
        router("/dashboard");
      } else {
        toast.error(creditUserType?.message)
      }
    }
    // eslint-disable-next-line
  },[creditUserType]);

  useEffect(() => {
    dispatch(getUserTypes());
    //eslint-disable-next-line
  }, [])

  
  const UpdateIndividualUsertype = async () => {
    const data = {
      userType: defaultUserMethod,
    };

    dispatch(updateCreditUserType(data));
  };

  const handleSubmit = () => {
    UpdateIndividualUsertype();
  };
  return (
    <>
      <Wrapper className={style.form}>
        {breakPoint && <BlockSpacer mt={100} />}
        <div className={style.alignTitle}>
          <Title text={"Update User Type"} />
        </div>

        <BlockSpacer mt={69} />

        <div>
          <FormAligner>
            <FormLabel text={"User Type"} />
            <Select
              value={defaultUserMethod}
              onChange={handleUserTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              displayEmpty
              renderValue={
                defaultUserMethod !== ""
                  ? undefined
                  : () => <span>Select one</span>
              }
              style={{ width: "100%", borderRadius: "6px", outline: "none" }}
            >
              {userTypes?.map((option) => (
                <MenuItem key={option?.name} value={option?.name}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </FormAligner>
          <BlockSpacer mt={25} />
          <div className={style.alignMiddle}>
            <Button
              color={"primary"}
              width={"328px"}
              onClick={() => handleSubmit()}
              disabled={!(
                defaultUserMethod!==""
              )}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </div>
        <BlockSpacer mt={100} />
      </Wrapper>
    </>
  );
}

export default UpdateIndividualUser;
