import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuccessMessage from "../../Cards/SuccessMessages";
import OfferMessage from "../../Cards/OfferSucces(feedback)/index";

import { style } from "../OtpVerificationModal";
import GreenIcon from "../../IconSvgs/GreenIcon";

export default function OfferSuccessModal({ open, handleClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-otp-title"
        aria-describedby="modal-otp-description"
      >
        <Box sx={style}>
          <OfferMessage
            subTextStart={"Your feedback has been received"}
            icon={<GreenIcon />}
            route={"/feedback/my-tickets"}
          />
        </Box>
      </Modal>
    </div>
  );
}
