import React from 'react';
import { Section } from './style'
import PersonalDetailSidebar from '../ProfileSidebars/PersonalDetailSidebar'

function PersonalDetalLayout({children}) {
    return (
        <Section>
            <div className="left-side">
                <PersonalDetailSidebar />
            </div>
            <div className="right-side">
                {children}
            </div>
        </Section>
    );
    
}

export default PersonalDetalLayout;