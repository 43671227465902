import React, { useState, useEffect, useRef } from "react";
import PersonalDetalLayout from "../../../../../components/PersonalDetailLayout";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import ClearIcon from "@mui/icons-material/Clear";
import Buttons from "../../../../../components/Buttons";
import BlockSpacer from "../../../../../components/BlockSpacer";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import FormLabel from "../../../../../components/Forms/Label";
import { Section, textStyle, profileText, container } from "./style";
import FooterCheckoutNoLink from "../../../../../components/FooterCheckoutNoLink";
import SuccessModal from "../../../../../components/Modals/SucessfulDocumentModal";
import FileuploadIcon from "../../../../../components/IconSvgs/FileuploadIcon";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllIdTypes,
  getUserDocs,
  sendOtp,
  closeOtpModal,
  validateOtp,
} from "../../../../../redux/slices/profileSlice";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { updatePersonalDocs } from "../../../../../redux/slices/updateProfileSlice";
import SelectField from "../../../../../components/Core/SelectField";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import Check from "../../../../../assets/checked.png";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

function ProfileMyDocument(props) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const [token, setToken] = useState("");
  const [idType, setIdType] = useState("NIN");
  const [idProgress, setIdProgress] = useState(0);
  const [idBackProgress, setIdBackProgress] = useState(0);
  const [utilityProgress, setUtilityProgress] = useState(0);
  const initialErr = {
    frontEncodedString: false,
    utilityEncodedString: false,
    utilityEncodedStringBack: false,
  };
  const [fileErr, setFileErr] = useState(initialErr);
  const [base64File, setBase64File] = useState({
    frontEncodedString: "",
    // backEncodedString: '',
    utilityEncodedString: "",
    utilityEncodedStringBack: "",
    photoEncodedString: "",
  });
  const { width } = useScreenSize();

  const handleOpenSuccessMesage = () => setOpenSuccess(true);
  const handleSuceesMessageClose = () => setOpenSuccess(false);

  const dispatch = useDispatch();
  const { loading, docMsg } = useSelector((state) => state.updateProfile);

  const {
    users,
    showEmailOtpModal,
    otp,
    otpError,
    idTypes,
    validateEmailOtp,
    documents,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getUserDocs());
  }, [docMsg]);

  const photoFileInputRef = useRef();
  const frontFileInputRef = useRef();
  const backFileInputRef = useRef();
  const utilityFileInputRef = useRef();

  const toggleEdit = (e) => {
    setShowEdit(!showEdit);
  };

  // utility upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setUtilityProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.utilityEncodedString]);
  // id back file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setIdBackProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.utilityEncodedStringBack]);
  // id file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setIdProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.frontEncodedString]);

  const data = {
    idNumber: "",
    idTypeId: 1,
    utilityBillType: "",
    otherUtilityTypeDetails: "",
    utilityBillPeriod: "",
  };
  const [formData, setformData] = useState(data);

  const idOptions = idTypes ? idTypes?.map((item) => item?.name) : [];

  useEffect(() => {
    if (documents?.data) {
      setIdType(documents?.data?.idType?.name);
      setformData({
        ...formData,
        idNumber: documents?.data?.idNumber,
      });
    }
  }, [documents]);

  const handleIdType = (e) => {
    setIdType(e.target.value);
    setformData({
      ...formData,
      idTypeId: idType,
    });
  };

  const [billType, setBillType] = useState("");
  const handleBillType = (e) => {
    setBillType(e.target.value?.replace(" ", "_"));
    setformData({
      ...formData,
      utilityBillType: e.target.value?.replace(" ", "_"),
    });
  };

  useEffect(() => {
    dispatch(getAllIdTypes());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleFileChange = (e, name) => {
    const { files } = e.target;

    if (name === "frontEncodedString") {
      setIdProgress(0);
    } else if (name === "utilityEncodedString") {
      setUtilityProgress(0);
    } else if (name === "utilityEncodedStringBack") {
      setIdBackProgress(0);
    }

    // console.log(files[0]);

    const encodedFileBase64 = (file) => {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setBase64File({
            ...base64File,
            [name]: reader.result.split("base64,")[1],
          });
        };
        console.log(reader);
        reader.onerror = (error) => {
          console.log("error", error);
        };
      }
    };

    if (
      files[0]?.size <= 2000000 &&
      (files[0]?.type === "image/jpeg" ||
        files[0]?.type === "application/pdf" ||
        files[0]?.type === "image/png")
    ) {
      encodedFileBase64(files[0]);
      setFileErr({
        ...fileErr,
        [name]: false,
      });
    } else {
      setFileErr({
        ...fileErr,
        [name]: true,
      });
    }
    e.target.value = null;
  };

  const handleSubmit = async () => {
    const {
      frontEncodedString,
      photoEncodedString,
      utilityEncodedString,
      utilityEncodedStringBack,
    } = base64File;

    const {
      idNumber,
      utilityBillPeriod,
      utilityBillType,
      otherUtilityTypeDetails,
      id,
    } = formData;

    if (!photoEncodedString)
      return toast.error("Please upload a profile image");
    // return toast.error("Please upload a profile image");
    let submitData = {
      idDocumentImage: {
        encodedUpload: frontEncodedString,
        name: "ID Image",
      },
      idNumber,
      idTypeId: idTypes?.find((item) => item.name === idType)?.id,
      utilityBillPeriod,
      utilityBillType: utilityBillType === "OTHERS" ? null : utilityBillType,
      otherUtilityTypeDetails:
        utilityBillType === "OTHERS" ? otherUtilityTypeDetails : "",
      passportPhotographImage: {
        encodedUpload: photoEncodedString,
        name: "Photo Image",
      },
      utilityBillImage: {
        encodedUpload: utilityEncodedString,
        name: "Utility Image",
      },
      utilityBillImage_Back: {
        encodedUpload: utilityEncodedStringBack,
        name: "Utility Image Back",
      },
    };

    let data = documents
      ? {
          ...submitData,
          id,
        }
      : submitData;

    // const reset = {
    //   setShowEdit,
    //   setformData,
    //   setBase64File,
    //   getUserDocs,
    // };

    console.log(data);
    toggleEdit();
    dispatch(updatePersonalDocs(data));
  };

  const handleFileSelect = (e, reference) => {
    e.preventDefault();
    reference.current.click();
  };

  useEffect(() => {
    setformData({
      ...formData,
      id: documents?.data?.id,
      idNumber: documents?.data?.idNumber,
      idTypeId: documents?.data?.idTypeId?.id,
      utilityBillPeriod: moment(
        documents?.data?.utilityBillPeriod,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD"),
      utilityBillType: documents?.data?.utilityBillType,
      otherUtilityTypeDetails: documents?.data?.otherUtilityTypeDetails,
    });
    setIdType(documents?.data?.name);
    setBillType(documents?.data?.utilityBillType);
  }, [documents]);

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(sendOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
    setToken("");
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  const BILL_TYPE = ["WATER BILL", "LIGHT BILL", "WASTE BILL", "OTHERS"];

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      toggleEdit();
    }
  }, [validateEmailOtp]);

  useEffect(() => {
    if (!documents) {
      dispatch(getUserDocs());
    }
  }, [documents]);

  const handleUserInputs = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const currentDay = moment().format("YYYY-MM-DD");
  const utilityBillLimit = moment().subtract(3, "months").format("YYYY-MM-DD");

  return (
    <>
      <TopBar />
      <>
        <ToastContainer />
        <Section>
          <PersonalDetalLayout>
            <Box xs={container}>
              <Box
                sx={{
                  display: width > 700 ? "none" : "flex",
                  alignItems: "center",
                  gap: 2,
                  my: 3,
                  pl: 2,
                }}
              >
                <Box>
                  <Link to="/profile">
                    <ArrowBackIosNew />
                  </Link>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "20px",
                      lineHeight: "24px",
                      textTransform: "capitalize",
                      color: "#222222",
                    }}
                  >
                    My Documents
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      mt: 5,
                      ml: { xs: 3, lg: 0 },
                      display: "flex",
                      alignItems: {
                        xs: "flex-start",
                        sm: "flex-start",
                        lg: "center",
                      },
                      justifyContent: "space-between",
                      width: 330,
                      gap: 2,
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {base64File.photoEncodedString !== "" ||
                      documents?.data?.passportPhotographImage?.imageUrl ? (
                        <img
                          className="image-frame"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid #FFFFFF",
                            width: width <= 700 ? "72px" : "116px",
                            height: width <= 700 ? "72px" : "116px",
                          }}
                          src={
                            base64File.photoEncodedString
                              ? `data:image/jpeg;base64,${base64File.photoEncodedString}`
                              : documents?.data?.passportPhotographImage
                                  ?.imageUrl
                              ? documents?.data?.passportPhotographImage
                                  ?.imageUrl
                              : null
                          }
                          alt="User"
                          width={width <= 700 ? "72px" : "116px"}
                          height={width <= 700 ? "72px" : "116px"}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            width: width <= 700 ? "72px" : "116px",
                            height: width <= 700 ? "72px" : "116px",
                          }}
                        />
                      )}

                      <div className="banner"></div>

                      <input
                        type="file"
                        className="file file-input"
                        accept="image/jpeg,image/png"
                        disabled={showEdit}
                        ref={photoFileInputRef}
                        onChange={(e) =>
                          handleFileChange(e, "photoEncodedString")
                        }
                        // disabled={showEdit}
                      />
                      <CameraAltIcon
                        className="camera"
                        onClick={(e) => handleFileSelect(e, photoFileInputRef)}
                      />
                    </div>

                    <Box>
                      <Typography sx={profileText}>Profile</Typography>
                      <Typography sx={textStyle}>
                        Update your photo and personal Details
                      </Typography>
                      {showEdit ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{ display: width > 700 && "none" }}
                        >
                          <Box
                            sx={{ ml: { xs: 0, lg: 40 }, mt: { xs: 1, lg: 6 } }}
                          >
                            <Buttons
                              color="primary"
                              width={width <= 700 ? "60px" : "72px"}
                              fontSize={width <= 700 && "14px"}
                              onClick={handleSendOtp}
                            >
                              Edit
                            </Buttons>
                          </Box>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{ display: width > 700 && "none" }}
                        >
                          <Box
                            sx={{ ml: { xs: 0, lg: 40 }, mt: { xs: 1, lg: 6 } }}
                          >
                            <Buttons
                              color="primary"
                              width={width <= 700 ? "60px" : "72px"}
                              fontSize={width <= 700 && "14px"}
                              onClick={toggleEdit}
                            >
                              Cancel
                            </Buttons>
                          </Box>
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {showEdit ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ display: width <= 700 && "none" }}
                  >
                    <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                      <Buttons
                        color="primary"
                        width={"72px"}
                        onClick={handleSendOtp}
                      >
                        Edit
                      </Buttons>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ display: width <= 700 && "none" }}
                  >
                    <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                      <Buttons
                        color="primary"
                        width={"72px"}
                        onClick={toggleEdit}
                      >
                        Cancel
                      </Buttons>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <BlockSpacer mt={70} />
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <Box sx={{ ml: { xs: "1rem" } }}>
                      <FormLabel text={"ID Type"} />
                    </Box>

                    <TextField
                      placeholder="NIN"
                      type="text"
                      value={"NIN"}
                      required
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                        input: { background: !showEdit && "#e9ecef" },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={showEdit}
                      onChange={handleUserInputs}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <Box sx={{ ml: { xs: "1rem" } }}>
                      <FormLabel text={"ID Number"} />
                    </Box>

                    <TextField
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                      }}
                      placeholder="123-000-3456"
                      type="text"
                      value={formData?.idNumber}
                      required
                      disabled={showEdit}
                      onChange={handleUserInputs}
                      name="idNumber"
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer mt={70} />
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ display: "flex" }}>
                    <FileuploadIcon />
                    <Box>
                      <Typography>Upload ID (front)</Typography>
                      {base64File?.frontEncodedString === "" ? (
                        <Typography>jpg, png. PDF 2 MB</Typography>
                      ) : (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <Box sx={{ width: "150px" }}>
                              <LinearProgress
                                variant="determinate"
                                value={idProgress}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <ClearIcon
                              onClick={() => {
                                setBase64File({
                                  ...base64File,
                                  frontEncodedString: "",
                                });
                                setIdProgress(0);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {fileErr.frontEncodedString && (
                        <Typography style={{ color: "#FF0000" }}>
                          File must be less than 2MB and JPG, PNG or PDF format
                        </Typography>
                      )}
                    </Box>
                    {documents?.data?.idDocumentImage?.imageUrl && showEdit && (
                      <img
                        // className="position-absolute"
                        src={Check}
                        alt="check"
                        width="15"
                        height="15"
                      />
                    )}
                  </Box>
                </Grid>
                {documents?.data?.idDocumentImage?.imageUrl && showEdit ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <a
                        href={documents?.data?.idDocumentImage?.imageUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            width: "150px",
                            height: "34px",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "#828282",
                          }}
                        >
                          View
                        </Button>
                      </a>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <input
                        type="file"
                        className="file"
                        ref={frontFileInputRef}
                        accept="application/pdf,image/jpeg,image/png"
                        disabled={showEdit}
                        onChange={(e) =>
                          handleFileChange(e, "frontEncodedString")
                        }
                      />
                      <Button
                        sx={{
                          background: "#111E6C",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "white",
                        }}
                        onClick={(e) => handleFileSelect(e, frontFileInputRef)}
                      >
                        Choose file
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <BlockSpacer mt={30} />
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ display: "flex" }}>
                    <FileuploadIcon />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>Upload ID (Back)</Typography>
                      {base64File?.utilityEncodedStringBack === "" ? (
                        <Typography>jpg, png. PDF 2 MB</Typography>
                      ) : (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <Box sx={{ width: "150px" }}>
                              <LinearProgress
                                variant="determinate"
                                value={idBackProgress}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <ClearIcon
                              onClick={() => {
                                setBase64File({
                                  ...base64File,
                                  utilityEncodedStringBack: "",
                                });
                                setIdBackProgress(0);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {fileErr.utilityEncodedStringBack && (
                        <Typography style={{ color: "#FF0000" }}>
                          File must be less than 2MB and JPG, PNG or PDF format
                        </Typography>
                      )}
                    </Box>
                    {documents?.data?.idDocumentImage?.imageUrl && showEdit && (
                      <img
                        // className="position-absolute"
                        src={Check}
                        alt="check"
                        width="15"
                        height="15"
                      />
                    )}
                  </Box>
                </Grid>
                {documents?.data?.utilityBillImage_Back?.imageUrl &&
                showEdit ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <a
                        href={documents?.data?.utilityBillImage_Back?.imageUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            width: "150px",
                            height: "34px",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "#828282",
                          }}
                        >
                          View
                        </Button>
                      </a>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <input
                        type="file"
                        className="file"
                        accept="application/pdf,image/jpeg,image/png"
                        disabled={showEdit}
                        ref={backFileInputRef}
                        onChange={(e) =>
                          handleFileChange(e, "utilityEncodedStringBack")
                        }
                      />
                      <Button
                        sx={{
                          background: "#111E6C",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "white",
                        }}
                        onClick={(e) => handleFileSelect(e, backFileInputRef)}
                      >
                        Choose file
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <BlockSpacer mt={50} />
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <SelectField
                      name={"Utility Bill Type"}
                      // method={defaultStateMethod}
                      changeAction={handleBillType}
                      method={billType?.replace("_", " ")}
                      items={BILL_TYPE}
                      disabled={showEdit}
                      asterisk
                    />
                  </FormAligner>
                </Grid>
                {billType === "OTHERS" && (
                  <>
                    <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                      <FormAligner>
                        <Box sx={{ ml: { xs: "1rem" } }}>
                          <FormLabel text={"Utility Bill Type Details"} />
                        </Box>
                        <TextField
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "90%",
                              lg: "90%",
                            },
                          }}
                          type="text"
                          disabled={showEdit}
                          required
                          value={formData.otherUtilityTypeDetails}
                          onChange={handleUserInputs}
                          name="otherUtilityTypeDetails"
                        />
                      </FormAligner>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <Box sx={{ ml: { xs: "1rem" } }}>
                      <FormLabel text={"Utility Bill Period"} />
                    </Box>

                    <TextField
                      sx={{
                        width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                      }}
                      placeholder="02/02/2022"
                      type="date"
                      disabled={showEdit}
                      required
                      inputProps={{ min: utilityBillLimit, max: currentDay }}
                      value={formData?.utilityBillPeriod}
                      onChange={handleUserInputs}
                      name="utilityBillPeriod"
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer mt={50} />
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ display: "flex" }}>
                    <FileuploadIcon />
                    <Box>
                      <Typography>Utility Bill Image</Typography>
                      {base64File?.utilityEncodedString === "" ? (
                        <Typography>jpg, png. PDF 2 MB</Typography>
                      ) : (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <Box sx={{ width: "150px" }}>
                              <LinearProgress
                                variant="determinate"
                                value={utilityProgress}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <ClearIcon
                              onClick={() => {
                                setBase64File({
                                  ...base64File,
                                  utilityEncodedString: "",
                                });
                                setUtilityProgress(0);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {fileErr.utilityEncodedString && (
                        <Typography style={{ color: "#FF0000" }}>
                          File must be less than 2MB and JPG, PNG or PDF format
                        </Typography>
                      )}
                    </Box>
                    {documents?.data?.idDocumentImage?.imageUrl && showEdit && (
                      <img
                        // className="position-absolute"
                        src={Check}
                        alt="check"
                        width="15"
                        height="15"
                      />
                    )}
                  </Box>
                </Grid>
                {documents?.data?.utilityBillImage?.imageUrl && showEdit ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <a
                        href={documents?.data?.utilityBillImage?.imageUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            width: "150px",
                            height: "34px",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "#828282",
                          }}
                        >
                          View
                        </Button>
                      </a>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                      <input
                        type="file"
                        disabled={showEdit}
                        className="file"
                        accept="application/pdf,image/jpeg,image/png"
                        ref={utilityFileInputRef}
                        onChange={(e) =>
                          handleFileChange(e, "utilityEncodedString")
                        }
                      />
                      <Button
                        sx={{
                          background: "#111E6C",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "white",
                        }}
                        onClick={(e) =>
                          handleFileSelect(e, utilityFileInputRef)
                        }
                      >
                        Choose file
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            <BlockSpacer mt={200} />

            <FooterCheckoutNoLink
              proceedInfoButton={"Save"}
              disabled={showEdit}
              handleOpenSuccessMesage={handleOpenSuccessMesage}
              handleClose={() => handleSubmit()}
              isLoading={loading}
            />
          </PersonalDetalLayout>
          {showEmailOtpModal && (
            <OtpVerificationEmailModal
              open={showEmailOtpModal}
              handleClose={handleOTPModalClose}
              handleOtpChange={(otp) => createOtp(otp)}
              otp={token}
              resendOtp={() => {
                dispatch(sendOtp());
              }}
              otpToken={otp?.data}
              handleSuccessOpen={() => handleEmailOtpSubmit()}
            />
          )}
          {openSuccess && (
            <SuccessModal
              open={openSuccess}
              handleClose={handleSuceesMessageClose}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default ProfileMyDocument;

{
  /* <Grid container spacing={2}>
<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    <Box sx={{ mt: 5, ml: { xs: 3, lg: 0 }, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 330 }}>
        <Avatar sx={{ width: '80px', height: '80px' }} />
        <Box>
            <Typography sx={profileText}>Profile</Typography>
            <Typography sx={textStyle}>Update your photo and personal Details</Typography>
        </Box>

    </Box>

</Grid>

<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
        <Button
            color="primary"
            width={'72px'}
        >
            Edit
        </Button>

    </Box>


</Grid>
</Grid> */
}

// <Grid container spacing={2}>
// <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//     <Box sx={{ mt: 5, ml: { xs: 3, lg: 0 }, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 330 }}>
//         <Avatar sx={{ width: '80px', height: '80px' }} />
//         <Box>
//             <Typography sx={profileText}>Profile</Typography>
//             <Typography sx={textStyle}>Update your photo and personal Details</Typography>
//         </Box>

//     </Box>

// </Grid>

// <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//     <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
//         <Button
//             color="primary"
//             width={'72px'}
//         >
//             Edit
//         </Button>

//     </Box>

// </Grid>
// </Grid>

// <BlockSpacer mt={70} />

// <Grid container spacing={2}>
// <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
//     <Box xs={{ width: '100px', ml: '2rem' }}>
//         <FormAligner>
//             <FormLabel text={"First Name"} />
//             <TextField
//                 placeholder="ekiyee"
//                 type="email"
//                 required
//                 name="name"
//             />
//         </FormAligner>

//     </Box>
// </Grid>
// <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
//     <Box xs={{ width: '100px', ml: 5 }}>
//         <FormAligner>
//             <FormLabel text={"First Name"} />
//             <TextField
//                 placeholder="ekiyee"
//                 type="email"
//                 required
//                 name="name"
//             />
//         </FormAligner>

//     </Box>

// </Grid>

// </Grid>

// </Box>
