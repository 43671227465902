import { useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from "react-redux";

const CopyToClipboardButton = () => {
  const [open, setOpen] = useState(false);
  const { users } = useSelector(state => state.profile)

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(users?.referralLink);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <ContentCopyIcon />
      </IconButton>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyToClipboardButton;