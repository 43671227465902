import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import OtpInput from "react-otp-input";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { 
  verifyPhone, 
  validatePhoneOtp,
  closeModal 
} from "../../../redux/slices/updateProfileSlice";
import Spinner from "../../Spinner";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  height: "auto",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};
const otpStyle = {
  width: "56px",
  margin: "0 auto",
  background: "#FFF",
  height: "56px",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
};

const mobOtpStyle = {
  width: "36px",
  margin: "0 auto",
  background: "#FFF",
  height: "36px",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
};

const otpFocusedStyle = {
  border: "2px solid #E0E0E0",
};

export default function OtpVerificationModal({
  open,
  handleClose,
  handleOtpChange,
  otpData,
  otp,
  secondPhone,
  handleSuccessOpen,
}) {
  const dispatch = useDispatch();
  const [token, setToken] = React.useState("");
  const handleChange = (otp) => setToken(otp);
  const modalClose = () => {
    handleClose();
    setToken("")
  }

  const { phoneMsg, loading } = useSelector(
    (state) => state.updateProfile
  );

  const handlePhoneOtpSubmit = async (e) => {
    e.preventDefault();
    if (
      (otpData && token === otpData) ||
      (phoneMsg?.data?.otp && token === phoneMsg?.data?.otp)
    ) {
      await dispatch(validatePhoneOtp(token));
      setToken("");
    } else {
      // setErrorMsg("Please enter a valid OTP");
      toast.error("Enter a valid OTP")
    }

  };

  const handleResendPhoneOtp = () => {
    dispatch(verifyPhone(secondPhone));
    toast.success("OTP Resent")
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.header}>OTP Verification</div>
          <BlockSpacer mt={31} />
          <div className={styles.text}>Enter OTP sent to this Phone Number</div>
          <BlockSpacer mt={35} />
          <div className={styles.input}>
            <OtpInput
              onChange={handleChange}
              numInputs={5}
              value={token}
              placeholder="-----"
              inputStyle={otpStyle}
              focusStyle={otpFocusedStyle}
            />
          </div>
          <div className={styles.mobInput}>
            <OtpInput
              onChange={handleChange}
              numInputs={5}
              value={token}
              placeholder="-----"
              inputStyle={mobOtpStyle}
              focusStyle={otpFocusedStyle}
            />
          </div>
          <BlockSpacer mt={20} />
          <div className={styles.msg}>
            Didn't get an OTP? {" "}
            <span className={styles.resend} onClick={handleResendPhoneOtp} >Resend</span>
          </div>
          <BlockSpacer mt={44} />
          <div className={styles.button}>
            <Button
              color={"tertiary"}
              width={164}
              height={41}
              onClick={modalClose}
            >
              Cancel
            </Button>
            <Button
              color={"primary"}
              width={164}
              height={41}
              onClick={handlePhoneOtpSubmit}
            >
              {loading ? <Spinner /> : "OK"}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
