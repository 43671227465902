import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../../BlockSpacer";
import FormAligner from "../../../Hocs/FormAligner";
import FormLabel from "../../../Forms/Label";
import { Backdrop, TextField, Typography } from "@mui/material";
import SelectField from "../../../Core/SelectField";
import { Link } from "react-router-dom";
import Button from "../../../Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getBankDetails } from "../../../../redux/slices/profileSlice.js";
import { generateBankStatement } from "../../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 310, sm: 310, md: 416 },
  maxWidth: "100%",
  height: "374px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function SourceAccountModal({
  open,
  handleClose,
  handleNoteTicketOpen,
}) {
  const dispatch = useDispatch();

  const { loading, initializedLoan, generatedBankStatement } = useSelector(
    (state) => state.getCredit
  );

  const { bankDetails, bankDetailsError } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getBankDetails());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (generatedBankStatement?.message === "Extract bank statement") {
      handleNoteTicketOpen();
      toast.success(generatedBankStatement?.object?.body?.body?.message);
    } else if (generatedBankStatement?.message === "Redirecting to MONO") {
    }
    // eslint-disable-next-line
  }, [generatedBankStatement]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      generateBankStatement({
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Source Account</div>
            <BlockSpacer mt={50} />
            <TextField
              placeholder="Bank Account"
              type="text"
              required
              name="Bank Account"
              value={
                bankDetails
                  ? `${bankDetails?.accountName} - ${bankDetails?.accountNumber}`
                  : ""
              }
              InputProps={{ readOnly: true }}
            />
            {bankDetailsError && (
              <Typography sx={{ color: "#ff0000", fontSize: "10px" }}>
                Bank Account is not available for this user
              </Typography>
            )}
            <BlockSpacer mt={100} />
            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}
                onClick={handleSubmit}
              >
                {loading ? (
                  <ClipLoader color={"#fff"} loading={loading} size={20} />
                ) : (
                  "Request"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
