import React, { useEffect, useState } from "react";
import { Section, choosePayment, PaymantBox } from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import SummaryCard from "../../../components/Cards/SummaryCard/index.jsx";
import { Typography, Box, Paper, Button } from "@mui/material";
import MasterCardIcon from "../../../components/IconSvgs/MasterCardIcon.jsx";
import bankicon from "../../../components/Assets/icons/bankicon.svg";
import LineBreak from "../../../components/Hocs/LineBreak/index.jsx";
import Buttons from "../../../components/Buttons";
// import CheckBox from "../../../components/Checkboxs";
import FooterCheckout from "../../../components/FooterCheckout/index.jsx";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {
  loanRepaymentSetup,
  testDebitCard,
  cancelSetup
} from "../../../redux/slices/getCreditSlice.js";
import { ClipLoader } from "react-spinners";

function ChoosePayment() {
  const navigate = useNavigate();
  const [paySetup, setPaySetup] = useState("");
  const { initializedLoan, products, repaymentSetup, repayment, testCard, loading } =
    useSelector((state) => state.getCredit);
  const [refund, setRefund] = useState(false);
  const [CardName, setCardName] = useState("");
  const dispatch = useDispatch();

  const handleUpdate = (e) => {
    const { value } = e.target;
    if (value === "3" && !(paySetup.includes("3"))) {
      const cardName = new Date().getTime().toString();
      setCardName(cardName);
      dispatch(testDebitCard({ cardName: cardName }));
      setRefund(true);
    }
    const payList = paySetup?.split("%");
    if (payList.includes(value?.toString())) {
      let index = payList.indexOf(value?.toString());
      payList.splice(index, 1);
    } else {
      payList.push(value?.toString());
    }
    let pays = payList?.join("%");
    pays = pays?.startsWith("%") ? pays?.substring(1) : pays;
    pays = pays?.slice(-1) !== "%" ? pays?.concat("%") : pays;
    console.log("pas", pays);
    setPaySetup(payList?.join("%"));
  };

  useEffect(() => {
    if (repaymentSetup) {
      navigate("/quick/loan/team-condition");
    }
    // eslint-disable-next-line
  }, [repaymentSetup]);

  useEffect(() => {
    if (testCard) {
      setRefund(true);
    }
    // eslint-disable-next-line
  }, [testCard]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loanRepaymentSetup({
        loanId: initializedLoan?.object?.body?.body?.loanId,
        loanRepaymentMethodsString: paySetup?.startsWith("%")
          ? paySetup?.length > 2 ? paySetup?.substring(1) : paySetup?.substring(1)?.concat("%")
          : paySetup?.concat("%"),
        transactionReference: testCard?.transactionReference,
        cardName: CardName
      })
    );
  };

  const cancelLoan = () => {
    dispatch(cancelSetup());
    navigate(-1);
  }
  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={20} />
          <SummaryCard
            title={initializedLoan?.data?.body?.productName}
            currencyRequested={"Requested Loan Amount"}
            tenor={"Tenor"}
            currency={repayment?.object?.body?.body?.requestLoanAmount}
            days={`${repayment?.object?.body?.body?.tenor} days`}
            infoPayable={"Interest Payable"}
            feePayable={"Fee Payable"}
            interestpayableCurrency={repayment?.object?.body?.body?.interestPayable}
            feePayableCurrency={repayment?.object?.body?.body?.feePayable}
            TotalRepayment={"Total Repayment"}
            LoanDueDate={"Loan Due Date"}
            totalPaymentAmount={repayment?.object?.body?.body?.totalRepayment}
            totalPaymentDate={repayment?.object?.body?.body?.loanDueDate}
          />

          <BlockSpacer mt={50} />

          <Typography sx={choosePayment}>Choose Payment Type</Typography>

          {products && (
            <Box sx={PaymantBox}>
              {initializedLoan?.data?.body?.repaymentTypeMgts?.map((item) => (
                <div key={item?.id}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "170px",
                        marginTop: ".5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      {item?.repaymentName === "Debit Cards-Banks" ? (
                        <MasterCardIcon />
                      ) : (
                        <img src={bankicon} alt="bank" width={"25px"} />
                      )}
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          letterSpacing: "-0.03em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.repaymentName}
                      </Typography>
                    </Box>

                    <Checkbox
                      size={"small"}
                      sx={{
                        color: "#111E6C",
                        "&.Mui-checked": {
                          color: "#111E6C",
                        },
                      }}
                      value={item?.repaymentId}
                      checked={paySetup
                        ?.split("%")
                        ?.includes(item?.repaymentId?.toString())}
                      onChange={handleUpdate}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <LineBreak />
                </div>
              ))}
            </Box>
          )}

          <BlockSpacer mt={150} />
          <Paper
            elevation={0.5}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "87%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                type="button"
                onClick={cancelLoan}
              >
                Back
              </Button>

              <Buttons
                color="primary"
                width="328px"
                disabled={!(paySetup.includes("3")) && refund === false}
                onClick={handleSubmit}
              >
                {
                  loading ? (<ClipLoader color={"#fff"} loading={loading} size={20} />) :
                  "Proceed"
                }
              </Buttons>
            </Box>
          </Paper>
        </Section>
      </>
    </>
  );
}

export default ChoosePayment;
