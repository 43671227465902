import styled from "@emotion/styled";

export const Section = styled.section`
   margin-top: 10%;

   @media(max-width: 1244px){
    margin-top: 25%;
   }
`

export const textheading = {
   fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: '#222222'
}

export const container = {
   marginLeft: {xs: '1rem'} ,
   margin: '2rem'
}