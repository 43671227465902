import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlockSpacer from "../../../../../components/BlockSpacer";
import Buttons from "../../../../../components/Buttons";
import { Box, Typography, Grid, TextField, IconButton } from "@mui/material";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonalDetalLayout from "../../../../../components/PersonalDetailLayout";
import FormLabel from "../../../../../components/Forms/Label";
import SuccessModal from "../../../../../components/Modals/SuccessModal";
import OtpVerificationModal from "../../../../../components/Modals/IndividualProfileModal/OtpVerificationModal";
// import OtpSuccessVerificationModal from "../../../Modals/OtpSuccessVerificationModal";
import FooterCheckout from "../../../../../components/FooterCheckout";
import { Section, container, headingText } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../../../redux/slices/authSlice";
import { 
  changeUserPassword,
  clearChangePassword
} from "../../../../../redux/slices/updateProfileSlice";
import {
  sendOtp,
  closeOtpModal,
  validateOtp,
} from "../../../../../redux/slices/profileSlice";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

function ProfileChangePassword(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [showEdit, setShowEdit] = useState(true);
  const [token, setToken] = useState("");
  const [touched, setTouched] = useState({});
  const handleOpenSuccessMesage = () => setOpenSuccess(true);
  const handleSuceesMessageClose = () => setOpenSuccess(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    showEmailOtpModal,
    otp,
    validateEmailOtp,
  } = useSelector((state) => state.profile);
  const { loading, passChangeMsg } = useSelector(state => state.updateProfile);
  const { width } = useScreenSize();

  const passwordData = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  }

  const logout = () => {
    localStorage.clear();
    dispatch(userLogout());
    dispatch(clearChangePassword());
    navigate("/");
  }

  useEffect(() => {
    if(passChangeMsg) {
      logout();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[passChangeMsg])

  const [formData, setFormData] = useState(passwordData);

  const toggleEdit = (e) => {
    setShowEdit(!showEdit);
  };
  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(sendOtp());
    setErrors(validatePassword(formData));
  };

  useEffect(()=>{
    setErrors(validatePassword(formData))
  },[formData]);

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      toggleEdit();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailOtp]);

  const handleUserInput = (e) => {
    if(e.target.name==="currentPassword") {
      setTouched({...touched,currentPassword: true})
    } else if(e.target.name==="newPassword") {
      setTouched({...touched,newPassword:true})
    }else if(e.target.name==="confirmPassword") {
      setTouched({...touched,confirmPassword:true})
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  };

  function validatePassword(values) {
    let errors = {};
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;

    if (!values.currentPassword) {
      errors.currentPassword = "Current Password is required";
    }
    if (!values.newPassword) {
      errors.newPassword = "New Password is required";
    } else if (!re.test(values.newPassword)) {
      errors.newPassword =
        "Your password must contain at least one uppercase, one lowercase, a special character, and must be at least 8 characters";
    }
    if (values.confirmPassword !== values.newPassword) {
      errors.cNewPassword = "Password does not match";
    }

    return errors;
  }


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowNewPassword = () => {
    setNewPassword(!newPassword);
  };

  const handleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };

  const handleChangePassword = () => {
    const { currentPassword, newPassword } = formData;
    const data = {
      oldPassword: currentPassword,
      newPassword,
      otp: token,
    };
    dispatch(changeUserPassword(data))
  }

  return (
    <>
      <TopBar />
      <>
        <Section>
          <PersonalDetalLayout>
            <Box sx={container}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box 
                  container 
                  alignItems={"center"} 
                  sx={{
                    mb:3,
                    mt:3,
                    display:{md:"none",lg:"none", xs: "flex", sm: "flex"},
                    alignItems: "center",
                    gap: 2
                  }} 
                >
                  <Grid item xs={2} sm={2} >
                    <Link to="/profile" >
                      <ArrowBackIosNew />
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={8} md={12} lg={12} >
                    <Typography
                      sx={headingText}
                      style={{whiteSpace:"nowrap"}}
                    >
                      Change Password
                    </Typography>
                  </Grid>
                </Box>
                <Typography
                  sx={headingText}
                  variant={"h6"}
                  style={{display:width<=700&&"none"}}
                >
                  Change Password
                </Typography>
                <Box>
                  {showEdit ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                        <Buttons
                          color="primary"
                          width={width<=700 ? "60px" :  "72px"}
                          fontSize={width<=700 && "14px"}
                          onClick={handleSendOtp}
                        >
                          Edit
                        </Buttons>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                        <Buttons
                          color="primary"
                          width={width<=700 ? "60px" :  "72px"}
                          fontSize={width<=700 && "14px"}
                          onClick={toggleEdit}
                        >
                          Cancel
                        </Buttons>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
              <BlockSpacer mt={50} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormAligner>
                    <FormLabel text={"Current Password"} />
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="currentPassword"
                      onChange={handleUserInput}
                      disabled={showEdit}
                      value={formData.currentPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {(errors.currentPassword && touched?.currentPassword) && (
                    <span style={{color:"#FF0000"}} >{errors.currentPassword}</span>
                  )}
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer mt={30} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormAligner>
                    <FormLabel text={"New Password"} />
                    <TextField
                      variant="outlined"
                      type={newPassword ? "text" : "password"}
                      name="newPassword"
                      disabled={showEdit}
                      onChange={handleUserInput}
                      value={formData.newPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowNewPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {(errors?.newPassword && touched?.newPassword) && (
                    <span style={{color:"#FF0000"}} >{errors.newPassword}</span>
                  )}
                  </FormAligner>
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormAligner>
                    <FormLabel text={"Confirm Password"} />
                    <TextField
                      variant="outlined"
                      type={confirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      disabled={showEdit}
                      onChange={handleUserInput}
                      value={formData.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleConfirmPassword}
                            >
                              {confirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {(errors.cNewPassword && touched?.confirmPassword) && (
                    <span style={{color:"#FF0000"}} >{errors.cNewPassword}</span>
                  )}
                  </FormAligner>
                </Grid>
              </Grid>

              {/* <BlockSpacer mt={50} />
                            <FooterCheckout /> */}
            </Box>
            <BlockSpacer mt={100} />
            <FooterCheckout 
              proceedInfoButton={"Save"} 
              handleOpenSuccessMesage={handleOpenSuccessMesage}
              handleClose={()=>handleChangePassword()}
              isLoading={loading}
              disabled={showEdit}
            />
          </PersonalDetalLayout>

          {/* <BlockSpacer mt={50} />
                            <FooterCheckout /> */}

          {showEmailOtpModal && (
            <OtpVerificationEmailModal
              open={showEmailOtpModal}
              handleClose={handleOTPModalClose}
              handleOtpChange={(otp) => createOtp(otp)}
              otp={token}
              resendOtp={() => {
                dispatch(sendOtp());
              }}
              otpToken={otp?.data}
              handleSuccessOpen={() => handleEmailOtpSubmit()}
            />
          )}
          {openSuccess && (
            <SuccessModal
              open={openSuccess}
              handleClose={handleSuceesMessageClose}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default ProfileChangePassword;
