import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import style from "./style.module.css";
import { Link } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function SignInForgotPassword() {
  return (
    <div className={style.container}>
      <div>
        <Checkbox
          {...label}
          defaultChecked
          style={{
            color: "#1C448D",
          }}
        />{" "}
        Stay signed in
      </div>
      <Link to="/auth/forgot-password">
        <div className={style.forgotPassword}>Forgot Password?</div>
      </Link>
    </div>
  );
}

export default SignInForgotPassword;
