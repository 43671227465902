import styled from "@emotion/styled";

export const ProfileContain = styled.div`
    padding-top: 14px;
    padding-left: 10px;
    margin-bottom: 16px;
    display: flex;
    gap: 12px;
    .profile-detail {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:2px;
        column-gap: 2px;
    }
    .profile {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 0;
        letter-spacing: -0.04em;
        text-decoration: underline;
        pointer: cursor;
        color: #111E6C;
    }
`

export const ProfileNav = styled.p`
    font-family: 'Montserrat';
    font-style: 'normal';
    font-weight: 500;
    font-size: '13px';
    line-height: '20px';
    letter-spacing: '-0.04em';
    text-decoration: 'underline';
    pointer: 'cursor';
    color: '#111E6C';
`

export const  Nav = styled.nav`
left: 0;
// width: 100vw;
position: fixed;
width: 100%;
z-index: 10;
//  z-index: 99999999;
 height: 101px;
background: #FFFFFF;
box-shadow: 4px 0px 4px rgba(196, 204, 221, 0.12);
`