import React from "react";
import { Link } from "react-router-dom";
import Mail from "../../../assets/mail.svg";
import style from "./style.module.css";

const VerifyEmailSuccess = () => {
  return (
    <div className={style.container}>
      <div>
        <div className={style.verifyMsg}>
          <div className={style.verifyIcon}>
            <img src={Mail} alt="mail" className={style.icon} />
          </div>
          <div className={style.verifyText}>
            <h2 className={style.header} >Congratulations</h2>
            <p className={style.text} >Your email has been verified successfully</p>
          </div>
        </div>
        <div className={style.btnContain}>
          <Link to="/" style={{width:"100%"}} >
            <button className={style.verifyBtn}>OK</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailSuccess;
