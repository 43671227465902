import HttpService from "./HttpService";

export const loginService = (formData) => {
  const http = new HttpService();
  const url = "login";

  return http.postData(formData, url);
};

export const registerService = (formData) => {
  const http = new HttpService();
  const url = "users";

  return http.postData(formData, url);
};

export const getUserTypesService = () => {
  const http = new HttpService();
  const url = "users/get-user-types";

  return http.getData(url);
};

export const getCorporateTypesService = () => {
  const http = new HttpService();
  const url = "users/get-business-types";

  return http.getData(url);
};

export const resetPasswordService = (formData) => {
  const http = new HttpService();
  const url = "users/reset-password";

  return http.postData(formData, url);
};
