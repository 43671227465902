import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// page routes
import CompanyKyc from "./pages/auth/corperate-kyc/index.jsx";
import SignupEmailVerification from "./pages/auth/email-verification/index.jsx";
import ForgotPassword from "./pages/auth/forgot-password/index.jsx";
import Login from "./pages/auth/login/index.jsx";
import CompanyBankDetails from "./pages/auth/profile/company-user/bank-details/index.jsx";
import BusinessDocument from "./pages/auth/profile/company-user/business-document/index.jsx";
import CompanyUserPassword from "./pages/auth/profile/company-user/change-password/index.jsx";
import ProfileCompanyInfo from "./pages/auth/profile/company-user/company-info/index.jsx";
import CompanyDirectorProfile from "./pages/auth/profile/company-user/directors/index.jsx";
import ProfileChangePassword from "./pages/auth/profile/personal-details/change-password/index.jsx";
import ProfileMyDument from "./pages/auth/profile/personal-details/my-document/index.jsx";
import ProfileBankDetails from "./pages/auth/profile/personal-details/mybank-detail/index.jsx";
import ProfilePersonalInfo from "./pages/auth/profile/personal-details/personal-info/index.jsx";
import ResetLinkSuccess from "./pages/auth/reset-link-success/index.jsx";
import {
  default as ResetPassword,
  default as ResetPasswordSuccess,
} from "./pages/auth/reset-password/index.jsx";
import Signup from "./pages/auth/signup/index.jsx";
import SignupSuccess from "./pages/auth/signup/success/index.jsx";
import QuickLoano from "./pages/get-credit/quick-loan(pens)/index";
import VehicleAssets from "./pages/get-credit/collateral/Vehicle-asset-no/index.jsx";
import PaydayTermsCondition from "./pages/get-credit/quick-payday-terms/index.jsx";
import VehicleloanSummaryNoBusiness from "./pages/get-credit/collateral/vehicle-loan-summary-no-salary-earner/index.jsx";
import NoBusinessQuickPayday from "./pages/get-credit/quick-payday-loan(no)/index";
import SideBusinessQuickPayday from "./pages/get-credit/quick-payday-loan(side)/index";
import PensionerCredit from "./pages/get-credit/Pensioner/loan.jsx";
import OpenTickets from "./pages/Open-Ticket/index";
import CashBacks from "./pages/get-credit/collateral/cash-back-side-business/index.jsx";
import ClosedTickets from "./pages/Closed-Tickets/index.jsx";
import ProprietorTermsCondition from "./pages/get-credit/proprietor-asset-term/index";
import PersonalTermCondition from "./pages/get-credit/personal-asset-terms/index";
import NoBusinessQuickLoan from "./pages/get-credit/quick-loan(no)/index.jsx";
import BusinessQuickLoan from "./pages/get-credit/quick-loan(side)/index.jsx";
import PersonalChoosePay from "./pages/get-credit/personal-choose-payment/index.jsx";
import UserKyc from "./pages/auth/user-kyc/index.jsx";
import Dashboard from "./pages/dashboard/index.js";
import FeedBack from "./pages/feedback/index.js";
import MyCredit from "./pages/my-credit/index.js";
import PendingTransaction from "./pages/my-credit/pending-transaction/pending.js";
import ClosedTransaction from "./pages/my-credit/closed-transaction/closed.js";
import CorporateCredit from "./pages/get-credit/Corporate Loan/loan.jsx";
import ActiveTransaction from "./pages/my-credit/active-transaction/active.js";
import ProcessingStage from "./pages/my-credit/pending-transaction/processing/processing.js";
import SoleProprietorLoan from "./pages/get-credit/sole-proprietor/loan.jsx";
import PaidOff from "./pages/my-credit/closed-transaction/Paid Off/paid.js";
import DocumentsUpload from "./pages/my-credit/pending-transaction/documents-upload/documents-upload.js";
import FinanceLeaseSummary from "./pages/get-credit/lease-summary/index.jsx";
import NoSalaryLoan from "./pages/get-credit/salary-with-no-side-business/loan.jsx";
import WithDrawn from "./pages/my-credit/closed-transaction/Withdrawn/withdrawn.js";
import SignOffer from "./pages/my-credit/pending-transaction/sign-offer/sign-offer.js";
import Declined from "./pages/my-credit/closed-transaction/Declined/declined.js";
import RequiredDocument from "./pages/my-credit/Required Document/required-documents.js";
import SoleProprietorBusiness from "./pages/get-credit/sole-proprietor-business-loan/proprietor.jsx";
import QuickloanNoBusinessSummary from "./pages/get-credit/collateral/cash-loan-summary(no-side-business)/index.jsx";
import QuickloanBusinessSummary from "./pages/get-credit/collateral/cash-loan-summary(side-business)/index.jsx";
import GetCredit from "./pages/get-credit/index.jsx";
import CardPayment from "./pages/get-credit/card-payment/index.jsx";
import ChoosePayment from "./pages/get-credit/chose-payment/index.jsx";
import CashbackLoan from "./pages/get-credit/collateral/cash-back/index.jsx";
import CorporateCashBack from "./pages/get-credit/collateral/cash-back(corporate)/index.jsx";
import SoleProprietorCashBack from "./pages/get-credit/collateral/cash-back(sole-proprietor)/index.jsx";
import CashbackSummary from "./pages/get-credit/collateral/cash-loan-summary/index.jsx";
import CashbackChoosePayment from "./pages/get-credit/collateral/choose-payment/index.jsx";
import ChoosePaymentNoBusiness from "./pages/get-credit/collateral/choose-payment(no-business)/index";
import ChoosePaymentSideBusiness from "./pages/get-credit/collateral/choose-payment(side-business)/index.jsx";
import CashbackChooseTerm from "./pages/get-credit/collateral/terms/index.jsx";
import VehicleAsset from "./pages/get-credit/collateral/Vehicle-asset/vehicle.jsx";
import VehicleloanSummary from "./pages/get-credit/collateral/vehicle-loan-summary/index";
import ConsumerTermsCondition from "./pages/get-credit/consumer-asset-term/index.jsx";
import ConsumerBusiness from "./pages/get-credit/consumer-business-loan/consumer.jsx";
import ConsumerPay from "./pages/get-credit/Consumer-choose-payment/index";
import ConsumerloanSummary from "./pages/get-credit/consumer-loan-summary/index.jsx";
import LandAssetTermsCondition from "./pages/get-credit/land-asset-terms/index";
import LandAsset from "./pages/get-credit/Land-choose-payment/index.jsx";
import CashBacked from "./pages/get-credit/collateral/cash-back-pensioner/index.jsx";
import VehicleTermsConditions from "./pages/get-credit/vehicle-asset-terms/index";
import PersonalLoanSummary from "./pages/get-credit/Personal-loan-summary/index";
import FinanceLease from "./pages/get-credit/LEASE/finance-lease/finance.jsx";
import ChoosePayday from "./pages/get-credit/quickpayday-chose-payment/index";
import TopUp from "./pages/my-credit/active-transaction/Top-up/Top-up.js";
import ViewHistory from "./pages/my-credit/active-transaction/view history/index";
import LandAssetNoBusinessTermsCondition from "./pages/get-credit/land-asset-terms(no)/index.jsx";
import LandloanSummaryNoBusiness from "./pages/get-credit/Landed-loan-summary(no)/index";
import VehiclePay from "./pages/get-credit/vehicle-chose-payment/index";
import QuickPayday from "./pages/get-credit/quick-payday-loan/index.jsx";
import ProprietorloanSummary from "./pages/get-credit/proprietor-summary-loan/index.jsx";
import SolePay from "./pages/get-credit/proprietor-choose-payment/index.jsx";
import PayOff from "./pages/my-credit/active-transaction/Pay-Off/Pay-off.js";
import LandloanSummary from "./pages/get-credit/Landed-loan-summary/index.jsx";
import LandProperty from "./pages/get-credit/Landed-Property-Asset/land.jsx";
import QuickLoan from "./pages/get-credit/quick-loan/index.jsx";
import QuickloanSummary from "./pages/get-credit/quick-loan-summary/index.jsx";
import SalaryLoan from "./pages/get-credit/salary-earner-with-side-business/loan.jsx";
import StudentLoan from "./pages/get-credit/student-loan/loan.jsx";
import TeamCondition from "./pages/get-credit/terms/index.jsx";
import Help from "./pages/help/index";
import Extension from "./pages/my-credit/active-transaction/Extension/Extension.js";
import Repayment from "./pages/my-credit/active-transaction/Repayment/repay.js";
import CardPayday from "./pages/get-credit/card-payday/index.jsx";
import NoBusinessCardPayday from "./pages/get-credit/card-payday(no)/index.jsx";
import SideBusinessCardPayday from "./pages/get-credit/card-payday(side)/index.jsx";
import SoleProprietorVehicleTermsCondition from "./pages/get-credit/vehicle-asset-terms(sole-proprietor)/index.jsx";
import CorporateVehicleTermsCondition from "./pages/get-credit/vehicle-asset-terms(corporate)/index.jsx";
import MyTickets from "./pages/My-Tickets/index.jsx";
import PersonalLoan from "./pages/get-credit/Personal-loan/personal.jsx";
import NoBusinessPersonalLoan from "./pages/get-credit/Personal-loan(no)/personal.jsx";
import SideBusinessPersonalLoan from "./pages/get-credit/Personal-loan(side)/personal.jsx";
import QuickpaydaySummary from "./pages/get-credit/quick-payday-summary/index.jsx";
import History from "./pages/wallet/history/history.js";
import CorporateQuickloanSummary from "./pages/get-credit/collateral/cash-loan-summary(corporate)/index.jsx";
import SoleProprietorQuickloanSummary from "./pages/get-credit/collateral/cash-loan-summary(sole-proprietor)/index.jsx";
import SideBusinessChoosePayday from "./pages/get-credit/quickpayday-chose-payment(side)/index.jsx";
import NoBusinessChoosePayday from "./pages/get-credit/quickpayday-chose-payment(no)/index.jsx";
import CorporateLease from "./pages/get-credit/LEASE/Corporate-Lease/corporate.jsx";
import LandAssetNoBusiness from "./pages/get-credit/Land-choose-payment(no)/index.jsx";
import SoleLease from "./pages/get-credit/sole-finance-lease/index";
import SoleLeaseSummary from "./pages/get-credit/lease-summary-sole/index";
import CorporateFinanceLease from "./pages/get-credit/finance-lease-corporate/index";
import Wallet from "./pages/wallet/index.js";
import MyReferral from "./pages/wallet/My Referrals/index.js";
import CorporateFinanceLeaseSummary from "./pages/get-credit/lease-summary-corporate/index.jsx";
import LandPropertyNoBusiness from "./pages/get-credit/Landed-Property-Asset(no)/land.jsx";
import VehicleNoBusinessTermsCondition from "./pages/get-credit/vehicle-asset-terms-no/index";
import LeaseCredit from "./pages/get-credit/LEASE/lease.jsx";
import ReferralBonus from "./pages/wallet/Poked Referrals/index.js";
import SideBusinessPaydayTermsCondition from "./pages/get-credit/quick-payday-terms(side)/index.jsx";
import NoBusinessPaydayTermsCondition from "./pages/get-credit/quick-payday-terms(no)/index.jsx";
import Rosabon from "./pages/wallet/Rosabon/index.js";
import BusinessChoosePayment from "./pages/get-credit/chose-payment(side)/index.jsx";
import NoBusinessCardPayment from "./pages/get-credit/card-payment(no)/index";
import BusinessCardPayment from "./pages/get-credit/card-payment(side)/index.jsx";
import NoBusinessChoosePayment from "./pages/get-credit/chose-payment(no)/index.jsx";
import SideBusinessQuickpaydaySummary from "./pages/get-credit/quick-payday-summary(side)/index.jsx";
import NoBusinessQuickpaydaySummary from "./pages/get-credit/quick-payday-summary(no)/index.jsx";
import VehicleNoBusinessPay from "./pages/get-credit/vehicle-choose-payment-no-business/index.jsx";
import LeaseSalary from "./pages/get-credit/lease-choose-payment/index";
import NoSideBusinessTermsCondition from "./pages/get-credit/terms(no)/index.jsx";
import SideBusinessTermsCondition from "./pages/get-credit/terms(side)/index.jsx";
import LeaseTermsCondition from "./pages/get-credit/lease-term/index.jsx";
import NoBusinessQuickloanSummary from "./pages/get-credit/quick-loan-summary(no)/index.jsx";
import SideBusinessPersonalChoosePay from "./pages/get-credit/personal-choose-payment(side)/index.jsx";
import SoleProprietorVehicleloanSummary from "./pages/get-credit/collateral/vehicle-loan-summary(sole-vehicle)/index.jsx";
import SoleProprietorVehiclePay from "./pages/get-credit/vehicle-chose-payment(sole-proprietor)/index.jsx";
import CorporateVehiclePay from "./pages/get-credit/vehicle-chose-payment(corporate)/index.jsx";
import CorporateVehicleloanSummary from "./pages/get-credit/collateral/vehicle-loan-summary(corporate-vehicle)/index.jsx";
import CorporateLandAssetTermsCondition from "./pages/get-credit/land-asset-terms(corporate)/index.jsx";
import SoleProprietorLandAssetTermsCondition from "./pages/get-credit/land-asset-terms(sole-proprietor)/index.jsx";
import NoBusinessPersonalChoosePay from "./pages/get-credit/personal-choose-payment(no)/index.jsx";
import BusinessQuickloanSummary from "./pages/get-credit/quick-loan-summary(side)/index.jsx";
import BusinessTermsCondition from "./pages/get-credit/collateral/terms(side-business)/index.jsx";
import CorporateLandProperty from "./pages/get-credit/Landed-Property-Asset(corporate)/land.jsx";
import SoleProprietorLandProperty from "./pages/get-credit/Landed-Property-Asset(sole-proprietor)/land.jsx";
import CorporateChoosePayment from "./pages/get-credit/collateral/choose-payment(corporate)/index.jsx";
import SoleProprietorChoosePayment from "./pages/get-credit/collateral/choose-payment(sole-proprietor)/index.jsx";
import CorporateTermsCondition from "./pages/get-credit/collateral/terms(corporate)/index.jsx";
import SoleProprietorTermsCondition from "./pages/get-credit/collateral/terms(sole-proprietor)/index.jsx";
import SoleProprietorLandloanSummary from "./pages/get-credit/Landed-loan-summary(sole-proprietor)/index.jsx";
import CorporateLandloanSummary from "./pages/get-credit/Landed-loan-summary(corporate)/index.jsx";
import NoBusinessTermsCondition from "./pages/get-credit/collateral/terms(no-side-business)/index.jsx";
import NoBusinessCashBacks from "./pages/get-credit/collateral/cash-back-no-side-business/index.jsx";
import CorporatePay from "./pages/get-credit/Corporate-choose-payment/index.jsx";
import SoleProprietorQuickLoan from "./pages/get-credit/quick-loan(sole-proprietor)/index.jsx";
import CorporateLandAsset from "./pages/get-credit/Land-choose-payment(corporate)/index.jsx";
import SoleProprietorLandAsset from "./pages/get-credit/Land-choose-payment(sole-proprietor)/index.jsx";
import CorporateVehicleAsset from "./pages/get-credit/collateral/vehicle-asset-corporate/index.jsx";
import CorporateLeaseTermsCondition from "./pages/get-credit/corporate-lease-terms/index.jsx";
import ProprietorLease from "./pages/get-credit/LEASE/sole-proprietor/sole.jsx";
import NoBusinessPersonalLoanSummary from "./pages/get-credit/Personal-loan-summary(no)/index.jsx";
import SideBusinessPersonalLoanSummary from "./pages/get-credit/Personal-loan-summary(side)/index.jsx";
import NoBusinessPersonalTermCondition from "./pages/get-credit/personal-asset-terms(no)/index.jsx";
import SoleProprietorVehicleAssets from "./pages/get-credit/collateral/vehicle-asset-sole/index.jsx";
import TicketDetails from "./pages/feedback/ticket-details.js";
import SideBusinessPersonalTermCondition from "./pages/get-credit/personal-asset-terms(side)/index.jsx";
import EditTicket from "./pages/feedback/ticket-edit.js";
import ProfilePage from "./pages/auth/profile/profile-page/index.jsx";
import DashboardLayout from "./components/DashboardLayout/index.js";
import VerifyEmailSuccess from "./pages/auth/verify-email-success/index.jsx";
import CompanyBusinessBankDetails from "./pages/auth/profile/company-user/business-bank-details/index.jsx";
import MyBusinessBankDetails from "./pages/auth/profile/personal-details/business-bank-details/index.jsx";

import { ToastContainer } from "react-toastify";
import LoanCredit from "./pages/getCredit/loans/index.jsx";
import UploadLoanDocs from "./pages/get-credit/docs-page/index.jsx";
import UploadPendingDocs from "./pages/my-credit/pending-transaction/docs-page/index.jsx";
import SoleProprietorVehicle from "./pages/get-credit/soleProprietor-vehicle/index.jsx";
import { userLogout } from "./redux/slices/authSlice.js";
import { useDispatch } from "react-redux";
import UsertypeUpdate from "./pages/auth/usertype-update/index.jsx";
import GroupLoan from "./pages/get-credit/group-loan/index.jsx";

const PageRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [lastActivity, setLastActivity] = React.useState(Date.now());
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (
  //     location.pathname !== "/" ||
  //     location.pathname !== "/auth/forgot-password" ||
  //     location.pathname !== "/auth/reset-password" ||
  //     location.pathname !== "/auth/reset-link-success" ||
  //     location.pathname !== "/auth/signup" ||
  //     location.pathname !== "/auth/signup/success" ||
  //     location.pathname !== "/user/kyc" ||
  //     location.pathname !== "/company/kyc" ||
  //     location.pathname !== "/auth/email-verification" ||
  //     location.pathname !== "/auth/email-verification/success"
  //   ) {
  //     const intervalId = setInterval(() => {
  //       const now = Date.now();
  //       const elapsedTime = now - lastActivity;
  //       const minutesSinceActivity = Math.floor(elapsedTime / (1000 * 60));

  //       if (minutesSinceActivity >= 10) {
  //         localStorage.clear();
  //         dispatch(userLogout());
  //         setLastActivity(now);
  //         navigate("/");
  //         console.log("hello");
  //       }
  //     }, 1000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [lastActivity]);

  const handleUserActivity = () => {
    setLastActivity(Date.now());
  };

  return (
    <div onMouseMove={handleUserActivity} onClick={handleUserActivity}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        {/* <Route
          path="/auth/reset-password-success"
          element={<ResetPasswordSuccess />}
        /> */}
        <Route path="/auth/reset-link-success" element={<ResetLinkSuccess />} />
        <Route path="/auth/signup" element={<Signup />} />
        <Route path="/auth/signup/success" element={<SignupSuccess />} />
        <Route path="/user/kyc" element={<UserKyc />} />
        <Route path="/company/kyc" element={<CompanyKyc />} />
        <Route
          path="/auth/email-verification"
          element={<SignupEmailVerification />}
        />
        <Route
          path="/auth/email-verification/success"
          element={<VerifyEmailSuccess />}
        />
        <Route path="/update-usertype" element={<UsertypeUpdate />} />
        <Route path="/*" element={<DashboardRoutes />} />
      </Routes>
    </div>
  );
};

const DashboardRoutes = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route path="/rosabon-earnings" element={<Rosabon />} />
        <Route path="/get-credit/loans" element={<LoanCredit />} />
        {/* <Route
          path="/vehicle-asset/corporate/terms"
          element={<CorporateVehicleTermsCondition />}
        /> */}
        {/* <Route
          path="/vehicle-asset/sole-proprietor/terms"
          element={<SoleProprietorVehicleTermsCondition />}
        /> */}
        {/* <Route
          path="/quick-loan-sole-proprietor"
          element={<SoleProprietorQuickLoan />}
        /> */}
        <Route
          path="/feedback/my-tickets/edit-tickets"
          element={<EditTicket />}
        />
        {/* <Route
          path="/cash-back-corporate/summary"
          element={<CorporateQuickloanSummary />}
        /> */}
        <Route
          path="/feedback/my-tickets/:ticketid"
          element={<TicketDetails />}
        />
        {/* <Route
          path="/cash-back-sole-proprietor/summary"
          element={<SoleProprietorQuickloanSummary />}
        /> */}
        {/* <Route
          path="/vehicle-loan/corporate/choose-payment"
          element={<CorporateVehiclePay />}
        /> */}
        {/* <Route
          path="/cash-back-sole-proprietor/choose-payment"
          element={<SoleProprietorChoosePayment />}
        /> */}
        {/* <Route
          path="/cash-back-sole-proprietor/terms"
          element={<SoleProprietorTermsCondition />}
        /> */}
        {/* <Route
          path="/cash-back-corporate/terms"
          element={<CorporateTermsCondition />}
        /> */}
        {/* <Route
          path="/cash-back-corporate/choose-payment"
          element={<CorporateChoosePayment />}
        /> */}
        {/* <Route
          path="/vehicle-loan/sole-proprietor/choose-payment"
          element={<SoleProprietorVehiclePay />}
        /> */}
        {/* <Route
          path="/Land-loan/sole-proprietor/summary"
          element={<SoleProprietorLandloanSummary />}
        /> */}
        {/* <Route
          path="/Land-loan/corporate/summary"
          element={<CorporateLandloanSummary />}
        /> */}
        {/* <Route
          path="/corporate-loan/landed-asset"
          element={<CorporateLandProperty />}
        /> */}
        <Route
          path="/sole-proprietor-loan/landed-asset/:id"
          element={<SoleProprietorLandProperty />}
        />
        {/* <Route
          path="/vehicle-loan/sole-proprietor/summary"
          element={<SoleProprietorVehicleloanSummary />}
        /> */}
        {/* <Route path="/cash-back-corporate" element={<CorporateCashBack />} /> */}
        {/* <Route
          path="/cash-back-sole-proprietor"
          element={<SoleProprietorCashBack />}
        /> */}
        {/* <Route
          path="/vehicle-loan/corporate/summary"
          element={<CorporateVehicleloanSummary />}
        /> */}
        {/* <Route
          path="/vehicle-asset-loan/sole-proprietor"
          element={<SoleProprietorVehicleAssets />}
        /> */}
        {/* <Route
          path="/personal-loan/no-side-business/terms"
          element={<NoBusinessPersonalTermCondition />}
        /> */}
        {/* <Route
          path="/personal-loan/side-business/terms"
          element={<SideBusinessPersonalTermCondition />}
        /> */}
        {/* <Route
          path="/vehicle-asset-loan/corporate"
          element={<CorporateVehicleAsset />}
        /> */}
        {/* <Route
          path="/land-asset/sole-proprietor/choose-payment"
          element={<SoleProprietorLandAsset />}
        /> */}
        {/* <Route
          path="/land-asset/sole-proprietor/terms"
          element={<SoleProprietorLandAssetTermsCondition />}
        /> */}
        {/* <Route
          path="/land-asset/corporate/terms"
          element={<CorporateLandAssetTermsCondition />}
        /> */}
        {/* <Route
          path="/land-asset/corporate/choose-payment"
          element={<CorporateLandAsset />}
        /> */}
        {/* <Route
          path="/quick-payday/side-business/terms-condition"
          element={<SideBusinessPaydayTermsCondition />}
        /> */}
        {/* <Route
          path="/personal-loan/no-side-business/choose-payment"
          element={<NoBusinessPersonalChoosePay />}
        /> */}
        {/* <Route
          path="/personal-loan/side-business/choose-payment"
          element={<SideBusinessPersonalChoosePay />}
        /> */}
        {/* <Route
          path="/quick-payday/no-side-business/terms-condition"
          element={<NoBusinessPaydayTermsCondition />}
        /> */}
        {/* <Route
          path="/quick-loan/side-business/terms"
          element={<SideBusinessTermsCondition />}
        /> */}
        {/* <Route
          path="/quick-loan/no-side-business/terms"
          element={<NoSideBusinessTermsCondition />}
        /> */}
        {/* <Route
          path="/personal-loan/no-side-business/summary"
          element={<NoBusinessPersonalLoanSummary />}
        /> */}
        {/* <Route
          path="/personal-loan/side-business/summary"
          element={<SideBusinessPersonalLoanSummary />}
        /> */}
        {/* <Route
          path="/cash-back/no-side-business/terms"
          element={<NoBusinessTermsCondition />}
        /> */}
        {/* <Route
          path="/cash-back/side-business/terms"
          element={<BusinessTermsCondition />}
        /> */}
        {/* <Route
          path="/land-asset/no-business/choose-payment"
          element={<LandAssetNoBusiness />}
        /> */}
        <Route path="/feedback/my-tickets" element={<MyTickets />} />
        {/* <Route
          path="/vehicle/loan/summary/no-business"
          element={<VehicleloanSummaryNoBusiness />}
        /> */}
        {/* <Route
          path="/quick-loan/no-side-business/choose-payment"
          element={<NoBusinessChoosePayment />}
        /> */}

        {/* <Route
          path="/quick-loan/side-business/choose-payment"
          element={<BusinessChoosePayment />}
        /> */}
        {/* <Route
          path="/land-loan/no-business/summary"
          element={<LandloanSummaryNoBusiness />}
        /> */}
        {/* <Route
          path="/quick-loan/no-side-business/summary"
          element={<NoBusinessQuickloanSummary />}
        /> */}
        <Route path="/feedback/closed-tickets" element={<ClosedTickets />} />
        {/* <Route
          path="/quick-loan/side-business/summary"
          element={<BusinessQuickloanSummary />}
        /> */}
        {/* <Route
          path="/cash-back/no-side-business/choose-payment"
          element={<ChoosePaymentNoBusiness />}
        /> */}
        {/* <Route
          path="/cash-back/side-business/choose-payment"
          element={<ChoosePaymentSideBusiness />}
        /> */}
        {/* <Route
          path="/consumer-asset-terms"
          element={<ConsumerTermsCondition />}
        /> */}
        {/* <Route
          path="/quick-payday/side-business/card-payment"
          element={<SideBusinessCardPayday />}
        /> */}
        {/* <Route
          path="/quick-payday/no-side-business/card-payment"
          element={<NoBusinessCardPayday />}
        /> */}
        {/* <Route
          path="/cash-back/no-side-business/summary"
          element={<QuickloanNoBusinessSummary />}
        /> */}
        {/* <Route
          path="/cash-back/side-business/summary"
          element={<QuickloanBusinessSummary />}
        /> */}
        {/* <Route
          path="/quick-payday/side-business"
          element={<SideBusinessQuickPayday />}
        /> */}
        {/* <Route
          path="/quick-payday/no-side-business"
          element={<NoBusinessQuickPayday />}
        /> */}
        <Route path="/lease/terms" element={<LeaseTermsCondition />} />
        {/* <Route
          path="/proprietor-asset-terms"
          element={<ProprietorTermsCondition />}
        /> */}
        {/* <Route
          path="/cash-back/no-side-business"
          element={<NoBusinessCashBacks />}
        /> */}
        {/* <Route
          path="/vehicle/asset/no-salary-earner"
          element={<VehicleAssets />}
        /> */}
        {/* <Route
          path="/land/asset/no-business"
          element={<LandPropertyNoBusiness />}
        /> */}
        {/* <Route
          path="/lease/sole-proprietor/summary"
          element={<SoleLeaseSummary />}
        /> */}
        {/* <Route path="/choose-pay" element={<CorporatePay />} /> */}
        <Route
          path="/finance-lease-summary"
          element={<FinanceLeaseSummary />}
        />
        {/* <Route
          path="/finance-lease/corporate/summary"
          element={<CorporateFinanceLeaseSummary />}
        /> */}
        <Route path="/corporate-loan" element={<CorporateCredit />} />
        {/* <Route path="/sole-proprietor/choose-payment" element={<SolePay />} /> */}
        {/* <Route path="/cash-back/pensioner" element={<CashBacked />} /> */}
        {/* <Route path="/quick-loan/pensioner" element={<QuickLoano />} /> */}
        {/* <Route
          path="/sole-proprietor-business"
          element={<SoleProprietorBusiness />}
        /> */}
        {/* <Route
          path="/vehicle/loan/no-business-choose-payment"
          element={<VehicleNoBusinessPay />}
        /> */}
        {/* <Route
          path="/finance-lease/corporate"
          element={<CorporateFinanceLease />}
        /> */}
        <Route path="/lease/corporate" element={<CorporateLease />} />
        <Route path="/lease/choose-payment" element={<LeaseSalary />} />
        <Route path="/lease/sole-proprietor" element={<ProprietorLease />} />
        <Route
          path="/personal-asset-terms"
          element={<PersonalTermCondition />}
        />
        {/* <Route
          path="/corporate/terms"
          element={<CorporateLeaseTermsCondition />}
        /> */}
        {/* <Route path="/quick/loan/card-payment" element={<CardPayment />} /> */}
        {/* <Route
          path="/quick-loan/side-business/card-payment"
          element={<BusinessCardPayment />}
        /> */}
        {/* <Route
          path="/quick-loan/no-side-business/card-payment"
          element={<NoBusinessCardPayment />}
        /> */}
        <Route path="/feedback/open-tickets" element={<OpenTickets />} />
        <Route path="/finance-lease/sole-proprietor" element={<SoleLease />} />
        <Route path="/lease" element={<LeaseCredit />} />
        <Route
          path="/personal-loan/choose-payment"
          element={<PersonalChoosePay />}
        />
        <Route path="/finance-lease/:id" element={<FinanceLease />} />
        {/* <Route path="/cash-back/side-business" element={<CashBacks />} /> */}
        {/* <Route
          path="/proprietor/loan/summary"
          element={<ProprietorloanSummary />}
        /> */}
        <Route path="/pensioner-loan" element={<PensionerCredit />} />
        <Route
          path="/personal-loan/summary"
          element={<PersonalLoanSummary />}
        />
        {/* <Route path="/sole-proprietor-loan" element={<SoleProprietorLoan />} /> */}
        <Route path="/quick-payday/:id" element={<QuickPayday />} />

        {/* <Route path="/quick-payday/card-payment" element={<CardPayday />} /> */}

        <Route path="/my-referrals" element={<MyReferral />} />
        <Route path="/wallet-history" element={<History />} />
        <Route
          path="/vehicle-asset-terms"
          element={<VehicleTermsConditions />}
        />
        {/* <Route
          path="/vehicle-asset/no-business/terms"
          element={<VehicleNoBusinessTermsCondition />}
        /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/help" element={<Help />} />
        <Route path="/consumer-business/:id" element={<ConsumerBusiness />} />
        {/* <Route path="/quick-payday/choose-payment" element={<ChoosePayday />} /> */}
        {/* <Route
          path="/quick-payday/side-business/choose-payment"
          element={<SideBusinessChoosePayday />}
        /> */}
        {/* <Route
          path="/quick-payday/no-side-business/choose-payment"
          element={<NoBusinessChoosePayday />}
        /> */}
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/get-credit" element={<GetCredit />} />
        <Route path="/my-credit" element={<MyCredit />} />
        {/* <Route
          path="/quick-payday/terms-condition"
          element={<PaydayTermsCondition />}
        /> */}

        {/* <Route
          path="/quick-loan/no-side-business"
          element={<NoBusinessQuickLoan />}
        /> */}
        {/* <Route
          path="/quick-loan/side-business"
          element={<BusinessQuickLoan />}
        /> */}
        <Route path="/pending-transaction" element={<PendingTransaction />} />
        <Route path="/closed-transaction" element={<ClosedTransaction />} />
        <Route path="/landed-asset-loan/:id" element={<LandProperty />} />
        <Route path="/active-transaction" element={<ActiveTransaction />} />
        <Route path="/top-up/:id" element={<TopUp />} />
        <Route path="/pay-off/:id" element={<PayOff />} />
        {/* <Route path="/quick-payday/summary" element={<QuickpaydaySummary />} /> */}
        {/* <Route
          path="/quick-payday/no-side-business/summary"
          element={<NoBusinessQuickpaydaySummary />}
        /> */}
        {/* <Route
          path="/quick-payday/side-business/summary"
          element={<SideBusinessQuickpaydaySummary />}
        /> */}
        <Route path="/extension" element={<Extension />} />
        <Route path="/view-history/:id" element={<ViewHistory />} />
        <Route path="/repayment/:id" element={<Repayment />} />
        <Route path="/vehicle/loan/summary" element={<VehicleloanSummary />} />
        <Route path="/processing-stage/:id" element={<ProcessingStage />} />
        <Route path="/Land/loan/summary" element={<LandloanSummary />} />
        <Route path="/paid-off" element={<PaidOff />} />
        <Route path="/documents-upload" element={<DocumentsUpload />} />
        <Route path="/withdrawn/:transactionId" element={<WithDrawn />} />
        <Route path="/sign-offer/:transactionId" element={<SignOffer />} />
        <Route path="/declined/:transactionId" element={<Declined />} />
        <Route path="/required-document" element={<RequiredDocument />} />
        <Route path="/feedback" element={<FeedBack />} />
        {/* <Route path="/student-loan" element={<StudentLoan />} /> */}
        {/* <Route path="/land-asset/choose-payment" element={<LandAsset />} /> */}
        {/* <Route path="/salary-earner-loan" element={<SalaryLoan />} /> */}
        {/* <Route path="/no-salary-earner-loan" element={<NoSalaryLoan />} /> */}
        <Route path="/quick-loan/:id" element={<QuickLoan />} />
        <Route path="/group-loan/:id" element={<GroupLoan />} />
        <Route path="/quick/loan/summary" element={<QuickloanSummary />} />
        <Route path="/vehicle/loan/choose-payment" element={<VehiclePay />} />
        <Route path="/quick/loan/choose-payment" element={<ChoosePayment />} />
        <Route path="/quick/loan/team-condition" element={<TeamCondition />} />

        <Route path="/cash-back/:id" element={<CashbackLoan />} />
        <Route path="/personal-loan/:id" element={<PersonalLoan />} />
        {/* <Route
          path="/personal-loan/side-business"
          element={<SideBusinessPersonalLoan />}
        /> */}
        {/* <Route
          path="/personal-loan/no-side-business"
          element={<NoBusinessPersonalLoan />}
        /> */}
        {/* <Route path="/land-asset-terms" element={<LandAssetTermsCondition />} /> */}
        {/* <Route
          path="/consumer/loan/summary"
          element={<ConsumerloanSummary />}
        /> */}
        {/* <Route
          path="/land-asset/no-business/terms"
          element={<LandAssetNoBusinessTermsCondition />}
        /> */}
        {/* <Route path="/consumer/choose-payment" element={<ConsumerPay />} /> */}
        <Route path="/profile/company/info" element={<ProfileCompanyInfo />} />
        {/* <Route path="/cash-back-summary" element={<CashbackSummary />} /> */}
        {/* <Route
          path="/cash-back/choose-payment"
          element={<CashbackChoosePayment />}
        /> */}
        {/* <Route path="/cash-back/terms" element={<CashbackChooseTerm />} /> */}
        <Route path="/vehicle-asset-cash-loan/:id" element={<VehicleAsset />} />
        <Route
          path="/profile/personal-info"
          element={<ProfilePersonalInfo />}
        />
        <Route path="/profile/my-document" element={<ProfileMyDument />} />
        <Route path="/referral-bonus" element={<ReferralBonus />} />
        <Route path="/profile/bank-details" element={<ProfileBankDetails />} />
        <Route
          path="/profile/business-bank-details"
          element={<MyBusinessBankDetails />}
        />
        <Route
          path="/profile/personal-detail/change-password"
          element={<ProfileChangePassword />}
        />

        <Route
          path="/profile/company/business-document"
          element={<BusinessDocument />}
        />
        <Route
          path="/profile/company/bank-details"
          element={<CompanyBankDetails />}
        />
        <Route
          path="/profile/company/business-bank-details"
          element={<CompanyBusinessBankDetails />}
        />
        <Route
          path="/profile/company/change-password"
          element={<CompanyUserPassword />}
        />

        <Route
          path="/profile/company/director"
          element={<CompanyDirectorProfile />}
        />

        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/upload-loan-documents" element={<UploadLoanDocs />} />
        <Route
          path="/vehicle-asset/sole-proprietor/:id"
          element={<SoleProprietorVehicle />}
        />
        <Route
          path="/pending-upload-loan-documents/:transactionId"
          element={<UploadPendingDocs />}
        />
      </Routes>
    </DashboardLayout>
  );
};

export default PageRoutes;
