import Lock from "../components/Assets/icons/Lock.svg";

export const AVAILABLE_USER_TYPES = [
  "Student (Post-secondary)",
  "Salary Earner (Owns a Side Business)",
  "Salary Earner (With No Side Business)",
  "Pensioner",
];

export const COMPANY_USER_TYPES = [
  "Sole proprietor (Registered)",
  "Sole proprietor (Unregistered)",
  "Corporate (Public Listed)",
  "Corporate (Unlisted)",
];

export const HOW_DID_YOU_HEAR_ABOUT_US = [
  "ANOTHER_USER",
  "ROSABON_SALES",
  "OTHER",
];

export const DEFAULT_COUNTRY_CODE = "ng";

export const AVAILABLE_CORPORATE_TYPES = [
  "SOLE_PROPRIETOR_REGISTERED",
  "SOLE_PROPRIETOR_UNREGISTERED",
  "CORPORATE_LISTED",
  "CORPORATE_UNLISTED",
];

export const GENDER = ["Male", "Female"];
export const MARITAL_STATUS = ["Single", "Married", "Divorced"];

export const PROFESSIONAL_OCCUPATION = ["Product Designer", "Others"];

export const EMPLOYER_NAMES = ["Optisoft", "Others"];

export const EMPLOYER_SECTORS = ["Private", "Public"];

export const PAYROLL_HANDLERS = ["IPPIS", "Others"];

export const EMPLOYER_INDUSTRY = [
  "Aerospace",
  "Agriculture",
  "Computer and technology",
  "Construction",
  "Education",
  "Energy",
  "Entertainment",
  "Fashion",
  "Finance and economic",
  "Food and beverage",
  "Health care",
  "Manufacturing",
  "Media and news",
  "Pharmaceutical",
  "Telecommunication",
  "Transportation",
  "Others",
];

export const LIST_OF_STATE = [
  "Abia State",
  "Adamawa State",
  "Akwa Ibom State",
  "Anambra State ",
  "Bauchi State",
  "Bayelsa State",
  "Benue State",
  "Borno State",
  "Cross River State",
  "Delta State",
  "Ebonyi State",
  "Edo State",
  "Ekiti State",
  "Enugu State",
  "Gombe State",
  "Imo State",
  "Jigawa State",
  "Kaduna State",
  "Kano State",
  "Katsina State",
  "Kebbi State",
  "Kogi State",
  "Kwara State",
  "Lagos State",
  "Nasarawa State",
  "Niger State",
  "Ogun State",
  "Ondo State",
  "Osun State",
  "Oyo State ",
  "Plateau State",
  "Rivers State",
  "Sokoto State",
  "Taraba State",
  "Yobe State",
  "Zamfara State",
  "FCT",
];

export const BUSINESS_STATUS = {
  REGISTERED: "Registered",
  UNREGISTERED: "Unregistered",
};

export const AVAILABLE_SCHOOLS = {
  SCHOOL_1: "School1 academy",
  SCHOOL_2: "School2 academy",
};

export const INCOMING_NOTIFACATION = [
  {
    id: 1,
    icon: Lock,
    sucess: "Your password has been successfully changed.",
    successDate: "Redeemed Bonus. July 23, 2021 at 9:15 AM",
  },

  {
    id: 2,
    icon: Lock,
    sucess: "Your password has been successfully changed.",
    successDate: "July 23, 2021 at 9:15 AM",
  },

  {
    id: 3,
    icon: Lock,
    sucess: "Your password has been successfully changed.",
    successDate: "Your password has been successfully changed.",
  },
];

export const LIST_0F_BANK = [
  "First Bank",
  "Access Bank Plc",
  "Guaranty Trust Holding Company Plc",
  "Union Bank of Nigeria Plc",
  "United Bank for Africa Plc",
  "Zenith Bank Plc",
  "Citibank Nigeria Limited",
  "Ecobank Nigeria",
  "Heritage Bank Plc",
  "Keystone Bank Limited",
  "Standard Chartered",
  "Sterling Bank Plc",
];

export const LIST_OF_UTILITY_BILL = [
  "LIGHT_BILL",
  "WATER_BILL",
  "WASTE_BILL",
];

export const COMPANY_STATUS = ["REGISTERED", "UNREGISTERED"];
