import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetCurrentTransactionQuery = () => {
  const getCurrentTransaction = async () => {
    const request = await axiosInstance.get(
      `/auth/my-credit/transactions/running`
    );

    const response = request.data;
    return response;
  };
  const query = useQuery(["CURRENT_TRANSACTION"], getCurrentTransaction);
  return query;
};

export default useGetCurrentTransactionQuery;
