export const cardBox = {
  width: { sx: "100%", sm: "100%", md: "100%", lg: "414.49px" },
  height: { xs: "850px", lg: "859.7px" },
  margin: { xs: "1rem" },
  marginLeft: { lg: "-1.5rem" },
  maxWidth: "100%",
  boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
};

export const summaryTitle = {
  fontFamily: "Montserrat",
  fontSize: "17.8411px",
  fontWeight: "600",
  lineHeight: "25px",
  padding: "1.2rem",
};

export const currencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "13.2925px",

  lineHeight: "16px",
  letterSpacing: "-0.01em",
  color: "#242424",
};

export const secondCurrencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  letterSpacing: "-0.181262px",
  color: "#242424",
  marginTop: "1rem",
};

export const firstCurrencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  letterSpacing: "-0.181262px",
  color: "#242424",
  marginTop: "1rem",
};

export const currencyBlue = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  letterpSacing: "-0.181262px",
  color: "#111E6C",
  marginTop: "1rem",
};

export const Cardstyle = {
  width: { sx: "100%", sm: "100%", md: "100%", lg: "414.49px" },
  boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
  maxWidth: "100%",
  height: "175.39px",
  marginLeft: { lg: "-1.5rem" },
};
