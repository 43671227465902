import React from "react";
import BlockSpacer from "../../BlockSpacer";
import DiscoveryIcon from "../../IconSvgs/DiscoveryIcon";
import LogoWhiteDot from "../../Logo/Alternate";
import style from "./style.module.css";

function KycSideScreen() {
  return (
    <div className={style.container}>
      <LogoWhiteDot />
      <DiscoveryIcon />
      <BlockSpacer mt={55} />
      <div>
        <div className={style.head}>Almost There!</div>
        <BlockSpacer mt={18} />
        <div className={style.subText}>
          We only need a few info to review before unlocking your full access{" "}
        </div>
      </div>
    </div>
  );
}

export default KycSideScreen;
