import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import BlockSpacer from "../../../components/BlockSpacer";
import style from "../style.module.css";
import "../stye.css";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";

const TransferToTreasury = ({
  transferAmount,
  walletBalance,
  setTransferAmount,
  close,
  submit,
}) => {
  const { width } = useScreenSize();
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: { xs: "100vw", sm: "100vw", md: "503px", lg: "503px" },
        height: { xs: "100vh", sm: "100vh", md: "870px", lg: "870px" },
        position: { md: "absolute", lg: "absolute" },
        right: "0px",
        top: "0px",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem",
        }}
      >
        <IconButton onClick={() => close()}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width > 700 ? 160 : 50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: { md: "430px", lg: "430px" },
          height: { md: "459px", lg: "459px" },
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Box
            display={"flex"}
            sx={{
              alignItems: "center",
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "16px",
                letterSpacing: "-0.03em",
                padding: { xs: "1rem", sm: "1rem", md: "2rem", lg: "2rem" },
              }}
            >
              Available Balance
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "19px",
                lineHeight: "23px",
                letterSpacing: "-0.03em",
                padding: { xs: "1rem", sm: "1rem", md: "2rem", lg: "2rem" },
              }}
            >
              ₦{" "}
              {transferAmount !== ""
                ? (
                    walletBalance?.amount - parseFloat(transferAmount)
                  )?.toLocaleString()
                : walletBalance?.amount ?? 0}
            </Typography>
          </Box>
          <Box>
            <form className={style.Top}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="Top Request"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "block",
                  }}
                >
                  Transfer Amount
                </label>
                <input
                  type="number"
                  name="transferAmount"
                  id="pley"
                  placeholder="  ₦ 1,500,000"
                  style={{
                    width: "321px",
                    height: "48px",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "inlineBlock",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxSizing: "borderBox",
                  }}
                  value={transferAmount}
                  onChange={(e) => setTransferAmount(e.target.value)}
                />
              </div>

              <div className={style.TenorForm}>
                <label
                  htmlFor="Tenor"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "block",
                  }}
                >
                  Beneficiary Accounnt
                </label>
                <select
                  name=""
                  id="Benefciary"
                  style={{
                    width: "321px",
                    height: "48px",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "Block",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxSizing: "borderBox",
                  }}
                >
                  <option
                    value=""
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "12px",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                    }}
                    className="option"
                  >
                    Treasury wallet
                  </option>
                </select>
                <Button
                  sx={{
                    background: "#111E6C",
                    borderRadius: "10px",
                    width: "100%",
                    height: "54px",
                    marginTop: "3rem",
                    marginBottom: "1rem",
                    fontFamily: "Montserrat",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    "&:hover": {
                      backgroundColor: "#BDBDBD",
                      color: "#111E6C",
                    },
                  }}
                  onClick={() => {
                    submit();
                    setTransferAmount("");
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TransferToTreasury;
