import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { textheading, smallText } from "./style";
import BlockSpacer from "../../../BlockSpacer";
import SelectField from "../../../Core/SelectField";
import FormAligner from "../../../Hocs/FormAligner";
import Buttons from "../../../Buttons";
import FormLabel from "../../Label";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompanyGroup,
  getIndustryManagement,
} from "../../../../redux/slices/profileSlice";
import {
  getErpEmployers,
  getErpIndustries,
} from "../../../../redux/slices/updateProfileSlice";
import { EMPLOYER_SECTORS, PAYROLL_HANDLERS } from "../../../../constants";
import useScreenSize from "../../../ScreenSize";

const EmployerDetailForm = ({
  profileForm,
  handleForm,
  handleSendOtp,
  setCurrentEdit,
  showEdit,
  removeEdit,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const [defaultEmployerNameMethod, setDefaultEmployerNameMethod] = useState(
    profileForm?.individualUser?.employmentDetail?.employerName
  );
  const { width } = useScreenSize();
  const { erpEmployers, erpIndustries } = useSelector(
    (state) => state.updateProfile
  );
  // const selectedEmployer = erpEmployers?.find(
  //   (employer) => employer?.employerName === defaultEmployerNameMethod
  // );

  const handleEmployerNameChange = (event) => {
    setDefaultEmployerNameMethod(event.target.value);
    handleForm((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          employerName: event.target.value,
          employmentId: erpEmployers
            ?.find((employer) => employer?.employerName === event.target.value)
            ?.idErp.toString(),
        },
      },
    }));
  };

  useEffect(() => {
    dispatch(getAllCompanyGroup());
    dispatch(getIndustryManagement());
    dispatch(getErpEmployers());
    dispatch(getErpIndustries());
  }, []);

  // const { companyGroups, industryManagement } = useSelector(
  //   (state) => state.profile
  // );

  const { erpSectors, users } = useSelector((state) => state.profile);

  const industries = erpSectors ? erpSectors?.map((item) => item?.sector) : [];

  const employDet = profileForm?.individualUser?.employmentDetail;
  const employCreditDet = users?.individualUser?.creditEmploymentDetail;

  const employeeNames = erpEmployers
    ? erpEmployers?.map((item) => item?.employerName)
    : [];
  const employerNamesOptions = [...employeeNames, "Others"];
  const industryOptions = [...industries];

  //Employer Sector
  const [defaultEmployerSectorMethod, setDefaultEmployerSectorMethod] =
    useState(
      profileForm?.individualUser?.employmentDetail?.sector === "PRIVATE_SECTOR"
        ? "Private"
        : "Public"
    );

  const handleEmployerSectorChange = (event) => {
    setDefaultEmployerSectorMethod(event.target.value);
    handleForm((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          sector:
            event.target.value === "Private"
              ? "PRIVATE_SECTOR"
              : "PUBLIC_SECTOR",
        },
      },
    }));
  };

  //Payroll handler
  const [defaultPayrollHandlerMethod, setDefaultPayrollHandlerMethod] =
    useState(profileForm?.individualUser?.employmentDetail?.payrollHandler);
  const handlePayrollHandlerChange = (event) => {
    setDefaultPayrollHandlerMethod(event.target.value);
    handleForm((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          payrollHandler:
            event.target.value === "Others" ? "" : event.target.value,
        },
      },
    }));
  };

  const [dafaultEmployerIndutry, setDefaultEmployerIndutry] = useState(
    erpSectors?.find((sector) => sector?.id === employCreditDet?.erpSectorId)
      ?.sector
  );

  const handleEployerIndustryChange = (e) => {
    setDefaultEmployerIndutry(e.target.value);
    handleForm((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          erpSectorId: erpSectors?.find(
            (sector) => sector?.sector === e.target.value
          )?.id,
        },
      },
    }));
  };

  useEffect(() => {
    setDefaultEmployerNameMethod(
      employDet?.employerName === "" ? "Others" : employDet?.employerName
    );
  }, [profileForm]);

  useEffect(() => {
    setDefaultEmployerSectorMethod(
      employDet?.sector === "PUBLIC_SECTOR" ? "Public" : "Private"
    );
  }, [profileForm]);

  useEffect(() => {
    setDefaultPayrollHandlerMethod(
      employDet?.payrollHandler === "" ? "Others" : employDet?.payrollHandler
    );
  }, [profileForm]);

  useEffect(() => {
    setDefaultEmployerIndutry(
      employCreditDet?.erpSectorId === null
        ? ""
        : erpSectors?.find(
            (sector) => sector?.id === employCreditDet?.erpSectorId
          )?.sector
    );
  }, [users?.individualUser?.creditEmploymentDetail, erpSectors]);

  const handleUserInput = (e) => {
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        employmentDetail: {
          ...profileForm?.individualUser?.employmentDetail,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  console.log(employDet);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography sx={textheading}>Employment Details</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            {!showEdit.includes(4) ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width <= 700 ? "60px" : "72px"}
                    fontSize={width <= 700 && "14px"}
                    onClick={() => {
                      handleSendOtp();
                      setCurrentEdit(4);
                    }}
                  >
                    Edit
                  </Buttons>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="tertiary"
                    width={width <= 700 ? "60px" : "72px"}
                    fontSize={width <= 700 && "14px"}
                    onClick={() => {
                      removeEdit(4);
                      handleSubmit();
                    }}
                  >
                    Save
                  </Buttons>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
      <BlockSpacer />
      <div>
        <BlockSpacer />
        <Grid container spacing={5} alignItems={"flex-end"}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormAligner>
              <SelectField
                name={"Employer Name"}
                method={
                  !employerNamesOptions.includes(defaultEmployerNameMethod)
                    ? "Others"
                    : defaultEmployerNameMethod
                }
                disabled={!showEdit.includes(4)}
                changeAction={handleEmployerNameChange}
                items={employerNamesOptions}
                asterisk
                maxWidth={"100%"}
              />
            </FormAligner>
          </Grid>
          {/* !employerNamesOptions.includes(defaultEmployerNameMethod) || */}
          {defaultEmployerNameMethod === "Others" && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Employer Name Details"} />
                <TextField
                  placeholder="Employer name"
                  type="text"
                  required
                  value={employDet?.employerName}
                  disabled={!showEdit.includes(4)}
                  name="employerName"
                  onChange={handleUserInput}
                />
              </FormAligner>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormAligner>
              <SelectField
                name={"Employer Sector"}
                method={defaultEmployerSectorMethod}
                disabled={!showEdit.includes(4)}
                changeAction={handleEmployerSectorChange}
                items={EMPLOYER_SECTORS}
                asterisk
                maxWidth={"100%"}
              />
            </FormAligner>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            alignItems={"flex-end"}
          >
            {defaultEmployerSectorMethod === "Public" && (
              <FormAligner>
                <SelectField
                  name={"Employer Payroll Handler"}
                  method={
                    !PAYROLL_HANDLERS?.includes(defaultPayrollHandlerMethod)
                      ? "Others"
                      : defaultPayrollHandlerMethod
                  }
                  disabled={!showEdit.includes(4)}
                  changeAction={handlePayrollHandlerChange}
                  items={PAYROLL_HANDLERS}
                  asterisk
                  maxWidth={"100%"}
                />
              </FormAligner>
            )}
            {defaultEmployerSectorMethod === "Private" && (
              <FormAligner>
                <FormLabel text={"Employment ID"} />
                <TextField
                  placeholder="12345"
                  type="number"
                  required
                  value={employDet?.employmentId}
                  disabled={!showEdit.includes(4)}
                  name="employmentId"
                  onChange={handleUserInput}
                />
              </FormAligner>
            )}
          </Grid>
          {defaultEmployerSectorMethod === "Public" && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {defaultEmployerSectorMethod === "Public" &&
                (defaultPayrollHandlerMethod === "Others" ||
                  !PAYROLL_HANDLERS?.includes(defaultPayrollHandlerMethod)) && (
                  <FormAligner>
                    <FormLabel text={"Payroll Handler Details"} />
                    <TextField
                      placeholder=""
                      type="text"
                      required
                      disabled={!showEdit.includes(4)}
                      value={employDet?.payrollHandlerDetails}
                      fullWidth
                      name="payrollHandlerDetails"
                      onChange={handleUserInput}
                    />
                  </FormAligner>
                )}
              {defaultEmployerSectorMethod === "Public" &&
                defaultPayrollHandlerMethod === "IPPIS" && (
                  <FormAligner>
                    <FormLabel text={"IPPIS Number"} />
                    <TextField
                      placeholder="ex-> RC 01234-****"
                      type="text"
                      required
                      disabled={!showEdit.includes(4)}
                      value={employDet?.ippisNumber}
                      name="ippisNumber"
                      onChange={handleUserInput}
                    />
                  </FormAligner>
                )}
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormAligner>
              <SelectField
                name={"Employer Industry"}
                method={
                  !industryOptions?.includes(dafaultEmployerIndutry)
                    ? ""
                    : dafaultEmployerIndutry
                }
                disabled={!showEdit.includes(4)}
                changeAction={handleEployerIndustryChange}
                items={industryOptions}
                asterisk
                maxWidth={"100%"}
              />
            </FormAligner>
          </Grid>
          {dafaultEmployerIndutry === "Others" && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Employer Industry Details"} />
                <TextField
                  placeholder="Industry Details"
                  type="text"
                  disabled={!showEdit.includes(4)}
                  required
                  value={employDet?.industry}
                  name="industry"
                  onChange={handleUserInput}
                  sx={{ maxWidth: "785px" }}
                />
              </FormAligner>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormAligner>
              <FormLabel text={"Position"} />
              <TextField
                placeholder="ex-> Manager"
                type="text"
                required
                disabled={!showEdit.includes(4)}
                value={employDet?.position}
                name="position"
                onChange={handleUserInput}
              />
            </FormAligner>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormAligner>
              <FormLabel text={"Employment Start Date"} />
              <TextField
                placeholder=""
                type="date"
                required
                disabled={!showEdit.includes(4)}
                name="employmentStartDate"
                value={employDet?.employmentStartDate}
                onChange={handleUserInput}
                // disabled
              />
            </FormAligner>
          </Grid>
        </Grid>
        <BlockSpacer />
        <BlockSpacer />
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormAligner>
              <FormLabel text={"Employer Address"} />
              <TextField
                placeholder=""
                type="text"
                required
                disabled={!showEdit.includes(4)}
                value={employDet?.employerAddress}
                name="employerAddress"
                onChange={handleUserInput}
                sx={{ maxWidth: "785px" }}
              />
            </FormAligner>
          </Grid>
        </Grid>
        <BlockSpacer />
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <FormAligner>
              <FormLabel text={"Official email address"} />
              <TextField
                placeholder="info@optisoft.com"
                disabled={!showEdit.includes(4)}
                type="email"
                value={employDet?.officeEmailAddress}
                required
                name="officeEmailAddress"
                onChange={handleUserInput}
              />
            </FormAligner>
          </Grid>
        </Grid>
        <BlockSpacer />
      </div>
    </>
  );
};

export default EmployerDetailForm;
