import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../components/Buttons";
import BlockSpacer from "../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../components/Cards/SummaryCard";
import OfferSuccessModal from "../../../components/Modals/OfferModal";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { acceptLoanOffer, cancelInitialization, cancelLoanValue, cancelRepayments, clearLoan } from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";

function QuickloanSummary(props) {
  const [open, setOpen] = useState(false);
  const handleOfferOpen = () => setOpen(true);
  const handleOfferClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { initializedLoan, repayment, acceptLoan, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (acceptLoan) {
      handleOfferOpen();
    }
  }, [acceptLoan]);

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(
      acceptLoanOffer({
        action: "reject",
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      acceptLoanOffer({
        action: "accept",
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  const navigate = useNavigate();

  const cancelLoan = () => {
    navigate(-1);
    dispatch(cancelLoanValue());
    dispatch(clearLoan());
    dispatch(cancelInitialization());
    dispatch(cancelRepayments());
  }
  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={5} />
          <Typography variant="h5" component="h5" sx={contentTitle}>
            Loan Summary
          </Typography>

          <SummaryCard
            title={initializedLoan?.data?.body?.productName}
            currencyRequested={"Requested Loan Amount"}
            tenor={"Tenor"}
            currency={repayment?.object?.body?.body?.requestLoanAmount}
            days={`${repayment?.object?.body?.body?.tenor} days`}
            infoPayable={"Interest Payable"}
            feePayable={"Fee Payable"}
            interestpayableCurrency={repayment?.object?.body?.body?.interestPayable}
            feePayableCurrency={repayment?.object?.body?.body?.feePayable}
            TotalRepayment={"Total Repayment"}
            LoanDueDate={"Loan Due Date"}
            totalPaymentAmount={repayment?.object?.body?.body?.totalRepayment}
            totalPaymentDate={repayment?.object?.body?.body?.loanDueDate}
          />

          <BlockSpacer mt="200" />

          <Paper
            elevation={0.5}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "89%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: { xs: "1rem" },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                }}
                onClick={cancelLoan}
                type="button"
              >
                Cancel
              </Button>
              <Buttons color="primary" width="328px" onClick={handleSubmit}>
                {
                  loading ? (<ClipLoader color={"#fff"} loading={loading} size={20} />) :
                  "Accept Offer"
                }
              </Buttons>
            </Box>
          </Paper>

          {open && (
            <OfferSuccessModal open={open} handleClose={handleOfferClose} />
          )}
        </Section>
      </>
    </>
  );
}

export default QuickloanSummary;
