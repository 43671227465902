import React, { useState, useEffect } from "react";
import SplitScreenLeft from "../../../components/UniqueScreens/SplitScreenLeft";
import style from "./style.module.css";
import { getAllSources } from "../../../redux/slices/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import UpdateIndividualUser from "../../../components/Forms/UpdateIndividualUsertype";
import UpdateCorporateUsertype from "../../../components/Forms/UpdateCorporateUsertype";

function UsertypeUpdate() {
  const dispatch = useDispatch();
  const { login } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(getAllSources());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={style.container}>
      <div className={style.ssl}>
        <SplitScreenLeft
        />
      </div>

      <div className={style.login}>
        {login?.role?.name === "INDIVIDUAL_USER" ? (
          <UpdateIndividualUser/>
        ) : (
          <UpdateCorporateUsertype/>
        )}
      </div>
    </div>
  );
}

export default UsertypeUpdate;
