import Styled from "@emotion/styled";

export const Nav = Styled.nav`
left: 0;
position: fixed;
 width: 99.5%;
z-index: 10;
//  z-index: 99999999;
 height: 101px;
background: #FFFFFF;
box-shadow:  0px 4px 4px rgba(196, 204, 221, 0.12);

.top {
    display: flex;
      gap: 10rem;
   
}

.brand {
    width: 15%;
    display: flex;
    padding-top: 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem 2rem;
}

.content {
     padding-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
    h4 {
        color: #222222;
        font-family: Montserrat;
        font-size: 21px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.04em;
        text-align: left;
        
    }
    .userAvatar {
        display: flex;
        width: 35%
    }
}

`;
