import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Section } from "./style";
import LoanImages from "../../components/Assets/images/loan.png";
import LeaseImage from "../../components/Assets/images/lease.png";
import BackgroundCard from "../../components/BackgroudCard";
import { Link } from "react-router-dom";
import TopBar from "../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/slices/profileSlice";

function GetCredit() {
  const dispatch = useDispatch();
  const [leaseLink, setLeaseLink] = useState();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const { users } = useSelector((state) => state.profile);

  useEffect(() => {
    if (
      users?.userType === "Student" ||
      users?.userType === "Student (Post-secondary)"
    ) {
    } else if (users?.userType === "Salary Earner (With No Side Business)") {
      setLeaseLink("/lease");
    } else if (users?.userType === "Salary Earner (Owns a Side Business)") {
      setLeaseLink("/lease");
    } else if (users?.userType === "Pensioner") {
    } else if (
      users?.userType === "SOLE_PROPRIETOR_REGISTERED" ||
      users?.userType === "SOLE_PROPRIETOR_UNREGISTERED"
    ) {
      setLeaseLink("/lease/sole-proprietor");
    } else if (
      users?.userType === "CORPORATE_LISTED" ||
      users?.userType === "CORPORATE_UNLISTED"
    ) {
      setLeaseLink("/lease/corporate");
    } else {
      setLeaseLink("/lease");
    }
  }, [users]);

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Link to={"/get-credit/loans"}>
                <BackgroundCard image={LoanImages} alt="loan images" />
              </Link>
            </Grid>
            {(users?.userType !== "Student" ||
              users?.userType === "Student (Post-secondary)") && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Link to={leaseLink}>
                  <BackgroundCard image={LeaseImage} alt="loan images" />
                </Link>
              </Grid>
            )}
          </Grid>
        </Section>
      </main>
    </>
  );
}

export default GetCredit;
