import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateCreditUserTypeService, switchPlatformService } from "../../services/switchPlatformServices";

const initialState = {
  loading: false,
  creditUserType: null,
  creditUserTypeErr: null,
  switchedPlatform: null,
  switchedPlatformErr: null,
};

export const updateCreditUserType = createAsyncThunk(
  "switchPlatform/updateCreditUserType",
  (formData, { rejectWithValue }) => {
    return updateCreditUserTypeService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const switchPlatform = createAsyncThunk(
  "switchPlatform/switchPlatform",
  (formData, { rejectWithValue }) => {
    return switchPlatformService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);


const switchPlatformSlice = createSlice({
  name: 'switchPlatform',
  initialState,
  extraReducers: builder => {
    // update treasury user usertype to credit pending
    builder.addCase(updateCreditUserType.pending, (state) => {
      state.loading = true
    }) 
    // update treasury user usertype to credit success
    builder.addCase(updateCreditUserType.fulfilled, (state, action) => {
      state.loading = false
      state.creditUserType = action.payload
      state.creditUserTypeErr = null
    })
    // update treasury user usertype to credit failed
    builder.addCase(updateCreditUserType.rejected, (state, action) => {
      state.loading = false
      state.creditUserType = null
      state.creditUserTypeErr = action.error
    })
    // switch platform pending
    builder.addCase(switchPlatform.pending, (state) => {
      state.loading = true
    }) 
    // switch platform success
    builder.addCase(switchPlatform.fulfilled, (state, action) => {
      state.loading = false
      state.switchedPlatform = action.payload
      state.switchedPlatformErr = null
    })
    // switch platform failed
    builder.addCase(switchPlatform.rejected, (state, action) => {
      state.loading = false
      state.switchedPlatform = null
      state.switchedPlatformErr = action.error
    })
  }
});

export default switchPlatformSlice.reducer;