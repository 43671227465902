import React, { useEffect } from "react";
import Sidebar from "../SideBar";
import { Grid } from "@mui/material";
import { Section } from "./style.js";
import { useNavigate } from "react-router-dom";

function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const authenticated = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if(authenticated) {
      if (authenticated?.role?.name === "COMPANY" && !(authenticated?.kyc)) {
        navigate("/company/kyc");
      } else if (
        authenticated?.role?.name === "INDIVIDUAL_USER" &&
        !(authenticated?.kyc)
      ) {
        navigate("/user/kyc");
      }
    } else {
      navigate("/")
    }
  },[authenticated])
  return (
    <main>
      <Section>
        <div className="left-side">
          <Sidebar />
        </div>
        <div className="right-side">{children}</div>
      </Section>
    </main>
  );
}

export default DashboardLayout;
