import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../components/Buttons";
import BlockSpacer from "../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../components/Cards/LeaseSummaryCard";
import { Link } from "react-router-dom";
import OfferSuccessModal from "../../../components/Modals/OfferSuccess(L)";
import LoanValueModal from "../../../components/Modals/CollateralLoan/SorceAccount(L)/index";
import NoteModal from "../../../components/Modals/NoteModal(Le)";
import TopBar from "../../../components/GetCreditTopBar";

import ApproveBankModal from "../../../components/Modals/CollateralLoan/ApproveBankLease/index";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptLoanOffer,
  cancelInitialization,
  cancelRepayments,
} from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function FinanceLeaseSummary(props) {
  // Acceptance Loan Offer
  const navigate = useNavigate();
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const handleOfferOpen = () => setOpenOfferModal(true);
  const handleOfferClose = () => setOpenOfferModal(false);

  //Source Account Modal
  const [openSource, setOpenSource] = useState(false);
  const handleSource = () => {
    setOpenSource(true);
    setOpenOfferModal(false);
  };

  //Note Ticket

  const dispatch = useDispatch();

  const { initializedLoan, repayment, acceptLoan, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (acceptLoan) {
      handleOfferOpen();
    }
  }, [acceptLoan]);

  const [openTicket, setOpenTicket] = useState(false);
  const handleNoteTicketOpen = () => {
    setOpenTicket(true);
    setOpenSource(false);
  };

  const handleOpenTicketClose = () => {
    setOpenTicket(false);
  };

  //Open Approve Bank

  const [openApprove, setOpenApprove] = useState(false);
  const handleApprovalOpen = () => {
    setOpenApprove(true);
    setOpenTicket(false);
  };
  const handleApprovalClose = () => {
    setOpenApprove(false);
  };

  const handleSourceClose = () => setOpenSource(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      acceptLoanOffer({
        action: "accept",
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  const cancelLoan = () => {
    navigate("/finance-lease");
    dispatch(cancelInitialization());
    dispatch(cancelRepayments());
  };
  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={5} />
          <Typography variant="h5" sx={contentTitle}>
            Lease Summary
          </Typography>
          <SummaryCard
            title={"Finance Lease"}
            currencyRequested={"Accessible Lease Value"}
            currency={`₦${parseFloat(
              repayment?.object?.body?.body?.accessibleLeaseAmount
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            UpfrontPayment={"Upfront Payment"}
            hundredThousand={`₦${parseFloat(
              repayment?.object?.body?.body?.upfrontPayment
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            PercentageOfleaseValue={"Percentage of Lease Value"}
            Percentagelease={`${repayment?.object?.body?.body?.percentageOfLeaseValue}%`}
            tenor={"Tenor"}
            days={`${repayment?.object?.body?.body?.tenor} Month(s)`}
            TotalRepayment={"Total Repayment"}
            RepaymentAmount={`₦${parseFloat(
              repayment?.object?.body?.body?.totalRepayment
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            TotalDebtWeight={"Total Debt Weight"}
            DebtAmount={`${repayment?.object?.body?.body?.totalDebtWeight}%`}
            monthlyRepayments={
              repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
            }
          />
        </Section>
        <BlockSpacer mt={40} />
        <Paper
          elevation={1}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "100%" },
            margin: "1rem",
            marginLeft: { lg: "-2rem" },
            marginRight: { lg: "auto" },
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: { xs: "1rem" },
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "18px",
              }}
              type="button"
              onClick={cancelLoan}
            >
              Cancel
            </Button>

            <Buttons color="primary" width="328px" onClick={handleSubmit}>
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Accept Offer"
              )}
            </Buttons>
          </Box>
        </Paper>
      </>
      {openOfferModal && (
        <OfferSuccessModal
          open={openOfferModal}
          handleClose={handleOfferClose}
          handleSource={handleSource}
        />
      )}
      {openSource && (
        <LoanValueModal
          open={openSource}
          handleClose={handleSourceClose}
          handleNoteTicketOpen={handleNoteTicketOpen}
        />
      )}
      {openTicket && (
        <NoteModal
          open={openTicket}
          handleClose={handleOpenTicketClose}
          handleApprovalOpen={handleApprovalOpen}
        />
      )}

      {openApprove && (
        <ApproveBankModal
          open={openApprove}
          handleClose={handleApprovalClose}
        />
      )}
    </>
  );
}

export default FinanceLeaseSummary;
