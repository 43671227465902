import React, { useEffect, useState } from "react";
import PersonalDetalLayout from "../../../../../components/PersonalDetailLayout";
import BlockSpacer from "../../../../../components/BlockSpacer";
import SelectField from "../../../../../components/Core/SelectField";
import { Section, container, textheading } from "./style";
import { AVAILABLE_USER_TYPES } from "../../../../../constants";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import FormLabel from "../../../../../components/Forms/Label";
import { Box, Typography, Grid, TextField } from "@mui/material";
import ProfileContactDetails from "../../../../../components/Forms/Profile/ContactDetailForm";
import SchoolDetailsForm from "../../../../../components/Forms/Profile/SchoolDetailsForm";
import NextOfKinForm from "../../../../../components/Forms/Profile/NextOfkinForm";
import EmployerDetailForm from "../../../../../components/Forms/Profile/EmployerDetailForm";
import Buttons from "../../../../../components/Buttons";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsers,
  getCountries,
} from "../../../../../redux/slices/profileSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import FooterCheckout from "../../../../../components/FooterCheckout";
import {
  sendOtp,
  closeOtpModal,
  validateOtp,
  updateUserKyc,
  getErpSectors,
} from "../../../../../redux/slices/profileSlice";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";

function PersonalInformation() {
  const [defaultUserMethod, setDefaultUserMethod] = React.useState("");
  const [currentEdit, setCurrentEdit] = useState();
  const [showEdit, setShowEdit] = useState([]);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { width } = useScreenSize();

  const {
    users: individualUser,
    kycData,
    showEmailOtpModal,
    otp,
    validateEmailOtp,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCountries());
    dispatch(getErpSectors());
  }, []);

  useEffect(() => {
    setDefaultUserMethod(individualUser?.userType);
  }, [individualUser]);

  const addEdit = (id) => {
    console.log("add");
    if (!showEdit.includes(id)) {
      setShowEdit((current) => [...current, id]);
    }
  };

  useEffect(() => {
    const handler = (event) => {
      event.returnValue = "ss";
    };

    if (showEdit.length > 0) {
      window.addEventListener("beforeunload", (e) => handler(e));
      window.addEventListener("popstate", (e) => handler(e));
      return () => {
        window.removeEventListener("beforeunload", (e) => handler(e));
        window.removeEventListener("popstate", (e) => handler(e));
      };
    }
  }, []);

  const removeEdit = (id) => {
    console.log("remove");
    if (showEdit.includes(id)) {
      setShowEdit(showEdit?.filter((item) => item !== id));
    }
  };

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleSendOtp = () => {
    dispatch(sendOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
    setToken("");
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      addEdit(currentEdit);
    }
  }, [validateEmailOtp]);

  const data = {
    isAssited: null,
    isKyc: true,
    isNewsLetters: null,
    phone: null,
    role: "INDIVIDUAL_USER",
    source: "ANOTHER_USER",
    sourceOthers: null,
    status: "ACTIVE",
    usage: "CREDIT",
    userType: null,
    individualUser: {
      bvn: null,
      contactAddress: {
        city: null,
        country: "Nigeria",
        homeAddress: null,
        secondaryPhoneNumber: null,
        nationality: null,
        postCode: null,
        state: null,
        streetAddress: null,
      },
      employmentDetail: {
        employerAddress: null,
        employerName: null,
        employmentId: null,
        industry: null,
        industryDetails: null,
        ippisNumber: null,
        occupation: null,
        officeEmailAddress: null,
        payrollHandler: null,
        payrollHandlerDetails: null,
        sector: null,
      },
      occupation: {
        businessStatus: null,
        businessType: null,
        city: null,
        occupation: null,
        occupationDetails: null,
        schoolAddress: null,
        schoolName: null,
        state: null,
      },
      nokDetail: {
        address: "",
        city: "",
        email: "",
        firstName: "",
        lastName: "",
        relationship: "",
        state: "",
      },
      dateOfBirth: null,
      countryId: 1,
      firstName: null,
      genderId: null,
      lastName: null,
      maritalStatus: null,
      middleName: null,
    },
  };

  const [formData, setFormData] = useState(data);

  useEffect(() => {
    const nokName = individualUser?.individualUser?.nokDetail?.name?.split(" ");
    setFormData({
      ...formData,
      phone: individualUser?.phone,
      userType: individualUser?.userType,
      isAssited: individualUser?.isAssited,
      isNewsLetters: individualUser?.newsLetters,
      source: individualUser?.source,
      sourceOthers: individualUser?.sourceOthers,
      individualUser: {
        ...formData.individualUser,
        bvn: individualUser?.individualUser?.bvn,
        contactAddress: {
          ...formData?.individualUser?.contactAddress,
          city: individualUser?.individualUser?.address?.city,
          country: individualUser?.individualUser?.address?.country,
          homeAddress: individualUser?.individualUser?.address?.homeAddress,
          secondaryPhoneNumber:
            individualUser?.individualUser?.address?.secondaryPhoneNumber,
          nationality: individualUser?.individualUser?.address?.nationality,
          state: individualUser?.individualUser?.address?.state,
          streetAddress: individualUser?.individualUser?.address?.streetAddress,
        },
        employmentDetail: {
          ...formData?.individualUser?.employmentDetail,
          employerAddress:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.employerAddress,
          employerName:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.employerName,
          employmentStartDate:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.employmentStartDate,
          employmentConfirmed:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.employmentConfirmed,
          position:
            individualUser?.individualUser?.creditEmploymentDetail?.position,
          employmentId:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.employmentId,
          employerId:
            individualUser?.individualUser?.creditEmploymentDetail?.employerId,
          industry:
            individualUser?.individualUser?.creditEmploymentDetail?.industry,
          industryDetails:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.industryDetails,
          ippisNumber:
            individualUser?.individualUser?.creditEmploymentDetail?.ippisNumber,
          occupation:
            individualUser?.individualUser?.creditEmploymentDetail?.occupation,
          officeEmailAddress:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.officeEmailAddress,
          payrollHandler:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.payrollHandler,
          payrollHandlerDetails:
            individualUser?.individualUser?.creditEmploymentDetail
              ?.payrollHandlerDetails,
          sector:
            individualUser?.individualUser?.creditEmploymentDetail?.sector,
        },
        occupation: {
          businessStatus:
            individualUser?.individualUser?.occupationDetail?.businessStatus,
          businessType:
            individualUser?.individualUser?.occupationDetail?.businessType,
          city: individualUser?.individualUser?.occupationDetail?.city,
          occupation:
            individualUser?.individualUser?.occupationDetail?.occupation,
          occupationDetails:
            individualUser?.individualUser?.occupationDetail?.occupationDetails,
          schoolAddress:
            individualUser?.individualUser?.occupationDetail?.schoolAddress,
          schoolName:
            individualUser?.individualUser?.occupationDetail?.schoolName,
          state: individualUser?.individualUser?.occupationDetail?.state,
        },
        nokDetail: {
          address: individualUser?.individualUser?.nokDetail?.address,
          city: individualUser?.individualUser?.nokDetail?.city,
          email: individualUser?.individualUser?.nokDetail?.email,
          firstName:
            individualUser?.individualUser?.nokDetail && nokName?.length > 1
              ? nokName[0]
              : "",
          lastName:
            individualUser?.individualUser?.nokDetail && nokName?.length > 1
              ? nokName[1]
              : "",
          relationship: individualUser?.individualUser?.nokDetail?.relationship,
          state: individualUser?.individualUser?.nokDetail?.state,
        },
        dateOfBirth: individualUser?.individualUser?.dateOfBirth,
        countryId: 1,
        firstName: individualUser?.individualUser?.firstName,
        genderId: individualUser?.individualUser?.gender?.id,
        lastName: individualUser?.individualUser?.lastName,
        maritalStatus: individualUser?.individualUser?.maritalStatus,
        middleName: individualUser?.individualUser?.middleName,
      },
    });
  }, [individualUser, dispatch]);

  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };

  const handleSubmit = () => {
    const submitData = {
      ...formData,
      individualUser: {
        ...formData.individualUser,
        employmentDetail:
          formData?.userType === AVAILABLE_USER_TYPES[0]
            ? null
            : {
                ...formData?.individualUser?.employmentDetail,
              },
      },
    };
    dispatch(updateUserKyc(submitData));
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <PersonalDetalLayout>
            <Box sx={container}>
              <Box
                sx={{
                  display: width > 700 ? "none" : "flex",
                  gap: 2,
                  alignItems: "center",
                  mb: 6,
                }}
              >
                <Box>
                  <Link to="/profile">
                    <ArrowBackIosNew />
                  </Link>
                </Box>
                <Box>
                  <Typography sx={textheading}>Personal Info</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={textheading}>Personal Details</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Box>
                    {!showEdit.includes(1) ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                          sx={{ ml: { xs: 0, lg: 40 }, mt: { sx: 2, lg: 6 } }}
                        >
                          <Buttons
                            color="primary"
                            width={width <= 700 ? "60px" : "72px"}
                            fontSize={width <= 700 && "14px"}
                            onClick={() => {
                              handleSendOtp();
                              setCurrentEdit(1);
                            }}
                          >
                            Edit
                          </Buttons>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                          sx={{ ml: { xs: 0, lg: 40 }, mt: { sx: 2, lg: 6 } }}
                        >
                          <Buttons
                            color="tertiary"
                            width={width <= 700 ? "60px" : "72px"}
                            fontSize={width <= 700 && "14px"}
                            onClick={() => {
                              removeEdit(1);
                              handleSubmit();
                            }}
                          >
                            Save
                          </Buttons>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Box>
              <BlockSpacer />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormAligner>
                    <FormLabel text={"User Type"} />
                    <TextField
                      type="text"
                      value={
                        defaultUserMethod === "Student" ||
                        defaultUserMethod === "Student (Post-secondary)"
                          ? "Student (Post-Secondary)"
                          : defaultUserMethod
                      }
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="user type"
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer mt={width > 700 && 50} />
              {/* First Grid */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"First Name"} />
                    <TextField
                      placeholder="ekiyee"
                      type="text"
                      value={individualUser?.individualUser?.firstName}
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="name"
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Middle Name"} />
                    <TextField
                      placeholder="bilaowei"
                      type="text"
                      required
                      value={individualUser?.individualUser?.middleName}
                      name="middleName"
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Last Name"} />
                    <TextField
                      placeholder="bilaowei"
                      type="text"
                      required
                      value={individualUser?.individualUser?.lastName}
                      name="lastName"
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
              </Grid>

              <BlockSpacer />
              {/* second Grid */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Gender"} />
                    <TextField
                      placeholder="Female"
                      type="text"
                      value={individualUser?.individualUser?.gender?.gender}
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                      name="gender"
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Marital Status"} />
                    <TextField
                      placeholder="Single"
                      type="text"
                      value={individualUser?.individualUser?.maritalStatus}
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                      name="maritalStatus"
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Date of Birth"} />
                    <TextField
                      type="date"
                      value={moment(
                        individualUser?.individualUser?.dateOfBirth,
                        "DD-MM-YYYY"
                      ).format("YYYY-MM-DD")}
                      required
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="dateOfBirth"
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer />
              {/* third Grid */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Primary Phone Number"} />
                    <TextField
                      placeholder="+234 7088957111"
                      type="text"
                      value={individualUser?.phone}
                      required
                      name="phone"
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Email"} />
                    <TextField
                      placeholder="optisoft@gmail.com"
                      type="email"
                      required
                      value={individualUser?.email}
                      name="email"
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Customer ID Number"} />
                    <TextField
                      placeholder="1234657891"
                      type="number"
                      required
                      value={individualUser?.customerId}
                      name="id"
                      disabled={!showEdit.includes(1)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          background: showEdit.includes(1) && "#e9ecef",
                        },
                      }}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              {/* fourth Grid */}
              <BlockSpacer />

              {defaultUserMethod === AVAILABLE_USER_TYPES[0] && (
                <>
                  <BlockSpacer mt={100} />
                  <ProfileContactDetails
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    removeEdit={(id) => removeEdit(id)}
                    handleSubmit={handleSubmit}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                  <BlockSpacer mt={150} />
                  <SchoolDetailsForm
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                  {/* <BlockSpacer mt={150} />
                                    <EmployerDetailForm /> */}
                  <BlockSpacer mt={150} />
                  <NextOfKinForm
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                </>
              )}

              {(defaultUserMethod === AVAILABLE_USER_TYPES[1] ||
                defaultUserMethod === AVAILABLE_USER_TYPES[2]) && (
                <>
                  <BlockSpacer mt={100} />
                  <ProfileContactDetails
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                  {/* <BlockSpacer mt={150} />
                                    <SchoolDetailsForm /> */}
                  <BlockSpacer mt={150} />
                  <EmployerDetailForm
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                  <BlockSpacer mt={150} />
                  <NextOfKinForm
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                </>
              )}

              {defaultUserMethod === AVAILABLE_USER_TYPES[3] && (
                <>
                  <BlockSpacer mt={100} />
                  <ProfileContactDetails
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    editForm={setShowEdit}
                    edit={showEdit}
                    saveForm={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                  <BlockSpacer mt={150} />
                  <NextOfKinForm
                    handleSendOtp={() => handleSendOtp()}
                    setCurrentEdit={(id) => setCurrentEdit(id)}
                    showEdit={showEdit}
                    handleSubmit={handleSubmit}
                    removeEdit={(id) => removeEdit(id)}
                    profileForm={formData}
                    handleForm={setFormData}
                  />
                </>
              )}
            </Box>
            <BlockSpacer mt={100} />
            {/* <FooterCheckout 
              proceedInfoButton={"Save"} 
              // handleOpenSuccessMesage={handleOpenSuccessMesage}
              handleClose={()=>handleSubmit()}
              disabled={showEdit.length > 0}
              // isLoading={loading}
            /> */}
          </PersonalDetalLayout>
        </Section>
        {showEmailOtpModal && (
          <OtpVerificationEmailModal
            open={showEmailOtpModal}
            handleClose={handleOTPModalClose}
            handleOtpChange={(otp) => createOtp(otp)}
            otp={token}
            resendOtp={() => {
              dispatch(sendOtp());
            }}
            otpToken={otp?.data}
            handleSuccessOpen={() => handleEmailOtpSubmit()}
          />
        )}
      </>
    </>
  );
}

export default PersonalInformation;
