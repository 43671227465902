import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  height: 100%;
  @media (max-width: 1215px) {
    margin-top: 25%;
    padding: 1rem;
  }
`;

export const QuickNanoLoan = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  padding: "1rem",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "22px",
  letterSpacing: "-0.19px",
  color: "#000000",
};

export const callToActionCard = {
  padding: { xs: ".2rem" },
  display: "flex",
  gap: ".5rem",
  // width: {xs: '100%'},
  justifyContent: "flex-start",
};
export const processingStage = {
  position: "relative",
  left: { xs: "200px", lg: "330px" },
  top:{xs:"-30px", lg:"-30px"},

  // left: { xs: "350px", lg: "350.5px" },
  // top: { xs: "150px", lg: "230px" },
  align: "flex-end",
  color: "#F2C94C",
  fontSize: "10px",
  fontFamily: "Montserrat",
  fontWeight: "500",
};
export const fixed = {
  overflow:"hidden"
}
export const documentsUpload = {
  position: "relative",
  left: { xs: "175px", lg: "270px" },
  color: "#219653",
  fontSize: "10px",
  fontFamily: "Montserrat",
  top: { xs: "-34px", lg: "-30px" },
  fontWeight: "500",
};
export const signOffer = {
  // position: "relative",
  // left: { xs: "270px", lg: "380px" },
  // top: { xs: "-30px", lg: "-30px" },
  fontFamily: "Montserrat",
  fontSize: "10px",
  color: "#EB5757",
  fontWeight: 500,
};

export const paragraphTitle = {
  fontFamily: "Montserrat",
  fontStyle: "13px",
  fontWeight: "400",
  paddingRight: { xxs:"3.5rem",xs:"3.0 rem", lg: "2.5rem" },
  // fontSize: '13px',
  fontSize: { xxs:"5px", xs: "10px", lg: "13px" },
  lineHeight: { xs: "20px", lg: "148.8%" },
  letterSpacing: "-0.01em",
  color: "#4F4F4F",
};
