import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  height: 100%;
  @media (max-width: 1215px) {
    margin-top: 25%;
    padding: 1rem;
  }
`;

export const QuickNanoLoan = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  padding: "1rem",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "22px",
  letterSpacing: "-0.19px",
  color: "#000000",
};

export const callToActionCard = {
  padding: { xs: ".5rem" },
  display: "flex",
  // width: {xs: '100%'},
  justifyContent: "space-between",
};

export const paragraphTitle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  // fontSize: '13px',
  fontSize: { xs: "10px", lg: "13px" },
  // lineHeight: {xs: '50'} '148.4%',
  display: "flex",
  letterSpacing: "-0.01em",
  color: "#4F4F4F",
};

export const Title = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "17px",
  lineHeight: "21px",
  letterSpacing: "-0.03em",
};

export const subTitle = {
  fontWeight: "400",
  fontFamily: "Montserrat",
  fontSize: "15px",
  lineHeight: "21px",
  letterSpacing: "-0.03em",
};


export const Head = {
    fontFamily: "Montserrat",
    fontWeight: "700",
    fontSize:"20px",lineHeight:"21px", letterSpacing:"-0.03em"
}