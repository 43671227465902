import React, { useState, useEffect, useRef } from "react";
import ProfileLayout from "../../../../../components/ProfileLayout";
import Buttons from "../../../../../components/Buttons";
import FileuploadIcon from "../../../../../components/IconSvgs/FileuploadIcon";
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Avatar,
} from "@mui/material";
import SelectField from "../../../../../components/Core/SelectField";
import BlockSpacer from "../../../../../components/BlockSpacer";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import FormLabel from "../../../../../components/Forms/Label";
import { LIST_OF_UTILITY_BILL } from "../../../../../constants";
import { Section, textStyle, profileText } from "./style";
import LinearProgress from "@mui/material/LinearProgress";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyDocs,
  sendCompanyOtp,
  getAllIdTypes,
  validateOtp,
  closeOtpModal,
} from "../../../../../redux/slices/profileSlice";
import moment from "moment";
import { updateCompanyDocs } from "../../../../../redux/slices/updateProfileSlice";
import FooterCheckoutNoLink from "../../../../../components/FooterCheckoutNoLink";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

function BusinessDocument(props) {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(true);
  const [token, setToken] = useState("");
  const [idType, setIdType] = useState("");
  const [cacProgress, setCacProgress] = useState(0);
  const [certOfIncoProgress, setCertOfIncoProgress] = useState(0);
  const [moaImageProgress, setMoaImageProgress] = useState(0);
  const [utilityProgress, setUtilityProgress] = useState(0);
  const [personImgProgress, setPersonImgProgress] = useState(0);
  const [co7ImgProgress, setCo7ImgProgress] = useState(0);
  const [base64File, setBase64File] = useState({
    cacString: "",
    certOfIncoString: "",
    contactPersonImgString: "",
    contactPersonPhotoString: "",
    moaImageString: "",
    utilityBillImg: "",
    co2AndCO7Image: "",
  });
  const initError = {
    cacString: false,
    certOfIncoString: false,
    contactPersonImgString: false,
    utilityBillImg: false,
    co2AndCO7Image: false,
  };
  const [fileErr, setFileErr] = useState(initError);
  const [openSuccess, setOpenSuccess] = useState(false);
  const { width } = useScreenSize();

  const cacFileInputRef = useRef();
  const certIncoInputRef = useRef();
  const contactImgInputRef = useRef();
  const contactPhotoInputRef = useRef();
  const moaimageInputRef = useRef();
  const utilityBillInputRef = useRef();
  const co2Co7InputRef = useRef();

  const { companyDocMsg, loading } = useSelector(
    (state) => state.updateProfile
  );

  useEffect(() => {
    dispatch(getCompanyDocs());
  }, [companyDocMsg]);

  const handleSubmit = async () => {
    const {
      cacString,
      certOfIncoString,
      utilityBillImg,
      contactPersonImgString,
      moaImageString,
      contactPersonPhotoString,
      co2AndCO7Image,
    } = base64File;

    const { utilityBillPeriod, id, otherUtilityBillType } = formData;

    let submitData = {
      co2AndCO7Image: {
        encodedUpload: cacString,
        name: "CO2 Image",
      },
      idTypeId: 1,
      utilityBillPeriod,
      utilityBillType: billType === "OTHERS" ? null : billType,
      otherUtilityBillType: billType === "OTHERS" ? otherUtilityBillType : "",
      certificateOfIncoImage: {
        encodedUpload: certOfIncoString,
        name: "Certificate of inco",
      },
      utilityBillImage: {
        encodedUpload: utilityBillImg,
        name: "Utility Image",
      },
      contactPersonIdentityImage: {
        encodedUpload: contactPersonImgString,
        name: "Contact Person",
      },
      contactPersonPhotographImage: contactPersonPhotoString
        ? {
            encodedUpload: contactPersonPhotoString,
            name: "Contact Person",
          }
        : null,
      co2AndCO7Image2: {
        encodedUpload: co2AndCO7Image,
        name: "CO7 image",
      },
      moaImage: null,
      cacImage: null,
      contactPersonIdNumber: formData.idNumber,
    };

    let data = companyDocs
      ? {
          ...submitData,
          id,
        }
      : submitData;

    // const reset = {
    //   setShowEdit,
    //   setformData,
    //   setBase64File,
    //   getUserDocs,
    // };

    console.log(data);
    dispatch(updateCompanyDocs(data));
  };

  const toggleEdit = (e) => {
    setShowEdit(!showEdit);
  };

  // utility upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setUtilityProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.utilityEncodedString]);
  // utility upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setMoaImageProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.moaImageString]);
  // id back file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCertOfIncoProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.certOfIncoString]);
  // id file upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCacProgress((oldProgress) => {
        if (oldProgress === 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.cacString]);
  // person img upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setPersonImgProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.contactPersonImgString]);
  // co7 upload
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCo7ImgProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return () => {
            clearInterval(timer);
          };
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, [base64File?.co2AndCO7Image]);

  const {
    users,
    showEmailOtpModal,
    otp,
    otpError,
    idTypes,
    validateEmailOtp,
    companyDocs,
    documents,
  } = useSelector((state) => state.profile);

  // utility bill period max and min date
  const currentDay = moment().format("YYYY-MM-DD");
  const utilityBillLimit = moment().subtract(3, "months").format("YYYY-MM-DD");

  const data = {
    idNumber: "",
    idTypeId: 1,
    utilityBillType: "",
    utilityBillPeriod: "",
    otherUtilityBillType: "",
  };
  const [formData, setformData] = useState(data);

  const idOptions = idTypes ? idTypes?.map((item) => item?.name) : [];

  useEffect(() => {
    if (companyDocs) {
      setIdType(companyDocs?.idType?.name);
      setBillType(companyDocs?.utilityBillType);
      setformData({
        ...formData,
        idNumber: companyDocs?.idNumber,
        utilityBillType: companyDocs?.utilityBillType,
        utilityBillPeriod: companyDocs?.utilityBillPeriod,
        otherUtilityBillType: companyDocs?.otherUtilityBillType,
      });
    }
  }, [companyDocs]);

  const handleIdType = (e) => {
    setIdType(e.target.value);
  };

  const [billType, setBillType] = useState("");
  const handleBillType = (e) => {
    setBillType(e.target.value);
    setformData({
      ...formData,
      utilityBillType: e.target.value,
    });
  };

  const handleFileSelect = (e, reference) => {
    e.preventDefault();
    reference.current.click();
  };

  useEffect(() => {
    dispatch(getAllIdTypes());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenSuccessMesage = () => setOpenSuccess(true);
  const handleSuceesMessageClose = () => setOpenSuccess(false);

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleFileChange = (e, name) => {
    const { files } = e.target;

    if (name === "cacString") {
      setCacProgress(0);
    } else if (name === "certOfIncoString") {
      setCertOfIncoProgress(0);
    } else if (name === "moaImageString") {
      setMoaImageProgress(0);
    } else if (name === "utilityBillImg") {
      setUtilityProgress(0);
    } else if (name === "contactPersonImgString") {
      setPersonImgProgress(0);
    } else if (name === "co2AndCO7Image") {
      setCo7ImgProgress(0);
    }

    // console.log(files[0]);

    const encodedFileBase64 = (file) => {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setBase64File({
            ...base64File,
            [name]: reader.result.split("base64,")[1],
          });
        };
        console.log(reader);
        reader.onerror = (error) => {
          console.log("error", error);
        };
      }
    };

    if (
      files[0]?.size <= 2000000 &&
      (files[0]?.type === "image/jpeg" ||
        files[0]?.type === "application/pdf" ||
        files[0]?.type === "image/png")
    ) {
      encodedFileBase64(files[0]);
      setFileErr({
        ...fileErr,
        [name]: false,
      });
    } else {
      setFileErr({
        ...fileErr,
        [name]: true,
      });
    }
    e.target.value = null;
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(sendCompanyOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
    setToken("");
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  const BILL_TYPE = ["LIGHT_BILL", "WATER_BILL", "WASTE_BILL", "OTHERS"];

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      toggleEdit();
    }
  }, [validateEmailOtp]);

  useEffect(() => {
    if (!companyDocs) {
      dispatch(getCompanyDocs());
    }
  }, [companyDocs]);

  const handleUserInputs = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const companyType = users?.company?.companyType;

  return (
    <>
      <TopBar />
      <>
        <Section>
          <ProfileLayout>
            <Box
              sx={{
                display: width > 700 ? "none" : "flex",
                mb: 3,
                mt: 3,
                pl: 2,
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box>
                <Link to="/profile">
                  <ArrowBackIosNew />
                </Link>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "24px",
                    textTransform: "capitalize",
                    color: "#222222",
                  }}
                >
                  Businesss Documents
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pr: { xs: "1rem", md: "1.5rem" },
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      mt: 5,
                      ml: { xs: 3, lg: 0 },
                      display: "flex",
                      alignItems: {
                        xs: "flex-start",
                        sm: "flex-start",
                        lg: "center",
                      },
                      justifyContent: "space-between",
                      width: 330,
                      gap: 2,
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {base64File.contactPersonPhotoString !== "" ||
                      companyDocs?.contactPersonPhotographImage?.imageUrl ? (
                        <img
                          className="image-frame"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid #FFFFFF",
                            width: width <= 700 ? "72px" : "116px",
                            height: width <= 700 ? "72px" : "116px",
                          }}
                          src={
                            base64File.contactPersonPhotoString
                              ? `data:image/jpeg;base64,${base64File.contactPersonPhotoString}`
                              : companyDocs?.contactPersonPhotographImage
                                  ?.imageUrl
                              ? companyDocs?.contactPersonPhotographImage
                                  ?.imageUrl
                              : null
                          }
                          alt="User"
                          width={width <= 700 ? "72px" : "116px"}
                          height={width <= 700 ? "72px" : "116px"}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            width: width <= 700 ? "72px" : "116px",
                            height: width <= 700 ? "72px" : "116px",
                          }}
                        />
                      )}
                      <div className="banner"></div>
                      <input
                        type="file"
                        className="file file-input"
                        accept="image/jpeg,image/png"
                        disabled={showEdit}
                        ref={contactPhotoInputRef}
                        onChange={(e) =>
                          handleFileChange(e, "contactPersonPhotoString")
                        }
                        // disabled={showEdit}
                      />
                      <CameraAltIcon
                        className="camera"
                        onClick={(e) =>
                          handleFileSelect(e, contactPhotoInputRef)
                        }
                      />
                    </div>

                    <Box>
                      <Typography sx={profileText}>Profile</Typography>
                      <Typography sx={textStyle}>
                        Update your photo and personal Details
                      </Typography>
                      {showEdit ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{ display: width > 700 && "none" }}
                        >
                          <Box
                            sx={{ ml: { xs: 0, lg: 40 }, mt: { xs: 1, lg: 6 } }}
                          >
                            <Buttons
                              color="primary"
                              width={width <= 700 ? "60px" : "72px"}
                              fontSize={width <= 700 && "14px"}
                              onClick={handleSendOtp}
                            >
                              Edit
                            </Buttons>
                          </Box>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          style={{ display: width > 700 && "none" }}
                        >
                          <Box
                            sx={{ ml: { xs: 0, lg: 40 }, mt: { xs: 1, lg: 6 } }}
                          >
                            <Buttons
                              color="primary"
                              width={width <= 700 ? "60px" : "72px"}
                              fontSize={width <= 700 && "14px"}
                              onClick={toggleEdit}
                            >
                              Cancel
                            </Buttons>
                          </Box>
                        </Grid>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {showEdit ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ display: width <= 700 && "none" }}
                  >
                    <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                      <Buttons
                        color="primary"
                        width={width <= 700 ? "60px" : "72px"}
                        fontSize={width <= 700 && "14px"}
                        onClick={handleSendOtp}
                      >
                        Edit
                      </Buttons>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ display: width <= 700 && "none" }}
                  >
                    <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                      <Buttons
                        color="primary"
                        width={width <= 700 ? "60px" : "72px"}
                        fontSize={width <= 700 && "14px"}
                        onClick={toggleEdit}
                      >
                        Cancel
                      </Buttons>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            <BlockSpacer mt={100} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormAligner>
                  <Box sx={{ ml: { xs: "1rem" } }}>
                    <FormLabel text={"ID Type"} />
                  </Box>

                  <TextField
                    placeholder="NIN"
                    type="text"
                    value={"NIN"}
                    required
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                      input: { background: !showEdit && "#e9ecef" },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={showEdit}
                    onChange={handleUserInputs}
                  />
                </FormAligner>
              </Grid>
              <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                <FormAligner>
                  <Box sx={{ ml: { xs: "1rem" } }}>
                    <FormLabel text={"ID Number"} />
                  </Box>

                  <TextField
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" },
                    }}
                    placeholder="123-000-3456"
                    type="text"
                    value={formData?.idNumber}
                    required
                    disabled={showEdit}
                    onChange={handleUserInputs}
                    name="idNumber"
                  />
                </FormAligner>
              </Grid>
            </Grid>

            <BlockSpacer mt={100} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ display: "flex" }}>
                  <FileuploadIcon />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "15px",
                        letterSpacing: "-0.04em",
                        textFransform: "capitalize",
                        color: "#828282",
                      }}
                    >
                      Contact Person ID
                    </Typography>
                    {base64File?.contactPersonImgString === "" ? (
                      <Typography>jpg, png. PDF 2 MB</Typography>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Box>
                          <Box sx={{ width: "150px" }}>
                            <LinearProgress
                              variant="determinate"
                              value={personImgProgress}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <ClearIcon
                            onClick={() => {
                              setBase64File({
                                ...base64File,
                                contactPersonImgString: "",
                              });
                              setPersonImgProgress(0);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {fileErr.contactPersonImgString && (
                      <Typography style={{ color: "#FF0000" }}>
                        File must be less than 2MB and JPG, PNG or PDF format
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              {companyDocs?.contactPersonIdImage?.imageUrl && showEdit ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <a
                      href={companyDocs?.contactPersonIdImage?.imageUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        sx={{
                          background: "#F2F2F2",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "#828282",
                        }}
                      >
                        View
                      </Button>
                    </a>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <input
                      type="file"
                      className="file"
                      accept="application/pdf,image/jpeg,image/png"
                      ref={contactImgInputRef}
                      disabled={showEdit}
                      onChange={(e) =>
                        handleFileChange(e, "contactPersonImgString")
                      }
                    />
                    <Button
                      sx={{
                        background: "#111E6C",
                        width: "150px",
                        height: "34px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "13px",
                        lineHeight: "16px",
                        textAlign: "right",
                        letterSpacing: "-0.04em",
                        color: "white",
                      }}
                      onClick={(e) => handleFileSelect(e, contactImgInputRef)}
                    >
                      Choose file
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
            <BlockSpacer mt={50} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ display: "flex" }}>
                  <FileuploadIcon />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "15px",
                        letterSpacing: "-0.04em",
                        textFransform: "capitalize",
                        color: "#828282",
                      }}
                    >
                      Company Certificate of Incorporation
                    </Typography>
                    {base64File?.certOfIncoString === "" ? (
                      <Typography>jpg, png. PDF 2 MB</Typography>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Box>
                          <Box sx={{ width: "150px" }}>
                            <LinearProgress
                              variant="determinate"
                              value={certOfIncoProgress}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <ClearIcon
                            onClick={() => {
                              setBase64File({
                                ...base64File,
                                certOfIncoString: "",
                              });
                              setCertOfIncoProgress(0);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {fileErr.certOfIncoString && (
                      <Typography style={{ color: "#FF0000" }}>
                        File must be less than 2MB and JPG, PNG or PDF format
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              {companyDocs?.certificateOfIncoImage?.imageUrl && showEdit ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <a
                      href={companyDocs?.certificateOfIncoImage?.imageUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        sx={{
                          background: "#F2F2F2",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "#828282",
                        }}
                      >
                        View
                      </Button>
                    </a>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <input
                      type="file"
                      className="file"
                      accept="application/pdf,image/jpeg,image/png"
                      ref={certIncoInputRef}
                      disabled={showEdit}
                      onChange={(e) => handleFileChange(e, "certOfIncoString")}
                    />
                    <Button
                      sx={{
                        background: "#111E6C",
                        width: "150px",
                        height: "34px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "13px",
                        lineHeight: "16px",
                        textAlign: "right",
                        letterSpacing: "-0.04em",
                        color: "white",
                      }}
                      onClick={(e) => handleFileSelect(e, certIncoInputRef)}
                    >
                      Choose file
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>

            {companyType === "CORPORATE_LIMITED" && (
              <>
                <BlockSpacer mt={50} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ display: "flex" }}>
                      <FileuploadIcon />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "15px",
                            letterSpacing: "-0.04em",
                            textFransform: "capitalize",
                            color: "#828282",
                          }}
                        >
                          Company form CO2
                        </Typography>
                        {base64File?.cacString === "" ? (
                          <Typography>jpg, png. PDF 2 MB</Typography>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box>
                              <Box sx={{ width: "150px" }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={cacProgress}
                                />
                              </Box>
                            </Box>
                            <Box>
                              <ClearIcon
                                onClick={() => {
                                  setBase64File({
                                    ...base64File,
                                    cacString: "",
                                  });
                                  setCacProgress(0);
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                        {fileErr.cacString && (
                          <Typography style={{ color: "#FF0000" }}>
                            File must be less than 2MB and JPG, PNG or PDF
                            format
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {companyDocs?.cacImage?.imageUrl && showEdit ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                        <a
                          href={companyDocs?.cacImage?.imageUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              width: "150px",
                              height: "34px",
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                              textAlign: "right",
                              letterSpacing: "-0.04em",
                              color: "#828282",
                            }}
                          >
                            View
                          </Button>
                        </a>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                        <input
                          type="file"
                          className="file"
                          accept="application/pdf,image/jpeg,image/png"
                          ref={cacFileInputRef}
                          disabled={showEdit}
                          onChange={(e) => handleFileChange(e, "cacString")}
                        />
                        <Button
                          sx={{
                            background: "#111E6C",
                            width: "150px",
                            height: "34px",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "white",
                          }}
                          onClick={(e) => handleFileSelect(e, cacFileInputRef)}
                        >
                          Choose file
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {companyType === "CORPORATE_LIMITED" && (
              <>
                <BlockSpacer mt={50} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ display: "flex" }}>
                      <FileuploadIcon />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "15px",
                            letterSpacing: "-0.04em",
                            textFransform: "capitalize",
                            color: "#828282",
                          }}
                        >
                          Company Form CO7
                        </Typography>
                        {base64File?.co2AndCO7Image === "" ? (
                          <Typography>jpg, png. PDF 2 MB</Typography>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box>
                              <Box sx={{ width: "150px" }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={co7ImgProgress}
                                />
                              </Box>
                            </Box>
                            <Box>
                              <ClearIcon
                                onClick={() => {
                                  setBase64File({
                                    ...base64File,
                                    co2AndCO7Image: "",
                                  });
                                  setCo7ImgProgress(0);
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                        {fileErr.co2AndCO7Image && (
                          <Typography style={{ color: "#FF0000" }}>
                            File must be less than 2MB and JPG, PNG or PDF
                            format
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {companyDocs?.co2AndCO7Image?.imageUrl && showEdit ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                        <a
                          href={companyDocs?.co2AndCO7Image?.imageUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              width: "150px",
                              height: "34px",
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "13px",
                              lineHeight: "16px",
                              textAlign: "right",
                              letterSpacing: "-0.04em",
                              color: "#828282",
                            }}
                          >
                            View
                          </Button>
                        </a>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                        <input
                          type="file"
                          className="file"
                          accept="application/pdf,image/jpeg,image/png"
                          ref={co2Co7InputRef}
                          disabled={showEdit}
                          onChange={(e) =>
                            handleFileChange(e, "co2AndCO7Image")
                          }
                        />
                        <Button
                          sx={{
                            background: "#111E6C",
                            width: "150px",
                            height: "34px",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "white",
                          }}
                          onClick={(e) => handleFileSelect(e, co2Co7InputRef)}
                        >
                          Choose file
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {companyType === "CORPORATE_LIMITED" && (
              <>
                <BlockSpacer mt={50} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormAligner>
                      <SelectField
                        name={"Utility Bill Type"}
                        // method={defaultStateMethod}
                        changeAction={handleBillType}
                        method={billType}
                        items={BILL_TYPE}
                        disabled={showEdit}
                        asterisk
                      />
                    </FormAligner>
                  </Grid>
                  {billType === "OTHERS" && (
                    <>
                      <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                        <FormAligner>
                          <Box sx={{ ml: { xs: "1rem" } }}>
                            <FormLabel text={"Utility Bill Type Details"} />
                          </Box>
                          <TextField
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "100%",
                                md: "90%",
                                lg: "90%",
                              },
                            }}
                            type="text"
                            disabled={showEdit}
                            required
                            value={formData.otherUtilityBillType}
                            onChange={handleUserInputs}
                            name="otherUtilityBillType"
                          />
                        </FormAligner>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                    <FormAligner>
                      <Box sx={{ ml: { xs: "1rem" } }}>
                        <FormLabel text={"Utility Bill Period"} />
                      </Box>

                      <TextField
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "90%",
                            lg: "90%",
                          },
                        }}
                        placeholder="02/02/2022"
                        type="date"
                        disabled={showEdit}
                        required
                        inputProps={{ min: utilityBillLimit, max: currentDay }}
                        value={formData?.utilityBillPeriod}
                        onChange={handleUserInputs}
                        name="utilityBillPeriod"
                      />
                    </FormAligner>
                  </Grid>
                </Grid>
              </>
            )}

            {billType === "OTHERS" && (
              <>
                <BlockSpacer mt={50} />
                <Grid container spacing={2} sx={{ px: 2 }}>
                  <Grid item xs={12} sm={2} md={6} lg={6} xl={6}>
                    <FormAligner>
                      <Box sx={{ ml: { xs: "1rem" } }}>
                        <FormLabel text={"Utility Bill Type Details"} />
                      </Box>
                      <TextField
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "100%",
                            md: "90%",
                            lg: "90%",
                          },
                        }}
                        type="text"
                        disabled={showEdit}
                        required
                        value={""}
                        // onChange={handleUserInputs}
                        name="utilityBillPeriodDetails"
                      />
                    </FormAligner>
                  </Grid>
                </Grid>
              </>
            )}

            <BlockSpacer mt={100} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ display: "flex" }}>
                  <FileuploadIcon />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "15px",
                        letterSpacing: "-0.04em",
                        textFransform: "capitalize",
                        color: "#828282",
                      }}
                    >
                      Utility Bill Image
                    </Typography>
                    {base64File?.utilityBillImg === "" ? (
                      <Typography>jpg, png. PDF 2 MB</Typography>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Box>
                          <Box sx={{ width: "150px" }}>
                            <LinearProgress
                              variant="determinate"
                              value={utilityProgress}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <ClearIcon
                            onClick={() => {
                              setBase64File({
                                ...base64File,
                                utilityBillImg: "",
                              });
                              setUtilityProgress(0);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {fileErr.utilityBillImg && (
                      <Typography style={{ color: "#FF0000" }}>
                        File must be less than 2MB and JPG, PNG or PDF format
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              {companyDocs?.utilityBillImage?.imageUrl && showEdit ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <a
                      href={companyDocs?.utilityBillImage?.imageUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        sx={{
                          background: "#F2F2F2",
                          width: "150px",
                          height: "34px",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          textAlign: "right",
                          letterSpacing: "-0.04em",
                          color: "#828282",
                        }}
                      >
                        View
                      </Button>
                    </a>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
                    <input
                      type="file"
                      className="file"
                      accept="application/pdf,image/jpeg,image/png"
                      ref={utilityBillInputRef}
                      disabled={showEdit}
                      onChange={(e) => handleFileChange(e, "utilityBillImg")}
                    />
                    <Button
                      sx={{
                        background: "#111E6C",
                        width: "150px",
                        height: "34px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "13px",
                        lineHeight: "16px",
                        textAlign: "right",
                        letterSpacing: "-0.04em",
                        color: "white",
                      }}
                      onClick={(e) => handleFileSelect(e, utilityBillInputRef)}
                    >
                      Choose file
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
            <BlockSpacer mt={50} />
            <BlockSpacer mt={200} />

            <FooterCheckoutNoLink
              proceedInfoButton={"Save"}
              disabled={showEdit}
              handleOpenSuccessMesage={handleOpenSuccessMesage}
              handleClose={() => handleSubmit()}
              isLoading={loading}
            />

            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormAligner>
                  <FormLabel text={"Source"} />
                  <TextField placeholder="" type="text" required name="" />
                </FormAligner>
              </Grid>
            </Grid> */}
          </ProfileLayout>
          {showEmailOtpModal && (
            <OtpVerificationEmailModal
              open={showEmailOtpModal}
              handleClose={handleOTPModalClose}
              handleOtpChange={(otp) => createOtp(otp)}
              otp={token}
              resendOtp={() => {
                dispatch(sendCompanyOtp());
              }}
              otpToken={otp?.data}
              handleSuccessOpen={() => handleEmailOtpSubmit()}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default BusinessDocument;
