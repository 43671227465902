import React, { useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../../components/Buttons";
import { Link } from "react-router-dom";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../../components/Cards/CollatralLoan/SummaryCard";
import OfferSuccessModal from "../../../../components/Modals/CollateralLoan/OfferSuccessModal";
import SourceAccount from "../../../../components/Modals/CollateralLoan/SorceAccountModal";
import CollateralNoteModal from "../../../../components/Modals/CollateralLoan/CollateralNoteModal";
import ApprovalBankModal from "../../../../components/Modals/CollateralLoan/ApproveBankModal(side-business)/index";
import TopBar from "../../../../components/GetCreditTopBar";

function QuickloanBusinessSummary(props) {
  const [open, setOpen] = useState(false);
  const handleOfferOpen = () => {
    setOpen(true);
  };
  const handleOfferClose = () => setOpen(false);
  //   Account source
  const [openSource, setOpenSource] = useState(false);
  const handleSourceAccontOpen = () => {
    setOpenSource(true);
    setOpen(false);
  };
  const handleSourceAccontClose = () => setOpenSource(false);

  // Note modal
  const [openNote, setOpenNote] = useState(false);
  const handleNoteOpen = () => {
    setOpenNote(true);
    setOpenSource(false);
  };
  const handleNoteClose = () => setOpenNote(false);

  // Approve bank Modal
  const [openBankApproval, setBankApproval] = useState(false);
  const handleApprovalOpen = () => {
    setBankApproval(true);
    setOpenNote(false);
  };
  const handleApprovalClose = () => setBankApproval(false);

  return (
    <>
      <TopBar/>
      <Section>
        <BlockSpacer mt={5} />
        <Typography variant="h5" component="h5" sx={contentTitle}>
          Loan Summary
        </Typography>

        <SummaryCard
          title={"Cash-Backed Loan"}
          currencyRequested={"Accessible Loan Amount"}
          interestRate={"Interest Rate"}
          interestRatePercentage={"20.00%"}
          tenor={"Tenor"}
          dataNumber={"12/08/2022"}
          feeRate={"Fee Rate"}
          currency={"2,000,000"}
          firstMonthRepayment={"First Monthly Repayment"}
          totalLoanRate={"20.00%"}
          interestPayable={"Interest Payable"}
          days={"Two Months"}
          dueDate={"Due Date"}
          totalLoanDepositWieght={"Total Loan To Deposit Weight"}
          infoPayable={"Interest Payable"}
          feePayable={"Fee Payable"}
          feePayableCurrency={"2,000,000"}
          TotalRepayment={"Total Repayment"}
          LoanDueDate={"Loan Due Date"}
          totalPaymentAmount={"100,000"}
          secondMonthRepayment={"Second Monthly Repayment"}
        />

        <BlockSpacer mt="200" />

        <Paper
          elevation={1}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "89%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/cash-back/side-business">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: { xs: "300px", lg: "328px" },
                  height: "54px",
                  color: "#111E6C",
                  maxWidth: "100%",
                  marginBottom: { xs: "1rem" },
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  textTransform: "capitalize",
                  lineHeight: "21px",
                }}
              >
                Cancel
              </Button>
            </Link>

            <Buttons
              color="primary"
              width="328px"
              onClick={() => handleOfferOpen()}
            >
              Accept Offer
            </Buttons>
          </Box>
        </Paper>

        {open && (
          <OfferSuccessModal
            handleSourceAccontOpen={handleSourceAccontOpen}
            open={open}
            handleClose={handleOfferClose}
          />
        )}
        {openSource && (
          <SourceAccount
            open={openSource}
            handleNoteOpen={handleNoteOpen}
            handleClose={handleSourceAccontClose}
          />
        )}

        {openNote && (
          <CollateralNoteModal
            open={openNote}
            handleClose={handleNoteClose}
            handleApprovalOpen={handleApprovalOpen}
          />
        )}
        {openBankApproval && (
          <ApprovalBankModal
            open={openBankApproval}
            handleClose={handleApprovalClose}
          />
        )}
      </Section>
    </>
  );
}

export default QuickloanBusinessSummary;
