import React, { useState } from "react";
import {
  Section,
  Title,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
  styles,
} from "./style.js";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import FormLabel from "../../../../components/Forms/Label/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../../components/Buttons/index.jsx";
import NoteModal from "../../../../components/Modals/NoteModal/index.jsx";
import TextField from "@mui/material/TextField";
import Loanvalue from "../../../../components/Modals/Loanvalue(no)/index";
import TopBar from "../../../../components/GetCreditTopBar";

function VehicleAssets() {
  const item = ["Select Brand"];
  const items = ["Business", "Others"];

  const [method, setMethod] = useState("");

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const [openLoanValue, setLoanValueOpen] = useState(false);

  const handleLoanValue = () => {
    setLoanValueOpen(true);
  };
  const handleLoanValueClose = () => setLoanValueOpen(false);

  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={Title}>
          Vehicle Asset Cash Loan
        </Typography>
        <Box sx={contentContent}>
          <Typography sx={paragraphtitle}>
            . Lorem Ipsum is simply dummy text of the{" "}
          </Typography>
          <Typography sx={paragraphtitle}>
            . printing and typesetting industry.
          </Typography>
          <Typography sx={paragraphtitle}>
            . Lorem Ipsum is simply dummy text of the{" "}
          </Typography>
        </Box>
        <BlockSpacer mt={20} />
        <Typography sx={requirements}>
          Product Request Requirements
        </Typography>
        <BlockSpacer mt={20} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
                marginLeft: { xs: "1rem", lg: "0rem" },
              }}
            >
              <FormAligner>
                <SelectField name={"Asset Usage Location"} maxWidth="100%" />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField
                  name={"Vehicle Brand"}
                  items={item}
                  label="Select Brand"
                />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <FormLabel text={"Vehicle's Worth"} />
                <TextField
                  placeholder="Input Worth"
                  InputProps={{
                    style: {
                      color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "17px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField name={"Salary Payment Day"} />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField name={"Tenor"} />
              </FormAligner>
              <BlockSpacer mt={20} />
              {method === items[1] && (
                <FormAligner>
                  <FormLabel text={"Input Reason here"} />

                  <TextField
                    placeholder="State reason here"
                    InputProps={{
                      style: {
                        color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                  />
                </FormAligner>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* <BlockSpacer mt={10} /> */}
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
                // marginTop: "-1rem",
              }}
            >
              <FormAligner>
                <SelectField name={"Vehicle Status"} />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField name={"Vehicle Age"} />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <FormLabel text={"Net Monthly Salary"} />
                <TextField
                  placeholder="Input net monthly salary"
                  InputProps={{
                    style: {
                      color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "17px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <FormLabel text={"Other monthly Loan Obligation"} />
                <TextField
                  placeholder="Input monthly Loan Obligation"
                  InputProps={{
                    style: {
                      color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "17px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField
                  name={"Reason for Loan"}
                  items={items}
                  changeAction={changeAction}
                  method={method}
                />
              </FormAligner>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <BlockSpacer mt={80} />
      <Paper
        elevation={0.5}
        sx={{
          height: { xs: "15%", lg: "10%" },
          width: { xs: "92%", sm: "100%", lg: "87%" },
          margin: "1rem",
          maxwidth: "100%",
          boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
            padding: "1rem",
            maxWidth: "100%",
          }}
        >
          <Link to="/no-salary-earner-loan">
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "#111E6C",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              Back
            </Button>
          </Link>

          <Buttons
            color="primary"
            width="328px"
            onClick={() => handleLoanValue()}
          >
            Profile Me
          </Buttons>
        </Box>
      </Paper>
      {openLoanValue && (
        <Loanvalue open={openLoanValue} handleClose={handleLoanValueClose} />
      )}
    </>
  );
}

export default VehicleAssets;
