import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getLoanProductByTargetMarketService,
  getLoanProductService,
  getLoanReasonService,
  initializeLoanProcessingService,
  getLoanValueService,
  generateRepaymentService,
  acceptLoanOfferService,
  loanRepaymentSetupService,
  submitLoanProcessingService,
  testDebitCardService,
  generateBankStatementService,
  getRequiredDocService,
  uploadRequiredDocService,
  getAllRegistrationService,
  getAllVehicleService,
  generateMonoAccIdService,
  fetchMonoAccDetService,
  fetchMonoAccStatementService,
  receiveMonoWebhookService,
  reauthorizeMonoService,
  getExistingCashBackedService,
  getTreasuryFundsValueService,
} from "../../services/getCreditServices";

const initialState = {
  loading: false,
  targetProduct: null,
  targetProductErr: null,
  products: null,
  productsErr: null,
  reasons: null,
  reasonsErr: null,
  initializedLoan: null,
  initializedLoanErr: null,
  loanValue: null,
  loanValueErr: null,
  repayment: null,
  repaymentErr: null,
  acceptLoan: null,
  acceptLoanErr: null,
  repaymentSetup: null,
  repaymentSetupErr: null,
  submitted: null,
  submittedErr: null,
  testCard: null,
  testCardErr: null,
  generatedBankStatement: null,
  generatedBankStatementErr: null,
  requiredDocs: null,
  requiredDocsErr: null,
  uploadDocs: null,
  uploadDocsErr: null,
  registeredServices: null,
  registeredServicesErr: null,
  allVehicles: null,
  allVehiclesErr: null,
  generatedMonoAccId: null,
  generatedMonoAccIdErr: null,
  monoAccDetails: null,
  monoAccDetailsErr: null,
  monoAccStatement: null,
  monoAccStatementErr: null,
  monoWebhook: null,
  monoWebhookErr: null,
  reauthorizeMono: null,
  reauthorizeMonoErr: null,
  existingCashBacked: null,
  existingCashBackedErr: null,
  treasuryFundValue: null,
  treasuryFundValueErr: null,
};

export const getLoanproductsByTarget = createAsyncThunk(
  "getCredit/loanByTargetMarket",
  (payload, { rejectWithValue }) => {
    return getLoanProductByTargetMarketService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getLoanProducts = createAsyncThunk(
  "getCredit/getLoanProducts",
  (payload, { rejectWithValue }) => {
    return getLoanProductService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getLoanReasons = createAsyncThunk(
  "getCredit/getLoanReasons",
  (payload, { rejectWithValue }) => {
    return getLoanReasonService()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const initializeLoanProcessing = createAsyncThunk(
  "getCredit/initializeLoanProcessing",
  (payload, { rejectWithValue }) => {
    return initializeLoanProcessingService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getLoanValue = createAsyncThunk(
  "getCredit/getLoanValue",
  (payload, { rejectWithValue }) => {
    return getLoanValueService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const generateRepayment = createAsyncThunk(
  "getCredit/generateRepayment",
  (payload, { rejectWithValue }) => {
    return generateRepaymentService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const acceptLoanOffer = createAsyncThunk(
  "getCredit/acceptLoanOffer",
  (payload, { rejectWithValue }) => {
    return acceptLoanOfferService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const loanRepaymentSetup = createAsyncThunk(
  "getCredit/loanRepaymentSetup",
  (payload, { rejectWithValue }) => {
    return loanRepaymentSetupService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const submitLoanProcessing = createAsyncThunk(
  "getCredit/submitLoanProcessing",
  (payload, { rejectWithValue }) => {
    return submitLoanProcessingService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const testDebitCard = createAsyncThunk(
  "getCredit/testDebitCard",
  (payload, { rejectWithValue }) => {
    return testDebitCardService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const generateBankStatement = createAsyncThunk(
  "getCredit/generateBankStatement",
  (payload, { rejectWithValue }) => {
    return generateBankStatementService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getRequiredDoc = createAsyncThunk(
  "getCredit/getRequiredDoc",
  (payload, { rejectWithValue }) => {
    return getRequiredDocService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const uploadRequiredDoc = createAsyncThunk(
  "getCredit/uploadRequiredDoc",
  (payload, { rejectWithValue }) => {
    return uploadRequiredDocService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getAllRegistrations = createAsyncThunk(
  "getCredit/getAllRegistrations",
  (payload, { rejectWithValue }) => {
    return getAllRegistrationService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getAllVehicles = createAsyncThunk(
  "getCredit/getAllVehicles",
  (payload, { rejectWithValue }) => {
    return getAllVehicleService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const generateMonoAccId = createAsyncThunk(
  "getCredit/generateMonoAccId",
  (payload, { rejectWithValue }) => {
    return generateMonoAccIdService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const fetchMonoAccDetails = createAsyncThunk(
  "getCredit/fetchMonoAccDetails",
  (payload, { rejectWithValue }) => {
    return fetchMonoAccDetService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const fetchMonoAccStatement = createAsyncThunk(
  "getCredit/fetchMonoAccStatement",
  (payload, { rejectWithValue }) => {
    return fetchMonoAccStatementService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const receiveMonoWebhook = createAsyncThunk(
  "getCredit/receiveMonoWebhook",
  (payload, { rejectWithValue }) => {
    return receiveMonoWebhookService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const reAuthorizeMono = createAsyncThunk(
  "getCredit/reAuthorizeMono",
  (payload, { rejectWithValue }) => {
    return reauthorizeMonoService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getExistingCashBackedLoans = createAsyncThunk(
  "getCredit/getExistingCashBackedLoans",
  (payload, { rejectWithValue }) => {
    return getExistingCashBackedService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getTreasuryFundValue = createAsyncThunk(
  "getCredit/getTreasuryFundValue",
  (payload, { rejectWithValue }) => {
    return getTreasuryFundsValueService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

const getCreditSlice = createSlice({
  name: "getCredit",
  initialState,
  reducers: {
    clearLoan(state) {
      state.initializedLoan = null;
      state.initializedLoanErr = null;
      state.loanValue = null;
      state.loanValueErr = null;
      state.repayment = null;
      state.repaymentErr = null;
      state.acceptLoan = null;
      state.acceptLoanErr = null;
      state.repaymentSetup = null;
      state.repaymentSetupErr = null;
      state.submitted = null;
      state.submittedErr = null;
      state.testCard = null;
      state.testCardErr = null;
      state.requiredDocs = null;
      state.requiredDocsErr = null;
      state.uploadDocs = null;
      state.uploadDocsErr = null;
      state.generatedBankStatement = null;
      state.generatedBankStatementErr = null;
      state.monoAccDetails = null;
      state.monoAccDetailsErr = null;
      state.monoAccStatement = null;
      state.monoAccStatementErr = null;
      state.monoWebhook = null;
      state.monoWebhookErr = null;
      state.reauthorizeMono = null;
      state.reauthorizeMonoErr = null;
      state.generatedMonoAccId = null;
      state.generatedMonoAccIdErr = null;
      state.existingCashBacked = null;
      state.existingCashBackedErr = null;
    },
    cancelInitialization(state) {
      state.initializedLoan = null;
      state.initializedLoanErr = null;
    },
    cancelLoanValue(state) {
      state.loanValue = null;
      state.loanValueErr = null;
    },
    cancelRepayments(state) {
      state.repayment = null;
      state.repaymentErr = null;
    },
    cancelAcceptedLoan(state) {
      state.acceptLoan = null;
      state.acceptLoanErr = null;
    },
    cancelSetup(state) {
      state.repaymentSetup = null;
      state.repaymentSetupErr = null;
    },
  },
  extraReducers: (builder) => {
    // fetch products by target market
    builder.addCase(getLoanproductsByTarget.pending, (state) => {
      state.loading = true;
    });
    // fetch product by target market success
    builder.addCase(getLoanproductsByTarget.fulfilled, (state, action) => {
      state.loading = false;
      state.targetProduct = action.payload;
      state.targetProductErr = null;
    });
    // fetch product by target market fail
    builder.addCase(getLoanproductsByTarget.rejected, (state, action) => {
      state.loading = false;
      state.targetProduct = null;
      state.targetProductErr = action.payload?.data;
    });
    // fetch all products
    builder.addCase(getLoanProducts.pending, (state) => {
      state.loading = true;
    });
    // fetch all products success
    builder.addCase(getLoanProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.productsErr = null;
    });
    // fetch all products fail
    builder.addCase(getLoanProducts.rejected, (state, action) => {
      state.loading = false;
      state.products = null;
      state.productsErr = action.payload?.data;
    });
    // fetch loan reasons
    builder.addCase(getLoanReasons.pending, (state) => {
      state.loading = true;
    });
    // fetch loan reasons success
    builder.addCase(getLoanReasons.fulfilled, (state, action) => {
      state.loading = false;
      state.reasons = action.payload;
      state.reasonsErr = null;
    });
    // fetch loan reasons fail
    builder.addCase(getLoanReasons.rejected, (state, action) => {
      state.loading = false;
      state.reasons = null;
      state.reasonsErr = action.payload?.data;
    });
    // initialize loan processing
    builder.addCase(initializeLoanProcessing.pending, (state) => {
      state.loading = true;
    });
    // initialize loan processing success
    builder.addCase(initializeLoanProcessing.fulfilled, (state, action) => {
      state.loading = false;
      state.initializedLoan = action.payload;
      state.initializedLoanErr = null;
    });
    // initialize loan processing fail
    builder.addCase(initializeLoanProcessing.rejected, (state, action) => {
      state.loading = false;
      state.initializedLoan = null;
      state.initializedLoanErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ??
          action.payload?.data?.message ??
          "" ??
          ""
      );
    });
    // get loan value
    builder.addCase(getLoanValue.pending, (state) => {
      state.loading = true;
    });
    // get loan value success
    builder.addCase(getLoanValue.fulfilled, (state, action) => {
      state.loading = false;
      state.loanValue = action.payload;
      state.loanValueErr = null;
      if (
        action.payload?.object?.body?.message === "Bank statement retrieved"
      ) {
        toast.success(action.payload?.object?.body?.message);
      } else {
        toast.error(action.payload?.object?.body?.message);
      }
    });
    // get loan value fail
    builder.addCase(getLoanValue.rejected, (state, action) => {
      state.loading = false;
      state.loanValue = null;
      state.loanValueErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ?? action.payload?.data?.message ?? ""
      );
    });
    // generate repayment
    builder.addCase(generateRepayment.pending, (state) => {
      state.loading = true;
    });
    // generate repayment success
    builder.addCase(generateRepayment.fulfilled, (state, action) => {
      state.loading = false;
      state.repayment = action.payload;
      state.repaymentErr = null;
    });
    // generate repayment fail
    builder.addCase(generateRepayment.rejected, (state, action) => {
      state.loading = false;
      state.repayment = null;
      state.repaymentErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ?? action.payload?.data?.message ?? ""
      );
    });
    // accept loan offer
    builder.addCase(acceptLoanOffer.pending, (state) => {
      state.loading = true;
    });
    // accept loan offer success
    builder.addCase(acceptLoanOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.acceptLoan = action.payload;
      state.acceptLoanErr = null;
    });
    // accept loan offer fail
    builder.addCase(acceptLoanOffer.rejected, (state, action) => {
      state.loading = false;
      state.acceptLoan = null;
      state.acceptLoanErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ?? action.payload?.data?.message ?? ""
      );
    });
    // loan repayment setup
    builder.addCase(loanRepaymentSetup.pending, (state) => {
      state.loading = true;
    });
    // loan repayment setup success
    builder.addCase(loanRepaymentSetup.fulfilled, (state, action) => {
      state.loading = false;
      state.repaymentSetup = action.payload;
      state.repaymentSetupErr = null;
      toast(action.payload?.object?.body?.message);
    });
    // loan repayment setup fail
    builder.addCase(loanRepaymentSetup.rejected, (state, action) => {
      state.loading = false;
      state.repaymentSetup = null;
      state.repaymentSetupErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ?? action.payload?.data?.message ?? ""
      );
    });
    // submit loan processing
    builder.addCase(submitLoanProcessing.pending, (state) => {
      state.loading = true;
    });
    // submit loan processing success
    builder.addCase(submitLoanProcessing.fulfilled, (state, action) => {
      state.loading = false;
      state.submitted = action.payload;
      state.submittedErr = null;
    });
    // submit loan processing fail
    builder.addCase(submitLoanProcessing.rejected, (state, action) => {
      state.loading = false;
      state.submitted = null;
      state.submittedErr = action.payload?.data;
      toast.error(
        action.payload?.data.object.body ?? action.payload?.data?.message ?? ""
      );
    });
    // test debit card
    builder.addCase(testDebitCard.pending, (state) => {
      state.loading = true;
    });
    // test debit card success
    builder.addCase(testDebitCard.fulfilled, (state, action) => {
      state.loading = false;
      state.testCard = action.payload;
      state.testCardErr = null;
      window.open(action.payload?.paymentLink, "_blank");
    });
    // test debit card fail
    builder.addCase(testDebitCard.rejected, (state, action) => {
      state.loading = false;
      state.testCard = null;
      state.testCardErr = action.payload?.data;
    });
    // generate bank statement
    builder.addCase(generateBankStatement.pending, (state) => {
      state.loading = true;
    });
    // generate bank statement success
    builder.addCase(generateBankStatement.fulfilled, (state, action) => {
      state.loading = false;
      state.generatedBankStatement = action.payload;
      state.generatedBankStatementErr = null;
    });
    // generate bank statement fail
    builder.addCase(generateBankStatement.rejected, (state, action) => {
      state.loading = false;
      state.generatedBankStatement = null;
      state.generatedBankStatementErr = action.payload?.data;
    });
    // get required documents
    builder.addCase(getRequiredDoc.pending, (state) => {
      state.loading = true;
    });
    // get required documents success
    builder.addCase(getRequiredDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.requiredDocs = action.payload;
      state.requiredDocsErr = null;
    });
    // get required documents fail
    builder.addCase(getRequiredDoc.rejected, (state, action) => {
      state.loading = false;
      state.requiredDocs = null;
      state.requiredDocsErr = action.payload?.data;
    });
    // upload documents
    builder.addCase(uploadRequiredDoc.pending, (state) => {
      state.loading = true;
    });
    // upload documents success
    builder.addCase(uploadRequiredDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.uploadDocs = action.payload;
      state.uploadDocsErr = null;
    });
    // upload documents fail
    builder.addCase(uploadRequiredDoc.rejected, (state, action) => {
      state.loading = false;
      state.uploadDocs = null;
      state.uploadDocsErr = action.payload?.data;
      if (action.payload?.data?.statusCode === "EXPECTATION_FAILED") {
        toast.error("Expectation Failed! Please try again!");
      }
    });
    // get all registered service
    builder.addCase(getAllRegistrations.pending, (state) => {
      state.loading = true;
    });
    // get all registered service success
    builder.addCase(getAllRegistrations.fulfilled, (state, action) => {
      state.loading = false;
      state.registeredServices = action.payload;
      state.registeredServicesErr = null;
    });
    // get all registered service fail
    builder.addCase(getAllRegistrations.rejected, (state, action) => {
      state.loading = false;
      state.registeredServices = null;
      state.registeredServicesErr = action.payload?.data;
    });
    // get all vehicles
    builder.addCase(getAllVehicles.pending, (state) => {
      state.loading = true;
    });
    // get all vehicles success
    builder.addCase(getAllVehicles.fulfilled, (state, action) => {
      state.loading = false;
      state.allVehicles = action.payload;
      state.allVehiclesErr = null;
    });
    // get all vehicles fail
    builder.addCase(getAllVehicles.rejected, (state, action) => {
      state.loading = false;
      state.allVehicles = null;
      state.allVehiclesErr = action.payload?.data;
    });
    // generate mono account id
    builder.addCase(generateMonoAccId.pending, (state) => {
      state.loading = true;
    });
    // generate mono account id success
    builder.addCase(generateMonoAccId.fulfilled, (state, action) => {
      state.loading = false;
      state.generatedMonoAccId = action.payload;
      state.generatedMonoAccIdErr = null;
      toast(action.payload?.message);
    });
    // generate mono account id fail
    builder.addCase(generateMonoAccId.rejected, (state, action) => {
      state.loading = false;
      state.generatedMonoAccId = null;
      state.generatedMonoAccIdErr = action.payload?.data;
      toast(action.payload?.message);
    });
    // fetch mono account details
    builder.addCase(fetchMonoAccDetails.pending, (state) => {
      state.loading = true;
    });
    // fetch mono account details success
    builder.addCase(fetchMonoAccDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.monoAccDetails = action.payload;
      state.monoAccDetailsErr = null;
      toast(action.payload?.message);
    });
    // fetch mono account details fail
    builder.addCase(fetchMonoAccDetails.rejected, (state, action) => {
      state.loading = false;
      state.monoAccDetails = null;
      state.monoAccDetailsErr = action.payload?.data;
      toast(action.payload?.message);
    });
    // fetch mono account statement
    builder.addCase(fetchMonoAccStatement.pending, (state) => {
      state.loading = true;
    });
    // fetch mono account statement success
    builder.addCase(fetchMonoAccStatement.fulfilled, (state, action) => {
      state.loading = false;
      state.monoAccStatement = action.payload;
      state.monoAccStatementErr = null;
      toast(action.payload?.message);
    });
    // fetch mono account statement fail
    builder.addCase(fetchMonoAccStatement.rejected, (state, action) => {
      state.loading = false;
      state.monoAccStatement = null;
      state.monoAccStatementErr = action.payload?.data;
      toast(action.payload?.message);
    });
    // receive mono webhook
    builder.addCase(receiveMonoWebhook.pending, (state) => {
      state.loading = true;
    });
    // receive mono webhook success
    builder.addCase(receiveMonoWebhook.fulfilled, (state, action) => {
      state.loading = false;
      state.monoWebhook = action.payload;
      state.monoWebhookErr = null;
      toast(action.payload?.message);
    });
    // receive mono webhook fail
    builder.addCase(receiveMonoWebhook.rejected, (state, action) => {
      state.loading = false;
      state.monoWebhook = null;
      state.monoWebhookErr = action.payload?.data;
      toast(action.payload?.message);
    });
    // reauthorize mono
    builder.addCase(reAuthorizeMono.pending, (state) => {
      state.loading = true;
    });
    // reauthorize mono success
    builder.addCase(reAuthorizeMono.fulfilled, (state, action) => {
      state.loading = false;
      state.reauthorizeMono = action.payload;
      state.reauthorizeMonoErr = null;
      toast(action.payload?.message);
    });
    // reauthorize mono fail
    builder.addCase(reAuthorizeMono.rejected, (state, action) => {
      state.loading = false;
      state.reauthorizeMono = null;
      state.reauthorizeMonoErr = action.payload?.data;
      toast(action.payload?.message);
    });
    // get existing cash backed loans
    builder.addCase(getExistingCashBackedLoans.pending, (state) => {
      state.loading = true;
    });
    // get existing cash backed loans success
    builder.addCase(getExistingCashBackedLoans.fulfilled, (state, action) => {
      state.loading = false;
      state.existingCashBacked = action.payload;
      state.existingCashBackedErr = null;
    });
    // get existing cash backed loans fail
    builder.addCase(getExistingCashBackedLoans.rejected, (state, action) => {
      state.loading = false;
      state.existingCashBacked = null;
      state.existingCashBackedErr = action.payload?.data;
    });
    // get treasury fund value
    builder.addCase(getTreasuryFundValue.pending, (state) => {
      state.loading = true;
    });
    // get existing cash backed loans success
    builder.addCase(getTreasuryFundValue.fulfilled, (state, action) => {
      state.loading = false;
      state.treasuryFundValue = action.payload;
      state.treasuryFundValueErr = null;
    });
    // get existing cash backed loans fail
    builder.addCase(getTreasuryFundValue.rejected, (state, action) => {
      state.loading = false;
      state.treasuryFundValue = null;
      state.treasuryFundValueErr = action.payload?.data;
    });
  },
});

export default getCreditSlice.reducer;
export const {
  clearLoan,
  cancelAcceptedLoan,
  cancelInitialization,
  cancelLoanValue,
  cancelRepayments,
  cancelSetup,
} = getCreditSlice.actions;
