import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import feedbackReducer from "../reducers/feedbackpost";
import editTicketReducer from "../reducers/edit-ticket";
import helpReducer from "../slices/helpSlice";
import paymentSlice from "../slices/paymentSlice";
import profileSlice from "../slices/profileSlice";
import updateProfileSlice from "../slices/updateProfileSlice";
import CategoryId from "../slices/CategoryIdSlice";
import walletSlice from "../slices/walletSlice";
import getCreditSlice from "../slices/getCreditSlice";
import myCreditSlice from "../slices/myCreditSlice";
import notificationSlice from "../slices/notificationSlice";
import switchPlatformSlice from "../slices/switchPlatformSlice";
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  feedback: feedbackReducer,
  getCredit: getCreditSlice,
  id: CategoryId,
  myCredit: myCreditSlice,
  payment: paymentSlice,
  profile: profileSlice,
  editTicket:editTicketReducer,
  help: helpReducer,
  updateProfile: updateProfileSlice,
  wallet: walletSlice,
  switchPlatform: switchPlatformSlice,
  notifications: notificationSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
