import React, { useState, useEffect } from "react";
import Copy from "../../../components/Assets/icons/copy.svg";
import NoteModal from "../../../components/Modals/PokeUser/index";
import PokeModal from "../../../components/Modals/Poker/index";
import style from "../style.module.css";
import ShareLink from "../../../components/ReferalLink/ShareLink";
import PokeReferral from "../Poked Referrals/index";
import TopBar from "../../../components/WalletTopBar";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import { Section, headingText } from "./style";
import BlockSpacer from "../../../components/BlockSpacer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMyReferrals } from "../../../redux/slices/walletSlice";
import Paginate from "../../../components/Paginate";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from '@mui/icons-material';
import { Link } from "react-router-dom";


function MyReferral() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleOpenModalClose = () => {
    setOpenModal(false);
  };
  const [useReferral, setUseReferral] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyReferrals());
  }, [])

  const { myReferrals, loading } = useSelector((state) => state.wallet);
  const { users } = useSelector((state) => state.profile);
  const { width } = useScreenSize();

  const handleRef = () => {
    setUseReferral(true);
    setPokeModal(true);
  };
  const [pokeModal, setPokeModal] = useState(false);
  const handlePokeModal = () => {
    setPokeModal(true);
    setOpenModal(false);
  };
  const handlePokeModalClose = () => {
    setPokeModal(false);
  };

  const nextPage = () => {
    setCurrentPage(currentPage+1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage-1);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.grey,
      color: theme.palette.common.black,
    },
  }));

  const totalTransactions = Math.ceil((myReferrals?.entities?.length)/10);
  const currentTransactions = currentPage === totalTransactions ? 
  myReferrals?.entities?.slice(((currentPage-1)*10),myReferrals?.entities?.length) :
  myReferrals?.entities?.slice(((currentPage-1)*10), (currentPage*10))
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
    <>
      <TopBar />
      <main>
        {!useReferral && (
          <Section>
            <Box>
              <Box 
                sx={{
                  mb:2,
                  mt:1,
                  display:"flex",
                  alignItems: "center",
                  gap: 2
                }} 
              >
                <Box sx={{display:{md:"none",lg:"none",xs:"flex",sm:"flex"}}} >
                  <Link to="/wallet" >
                    <ArrowBackIosNew />
                  </Link>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "17px",
                      lineHeight: "20px",
                      lettSpacing: "-0.03em",
                    }}
                  >
                    My Referrals
                  </Typography>
                </Box>
              </Box>
              <form
                className={style.referralForm}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "45%",
                  alignItems: "center"
                }}
              >
                <Box 
                  sx={{ 
                    marginLeft: {xs:"-1.4rem",sm:"-1.4rem",md:"-2rem",lg:"-2rem"},
                    marginRight: "0.6rem"
                  }}
                >
                  <label
                    htmlFor="Top Request"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      display: "block",
                      maxWidth: "100%",
                      marginBottom: "1.5rem",
                    }}
                  >
                    Referral Link
                  </label>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      Width: "174px",
                      Height: "16px",
                    }}
                  >
                    <input
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineSeight: "16px",
                        display: "flex",
                        letterSpacing: "-0.04em",
                        color: "#333333",
                        padding: "1rem",
                        whiteSpace: "no-wrap",
                        maxWidth: "100%",
                        overflowX: "hidden",
                        border: "none"
                      }}
                      value={users?.referralLink}
                      readOnly
                    />
                    <Box
                      sx={{
                        background: "#F2F2F2",
                        borderRadius: "4px",
                        height: "48px",
                      }}
                    >
                      <ShareLink />
                    </Box>
                  </Box>

                  {/* <input
                    type="text"
                    name=""
                    id="pley"
                    placeholder=""
                    style={{
                      width: "335px",
                      maxWidth: "100%",
                      height: "48px",
                      padding: "12px 20px",
                      margin: "8px 0",
                      display: "inlineBlock",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      boxSizing: "borderBox",
                      position: "absolute",
                    }}
                  />

                  <div
                    style={{
                      background: "#F2F2F2",
                      width: "48px",
                      height: "49px",
                      position: "relative",
                      top: ".5rem",
                      left: "18rem",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      src={Copy}
                      alt=""
                      style={{
                        cursor: "pointer",
                        top: "10px",
                        left: "10px",
                        position: "absolute",
                      }}
                      onClick={() =>
                        navigator.clipboard.writeText("")
                      }
                    />
                  </div> */}
                </Box>
                <div>
                  <label
                    htmlFor="Top Request"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      display: "block",
                      maxWidth: "100%",
                      marginBottom: "1.5rem",
                    }}
                  >
                    Total Referrals
                  </label>
                  <input
                    type="text"
                    name=""
                    id="del"
                    placeholder="12"
                    style={{
                      width: "91.65px",
                      maxWidth: "100%",
                      height: "48px",
                      padding: "12px 30px",
                      margin: "8px 0",
                      display: "inlineBlock",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      boxSizing: "borderBox",
                      // position: "absolute",
                    }}
                    readOnly
                    value={myReferrals?.entities?.length}
                  />
                </div>
              </form>
            </Box>
            <BlockSpacer mt={60} />
            <Box>
              <TableContainer 
                component={Paper} 
                sx={{maxWidth:980, display:{xs:"none",sm:"none",lg:"block", md:"block"}}} 
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >S/N</StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >Date</StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >Name</StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      >Status</StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                          lineHeight: "20px",
                          letterSpacing: "-0.03em",
                        }}
                      ></StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {currentTransactions?.map((row) => (
                      <StyledTableRow
                        key={row?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row"
                          sx={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >{row.dateOfReg}</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >{row.customerName}</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >{row.status}</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >
                          <button
                            className={row.status === "INACTIVE" ? style.buttn : style.butten}
                            disabled={row.status === "ACTIVE"}
                            // onClick={() => handleClick(data.id)}
                            onClick={row.status === "INACTIVE" ?handleOpenModal:handleRef}
                            // onClick={handleRef}
                          >
                            Poke User
                          </button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {
                currentTransactions?.map((row) => (
                  <Box 
                    key={row.id} 
                    sx={{
                      display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                      py:2,
                      px:2,
                      justifyContent:"space-between",
                      alignItems: "center",
                      gap:2
                    }} 
                  >
                    <Box>
                      <Typography className={style.referralDate} >{row.dateOfReg}</Typography>
                      <Typography className={style.referralName} >{row.customerName}</Typography>
                    </Box>
                    <Box>
                      <Box sx={{display:"flex",alignItems:"center",gap:6}} >
                        <Typography className={style.referralStatus} >{row.status}</Typography>
                        <div
                          style={{
                              width:8,heigh:8,borderRadius:"100%",
                              backgroundColor: row.status==="ACTIVE" ? "#6FCF97" : "#828282"
                            }}
                        ></div>
                      </Box>
                      <Box>
                        <button
                          className={row.status === "INACTIVE" ? style.buttn : style.butten}
                          disabled={row.status === "ACTIVE"}
                          // onClick={() => handleClick(data.id)}
                          onClick={row.status === "INACTIVE" ?handleOpenModal:handleRef}
                          // onClick={handleRef}
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "17px",
                            letterSpacing: "-0.01em",
                          }}
                        >
                          Poke User
                        </button>
                      </Box>
                    </Box>
                  </Box>
                ))
              }
              {/* <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  background: " #F8F8F8",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  1
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Active
                </Typography>
                <button className={style.buttn} onClick={handleOpenModal}>
                  Poke User
                </button>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  2
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Inactive
                </Typography>
                <button className={style.butten} onClick={handleRef}>
                  Poke User
                </button>
              </Box> */}
            </Box>
          </Section>
        )}
        {useReferral && (
          <Section>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "17px",
                  lineHeight: "20px",
                  lettSpacing: "-0.03em",
                }}
              >
                My Referrals
              </Typography>
              <form
                className={style.referralForm}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "45%",
                }}
              >
                <div style={{ marginTop: "2rem", marginLeft: "-2rem" }}>
                  <label
                    htmlFor="Top Request"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      display: "block",
                      maxWidth: "100%",
                    }}
                  >
                    Referral Link
                  </label>
                  <input
                    type="text"
                    name=""
                    id="pley"
                    placeholder=""
                    style={{
                      width: "335px",
                      maxWidth: "100%",
                      height: "48px",
                      padding: "12px 20px",
                      margin: "8px 0",
                      display: "inlineBlock",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      boxSizing: "borderBox",
                      position: "absolute",
                    }}
                  />

                  <div
                    style={{
                      background: "#F2F2F2",
                      width: "48px",
                      height: "49px",
                      position: "relative",
                      top: ".5rem",
                      left: "18rem",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      src={Copy}
                      alt=""
                      style={{
                        cursor: "pointer",
                        top: "10px",
                        left: "10px",
                        position: "absolute",
                      }}
                      onClick={() =>
                        navigator.clipboard.writeText(users?.referralLink) 
                      }
                    />
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <label
                    htmlFor="Top Request"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      display: "block",
                      maxWidth: "100%",
                    }}
                  >
                    Total Referrals
                  </label>
                  <input
                    type="text"
                    name=""
                    id="del"
                    placeholder="12"
                    style={{
                      width: "91.65px",
                      maxWidth: "100%",
                      height: "48px",
                      padding: "12px 20px",
                      margin: "8px 0",
                      display: "inlineBlock",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      boxSizing: "borderBox",
                      position: "absolute",
                    }}
                  />
                </div>
              </form>
            </Box>
            <BlockSpacer mt={60} />
            <Box>
              <Box
                display={"flex"}
                sx={{
                  width: "683px",
                  maxWidth: "70%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "20px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  ID
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "20px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Date
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "20px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "20px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Status
                </Typography>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  background: " #F8F8F8",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  N0_1947034
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Active
                </Typography>
                <button className={style.buttn} onClick={handleOpenModal}>
                  Poke User
                </button>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  N0_1947034
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Inactive
                </Typography>
                <button className={style.butten} onClick={handleRef}>
                  Poke User
                </button>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  background: " #F8F8F8",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  N0_1947034
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Active
                </Typography>
                <button className={style.buttn} onClick={handleOpenModal}>
                  Poke User
                </button>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  width: "987px",
                  maxWidth: "92%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  N0_1947034
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Apr 28, 2022
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Jane Doe
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Active
                </Typography>
                <button className={style.buttn} onClick={handleOpenModal}>
                  Poke User
                </button>
              </Box>
            </Box>
          </Section>
        )}
        <BlockSpacer mt={80} />
        <Paginate 
          current={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          total={totalTransactions}
        />
        {openModal && (
          <NoteModal
            open={openModal}
            handleClose={handleOpenModalClose}
            handlePokeModal={handlePokeModal}
          />
        )}
        {pokeModal && (
          <PokeModal
            open={pokeModal}
            handleClose={handlePokeModalClose}
            // poke={handleRef}
          />
        )}
        <BlockSpacer mt={width>700?240:120} />
        <Button
          sx={{
            background: "#111E6C",
            width: "328px",
            height: "54px",
            color: "#FFFFFF",
            marginBottom: "1rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            textTransform: "capitalize",
            fontSize: "18px",
            display: {xs:"none",md:"block"},
            "&:hover": {
              backgroundColor: "#BDBDBD",
              color: "#111E6C",
            },
          }}
          onClick={() => {
            navigate("/wallet");
          }}
        >
          Back
        </Button>
        <BlockSpacer mt={20} />
      </main>
    </>
  );
}

export default MyReferral;
