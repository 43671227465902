import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import BlockSpacer from "../../../components/BlockSpacer";
import style from "../style.module.css";
import "../stye.css";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";

const WithdrawToBank = ({
  withdrawForm,
  walletBalance,
  role,
  bankDetailsError,
  handleChange,
  bankDetails,
  frontFileInputRef,
  handleFileChange,
  handleFileSelect,
  withdrawOptions,
  close,
  submit,
}) => {
  const { width } = useScreenSize();
  return (
    <Box
      sx={{
        background: "rgba(28, 68, 141, 0.03)",
        width: { xs: "100vw", sm: "100vw", md: "503px", lg: "503px" },
        height: { xs: "100vh", sm: "100vh", md: "870px", lg: "870px" },
        position: { md: "absolute", lg: "absolute" },
        right: "0px",
        top: "0px",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          gap: 4,
          alignItems: "center",
          marginTop: "2rem",
          paddingLeft: "1rem",
        }}
      >
        <IconButton onClick={() => close()}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "16px",
            letterSpacing: "-0.03em",
          }}
        >
          Wallet
        </Typography>
      </Box>
      <BlockSpacer mt={width > 700 ? 160 : 50} />
      <Card
        sx={{
          maxWidth: "100%",
          width: { md: "430px", lg: "430px" },
          height: "auto",
          background: "#FFFFFF",
          boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <CardContent>
          <Box
            display={"flex"}
            sx={{
              alignItems: "center",
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "16px",
                letterSpacing: "-0.03em",
                padding: { xs: "1rem", sm: "1rem", md: "2rem", lg: "2rem" },
              }}
            >
              Available Balance
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "19px",
                lineHeight: "23px",
                letterSpacing: "-0.03em",
                padding: { xs: "1rem", sm: "1rem", md: "2rem", lg: "2rem" },
              }}
            >
              ₦{" "}
              {withdrawForm.withdrawalAmount !== ""
                ? (
                    walletBalance?.amount -
                    parseFloat(withdrawForm.withdrawalAmount)
                  )?.toLocaleString()
                : walletBalance?.amount.toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <form className={style.TopRequest}>
              {role !== "COMPANY" &&
                bankDetailsError ===
                  "Bank Account not available for this user" && (
                  <span className="text-danger">
                    No Registered Bank Account Details
                  </span>
                )}
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="Top Request"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "block",
                  }}
                >
                  Withdrawal Amount
                </label>
                <input
                  type="number"
                  name="withdrawalAmount"
                  value={withdrawForm.withdrawalAmount}
                  id="pley"
                  placeholder="  ₦ 1,500,000"
                  onChange={handleChange}
                  style={{
                    width: "321px",
                    height: "48px",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "inlineBlock",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxSizing: "borderBox",
                  }}
                />
              </div>
              <div className="pt-1 mb-3">
                <div className="mb-4">
                  <label>Beneficiary Account</label>
                  <div className="input-group">
                    <select
                      style={{
                        width: "321px",
                        height: "48px",
                        padding: "12px 20px",
                        margin: "8px 0",
                        display: "inlineBlock",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxSizing: "borderBox",
                      }}
                      name="bankAccountId"
                      value={withdrawForm.bankAccountId}
                      onChange={handleChange}
                    >
                      {role === "COMPANY" ? (
                        <option value={0}>To Bank</option>
                      ) : (
                        <>
                          <option value={0}>Please select your account</option>
                          <option value={bankDetails?.id}>
                            {bankDetails?.bank?.name} -{" "}
                            {bankDetails?.accountNumber}
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                  {/* {errors.bankAccountId && (
                    <small className="text-danger">{errors.bankAccountId}</small>
                  )} */}
                </div>
              </div>
              {role === "COMPANY" ? (
                <>
                  <BlockSpacer />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ display: "flex" }}>
                        {/* <FileuploadIcon /> */}
                        <Box>
                          <Typography>
                            Upload withdrawal mandate instruction letter
                          </Typography>
                          <Typography>jpg, png. PDF 2 MB</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box>
                        <input
                          type="file"
                          className="file"
                          style={{ display: "none" }}
                          ref={frontFileInputRef}
                          onChange={(e) => handleFileChange(e)}
                        />
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            width: "120px",
                            height: "auto",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "right",
                            letterSpacing: "-0.04em",
                            color: "#828282",
                          }}
                          onClick={(e) =>
                            handleFileSelect(e, frontFileInputRef)
                          }
                        >
                          Choose file
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <BlockSpacer />
                </>
              ) : (
                <></>
              )}
              <div className={style.TenorForm}>
                <label
                  htmlFor="Tenor"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "block",
                  }}
                >
                  Reason for Withdrawal
                </label>
                <select
                  style={{
                    width: "321px",
                    height: "48px",
                    padding: "12px 20px",
                    margin: "8px 0",
                    display: "Block",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxSizing: "borderBox",
                  }}
                  // onClick={handleOnclick}
                  name="withdrawalReasonId"
                  value={withdrawForm.withdrawalReasonId}
                  onChange={handleChange}
                >
                  <option value="">Please select reason for withdrawal</option>
                  {withdrawOptions
                    ?.filter((item) => item.status === "ACTIVE")
                    ?.map((data) => (
                      <option key={data.id} value={`${data.id}`}>
                        {data.reason}
                      </option>
                    ))}
                  <option value="others">Others</option>
                </select>
              </div>
              {withdrawForm.withdrawalReasonId === "others" && (
                <div style={{ marginTop: "1rem" }}>
                  <label
                    htmlFor="Tenor"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "12px",
                      lineHeight: "15px",
                      display: "block",
                    }}
                  >
                    Others
                  </label>
                  <textarea
                    id="pley"
                    placeholder="Please provide reason for withdrawal"
                    style={{
                      width: "321px",
                      height: "133px",

                      padding: "12px 20px",
                      margin: "8px 0",
                      display: "inlineBlock",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      boxSizing: "borderBox",
                    }}
                    value={withdrawForm.withdrawalReasonOthers}
                    name="withdrawalReasonOthers"
                    onChange={handleChange}
                  />
                </div>
              )}
              <Button
                sx={{
                  background: "#111E6C",
                  borderRadius: "10px",
                  width: "100%",
                  height: "54px",
                  marginTop: "3rem",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "#BDBDBD",
                    color: "#111E6C",
                  },
                }}
                onClick={() => {
                  submit();
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WithdrawToBank;
