import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import Success from "../../Assets/images/success.png";
import Button from "../../Buttons";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:302.91,sm:302.91,md:422.62,lg:422.62},
  maxWidth: "100%",
  height: 342,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function BVNSuccess({ open, handleClose, handleChange }) {
  const BVNData = JSON.parse(localStorage.getItem("BVNDATA"));
  const close = () => {
    if(handleChange) {
      handleChange(BVNData?.data?.firstName, BVNData?.data?.lastName);
    }
    handleClose();
  }
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
          width: { xs: "50px" },
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.header}>
              <img src={Success} style={{ width: "56px", height: "56px" }} />
            </div>
            <BlockSpacer mt={70} />
            <div className={styles.text}>Your BVN is Verified</div>
            <BlockSpacer mt={70} />
            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}
                onClick={close}
              >
                Continue
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
