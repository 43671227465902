import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import ShareLink from "./ShareLink";
import ShareCode from "./ShareCode";
import { useSelector } from "react-redux";

function ReferalLink() {
  const [expanded, setExpanded] = React.useState(false);
  const { users } = useSelector(state => state.profile)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box
        sx={{
          mt: "2rem",
          maxWidth: { xs: "100%", lg: "405px" },
          height: "239px",
          padding: "1rem",
          marginLeft: { xs: "0", lg: "7rem" },
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>My Referral Link</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#828282",
              }}
            >
              Referral Link
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                Width: "174px",
                Height: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineSeight: "16px",
                  display: "flex",
                  letterSpacing: "-0.04em",
                  color: "#333333",
                  padding: "1rem",
                }}
              >
                {users?.referralLink}
              </Typography>
              <Box
                sx={{
                  background: "#F2F2F2",
                  borderRadius: "4px",
                  height: "48px",
                }}
              >
                <ShareLink />
              </Box>
            </Box>
          </AccordionDetails>

          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Referral Code</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
                color: "#828282",
              }}
            >
              Referral Code
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                Width: "174px",
                Height: "16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineSeight: "16px",
                  display: "flex",
                  letterSpacing: "-0.04em",
                  color: "#333333",
                  padding: "1rem",
                }}
              >
                {users?.myReferralCode}
              </Typography>
              <Box
                sx={{
                  background: "#F2F2F2",
                  borderRadius: "4px",
                  height: "48px",
                }}
              >
                <ShareCode />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default ReferalLink;

// export default function ControlledAccordions() {
//   const [expanded, setExpanded] = React.useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   return (
//     <div>
//       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel1bh-content"
//           id="panel1bh-header"
//         >
//           <Typography sx={{ width: '33%', flexShrink: 0 }}>
//             General settings
//           </Typography>
//           <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
//             Aliquam eget maximus est, id dignissim quam.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel2bh-content"
//           id="panel2bh-header"
//         >
//           <Typography sx={{ width: '33%', flexShrink: 0 }}>Users</Typography>
//           <Typography sx={{ color: 'text.secondary' }}>
//             You are currently not an owner
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
//             varius pulvinar diam eros in elit. Pellentesque convallis laoreet
//             laoreet.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel3bh-content"
//           id="panel3bh-header"
//         >
//           <Typography sx={{ width: '33%', flexShrink: 0 }}>
//             Advanced settings
//           </Typography>
//           <Typography sx={{ color: 'text.secondary' }}>
//             Filtering has been entirely disabled for whole web server
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
//             amet egestas eros, vitae egestas augue. Duis vel est augue.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//       <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel4bh-content"
//           id="panel4bh-header"
//         >
//           <Typography sx={{ width: '33%', flexShrink: 0 }}>Personal data</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography>
//             Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
//             amet egestas eros, vitae egestas augue. Duis vel est augue.
//           </Typography>
//         </AccordionDetails>
//       </Accordion>
//     </div>
//   );
// }
