import React, { useState, useEffect, useRef } from "react";
import Ellipse from "../../components/Assets/icons/ellipse.png";
import Naira from "../../components/Assets/icons/naira.svg";
import Atm from "../../components/Assets/icons/atm.svg";
import Transfer from "../../components/Assets/icons/transfer.svg";
import History from "../../components/Assets/icons/history.svg";
import Fund from "../../components/Assets/icons/fund.svg";
import { Section } from "./style";
import BlockSpacer from "../../components/BlockSpacer";
import Drawer from "@mui/material/Drawer";

import WithdrawalRequest from "../../components/Modals/WithdrawWallet";
import TransferSuccess from "../../components/Modals/TransferSuccess";
import "./stye.css";
import { Link } from "react-router-dom";
import { Typography, Grid, Paper, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletBalance,
  fundByBankAccount,
  getWithdrawalReasons,
  withdrawToBank,
  clearWithdrawMsgs,
  transferToTreasury,
  clearTransferMsgs,
} from "../../redux/slices/walletSlice";
// import Ellipse from "../../assets/Ellipse.png";
import {
  registerTransaction,
  clearReferences,
} from "../../redux/slices/paymentSlice";
import ApproveDebitFund from "../../components/Modals/ApproveDebitFund";
import { getBankDetails } from "../../redux/slices/profileSlice";
import FileuploadIcon from "../../components/IconSvgs/FileuploadIcon";
import { toast, ToastContainer } from "react-toastify";
import TopBar from "../../components/WalletTopBar";
import WithdrawToBank from "./walletActions/Withdraw";
import useScreenSize from "../../components/ScreenSize";
import TransferToTreasury from "./walletActions/Transfer";
import {
  DebitCardFund,
  FundWallet,
  TransferFund,
  UssdFund,
} from "./walletActions/Fund";

function Wallet() {
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [openWithdrawRequest, setOpenWithdrawRequest] = useState(false);
  const [openButton, setOPenButton] = useState(false);

  const { users, bankDetails, bankDetailsError } = useSelector(
    (state) => state.profile
  );
  const {
    walletBalance,
    bankFund,
    withdrawReasons,
    withdrawMsg,
    withdrawMsgError,
    treasuryTransferMsg,
  } = useSelector((state) => state.wallet);

  const [totalBalance, setTotalBalance] = useState(walletBalance?.amount);

  useEffect(() => {
    setTotalBalance(walletBalance?.amount);
  }, [walletBalance]);

  const [sendAmount, setSendAmount] = useState(0);
  const [transferAmount, setTransferAmount] = useState(0);
  const withdrawData = {
    bankAccountId: "",
    withdrawalAmount: "",
    withdrawalReasonId: "",
    withdrawalReasonOthers: "",
  };

  const withdrawOptions = withdrawReasons ? withdrawReasons?.data?.body : [];

  const [withdrawMandate, setWithdrawMandate] = useState("");
  const frontFileInputRef = useRef();
  const [withdrawForm, setWithdrawForm] = useState(withdrawData);
  const { width } = useScreenSize();

  useEffect(() => {
    dispatch(getWalletBalance());
    dispatch(fundByBankAccount());
    dispatch(getBankDetails());
    dispatch(getWithdrawalReasons());
  }, []);

  const ButtonOpen = () => {
    setOPenButton(true);
  };

  const handleWithdrawRequestClose = () => {
    setOpenWithdrawRequest(false);
  };
  const [openTransfer, setOpenTransfer] = useState(false);

  const handleOpenTransfer = () => {
    setOpenTransfer(true);
  };
  const handleOpenTransferClose = () => {
    setOpenTransfer(false);
  };

  const [fundz, setFundz] = useState(false);
  const [change, setChange] = useState(false);
  const [xchange, setXchange] = useState(false);
  const [dChange, setDchange] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [tranfers, setTransfers] = useState(false);
  const [ussd, setUssd] = useState(false);
  const [debitz, setDebitz] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [balance, setBalance] = useState(false);
  const [fundMethod, setFundMethod] = useState("");
  const [payModal, setPayModal] = useState(false);

  const { loading: payLoading, reg_transaction } = useSelector(
    (state) => state.payment
  );

  const Bal = () => {
    setBalance(true);
    setTransfers(false);
    setFundz(false);
    setTransfer(false);
    setUssd(false);
    setDebitz(false);
    setOPenButton(false);
    setOpenBack(false);
  };

  const handleTransfers = () => {
    setTransfers(true);
    setTransfer(false);
    setFundz(false);
    setUssd(false);
    setDebitz(false);
    setBalance(false);
    setOPenButton(false);
    setOpenBack(false);
  };

  const UnBal = () => {
    setBalance(false);
    setOPenButton(false);
  };

  const Back = () => {
    setOpenBack(true);
    setFundz(false);
    setTransfer(false);
    setTransfers(false);
    setUssd(false);
    setDebitz(false);
    setBalance(false);
    setOPenButton(false);
  };
  const openFundz = () => {
    setFundz(true);
    setTransfer(false);
    setTransfers(false);
    setUssd(false);
    setDebitz(false);
    setBalance(false);
    setOPenButton(false);
  };
  const closeFund = () => {
    setFundz(false);
    setOpenBack(false);
  };
  const handleFund = () => {
    Back();
    openFundz();
  };
  const handleTransfer = () => {
    setTransfer(true);
    setFundz(false);
    setOPenButton(false);
  };
  const handleTransferClose = () => {
    setTransfers(false);
    setOPenButton(false);
    setOpenBack(false);
  };
  const handleUssd = () => {
    setUssd(true);
    setFundz(false);
    setDebitz(false);
  };
  const handleDebit = () => {
    setUssd(false);
    setFundz(false);
    setDebitz(true);
  };
  const openChange = () => {
    setChange(true);
    setXchange(false);
    setDchange(false);
    setFundMethod("Transfer");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWithdrawForm({
      ...withdrawForm,
      [name]: value,
    });
  };

  const closePayWithDebitCard = () => {
    dispatch(clearReferences());
    setPayModal(false);
  };

  const fundWalletDebit = () => {
    dispatch(
      registerTransaction({
        amount: sendAmount,
        purposeOfPayment: "WALLET_FUNDING",
      })
    );
  };

  useEffect(() => {
    if (reg_transaction) {
      setPayModal(true);
    }
  }, [reg_transaction]);

  const opChange = () => {
    setXchange(true);
    setChange(false);
    setDchange(false);
    setFundMethod("USSD");
  };

  const fundWithDebit = () => {
    setDchange(true);
    setXchange(false);
    setChange(false);
    setFundMethod("Debit");
  };

  const fullName = JSON.parse(localStorage.getItem("user"))?.fullName;
  const role = JSON.parse(localStorage.getItem("user"))?.role?.name;

  const walletAccount1 = users?.virtualAccountNo?.slice(0, 4);
  const walletAccount2 = users?.virtualAccountNo?.slice(4, 8);
  const walletAccount3 = users?.virtualAccountNo?.slice(8, 10);

  const handleFileSelect = (e, reference) => {
    e.preventDefault();
    reference.current.click();
  };

  const handleFileChange = (e) => {
    const { files } = e.target;

    // console.log(files[0]);

    const encodedFileBase64 = (file) => {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setWithdrawMandate(reader.result.split("base64,")[1]);
        };
        console.log(reader);
        reader.onerror = (error) => {
          console.log("error", error);
        };
      }
    };

    if (
      files[0]?.size <= 2000000 &&
      (files[0]?.type === "image/jpeg" ||
        files[0]?.type === "application/pdf" ||
        files[0]?.type === "image/png")
    ) {
      encodedFileBase64(files[0]);
    } else {
      toast.error(
        "File format should be .png, .jpeg or .pdf and less than 2mb"
      );
    }
    e.target.value = null;
  };

  const handleWithdrawRequest = () => {
    setOpenWithdrawRequest(true);
  };

  useEffect(() => {
    if (withdrawMsg) {
      handleWithdrawRequest();
      dispatch(getWalletBalance());
      dispatch(clearWithdrawMsgs());
    } else if (withdrawMsgError) {
      toast.error(withdrawMsgError ?? "Withdrawal request failed");
      dispatch(clearWithdrawMsgs());
    }
  }, [withdrawMsg, withdrawMsgError]);

  const handleWithdrawToBank = () => {
    const {
      bankAccountId,
      withdrawalAmount,
      withdrawalReasonId,
      withdrawalReasonOthers,
    } = withdrawForm;
    const submit = {
      bankAccountId: parseInt(bankAccountId),
      withdrawalAmount: parseFloat(withdrawalAmount),
      withdrawalReasonId:
        withdrawalReasonId === "others" ? null : Number(withdrawalReasonId),
      withdrawalReasonOthers:
        withdrawalReasonId === "others" ? withdrawalReasonOthers : null,
      withdrawalMandateLetterImage:
        role === "COMPANY"
          ? {
              encodedUpload: withdrawMandate,
              name: "WithdrawMandate",
            }
          : null,
    };
    dispatch(withdrawToBank(submit));
  };

  useEffect(() => {
    if (treasuryTransferMsg) {
      handleOpenTransfer();
      dispatch(getWalletBalance());
      dispatch(clearTransferMsgs());
    }
  }, [treasuryTransferMsg]);

  const handleSubmitTransfer = () => {
    const transferData = {
      amount: parseFloat(transferAmount),
      useraccountId: JSON.parse(localStorage.getItem("user"))?.id,
    };

    dispatch(transferToTreasury(transferData));
  };

  return (
    <>
      <TopBar />
      <ToastContainer />
      <main>
        <Section style={{ maxWidth: "100%" }}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6} sx={{ maxWidth: "100%" }}>
              <Box>
                <div
                  style={{
                    position: "relative",
                    marginTop: { xs: "20rem", lg: "1rem" },
                  }}
                >
                  <Paper
                    elevation={2}
                    style={{
                      maxWidth: "100%",
                      background: " #BDBDBD",
                      borderRadius: "20px",
                      height: "192px",
                      boxShadow: "0px 6px 18px rgba(196, 204, 221, 0.25)",
                      opacity: 0.6,
                      position: "relative",
                    }}
                    sx={{
                      margin: { xs: 0, sm: 0, lg: ".2rem" },
                      width: { xs: "100%", sm: "100%", md: 480, lg: 513 },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        position: "absolute",
                        top: "-15px",
                        left: { xs: "150px", lg: "230px" },
                      }}
                    ></Box>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "13px",
                        letterSpacing: "-1px",
                        position: "absolute",
                        padding: "1.5rem",
                        color: "#000000",
                      }}
                    >
                      Nuban Account Number
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "13px",
                        letterSpacing: "-1px",
                        position: "absolute",
                        paddingLeft: "1.5rem",
                        paddingTop: "3.5rem",
                        color: "#000000",
                      }}
                    >
                      Providus Bank
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "24px",
                        letterSpacing: "-1px",
                        position: "absolute",
                        paddingLeft: "1.5rem",
                        paddingTop: "4.5rem",
                        color: "#000000",
                      }}
                    >
                      {users?.virtualAccountNo &&
                        `${walletAccount1}-${walletAccount2}-${walletAccount3}`}
                    </Typography>

                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "13px",
                        letterSpacing: "-1px",
                        position: "absolute",
                        paddingLeft: "1.5rem",
                        paddingTop: "8rem",
                        color: "#000000",
                      }}
                    >
                      Balance
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "13px",
                        position: "absolute",
                        paddingLeft: "1.5rem",
                        paddingTop: "10rem",
                        color: "#000000",
                      }}
                    >
                      ₦ {totalBalance?.toLocaleString()}
                    </Typography>
                  </Paper>

                  <img
                    src={Ellipse}
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      right: "8%",
                    }}
                  />
                  <Paper
                    sx={{
                      position: "absolute",
                      background: "#FFFFF",
                      top: "10px",
                      borderRadius: "10px",
                      width: "31px",
                      height: "33px",
                      maxWidth: "100%",
                      left: { lg: "450px" },
                      right: { xs: "4%", sm: "4%" },
                      margin: { xs: "12px", lg: "8px" },
                    }}
                  >
                    <img
                      src={Naira}
                      alt="naira"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "7px",
                        maxWidth: "100%",
                      }}
                    />
                  </Paper>
                </div>
              </Box>
              <BlockSpacer mt={70} />
              <Box
                display={"flex"}
                style={{ justifyContent: "space-between" }}
                sx={{ marginLeft: { xs: "1rem", sm: "1rem" } }}
              >
                <Paper
                  elevation={3}
                  style={{
                    width: "58px",
                    height: "51px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(148, 148, 148, 0.25)",
                    background: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <img
                    src={Atm}
                    alt="atm"
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Bal();
                    }}
                  />
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    width: "58px",
                    height: "51px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(148, 148, 148, 0.25)",
                    background: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <img
                    src={Transfer}
                    alt="atm"
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleTransfers();
                      ButtonOpen();
                    }}
                  />
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    width: "58px",
                    height: "51px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(148, 148, 148, 0.25)",
                    background: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <Link to="/wallet-history">
                    <img
                      src={History}
                      alt="atm"
                      style={{
                        position: "absolute",
                        top: "15px",
                        left: "18px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Paper>
                <Paper
                  elevation={3}
                  style={{
                    width: "58px",
                    height: "51px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(148, 148, 148, 0.25)",
                    background: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <img
                    src={Fund}
                    alt="atm"
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleFund();
                    }}
                  />
                </Paper>
              </Box>
              <BlockSpacer mt={20} />

              <Box
                display={"flex"}
                style={{ justifyContent: "space-between" }}
                sx={{ marginLeft: { xs: "1rem", sm: "1rem" } }}
              >
                <Typography
                  style={{
                    fontWeight: "400",

                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Withdraw
                </Typography>
                <Typography
                  style={{
                    fontWeight: "400",

                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Transfer
                </Typography>
                <Typography
                  style={{
                    fontWeight: "400",

                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  History
                </Typography>
                <Typography
                  style={{
                    fontWeight: "400",

                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    letterSpacing: "-0.03em",
                  }}
                >
                  Fund Wallet
                </Typography>
              </Box>
              <BlockSpacer mt={120} />
              <Link to="/my-referrals">
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "17px",
                    fontFamily: "Montserrat",
                    lineHeight: "20px",
                    leterSpacing: "-0.03em",
                    color: "#242424",
                    cursor: "pointer",
                  }}
                  sx={{ marginLeft: { xs: "1rem", sm: "1rem" } }}
                >
                  My Referrals
                </Typography>
              </Link>
              <BlockSpacer mt={60} />
              <Link to="/referral-bonus">
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "17px",
                    fontFamily: "Montserrat",
                    lineHeight: "20px",
                    leterSpacing: "-0.03em",
                    color: "#242424",
                    cursor: "pointer",
                  }}
                  sx={{ marginLeft: { xs: "1rem", sm: "1rem" } }}
                >
                  My Referral Bonus
                </Typography>
              </Link>
              <BlockSpacer mt={60} />
              <Link to="/rosabon-earnings">
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "17px",
                    fontFamily: "Montserrat",
                    lineHeight: "20px",
                    leterSpacing: "-0.03em",
                    color: "#242424",
                    cursor: "pointer",
                  }}
                  sx={{ marginLeft: { xs: "1rem", sm: "1rem" } }}
                >
                  Rosabon Special Earnings
                </Typography>
              </Link>
            </Grid>
            {fundz && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{}}>
                <FundWallet
                  label={label}
                  fundMethod={fundMethod}
                  fundWithDebit={fundWithDebit}
                  openChange={openChange}
                  opChange={opChange}
                  xchange={xchange}
                  change={change}
                  handleTransfer={handleTransfer}
                  dChange={dChange}
                  handleUssd={handleUssd}
                  handleDebit={handleDebit}
                />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={fundz}
                onClose={() => closeFund()}
                variant={"temporary"}
              >
                <FundWallet
                  label={label}
                  fundMethod={fundMethod}
                  fundWithDebit={fundWithDebit}
                  openChange={openChange}
                  opChange={opChange}
                  xchange={xchange}
                  change={change}
                  handleTransfer={handleTransfer}
                  dChange={dChange}
                  handleUssd={handleUssd}
                  handleDebit={handleDebit}
                  close={closeFund}
                />
              </Drawer>
            )}
            {transfer && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{}}>
                <TransferFund
                  fullName={fullName}
                  bankFund={bankFund}
                  close={openFundz}
                />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={transfer}
                onClose={() => openFundz()}
                variant={"temporary"}
              >
                <TransferFund
                  fullName={fullName}
                  bankFund={bankFund}
                  close={openFundz}
                />
              </Drawer>
            )}

            {/* Start Ussd  */}

            {ussd && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{}}>
                <UssdFund close={openFundz} />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={ussd}
                onClose={() => openFundz()}
                variant={"temporary"}
              >
                <UssdFund close={openFundz} />
              </Drawer>
            )}
            {/* Pay with Debit Card */}
            {debitz && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <DebitCardFund
                  sendAmount={sendAmount}
                  setSendAmount={setSendAmount}
                  fundWalletDebit={fundWalletDebit}
                  close={openFundz}
                />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={debitz}
                onClose={() => openFundz()}
                variant={"temporary"}
              >
                <DebitCardFund
                  sendAmount={sendAmount}
                  setSendAmount={setSendAmount}
                  fundWalletDebit={fundWalletDebit}
                  close={openFundz}
                />
              </Drawer>
            )}
            {}
            {/* Available Balance */}
            {balance && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <WithdrawToBank
                  withdrawForm={withdrawForm}
                  walletBalance={walletBalance}
                  role={role}
                  bankDetailsError={bankDetailsError}
                  handleChange={handleChange}
                  bankDetails={bankDetails}
                  frontFileInputRef={frontFileInputRef}
                  handleFileChange={handleFileChange}
                  handleFileSelect={handleFileSelect}
                  withdrawOptions={withdrawOptions}
                  submit={handleWithdrawToBank}
                />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={balance}
                onClose={() => UnBal()}
                variant={"temporary"}
              >
                <WithdrawToBank
                  withdrawForm={withdrawForm}
                  walletBalance={walletBalance}
                  role={role}
                  bankDetailsError={bankDetailsError}
                  handleChange={handleChange}
                  bankDetails={bankDetails}
                  frontFileInputRef={frontFileInputRef}
                  handleFileChange={handleFileChange}
                  handleFileSelect={handleFileSelect}
                  withdrawOptions={withdrawOptions}
                  close={UnBal}
                  submit={handleWithdrawToBank}
                />
              </Drawer>
            )}
            {tranfers && width > 700 ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TransferToTreasury
                  transferAmount={transferAmount}
                  walletBalance={walletBalance}
                  setTransferAmount={setTransferAmount}
                  submit={() => handleSubmitTransfer()}
                />
              </Grid>
            ) : (
              <Drawer
                anchor={"top"}
                open={tranfers}
                onClose={() => handleTransferClose()}
                variant={"temporary"}
              >
                <TransferToTreasury
                  transferAmount={transferAmount}
                  walletBalance={walletBalance}
                  setTransferAmount={setTransferAmount}
                  close={() => handleTransferClose()}
                  submit={() => handleSubmitTransfer()}
                />
              </Drawer>
            )}
          </Grid>
        </Section>
        <BlockSpacer mt={150} />

        {openBack && (
          <Paper
            elevation={0.5}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "98%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "8px 8px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Button
              sx={{
                background: "#111E6C",
                width: "328px",
                height: "54px",
                color: "white",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              onClick={() => {
                closeFund();
              }}
            >
              Back
            </Button>
          </Paper>
        )}
        {/* Available Balance Button */}
        {balance && (
          <Paper
            elevation={0}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "98%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "8px 8px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Button
                sx={{
                  background: "#111E6C",
                  width: "328px",
                  height: "54px",
                  color: "white",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
                onClick={() => {
                  UnBal();
                }}
              >
                Cancel
              </Button>

              {/* <Button
                sx={{
                  background: "#111E6C",
                  borderRadius: "10px",

                  width: "329.37px",
                  height: "54px",

                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "#BDBDBD",
                    color: "#111E6C",
                  },
                }}
                onClick={() => {
                  handleWithdrawToBank();
                }}
              >
                Submit
              </Button> */}
            </Box>
          </Paper>
        )}
        {openButton && (
          <Paper
            elevation={0}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "98%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "8px 8px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Button
                sx={{
                  background: "#111E6C",
                  width: "328px",
                  height: "54px",
                  color: "white",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
                onClick={() => {
                  handleTransferClose();
                }}
              >
                Cancel
              </Button>

              {/* <Button
                sx={{
                  background: "#111E6C",
                  borderRadius: "10px",

                  width: "329.37px",
                  height: "54px",

                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "#BDBDBD",
                    color: "#111E6C",
                  },
                }}
                onClick={() => {
                  handleSubmitTransfer();
                }}
              >
                Submit
              </Button> */}
            </Box>
          </Paper>
        )}
        {openWithdrawRequest && (
          <WithdrawalRequest
            open={openWithdrawRequest}
            handleClose={handleWithdrawRequestClose}
          />
        )}
        {openTransfer && (
          <TransferSuccess
            open={openTransfer}
            handleClose={handleOpenTransferClose}
          />
        )}
        {payModal && (
          <ApproveDebitFund
            amount={sendAmount}
            open={payModal}
            handleClose={() => closePayWithDebitCard()}
          />
        )}
      </main>
    </>
  );
}

export default Wallet;
