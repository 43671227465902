import React from 'react';
import { Box, Typography } from '@mui/material';
import { TitleStyle, Container, imageRezes } from './style.js'



function GetLoanCard({ title, image, alt }) {
    return (
        <Box sx={Container}>
            <img src={image} alt={alt} style={imageRezes} />
            <Typography sx={TitleStyle}>{title}</Typography>
        </Box>
    )
}
export default GetLoanCard
