import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Section, Ticket } from "./style";

import { getMyTicketServices } from "../../services/feedbackServices";
import { Circle } from "@mui/icons-material";

import SelectField from "../../components/Core/SelectField/index.jsx";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import BlockSpacer from "../../components/BlockSpacer";
import OfferSuccessModal from "../../components/Modals/FeedbackModal/index.jsx";
import TopBar from "../../components/FeedBackTopBar";
import useScreenSize from "../../components/ScreenSize";
import Paginate from "../../components/Paginate";

function MyTickets() {
  const [loading, setLoading] = useState(true);

  const [error, setError] = React.useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [chunkse, setChunkse] = React.useState([]);

  const [datas, setDatas] = React.useState(" ");

  const { width } = useScreenSize();

  const router = useNavigate();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.grey,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const nextPage = () => {
    setCurrentPage(currentPage+1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage-1);
  }

  const getMyTickets = async () => {
    setLoading(true);

    try {
      const response = await getMyTicketServices();

      if (response) {
        console.log("This is the my tickets response", response.data);

        const data = response.data.map((dat) => {
          return dat.title;
        });

        setDatas(data);

        const chunks = response?.data?.map((chunk) => {
          return createData(
            chunk.id,
            chunk.title,
            chunk.status,
            chunk.createdAt,
            chunk.modifiedAt
          );
        });

        setChunkse(chunks);
        setLoading(false);
      }
    } catch (error) {
      console.log("This is the error message", error.message);
      setError(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyTickets();
  }, []);

  function createData(ticketid, topic, status, dateLogged, lastUpdated) {
    return { ticketid, topic, status, dateLogged, lastUpdated };
  }

  const totalTransactions = Math.ceil((chunkse?.length)/10);
  const currentTransactions = currentPage === totalTransactions ? 
  chunkse?.slice(((currentPage-1)*10),chunkse?.length) :
  chunkse?.slice(((currentPage-1)*10), (currentPage*10))

  return (
    <>
      <TopBar />
      <>
        <main>
          <Section>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "21px",
              }}
            >
              My Tickets
            </Typography>
            <BlockSpacer mt={width>700&&70} />

            {loading && (
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "23px",
                  letterSpacing: "-0.03em",
                }}
              >
                {" "}
                Loading ...
              </div>
            )}

            {error ? (
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "23px",
                  color: "red",
                  letterSpacing: "-0.03em",
                }}
              >
                {" "}
                Error : {error + ":("}
              </div>
            ) : null}

            {!loading && datas.length > 0 ? (
              <div>
                <BlockSpacer mt={30} />

                <Box
                  sx={{display:{xs:"none",sm:"none",md:"flex",lg:"flex"}}}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={Ticket}>
                            Tickets#
                          </StyledTableCell>
                          <StyledTableCell sx={Ticket}>
                            Topic
                          </StyledTableCell>
                          <StyledTableCell sx={Ticket}>
                            Request Status
                          </StyledTableCell>
                          <StyledTableCell sx={Ticket}>
                            Date Logged{" "}
                          </StyledTableCell>
                          <StyledTableCell sx={Ticket}>
                            Last Updated
                          </StyledTableCell>
                          <StyledTableCell
                            sx={Ticket}
                          ></StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {currentTransactions?.map((chun, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              sx={Ticket}
                            >
                              {chun?.ticketid}
                            </StyledTableCell>
                            <StyledTableCell sx={Ticket}>
                              {chun?.topic}
                            </StyledTableCell>
                            <StyledTableCell sx={Ticket}>
                              <Button
                                sx={{
                                  background: "#F2F2F2",
                                  height: "29px",
                                  width: "118px",
                                  borderRadius: "10px",
                                  color: "#242424",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                }}
                                onClick={() =>
                                  router("/feedback/my-tickets/edit-tickets")
                                }
                              >
                                {chun?.status}{" "}
                              </Button>
                            </StyledTableCell>
                            <StyledTableCell sx={Ticket}>
                              {chun?.dateLogged}
                            </StyledTableCell>
                            <StyledTableCell sx={Ticket}>
                              {chun?.lastUpdated}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Box key={chun?.ticketid}>
                                <Link
                                  to={`/feedback/my-tickets/${chun?.ticketid}`}
                                >
                                  <Button
                                    sx={{
                                      background: "#111E6C",
                                      height: "39px",
                                      width: "109px",
                                      borderRadius: "5px",
                                      color: "#FFFFFF",
                                      fontFamily: "Montserrat",
                                      fontWeight: "400",
                                      textTransform: "capitalize",
                                      "&:hover": {
                                        color: "#242424",
                                        background: "#F2F2F2",
                                      },
                                    }}
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {currentTransactions?.map((chun, index) => (
                  <Box
                    key={index}
                    sx={{
                      display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 4,
                      marginBottom: 4
                    }}
                  >
                    <Box>
                      <Typography className="ticketTopic" >{chun?.topic}</Typography>
                      <div>
                        <Typography className="ticketDate" >{chun?.lastUpdated}</Typography>
                      </div>
                      <div style={{display:"flex", gap: "10px", alignItems: "center"}} >
                        <Typography className="ticketId" >#{chun?.ticketid}</Typography>
                        <span><Circle style={{width:"3px",height:"12px"}} /></span>
                        <Typography className="ticketStatus" >{chun?.status}</Typography>
                      </div>
                    </Box>
                    <Box>
                      <Box key={chun?.ticketid}>
                        <Link
                          to={`/feedback/my-tickets/${chun?.ticketid}`}
                        >
                          <Button
                            sx={{
                              background: "#111E6C",
                              width: "64px",
                              borderRadius: "5px",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                              textTransform: "capitalize",
                            }}
                          >
                            View
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <BlockSpacer mt={width>700&&120} />
              </div>
            ) : !loading && (
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "17px",
                  lineHeight: "21px",
                }}
              >
                No Tickets Available
              </Typography>
            )
          }
          </Section>
          <Paginate 
            current={currentPage}
            nextPage={nextPage}
            prevPage={prevPage}
            total={totalTransactions}
          />
          <BlockSpacer mt={50} />
        </main>
      </>
    </>
  );
}

export default MyTickets;
