import React from 'react'
import CompanySidebar from '../ProfileSidebars/CompanySidebar'
import { Section } from './style'

function ProfileLayout({ children }) {
    return (
        <Section>
            <div className="left-side">
                <CompanySidebar />
            </div>
            <div className="right-side">
                {children}
            </div>
        </Section>
    )
}

export default ProfileLayout
