import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetLoanProductsQuery = () => {
  const getLoanProducts = async () => {
    const request = await axiosInstance.get(`/users/access-loan-product`);
    const response = request.data;
    return response;
  };
  const query = useQuery(["LOAN_PRODUCTS"], getLoanProducts);
  return query;
};

export default useGetLoanProductsQuery;
