import styled from "@emotion/styled";

export const Section = styled.section`
 display: flex;
  .left-side {
   width: 30%;
  }

 .right-side {
    //  display: flex;
     width: 100%;
    // justify-content: center;
    //  align-items: center;
    //  flex-direction: column;
  }
 }

 @media (max-width: 1215px) {
    .left-side {
      display: none;
    }

    display: block;
}

 
  
`;
