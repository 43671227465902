import axios from "axios";
import * as React from "react";
import "./App.css";
import PageRoutes from "./pageRoutes";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactQueryDevtools } from "react-query-devtools";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { BusinessOutlined } from "@mui/icons-material";
import {
  getUsers,
  getCompanyDocs,
  getUserDocs,
} from "./redux/slices/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "./redux/slices/notificationSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [countryId, setCountryId] = React.useState(null);

  const queryClient = new QueryClient();

  React.useEffect(() => {
    dispatch(getUsers());
    dispatch(getCompanyDocs());
    dispatch(getUserDocs());
    dispatch(getNotifications());
    window.scrollTo(0, 0);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { isLoggedIn, isAuth } = useSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(getUsers());
  }, [isLoggedIn, isAuth]);

  // React.useEffect(()=> {
  //   const data = localStorage.getItem("token");
  //   if(data) {

  //   }
  // }, [])

  // const getCountries = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://api-credit.optisoft.com.ng:8090/country",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // setDefaultCountryMethod(res.data);
  //     setCountryId(res.data[0].id);
  //     localStorage.setItem("countries", JSON.stringify(res.data));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   getCountries();
  // }, []);

  // const getStates = async (countryId) => {
  //   try {
  //     const res = await axios.get(
  //       `https://api-credit.optisoft.com.ng:8090/state/${countryId}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log(res.data, "res.data");
  //     // setLocalState(res.data);
  //     localStorage.setItem("states", JSON.stringify(res.data));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   getStates(countryId);
  // }, [countryId]);

  return (
    <QueryClientProvider client={queryClient}>
      <PageRoutes />
      {/* <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"}/> */}
    </QueryClientProvider>
  );
}

export default App;
