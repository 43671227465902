import React from "react";
import BlockSpacer from "../../BlockSpacer";
import PersonalDetailForm from "../../Forms/PersonalDetailForm";
import style from "./style.module.css";

function PersonalDetails({
  defaultUserMethod,
  setDefaultUserMethod,
  setKycInfo,
  setBvn,
  verifyBvn,
  verifedLoading,
  error,
  itemOptions,
  usersDetails,
  info,
  verified,
  setVerified,
  bvnVerified
}) {
  return (
    <div className={style.container}>
      <div className={style.title}>Personal Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <PersonalDetailForm
          setKycInfo={setKycInfo}
          setBvn={setBvn}
          verifyBvn={verifyBvn}
          verified={verified}
          setVerified={setVerified}
          defaultUserMethod={defaultUserMethod}
          setDefaultUserMethod={setDefaultUserMethod}
          verifedLoading={verifedLoading}
          error={error}
          bvnVerified={bvnVerified}
          itemOptions={itemOptions}
          usersDetails={usersDetails}
          info={info}
        />
      </div>
    </div>
  );
}

export default PersonalDetails;
