import React, { useEffect, useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWalletTransactions } from "../../../redux/slices/walletSlice";

import { Section, headingText } from "./style";

import BlockSpacer from "../../../components/BlockSpacer";
import Paginate from "../../../components/Paginate";

import {
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  TableContainer,
  TableHead,
  styled,
  Box,
  Button
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

import PartWithdrawal from "../../../components/Modals/PartWithdrawal";
import TopBar from "../../../components/HistoryTopBar";
import {NorthEast, SouthWest, ArrowBackIosNew} from '@mui/icons-material';
import useScreenSize from "../../../components/ScreenSize";

function History() {
  const date = {
    startDate: "",
    endDate: "",
  };
  const [formData, setFormData] = useState(date);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [itemRow, setItemRow] = useState(null);
  const dispatch = useDispatch();
  const [openPartWithdrawal, setPartWithdrawal] = useState(false);
  const handlePartWithdrawal = (row) => {
    setPartWithdrawal(true);
    setItemRow(row);
  };
  const { width } = useScreenSize();
  const navigate = useNavigate();

  const nextPage = () => {
    setCurrentPage(currentPage+1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage-1);
  }

  const handlePartWithdrawalClose = () => {
    setPartWithdrawal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getWalletTransactions());
  }, [dispatch]);

  const { walletTransactions, transaction } = useSelector(
    (state) => state.wallet
  );


  useEffect(() => {
    const filteredWalletTrans = (formData.startDate==="" || 
    formData.endDate === "") ? walletTransactions?.entities : walletTransactions?.entities?.filter((item) => {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      const itemDate = moment(item.transactionDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );

      console.log(itemDate);
      const date = new Date(itemDate);

      return date >= startDate && date <= endDate;
    });
    setFilteredTransactions(filteredWalletTrans);
  }, [formData, walletTransactions]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.grey,
      color: theme.palette.common.black,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const totalTransactions = Math.ceil((filteredTransactions?.length)/10);
  const currentTransactions = currentPage === totalTransactions ? 
  filteredTransactions?.slice(((currentPage-1)*10),filteredTransactions?.length) :
  filteredTransactions?.slice(((currentPage-1)*10), (currentPage*10))

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box 
            sx={{
              mb:2,
              mt:1,
              display:{md:"none",lg:"none",xs:"flex",sm:"flex"},
              alignItems: "center",
              gap: 2
          }} 
          >
            <Box>
              <Link to="/wallet" >
                <ArrowBackIosNew />
              </Link>
            </Box>
            <Box>
              <Typography
                sx={headingText}
                style={{whiteSpace:"nowrap"}}
              >
                History
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            sx={{ 
              justifyContent: "space-between", 
              maxWidth: "100%",
              flexDirection: {xs:"column",sm:"column", md:"row", lg: "row"},
              alignItems: {lg:"center"},
              gap: {xs:6,sm:3}
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "17px",
                  lineHeight: "20px",
                  lettSpacing: "-0.03em",
                  color: "#242424",
                  paddingTop: ".5rem",
                }}
              >
                Filter by Transaction Date
              </Typography>
            </Box>
            <Box display={"flex"} >
              <Box sx={{ 
                display: "flex", 
                gap:{xs:2,sm:2, lg: 8},
                justifyContent:{xs:"space-between",sm:"space-between"} 
              }}
              >
                <Box sx={{ marginLeft: {lg:"-2rem"} }}>
                  
                  <div 
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <label style={{fontsize:width<=700&&"14px"}} >Start Date</label>
                    <input
                      className="form-control"
                      placeholder="Start date"
                      style={{
                        width: width>700?"206px":"108px",
                        height: "54px",
                        padding: width>700?"12px 50px":"12px",
                        margin: "8px 0",

                        border: "0px solid #ccc",
                        borderRadius: "5px",
                        boxSizing: "borderBox",
                        background: "#FBF8F8",
                      }}
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                    />
                  </div>
                </Box>
                <Box sx={{ marginLeft: {lg:"-2rem"} }}>
                  <div 
                    className="mb-4 "
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <label style={{fontsize:width<=700&&"14px"}} >End Date</label>
                    <input
                      className="form-control"
                      placeholder="Start date"
                      style={{
                        width: width>700?"206px":"108px",
                        height: "54px",
                        padding: width>700?"12px 50px":"12px",
                        margin: "8px 0",

                        border: "0px solid #ccc",
                        borderRadius: "5px",
                        boxSizing: "borderBox",
                        background: "#FBF8F8",
                      }}
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                    />
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <BlockSpacer mt={width>700&&80} />
          <TableContainer 
            component={Paper} 
            sx={{maxWidth:980,display:{xs:"none",sm:"none",lg:"block", md:"block"}}}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >ID</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >Date</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >Description</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >Type</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >Amount</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      lineHeight: "19.5px",
                      lettSpacing: "-0.03em",
                      color: "#000000",
                    }}
                  >Balance</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {currentTransactions?.map((row) => (
                  <StyledTableRow
                    key={row.transactionId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                        cursor: "pointer",
                      }}
                      onClick={()=>handlePartWithdrawal(row)}
                    >
                      {row.transactionId}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                      }}
                    >{row?.transactionDate?.split(" ")[0]}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                      }}
                    >{row?.transactionCategory?.replace(/_/g," ")}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                      }}
                    >{row?.transactionType}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                      }}
                    >
                      {
                        row?.transactionType === "CREDIT"
                        ? "+ ₦"+ row?.debit.toLocaleString()
                        : "- ₦" + row?.debit.toLocaleString()
                      }
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        lineHeight: "19.5px",
                        lettSpacing: "-0.03em",
                        color: "#000000",
                      }}
                    >₦{row?.balanceAfterTransaction.toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {
            currentTransactions?.map((row) => (
              <Box 
                key={row.transactionId} 
                sx={{
                  display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                  py:2,
                  justifyContent:"space-between",
                  alignItems: "center",
                  gap:2
                }} 
              >
                <Box style={{width:"120px"}} >
                  <Typography className="text1" >{row?.transactionDate?.split(" ")[0]}</Typography>
                  <Typography className="text2">{row.transactionId}</Typography>
                  <div>
                  <Typography className="text3" >
                    {row?.transactionCategory?.replace(/_/g," ")}
                  </Typography>
                  </div>
                  
                </Box>
                <Box>
                  {
                    row?.transactionType === "CREDIT"
                    ? (
                        <Box
                        sx={{
                          background: "rgba(111, 207, 151, 0.1)",
                          borderRadius: "10px",
                          width: "32px",
                          height: "32px",
                          display:"flex",
                          justifyContent:"center",
                          alignItems:"center"
                        }}
                      >
                        <NorthEast style={{color:"#27AE60"}} />
                      </Box>
                    )
                    : (
                      <Box
                          sx={{
                            background: "rgba(202, 14, 14, 0.1)",
                            borderRadius: "10px",
                            width: "32px",
                            height: "32px",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                          }}
                        >
                          <SouthWest style={{color:"#EB5757"}} />
                        </Box>
                    )
                  }
                </Box>
                <Box>
                  <Typography className="text4">
                    {
                      row?.transactionType === "CREDIT"
                      ? "+ ₦"+ row?.debit.toLocaleString()
                      : "- ₦" + row?.debit.toLocaleString()
                      }
                  </Typography>
                  <Typography className="text3">
                    ₦{row?.balanceAfterTransaction.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            ))
          }
        </Section>
        {openPartWithdrawal && (
          <PartWithdrawal
            open={openPartWithdrawal}
            handleClose={handlePartWithdrawalClose}
            item={itemRow}
          />
        )}
        <BlockSpacer mt={80} />
        <Paginate 
          current={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          total={totalTransactions}
        />
        <BlockSpacer mt={50} />
        <Button
          sx={{
            background: "#111E6C",
            width: "328px",
            height: "54px",
            color: "#FFFFFF",
            marginBottom: "1rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            textTransform: "capitalize",
            fontSize: "18px",
            display: {xs:"none",md:"block"},
            "&:hover": {
              backgroundColor: "#BDBDBD",
              color: "#111E6C",
            },
          }}
          onClick={() => {
            navigate("/wallet");
          }}
        >
          Back
        </Button>
        <BlockSpacer mt={20} />
      </main>
    </>
  );
}

export default History;
