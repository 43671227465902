import React, { useState } from "react";
import { Typography, Grid, Paper, Button, Menu, MenuItem } from "@mui/material";
import {
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  signOffer,
} from "./style";
import { Box } from "@mui/system";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import { Link, useNavigate } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
import TopBar from "../../../components/MyCreditTopBar";

import { Section } from "./style";
import { MoreHoriz } from "@mui/icons-material";
import useGetPendingTransactionsQuery from "../../../api/services/transactions/get-pending-transactions.api.js";

function PendingTransaction() {
  const navigate = useNavigate();

  const [selectedLoan, setSelectedLoan] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoan(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedLoan(null);
  };

  const { data } = useGetPendingTransactionsQuery();
  const pendingTransactions = data?.data?.content;
  //
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {selectedLoan?.loanProcessingProgress === "SUBMITTED" && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(`/pending-upload-loan-documents/${selectedLoan?.id}`);
            }}
          >
            Upload Documents
          </MenuItem>
        )}
      </Menu>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: 21,
              fontFamily: "Montserrat",
              mt: { xs: "-2", lg: "-2" },
            }}
          >
            Pending Transactions
          </Box>
          <Grid container sx={{ overflow: "hidden" }}>
            {pendingTransactions?.map((loan) => (
              <Grid item lg={6} xs={12} sm={12} key={loan?.id}>
                <Paper
                  elevation={0}
                  sx={{
                    mt: 4,
                    width: { xs: "100%", lg: "90%" },
                    maxWidth: "100%",
                    height: { xs: "fit-content", lg: "213px" },
                    position: "relative",
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography sx={QuickNanoLoan}>
                      {loan?.product?.productName}
                    </Typography>
                    <Box
                      sx={{
                        pt: { xs: ".5rem", md: "1rem" },
                        pr: { xs: ".5rem", md: "1rem" },
                      }}
                    >
                      <Typography sx={signOffer}>
                        {loan?.loanProcessingProgress}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={callToActionCard}>
                    <Box>
                      <NanoImage />
                    </Box>
                    <div>
                      <Typography key={"id"} sx={paragraphTitle}>
                        {loan?.product.productSummary}
                      </Typography>
                      <Link to={`/processing-stage/${loan?.id}`}>
                        <Button
                          sx={{
                            background: "#F2F2F2",
                            marginTop: "1rem",
                            width: { xs: "150px", lg: "186px" },
                            borderRadius: "10px",
                            height: "42px",
                            color: "#111E6C",
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: { xs: "10px", lg: "15px" },
                          }}
                        >
                          Withdrawal Request
                        </Button>
                      </Link>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Section>
        <BlockSpacer mt={320} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#111E6C",
                width: "328px",
                height: "54px",
                color: "white",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              onClick={() => navigate("/my-credit")}
            >
              Back
            </Button>
          </Box>
        </Paper>
      </main>
    </>
  );
}

export default PendingTransaction;

// {/* <Grid container sx={{ fixed, overflow: { xs: "hidden" } }}>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   borderRadius: "8px",
//                   border: "1px #fff solid",
//                   boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
//                   overflow: "hidden",
//                   position: "relative",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={processingStage}>Processing(Stage)</Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/processing-stage">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           fontSize: { xs: "10px", lg: "15px" },
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                         }}
//                       >
//                         Withdrawal Request
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   position: "relative",
//                   boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.23)",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={documentsUpload}>
//                   Documents Upload Required
//                 </Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/documents-upload">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                           fontSize: { xs: "10px", lg: "15px" },
//                         }}
//                       >
//                         Withdrawal Request
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   position: "relative",
//                   boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.23)",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={signOffer}>Sign Offer</Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/sign-offer">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                           fontSize: { xs: "10px", lg: "15px" },
//                         }}
//                       >
//                         Withdrawal Request
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//           </Grid> */}
