import styled from "@emotion/styled";

export const Section = styled.section`
 margin-top: 8rem;
 height: 100%;

 .platformToggle {
   width: 443px;
   height: 56px;
   border: 1px solid #E0E0E0;
   border-radius: 20px;
   padding: .4rem;
   display: flex;
   margin: 1.2rem 0;
 }

 .toggle_btn {
   width: 100%;
   border: none;
   background-color: transparent;
   height: 100%;
   border-radius: 20px;
 }

 .platformHeader {
  margin-bottom: 1rem;
  color: #0c216e;
  font-size: 1.4rem;
 }

 .btn_active {
   background-color: #0c216e !important;
   color: #ffffff !important;
 }

 .switchPlatformContain {
    display: flex;
    margin: 1.2rem 0;
    gap: .5rem;
    align-items: center;
 }

 .switchPlatformContain button {
    background-color: #0c216e !important;
    color: #ffffff !important;
    padding: .5rem 1.2rem;
    border: none;
    border-radius: 4px;
 }

 @media(max-width:500px) {
    margin-top: 7rem;

   .toggle_container {
      padding: .4rem;
   }

    .platformToggle {
      width: 100%;
      height: 42px;
      padding: .2rem;
    }
 }

`