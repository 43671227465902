import styled from "@emotion/styled";

export const Section = styled.section`
   margin-top: 10%;

   @media(max-width: 1244px){
    margin-top: 25%;
   }
`

export const textheading = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: '#222222'
}

export const container = {
    margin: {xs:'1rem', sm:'2rem',lg:'2rem'}
}

export const smallText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '-0.01em',
    color: '#828282'
}

export const headingText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineheight: '24px',
    textAlign: 'right',
    textTransform: 'capitalize',
    mt: {xs: 0, lg: 5},
}