import React, { useState, useEffect } from "react";
import Copy from "../../../components/Assets/icons/copy.svg";
import NoteModal from "../../../components/Modals/PokeUser/index";
import PokeModal from "../../../components/Modals/Poker/index";
import style from "../style.module.css";
import { Divider } from "@mui/material";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  styled,
  tableCellClasses
} from "@mui/material";
import { Box } from "@mui/system";
import { Section } from "./style";
import BlockSpacer from "../../../components/BlockSpacer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  getMyReferralActivities, 
  getReferralRedeemThreshold,
  redeemReferralBonus
} from "../../../redux/slices/walletSlice";
import TopBar from "../../../components/WalletTopBar";
import Paginate from "../../../components/Paginate";
import useScreenSize from "../../../components/ScreenSize";
import { ArrowBackIosNew } from '@mui/icons-material';
import { Link } from "react-router-dom";

function ReferralBonus() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { users } = useSelector((state) => state.profile);
  const { loading, refActivities, refRedeemMsg, referralThreshold } =
    useSelector((state) => state.wallet);

  console.log("kkdw", refActivities);

  const handleRedeemClick = () => {
    dispatch(redeemReferralBonus());
  };

  const nextPage = () => {
    setCurrentPage(currentPage+1);
  }

  const prevPage = () => {
    setCurrentPage(currentPage-1);
  }

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyReferralActivities());
    dispatch(getReferralRedeemThreshold());
  },[dispatch])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.grey,
      color: theme.palette.common.black,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const totalTransactions = Math.ceil((refActivities?.entities?.length)/10);
  const currentTransactions = currentPage === totalTransactions ? 
  refActivities?.entities?.slice(((currentPage-1)*10),refActivities?.entities?.length) :
  refActivities?.entities?.slice(((currentPage-1)*10), (currentPage*10));
  const { width } = useScreenSize();
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              mb:2,
              mt:1,
              display:"flex",
              alignItems: "center",
              gap: 2,
              paddingLeft: {xs:"1rem",sm:"1rem",md:0,lg:0}
            }} 
          >
            <Box sx={{display:{md:"none",lg:"none",xs:"flex",sm:"flex"}}} >
              <Link to="/wallet" >
                <ArrowBackIosNew />
              </Link>
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "20px",
                lettSpacing: "-0.03em",
              }}
            >
              My Referral Bonus
            </Typography>
          </Box>
          <BlockSpacer mt={30} />

          <Box
            display={"flex"}
            sx={{ 
              justifyContent: {sx:"center",sm:"center",md:"space-between",lg:"space-between"}, 
              maxWidth: {xs:"100%",sm:"100%",md:"76%",lg:"76%"},
              flexDirection: {xs:"column-reverse",sm:"column-reverse",md:"row"},
              gap:"19px",
              paddingLeft: {xs: "1rem",sm:"1rem"}
            }}
          >
            <Paper
              elevation={0.5}
              sx={{
                width: {xs:"335px",sm:"335px",md:"467px",lg:"467px"},
                height: "156px",
                boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                borderRadius: "8px",
                background: "#F2F2F2",
                position: "relative",
                paddingRight: {xs:"1rem", sm:"1rem"}
              }}
            >
              <Box
                display={"flex"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxWidth: "447px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    lineHeight: "17.07px",
                    letterSpacing: "-0.01em",
                    padding: {xs:"1rem",sm:"335px",md:"1.5rem",lg:"1.5rem"},
                  }}
                >
                  Earned Referral Bonus
                </Typography>
                <button 
                  onClick={handleRedeemClick} 
                  className={style.buttin}
                >
                  Redeem
                </button>
              </Box>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "17px",
                  fontFamily: "Montserrat",
                  lineHeight: "21px",
                  letterSpacing: "-0.04em",
                  padding: "1.5rem",
                  position: "absolute",
                  top: "1.5rem",
                }}
              >
                ₦{" "}
                {users?.referralBonus?.earnedReferralBonus
                  ? users?.referralBonus?.earnedReferralBonus?.toFixed(2)
                  : "0.00"}
              </Typography>
              <Divider
                sx={{
                  width: {xs:"301px",sm:"301px",md:"418px",lg:"418px"},
                  height: "7px",
                  background: "rgba(17, 30, 108, 0.1);",
                  borderRadius: "30px",
                  position: "absolute",
                  left: "1.5rem",
                  top: "6rem",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  lineHeight: "15px",
                  letterSpacing: "-0.01em",
                  padding: "3.5rem",
                  position: "absolute",
                  left: {xs:"2rem",sm:"2rem",md:"6rem",lg:"6rem"},
                }}
              >
                {users?.referralBonus?.earnedReferralBonus
                    ? users?.referralBonus?.earnedReferralBonus
                    : "0"}{" "}
                  out of ₦
                  {referralThreshold?.amount
                    ? referralThreshold?.amount
                    : "0.00"}{" "}
                  Earned
              </Typography>
            </Paper>
            <Paper
              elevation={0.5}
              sx={{
                width: "270px",
                height: "157px",
                boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                borderRadius: "8px",
                background: "#FFFFFF",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  lineHeight: "17.07px",
                  letterSpacing: "-0.01em",
                  padding: "1.5rem",
                }}
              >
                Total Redeemed Bonus :
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "17px",
                  fontFamily: "Montserrat",
                  lineHeight: "21px",
                  letterSpacing: "-0.04em",
                  padding: "1.5rem",
                  position: "absolute",
                  top: "1.5rem",
                }}
              >
                ₦{" "}
                {users?.referralBonus?.totalRedeemedBonus
                  ? users?.referralBonus?.totalRedeemedBonus?.toFixed(2)
                  : "0.00"}
              </Typography>
            </Paper>
          </Box>
          <BlockSpacer mt={60} />
          <Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "17px",
                fontFamily: "Montserrat",
                lineHeight: "20px",
                letterSpacing: "-0.03em",
                padding: "1.5rem",
              }}
            >
              Latest Activity
            </Typography>
          </Box>
          <BlockSpacer mt={60} />
          <Box>
            <TableContainer 
              component={Paper} 
              sx={{maxWidth:980,display:{xs:"none",sm:"none",lg:"block", md:"block"}}} 
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >ID</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Date</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Description</StyledTableCell>
                    <StyledTableCell 
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                        lineHeight: "20px",
                        letterSpacing: "-0.03em",
                      }}
                    >Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {currentTransactions?.map((row) => (
                    <StyledTableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row"
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >
                        {row?.id}
                      </StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >{row?.createdAt}</StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >{row?.description}</StyledTableCell>
                      <StyledTableCell 
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >₦{row?.amount}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              currentTransactions?.map((row) => (
                <Box
                  key={row.name}
                  sx={{
                    display:{xs:"flex",sm:"flex",md:"none",lg:"none"},
                    py:2,
                    justifyContent:"space-between",
                    alignItems: "center",
                    gap:2,
                    px: 2
                  }} 
                >
                  <Box>
                    <Typography className={style.bonusDate} >{row?.createdAt} </Typography>
                    <Typography className={style.referralName} >{row?.id}</Typography>
                    <Typography className={style.bonusDetail} >{row?.description}</Typography>
                  </Box>
                  <Box>
                    <Typography className={style.bonusAmount} >₦{row?.amount}</Typography>
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Section>
        <BlockSpacer mt={width>700&&80} />
        <Paginate 
          current={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          total={totalTransactions}
        />
        <BlockSpacer mt={width>700?180:120} />
        <Button
          sx={{
            background: "#111E6C",
            width: "328px",
            height: "54px",
            color: "#FFFFFF",
            marginBottom: "1rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            textTransform: "capitalize",
            fontSize: "18px",
            display: {xs:"none",md:"block"},
            "&:hover": {
              backgroundColor: "#BDBDBD",
              color: "#111E6C",
            },
          }}
          onClick={() => {
            navigate("/wallet");
          }}
        >
          Back
        </Button>
        <BlockSpacer mt={20} />
      </main>
    </>
  );
}

export default ReferralBonus;
