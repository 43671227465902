import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAuthUsersService,
  getAuthUserService,
  sendOtpService,
  validateOtpService,
  getBanksService,
  getAllGenderService,
  getCountriesService,
  getStatesService,
  getUserDocsService,
  updateUserKycService,
  getAllSourcesService,
  getAllIdTypesService,
  getAllProfessionalOccupationService,
  getBankDetailsService,
  sendCompanyOtpService,
  getCompanyDocsService,
  getAllCompanyGroupService,
  getIndustryManagementService,
  verifyBvnService,
  getKycDetailsService,
  getCompanyInclusionService,
  getBusinessBankDetailsService,
  getErpSectorService
} from "../../services/profileServices";

const initialState = {
  loading: false,
  user: null,
  userError: null,
  users: null,
  usersError: null,
  kycData: null,
  kycDataError: null,
  modal: null,
  bvnMessage: null,
  bvnError: null,
  showBvnModal: false,
  countries: null,
  countriesError: null,
  states: null,
  statesError: null,
  lgas: null,
  lgasError: null,
  otp: null,
  otpError: null,
  showEmailOtpModal: false,
  banks: null,
  banksError: null,
  validateEmailOtp: null,
  validateOtpError: null,
  companyDocs: null,
  companyDocsError: null,
  id: 0,
  bankDetails: null,
  bankDetailsError: null,
  businessBankDetails: null,
  businessBankDetailsErr: null,
  withdrawReasons: null,
  withdrawReasonsError: null,
  documents: null,
  documentsError: null,
  sidebar: false,
  gender: null,
  genderError: null,
  sources: null,
  sourcesError: null,
  idTypes: null,
  idTypesError: null,
  professionalOccupations: null,
  professionalOccupationsError: null,
  companyGroups: null,
  companyGroupError: null,
  industryManagement: null,
  industryManagementError: null,
  companyIncl: null,
  companyInclErr: null,
  erpSectors: null,
  erpSectorsErr: null,
};

export const getUsers = createAsyncThunk('profile/getUsers', (payload,{ rejectWithValue }) => {
  return getAuthUsersService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getUser = createAsyncThunk('profile/getUser', (email, { rejectWithValue }) => {
  return getAuthUserService(email)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const sendOtp = createAsyncThunk('profile/sendOtp', (payload,{ rejectWithValue }) => {
  return sendOtpService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const validateOtp = createAsyncThunk('profile/validateOtp', (otp, { rejectWithValue }) => {
  return validateOtpService(otp)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getBanks = createAsyncThunk('profile/getBanks', (payload,{ rejectWithValue }) => {
  return getBanksService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getGenders = createAsyncThunk('profile/getGenders', (payload, { rejectWithValue }) => {
  return getAllGenderService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getCountries = createAsyncThunk('profile/getCountries', (payload, { rejectWithValue }) => {
  return getCountriesService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getStates = createAsyncThunk('profile/getStates', (id, { rejectWithValue }) => {
  return getStatesService(id)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const updateUserKyc = createAsyncThunk('profile/updateUserKyc', (payload, { rejectWithValue }) => {
  return updateUserKycService(payload)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getUserDocs = createAsyncThunk('profile/getUserDocs', (payload, { rejectWithValue }) => {
  return getUserDocsService()
  .then(response => response)
  .catch(error => rejectWithValue(error));
});

export const getAllIdTypes = createAsyncThunk('profile/getAllIdTypes', (payload, { rejectWithValue }) => {
  return getAllIdTypesService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getAllSources = createAsyncThunk('profile/getAllSources', (payload, { rejectWithValue }) => {
  return getAllSourcesService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getAllProfessionalOccupation = createAsyncThunk('profile/getAllProfessionalOccupation', 
(payload, { rejectWithValue }) => {
  return getAllProfessionalOccupationService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getAllCompanyGroup = createAsyncThunk('profile/getAllCompanyGroup',
(payload, { rejectWithValue }) => {
  return getAllCompanyGroupService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response)) 
})

export const getIndustryManagement = createAsyncThunk('profile/getIndustryManagement',
(payload, { rejectWithValue }) => {
  return getIndustryManagementService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response)) 
})

export const getBankDetails = createAsyncThunk('profile/getBankDetails', 
(payload, { rejectWithValue }) => {
  return getBankDetailsService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getBusinessBankDetails = createAsyncThunk('profile/getBusinessBankDetails', 
(payload, { rejectWithValue }) => {
  return getBusinessBankDetailsService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const sendCompanyOtp = createAsyncThunk('profile/sendCompanyOtp', (payload,{ rejectWithValue }) => {
  return sendCompanyOtpService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getCompanyDocs = createAsyncThunk('profile/getCompanyDocs', (payload,{ rejectWithValue }) => {
  return getCompanyDocsService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const verifyBvn = createAsyncThunk('profile/verifyBvn', (formData, { rejectWithValue }) => {
  return verifyBvnService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
})

export const getKycDetails = createAsyncThunk('profile/getKycDetails', (payload, { rejectWithValue }) => {
  return getKycDetailsService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getCompanyInclusion = createAsyncThunk('profile/getCompanyInclusion', 
  (payload, { rejectWithValue }) => {
  return getCompanyInclusionService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getErpSectors = createAsyncThunk('auth/getErpSectors',(payload, { rejectWithValue }) => {
  return getErpSectorService()
  .then(response=>response.data)
  .catch(error=> rejectWithValue(error.response))
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers:{
    closeOtpModal(state) {
      state.showEmailOtpModal = false
      state.otp = null
      state.validateOtpError = null
      state.validateEmailOtp = null
    }
  },
  extraReducers: builder => {
    // fetch users
    builder.addCase(getUsers.pending, state => {
      state.loading = true;
    })
    // fetch users success
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false
      state.users = action.payload
      state.usersError = null
    })
    // fetch users fail
    builder.addCase(getUsers.rejected, (state, action)=>{
      state.loading = false
      state.users = null
      state.usersError = action.error
      console.log("error here", action);
    })
    // fetch single user
    builder.addCase(getUser.pending, state => {
      state.loading = true
    })
    // fetch single user success
    builder.addCase(getUser.fulfilled, (state, action)=> {
      state.loading = false
      state.user = action.payload
      state.userError = null
    })
    // fetch single user failed
    builder.addCase(getUser.rejected, (state, action)=>{
      state.loading = false
      state.user = null
      state.user = action.error
    })
    // fetch banks
    builder.addCase(getBanks.pending, state => {
      state.loading = true
    })
    // fetch banks success
    builder.addCase(getBanks.fulfilled, (state, action) => {
      state.loading = false
      state.banks = action.payload
      state.banksError = null
    })
    // fetch banks failed
    builder.addCase(getBanks.rejected, (state, action)=>{
      state.loading = false
      state.banks = null
      state.banksError = action.error
    })
    // update user kyc
    builder.addCase(updateUserKyc.pending, state=>{
      state.loading = true
      toast.info('Saving...');
    })
    // update user kyc success
    builder.addCase(updateUserKyc.fulfilled, (state,action)=>{
      state.loading = false
      state.kycData = action.payload
      state.kycDataError = null
      toast.success("User details updated")
    })
    // update user kyc failed
    builder.addCase(updateUserKyc.rejected, (state, action)=>{
      state.loading = false
      state.kycData = null
      state.kycDataError = action.error
    })
    // get countries pend
    builder.addCase(getCountries.pending,state => {
      state.loading = true
    })
    // get countries succeed
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.loading = false
      state.countries = action.payload
      state.countriesError = null
    })
    // get countries failed
    builder.addCase(getCountries.rejected, (state, action) => {
      state.loading = false
      state.countries = null
      state.countriesError = action.error
    })
    // get states pending
    builder.addCase(getStates.pending, state => {
      state.loading = true
    })
    // get states success
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.loading = false
      state.states = action.payload
      state.statesError = null
    })
    // get states failed
    builder.addCase(getStates.rejected, (state, action) => {
      state.loading = false
      state.states = null
      state.statesError = action.error
    })
    // get genders pending
    builder.addCase(getGenders.pending, state => {
      state.loading = true
    })
    // get genders success
    builder.addCase(getGenders.fulfilled, (state, action) => {
      state.loading = false
      state.gender = action.payload
      state.genderError = null
    })
    // get genders failed
    builder.addCase(getGenders.rejected, (state, action) => {
      state.loading = false
      state.gender = null
      state.genderError = action.error
    })
    // get user documents pending
    builder.addCase(getUserDocs.pending, state => {
      state.loading = true
    })
    // get user documents success
    builder.addCase(getUserDocs.fulfilled, (state, action) => {
      state.loading = false
      state.documents = action.payload
      state.documentsError = null
    })
    // get user documents failed
    builder.addCase(getUserDocs.rejected, (state, action) => {
      state.loading = false
      state.documents = null
      state.documentsError = action.error
    })
    // send otp pending
    builder.addCase(sendOtp.pending, state => {
      state.loading = true
    })
    // get send otp success
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false
      state.otp = action.payload
      state.otpError = null
      state.showEmailOtpModal = true
    })
    // get send otp failed
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false
      state.otp = null
      state.otpError = action.error
      state.showEmailOtpModal = false
    })
    // get all id types pending
    builder.addCase(getAllIdTypes.pending, state => {
      state.loading = true
    })
    // get all id types success
    builder.addCase(getAllIdTypes.fulfilled, (state, action) => {
      state.loading = false
      state.idTypes = action.payload
      state.idTypesError = null
    })
    // get all id types failed
    builder.addCase(getAllIdTypes.rejected, (state, action) => {
      state.loading = false
      state.idTypes = null
      state.idTypesError = action.error
    })
    // validate otp pending
    builder.addCase(validateOtp.pending, state => {
      state.loading = true
    })
    // validate otp success
    builder.addCase(validateOtp.fulfilled, (state, action) => {
      state.loading = false
      state.validateEmailOtp = action.payload
      state.validateOtpError = null
    })
    // validate otp failed
    builder.addCase(validateOtp.rejected, (state, action) => {
      state.loading = false
      state.validateEmailOtp = null
      state.validateOtpError = action.payload
    })
    // get all sources pending
    builder.addCase(getAllSources.pending, state => {
      state.loading = true
    })
    // get all sources success
    builder.addCase(getAllSources.fulfilled, (state, action) => {
      state.loading = false
      state.sources = action.payload
      state.sourcesError = null
    })
    // get all sources failed
    builder.addCase(getAllSources.rejected, (state, action) => {
      state.loading = false
      state.sources = null
      state.sourcesError = action.error
    })
    // get professional occupations pending
    builder.addCase(getAllProfessionalOccupation.pending, state => {
      state.loading = true
    })
    // get professional occupations success
    builder.addCase(getAllProfessionalOccupation.fulfilled, (state, action) => {
      state.loading = false
      state.professionalOccupations = action.payload
      state.professionalOccupationsError = null
    })
    // get professional occupations failed
    builder.addCase(getAllProfessionalOccupation.rejected, (state, action) => {
      state.loading = false
      state.professionalOccupations = null
      state.professionalOccupationsError = action.error
    })
    // get bank details pending
    builder.addCase(getBankDetails.pending, state => {
      state.loading = true
    })
    // get bank details success
    builder.addCase(getBankDetails.fulfilled, (state, action) => {
      state.loading = false
      state.bankDetails = action.payload
      state.bankDetailsError = null
    })
    // get bank details failed
    builder.addCase(getBankDetails.rejected, (state, action) => {
      state.loading = false
      state.bankDetails = null
      state.bankDetailsError = action.error
    })
    // get business bank details pending
    builder.addCase(getBusinessBankDetails.pending, state => {
      state.loading = true
    })
    // get business bank details success
    builder.addCase(getBusinessBankDetails.fulfilled, (state, action) => {
      state.loading = false
      state.businessBankDetails = action.payload
      state.businessBankDetailsErr = null
    })
    // get business bank details failed
    builder.addCase(getBusinessBankDetails.rejected, (state, action) => {
      state.loading = false
      state.businessBankDetails = null
      state.businessBankDetailsErr = action.error
    })
    // send company otp pending
    builder.addCase(sendCompanyOtp.pending, state => {
      state.loading = true
    })
    // get send company otp success
    builder.addCase(sendCompanyOtp.fulfilled, (state, action) => {
      state.loading = false
      state.otp = action.payload
      state.otpError = null
      state.showEmailOtpModal = true
    })
    // get send company otp failed
    builder.addCase(sendCompanyOtp.rejected, (state, action) => {
      state.loading = false
      state.otp = null
      state.otpError = action.error
      state.showEmailOtpModal = false
    })
    // get company documents pending
    builder.addCase(getCompanyDocs.pending, state => {
      state.loading = true
    })
    // get company documents success
    builder.addCase(getCompanyDocs.fulfilled, (state, action) => {
      state.loading = false
      state.companyDocs = action.payload
      state.companyDocsError = null
    })
    // get company documents failed
    builder.addCase(getCompanyDocs.rejected, (state, action) => {
      state.loading = false
      state.companyDocs = null
      state.companyDocsError = action.error
    })
    // get company groups documents pending
    builder.addCase(getAllCompanyGroup.pending, state => {
      state.loading = true
    })
    // get company groups success
    builder.addCase(getAllCompanyGroup.fulfilled, (state, action) => {
      state.loading = false
      state.companyGroups = action.payload
      state.companyGroupError = null
    })
    // get company groups failed
    builder.addCase(getAllCompanyGroup.rejected, (state, action) => {
      state.loading = false
      state.companyGroups = null
      state.companyGroupError = action.error
    })
    // get industry managements pending
    builder.addCase(getIndustryManagement.pending, state => {
      state.loading = true
    })
    // get industry managements success
    builder.addCase(getIndustryManagement.fulfilled, (state, action) => {
      state.loading = false
      state.industryManagement = action.payload
      state.industryManagementError = null
    })
    // get industry managements failed
    builder.addCase(getIndustryManagement.rejected, (state, action) => {
      state.loading = false
      state.industryManagement = null
      state.industryManagementError = action.error
    })
    // verify bvn pending
    builder.addCase(verifyBvn.pending, state => {
      state.loading = true
    })
    // verify bvn success
    builder.addCase(verifyBvn.fulfilled, (state, action) => {
      state.loading = false
      state.bvnMessage = action.payload
      state.bvnError = null
      state.showBvnModal = true
    })
    // verify bvn failed
    builder.addCase(verifyBvn.rejected, (state, action) => {
      state.loading = false
      state.bvnMessage = null
      state.bvnError = action.error
      state.showBvnModal = false;
    })
    // get kyc details pending
    builder.addCase(getKycDetails.pending, state => {
      state.loading = true
    })
    // get kyc details success
    builder.addCase(getKycDetails.fulfilled, (state, action) => {
      state.loading = false
      state.kycData = action.payload
      state.kycDataError = null
    })
    // get kyc details failed
    builder.addCase(getKycDetails.rejected, (state, action) => {
      state.loading = false
      state.kycData = null
      state.kycDataError = action.error
    })
    // get company inclusions pending
    builder.addCase(getCompanyInclusion.pending, state => {
      state.loading = true
    })
    // get company inclusions success
    builder.addCase(getCompanyInclusion.fulfilled, (state, action) => {
      state.loading = false
      state.companyIncl = action.payload
      state.companyInclErr = null
    })
    // get company inclusions failed
    builder.addCase(getCompanyInclusion.rejected, (state, action) => {
      state.loading = false
      state.companyIncl = null
      state.companyInclErr = action.error
    })
    // get erp sectors pending
    builder.addCase(getErpSectors.pending, (state) => {
      state.loading = true
    })
    // get erp sectors success
    builder.addCase(getErpSectors.fulfilled, (state, action) => {
      state.loading = false
      state.erpSectors = action.payload
      state.erpSectorsErr = null
    })
    // get erp sectors failed
    builder.addCase(getErpSectors.rejected, (state, action) => {
      state.loading = false
      state.erpSectors = null
      state.erpSectorsErr = action.error
    })
  }
});

export default profileSlice.reducer
export const { closeOtpModal } = profileSlice.actions