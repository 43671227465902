import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  cardBox,
  summaryTitle,
  currencyStyle,
  secondCurrencyStyle,
  firstCurrencyStyle,
  currencyBlue,
  Cardstyle,
} from "./style";
import DotLine from "../../Hocs/DotLine";
import BlockSpacer from "../../BlockSpacer";

export default function SummaryCard({
  title,
  currencyRequested,
  tenor,
  dataNumber,
  ProcessingfeePayable,
  currency,
  StampDutyFeePayable,
  StampDutyFeeRate,
  StampFeeAmount,
  days,
  ManagementFeePayable,
  ManagementFeeRate,
  ManagementFeePercentage,
  ManagementFeeAmount,
  interestpayableCurrency,
  interestRatePercentage,
  totalPaymentDate,
  totalPaymentAmount,
  feePayableCurrency,
  TotalRepayment,
  totalLoanAmount,
  interestPayable,
  interestRate,
  ProcessingfeeRate,
  LoanDueDate,
  LegalFeeAmount,
  LegalFeePercentage,
  StampDutyFeePercentage,
  InsurancePayable,
  hundredThousand,
  totalLoanDepositWieght,
  totalLoanRate,
  firstMonthRepayment,
  secondMonthRepayment,
  secondMonthFee,
  dueDate,
  DueDate,
  firstMonthFee,
  LegalFeePayable,
  LegalFeeRate,
}) {
  return (
    <>
      <Paper elevation={0.5} sx={cardBox}>
        {" "}
        <Typography variant="h6" style={summaryTitle}>
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{currencyRequested}</Typography>
            <Typography sx={firstCurrencyStyle}>{currency}</Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{tenor}</Typography>
            <Typography sx={secondCurrencyStyle}>{days}</Typography>
          </Box>
        </Box>
        <DotLine />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{interestRate}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {interestRatePercentage}
            </Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{interestPayable}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {feePayableCurrency}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{ProcessingfeeRate}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {interestRatePercentage}
            </Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{ProcessingfeePayable}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {feePayableCurrency}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{LegalFeeRate}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {LegalFeePercentage}
            </Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{LegalFeePayable}</Typography>
            <Typography sx={secondCurrencyStyle}>{LegalFeeAmount}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{StampDutyFeeRate}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {StampDutyFeePercentage}
            </Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{StampDutyFeePayable}</Typography>
            <Typography sx={secondCurrencyStyle}>{StampFeeAmount}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{ManagementFeeRate}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {ManagementFeePercentage}
            </Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{ManagementFeePayable}</Typography>
            <Typography sx={secondCurrencyStyle}>
              {ManagementFeeAmount}
            </Typography>
          </Box>
        </Box>
        <DotLine />
        <Box
          sx={{
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{TotalRepayment}</Typography>
            <Typography sx={currencyBlue}>{hundredThousand}</Typography>
          </Box>
        </Box>
        <DotLine />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{firstMonthRepayment}</Typography>
            <Typography sx={currencyBlue}>{firstMonthFee}</Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{DueDate}</Typography>
            <Typography sx={currencyBlue}>{dueDate}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{secondMonthRepayment}</Typography>
            <Typography sx={currencyBlue}>{secondMonthFee}</Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{DueDate}</Typography>
            <Typography sx={currencyBlue}>{dueDate}</Typography>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
