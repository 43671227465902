import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ActiveImage from "../../components/Assets/images/active.png";
import PendingImage from "../../components/Assets/images/pend.png";
import ClosedImage from "../../components/Assets/images/closed.png";
import BackgroundCard from "../../components/BackgroudCard";
import TopBar from "../../components/MyCreditTopBar";
import { Section } from "./style";

function MyCredit() {
  
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Link to="/active-transaction">
                <BackgroundCard
                  image={ActiveImage}
                  alt="Active transaction"
                ></BackgroundCard>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Link to="/pending-transaction">
                <BackgroundCard
                  image={PendingImage}
                  alt="Pending transaction"
                ></BackgroundCard>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Link to="/closed-transaction">
                <BackgroundCard
                  image={ClosedImage}
                  alt="Closed transaction"
                ></BackgroundCard>
              </Link>
            </Grid>
          </Grid>
        </Section>
      </main>
    </>
  );
}

export default MyCredit;
