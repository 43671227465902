
export const Container = {
  maxWidth: '100%',
  width: '100%'
}

export const imageRezes = {
  maxWidth: '100%',
  height: 'auto'
}

export const TitleStyle = {

}