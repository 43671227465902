import styled from "@emotion/styled";

export const Section = styled.section`
 margin-top: 5rem;

`

export const AccountHightTittle = {
    marginTop: { xs: '-1rem', sm: '1rem', lg: '-10rem' },
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '-0.04em',
    color: '#242424',
    padding: '1rem'
}

export const LeaseTitle = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '17px',
    linHeight: '20px',
    letterSpacing: '-0.03em',
    color: '#242424'
}

export const LeaseContent = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000'

}

export const transactionStyle = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#111E6C'
}
export const transactionblack = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#383838'
}

