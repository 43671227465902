import React from "react";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import { TextField } from "@mui/material";
import Buttons from "../../../../components/Buttons/index.jsx";
import Card from "@material-ui/core/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@material-ui/core/CardContent";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Link, useParams } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import {
  paperContainer,
  textLeft,
  right,
  Account,
  Number,
  Section,
} from "./style";
import { useEffect, useState } from "react";
import masterCard from "../../../../components/Assets/icons/master.svg";
import wallet from "../../../../components/Assets/icons/wallet.svg";
import Checkbox from "@mui/material/Checkbox";
import PaymentSuccessModal from "../../../../components/Modals/PaymentSuccessModal";
import TopBar from "../../../../components/MyCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import PaystackPayModal from "../../../../components/Modals/PaystackPay/index.jsx";
import {
  clearReferences,
  registerTransaction,
} from "../../../../redux/slices/paymentSlice.js";
import {
  repayLoan,
  clearRepayLoanInfo,
} from "../../../../redux/slices/myCreditSlice.js";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

function Repayment() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const { id } = useParams();
  const [amount, setAmount] = useState("");
  const [payDebitCard, setPayDebitCard] = useState(false);
  const [request, setRequest] = useState(true);
  const [paymentCard, setPaymentCard] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const {
    activeTransactions: allActiveTransactions,
    repayActiveLoan,
    repayActiveLoanErr,
    loading,
  } = useSelector((state) => state.myCredit);
  const { reg_transaction } = useSelector((state) => state.payment);
  //Successful Payment

  const selectedLoan = allActiveTransactions?.data?.content?.find(
    (loan) => loan?.id === parseInt(id)
  );

  const [openSuccessRepayment, setSuccessRepaymentOpen] = useState(false);
  const handleSuccessRepaymentOpen = () => setSuccessRepaymentOpen(true);
  const handleSuccessRepaymentClose = () => setSuccessRepaymentOpen(false);

  useEffect(() => {
    if (repayActiveLoan) {
      toast.success("Loan repayed");
      setAmount("");
      setPaymentMethod("");
      handleSuccessRepaymentOpen();
      dispatch(clearRepayLoanInfo());
    } else if (repayActiveLoanErr) {
      toast.error(repayActiveLoanErr?.message);
      dispatch(clearRepayLoanInfo());
    }
    // eslint-disable-next-line
  }, [repayActiveLoan, repayActiveLoanErr]);

  useEffect(() => {
    if (reg_transaction) {
      setPayDebitCard(true);
    }
  }, [reg_transaction]);

  const openHandleRequest = () => {
    setRequest(false);
    setPaymentCard(false);
  };

  const closePayWithDebitCard = () => {
    dispatch(clearReferences());
    setPayDebitCard(false);
  };

  const handlePayment = () => {
    if (paymentMethod === "Debit Card") {
      dispatch(
        registerTransaction({
          amount: amount,
          purposeOfPayment: "WALLET_FUNDING",
        })
      );
    } else {
      // handleSuccessRepaymentOpen();
      dispatch(
        repayLoan({
          transactionId: selectedLoan?.transactionId,
          loanRepaymentMethod: "WALLET",
          repaymentAmount: parseFloat(amount),
          transactionReference: "",
        })
      );
    }
  };

  const payWithDebitCard = () => {
    dispatch(
      repayLoan({
        transactionId: selectedLoan?.transactionId,
        loanRepaymentMethod: "DEBIT_CARD",
        repaymentAmount: parseFloat(amount),
        transactionReference: reg_transaction?.transactionReference ?? "",
      })
    );
  };

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: 21,
              fontFamily: "Montserrat",
              mt: { xs: "-2", lg: "-2" },
            }}
          >
            Repayment
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <Box style={{ position: "relative" }}>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "20px", lg: "20px" },
                    width: "100%",
                    maxWidth: { lg: "365.41px" },
                    height: { xs: "401px", lg: "401px" },
                    borderRadius: "8.94247px",
                    paperContainer,
                    boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.23)",
                  }}
                >
                  <Box>
                    <Typography sx={textLeft}>
                      {selectedLoan?.product?.productName}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Request Loan Amount</Typography>
                      <Typography sx={Account} style={{}}>
                        Tenor
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "343px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Number}
                        style={{ mt: { xs: "", lg: "" } }}
                      >
                        &#8358;
                        {parseFloat(
                          selectedLoan?.loanSummary?.requestedLoanAmount
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography sx={Number} style={{}}>
                        {selectedLoan?.loanSummary?.tenor}{" "}
                        {selectedLoan?.product?.feePaymentType === "WEEKLY"
                          ? "Day(s)"
                          : "Month(s)"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "483.41px" },
                        maxWidth: "343px",
                        top: "-8px",
                      }}
                    ></Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Interest Payable</Typography>
                      <Typography sx={Account}>Fee Payable</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number}>
                        &#8358;
                        {parseFloat(
                          selectedLoan?.loanSummary?.interestPayable
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography sx={Number}>
                        &#8358;
                        {parseFloat(
                          selectedLoan?.loanSummary?.feePayable
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "483.41px" },
                        top: "-10pxpx",
                        maxWidth: "343px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Total Repayment</Typography>
                      <Typography sx={Account}>Loan Due Date</Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number} style={{ color: "#111E6C" }}>
                        &#8358;
                        {parseFloat(
                          selectedLoan?.loanSummary?.totalRepayment
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography sx={Number} style={{ color: "#111E6C" }}>
                        {selectedLoan?.loanSummary?.loanDueDate
                          ?.split(" ")[0]
                          .replaceAll("-", "/")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "383.41px" },
                        top: "-10pxpx",
                        maxWidth: "343px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Request Date</Typography>
                      <Typography sx={Account}>Outstanding Balance</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number}>
                        {selectedLoan?.loanSummary?.createdAt
                          ?.slice(0, 10)
                          ?.replaceAll("-", "/")}
                      </Typography>
                      <Typography sx={Number}>
                        &#8358;
                        {(
                          selectedLoan?.outstanding ?? "0.00"
                        )?.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <BlockSpacer mt={40} />
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  display: "block",
                }}
              >
                <form>
                  <div>
                    <label
                      htmlFor="Top Request"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "15px",
                        display: "block",
                      }}
                    >
                      Value to pay
                    </label>
                    <input
                      type="text"
                      name=""
                      id="pley"
                      placeholder="input request here"
                      style={{
                        width: "100%",
                        height: "48px",
                        padding: "12px 20px",
                        margin: "8px 0",
                        display: "inlineBlock",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxSizing: "borderBox",
                      }}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  {/* <div className={style.TenorForm}>
                      <label
                        htmlFor="Tenor"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "15px",
                          display: "block",
                        }}
                      >
                        Tenor
                      </label>
                      <select
                        name=""
                        id="tenor"
                        style={{
                          width: "321px",
                          height: "48px",
                          padding: "12px 20px",
                          margin: "8px 0",
                          display: "Block",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          boxSizing: "borderBox",
                        }}
                      >
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                      </select>
                    </div> */}
                </form>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                background: "rgba(28, 68, 141, 0.03)",
                width: "100%",
              }}
            >
              {!paymentCard && (
                <Box
                  sx={{
                    marginTop: { xs: "40px", lg: "80px" },
                    mx: { xs: "1rem", md: "2rem" },
                  }}
                >
                  <Card
                    sx={{
                      maxWidth: "100%",
                      height: "189px",
                      border: "none",
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "15.78px",
                        }}
                      >
                        Payment Method
                      </Typography>
                    </CardContent>
                    <Box
                      display={"flex"}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box display={"flex"} sx={{ marginLeft: "15px" }}>
                        <img src={masterCard} alt="logo" />
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "13.52px",
                            fontFamily: "Montserrat",
                            marginLeft: "20px",
                          }}
                        >
                          Debit Card
                        </Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          checked={paymentMethod === "Debit Card"}
                          onClick={() => setPaymentMethod("Debit Card")}
                          {...label}
                          defaultChecked
                        />
                      </Box>
                    </Box>
                    <BlockSpacer mt={20} />
                    <Divider
                      style={{ width: "422.62px" }}
                      variant="middle"
                      light
                    />
                    <BlockSpacer mt={10} />
                    <Box
                      display={"flex"}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box display={"flex"} sx={{ marginLeft: "15px" }}>
                        <img src={wallet} alt="logo" />
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "13.52px",
                            fontFamily: "Montserrat",
                            marginLeft: "20px",
                          }}
                        >
                          wallet
                        </Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          checked={paymentMethod === "Wallet"}
                          onClick={() => setPaymentMethod("Wallet")}
                          {...label}
                        />
                      </Box>
                    </Box>
                    <BlockSpacer mt={20} />
                  </Card>
                </Box>
              )}
            </Grid>
          </Grid>
          {/* Card payment */}
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "87%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/active-transaction">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>

            {request && (
              <Buttons
                color="primary"
                width="328px"
                onClick={openHandleRequest}
              >
                Send Request
              </Buttons>
            )}
            {!request && (
              <Buttons color="primary" width="328px" onClick={handlePayment}>
                {loading ? (
                  <ClipLoader color={"#fff"} loading={loading} size={20} />
                ) : (
                  "Pay"
                )}
              </Buttons>
            )}
          </Box>
        </Paper>
        {openSuccessRepayment && (
          <PaymentSuccessModal
            open={openSuccessRepayment}
            handleClose={handleSuccessRepaymentClose}
          />
        )}
        {payDebitCard && (
          <PaystackPayModal
            amount={amount}
            open={payDebitCard}
            handleClose={() => closePayWithDebitCard()}
            onSubmitAction={payWithDebitCard}
          />
        )}
      </main>
    </>
  );
}

export default Repayment;
