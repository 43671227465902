import * as yup from "yup";

export const signUpBusinessValidation = yup.object().shape({
  companyName: yup.string().required("Company Name is required"),
  contactPersonFirstName: yup.string().required("First Name is required"),
  contactPersonLastName: yup.string().required("Last Name is required"),
  contactPersonEmail: yup
    .string()
    .email("Invalid Email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
      'Password must contain at least one upper case letter, one special character, one number and one lower case letter'
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  contactPersonphoneNumber: yup
    .string()
    .min(11, "Phone number must be at least 11 characters long")
    .required("Phone number is required"),
  contactPersonDOB: yup
    .date()
    .max(
      new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 18),
      "You must be at least 18 years old"
    )
    .required("Birthdate is required"),

  terms: yup.boolean().oneOf([true], "Terms and conditions must be accepted"),
  // otherInfo: yup.string().required("Other info is required"),
  // referalCode: yup.string().required("Referal code is required"),
});
