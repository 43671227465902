import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1244px) {
    margin-top: 25%;
  }
`;

export const choosePayment = {
  padding: "1rem",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "22px",
  letterSpacing: "-0.5px",
  color: "#222222",
};

export const PaymantBox = {
  width: "405px",
  padding: "1rem",
  maxWidth: "100%",
};

export const Uploadfile = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "-0.04em",
  color: "#333333",
};
