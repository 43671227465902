import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import { Link } from "react-router-dom";
import ConfettiIcon from "../../IconSvgs/ConfettiIcon";
import { Typography } from "@mui/material";
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "510px",
  maxWidth: "100%",
  height: "342px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function SuccessLoan({ open, handleClose }) {
  return (
    <div>
      <Box sx={{ maxWidth: "90%", padding: "1rem", width: { xs: "50px" } }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.header}>
              <ConfettiIcon />
            </div>
            <BlockSpacer mt={60} />
            <div className={styles.success}>Success!</div>
            <BlockSpacer mt={20} />
            <div className={styles.text}>
              Your loan is being processed and you will be notified if accepted
            </div>
            <BlockSpacer mt={25} />
            <Link to="/get-credit">
              <Button
                color={"primary"}
                width={240}
                height={41}
                marginLeft={"6rem"}

                // onClick={handleClose}
              >
                Continue
              </Button>
            </Link>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
