import React, { useEffect, useState } from "react";
import {
  Section,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
} from "./style.js";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../../components/Buttons/index.jsx";
import FormLabel from "../../../../components/Forms/Label/index.jsx";
import TextField from "@mui/material/TextField";
import NoteModal from "../../../../components/Modals/NoteModal/index.jsx";
import ApproveBankModal from "../../../../components/Modals/ApproveBankModal/index";
import FailureModal from "../../../../components/Modals/FailureModal/index";
// import LoanValueModal from "../../../../components/Modals/CollateralLoan/LoanValueModal";
import LoanValueModal from "../../../../components/Modals/PersonalLoanValue/index.jsx";
import TopBar from "../../../../components/GetCreditTopBar";
import {
  getLoanProducts,
  getLoanReasons,
  initializeLoanProcessing,
  getExistingCashBackedLoans,
  getTreasuryFundValue,
} from "../../../../redux/slices/getCreditSlice.js";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

function CashBack() {
  const paybackStructure = ["Monthly Payment", "Lump Sum Payment"];
  const [tenors, setTenors] = useState([]);
  const [selectedTenor, setSelectedTenor] = useState("");
  const [userLoanReason, setUserLoanReason] = useState("");
  const [payback, setPayback] = useState("");
  const [payday, setPayday] = useState("");
  const [salaryInfo, setSalaryInfo] = useState({
    netSalary: "",
    monthlyLoanObligation: "",
  });
  const navigate = useNavigate();

  const [method, setMethod] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    products,
    reasons,
    loading,
    initializedLoan,
    existingCashBacked,
    existingCashBackedErr,
    treasuryFundValue,
  } = useSelector((state) => state.getCredit);

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(getExistingCashBackedLoans());
    dispatch(getLoanReasons());
    dispatch(getTreasuryFundValue());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let product = products?.find((item) => item.id === parseInt(id));
    getRange(product?.minimumTenor, product?.maximumTenor);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (initializedLoan) {
      handleLoanOpen();
    }
  }, [initializedLoan]);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const [openNote, setNoteOpen] = useState(false);
  const handleNoteOpen = () => setNoteOpen(true);
  const handleNoteClose = () => setNoteOpen(false);

  //  Approve bank verifcation state
  const [openAroveBank, setApproveBankOpen] = useState(false);
  const handleformInfoOpen = () => setApproveBankOpen(true);
  const handleformInfoClose = () => setApproveBankOpen(false);

  // falure elageble for loan
  const [openEligableOpen, setEligableOpen] = useState(false);
  const handleEligableOpen = () => setEligableOpen(true);
  const handleEligableClose = () => setEligableOpen(false);

  // check if you are eligible
  const [openLoan, setLoan] = useState(false);
  const handleLoanOpen = () => setLoan(true);
  const handleLoanClose = () => setLoan(false);

  const reasonOpt = reasons ? reasons?.map((item) => item.loanReason) : [];
  const reasonOptions = [...reasonOpt, "Others"];

  const getRange = (min, max) => {
    var range = [];
    for (var i = min; i <= max; i++) {
      range.push(i === 1 ? "1 month" : `${i} months`);
    }
    setTenors(range);
  };

  const selectedProduct = products?.find((item) => item.id === parseInt(id));

  const handleInput = (e) => {
    const { name, value } = e.target;

    setSalaryInfo({
      ...salaryInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      initializeLoanProcessing({
        assetType: "string",
        existingCashBackedLoan: existingCashBackedErr ? 0 : existingCashBacked,
        landedPropertyLocation: "string",
        loanReasonId:
          method === "Others"
            ? null
            : reasons?.find((item) => item.loanReason === method).id,
        month: {
          apr: 0,
          aug: 0,
          dec: 0,
          feb: 0,
          jan: 0,
          jul: 0,
          jun: 0,
          mar: 0,
          may: 0,
          nov: 0,
          oct: 0,
          sep: 0,
        },
        netMonthlySalary: parseFloat(salaryInfo.netSalary),
        otherMonthlyObligation: parseFloat(salaryInfo.monthlyLoanObligation),
        paymentStructure: payback==="Monthly Payment"?"MONTHLY_PAYMENT":payback==="Lump Sum Payment"?"LUMP_SUM_PAYMENT":"",
        productId: parseInt(id),
        salaryPaymentDay: payday,
        tenor: parseInt(selectedTenor?.split(" ")[0]),
        treasuryFundValue: 0,
        userLoanReason: userLoanReason,
        vehicleBrandDetails: "string",
        vehicleId: 0,
        vehicleRegistrationId: 0,
        worthOfLandedProperty: 0,
        worthOfVehicle: 0,
      })
    );
  };

  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={titleContainer}>
          {selectedProduct?.productName}
        </Typography>
        <Box sx={contentContent}>
          {selectedProduct?.productSummary?.split(".")?.map((item, id) => (
            <Typography sx={paragraphtitle} key={id}>
              {item}
            </Typography>
          ))}
        </Box>
        <BlockSpacer mt={20} />
        <Typography sx={requirements}>Product Request Requirements</Typography>
        <BlockSpacer mt={20} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
              }}
            >
              <FormAligner>
                <FormLabel text={"Treasury Fund Value"} asterisk />
                <TextField
                  value={treasuryFundValue ? treasuryFundValue?.data : ""}
                  disabled
                  name={"Treasury Fund Value"}
                  asterisk
                />
              </FormAligner>

              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField
                  name={"Tenor"}
                  method={selectedTenor}
                  changeAction={(e) => setSelectedTenor(e.target.value)}
                  items={tenors}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={20} />

              <FormAligner>
                <SelectField
                  name={"Reason for Loan"}
                  items={reasonOptions}
                  changeAction={changeAction}
                  method={method}
                  asterisk
                />
              </FormAligner>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <BlockSpacer mt={10} />
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
              }}
            >
              <FormAligner>
                <FormLabel text={"Existing Cash Backed Loan"} asterisk />
                <TextField
                  value={existingCashBackedErr ? 0 : existingCashBacked}
                  disabled
                  name={"Existing Cash Backed Loan"}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={15} />

              <FormAligner>
                <SelectField
                  name={"Payback Structure"}
                  method={payback}
                  changeAction={(e) => setPayback(e.target.value)}
                  items={paybackStructure}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={15} />
              {method === "Others" && (
                <FormAligner>
                  <FormLabel text={"Input Reason here"} asterisk />

                  <TextField
                    placeholder="State reason here"
                    InputProps={{
                      style: {
                        color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                    value={userLoanReason}
                    onChange={(e) => setUserLoanReason(e.target.value)}
                  />
                </FormAligner>
              )}
            </Box>
          </Grid>
        </Grid>
      </Section>
      <BlockSpacer mt={80} />
      <Paper
        elevation={0.5}
        sx={{
          height: { xs: "15%", lg: "10%" },
          width: { xs: "92%", sm: "100%", lg: "87%" },
          margin: "1rem",
          maxwidth: "100%",
          boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
            padding: "1rem",
            maxWidth: "100%",
          }}
        >
          <Button
            sx={{
              background: "#F2F2F2",
              width: "328px",
              height: "54px",
              color: "#111E6C",
              marginBottom: "1rem",
              textTransform: "capitalize",
              fontWeight: "600",
              fontFamily: "Montserrat",
              fontSize: "17px",
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          <Buttons
            color="primary"
            width="328px"
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Profile Me"
            )}
          </Buttons>
        </Box>
      </Paper>
      {openNote && (
        <NoteModal
          open={openNote}
          handleClose={handleNoteClose}
          handleformInfoOpen={handleformInfoOpen}
        />
      )}
      {openAroveBank && (
        <ApproveBankModal
          open={openAroveBank}
          handleEligableOpen={handleEligableOpen}
          handleClose={handleformInfoClose}
        />
      )}

      {openEligableOpen && (
        <FailureModal
          open={openEligableOpen}
          handleLoanOpen={handleLoanOpen}
          handleClose={handleEligableClose}
        />
      )}

      {openLoan && (
        <LoanValueModal open={openLoan} handleClose={handleLoanClose} />
      )}

      <BlockSpacer mt={20} />
    </>
  );
}

export default CashBack;
