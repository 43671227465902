import React, { useEffect, useState } from "react";
import {
  Section,
  Title,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
  styles,
} from "./style.js";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import FormLabel from "../../../../components/Forms/Label/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../../components/Buttons/index.jsx";
import NoteModal from "../../../../components/Modals/NoteModal/index.jsx";
import TextField from "@mui/material/TextField";
// import Loanvalue from "../../../../components/Modals/Loanvalue/index";
import Loanvalue from "../../../../components/Modals/PersonalLoanValue/index.jsx";
import TopBar from "../../../../components/GetCreditTopBar";
import {
  getLoanProducts,
  getLoanReasons,
  initializeLoanProcessing,
  getAllVehicles,
  getAllRegistrations,
} from "../../../../redux/slices/getCreditSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { formatInputWithCommas, numberValidity } from "../../../../functions/numberValidity.js";

function VehicleAsset() {
  const navigate = useNavigate();
  const item = ["Select Brand"];
  const items = ["Business", "Others"];
  const [tenors, setTenors] = useState([]);
  const [selectedTenor, setSelectedTenor] = useState("");
  const [userLoanReason, setUserLoanReason] = useState("");
  const [payday, setPayday] = useState("");
  const [salaryInfo, setSalaryInfo] = useState({
    netSalary: "",
    monthlyLoanObligation: "",
    worthOfVehicle: "",
  });
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [vehicleLocate, setVehicleLocate] = useState("");
  const [vehicleAgeOpt, setVehicleAgeOpt] = useState([]);
  const [vehicleAge, setVehicleAge] = useState("");
  const [vehicleId, setVehicleId] = useState("");

  const [method, setMethod] = useState("");
  const { users } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const userType = users?.userType;
  const { id } = useParams();

  const {
    products,
    reasons,
    loading,
    initializedLoan,
    allVehicles,
    registeredServices,
  } = useSelector((state) => state.getCredit);

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(getLoanReasons());
    dispatch(getAllVehicles());
    dispatch(getAllRegistrations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedVehicle !== "" && vehicleStatus !== "") {
      let chosenBrand = allVehicles?.content?.find(
        (vehicle) => vehicle?.vehicleBrand === selectedVehicle
      );
      if (vehicleStatus === "Tokunbo") {
        let age = [];
        for (var i = 1; i <= chosenBrand?.maximumTenorTokunbo; i++) {
          age.push(i === 1 ? "1 year" : `${i} years`);
        }
        setVehicleAgeOpt(age);
      } else if (vehicleStatus === "Nigerian Used") {
        let age = [];
        for (var j = 1; j <= chosenBrand?.maximumTenorNigerianUsed; j++) {
          age.push(j === 1 ? "1 year" : `${j} years`);
        }
        setVehicleAgeOpt(age);
      }
    }
    // eslint-disable-next-line
  }, [selectedVehicle, vehicleStatus]);

  useEffect(() => {
    let product = products?.find((item) => item.id === parseInt(id));
    getRange(product?.minimumTenor, product?.maximumTenor);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (initializedLoan) {
      handleLoanValue();
    }
  }, [initializedLoan]);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const [openLoanValue, setLoanValueOpen] = useState(false);

  const handleLoanValue = () => {
    setLoanValueOpen(true);
  };
  const handleLoanValueClose = () => setLoanValueOpen(false);

  const reasonOpt = reasons ? reasons?.map((item) => item.loanReason) : [];
  const reasonOptions = [...reasonOpt, "Others"];

  const getRange = (min, max) => {
    var range = [];
    for (var i = min; i <= max; i++) {
      range.push(i === 1 ? "1 month" : `${i} months`);
    }
    setTenors(range);
  };

  const selectedProduct = products?.find((item) => item.id === parseInt(id));

  const handleInput = (e) => {
    const { name, value } = e.target;

    setSalaryInfo({
      ...salaryInfo,
      [name]: value,
    });
  };

  const vehicleOptions = ["Lagos", "Port Harcourt", "Abuja"];
  const vehicleStatusOpt = ["New", "Tokunbo", "Nigerian Used"];
  const vehicleUsage = registeredServices?.content?.map(
    (location) => `${location?.location}-${location?.assetStatus}`
  );

  const verifySubmit = () => {
    if (
      salaryInfo.monthlyLoanObligation !== "" &&
      selectedTenor !== "" &&
      method !== "" &&
      selectedVehicle !== "" &&
      vehicleLocate !== ""
    ) {
      if (
        userType !== "CORPORATE_LISTED" ||
        userType !== "CORPORATE_UNLISTED"
      ) {
        if (
          (
            payday !== "" && salaryInfo.netSalary !== ""
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      initializeLoanProcessing({
        assetType: "Vehicle",
        existingCashBackedLoan: 0,
        landedPropertyLocation: "string",
        loanReasonId:
          method === "Others"
            ? null
            : reasons?.find((item) => item.loanReason === method).id,
        month: {
          apr: 0,
          aug: 0,
          dec: 0,
          feb: 0,
          jan: 0,
          jul: 0,
          jun: 0,
          mar: 0,
          may: 0,
          nov: 0,
          oct: 0,
          sep: 0,
        },
        netMonthlySalary:
          userType === "CORPORATE_LISTED" || userType === "CORPORATE_UNLISTED"
            ? 0
            : parseFloat(salaryInfo.netSalary?.replace(/,/g, "")),
        otherMonthlyObligation: parseFloat(salaryInfo.monthlyLoanObligation?.replace(/,/g, "")),
        paymentStructure: "string",
        productId: parseInt(id),
        salaryPaymentDay:
          userType === "CORPORATE_LISTED" || userType === "CORPORATE_UNLISTED"
            ? 0
            : payday,
        tenor: parseInt(selectedTenor?.split(" ")[0]),
        treasuryFundValue: 0,
        userLoanReason: userLoanReason,
        vehicleBrandDetails: "",
        vehicleId: allVehicles?.content?.find(
          (item) => item?.vehicleBrand === selectedVehicle
        )?.id,
        vehicleRegistrationId: vehicleId,
        worthOfLandedProperty: 0,
        worthOfVehicle: parseFloat(salaryInfo?.worthOfVehicle?.replace(/,/g, "")),
      })
    );
  };

  const updateVehicle = (e) => {
    setVehicleLocate(e.target.value);
    let vehicleSplit = e.target.value?.split("-");
    let selectedlocate = registeredServices?.content?.find(
      (item) =>
        item?.location === vehicleSplit[0] &&
        item?.assetStatus === vehicleSplit[1]
    );
    setVehicleStatus(selectedlocate?.assetStatus);
    setVehicleId(selectedlocate?.id);
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <Typography variant="h6" sx={Title}>
            {selectedProduct?.productName}
          </Typography>
          <Box sx={contentContent}>
            {selectedProduct?.productSummary?.split(".")?.map((item, id) => (
              <Typography sx={paragraphtitle} key={id}>
                {item}
              </Typography>
            ))}
          </Box>
          <BlockSpacer mt={20} />
          <Typography sx={requirements}>
            Product Request Requirements
          </Typography>
          <BlockSpacer mt={20} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  marginLeft: { xs: "1rem", lg: "0rem" },
                }}
              >
                <FormAligner>
                  <SelectField
                    name={"Asset Usage Location"}
                    items={vehicleUsage}
                    changeAction={(e) => updateVehicle(e)}
                    method={vehicleLocate}
                    maxWidth="100%"
                    asterisk
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <SelectField
                    name={"Vehicle Brand"}
                    items={allVehicles?.content?.map(
                      (item) => item?.vehicleBrand
                    )}
                    changeAction={(e) => setSelectedVehicle(e.target.value)}
                    method={selectedVehicle}
                    label="Select Brand"
                    asterisk
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <FormLabel asterisk text={"Vehicle's Worth"} />
                  <TextField
                    placeholder="Input Worth"
                    name="worthOfVehicle"
                    required
                    value={formatInputWithCommas(salaryInfo.worthOfVehicle)}
                    onChange={handleInput}
                    InputProps={{
                      style: {
                        // color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "17px",
                      },
                    }}
                  />
                  {/* {numberValidity(salaryInfo.worthOfVehicle, "Vehicle's worth")
                    .checkBool && (
                    <span className="errorText">
                      {
                        numberValidity(
                          salaryInfo.worthOfVehicle,
                          "Vehicle's worth"
                        ).checkText
                      }
                    </span>
                  )} */}
                </FormAligner>
                {(userType !== "CORPORATE_LISTED" ||
                  userType !== "CORPORATE_UNLISTED") && (
                  <>
                    <BlockSpacer mt={20} />
                    <FormAligner>
                      <SelectField
                        name={"Salary Payment Day"}
                        items={[...Array(31).keys()].map((item) => item + 1)}
                        method={payday}
                        changeAction={(e) => setPayday(e.target.value)}
                        asterisk
                      />
                    </FormAligner>
                  </>
                )}
                <BlockSpacer mt={20} />
                <FormAligner>
                  <SelectField
                    name={"Tenor"}
                    method={selectedTenor}
                    changeAction={(e) => setSelectedTenor(e.target.value)}
                    items={tenors}
                    asterisk
                  />
                </FormAligner>
                {method === "Others" && (
                  <FormAligner>
                    <BlockSpacer mt={20} />
                    <FormLabel text={"Input Reason here"} asterisk />

                    <TextField
                      placeholder="State reason here"
                      InputProps={{
                        style: {
                          color: "grey",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                        },
                      }}
                      value={userLoanReason}
                      onChange={(e) => setUserLoanReason(e.target.value)}
                    />
                  </FormAligner>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <BlockSpacer mt={10} /> */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                }}
              >
                <FormAligner>
                  <SelectField
                    name={"Vehicle Status"}
                    method={vehicleStatus}
                    disabled
                    // items={vehicleStatusOpt}
                    asterisk
                  />
                </FormAligner>
                <BlockSpacer mt={20} />
                {/* <FormAligner>
                  <SelectField
                    name={"Vehicle Age"}
                    method={vehicleAge}
                    disabled
                    changeAction={(e) => setVehicleAge(e.target.value)}
                    items={vehicleAgeOpt}
                  />
                </FormAligner> */}
                {(userType !== "CORPORATE_LISTED" ||
                  userType !== "CORPORATE_UNLISTED") && (
                  <>
                    <BlockSpacer mt={20} />
                    <FormAligner>
                      <FormLabel asterisk text={"Net Monthly Salary"} />
                      <TextField
                        placeholder="Input net month salary"
                        name="netSalary"
                        value={formatInputWithCommas(salaryInfo.netSalary)}
                        onChange={handleInput}
                        InputProps={{
                          style: {
                            // color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "15px",
                          },
                        }}
                      />
                      {/* {numberValidity(salaryInfo.netSalary, "Net Salary")
                        .checkBool && (
                        <span className="errorText">
                          {
                            numberValidity(salaryInfo.netSalary, "Net Salary")
                              .checkText
                          }
                        </span>
                      )} */}
                    </FormAligner>
                  </>
                )}
                <BlockSpacer mt={20} />
                <FormAligner>
                  <FormLabel asterisk text={"Other monthly Loan Obligation"} />
                  <TextField
                    placeholder="Input Monthly Loan Obligation"
                    name="monthlyLoanObligation"
                    value={formatInputWithCommas(salaryInfo.monthlyLoanObligation)}
                    onChange={handleInput}
                    InputProps={{
                      style: {
                        // color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                  />
                  {/* {numberValidity(
                    salaryInfo.monthlyLoanObligation,
                    "Loan Obligation"
                  ).checkBool && (
                    <span className="errorText">
                      {
                        numberValidity(
                          salaryInfo.monthlyLoanObligation,
                          "Loan Obligation"
                        ).checkText
                      }
                    </span>
                  )} */}
                </FormAligner>
                <BlockSpacer mt={20} />
                <FormAligner>
                  <SelectField
                    name={"Reason for Loan"}
                    items={reasonOptions}
                    changeAction={changeAction}
                    method={method}
                    asterisk
                  />
                </FormAligner>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "87%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              onClick={() => navigate(-1)}
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              Back
            </Button>

            <Buttons
              color="primary"
              width="328px"
              onClick={(e) => handleSubmit(e)}
              disabled={verifySubmit()}
            >
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Profile Me"
              )}
            </Buttons>
          </Box>
        </Paper>
        {openLoanValue && (
          <Loanvalue open={openLoanValue} handleClose={handleLoanValueClose} />
        )}
      </>
    </>
  );
}

export default VehicleAsset;
