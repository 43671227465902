import HttpService from "./HttpService";

export const feedbackCategoryService = () => {
  const http = new HttpService();

  const url = "auth/feedback/categories?platform=CREDIT";

  return http.getData(url);
};

export const feedbackPostService = (formData) => {
  const http = new HttpService();

  const url = "auth/feedback";

  return http.postDataWithToken(formData, url);
};

export const getMyOpenTicketServices = () => {
  const http = new HttpService();

  const url = "auth/feedback/open-tickets?platform=CREDIT";

  return http.getData(url);
};


export const getMyTicketServices = () => {
const http = new HttpService();

const url = "auth/feedback/my-tickets?platform=CREDIT"


return http.getData(url);

}


export const getClosedTicketServices = () => {
const http = new HttpService();

const url = "auth/feedback/closed-tickets?platform=CREDIT";


return http.getData(url);

}


export const getMyTicketsById = (ticketid) => {

const http = new HttpService();

const url = `auth/feedback/${ticketid}?platform=CREDIT`

return http.getData(url)

}


export const getTicketsReplyById= (ticketid) => {

const http = new HttpService();


const url =`auth/feedback/ticket-reply/${ticketid}`;

return http.getData(url)

}


export const postTicketsReply = (replyData) =>{


  const http = new HttpService();

  const url = "auth/feedback/reply-ticket";

  return http.postDataWithToken(replyData, url);


}


export const putEditTIcket = (formData) => {

const http = new HttpService();

const url = "/auth/feedback/edit-ticket?platform=CREDIT";

return http.putData(formData, url);

}