import React, { useEffect, useState } from "react";
import {
  Section,
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  Title,
  subTitle,
  Head,
  loan,
  Loan,
  contentTitle,
} from "./style.js";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoan,
  getLoanProducts,
} from "../../../redux/slices/getCreditSlice.js";
import useGetLoanProductsQuery from "../../../api/services/loans/access-user-loan.api.js";

//Loan Types Include:
// Student Loans, Salary Earner With No Side Business Loans, Pensioner Loans,
// Salary Earner With Side Business Loans, Sole Proprietor Loans, Corporate Loans

// Salary Earner With Side Business Loans
export const LoanTypes = () => {
  const { data: accessLoanProduct } = useGetLoanProductsQuery();
  const { users } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const userType = users?.userType;
  const userSector = users?.individualUser?.creditEmploymentDetail?.sector;
  //Vehicle Asset Cash Loan

  const { products } = useSelector((state) => state.getCredit);

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(clearLoan());
    // eslint-disable-next-line
  }, []);

  const [currentProduct, setCurrentProduct] = useState("");

  const handleCurrentProduct = (id) => {
    setCurrentProduct(id);
    console.log(id);
  };
  // Collateral loans
  const collateralLoans = [
    {
      productType: "Vehicle Asset Cash Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item?.productCode === "VEHICLE_ASSET_CASH_INDIVIDUAL"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return userType === "SOLE_PROPRIETOR_REGISTERED" ||
          userType === "SOLE_PROPRIETOR_UNREGISTERED"
          ? `/vehicle-asset/sole-proprietor/${id}`
          : `/vehicle-asset-cash-loan/${id}`;
      },
    },
    {
      productType: "Landed Asset Cash Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item?.productCode === "LANDED_ASSET_CASH_LOAN_INDIVIDUAL"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return userType === "SOLE_PROPRIETOR_REGISTERED" ||
          userType === "SOLE_PROPRIETOR_UNREGISTERED"
          ? `/sole-proprietor-loan/landed-asset/${id}`
          : `/landed-asset-loan/${id}`;
      },
    },
    {
      productType: "Cash-Backed Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item?.productCode === "CASH_BACKED"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return `/cash-back/${id}`;
      },
    },
  ];

  // Non Collateral Loans
  const nonCollateralLoans = [
    {
      productType: "Quick Nano Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item.productCode === "QUICK_NANO"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return `/quick-loan/${id}`;
      },
    },
    {
      productType: "Quick Payday Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item.productCode === "QUICK_PAYDAY"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return `/quick-payday/${id}`;
      },
    },

    {
      productType: "Personal Loan",
      products: {
        privateSector: accessLoanProduct?.body?.productList?.filter(
          (item) =>
            item?.creditProductLine === "LOAN" &&
            // userSector === "PRIVATE_SECTOR" &&
            item?.productCode === "PERSONAL_PRIVATE"
        ),
        publicSector: accessLoanProduct?.body?.productList?.filter(
          (item) =>
            item?.creditProductLine === "LOAN" &&
            // (userSector === "PUBLIC_SECTOR" || userSector === null) &&
            item?.productCode === "PERSONAL_PUBLIC"
        ),
      },
      showProducts: true,
      handleNavigate: (id) => {
        return `/personal-loan/${id}`;
      },
    },
    {
      productType: "Business Support Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item?.productCode === "CONSUMER_BUSINESS_SUPPORT"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return `/consumer-business/${id}`;
      },
    },
    {
      productType: "Group Loan",
      products: accessLoanProduct?.body?.productList?.filter(
        (item) =>
          item?.creditProductLine === "LOAN" &&
          item?.productCode === "PERSONAL_GROUP"
      ),
      showProducts: true,
      handleNavigate: (id) => {
        return `/group-loan/${id}`;
      },
    },
  ];

  console.log(nonCollateralLoans[2].products);
  return (
    <>
      <TopBar />
      <>
        <Section>
          <Typography variant="h6" sx={Loan}>
            Loan
          </Typography>
          <Typography variant="p">
            {userType === "Salary Earner (Owns a Side Business)"
              ? "Salary Earner (Owns a Side Business)"
              : userType === "Pensioner"
              ? "Pensioner"
              : userType === "Student" ||
                userType === "Student (Post-secondary)"
              ? "Student (Post-secondary)"
              : userType === "Salary Earner (With No Side Business)"
              ? "Salary Earner (No Side Business)"
              : userType === "SOLE_PROPRIETOR_REGISTERED" ||
                userType === "SOLE_PROPRIETOR_UNREGISTERED"
              ? "(Sole Proprietor)"
              : userType === "CORPORATE_LISTED" ||
                userType === "CORPORATE_UNLISTED"
              ? "(Corporate Public Listed / Unlisted)"
              : ""}
          </Typography>
          <Box sx={{ marginTop: "2rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Accordion style={{ border: "0", boxShadow: "" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div>
                      {" "}
                      <Typography variant={"h6"} sx={loan}>
                        Collateral Loans
                      </Typography>
                      <Typography
                        variant={"p"}
                        sx={{ fontSize: "14px", marginTop: "1rem" }}
                      >
                        Request a Collateral Loan
                      </Typography>
                    </div>
                  </AccordionSummary>
                  {collateralLoans?.map(
                    (loan) =>
                      loan.showProducts && (
                        <AccordionDetails key={loan?.productType}>
                          <Box
                            display={"flex"}
                            sx={{
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "17px",
                                letterSpacing: "-0.01em",
                              }}
                            >
                              {loan?.productType}
                            </Typography>
                            {/* <Link to="/vehicle-asset-cash-loan"> */}
                            {loan?.productType === currentProduct ? (
                              <Box>
                                <RemoveIcon
                                  sx={{
                                    color: "#111E6C",
                                    cursor: "pointer",
                                    width: "16px",
                                  }}
                                  onClick={() => handleCurrentProduct("")}
                                />
                              </Box>
                            ) : (
                              <Box>
                                <AddIcon
                                  sx={{
                                    color: "#111E6C",
                                    cursor: "pointer",
                                    width: "16px",
                                  }}
                                  onClick={() =>
                                    handleCurrentProduct(loan?.productType)
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                          {loan?.productType === currentProduct ? (
                            loan?.products && loan?.products.length ? (
                              loan.products?.map((item, id) => (
                                <Paper
                                  elevation={0.5}
                                  key={id}
                                  sx={{
                                    width: { xs: "448px", lg: "448px" },
                                    maxWidth: "100%",
                                    minHeight: { sx: "400px", lg: "213px" },
                                    boxShadow:
                                      " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "95%",
                                    }}
                                  >
                                    <Box>
                                      <Typography sx={QuickNanoLoan}>
                                        {item?.productName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box sx={callToActionCard}>
                                    <Box>
                                      <NanoImage />
                                    </Box>
                                    <div>
                                      {item?.productSummary
                                        ?.split(".")
                                        ?.map((describe, id) => (
                                          <Typography
                                            sx={paragraphTitle}
                                            key={id}
                                          >
                                            {describe}
                                          </Typography>
                                        ))}

                                      <Link to={loan.handleNavigate(item?.id)}>
                                        <Button
                                          sx={{
                                            background: "#F2F2F2",
                                            marginTop: "1rem",
                                            width: "144px",
                                            height: "42px",
                                            color: "#111E6C",
                                            fontFamily: "Montserrat",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            letterSpacing: "-0.03em",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Request
                                        </Button>
                                      </Link>
                                    </div>
                                  </Box>
                                </Paper>
                              ))
                            ) : (
                              <Paper
                                elevation={0.5}
                                sx={{
                                  width: { xs: "448px", lg: "448px" },
                                  maxWidth: "100%",
                                  height: { sx: "100px", lg: "100px" },
                                  boxShadow:
                                    " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "95%",
                                  }}
                                >
                                  <Box>
                                    <Typography sx={QuickNanoLoan}>
                                      No Product Available
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            )
                          ) : null}
                        </AccordionDetails>
                      )
                  )}
                </Accordion>
                <BlockSpacer mt={70} />
                {!(
                  userType === "CORPORATE_LISTED" ||
                  userType === "CORPORATE_UNLISTED"
                ) && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div>
                        {" "}
                        <Typography variant={"h6"} sx={loan}>
                          Non-Collateral Loans
                        </Typography>
                        <Typography
                          variant={"p"}
                          sx={{ fontSize: "14px", marginTop: "1rem" }}
                        >
                          Request a Non-Collateral Loan
                        </Typography>
                      </div>
                    </AccordionSummary>

                    {nonCollateralLoans.map(
                      (loan) =>
                        loan.showProducts &&
                        loan.productType.toLowerCase() !==
                          "Personal Loan".toLowerCase() && (
                          <AccordionDetails>
                            <Box
                              display={"flex"}
                              sx={{
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  letterSpacing: "-0.01em",
                                }}
                              >
                                {loan.productType}
                              </Typography>
                              {/* <Link to="/quick-loan"> */}
                              {currentProduct === loan?.productType ? (
                                <Box>
                                  <RemoveIcon
                                    sx={{
                                      color: "#111E6C",
                                      cursor: "pointer",
                                      width: "16px",
                                    }}
                                    onClick={() => handleCurrentProduct("")}
                                  />
                                </Box>
                              ) : (
                                <Box>
                                  <AddIcon
                                    sx={{
                                      color: "#111E6C",
                                      cursor: "pointer",
                                      width: "16px",
                                    }}
                                    onClick={() =>
                                      handleCurrentProduct(loan?.productType)
                                    }
                                  />
                                </Box>
                              )}
                            </Box>

                            {loan?.productType === currentProduct ? (
                              loan.products && loan.products?.length ? (
                                loan.products?.map(
                                  (item, id) =>
                                    item?.creditProductLine === "LOAN" &&
                                    // item?.productCode === "QUICK_NANO" &&
                                    item?.targetMarkets?.some(
                                      (item) => item.name === userType
                                    ) && (
                                      <Paper
                                        elevation={0.5}
                                        sx={{
                                          width: { xs: "448px", lg: "448px" },
                                          maxWidth: "100%",
                                          minHeight: {
                                            sx: "400px",
                                            lg: "213px",
                                          },
                                          boxShadow:
                                            " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                        }}
                                        key={id}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "95%",
                                          }}
                                        >
                                          <Box>
                                            <Typography sx={QuickNanoLoan}>
                                              {item?.productName}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box sx={callToActionCard}>
                                          <Box>
                                            <NanoImage />
                                          </Box>
                                          <div>
                                            {item?.productSummary
                                              ?.split(".")
                                              ?.map((describe, id) => (
                                                <Typography
                                                  sx={paragraphTitle}
                                                  key={id}
                                                >
                                                  {describe}
                                                </Typography>
                                              ))}
                                            <Link
                                              to={loan.handleNavigate(item?.id)}
                                            >
                                              <Button
                                                sx={{
                                                  background: "#F2F2F2",
                                                  marginTop: "1rem",
                                                  width: "144px",
                                                  height: "42px",
                                                  color: "#111E6C",
                                                  fontFamily: "Montserrat",
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  lineHeight: "21px",
                                                  letterSpacing: "-0.03em",
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Request
                                              </Button>
                                            </Link>
                                          </div>
                                        </Box>
                                      </Paper>
                                    )
                                )
                              ) : (
                                <Paper
                                  elevation={0.5}
                                  sx={{
                                    width: { xs: "448px", lg: "448px" },
                                    maxWidth: "100%",
                                    height: { sx: "100px", lg: "100px" },
                                    boxShadow:
                                      " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "95%",
                                    }}
                                  >
                                    <Box>
                                      <Typography sx={QuickNanoLoan}>
                                        No Product Available
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Paper>
                              )
                            ) : null}
                          </AccordionDetails>
                        )
                    )}
                    {nonCollateralLoans.map(
                      (loan) =>
                        loan.showProducts &&
                        loan.productType.toLowerCase() ===
                          "Personal Loan".toLowerCase() && (
                          <>
                            <AccordionDetails>
                              <Box
                                display={"flex"}
                                sx={{
                                  justifyContent: "space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    letterSpacing: "-0.01em",
                                  }}
                                >
                                  {loan.productType}
                                </Typography>
                                {loan?.productType === currentProduct ? (
                                  <Box>
                                    <RemoveIcon
                                      sx={{
                                        color: "#111E6C",
                                        cursor: "pointer",
                                        width: "16px",
                                      }}
                                      onClick={() => handleCurrentProduct("")}
                                    />
                                  </Box>
                                ) : (
                                  <Box>
                                    <AddIcon
                                      sx={{
                                        color: "#111E6C",
                                        cursor: "pointer",
                                        width: "16px",
                                      }}
                                      onClick={() =>
                                        handleCurrentProduct(loan?.productType)
                                      }
                                    />
                                  </Box>
                                )}
                              </Box>

                              {loan?.productType === currentProduct ? (
                                loan.products &&
                                !!loan.products?.publicSector?.length ? (
                                  loan?.products?.publicSector?.map(
                                    (item, id) =>
                                      item?.targetMarkets?.some(
                                        (item) => item.name === userType
                                      ) && (
                                        <Paper
                                          elevation={0.5}
                                          sx={{
                                            width: {
                                              xs: "448px",
                                              lg: "448px",
                                            },
                                            maxWidth: "100%",
                                            minHeight: {
                                              sx: "400px",
                                              lg: "213px",
                                            },
                                            boxShadow:
                                              " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                          }}
                                          key={id}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: "95%",
                                            }}
                                          >
                                            <Box>
                                              <Typography sx={QuickNanoLoan}>
                                                {item?.productName}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box sx={callToActionCard}>
                                            <Box>
                                              <NanoImage />
                                            </Box>
                                            <div>
                                              {item?.productSummary
                                                ?.split(".")
                                                ?.map((describe, id) => (
                                                  <Typography
                                                    sx={paragraphTitle}
                                                    key={id}
                                                  >
                                                    {describe}
                                                  </Typography>
                                                ))}
                                              <Link
                                                to={loan.handleNavigate(
                                                  item?.id
                                                )}
                                              >
                                                <Button
                                                  sx={{
                                                    background: "#F2F2F2",
                                                    marginTop: "1rem",
                                                    width: "144px",
                                                    height: "42px",
                                                    color: "#111E6C",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "21px",
                                                    letterSpacing: "-0.03em",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  Request
                                                </Button>
                                              </Link>
                                            </div>
                                          </Box>
                                        </Paper>
                                      )
                                  )
                                ) : !!loan?.products?.privateSector.length ? (
                                  loan?.products?.privateSector?.map(
                                    (item, id) =>
                                      // item?.productCode === "PERSONAL_PRIVATE" &&
                                      item?.targetMarkets?.some(
                                        (item) => item.name === userType
                                      ) && (
                                        <Paper
                                          elevation={0.5}
                                          sx={{
                                            width: {
                                              xs: "448px",
                                              lg: "448px",
                                            },
                                            maxWidth: "100%",
                                            minHeight: {
                                              sx: "400px",
                                              lg: "213px",
                                            },
                                            boxShadow:
                                              " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                          }}
                                          key={id}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: "95%",
                                            }}
                                          >
                                            <Box>
                                              <Typography sx={QuickNanoLoan}>
                                                {item?.productName}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box sx={callToActionCard}>
                                            <Box>
                                              <NanoImage />
                                            </Box>
                                            <div>
                                              {item?.productSummary
                                                ?.split(".")
                                                ?.map((describe, id) => (
                                                  <Typography
                                                    sx={paragraphTitle}
                                                    key={id}
                                                  >
                                                    {describe}
                                                  </Typography>
                                                ))}
                                              <Link
                                                to={loan.handleNavigate(
                                                  item?.id
                                                )}
                                              >
                                                <Button
                                                  sx={{
                                                    background: "#F2F2F2",
                                                    marginTop: "1rem",
                                                    width: "144px",
                                                    height: "42px",
                                                    color: "#111E6C",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "21px",
                                                    letterSpacing: "-0.03em",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  Request
                                                </Button>
                                              </Link>
                                            </div>
                                          </Box>
                                        </Paper>
                                      )
                                  )
                                ) : (
                                  <Paper
                                    elevation={0.5}
                                    sx={{
                                      width: { xs: "448px", lg: "448px" },
                                      maxWidth: "100%",
                                      height: { sx: "100px", lg: "100px" },
                                      boxShadow:
                                        " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "95%",
                                      }}
                                    >
                                      <Box>
                                        <Typography sx={QuickNanoLoan}>
                                          No Product Available
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Paper>
                                )
                              ) : null}
                            </AccordionDetails>
                          </>
                        )
                    )}
                  </Accordion>
                )}
              </Grid>
            </Grid>
          </Box>
          <BlockSpacer mt={450} />
          <Paper
            sx={{
              height: "96px",
              width: { xs: "100%", lg: "90%" },
              maxWidth: "100%",
              boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
            }}
            elevation={1}
          >
            <Link to="/get-credit">
              <Button
                sx={{
                  background: "#F2F2F2",
                  marginTop: "1rem",
                  width: { xs: "250px", lg: "328px" },
                  height: "42px",
                  color: "#111E6C",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "15px",
                  marginLeft: "1rem",
                }}
              >
                Back
              </Button>
            </Link>
          </Paper>
        </Section>
      </>
    </>
  );
};
