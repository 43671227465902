import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Ellipse from "../../assets/Ellipse.png";
import {
  duepaymentTitle,
  duePaymentAmount,
  nextDuePayment,
  nextDueDate,
} from "./style.js";
import useGetCurrentTransactionQuery from "../../api/services/transactions/get-current-transaction.api.js";

function TransactionCard() {
  const { data } = useGetCurrentTransactionQuery();
  const loanSummary = data?.data?.loanSummary;
  const duedPersonalLoan = loanSummary?.loanDueDates?.find(
    (loan) => !loan?.paidOff
  );
  const personalLoanDueDates = duedPersonalLoan?.repaymentDate.split(" ", 1);
  const quickLoanDueDate = loanSummary?.loanDueDate.split(" ", 1);
  // If no array of due dates return the loan due date of the loan
  const dueDate = personalLoanDueDates?.length
    ? personalLoanDueDates
    : quickLoanDueDate;

  // If no array of due dates return the monthly repayment

  const duePayment = personalLoanDueDates?.length
    ? duedPersonalLoan?.amount
    : loanSummary?.monthlyRepayment;
  return (
    <>
      <Box
        sx={{
          maxWidth: "513px",
          marginLeft: { xs: "2px" },
          height: 192,
          opacity: 0.7,
          backgroundColor: " rgba(17, 30, 108, 0.08)",
          boxShadow: " 0px 6px 18px rgba(196, 204, 221, 0.25)",
          borderRadius: "20px",
          margin: { xs: "1rem" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Box>
            <Typography>Transaction Amount</Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "26px",
                letterSpacing: "-1px",
                color: "#242424",
              }}
            >
              ₦{loanSummary?.requestedLoanAmount.toLocaleString() || "0.00"}
            </Typography>
          </Box>

          <Box
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 4px 10px rgba(148, 148, 148, 0.25)",
              borderRadius: "10px",
              width: "31px",
              height: "33px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ₦
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            mt: "1rem",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={duepaymentTitle}>Due Payment</Typography>
            <Typography sx={duePaymentAmount}>
              ₦{duePayment?.toLocaleString() || "0.00"}
            </Typography>
          </Box>

          {dueDate && (
            <Box>
              <Typography sx={nextDuePayment}>Next Due Date</Typography>
              <Typography sx={nextDueDate}>{dueDate}</Typography>
            </Box>
          )}
        </Box>
        <img
          src={Ellipse}
          alt="ellipse"
          style={{
            position: "absolute",
            top: 0,
            zIndex: "-1",
            right: "20px",
          }}
        />
      </Box>
    </>
  );
}

export default TransactionCard;
