import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  cardBox,
  summaryTitle,
  currencyStyle,
  secondCurrencyStyle,
  firstCurrencyStyle,
  currencyBlue,
  currencyBlueTwo,
} from "./style";
import DotLine from "../../../components/Hocs/DotLine";

export default function SummaryCard({
  title,
  currencyRequested,
  tenor,
  infoPayable,
  feePayable,
  currency,
  days,
  interestpayableCurrency,
  totalPaymentDate,
  totalPaymentAmount,
  feePayableCurrency,
  TotalRepayment,
  LoanDueDate,
}) {
  return (
    <>
      <Paper elevation={0.5} sx={cardBox}>
        <Typography variant="h6" style={summaryTitle}>
          {title}
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{currencyRequested}</Typography>
              <Typography sx={firstCurrencyStyle}>
                ₦
                {parseFloat(currency)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={currencyStyle}>{tenor}</Typography>
              <Typography sx={secondCurrencyStyle}>{days}</Typography>
            </Box>
          </Box>
          <DotLine />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.2rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{infoPayable}</Typography>
              <Typography sx={firstCurrencyStyle}>
                ₦
                {parseFloat(interestpayableCurrency)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{feePayable}</Typography>
              <Typography sx={secondCurrencyStyle}>
                ₦
                {parseFloat(feePayableCurrency)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
          </Box>
          <DotLine />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.2rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{TotalRepayment}</Typography>
              <Typography sx={currencyBlue}>
                ₦
                {parseFloat(totalPaymentAmount)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{LoanDueDate}</Typography>
              <Typography sx={currencyBlueTwo}>
                {totalPaymentDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
