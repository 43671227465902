import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetRequiredDocsQuery = ({ transactionId }) => {
  const getRequiredDocs = async () => {
    const request = await axiosInstance.get(
      `/auth/my-credit/transactions/required-docs/${transactionId}`
    );
    const response = request.data;
    return response;
  };
  const query = useQuery(
    ["TRANSACTION_REQUIRED_DOCS", transactionId],
    getRequiredDocs,
    {
      enabled: !!transactionId,
    }
  );
  return query;
};

export default useGetRequiredDocsQuery;
