import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
const useSubmitUnderWriterDocsMutation = () => {
  const submitUnderWriterDocs = async (data) => {
    const request = await axiosInstance.post(
      `/auth/my-credit/transactions/submit-underwriter-docs`,
      data
    );

    const response = request.data;
    return response;
  };
  const query = useMutation([], submitUnderWriterDocs, {});
  return query;
};

export default useSubmitUnderWriterDocsMutation;
