import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUsers } from "../../../redux/slices/profileSlice";
import { 
  LoanTypes
} from "./loanTypes";

function LoanCredit() {
  const dispatch = useDispatch();
  // comment

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch])

  return (
    <LoanTypes />
  )
}

export default LoanCredit;
