import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetManufacturingYearsQuery = () => {
  const getManufacturingYears = async () => {
    const request = await axiosInstance.get(`/years-of-vh-manufacture`);
    const response = request.data;
    return response;
  };
  const query = useQuery(["MANUFACTURING_YEARS"], getManufacturingYears);
  return query;
};

export default useGetManufacturingYearsQuery;
