import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import SuccessMessage from "../../Cards/SuccessMessages";
import OfferMessage from "../../Cards/CollatralLoan/OfferSuccessMessage";

import { style } from "../OtpVerificationModal";
import GreenIcon from "../../IconSvgs/GreenIcon";

export default function SuccessModalOffer({
  open,
  handleClose,
  handleSourceAccount,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-otp-title"
        aria-describedby="modal-otp-description"
      >
        <Box sx={style}>
          <OfferMessage
            handleSourceAccount={handleSourceAccount}
            subTextStart={"You have successfully accepted the loan offer"}
            icon={<GreenIcon />}
          />
        </Box>
      </Modal>
    </div>
  );
}
