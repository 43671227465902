import React, { useState } from "react";
import { Section } from "./style.js";
import { Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { COMPANY_USER_TYPES } from "../../../constants/index.js";

function ProfileSidebar() {
  const [currentLink, setCurrentLink] = useState(1);
  const [defaultUserMethod, setDefaultUserMethod] = useState();
  const { users } = useSelector(state => state.profile);

  React.useEffect(() => {
    if (users?.userType === "SOLE_PROPRIETOR_REGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[0]);
    } else if (users?.userType === "SOLE_PROPRIETOR_UNREGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[1]);
    } else if (users?.userType === "CORPORATE_LISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[2]);
    } else if (users?.userType === "CORPORATE_UNLISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[3]);
    }
  }, [users]);
  return (
    <>
      <Section>
        <div className="top">
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "26px",
              letterSpacing: "-0.04em",
              ml: "2.1rem",
              color: "#222222",
            }}
          >
            Profile
          </Typography>
          <div className="toggle"></div>
          <div className="links">
            <ul>
              <NavLink to="/profile/company/info" className="nav-list" >
                <span>Company information</span>
              </NavLink>{" "}
              <NavLink to="/profile/company/director" className="nav-list">
                <span>Director Details</span>
              </NavLink>{" "}
              <NavLink to="/profile/company/business-document" className="nav-list">
                <span>Company Documents</span>
              </NavLink>
              {/* <NavLink to="/profile/company/bank-details" className="nav-list">
                <span>Bank Details</span>
              </NavLink> */}
              {
                (defaultUserMethod === COMPANY_USER_TYPES[0] ||
                defaultUserMethod === COMPANY_USER_TYPES[1]) && (
                  <NavLink to="/profile/company/business-bank-details" className="nav-list">
                    <span>Business Bank Details</span>
                  </NavLink>
                )
              }
              <NavLink to="/profile/company/change-password" className="nav-list">
                <span>Change Password</span>
              </NavLink>
            </ul>
          </div>
        </div>
      </Section>
    </>
  );
}

export default ProfileSidebar;
