import React from "react";

function DiscoveryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="291"
      height="355"
      fill="none"
      viewBox="0 0 291 355"
    >
      <g clipPath="url(#clip0_2946_68068)">
        <ellipse
          fill="#000"
          opacity="0.4"
          rx="70.011"
          ry="8.728"
          transform="matrix(.99791 -.0646 -.06435 -.99793 137.427 332.233)"
        ></ellipse>
        <path
          fill="#6B81BE"
          d="M108.736 324.047H27.905a.328.328 0 010-.655h80.831a.327.327 0 010 .655zM103.164 328.108H70.977a.325.325 0 01-.231-.559.329.329 0 01.231-.096h32.179a.327.327 0 010 .655h.008zM290.673 324.047h-80.83a.331.331 0 01-.232-.096.33.33 0 010-.463.327.327 0 01.232-.096h80.83c.087 0 .17.034.231.096a.327.327 0 01-.231.559zM253.681 328.108h-32.186a.323.323 0 01-.231-.096.327.327 0 01.231-.559h32.179a.33.33 0 01.231.096.33.33 0 010 .463.326.326 0 01-.231.096h.007z"
        ></path>
        <path
          fill="#6B81BE"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M.41 320.542s-.38-12.191 1.71-16.763c2.09-4.572 4.371-18.287 8.552-23.049 4.181-4.762 10.643-18.859 12.163-33.907 1.52-15.049 4.561-36.956 12.924-36.765 8.362.19 6.651 20.382 9.882 28.764 3.23 8.381 7.222 11.429 6.842 25.907-.38 14.477 14.253 18.668 15.584 32.192l1.71 23.621H.41z"
        ></path>
        <path
          fill="#9AB3DA"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M9.152 320.543s-.19-10.287 4.37-19.811c4.562-9.525 2.281-18.478 5.322-26.478 3.04-8.001-2.463-23.156 4.18-29.298 6.645-6.141 12.544 7.574 13.304 20.344.76 12.771 7.602 13.144 8.742 24.574 1.14 11.429 7.032 12.016 11.023 18.866 3.991 6.85 3.421 11.803 3.421 11.803H9.152zM206.232 327.781h-92.933l7.602-18.477s.958-4.077 1.794-7.955a62.629 62.629 0 001.246-6.713 7.48 7.48 0 011.088-2.172c2.158-3.535 7.092-10.423 10.551-15.185 2.166-2.972 3.755-5.121 3.755-5.121l12.832-.434c6.751-.236 13.82-.953 15.31-.983h.244a80.849 80.849 0 0010.521-2.431c2.676-.815 4.804-1.478 4.804-1.478l9.692 4.381-.258 2.286-.882 7.864c-.486 4.396-.95 8.625-.95 8.892 0 .571 15.584 37.526 15.584 37.526z"
        ></path>
        <path
          fill="#6B81BE"
          d="M166.38 327.781h-10.681c-.76-4.26-1.778-9.38-2.28-10.858-.95-2.667-8.172-8.763-10.453-9.715-2.052-.861-16.914-4.808-20.251-5.86.53-2.213.938-4.454 1.224-6.713a7.484 7.484 0 011.087-2.171c5.678 1.067 16.199 3.147 20.008 4.648 5.321 2.095 14.634 12.001 16.724 15.239 1.239 1.92 3.231 9.547 4.622 15.43zM192.479 273.46l-.882 7.902a77.304 77.304 0 01-17.294-5.776c-2.387-1.173-4.767-3.185-6.842-4.846h.243a81.01 81.01 0 0010.521-2.43 36.914 36.914 0 0014.254 5.15zM193.216 327.781h-9.981c-2.782-8.282-7.442-21.396-10.232-25.335-4.181-5.905-16.154-17.906-21.665-20.383-3.535-1.584-10.901-3.566-15.729-4.785 2.167-2.971 3.756-5.12 3.756-5.12l12.832-.434c6.682 2.08 13.942 4.876 17.005 7.863 6.272 6.096 15.394 18.287 17.674 24.954 1.323 3.955 4.212 14.95 6.34 23.24z"
        ></path>
        <path
          fill="#9AB3DA"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M223.206 276.729s12.733 19.491 9.882 46.991h-6.218s-9.556-22.036-3.664-46.991z"
        ></path>
        <path
          fill="#6B81BE"
          d="M230.117 324.047a.328.328 0 01-.319-.282l-5.45-36.955a.326.326 0 11.646-.099l5.45 36.955a.328.328 0 01-.273.373l-.054.008z"
        ></path>
        <path
          fill="#6B81BE"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M245.708 263.646s5.435 24.635-11.023 60.066h-6.226s0-28.444 17.249-60.066z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M231.705 324.047h-.076a.33.33 0 01-.248-.268.324.324 0 01.005-.128l10.992-47.242a.325.325 0 01.146-.207.31.31 0 01.121-.044.312.312 0 01.129.007.33.33 0 01.248.268.324.324 0 01-.005.128l-10.992 47.242a.33.33 0 01-.32.244z"
        ></path>
        <path
          fill="#9AB3DA"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M267.95 263.646s3.466 26.921-28.576 60.066h-6.226s4.364-34.41 34.802-60.066z"
        ></path>
        <path
          fill="#6B81BE"
          d="M236.402 324.047a.354.354 0 01-.152 0 .331.331 0 01-.159-.193.322.322 0 01.023-.249l24.797-47.242a.321.321 0 01.322-.189.327.327 0 01.256.494l-24.798 47.242a.332.332 0 01-.289.137zM243.289 323.719s8.233-12.061 14.953-12.191c6.72-.129 2.151 7.109 2.151 7.109s4.698-2.415 7.671-1.524c2.972.892 0 4.321 0 4.321s7.792-1.776 4.751 2.285h-29.526zM269.674 307.779c-3.04 0-5.032 5.814-1.611 5.144 3.421-.671 4.652-5.144 1.611-5.144zM275.217 315.163c-2.06-.541-4.432 3.048-1.999 3.2 2.432.153 4.029-2.659 1.999-3.2z"
        ></path>
        <path
          fill="#0A1241"
          d="M-3.998 321s-.317 3.602 7.286 6.938c7.603 3.335 3.802 6.471 8.87 9.272 5.07 2.802-4.105 8.11 6.97 10.26 11.075 2.151 20.909-2.652 22.176-7.124 1.267-4.473 12.672-4.603 14.572-8.606 1.901-4.002 11.722-4.208 18.375-6.607 6.652-2.399 5.702-4.133 5.702-4.133H-3.998z"
        ></path>
        <path
          fill="#9AB3DA"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M218.821 176.387s-2.752-4.572-7.268-6.858l-1.664 1.379s6.841 8.336 7.746 18.288l1.186-12.809z"
        ></path>
        <path
          fill="#9AB3DA"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M241.962 166.626a65.285 65.285 0 00-15.964 19.049c-.935 7.619-1.764 15.056-2.281 20.801-1.459 18.059-11.593 112.908-11.593 112.908h-7.145s12.216-134.723 13.356-143.104c1.14-8.382 2.721-26.89 2.721-26.89l12.863-7.383s-1.779 5.059-2.737 8.381c-.456 1.57-1.817 10.287-3.276 20.939l9.973-9.905 4.083 5.204z"
        ></path>
        <path
          fill="#6B81BE"
          d="M237.879 161.392s3.52.099 4.09 5.242c-.008-.008-4.713-.48-4.09-5.242zM221.057 149.39s4.515.808 5.845.328c1.331-.48 7.017-7.711 7.017-7.711l-12.862 7.383zM216.176 259.281h-.061a.334.334 0 01-.212-.135.325.325 0 01-.054-.246c0-.16 2.911-16.436 3.162-23.072.19-5.159.092-19.811 0-27.652v-5.227c0-2.469.624-6.858 1.346-11.879.41-2.85.828-5.799 1.193-8.763 1.004-8.176 2.646-29.838 2.661-30.059a.326.326 0 01.35-.305.33.33 0 01.304.351c0 .221-1.657 21.891-2.661 30.09a312 312 0 01-1.193 8.77c-.715 5.029-1.338 9.364-1.338 11.787v5.22c.06 7.863.159 22.508 0 27.682-.251 6.675-3.14 22.996-3.17 23.156a.33.33 0 01-.327.282zM210.132 277.148h-.046a.325.325 0 01-.214-.13.331.331 0 01-.06-.244c0-.251 3.657-25.22 4.828-35.012 1.17-9.791 2.599-36.162 2.615-36.429a.33.33 0 01.466-.282.32.32 0 01.168.186c.015.04.021.083.019.126 0 .267-1.444 26.669-2.622 36.468-1.179 9.799-4.789 34.776-4.827 35.05a.328.328 0 01-.327.267zM211.615 318.69a.328.328 0 01-.327-.312c-.053-.998-1.254-24.611 0-31.675a142.337 142.337 0 001.901-17.525.325.325 0 01.258-.381.315.315 0 01.126-.001.313.313 0 01.254.253c.145.708-.638 10.667-1.9 17.776s0 31.286 0 31.53a.344.344 0 01-.019.127.339.339 0 01-.169.185.315.315 0 01-.124.031v-.008z"
        ></path>
        <path
          fill="#333"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M70.478 183.388l-6.302 4.481s-5.101 16.603 32.908 16.1c38.01-.503 61.636-22.539 57.934-39.332l-10.452-3.902-74.088 22.653z"
        ></path>
        <path
          fill="#6B81BE"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M121.532 152.659s48.652 28.825 49.921 30.478c1.27 1.654 3.17 10.157 3.17 11.94 0 1.783-22.425 42.799-22.425 42.799s-9.373-1.904-9.373-2.285c0-.381 10.391-39.493 10.391-39.493s-36.869-6.477-53.973-15.491-3.68-39.378 22.289-27.948z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M147.887 234.257h-.106a.326.326 0 01-.187-.164.333.333 0 01-.019-.248l11.533-34.669a.322.322 0 01.129-.168 31.025 31.025 0 003.641-2.689 39.32 39.32 0 00-6.081.114.322.322 0 01-.205-.053.33.33 0 01-.001-.549c2.281-1.524 5.991-4.229 6.333-5.174-.358-.449-4.501-.282-8.142.183a.34.34 0 01-.164-.023.323.323 0 01-.132-.099.328.328 0 01-.054-.32c.304-.815 1.011-3.109.639-3.939-.897-.968-23.345-10.233-38.215-16.215a.327.327 0 01-.182-.426.325.325 0 01.426-.183c8.917 3.589 38.009 15.376 38.564 16.55.433.967-.137 2.979-.449 3.931 2.281-.266 7.473-.762 8.226.16a.603.603 0 01.091.595c-.411 1.135-3.801 3.589-5.58 4.815 3.474-.213 5.367-.129 5.656.259a.419.419 0 01.079.191c.009.07 0 .141-.026.206-.258.655-2.98 2.491-3.953 3.139l-11.494 34.562a.327.327 0 01-.327.214z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M143.576 234.882l-2.599 14.226s5.952 1.272 5.952 1.082c0-.191 4.379-13.274 4.379-13.274l-7.732-2.034z"
        ></path>
        <path
          fill="#231F20"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M176.334 267.036c-.099 1.799-.319 3.216-.319 3.216l-20.989 1.371c-.441-1.851-1.216-3.383-2.577-3.147-1.361.237-1.756 1.593-1.817 3.277l-12.307.724s-.228-1.44-.479-3.81c-.532-4.968-1.148-14.005.099-22.097a32.088 32.088 0 0112.034.175c1.339.312 2.635.786 3.861 1.41 0 0-1.71 5.776-1.52 6.347.19.572 7.921 4.13 11.532 4.762.319.061.654.099.996.13 3.443.274 7.602-.823 9.966.51 1.581.877 1.68 4.427 1.52 7.132z"
        ></path>
        <path
          fill="#333"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M163.227 262.518c-1.11 0-12.977-3.977-14.801-5.516a.83.83 0 01-.358-.587c0-.762.715-4.922 1.315-8.244a.328.328 0 01.639.114c-.829 4.572-1.307 7.559-1.307 8.13.478.96 13.082 5.456 14.443 5.456 0 0 .167-.175.669-1.143a.324.324 0 01.323-.212.32.32 0 01.238.122c.029.036.05.078.062.123a.332.332 0 01-.045.264c-.608 1.181-.89 1.448-1.156 1.524l-.022-.031z"
        ></path>
        <path
          fill="#6B81BE"
          d="M176.334 267.037c-.099 1.798-.319 3.216-.319 3.216l-20.989 1.371c-.441-1.851-1.216-3.383-2.577-3.147-1.361.236-1.756 1.593-1.817 3.277l-12.307.724s-.228-1.441-.479-3.81l38.488-1.631z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M141.044 229.868s11.593 2.157 17.774 3.528l-4.972 9.304a36.278 36.278 0 01-14.512-2.164l1.71-10.668z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M71.442 279.205l-.19 16.672h6.842l.57-16.573-7.222-.099z"
        ></path>
        <path
          fill="#231F20"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M84.28 317.586s-1.596 1.188-3.618 2.59c-.09-1.089-.25-2.11-.562-2.11-.449 0-.6 1.783-.646 2.941-2.28 1.524-4.713 3.048-6.013 3.536-2.752.952-18.245.099-18.245.099s-.197-1.601-.266-3.688c-.106-2.881.046-6.683 1.315-8.504 2.19-3.147 7.602-3.337 7.602-3.337s4.09-8.854 4.272-9.814c.183-.96-1.14-5.806-1.14-5.806a44.5 44.5 0 0115.113-.671l-1.14 5.623s1.725 7.78 2.827 15.323c.19 1.296.365 2.576.502 3.818z"
        ></path>
        <path
          fill="#333"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M68.979 310.964a.323.323 0 01-.228-.091 6.487 6.487 0 00-3.68-1.364.327.327 0 01-.149-.563.33.33 0 01.218-.085 7.827 7.827 0 013.717 1.265l4.698-10.477a14.79 14.79 0 00-4.561-.152.33.33 0 01-.34-.285.326.326 0 01.256-.363 15.78 15.78 0 015.192.244.33.33 0 01.265.313.327.327 0 01-.029.144l-5.04 11.239a.326.326 0 01-.236.191l-.083-.016z"
        ></path>
        <path
          fill="#6B81BE"
          d="M84.282 317.585s-1.596 1.189-3.618 2.591c-.091-1.09-.25-2.111-.563-2.111-.448 0-.6 1.783-.646 2.941-2.28 1.524-4.713 3.048-6.013 3.536-2.752.952-18.244.099-18.244.099s-.198-1.6-.266-3.688c4.56.297 16.328.998 17.484.541 1.156-.457 8.27-5.509 11.403-7.726.152 1.295.327 2.575.463 3.817z"
        ></path>
        <path
          fill="#6B81BE"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M74.018 178.055s-2.03 46.228-3.04 58.671c-1.012 12.443-1.308 43.942-1.308 43.942s9.632.511 9.882-.251c.251-.762 11.95-40.384 12.46-45.718.51-5.333 19.005-47.493 21.794-62.48 2.79-14.988-32.437-30.738-39.788 5.836z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M78.101 277.339a.324.324 0 01-.289-.358c1.467-13.228 5.778-26.173 6.956-29.534-.806.427-1.923.915-2.478.671a.632.632 0 01-.373-.472c-.19-.961 1.962-5.41 2.6-6.698-4.744-6.294-4.47-6.858-4.379-7.041.092-.182.396-.762 6.789.884 8.506-41.908 12.923-63.662 12.923-63.662a.327.327 0 11.639.13s-4.432 21.906-12.984 64.005a.325.325 0 01-.403.251 35.398 35.398 0 00-6.31-1.249c.304.822 2.38 3.809 4.371 6.453a.33.33 0 010 .343c-1.376 2.759-2.736 5.928-2.63 6.454.228.145 1.52-.366 2.645-1.074a.323.323 0 01.49.194.33.33 0 01-.01.202c-.054.152-5.527 15.003-7.215 30.212a.326.326 0 01-.342.289z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M66.508 274.253l18.153.289-2.28 11.148-14.064.29s-1.049-7.056-1.81-11.727z"
        ></path>
        <path
          fill="#404B7C"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M54.384 68.462s-8.111 10.286-6.21 23.62c1.9 13.335 10.642 17.655 10.642 17.655s33.068-8.381 41.559-8.381c8.492 0 21.917 2.667 21.917 2.667s4.181-11.43 3.93-19.682c-.251-8.252-6.082-14.858-6.462-14.858-.38 0-13.554 1.775-25.717 2.537-12.163.762-39.659-3.558-39.659-3.558z"
        ></path>
        <path
          fill="#6B81BE"
          d="M60.466 107.992a.33.33 0 01-.275-.151.326.326 0 01-.021-.313c4.044-8.626 5.275-15.62 3.8-21.396-1.626-6.279-7.145-12.504-8.529-13.998-.965 1.99-5.025 10.607-5.268 15.544-.273 5.54 5.055 14.356 7.754 15.194a.673.673 0 00.669-.16c1.779-1.455 2.592-9.944 2.235-13.494-.38-3.81-3.74-9.289-4.607-9.525-.76.313-3.291 7.932-3.291 10.066 0 1.73 3.572 5.775 4.492 6.248.593-.541 1.308-5.746.852-7.498a7.51 7.51 0 00-1.483-2.713 13.69 13.69 0 00-1.01 4.153.328.328 0 01-.559.232.328.328 0 01-.095-.232c.154-1.688.58-3.34 1.261-4.892a.328.328 0 01.398-.181c.05.016.097.044.135.082a8.47 8.47 0 011.984 3.383c.41 1.578-.084 7.292-1.072 8.169a.562.562 0 01-.6.121c-1.035-.41-4.957-4.785-4.957-6.857 0-1.669 2.235-9.54 3.58-10.56a.6.6 0 01.54-.138c1.285.35 4.713 6.347 5.086 10.096.319 3.215-.32 12.306-2.47 14.066a1.326 1.326 0 01-1.278.274c-2.927-.914-8.507-9.959-8.21-15.856.289-5.715 5.321-15.803 5.55-16.23a.327.327 0 01.243-.175.32.32 0 01.28.091c.07.069 7.116 7.292 9.017 14.622 1.52 5.928.289 13.075-3.801 21.845a.327.327 0 01-.35.183z"
        ></path>
        <path
          fill="#6B81BE"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M66.28 104.526s-2.775 46.182-2.114 53.947c.662 7.764 27.367 9.326 27.367 9.326v-65.757l-25.253 2.484z"
        ></path>
        <path
          fill="#231F20"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M81.848 150.152a38.494 38.494 0 00-2.85 3.909c-.38.861-14.824 33.816-14.824 33.816s11.403 7.81 32.118 4.29c20.715-3.521 33.927-18.569 43.33-23.713 9.404-5.143 15.394-3.809 15.394-3.809s-15.105-10.759-22.136-15.24l-51.032.747z"
        ></path>
        <path
          fill="#606060"
          d="M83.52 182.192a.328.328 0 010-.655 51.058 51.058 0 0027.428-11.224c12.733-10.614 19.909-11.109 20.213-11.125a.327.327 0 01.231.56.327.327 0 01-.231.096c-.068 0-7.29.526-19.825 10.972a51.74 51.74 0 01-27.816 11.376zM87.703 171.014a.324.324 0 01-.317-.297.33.33 0 01.256-.35c.137 0 13.759-2.858 24.326-8.854a.319.319 0 01.252-.036.321.321 0 01.202.156.327.327 0 01.028.254.338.338 0 01-.163.197c-10.688 6.05-24.41 8.9-24.546 8.923l-.038.007z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          d="M115.107 57.429s-1.878-.808-2.349-.93c-.472-.122-1.209.45-.882 1.021.327.572 4.629 2.354 4.751 2.453.122.1 2.022 2.073 2.091 2.073.068 0 2.615.069 2.615 0s-6.226-4.617-6.226-4.617z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M122.71 64.774a30.825 30.825 0 01-4.051-.091c-.631-.191-2.79-2.606-3.102-2.858-.312-.251-2.06-1.204-2.22-1.615-.159-.412.981-.823 2.38.13a8.619 8.619 0 002.341 1.203l2.661.381 1.991 2.85z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M129.324 77.347l-7.161-12.634s-1.521-.632-1.84-1.203c-.319-.572-2.281-3.559-2.281-3.559s-5.952-2.888-6.302-3.238c-.349-.35-.19-1.806 1.46-1.044s7.252 2.507 8.202 3.3c.951.792 4.85 5.013 4.85 5.013l10.423 9.905-7.351 3.46z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M119.57 81.477l12.103 6.034-2.79-8 12.414-1.776s12.794 21.846 13.174 24.254c.38 2.407-.38 9.395-1.772 10.416-1.391 1.021-21.536-.823-32.748-4.252-11.213-3.429-16.535-31.439-.381-26.676z"
        ></path>
        <path
          fill="#231F20"
          d="M143.997 104.092a.339.339 0 01-.156-.041.333.333 0 01-.118-.112 145.708 145.708 0 00-10.642-14.104.326.326 0 01-.063-.231.326.326 0 01.557-.195 146.567 146.567 0 0110.643 14.18.329.329 0 01-.107.449.307.307 0 01-.114.054zM134.586 104.854a.327.327 0 01-.327-.328 88.5 88.5 0 00-.966-10.507c-.159-1.189-.266-1.974-.266-2.286a.33.33 0 01.327-.328.325.325 0 01.327.328c0 .236.114 1.09.259 2.164.535 3.51.86 7.049.973 10.599a.328.328 0 01-.327.358z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M129.324 84.524s-2.091-5.013-2.281-5.585c-.19-.571 4.242-4.952 6.971-5.844 2.729-.891 8.362 3.177 8.803 3.68a38.143 38.143 0 011.961 4.252l-1.011.953-.631-1.014s-4.371-1.013-6.712-.891c-2.342.121-5.953 3.939-5.953 3.939l.441 1.143-1.588-.633z"
        ></path>
        <path
          fill="#231F20"
          d="M131.628 83.23a.325.325 0 01-.307-.21.326.326 0 01.086-.362c1.414-1.28 3.436-2.82 4.995-2.903 1.918.023 3.829.245 5.701.663a.325.325 0 01.282.256.338.338 0 01-.052.258.326.326 0 01-.359.126 26.77 26.77 0 00-5.534-.648c-1.072.061-2.706 1.029-4.561 2.736a.334.334 0 01-.251.083z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M80.898 144.148l-1.14 6.667s17.674 6.858 32.027 6.477c14.352-.381 22.805-7.521 22.805-7.521l-.95-6.377c-16.648 7.116-34.292 6.872-52.742.754z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M136.863 137.671c.19.953-3.23 6.858-3.23 6.858a87.806 87.806 0 01-14.338 4.983c-1.269.32-2.577.61-3.907.853a43.967 43.967 0 01-9.312.831c-12.543-.381-26.987-6.667-26.987-6.667s2.28-16.001 1.52-23.431c-.76-7.429-1.9-33.244-1.9-33.244l13.303-8.763c7.412-4.571 11.023-2.285 16.436-1.142a72.346 72.346 0 0111.121 3.527s3.421 19.811 4.941 28.002c1.521 8.192 7.222 19.05 7.222 19.05s-.57 3.238-.57 3.809c0 .572 5.511 4.382 5.701 5.334z"
        ></path>
        <path
          fill="#231F20"
          d="M107.057 151.54h-.996c-8.871-.267-18.677-3.475-23.733-5.334a.33.33 0 01-.003-.582.331.331 0 01.231-.028c5.018 1.875 14.748 5.052 23.521 5.334 3.101.055 6.2-.221 9.244-.823a61.795 61.795 0 003.884-.846 89.498 89.498 0 0012.634-4.244.321.321 0 01.264-.019.324.324 0 01.217.315.335.335 0 01-.03.132.333.333 0 01-.2.174 90.124 90.124 0 01-12.71 4.244 63.21 63.21 0 01-3.93.861 45.906 45.906 0 01-8.393.816z"
        ></path>
        <path
          fill="#FF331F"
          stroke="#000"
          strokeMiterlimit="10"
          d="M110.86 77.599a1.592 1.592 0 00-.084 1.447c1.87 4.214 11.228 25.465 16.458 39.706 0 0 .7-7.871.13-12.062-.57-4.191-3.999-18.508-7.085-25.906a3.453 3.453 0 00-.16-.336v0a3.314 3.314 0 00-1.786-1.524l-5.557-1.988a1.586 1.586 0 00-1.916.662v0z"
        ></path>
        <path
          fill="#231F20"
          d="M98.566 107.521a.325.325 0 01-.32-.282c-.76-5.395-2.964-14.355-2.987-14.477a.33.33 0 01.228-.428.325.325 0 01.403.268c0 .091 2.16 8.74 2.957 14.195l.616-.068c-.289-1.524-1.467-7.62-2.448-11.346a.331.331 0 01.11-.36.326.326 0 01.521.192c1.133 4.298 2.509 11.666 2.524 11.742a.336.336 0 01-.001.134.345.345 0 01-.055.122.332.332 0 01-.225.133l-1.285.145-.038.03z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M98.574 107.193s-.471 5.57-.144 5.905c.327.335 3.185.145 3.322-.335s-1.9-5.715-1.9-5.715l-1.278.145z"
        ></path>
        <path
          fill="#231F20"
          d="M114.226 105.707a.326.326 0 01-.319-.274 107.253 107.253 0 00-2.76-11.56.332.332 0 01.092-.368.323.323 0 01.378-.034.344.344 0 01.099.09.343.343 0 01.055.122 109.576 109.576 0 012.729 11.33l.616-.083c-.449-2.012-2.745-12.268-4.204-16.428a.325.325 0 01.197-.42.326.326 0 01.418.199c1.627 4.64 4.28 16.763 4.303 16.847a.325.325 0 01-.274.396l-1.277.175-.053.008z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M114.225 105.379s-.335 5.586 0 5.906c.334.32 3.185.061 3.314-.42.129-.48-2.045-5.661-2.045-5.661l-1.269.175z"
        ></path>
        <path
          fill="#231F20"
          d="M119.296 149.512c-1.269.32-2.577.61-3.907.853l-5.07-8.503s3.04-9.906 2.98-10.096c-.061-.19-4.311-4.762-4.311-4.762s.57-5.395.51-6.797c-.061-1.402-3.467-17.319-3.482-17.967-.061-2.217-.57-5.776 1.079-2.156 1.65 3.619 5.253 18.98 5.253 19.872 0 .891-.251 6.156-.251 6.156s4.622 4.001 4.622 4.382c0 .381-2.98 10.926-2.98 10.926l5.557 8.092z"
        ></path>
        <path
          fill="#231F20"
          d="M101.995 142.693a48.24 48.24 0 01-11.456-1.402.326.326 0 01-.204-.384.326.326 0 01.355-.248c22.51 5.486 37.068-7.041 37.212-7.17a.33.33 0 01.242-.102.324.324 0 01.306.226c.014.043.019.089.014.134a.32.32 0 01-.129.229c-.121.115-10.125 8.717-26.34 8.717zM99.486 134.822a.327.327 0 01-.233-.526.328.328 0 01.195-.122c.144 0 14.344-1.821 22.182-6.187a.328.328 0 01.319.572c-7.944 4.427-22.273 6.24-22.418 6.263h-.045z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M81.5 88.654s-5.39-7.688-5.45-8.13c-.061-.442 3.671-7.3 4.69-8.061 1.019-.762 12.543-2.987 18.115-3.3 5.572-.312 11.844 1.715 12.414 1.966.57.252 3.74 2.416 3.991 3.94.251 1.524.509 13.966.509 13.966l-34.269-.38z"
        ></path>
        <path
          fill="#231F20"
          d="M94.63 87.046a.326.326 0 01-.259-.129c-.319-.42-7.875-10.271-8.324-10.904a.413.413 0 010-.411c.48-1.06 9.845-3.223 12.05-3.467 2.546-.282 7.974 1.844 10.178 2.773l.107.046c.258.107.532.214.106 10.607a.321.321 0 01-.342.312.327.327 0 01-.312-.343c.145-3.474.304-9.09.16-10.027-4.493-1.882-8.172-2.903-9.837-2.713-2.889.313-10.308 2.286-11.403 3.048 1.087 1.47 8.111 10.614 8.18 10.713a.328.328 0 01-.259.526l-.045-.03z"
        ></path>
        <path
          fill="#fff"
          d="M90.781 81.477s6.842-3.901 9.031-4.19c2.19-.29 8.362 1.79 8.362 1.79l-.41 23.834a.556.556 0 01-.381.501.554.554 0 01-.33.001.554.554 0 01-.27-.189L90.781 81.477z"
        ></path>
        <path
          fill="#FF331F"
          stroke="#000"
          strokeMiterlimit="10"
          d="M85.52 80.799a2.224 2.224 0 011.771 1.188c1.878 3.582 7.321 15.842 4.47 32.704-3.42 20.192-13.432 27.682-13.432 27.682v-13.464s5.45-10.797 5.45-21.464c0-8.854-4.188-16.763-5.61-19.232a2.413 2.413 0 01-.274-1.707v0c.12-.583.45-1.1.928-1.455l5.154-3.81a2.277 2.277 0 011.52-.435l.023-.007z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M106.365 114.66a.322.322 0 01-.304-.207.329.329 0 01-.022-.128c0-.068.091-7.261-.19-10.728a.327.327 0 01.25-.343l2.091-.473a.328.328 0 01.372.191 61.976 61.976 0 013.254 10.667.327.327 0 01-.19.366c-.061 0-1.574.648-5.253.694l-.008-.039zm.16-10.835c.221 3.132.19 8.748.175 10.173a17.373 17.373 0 004.424-.549 61.977 61.977 0 00-3.041-9.974l-1.558.35z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M105.886 107.292a23.598 23.598 0 003.991-1.044 37.704 37.704 0 012.379 7.909 40.335 40.335 0 01-6.606.671s.19-5.014.236-7.536z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M78.71 87.861s-25.367 27.05-31.259 36.574c0 0 5.99 12.763 10.361 18.196l11.692-7.139-2.942-10.477 15.204-6.477s5.435-17.693-3.056-30.677z"
        ></path>
        <path
          fill="#231F20"
          d="M59.12 125.777a25.57 25.57 0 01-7.602-1.128.326.326 0 01-.193-.4.33.33 0 01.147-.188.333.333 0 01.236-.037 25.864 25.864 0 0012.923.526c.122-.031.246-.051.372-.061a.329.329 0 01.231.559.324.324 0 01-.23.096l-.26.054a28.053 28.053 0 01-5.625.579z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M55.055 142.723s2.888-4.29 6.56-5.997a19.154 19.154 0 019.221-1.76l2.38 6.713s-3.231 4-5.892 5.714c-2.66 1.715-9.312 1.524-9.312 1.524l-2.957-6.194z"
        ></path>
        <path
          fill="#231F20"
          d="M57.569 140.041a.326.326 0 01-.315-.39.323.323 0 01.087-.166 14.837 14.837 0 014.135-3.048 20.04 20.04 0 017.298-1.806.328.328 0 010 .655c-2.44.132-4.834.724-7.055 1.745a14.234 14.234 0 00-3.915 2.926.329.329 0 01-.235.084z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M61.273 145.626s4.083-4.473 9.024-4.473a199.78 199.78 0 0010.027 18.143l-2.942 5.333a237.363 237.363 0 01-16.109-19.003v0z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M81.227 156.72s-2.805 5.433-7.176 13.433l2.95 2.476s5.89-6.476 8.84-14.378l-4.614-1.531z"
        ></path>
        <path
          fill="#404B7C"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M89.176 53.154a9.988 9.988 0 00-8.696-2.225c-5.512 1.014-7.032 6.203-3.292 9.388 3.147 2.682 1.52 5.669-1.399 5.372a1.519 1.519 0 00-1.654 1.227c-.048.248-.033.505.043.746.517 1.737 2.113 3.703 6.75 3.52 8.172-.32 8.682-9.204 3.102-11.559-5.58-2.354-2.41-6.476 3.862-4l1.284-2.469z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M91.573 52.842s-3.421-1.082-4.31.51c-.89 1.593-.89 3.62 3.1 3.49l1.21-4z"
        ></path>
        <path
          fill="#ECBDBB"
          d="M103.167 70.749l-.319 4.945-.51 7.817c-4.75 1.646-6.332-1.462-6.332-1.462l1.33-11.582.167-1.433 5.664 1.715z"
        ></path>
        <path
          fill="#404B7C"
          d="M103.165 70.749l-.319 4.945c-3.246-.907-4.85-3.75-5.489-5.227l.168-1.433 5.64 1.715zM107.628 55.479a8.402 8.402 0 01.098 5.844c-1.079 3.177-3.39-6.187-3.39-6.187l3.292.343z"
        ></path>
        <path
          fill="#ECBDBB"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M94.043 59.441a57.39 57.39 0 002.47 9.273c1.46 3.62 6.462 4.747 8.613 4.046 2.151-.7 4.751-10.271 1.391-17.38-3.36-7.11-14.063-4.633-12.474 4.061z"
        ></path>
        <path
          fill="#404B7C"
          d="M93.662 64.332l3.61-.32a18.33 18.33 0 01-.19-4.762c.32-1.714 3.041-2.095 3.422-4.19.38-2.096.129-4.443-4.12-3.75-4.25.694-5.831 6.546-2.722 13.022z"
        ></path>
        <path
          fill="#111E6C"
          stroke="#000"
          strokeMiterlimit="10"
          d="M108.227 56.271a42.755 42.755 0 00-11.752 2.507 39.189 39.189 0 00-6.302 2.888s-.95-5.143-.312-8.061c.639-2.919 7.473-6.355 11.525-6.165h.258c3.915.556 6.583 8.831 6.583 8.831z"
        ></path>
        <path
          fill="#9AB3DA"
          d="M108.227 56.271a42.752 42.752 0 00-11.753 2.507c-.137-2.286-.304-6.553.099-8.039.327-1.204 2.28-2.75 5.07-3.276 3.915.533 6.584 8.808 6.584 8.808z"
        ></path>
        <path
          fill="#404B7C"
          stroke="#272525"
          strokeMiterlimit="10"
          d="M95.746 59.044a53.058 53.058 0 015.709-5.333c2.205-1.562 7.853-2.606 13.813-1.776l-7.032 4.32s-7.252.351-12.49 2.79z"
        ></path>
        <path
          fill="#ECBDBB"
          d="M96.575 64.523a5.207 5.207 0 00-1.7-2.932 5.186 5.186 0 00-3.143-1.259 4.977 4.977 0 00-2.478.58c-3.36 1.775-.25 9.204 8.043 6.986l-.722-3.375z"
        ></path>
        <path
          fill="#404B7C"
          d="M96.028 66.81a.228.228 0 01-.213-.145c-1.962-4.74-5.557-3.81-5.717-3.75a.229.229 0 01-.26-.333.229.229 0 01.139-.108c.045 0 4.142-1.112 6.263 4.016a.23.23 0 01-.121.297l-.091.022z"
        ></path>
        <path
          fill="#404B7C"
          d="M92.584 66.543h-.06a.229.229 0 01-.16-.282c.291-1.01.7-1.981 1.216-2.896a.23.23 0 11.388.244c-.495.88-.888 1.812-1.17 2.781a.229.229 0 01-.214.153zM99.05 61.284s.761-2.194 2.433-1.592c0 0 .061-1.021.472-1.082.41-.061.532 1.768.532 1.768a8.906 8.906 0 00-3.436.906zM107.619 60.926s-.243-2.072-1.262-1.326c0 0 .061-1.006-.167-1.013-.525 0-.472 1.783-.472 1.783.68-.047 1.354.15 1.901.556zM104.555 67.068a.229.229 0 01-.106-.434c.342-.175 1.246-.762 1.269-1.326.023-.564-1.072-2.484-1.383-2.941a.23.23 0 01.233-.356c.06.011.113.046.147.097 0 0 1.52 2.286 1.467 3.215-.053.93-1.368 1.638-1.52 1.722a.232.232 0 01-.107.023zM105.442 68.935a7.6 7.6 0 01-1.566 1.173c-.943.503-2.037.67-2.463-.853a6.037 6.037 0 01-.19-1.745s1.14 1.105 4.219 1.425z"
        ></path>
        <path
          fill="#fff"
          d="M103.877 70.108c-.943.503-2.037.67-2.463-.853a2.976 2.976 0 012.463.853z"
        ></path>
        <path
          fill="#404B7C"
          d="M101.431 64.492c.483 0 .874-.484.874-1.082 0-.597-.391-1.082-.874-1.082s-.874.484-.874 1.082c0 .598.391 1.082.874 1.082z"
        ></path>
        <path
          fill="#fff"
          d="M101.621 63.258a.228.228 0 100-.457.228.228 0 000 .457z"
        ></path>
        <path
          fill="#404B7C"
          d="M106.562 64.492c.483 0 .874-.484.874-1.082 0-.597-.391-1.082-.874-1.082s-.874.484-.874 1.082c0 .598.391 1.082.874 1.082z"
        ></path>
        <path
          fill="#fff"
          d="M106.751 63.258a.23.23 0 000-.457.229.229 0 000 .457z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2946_68068">
          <path fill="#fff" d="M0 0H291V355H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DiscoveryIcon;
