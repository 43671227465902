import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 13%;
  @media (max-width: 1224px) {
    margin-top: 30%;
  }
`;

export const contentTitle = {
  padding: "1rem",
};

export const Title = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "21px",
  lineHeight: "20px",
  letterSpacing: "-0.5px",
};
