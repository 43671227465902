import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../../Forms/Label";
import { TextField, Backdrop } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "../../Buttons";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "430px",
  maxWidth: "100%",
  height: "469px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "8px",
  p: 4,
};

export default function Leasevalue({ open, handleClose, handleEligableOpen }) {
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Lease Value</div>
            <BlockSpacer mt={40} />
            <FormAligner>
              <FormLabel text={"Accessible Lease Value"} />
              <TextField
                type="text"
                InputProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                  },
                }}
              />
            </FormAligner>
            <BlockSpacer mt={10} />
            {/* <FormAligner>
              <FormLabel text={"Total Debt Weight"} />
              <TextField
                type="text"
                InputProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                  },
                }}
              />
            </FormAligner> */}
            {/* <div className={styles.text}>Check bank number for ticket ID and password sent by your bank</div> */}

            <BlockSpacer mt={10} />
            <FormAligner>
              <FormLabel text={"My Lease Value"} />
              <TextField
                type="text"
                required
                name="email"
                placeholder="Input Lease Value needed "
                InputProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                  },
                }}
              />
            </FormAligner>
            <BlockSpacer mt={20} />

            <div className={styles.button}>
              <Link to={"/lease/sole-proprietor/summary"}>
                <Button
                  color={"primary"}
                  width={240}
                  height={41}
                  //   onClick={() => handleEligableOpen()}
                >
                  Generate Repayment
                </Button>
              </Link>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
