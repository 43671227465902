import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1244px){
    margin-top: 25%;
  }
`

export const documentStyle = {
    width: '503px',
    maxWidth: '100%',
    height: '789px',
    background: 'rgba(28, 68, 141, 0.03)'
}

export const responsonive = {

    maxWidth: '100%',
    height: 'auto'
}
