import { useState } from "react";
import Spinner from "../Spinner";

export default function Button({
  width,
  height,
  color,
  borderColor,
  fontSize,
  onClick,
  borderRadius,
  marginBottom,
  isLoading,
  children,
  style,
  marginLeft,
  disabled,
  disabledColor,
  gap,
  type
}) {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type={type??"button"}
        style={{
          width,
          zIndex: 1,
          height: height || "54px",
          backgroundColor:
            color === "primary"
              ? "#111E6C"
              : color === "neutral" || color === "neutral-blue" || color === "transparent-blue"
              ? "transparent"
              : color === "tertiary"
              ? "#F2F2F2" 
              : "#55ADFF",
          color:
            color === "neutral"
              ? "#F2F2F2"
              : color === "neutral-blue"
              ? "#EF233C"
              : color === "tertiary"
              ? "#111E6C"
              : color === "transparent-blue" 
              ? "#111E6C"
              : "#ffffff",
          borderColor,
          padding: "0.5em",
          border:
            color === "neutral-blue"
              ? "1px solid #EF233C"
              : color === "neutral"
              ? "1px solid #999999"
              : "none",
          fontSize: fontSize || "inherit",
          display: "flex",
          gap: gap || "16px",
          alignItems: "center",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "27px",
          justifyContent: "center",
          borderRadius: borderRadius || "10px",
          boxShadow:
            color === "tertiary" ? "box-shadow: 0px 2px 6px 2px #EDF2FB" : "",
          cursor: isHover ? "pointer" : "default",
          marginBottom: marginBottom || "17px",
          marginLeft: marginLeft || { xs: "2rem" },
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={onClick}
        styles={style}
        disabled={disabled}
      >
        {isLoading ? <Spinner /> : children}
      </button>
    </>
  );
}
