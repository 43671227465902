export const duepaymentTitle = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    letterSpacing: '-1px',
    color: ' #333333'
}

export const nextDuePayment = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    letterSpacing: '-1px',
    color: ' #333333'
}

export const duePaymentAmount = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '-1px',
    color: '#242424'
}

export const nextDueDate = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '-1px',
    color: '#242424'  
}

