import React from "react";

function TwirlArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="406"
      height="458"
      fill="none"
      viewBox="0 0 406 458"
    >
      <path
        stroke="#F2F2F2"
        strokeWidth="4"
        d="M357 189c5.5-3.667 16.8-12.6 18-19 0 4 2 13.9 10 21.5"
      ></path>
      <path
        stroke="#F2F2F2"
        strokeLinecap="round"
        strokeWidth="4"
        d="M2 452.141c49.424 9.338 148.273 7.604 148.273-74.037.773-9.671-.926-28.614-13.9-27.013-16.218 2.001-5.792 73.036 53.285 74.037 59.078 1 143.64-73.037 184.184-250.126L375 171"
      ></path>
      <path
        fill="#F2F2F2"
        d="M384.593 4.137c.239-1.008 1.664-1.032 1.937-.033l3.045 11.115a1 1 0 00.734.71l11.213 2.664c1.007.24 1.031 1.664.033 1.937l-11.116 3.046a1 1 0 00-.709.733l-2.665 11.213c-.239 1.007-1.663 1.031-1.937.033l-3.045-11.116a1.001 1.001 0 00-.733-.709l-11.214-2.665c-1.007-.239-1.031-1.663-.032-1.937l11.115-3.045a.999.999 0 00.709-.733l2.665-11.213z"
      ></path>
      <circle cx="386" cy="20" r="3" fill="#1C448D"></circle>
    </svg>
  );
}

export default TwirlArrow;
