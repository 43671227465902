import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuccessMessage from "../../Cards/SuccessMessages";
// import { style } from "../OtpVerificationModal";
import GreenIcon from "../../IconSvgs/GreenIcon";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display:"flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function OtpSuccessVerificationModal({ open, handleClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-otp-title"
        aria-describedby="modal-otp-description"
      >
        <Box sx={style}>
          <SuccessMessage
            route={"#"}
            subTextStart={"Your Number was verified Successfully"}
            icon={<GreenIcon />}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
}
