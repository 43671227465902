import styled from "@emotion/styled";

export const Section = styled.section`
  display: flex;

  @media(max-width: 1215px) {
    .left-side {
        display: none;
    }
  }
  

`