import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, TextField, useMediaQuery } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import SignInForgotPassword from "../../GridFlex/SignInForgotPassword";
import FormAligner from "../../Hocs/FormAligner";
import Title from "../../Texts/Title";
import FormLabel from "../Label";
import style from "./style.module.css";
import { login } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/slices/authSlice";
import { useSearchParams } from "react-router-dom";

function LoginForm() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  // fetch the migrating user mail
  const [searchParams] = useSearchParams();
  const currentUser = searchParams.get("email");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    platformType: "WEB",
    platform: "CREDIT"
  });
  const { login:userData, loading } = useSelector(state => state.auth);
  const authenticated = JSON.parse(localStorage.getItem("user"));

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const logout = () => {
    localStorage.clear();
    navigate("/");
    dispatch(userLogout());
  }

  useEffect(() => {
    if(authenticated) {
      if(currentUser===null || currentUser===loggedInUser) {
        if (authenticated?.role?.name === "COMPANY" && !(authenticated?.kyc)) {
          navigate("/company/kyc");
        } else if (
          authenticated?.role?.name === "COMPANY" &&
          authenticated?.kyc
        ) {
          authenticated?.userType !== null ? 
          navigate("/dashboard") :
          navigate("/update-usertype");
        } else if (
          authenticated?.role?.name === "INDIVIDUAL_USER" &&
          !(authenticated?.kyc)
        ) {
          navigate("/user/kyc");
        } else if (
          authenticated?.role?.name === "INDIVIDUAL_USER" &&
          authenticated?.kyc
        ) {
          authenticated?.userType !== null ? 
          navigate("/dashboard") :
          navigate("/update-usertype");
        }
      } else {
        logout();
      }
    }
  },[authenticated])

  const loginUser = async () => {
    dispatch(login(formData))
  };

  const submitHandler = (e) => {
    e.preventDefault();
    loginUser();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const breakPoint = useMediaQuery("(min-width: 1215px)");

  const navigate = useNavigate();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if(e.target.name==="email") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    navigate("/user/kyc");
  };

  return (
    <div className={style.form}>
      <div className={style.alignTitle}>
        <Title text={"Login"} />
      </div>

      <BlockSpacer mt={!breakPoint ? 40 : 24} />
      <div>
        <ToastContainer />
        <FormAligner>
          <FormLabel text={"Email Address"} />
          <TextField
            placeholder="ros@gmail.com"
            type="email"
            required
            name="email"
            value={formData.email}
            onChange={handleFormChange}
          />
        </FormAligner>
        <BlockSpacer mt={30} />
        <FormAligner>
          <FormLabel text={"Password"} />
          <TextField
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
            name="password"
            value={formData.password}
            onChange={handleFormChange}
          />
        </FormAligner>
        <BlockSpacer mt={25} />
        <SignInForgotPassword />
        <div className={style.alignMiddle}>
          <Button
            color={"primary"}
            width={"328px"}
            disabled={formData.email === "" || formData.password === ""}
            onClick={submitHandler}
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Login"
            )}
          </Button>
        </div>
        <div className={style.haveAccount}>
          Don&apos;t have an account?{" "}
          <Link to="/auth/signup">
            <span className={style.signColor}>Sign up</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
