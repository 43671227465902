import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import Close from "../../../assets/close.svg";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../Spinner";
import { deleteCreditDirector, toggleDelete } from "../../../redux/slices/updateProfileSlice";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:302.91,sm:302.91,md:422.62,lg:422.62},
  maxWidth: "100%",
  height: 342,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function PaymentSuccessModal({ open, handleClose, directorId }) {
  const dispatch = useDispatch();
  const { deleteDirectorMsg, loading } = useSelector(state => state.updateProfile);

  React.useEffect(() => {
    if(deleteDirectorMsg) {
      dispatch(toggleDelete());
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDirectorMsg])
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
          width: { xs: "50px" },
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className={styles.header}>
              <img src={Close} style={{ width: "56px", height: "56px" }} alt="close" />
            </div>
            <BlockSpacer mt={70} />
            <div className={styles.text}>Are you sure you want to delete director?</div>
            <BlockSpacer mt={70} />
            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}

                onClick={() => {
                  dispatch(deleteCreditDirector(directorId));
                }}
              >
                { loading ? <Spinner /> : "Continue" }
              </Button>

              <Button
                color={"tertiary"}
                width={240}
                height={41}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
