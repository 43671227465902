import React, { useEffect, useState } from "react";
import {
  Section,
  Title,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
  requirement,
  styles,
} from "./style.js";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import FormLabel from "../../../../components/Forms/Label/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../../components/Buttons/index.jsx";
import TextField from "@mui/material/TextField";
import Leasevalue from "../../../../components/Modals/LeaseValue/index.jsx";
import TopBar from "../../../../components/GetCreditTopBar";
import {
  getLoanProducts,
  getLoanReasons,
  initializeLoanProcessing,
  getAllVehicles,
  getAllRegistrations,
} from "../../../../redux/slices/getCreditSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  formatInputWithCommas,
  numberValidity,
} from "../../../../functions/numberValidity.js";
import useGetLeaseStatusQuery from "../../../../api/services/lease/get-lease-status.api.js";
import useGetManufacturingYearsQuery from "../../../../api/services/lease/get-manufacturing-years.api.js";
import useGetLeaseBrandsQuery from "../../../../api/services/lease/get-lease-brand.api.js";
import useGetLeaseProductTypeQuery from "../../../../api/services/lease/get-lease-product-type.js";
import useGetLeaseTypeQuery from "../../../../api/services/lease/get-lease-type.api.js";
import useGetAllVendorsQuery from "../../../../api/services/lease/get-vendors.api.js";

function FinanceLease() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const erpProductId = searchParams.get("erpProductId");
  const [lease, setLease] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [tenors, setTenors] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedTenor, setSelectedTenor] = useState("");
  const [assetType, setAssetType] = useState("");
  const [leaseProduct, setLeaseProduct] = useState("");
  const [payday, setPayday] = useState("");
  const [salaryInfo, setSalaryInfo] = useState({
    netSalary: "",
    monthlyLoanObligation: "",
    worthOfVehicle: "",
  });
  const [selectedBrand, setSelectedBrand] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [vehicleLocate, setVehicleLocate] = useState("");
  const [vehicleAge, setVehicleAge] = useState("");
  const [vendor, setVendor] = useState("");

  const { users } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const userType = users?.userType;
  const { id } = useParams();

  const {
    products,
    reasons,
    loading,
    initializedLoan,
    allVehicles,
    registeredServices,
  } = useSelector((state) => state.getCredit);

  console.log(products);
  const handleLease = () => {
    setLease(true);
  };

  const handleLeaseClose = () => {
    setLease(false);
  };

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(getLoanReasons());
    dispatch(getAllVehicles());
    dispatch(getAllRegistrations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedBrand !== "" && vehicleStatus !== "") {
      let chosenBrand = allVehicles?.content?.find(
        (vehicle) => vehicle?.vehicleBrand === selectedBrand
      );
      if (vehicleStatus === "Tokunbo") {
        let age = [];
        for (var i = 1; i <= chosenBrand?.maximumTenorTokunbo; i++) {
          age.push(i === 1 ? "1 year" : `${i} years`);
        }
      } else if (vehicleStatus === "Nigerian Used") {
        let age = [];
        for (var j = 1; j <= chosenBrand?.maximumTenorNigerianUsed; j++) {
          age.push(j === 1 ? "1 year" : `${j} years`);
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedBrand, vehicleStatus]);

  useEffect(() => {
    if (!products) return;
    let product = products?.find((item) => item.id === parseInt(id));
    console.log(products);
    getRange(product?.minimumTenor, product?.maximumTenor);
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (initializedLoan) {
      handleLease();
    }
  }, [initializedLoan]);

  const getRange = (min, max) => {
    console.log(min, max);
    var range = [];
    for (var i = min; i <= max; i++) {
      range.push(i === 1 ? "1 month" : `${i} months`);
    }
    setTenors(range);
  };

  const selectedProduct = products?.find((item) => item.id === parseInt(id));

  const handleInput = (e) => {
    const { name, value } = e.target;

    setSalaryInfo({
      ...salaryInfo,
      [name]: value,
    });
  };

  const vehicleStatusOpt = ["NEW", "TOKUNBO", "NIGERIAN_USED"];
  const vehicleUsage = registeredServices?.content?.map(
    (location) => `${location?.location}-${location?.assetStatus}`
  );

  const handleSubmit = (e) => {
    console.log("submit hereereeeeeeeeeeeeeee", e);
    e.preventDefault();
    dispatch(
      initializeLoanProcessing({
        assetType,
        leaseDescription: description,
        leaseProducts: leaseProduct,
        leaseStatus: vehicleStatus,
        leaseType,
        leaseVendor: vendor,
        // existingCashBackedLoan: 0,
        // landedPropertyLocation: "string",
        // loanReasonId: null,
        // month: {
        //   apr: 0,
        //   aug: 0,
        //   dec: 0,
        //   feb: 0,
        //   jan: 0,
        //   jul: 0,
        //   jun: 0,
        //   mar: 0,
        //   may: 0,
        //   nov: 0,
        //   oct: 0,
        //   sep: 0,
        // },
        netMonthlySalary:
          userType === "CORPORATE_LISTED" || userType === "CORPORATE_UNLISTED"
            ? 0
            : parseFloat(salaryInfo.netSalary?.replace(/,/g, "")),
        otherMonthlyObligation: parseFloat(
          salaryInfo.monthlyLoanObligation?.replace(/,/g, "")
        ),
        // paymentStructure: "string",
        productId: Number(id),
        salaryPaymentDay:
          userType === "CORPORATE_LISTED" || userType === "CORPORATE_UNLISTED"
            ? 0
            : payday,
        tenor: parseInt(selectedTenor?.split(" ")[0]),
        // treasuryFundValue: 0,
        // userLoanReason: "",
        leaseBrand: selectedBrand,
        vehicleAge,
        vehicleId: allVehicles?.content?.find(
          (item) => item?.vehicleBrand === selectedBrand
        )?.id,
        vehicleRegistrationId: vehicleId,
        // worthOfLandedProperty: 0,
        worthOfVehicle: parseFloat(
          salaryInfo?.worthOfVehicle?.replace(/,/g, "")
        ),
      })
    );
  };
  console.log(vehicleId);

  const updateVehicle = (e) => {
    setVehicleLocate(e.target.value);
    let vehicleSplit = e.target.value?.split("-");
    let selectedlocate = registeredServices?.content?.find(
      (item) =>
        item?.location === vehicleSplit[0] &&
        item?.assetStatus === vehicleSplit[1]
    );
    // setVehicleStatus(selectedlocate?.assetStatus);
    setVehicleId(selectedlocate?.id);
  };
  const { data: leaseProductType } = useGetLeaseProductTypeQuery({
    productId: erpProductId,
  });
  const { data: leaseType } = useGetLeaseTypeQuery({
    productId: Number(erpProductId),
    yearOfManufacture: vehicleAge,
  });
  const { data: getLeaseStatus } = useGetLeaseStatusQuery({
    productId: erpProductId,
  });
  const leaseStatusItems = getLeaseStatus?.map((item) => item.name);

  const { data: manufacturingYears } = useGetManufacturingYearsQuery();

  const { data: getLeaseBrands } = useGetLeaseBrandsQuery();
  const leaseBrandItems = getLeaseBrands?.map((item) => item.name);

  const { data: getAllVendors } = useGetAllVendorsQuery();
  const vendorItems = getAllVendors?.map((item) => item.name);
  // console.log("Lease product type", leaseProductType);
  // console.log("Asset Usage Location", vehicleLocate);
  // console.log("Vehicle Status", vehicleStatus);
  // console.log("Asset Type", assetType);
  // console.log("description", description);
  // console.log(initializedLoan);
  // console.log(getAllVendors);
  console.log(products);

  return (
    <>
      <TopBar />
      <Section>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={Title}>
            {selectedProduct?.productName}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            (Salary Earner)
          </Typography>
        </Box>
        <Box sx={contentContent}>
          {selectedProduct?.productSummary?.split(".")?.map((item, id) => (
            <Typography sx={paragraphtitle} key={id}>
              {item}
            </Typography>
          ))}
        </Box>
        <BlockSpacer mt={20} />
        <Typography sx={requirements}>Product Request Requirements</Typography>
        <BlockSpacer mt={15} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
                marginLeft: { xs: "1rem", lg: "0rem" },
              }}
            >
              <FormAligner>
                <SelectField
                  name={"Asset Usage Location"}
                  items={vehicleUsage}
                  changeAction={(e) => updateVehicle(e)}
                  method={vehicleLocate}
                  maxWidth="100%"
                />
              </FormAligner>
              <BlockSpacer mt={10} />
              <FormAligner>
                <SelectField
                  name={"Vehicle Status"}
                  method={vehicleStatus}
                  changeAction={(e) => setVehicleStatus(e.target.value)}
                  items={leaseStatusItems}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={10} />
              <FormAligner>
                <SelectField
                  name={"Vehicle Age"}
                  method={vehicleAge}
                  changeAction={(e) => setVehicleAge(e.target.value)}
                  items={manufacturingYears}
                />
              </FormAligner>
              <BlockSpacer mt={10} />

              <FormAligner>
                <SelectField
                  name={"Vendor"}
                  method={vendor}
                  changeAction={(e) => setVendor(e.target.value)}
                  items={vendorItems}
                />
              </FormAligner>
              {(userType !== "CORPORATE_LISTED" ||
                userType !== "CORPORATE_UNLISTED") && (
                <>
                  <BlockSpacer mt={20} />
                  <FormAligner>
                    <FormLabel text={"Net Monthly Salary"} />
                    <TextField
                      placeholder="Input net month salary"
                      name="netSalary"
                      value={formatInputWithCommas(salaryInfo.netSalary)}
                      onChange={handleInput}
                      InputProps={{
                        style: {
                          // color: "grey", /*  */
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "15px",
                        },
                      }}
                    />
                    {/* {numberValidity(salaryInfo.netSalary, "Net Salary")
                        .checkBool && (
                        <span className="errorText">
                          {
                            numberValidity(salaryInfo.netSalary, "Net Salary")
                              .checkText
                          }
                        </span>
                      )} */}
                  </FormAligner>
                </>
              )}
              <BlockSpacer mt={10} />
              <FormAligner>
                <FormLabel text={"Other monthly Loan Obligation"} />
                <TextField
                  placeholder="Input Monthly Loan Obligation"
                  name="monthlyLoanObligation"
                  value={formatInputWithCommas(
                    salaryInfo.monthlyLoanObligation
                  )}
                  onChange={handleInput}
                  InputProps={{
                    style: {
                      // color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "15px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={10} />

              <FormAligner>
                <FormLabel text={"Lease Type"} />
                <TextField
                  placeholder="Type"
                  name="Lease Type"
                  value={leaseType ?? ""}
                  InputProps={{
                    style: {
                      // color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "15px",
                    },
                  }}
                  disabled
                />
              </FormAligner>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Box
              sx={{
                width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
                marginLeft: { xs: "1rem", lg: "0rem" },
              }}
            >
              <FormAligner>
                <SelectField
                  name={"Asset Type"}
                  method={assetType}
                  items={["VEHICLE"]}
                  changeAction={(e) => setAssetType(e.target.value)}
                  asterisk
                />
              </FormAligner>

              <BlockSpacer mt={10} />
              <FormAligner>
                <SelectField
                  name={"Vehicle Brand"}
                  items={leaseBrandItems}
                  changeAction={(e) => setSelectedBrand(e.target.value)}
                  method={selectedBrand}
                  label="Select Brand"
                />
              </FormAligner>
              <BlockSpacer mt={10} />

              <FormAligner>
                <SelectField
                  name={"Lease Products"}
                  method={leaseProduct}
                  items={leaseProductType?.map((item) => item.name) ?? []}
                  changeAction={(e) => setLeaseProduct(e.target.value)}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={10} />
              <FormAligner>
                <FormLabel text={"Description"} />
                <TextField
                  placeholder="Lease Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  InputProps={{
                    style: {
                      // color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "15px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={10} />
              <FormAligner>
                <FormLabel text={"Vehicle's Worth"} />
                <TextField
                  placeholder="0"
                  name="worthOfVehicle"
                  value={formatInputWithCommas(salaryInfo.worthOfVehicle)}
                  onChange={handleInput}
                  InputProps={{
                    style: {
                      // color: "grey",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "15px",
                    },
                  }}
                />
              </FormAligner>
              <BlockSpacer mt={10} />

              {(userType !== "CORPORATE_LISTED" ||
                userType !== "CORPORATE_UNLISTED") && (
                <>
                  <BlockSpacer mt={20} />
                  <FormAligner>
                    <SelectField
                      name={"Salary Payment Day"}
                      items={[...Array(31).keys()].map((item) => item + 1)}
                      method={payday}
                      changeAction={(e) => setPayday(e.target.value)}
                    />
                  </FormAligner>
                </>
              )}
              <BlockSpacer mt={10} />
              <FormAligner>
                <SelectField
                  name={"Tenor"}
                  method={selectedTenor}
                  changeAction={(e) => setSelectedTenor(e.target.value)}
                  items={tenors}
                  asterisk
                />
              </FormAligner>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <BlockSpacer mt={80} />
      <Paper
        elevation={0.5}
        sx={{
          height: { xs: "15%", lg: "10%" },
          width: { xs: "92%", sm: "100%", lg: "87%" },
          margin: "1rem",
          maxwidth: "100%",
          boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
            padding: "1rem",
            maxWidth: "100%",
          }}
        >
          <Link to="/lease">
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              Back
            </Button>
          </Link>

          <Buttons
            color="primary"
            width="328px"
            // onClick={() => handleLease()}
            onClick={(e) => {
              handleSubmit(e);
              console.log("wprlogm");
            }}
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Profile Me"
            )}
          </Buttons>
        </Box>
      </Paper>
      {lease && <Leasevalue open={lease} handleClose={handleLeaseClose} />}
    </>
  );
}

export default FinanceLease;
