import React from "react";
import Button from "../../Buttons";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import useScreenSize from "../../ScreenSize";

function SuccessMessage({
  icon,
  title,
  subTextStart,
  subTextHighlighted,
  subTextEnd,
  route,
  handleClose,
}) {
  const functionPass = () => {
    return;
  };
  const { width } = useScreenSize();
  return (
    <div className={style.container}>
      <BlockSpacer mt={10} />
      <div className={style.icon}>{icon}</div>
      {title && (
        <>
          <BlockSpacer mt={10} />
          <div className={style.title}>{title}</div>
        </>
      )}

      <BlockSpacer mt={10} />
      <div className={style.subText}>
        {subTextStart}{" "}
        <span className={style.highlighted}>{subTextHighlighted} </span>
        {subTextEnd}
      </div>
      <BlockSpacer mt={20} />
      <Link to={route ? route : "/"}>
        <Button
          color={"primary"}
          width={width <= 700 ? "210px" : "328px"}
          onClick={handleClose ? () => handleClose() : () => functionPass()}
        >
          Continue
        </Button>
      </Link>
      <BlockSpacer mt={54} />
    </div>
  );
}

export default SuccessMessage;
