import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetAllVendorsQuery = () => {
  const getAllVendors = async () => {
    const request = await axiosInstance.get("/users/vendors");
    const response = request.data;
    return response;
  };
  const query = useQuery(["VENDORS"], getAllVendors);
  return query;
};

export default useGetAllVendorsQuery;
