import React, { useEffect, useState } from "react";
import {
  Section,
  titleContainer,
  contentContent,
  paragraphtitle,
  requirements,
} from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormAligner from "../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../components/Core/SelectField/index.jsx";
import Buttons from "../../../components/Buttons/index.jsx";
import FormLabel from "../../../components/Forms/Label/index.jsx";
import TextField from "@mui/material/TextField";
import NoteModal from "../../../components/Modals/NoteModal/index.jsx";
import ApproveBankModal from "../../../components/Modals/ApproveBankModal/index";
import FailureModal from "../../../components/Modals/FailureModal/index";
import LoanValueModal from "../../../components/Modals/LoanValueModal/index.jsx";
import TopBar from "../../../components/GetCreditTopBar";
import {
  getLoanProducts,
  getLoanReasons,
  initializeLoanProcessing,
  cancelInitialization,
  cancelLoanValue,
  cancelRepayments,
  clearLoan
} from "../../../redux/slices/getCreditSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { getBankDetails } from "../../../redux/slices/profileSlice.js";
import { ClipLoader } from "react-spinners";

export default function QuickLoan() {
  const [tenors, setTenors] = useState([]);
  const [selectedTenor, setSelectedTenor] = useState("");
  const [userLoanReason, setUserLoanReason] = useState("");

  const [method, setMethod] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { products, reasons, loading, initializedLoan } = useSelector(
    (state) => state.getCredit
  );
  const { bankDetails, bankDetailsError } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getLoanProducts());
    dispatch(getBankDetails());
    dispatch(getLoanReasons());
    // eslint-disable-next-line
  }, []);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const [openNote, setNoteOpen] = useState(false);
  const handleNoteOpen = () => setNoteOpen(true);
  const handleNoteClose = () => setNoteOpen(false);

  //  Approve bank verifcation state
  const [openAroveBank, setApproveBankOpen] = useState(false);
  const handleformInfoOpen = () => {
    setApproveBankOpen(true);
    setNoteOpen(false);
  };
  const handleformInfoClose = () => setApproveBankOpen(false);

  const reasonOpt = reasons ? reasons?.map((item) => item.loanReason) : [];
  const reasonOptions = [...reasonOpt, "Others"];

  // falure elageble for loan
  const [openEligableOpen, setEligableOpen] = useState(false);
  const handleEligableOpen = () => {
    setEligableOpen(true);
    setApproveBankOpen(false);
  };
  const handleEligableClose = () => setEligableOpen(false);

  useEffect(() => {
    let product = products?.find((item) => item.id === parseInt(id));
    getRange(product?.minimumTenor, product?.maximumTenor);
    // eslint-disable-next-line
  }, [products]);

  // check if you are eligible
  const [openLoan, setLoan] = useState(false);
  const handleLoanOpen = () => {
    setLoan(true);
    setEligableOpen(false);
  };
  const handleLoanClose = () => setLoan(false);

  const getRange = (min, max) => {
    var range = [];
    for (var i = min; i <= max; i++) {
      range.push(i === 1 ? "1 day" : `${i} days`);
    }
    setTenors(range);
  };

  useEffect(() => {
    if (initializedLoan) {
      handleNoteOpen();
    }
  }, [initializedLoan]);

  const selectedProduct = products?.find(
    (item) => item.id === parseInt(id)
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      initializeLoanProcessing({
        assetType: "string",
        existingCashBackedLoan: 0,
        landedPropertyLocation: "string",
        loanReasonId:
          method === "Others"
            ? null
            : reasons?.find((item) => item.loanReason === method).id,
        month: {
          apr: 0,
          aug: 0,
          dec: 0,
          feb: 0,
          jan: 0,
          jul: 0,
          jun: 0,
          mar: 0,
          may: 0,
          nov: 0,
          oct: 0,
          sep: 0,
        },
        netMonthlySalary: 0,
        otherMonthlyObligation: 0,
        paymentStructure: "string",
        productId: parseInt(id),
        salaryPaymentDay: 0,
        tenor: parseInt(selectedTenor?.split(" ")[0]),
        treasuryFundValue: 0,
        userLoanReason: userLoanReason,
        vehicleBrandDetails: "string",
        vehicleId: 0,
        vehicleRegistrationId: 0,
        worthOfLandedProperty: 0,
        worthOfVehicle: 0,
      })
    );
  };

  const cancelLoan = () => {
    navigate("/get-credit/loans")
    dispatch(cancelInitialization());
    dispatch(cancelLoanValue());
    dispatch(cancelRepayments());
    dispatch(clearLoan());
  }

  return (
    <>
      <TopBar />
      <Section>
        <Typography
          variant="h6"
          sx={titleContainer}
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "22px",
            letterSpacing: "-0.5px",
          }}
        >
          {selectedProduct?.productName}
        </Typography>
        <Box sx={contentContent}>
          {selectedProduct?.productSummary?.split(".")?.map((item, id) => (
            <Typography sx={paragraphtitle} key={id}>
              {item}
            </Typography>
          ))}
        </Box>
        <BlockSpacer mt={20} />
        <Typography sx={requirements}>Product Request Requirements</Typography>
        <BlockSpacer mt={20} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
              }}
            >
              <FormAligner>
                <FormLabel text={"Bank Account"} />
                <TextField
                  placeholder="Bank Account"
                  type="text"
                  required
                  name="Bank Account"
                  value={
                    bankDetails
                      ? `${bankDetails?.accountName} - ${bankDetails?.accountNumber}`
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                {bankDetailsError && (
                  <Typography sx={{ color: "#ff0000", fontSize: "10px" }}>
                    Bank Account is not available for this user
                  </Typography>
                )}
              </FormAligner>

              <BlockSpacer mt={20} />
              <FormAligner>
                <SelectField
                  name={"Reason for Loan"}
                  items={reasonOptions}
                  changeAction={changeAction}
                  method={method}
                  asterisk
                />
              </FormAligner>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <BlockSpacer mt={10} />
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                maxWidth: "100%",
                padding: "1rem",
              }}
            >
              <FormAligner>
                <SelectField
                  name={"Tenor"}
                  method={selectedTenor}
                  changeAction={(e) => setSelectedTenor(e.target.value)}
                  items={tenors}
                  asterisk
                />
              </FormAligner>
              <BlockSpacer mt={15} />

              {method === "Others" && (
                <FormAligner>
                  <FormLabel text={"Input Reason here"} asterisk />

                  <TextField
                    placeholder="State reason here"
                    InputProps={{
                      style: {
                        color: "grey",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "15px",
                      },
                    }}
                    value={userLoanReason}
                    onChange={(e) => setUserLoanReason(e.target.value)}
                  />
                </FormAligner>
              )}
            </Box>
          </Grid>
        </Grid>
      </Section>
      <BlockSpacer mt={80} />
      <Paper
        elevation={0.5}
        sx={{
          height: { xs: "15%", lg: "10%" },
          width: { xs: "92%", sm: "100%", lg: "87%" },
          margin: "1rem",
          maxwidth: "100%",
          boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
            padding: "1rem",
            maxWidth: "100%",
          }}
        >
          <Button
            sx={{
              background: "#F2F2F2",
              width: "328px",
              height: "54px",
              color: "#111E6C",
              marginBottom: "1rem",
              textTransform: "capitalize",
              fontWeight: "600",
              fontFamily: "Montserrat",
              fontSize: "18px",
            }}
            type="button"
            onClick={cancelLoan}
          >
            Back
          </Button>

          <Buttons
            color="primary"
            width="328px"
            onClick={handleSubmit}
            disabled={
              bankDetailsError ||
              method === "" ||
              selectedTenor === "" ||
              loading
            }
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Request"
            )}
          </Buttons>
        </Box>
      </Paper>
      {openNote && (
        <NoteModal
          open={openNote}
          handleClose={handleNoteClose}
          handleformInfoOpen={handleformInfoOpen}
        />
      )}
      {openAroveBank && (
        <ApproveBankModal
          open={openAroveBank}
          handleEligableOpen={handleEligableOpen}
          handleClose={handleformInfoClose}
          handleLoanOpen={handleLoanOpen}
        />
      )}

      {openEligableOpen && (
        <FailureModal
          open={openEligableOpen}
          handleLoanOpen={handleLoanOpen}
          handleClose={handleEligableClose}
        />
      )}

      {openLoan && (
        <LoanValueModal open={openLoan} handleClose={handleLoanClose} />
      )}

      <BlockSpacer mt={20} />
    </>
  );
}
