import axiosInstance from "../../../utils/axiosInstance";
import { QueryClient, useMutation } from "react-query";
const useMarkReadNotificationsQuery = () => {
  const markReadNotifications = async () => {
    const request = await axiosInstance.put(
      `/notification?platform=CREDIT`,
      {}
    );

    const response = request.data;
    return response;
  };
  const queryClient = new QueryClient();
  const query = useMutation({
    mutationFn: markReadNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["NOTIFICATIONS"] });
    },
  });
  return query;
};

export default useMarkReadNotificationsQuery;
