import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import * as React from "react";
import styles from "./style.module.css";
// import OtpInput from "react-otp-input";
import { toast, ToastContainer } from "react-toastify";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 310, sm: 310, md: 416 },
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};
// const otpStyle = {
//     width: "56px",
//     margin: "0 auto",
//     background: "#FFF",
//     height: "56px",
//     border: "1px solid #E0E0E0",
//     borderRadius: "3px",
// };

// const otpFocusedStyle = {
//     border: "2px solid #E0E0E0",
// };

export default function BvnSuccessVerifcation({
  open,
  handleClose,
  handleChange,
}) {
  const BVNData = JSON.parse(localStorage.getItem("BVNDATA"));
  const notify = () => toast("BVN data updated");
  const [loading, setLoading] = React.useState(false);

  const UpdateKYC = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.put(
        "https://api-credit.optisoft.com.ng:8090/auth/users/update-first-last-name",
        {
          firstName: BVNData?.data?.firstName,
          lastName: BVNData?.data?.lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "this is bvn uppdate");
      if (response.status === 200) {
        localStorage.setItem("BVNDATA", JSON.stringify(response?.data));
        setLoading(false);
        if (handleChange) {
          handleChange(BVNData?.data?.firstName, BVNData?.data?.lastName);
        }
        notify();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.header}>BVN Verification</div>
          <BlockSpacer mt={31} />
          <div className={styles.text}>
            Your name on our system will be updated with{" "}
            {BVNData?.data?.lastName} {BVNData?.data?.firstName}{" "}
            {BVNData?.data?.middleName} to reflect exactly as it appears on your
            BVN
          </div>
          <BlockSpacer mt={35} />

          <div className={styles.button}>
            <BlockSpacer mt={35} />
            <Button
              color={"primary"}
              width={164}
              height={41}
              // onClick={() => handleClose()}
              onClick={() => UpdateKYC()}
            >
              {loading ? "Verifying..." : "OK"}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
