import HttpService from "./HttpService";

export const getLoanProductByTargetMarketService = () => {
  const http = new HttpService();
  const url = `/users/access-loan-product`;
  return http.getData(url);
};

export const getLoanProductService = () => {
  const http = new HttpService();
  const url = `/users/get-all-credit-product`;
  return http.getData(url);
};

export const getLoanReasonService = () => {
  const http = new HttpService();
  const url = `/users/get-loan-reasons`;
  return http.getData(url);
};

export const initializeLoanProcessingService = (formData) => {
  const http = new HttpService();
  const url = `/users/initialize-loan-processing`;

  return http.postDataWithToken(formData, url);
};

export const getLoanValueService = (formData) => {
  const http = new HttpService();
  const url = `/users/get-loan-value`;

  return http.postDataWithToken(formData, url);
};

export const generateRepaymentService = (formData) => {
  const http = new HttpService();
  const url = `/users/generate-repayment`;

  return http.postDataWithToken(formData, url);
};

export const acceptLoanOfferService = (formData) => {
  const http = new HttpService();
  const url = `/users/accept-loan-offer`;

  return http.postDataWithToken(formData, url);
};

export const loanRepaymentSetupService = (formData) => {
  const http = new HttpService();
  const url = `/users/complete-loan-repayment-setup`;

  return http.postDataWithToken(formData, url);
};

export const submitLoanProcessingService = (formData) => {
  const http = new HttpService();
  const url = `/users/submit-loan-processing`;

  return http.postDataWithToken(formData, url);
};

export const testDebitCardService = (formData) => {
  const http = new HttpService();
  const url = `/initialize-card-testing/credit`;
  return http.postDataWithToken(formData, url);
};

export const getAllVehicleService = () => {
  const http = new HttpService();
  const url = `/users/get-all-vehicle-registration`;
  return http.getData(url);
};

export const generateBankStatementService = (formData) => {
  const http = new HttpService();
  const url = `/users/request-bank-statement`;
  return http.postDataWithToken(formData ,url);
};

export const getRequiredDocService = (transId) => {
  const http = new HttpService();
  const url = `/users/${transId}/document_checklist`;
  return http.getData(url);
};

export const uploadRequiredDocService = (formData) => {
  const http = new HttpService();
  const url = `/users/submit-loan-requirement`;
  return http.postDataWithToken(formData ,url);
};

export const getAllRegistrationService = () => {
  const http = new HttpService();
  const url = `/users/get-all-registration`;
  return http.getData(url);
};

export const generateMonoAccIdService = (formData) => {
  const { loanId, ...code } = formData;  
  const http = new HttpService();
  const url = `/mono/5/exchange-token?loanId=${loanId}`;
  return http.postDataWithToken(code, url);
};

export const fetchMonoAccDetService = (formData) => {
  const { loanId, accountId } = formData;  
  const http = new HttpService();
  const url = `/mono/6/fetch-account-details?accountId=${accountId}&loanId=${loanId}`;
  return http.postDataWithToken({}, url);
};

export const fetchMonoAccStatementService = (formData) => {
  const { loanId } = formData;  
  const http = new HttpService();
  const url = `/mono/7/fetch-account-statement?usePDF=true&loanId=${loanId}`;
  return http.postDataWithToken({}, url);
};

export const receiveMonoWebhookService = (formData) => {
  const http = new HttpService();
  const url = `/mono/8/get-mono-web-hook-notification`;
  return http.postDataWithToken(formData, url);
};

export const reauthorizeMonoService = (formData) => {
  const { accountId } = formData;
  const http = new HttpService();
  const url = `/mono/9/reauthorize-mono-account?accountId=${accountId}`;
  return http.postDataWithToken({}, url);
};

export const getExistingCashBackedService = () => {
  const http = new HttpService();
  const url = `/users/existing_cash_backed_loan`;
  return http.getData(url);
};

export const getTreasuryFundsValueService = () => {
  const http = new HttpService();
  const url = `/users/get-treasury-funds-value`;
  return http.postDataWithToken({}, url);
};
