export const cardBox = {
  width: { sx: "100%", sm: "100%", md: "100%", lg: "414.49px" },
  height: "auto",
  minHeight: "674.07px",
  margin: { xs: "1rem" },
  maxWidth: "100%",
  boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
};

export const summaryTitle = {
  fontFamily: "Montserrat",
  fontSize: "17px",
  fontWeight: "600",
  lineHeight: "27px",
  padding: "1.2rem",
};

export const currencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "13.2925px",
  lineHeight: "16px",
  letterSpacing: "-0.01em",
  color: "#242424",
};

export const secondCurrencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  textAlign: 'right',
  letterSpacing: "-0.181262px",
  color: "#242424",
  marginTop: "1rem",
};

export const firstCurrencyStyle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  letterSpacing: "-0.181262px",
  color: "#242424",
  marginTop: "1rem",
};

export const currencyBlue = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13.2925px",
  letterpSacing: "-0.181262px",
  color: "#111E6C",
  marginTop: "1rem",
};

export const currencyBlueRight = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  textAlign: 'right',
  fontSize: "13.2925px",
  letterpSacing: "-0.181262px",
  color: "#111E6C",
  marginTop: "1rem",
};
