import React from "react";
import style from "./style.module.css";
import Box from "@mui/material/Box";
import SuccessMessage from "../../../components/Cards/SuccessMessages";
import SuccessGreenIcon from "../../../components/IconSvgs/SuccessGreenIcon";

function ResetLinkSuccess() {
  const email = localStorage.getItem("resetPasswordMail");
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <Box
          sx={{
            minWidth: 400,
            maxWidth: 792,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 30px rgba(225, 234, 254, 0.62)",
            borderRadius: "20px",
          }}
        >
          <SuccessMessage
            subTextStart={"Password reset link has been sent to"}
            subTextHighlighted={email}
            icon={<SuccessGreenIcon />}
            route={"/"}
          />
        </Box>
      </div>
    </div>
  );
}

export default ResetLinkSuccess;
