import React from "react";
import styles from "./style.module.css";

const Spinner = ({ big }) => {
  return (
    <div
      className={big ? `${styles.loading} ${styles.big}` : styles.loading}
      data-testid="spinner"
    ></div>
  );
};

export default Spinner;
