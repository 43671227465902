import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetPendingTransactionsQuery = () => {
  const getPendingTransactions = async () => {
    const request = await axiosInstance.get(
      `/auth/my-credit/transactions/pending`
    );
    const response = request.data;
    return response;
  };
  const query = useQuery(["PENDING_TRANSACTIONS"], getPendingTransactions);
  return query;
};

export default useGetPendingTransactionsQuery;
