import React, { useEffect } from "react";
// import DashboardLayout from "../../components/FeedbackDashboardLayout";
import * as yup from "yup";
import { useState } from "react";
import { Section, inputError } from "./style";
import { Card, FormControl, FormLabel, InputBase } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Formik, Form } from "formik";
import { Divider } from "@mui/material";
import CardContent from "@material-ui/core/CardContent";
import LockIcon from "../../components/Assets/icons/Lock.svg";
import { getTicketsReplyById } from "../../services/feedbackServices";
import moment from "moment/moment";

import { postTicketsReply } from "../../services/feedbackServices";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@mui/system";
import BlockSpacer from "../../components/BlockSpacer";
import { useMyTicketsData } from "../../hooks/use-my-tickets";
import { useTicketReplyData } from "../../hooks/use-reply-tickets";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { feedbackSchema } from "../../components/schemas";
import TopBar from "../../components/FeedBackTopBar";

function TicketDetails() {
  // const [notification, setNotification] = useState(false);

  // const [openNotification, setOpenNotification] = useState(false);

  // const handleOpenNotification = () => {
  //   setOpenNotification(true);
  //   setNotification(false);
  // };
  // const closeNotify = () => {
  //   setOpenNotification(false);
  // };

  // const handleNotification = () => {
  //   setNotification(true);
  // };
  // const handleNotificationClose = () => {
  //   setNotification(false);
  // };

  const {
    values,
    errors,
    dirty,
    touched,
    handleBlur,
    isSubmitting,
    isValid,
    getFieldProps,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      messageReply: "",
    },
    validationSchema: feedbackSchema,
    onSubmit: async (values, actions) => {
      console.log("Form data", values);
      console.log("submitted");
      console.log("Submit props", actions);

      setPostData(values);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      actions.resetForm();
    },
  });

  // console.log("Form data", values)

  const initialValues = {
    messageReply: "",
  };

  const validationSchema = yup.object().shape({
    messageReply: yup.string().required("required"),
  });

  // const onSubmit = (values, onSubmitProps) => {
  //   console.log("submitted");
  //   console.log("Form data", values);
  //   console.log("Submit props", onSubmitProps);

  //   onSubmitProps.setSubmitting(false)

  //   setPostData(values)

  //   onSubmitProps.resetForm();
  // };

  const postTicketReplied = async () => {
    try {
      const res = await postTicketsReply(replyData);

      if (res) {
        console.log("This is the response of the post data", res?.data);
      }
    } catch (error) {
      console.log("The Error", error?.message);
    }
  };

  const [postData, setPostData] = useState("");

  const handleInput = (e) => {
    setPostData(e.target.value);
  };

  const getTicketsReply = async (ticketid) => {
    try {
      setLoading(true);

      const response = await getTicketsReplyById(ticketid);

      if (response) {
        // console.log("This is my response", response?.data)

        setLoading(false);

        let resp = response?.data;
        let newResp = resp?.reverse();

        setReply(newResp?.sort((a, b) => a?.createdAt > b?.createdAt));
      }
    } catch (error) {
      console.log("The reply ticket error", error?.message);
      setErr(error?.message);
    }
  };

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(" ");
  const [reply, setReply] = useState([]);

  //let sortedItems = reply.sort((a,b) => Date.parse(new Date(a.createdAt?.split("/").reverse().join("-"))) - Date.parse(new Date(b.createdAt?.split("/".reverse().join("-")))));

  //let sortedItems1 = reply?.sort((a, b) => new Date(...a?.createdAt?.split('-')?.reverse()) - new Date(...b?.createdAt?.split('-')?.reverse()));

  useEffect(() => {
    getTicketsReply(ticketid);
  });

  const { ticketid } = useParams();

  const { isLoading, data, isError, error } = useMyTicketsData(ticketid);
  if (isLoading) {
    return (
      <div
        style={{
          fontFamily: "Montserrat",
          fontWeight: "400",
          fontSize: "20px",
          letterSpacing: "-0.03em",
        }}
      >
        Loading ...
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          fontFamily: "Montserrat",
          fontWeight: "400",
          fontSize: "20px",
          letterSpacing: "-0.03em",
          color: "red",
        }}
      >
        Error: {error?.message + ":("}
      </div>
    );
  }

  const replyData = {
    content: postData,
    images: [],
    platform: "CREDIT",
    ticketId: data?.data?.id,
    title: data?.data?.title,
  };

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box sx={{ marginTop: { xs: "4rem",lg:"1rem" }, marginLeft: { xs: "1rem" } }}>
            <Box style={{ position: "relative" }}>
              <Box sx={{maxHeight:{xs:"80vh",md:"60vh"}, overflowY:"scroll"}} >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "22px",
                      lineHeight: "150%",
                      letterSpacing: "-0.15px",
                    }}
                  >
                    Title
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "16px",
                      letterSpacing: "-0.01em",
                      marginTop: "1rem",
                    }}
                  >
                    {data?.data.title}
                  </Typography>
                </Box>
                <BlockSpacer mt={20} />
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "22px",
                      lineHeight: "161.9%",
                      letterSpacing: "-0.15px",
                    }}
                  >
                    Message
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "161.9%",
                      letterSpacing: "-0.01em",
                      width: {xs:"80%",md:"57%"},
                      marginTop: "1rem",
                    }}
                  >
                    {data?.data.message}
                  </Typography>
                </Box>
                <BlockSpacer mt={20} />

                <Box sx={{ marginLeft: "1.5rem" }}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "22px",
                      lineHeight: "150%",
                      letterSpacing: "-0.15px",
                    }}
                  >
                    Conversations
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "161.9%",
                      letterSpacing: "-0.15px",
                      width: {xs:"80%",md:"57%"},
                      marginTop: "1rem",
                    }}
                  >
                    {reply?.map((item) => (
                      <div key={item.id}>
                        {item.replyType === "USER_REPLY" ? (
                          <div>
                            <h4>Your Message</h4>
                            <p>{item?.content}</p>

                            <small>{item?.createdAt}</small>
                          </div>
                        ) : (
                          <div>
                            <h4>Admin</h4>
                            <p>{item?.content}</p>
                            <small>{item?.createdAt}</small>
                          </div>
                        )}
                      </div>
                    ))}
                  </Typography>
                </Box>
                <BlockSpacer mt={250} />
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                //validateOnMount
              >
                <Form
                  sx={{
                    width: { xs: "100%", md: "70%" },
                    height: "53px",
                    position: "fixed",
                    bottom: 0,
                    backgroundColor: "#FFF",
                    maxWidth: { xs: "100%", md: "70%" },
                  }}
                  onSubmit={handleSubmit}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "100%", md: "80%" },
                      maxWidth: "100%",
                      justifyContent: { md: "space-between" },
                      gap: "4px",
                      marginLeft: { xs: 0, md: "2rem" },
                    }}
                  >
                    <TextField
                      placeholder="Type your message"
                      name="messageReply"
                      id="reply"
                      value={values.messageReply}
                      InputProps={{
                        style: {
                          color: "grey",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "15px",
                        },
                      }}
                      onBlur={handleBlur}
                      error={
                        errors.messageReply && touched.messageReply
                          ? inputError
                          : ""
                      }
                      helperText={
                        errors.messageReply &&
                        touched.messageReply &&
                        errors.messageReply
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleInput(e);
                      }}
                      style={{
                        width: "80%",
                        height: "53px",
                        borderRadius: "5px",
                        background: "#F8F8F8",
                      }}
                    />
                    <Button
                      type="submit"
                      sx={{
                        width: "20%",
                        height: "53px",
                        background: "#F2F2F2",
                        color: "#BDBDBD",
                        letterSpacing: "-0.01em",
                        fontWeight: "400",
                        fontFamilly: "Montserrat",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        lineHeight: "13px",
                        borderRadius: "15px",
                        marginLeft: { xs: "1rem", md: "2rem" },
                        marginRight: { xs: "1rem", md: 0 },
                      }}
                      onClick={() => {
                        postTicketReplied(replyData);
                      }}
                      disabled={!isValid || isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Box>
          </Box>
          <BlockSpacer />
        </Section>
      </main>
    </>
  );
}

export default TicketDetails;
