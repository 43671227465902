import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { 
  verifyPhoneService,
  validatePhoneOtpService,
  updatePersonalDocsService,
  verifyAccountNoService,
  updateBankDetailsService,
  changeUserPasswordService,
  updateCompanyDocsService,
  updateCreditDirectorService,
  getCreditDirectorService,
  deleteDirectorService,
  updateBusinessBankDetailsService,
  getErpEmployersService,
  getErpIndustriesService
} from "../../services/updateProfileServices";

const initialState = {
  loading: false,
  verifyloading: false,
  phoneMsg: null,
  phoneMsgError: null,
  validatePhone: null,
  validatePhoneError: null,
  showPhoneOtpModal: false,
  contactMsg: null,
  contactMsgError: null,
  personalInfoMsg: null,
  personalInfoMsgError: null,
  infoSuccess: false,
  accountDetail: null,
  accountDetailError: null,
  showAccountValidErrorModal: false,
  passChangeMsg: null,
  passChangeError: null,
  docMsg: null,
  docMsgError: null,
  companyInfoMsg: null,
  companyInfoError: null,
  companyDocMsg: null,
  companyDocMsgError: null,
  directorMsg: null,
  directorMsgError: null,
  directors: null,
  directorsError: null,
  deleteDirectorMsg: false,
  deleteDirectorError: null,
  bankUpdateMsg: null,
  bankUpdateError: null,
  businessBankUpdateMsg: null,
  businessBankUpdateErr: null,
  erpEmployers: null,
  erpEmployersErr: null,
  erpIndustries: null,
  erpIndustriesErr: null
};

export const verifyPhone = createAsyncThunk('updateProfile/verifyPhone', 
(formData, { rejectWithValue }) => {
  return verifyPhoneService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const validatePhoneOtp = createAsyncThunk('updateProfile/validatePhoneOtp',
(formData, { rejectWithValue }) => {
  return validatePhoneOtpService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
})

export const updatePersonalDocs = createAsyncThunk('updateProfile/updatePersonalDocs',
(formData, { rejectWithValue }) => {
  return updatePersonalDocsService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
})

export const verifyAccountNo = createAsyncThunk('updateProfile/verifyAccountNo', 
(formData, { rejectWithValue }) => {
  return verifyAccountNoService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const updateBankdetails = createAsyncThunk('updateProfile/updateBankdetails', 
(formData, { rejectWithValue }) => {
  return updateBankDetailsService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const updateBusinessBankdetails = createAsyncThunk('updateProfile/updateBusinessBankdetails', 
(formData, { rejectWithValue }) => {
  return updateBusinessBankDetailsService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const changeUserPassword = createAsyncThunk('updateProfile/changeUserPassword', 
(formData, { rejectWithValue }) => {
  return changeUserPasswordService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const updateCompanyDocs = createAsyncThunk('updateProfile/updateCompanyDocs', 
(formData, { rejectWithValue }) => {
  return updateCompanyDocsService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const updateCreditDirector = createAsyncThunk('updateProfile/updateCreditDirector', 
(formData, { rejectWithValue }) => {
  return updateCreditDirectorService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getCreditDirector = createAsyncThunk('updateProfile/getCreditDirector', 
(formData, { rejectWithValue }) => {
  return getCreditDirectorService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const deleteCreditDirector = createAsyncThunk('updateProfile/deleteCreditDirector', 
(id, { rejectWithValue }) => {
  return deleteDirectorService(id)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getErpEmployers = createAsyncThunk('updateProfile/getErpEmployers', 
(formData, { rejectWithValue }) => {
  return getErpEmployersService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

export const getErpIndustries = createAsyncThunk('updateProfile/getErpIndustries', 
(formData, { rejectWithValue }) => {
  return getErpIndustriesService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response));
});

const updateProfileSlice = createSlice({
  name: 'updateProfile',
  initialState,
  reducers: {
    closeModal(state) {
      state.showPhoneOtpModal = false
    },
    toggleDelete(state) {
      state.deleteDirectorMsg = false
    },
    clearChangePassword(state) {
      state.passChangeMsg = null
    },
  },
  extraReducers: builder => {
    // verify phone pending
    builder.addCase(verifyPhone.pending, state => {
      state.loading = true
      state.phoneMsg = null
      state.phoneMsgError = null
    })
    // verify phone success
    builder.addCase(verifyPhone.fulfilled, (state, action) => {
      toast.success(action.payload);
      state.loading = false
      state.phoneMsg = action.payload
      state.phoneMsgError = null
      state.showPhoneOtpModal = true
      console.log("hl", action.payload);
    })
    // verify phone fail
    builder.addCase(verifyPhone.rejected, (state, action) => {
      state.loading = false
      state.phoneMsg = null
      state.phoneMsgError = action.error
      state.showPhoneOtpModal = false
      toast.error(action.payload?.data?.message);
    })
    // validate phone otp pending
    builder.addCase(validatePhoneOtp.pending, state => {
      state.loading = true
    })
    // validate phone otp success
    builder.addCase(validatePhoneOtp.fulfilled, (state, action) => {
      state.loading = false
      state.validatePhone = action.payload
      state.validatePhoneError = null
    })
    // validate phone otp fail
    builder.addCase(validatePhoneOtp.rejected, (state, action) => {
      state.loading = false
      state.validatePhone = null
      state.validatePhoneError = action.error
    })
    // update personal documents pending
    builder.addCase(updatePersonalDocs.pending, state => {
      state.loading = true
    })
    // update personal documents success
    builder.addCase(updatePersonalDocs.fulfilled, (state, action) => {
      state.loading = false
      state.docMsg = action.payload
      state.docMsgError = null
      toast.success("Documents updated")
    })
    // update personal documents fail
    builder.addCase(updatePersonalDocs.rejected, (state, action) => {
      state.loading = false
      state.docsMsg = null
      state.docsMsgError = action.error
      toast.error(action?.payload?.data?.message)
    })
    // verify account number pending
    builder.addCase(verifyAccountNo.pending, state => {
      state.verifyloading = true
    })
    // verify account number success
    builder.addCase(verifyAccountNo.fulfilled, (state, action) => {
      state.verifyloading = false
      state.accountDetail = action.payload
      state.accountDetailError = null
    })
    // verify account number fail
    builder.addCase(verifyAccountNo.rejected, (state, action) => {
      state.verifyloading = false
      state.accountDetail = null
      state.accountDetailError = action.error
      toast.error(action.payload?.data?.message);
    })
    // update bank details pending
    builder.addCase(updateBankdetails.pending, state => {
      state.loading = true
    })
    // update bank details success
    builder.addCase(updateBankdetails.fulfilled, (state, action) => {
      state.loading = false
      state.bankUpdateMsg = action.payload
      state.bankUpdateError = null
      toast.success("Bank details updated")
    })
    // update bank details fail
    builder.addCase(updateBankdetails.rejected, (state, action) => {
      state.loading = false
      state.bankUpdateMsg = null
      state.bankUpdateError = action.payload?.data?.message
      toast.error(action?.payload?.data?.message)
    })
    // update business bank details pending
    builder.addCase(updateBusinessBankdetails.pending, state => {
      state.loading = true
    })
    // update business bank details success
    builder.addCase(updateBusinessBankdetails.fulfilled, (state, action) => {
      state.loading = false
      state.businessBankUpdateMsg = action.payload
      state.businessBankUpdateErr = null
      toast.success("Bank details updated")
    })
    // update business bank details fail
    builder.addCase(updateBusinessBankdetails.rejected, (state, action) => {
      state.loading = false
      state.businessBankUpdateMsg = null
      state.businessBankUpdateErr = action.payload?.data?.message
      toast.error(action?.payload?.data?.message)
    })
    // change password pending
    builder.addCase(changeUserPassword.pending, state => {
      state.loading = true
    })
    // change password success
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      state.loading = false
      state.passChangeMsg = action.payload
      state.passChangeError = null
      toast.success("Password updated")
    })
    // change password fail
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.loading = false
      state.passChangeMsg = null
      state.passChangeError = action.payload?.data?.message
      toast.error(action?.payload?.data?.message)
    })
    // update Company Docs pending
    builder.addCase(updateCompanyDocs.pending, state => {
      state.loading = true
    })
    // update Company Docs success
    builder.addCase(updateCompanyDocs.fulfilled, (state, action) => {
      state.loading = false
      state.companyDocMsg = action.payload
      state.companyDocMsgError = null
      toast.success("Documents Uploaded")
    })
    // update Company Docs fail
    builder.addCase(updateCompanyDocs.rejected, (state, action) => {
      state.loading = false
      state.companyDocMsg = null
      state.companyDocMsgError = action.payload?.data?.message
      toast.error(action?.payload?.data?.message)
    })
    // update credit directors pending
    builder.addCase(updateCreditDirector.pending, state => {
      state.loading = true
    })
    // update credit directors success
    builder.addCase(updateCreditDirector.fulfilled, (state, action) => {
      state.loading = false
      state.directorMsg = action.payload
      state.directorMsgError = null
      toast.success("Director Added")
    })
    // update credit directors fail
    builder.addCase(updateCreditDirector.rejected, (state, action) => {
      state.loading = false
      state.directorMsg = null
      state.directorMsgError = action.payload?.data?.message
      toast.error(action?.payload?.data?.message)
    })
    // get credit directors pending
    builder.addCase(getCreditDirector.pending, state => {
      state.loading = true
    })
    // get credit directors success
    builder.addCase(getCreditDirector.fulfilled, (state, action) => {
      state.loading = false
      state.directors = action.payload
      state.directorsError = null
    })
    // get credit directors fail
    builder.addCase(getCreditDirector.rejected, (state, action) => {
      state.loading = false
      state.directors = null
      state.directorsError = action.payload?.data?.message
    })
    // delete credit director pending
    builder.addCase(deleteCreditDirector.pending, state => {
      state.loading = true
    })
    // delete credit director success
    builder.addCase(deleteCreditDirector.fulfilled, (state, action) => {
      state.loading = false
      state.deleteDirectorMsg = true
      state.deleteDirectorError = null
      toast.success("Director Deleted")
      console.log(action,"from dire delete");
    })
    // delete credit director fail
    builder.addCase(deleteCreditDirector.rejected, (state, action) => {
      state.loading = false
      state.deleteDirectorMsg = false
      state.deleteDirectorError = action.error
      toast.error("Unable to delete")
      console.log(action, "from dire delete");
    })
    // get erp employers pending
    builder.addCase(getErpEmployers.pending, state => {
      state.loading = true
    })
    // get erp employers success
    builder.addCase(getErpEmployers.fulfilled, (state, action) => {
      state.loading = false
      state.erpEmployers = action.payload
      state.erpEmployersErr = null
    })
    // get erp employers fail
    builder.addCase(getErpEmployers.rejected, (state, action) => {
      state.loading = false
      state.erpEmployers = null
      state.erpEmployersErr = action.payload?.data?.message
    })
    // get erp industries pending
    builder.addCase(getErpIndustries.pending, state => {
      state.loading = true
    })
    // get erp industries success
    builder.addCase(getErpIndustries.fulfilled, (state, action) => {
      state.loading = false
      state.erpIndustries = action.payload
      state.erpIndustriesErr = null
    })
    // get erp industries fail
    builder.addCase(getErpIndustries.rejected, (state, action) => {
      state.loading = false
      state.erpIndustries = null
      state.erpIndustriesErr = action.payload?.data?.message
    })
  }
});

export default updateProfileSlice.reducer
export const { closeModal, toggleDelete, clearChangePassword } = updateProfileSlice.actions;