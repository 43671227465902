import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";

export const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs:310, sm:310, md:416},
    height: 380,
    bgcolor: "#fff",
    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
    borderRadius: "20px",
    p: 4,
};




export default function NoteModal({
    open,
    handleClose,
    handleformInfoOpen,
}) {
    return (
        <div>
            <Box sx={{
                maxWidth: "90%",
                padding: '1rem',
            }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.header}>Note</div>
                        <BlockSpacer mt={70} />
                        <div className={styles.text}>Check bank number for ticket ID and password sent by your bank</div>
                        <BlockSpacer mt={70} />
                        <div className={styles.button}>

                            <Button
                                color={"primary"}
                                width={164}
                                height={41}
                                onClick={() => handleformInfoOpen()}
                            >
                                Continue
                            </Button>
                        </div>
                    </Box>
                </Modal>

            </Box>
        </div>
    );
}
