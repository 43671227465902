import styled from "@emotion/styled";

export const Section = styled.section`
 position: fixed;
 width: 15vw;
 height: 100vh;
 background: #FFFFFF;
 box-shadow: 4px 0px 4px rgba(196, 204, 221, 0.12);
 .links {
    margin-top: 3rem;
    width: 100%;
 }
 ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
}
li {
    border-radius: 5px;
    &:hover{
        background: #E7E7E7;
    }
}

a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;    
    letter-spacing: -0.04em; 
    color: #4F4F4F;

}
.nav-list {
    padding: 9px 24px 9px 12px;
}
.active {
    background: rgba(28, 68, 141, 0.09);
    border-radius: 5px;
    list-style: none;
    width: 100%;
}
 
`

export const headingText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: '32px',
    letterSpacing: '-0.04em',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '2rem',
    mr: 8

}