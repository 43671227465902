import React from "react";
import BlockSpacer from "../../BlockSpacer";
import CompanyDetailForm from "../../Forms/CompanyDetailForm";
import style from "./style.module.css";

function CompanyDetails({
  defaultUserMethod,
  setDefaultUserMethod,
  info,
  setKycInfo,
  itemOptions,
}) {
  return (
    <div className={style.container}>
      <div className={style.title}>Company Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <CompanyDetailForm
          defaultUserMethod={defaultUserMethod}
          setDefaultUserMethod={setDefaultUserMethod}
          info={info}
          setKycInfo={setKycInfo}
          itemOptions={itemOptions}
        />
      </div>
    </div>
  );
}

export default CompanyDetails;
