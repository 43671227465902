import React from 'react';
import {
  Typography,
  Button,
} from "@mui/material";
import { Box } from "@mui/system"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { previous, next } from "./style";

const Paginate = ({ nextPage, prevPage, total, current }) => {
  if(total>0) {
    return (
      <>
        <Box
          display={"flex"}
          sx={{ 
            justifyContent: {xs:"center",sm:"center",md:"space-between",lg:"space-between"}, 
            width: {xs:"100%",md:"100%",sm:"100%",lg:"70%"} 
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              width: "40%",
              marginLeft: "5rem",
              flexWrap: "wrap",
              display: {xs:"none", sm:"none",md:"flex",lg:"flex"}
            }}
          >
            <Button 
              sx={current===1 ? previous : next} 
              onClick={prevPage}
              disabled={current===1}
            >
              Previous
            </Button>
            <Button 
              sx={current===total ? previous : next} 
              onClick={nextPage} 
              disabled={current===total}
            >
              Next
            </Button>
          </Box>
          <Box
            display={"flex"}
            sx={{
              justifyContent: {xs:"center",sm:"center",md:"space-evenly",lg:"space-evenly"},
              width: {xs:"100%",sm:"100%",md:"20%",lg:"20%"},
              alignItems: "center",
              gap: 2
            }}
          >
            <button
              style={{
                borderRadius: "50%",
                background: current===1?"#FFFFFF":"#BDBDBD",
                border: "1.5px solid #BDBDBD",
                width: "21.58px",
                cursor: "pointer",
                height: "21.04px",
                position: "relative",
                padding:0
              }}
              onClick={prevPage}
              disabled={current===1}
            >

              <NavigateBeforeIcon
                style={{
                  color: current===1 ? "#BDBDBD":"#FFFFFF",
                  position: "absolute",
                  left: "-.2rem",
                  top: "-.1rem",
                  cursor: "pointer",
                }}
              />
            </button>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "22px",
              }}
            >
              {`${current} of ${total}`}
            </Typography>
            <button
              style={{
                borderRadius: "50%",
                background: current===total?"#FFFFFF":"#BDBDBD",
                border: "1.5px solid #BDBDBD",
                width: "21.58px",
                height: "21.04px",
                cursor: "pointer",
                padding: 0,
                position: "relative",
              }}
              onClick={nextPage}
              disabled={current===total}
            >
              <NavigateNextIcon
                style={{
                  color: current===total ? "#BDBDBD":"#FFFFFF",
                  position: "absolute",
                  left: "-.2rem",
                  top: "-.1rem",
                  cursor: "pointer",
                }}
              />
            </button>
          </Box>
        </Box>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}

export default Paginate