import React from "react";
import FormAligner from "../../../../components/Hocs/FormAligner/index.jsx";
import SelectField from "../../../../components/Core/SelectField/index.jsx";
import { TextField } from "@mui/material";
import Buttons from "../../../../components/Buttons/index.jsx";
import Card from "@material-ui/core/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@material-ui/core/CardContent";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Link } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import { paperContainer, left, right, Account, Number, Section } from "./style";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SuccessPayment from "../../../../components/Modals/SuccessPayment/index";
import { useState } from "react";
import style from "./style.module.css";
import "./stye.css";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import masterCard from "../../../../components/Assets/icons/master.svg";
import wallet from "../../../../components/Assets/icons/wallet.svg";
import Checkbox from "@mui/material/Checkbox";
import TopBar from "../../../../components/MyCreditTopBar/index.js";

function Extension() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [transfer, setTransfer] = useState(false);

  const hanldeTransfer = () => {
    setTransfer(true);
  };
  const closeHandle = () => {
    setTransfer(false);
  };

  // Payment Successful

  const [openSuccessPayment, setSuccessOpenPayment] = useState(false);
  const handleSuccessPaymentOpen = () => {
    setSuccessOpenPayment(true);
  };
  const handleSuccessPaymentClose = () => {
    setSuccessOpenPayment(false);
  };
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: 21,
              fontFamily: "Montserrat",
              mt: { xs: "-2", lg: "-2" },
            }}
          >
            Extension
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Box style={{ position: "relative" }}>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "20px", lg: "20px" },
                    width: { xs: "400px", lg: "365.41px" },
                    maxWidth: "100%",
                    height: { xs: "401px", lg: "401px" },
                    borderRadius: "8.94247px",
                    paperContainer,
                    boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.23)",
                  }}
                >
                  <Box sx={left} style={{ position: "absolute" }}>
                    <Typography
                      sx={Number}
                      style={{ fontSize: "15.6493px", marginTop: "5px" }}
                    >
                      Quick Loan Summary
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", lg: "space-between" },
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Request Loan Amount</Typography>
                      <Typography sx={Account} style={{}}>
                        Tenor
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "343px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Number}
                        style={{ mt: { xs: "", lg: "" } }}
                      >
                        2,000,000
                      </Typography>
                      <Typography sx={Number} style={{}}>
                        7 days
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "483.41px" },
                        maxWidth: "343px",
                        top: "-8px",
                      }}
                    ></Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography
                        sx={Account}
                        style={{ mt: { xs: "0", lg: "200px" } }}
                      >
                        Interest Payable
                      </Typography>
                      <Typography
                        sx={Account}
                        style={{
                          top: "100px",
                        }}
                      >
                        Fee Payable
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number}>2,000,000</Typography>
                      <Typography sx={Number} style={{ top: "150px" }}>
                        2000
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "483.41px" },
                        top: "-10px",
                        maxWidth: "343px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Total Repayment</Typography>
                      <Typography sx={Account}>Loan Due Date</Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number} style={{ color: "#111E6C" }}>
                        100,000
                      </Typography>
                      <Typography sx={Number} style={{ color: "#111E6C" }}>
                        12/03/2022
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "383.41px" },
                        top: "-10px",
                        maxWidth: "343px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Account}>Request Date</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                        maxWidth: "343px",
                      }}
                    >
                      <Typography sx={Number}>28/05/2022</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <BlockSpacer mt={40} />
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "80", lg: "80%" },
                  maxWidth: "100%",
                  padding: "1rem",
                  display: "block",
                }}
              >
                <form className={style.TopRequest}>
                  <div>
                    <label
                      htmlFor="Top Request"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "15px",
                        display: "block",
                      }}
                    >
                      Extension tenor
                    </label>
                    <input
                      type="text"
                      name=""
                      id="pley"
                      placeholder="input request here"
                      style={{
                        width: "321px",
                        height: "48px",
                        padding: "12px 20px",
                        margin: "8px 0",
                        display: "inlineBlock",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxSizing: "borderBox",
                      }}
                    />
                  </div>
                  <div className={style.TenorForm}>
                    <label
                      htmlFor="Tenor"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "15px",
                        display: "block",
                      }}
                    >
                      Extension fee
                    </label>
                    <select
                      name=""
                      id="tenor"
                      style={{
                        width: "321px",
                        height: "48px",
                        padding: "12px 20px",
                        margin: "8px 0",
                        display: "Block",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxSizing: "borderBox",
                      }}
                    >
                      <option value=""></option>
                      <option value=""></option>
                      <option value=""></option>
                      <option value=""></option>
                      <option value=""></option>
                    </select>
                  </div>
                </form>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              style={{
                background: "rgba(28, 68, 141, 0.03)",
                width: "475px",
                position: "relative",
              }}
            >
              <BlockSpacer mt={80} />
              {transfer && (
                <Card
                  style={{
                    maxwidth: "100%",
                    width: "494px",
                    height: "403px",
                    position: "absolute",
                    top: "-50px",
                    left: "-150px",
                    border: "none",
                    boxShadow: " 0px 4px 20px rgba(196, 204, 221, 0.23)",
                  }}
                >
                  <CardContent>
                    <BlockSpacer mt={20} />
                    <ArrowCircleLeftOutlinedIcon
                      onClick={closeHandle}
                      style={{ cursor: "pointer" }}
                    />
                  </CardContent>
                  <BlockSpacer mt={5} />
                  <Divider style={{ width: "494px" }} variant="middle" light />
                  <BlockSpacer mt={30} />
                  <Box
                    sx={{
                      marginRight: "auto",
                      marginLeft: "auto",
                      maxWidth: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "14px",
                          paddingLeft: "3.5rem",
                        }}
                      >
                        Dear Ekiyee
                      </Typography>
                      <BlockSpacer mt={10} />
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: "400",
                          width: "375px",
                          paddingLeft: "3.5rem",
                        }}
                      >
                        Hi Ekiyee, Kindly make payment into the displayed
                        account details
                      </Typography>
                    </Box>
                    <BlockSpacer mt={20} />
                    <Box
                      display={"flex"}
                      style={{
                        marginLeft: ".5rem",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Account Number
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Account Name
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Bank Name
                      </Typography>
                    </Box>
                    <BlockSpacer mt={5} />
                    <Box
                      display={"flex"}
                      sx={{ justifyContent: "space-evenly" }}
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        01234567890
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Rosabon
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Rosabon
                      </Typography>
                    </Box>
                    <BlockSpacer mt={40} />
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        paddingLeft: "3.5rem",
                        width: "388px",
                      }}
                    >
                      Account details expires in 48 hours, kindly endeavour to
                      make transfer before 09/07/2022, 09 : 00
                    </Typography>
                  </Box>
                </Card>
              )}
              <Card
                style={{
                  maxWidth: "100%",
                  width: "423px",
                  height: "189px",
                  marginRight: "auto",
                  marginLeft: "auto",
                  border: "none",
                  boxShadow: " 0px 4px 20px rgba(196, 204, 221, 0.23)",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "15.78px",
                    }}
                  >
                    Payment Method
                  </Typography>
                </CardContent>
                <Box
                  display={"flex"}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display={"flex"} sx={{ marginLeft: "15px" }}>
                    <img src={masterCard} alt="logo" />
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "13.52px",
                        fontFamily: "Montserrat",
                        marginLeft: "20px",
                      }}
                    >
                      Debit Card
                    </Typography>
                  </Box>
                  <Box>
                    <Checkbox {...label} onClick={closeHandle} />
                  </Box>
                </Box>
                <BlockSpacer mt={20} />
                <Divider style={{ width: "422.62px" }} variant="middle" light />
                <BlockSpacer mt={10} />
                <Box
                  display={"flex"}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box display={"flex"} sx={{ marginLeft: "15px" }}>
                    <img src={wallet} alt="logo" />
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "13.52px",
                        fontFamily: "Montserrat",
                        marginLeft: "20px",
                      }}
                    >
                      Transfer
                    </Typography>
                  </Box>
                  <Box>
                    <Checkbox {...label} onChange={hanldeTransfer} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "87%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: " 0px 4px 20px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/active-transaction">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
            <Buttons
              color="primary"
              width="328px"
              onClick={handleSuccessPaymentOpen}
            >
              Pay
            </Buttons>
          </Box>
        </Paper>
        {openSuccessPayment && (
          <SuccessPayment
            open={openSuccessPayment}
            handleClose={handleSuccessPaymentClose}
          />
        )}
      </main>
    </>
  );
}

export default Extension;
