import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import Success from "../../Assets/images/success.png";
import Button from "../../Buttons";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "422.62px",
  maxWidth: "100%",
  height: "435px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function PartWithdrawal({ open, handleClose, item }) {

  const generatePDF = () => {
    const input = document.getElementById("print");
    const pxToMm = (px) => {
      return Math.floor(px / document.getElementById("print")?.offsetHeight);
    };

    const mmToPx = (mm) => {
      return document.getElementById("print")?.offsetHeight * mm;
    };

    const a4WidthMm = 210;
    const a4HeightMm = 297;
    const a4HeightPx = mmToPx(a4HeightMm);
    const inputHeightMm = pxToMm(input?.offsetHeight);
    const numPages =
      inputHeightMm <= a4HeightMm
        ? 1
        : Math.floor(inputHeightMm / a4HeightMm) + 1;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Document of a4WidthMm wide and inputHeightMm high
      // if (inputHeightMm > a4HeightMm) {
      //   // elongated a4 (system print dialog will handle page breaks)
      //   var pdf = new JsPDF('p', 'mm', [inputHeightMm+16, a4WidthMm]);
      // } else {
      // standard a4
      var pdf = new JsPDF();
      // }

      pdf.addImage(imgData, "PNG", 35, 20, 118, 184);
      pdf.save(`transaction.pdf`);
    });
  };

  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
          width: { xs: "50px" },
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div id="print">
              <Box className={styles.header}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "19px",
                    lineHeight: "22px",
                    color: "#242424",
                    textAlign: "center"
                  }}
                >
                  {
                    item?.transactionType === "CREDIT"
                    ? "+ ₦"+ item?.debit.toLocaleString()
                    : "- ₦" + item?.debit.toLocaleString()
                  }
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontFamily: "Montserrat",
                    // paddingLeft: "0.8rem",
                    fontSize: "15px",
                    lineHeight: "150%",
                    textAlign: "center"
                  }}
                >
                  {item?.transactionCategory}
                </Typography>
              </Box>
              <BlockSpacer mt={40} />
              <Box
                display={"flex"}
                sx={{
                  width: "340px",
                  maxWidth: "416px",
                  justifyContent: "space-between",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                    paddingLeft: "1rem",
                  }}
                >
                  Transaction ID
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    lineHeight: "16px",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  {item?.transactionId}
                </Typography>
              </Box>
              <Divider
                sx={{ width: "375px", maxWidth: "100%", marginTop: ".5rem" }}
              />
              <Box
                display={"flex"}
                sx={{
                  width: "340px",
                  maxWidth: "416px",
                  justifyContent: "space-between",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                    paddingLeft: "1rem",
                  }}
                >
                  Transaction Date
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    lineHeight: "16px",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  {item?.transactionDate?.split(" ")[0]}
                </Typography>
              </Box>
              <Divider
                sx={{ width: "375px", maxWidth: "100%", marginTop: ".5rem" }}
              />
              <Box
                display={"flex"}
                sx={{
                  width: "340px",
                  maxWidth: "416px",
                  justifyContent: "space-between",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                    paddingLeft: "1rem",
                  }}
                >
                  Transaction Type
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    lineHeight: "16px",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  {item?.transactionType}
                </Typography>
              </Box>
              <Divider
                sx={{ width: "375px", maxWidth: "100%", marginTop: ".5rem" }}
              />
              <Box
                display={"flex"}
                sx={{
                  width: "340px",
                  maxWidth: "416px",
                  justifyContent: "space-between",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                    paddingLeft: "1rem",
                  }}
                >
                  Balance
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    lineHeight: "16px",
                    fontSize: "13px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  ₦{item?.balanceAfterTransaction.toLocaleString()}
                </Typography>
              </Box>

              <BlockSpacer mt={90} />
              <div className={styles.button}>
                <button
                  style={{
                    width: "192px",
                    height: "36px",
                    border: "1px solid #BDBDBD",
                    borderRadius: "15px",
                    boxSizing: "border-box",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    lineHeight: "150%",
                    fontSize: "14px",
                    letterSpacing: "-0.15px",
                    cursor: "pointer",
                  }}
                  onClick={()=>generatePDF()}
                >
                  Download pdf
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
