import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  notifcationCard,
  notificationTitle,
  titleContainer,
  markAllContainer,
  notificationSuccesfirst,
  notificationDateSucess,
} from "./style.js";
import useGetNotificationsQuery from "../../api/services/notifications/get-notifications.api.js";
import useMarkReadNotificationsQuery from "../../api/services/notifications/mark-read-notifications.api.js";

function NotifationCard() {
  const { data: notifications } = useGetNotificationsQuery();
  const dateFormatter = (time) => {
    const date = new Date(time);
    return date.toDateString();
  };
  const { mutateAsync } = useMarkReadNotificationsQuery();
  return (
    <>
      <Paper sx={notifcationCard} style={{ overflow: "auto" }}>
        <Box
          sx={{
            ...titleContainer,
            position: "sticky",
            top: 0,
            bgcolor: "white",
            py: "1.5rem",
          }}
        >
          <Typography variant="h4" sx={notificationTitle}>
            Notifications
          </Typography>
          <div style={{ cursor: "pointer" }} onClick={() => mutateAsync()}>
            <Typography sx={markAllContainer}>Mark all as read</Typography>
          </div>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {notifications?.map((notification) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: { xs: "100%", lg: "399px", gap: "10px" },
              }}
              key={notification?.id}
            >
              <Box
                sx={{
                  width: "41px",
                  height: "41px",
                  background: "rgba(17, 30, 108, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  mt: "10px",
                }}
              >
                <NotificationsIcon />
              </Box>
              <Box sx={{ flex: 1 }}>
                <p
                  style={notificationSuccesfirst}
                  dangerouslySetInnerHTML={{ __html: notification?.message }}
                />

                <Typography sx={notificationDateSucess}>
                  {dateFormatter(notification?.dateSent)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    </>
  );
}

export default NotifationCard;

// {INCOMING_NOTIFACATION.map(notification => (
//     <Box sx={notificationContainer} key={notification.id}>
//         <Box>
//             <img src={notification.icon} alt="Notification" />
//         </Box>

//         <Box sx={notifacationBox}>
//             <Typography sx={notificationSucces}>{notification.sucess}</Typography>
//             <Typography sx={notificationDateSucess}>{notification.successDate}</Typography>
//         </Box>

//     </Box>
// ))}
