import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import OtpInput from "react-otp-input";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};
const otpStyle = {
  width: "56px",
  margin: "0 auto",
  background: "#FFF",
  height: "56px",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
};

const mobOtpStyle = {
  width: "36px",
  margin: "0 auto",
  background: "#FFF",
  height: "36px",
  border: "1px solid #E0E0E0",
  borderRadius: "3px",
};

const otpFocusedStyle = {
  border: "2px solid #E0E0E0",
};

export default function OtpVerificationEmailModal({
  open,
  handleClose,
  handleOtpChange,
  otp,
  resendOtp,
  otpToken,
  handleSuccessOpen,
}) {
  const { validateOtpError } = useSelector(
    (state) => state.profile
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.header}>OTP Verification</div>
          <BlockSpacer mt={31} />
          <div className={styles.text}>Enter OTP sent to your Email</div>
          <BlockSpacer mt={35} />
          <div className={styles.input}>
            <OtpInput
              onChange={(e)=>handleOtpChange(e)}
              numInputs={5}
              value={otp}
              placeholder="-----"
              inputStyle={otpStyle}
              focusStyle={otpFocusedStyle}
            />
            {validateOtpError && (
              <span className="text-center" style={{ color: "#FF0000" }}>
                {validateOtpError?.data?.message}
              </span>
            )}
          </div>
          <div className={styles.mobInput}>
            <OtpInput
              onChange={(e)=>handleOtpChange(e)}
              numInputs={5}
              value={otp}
              placeholder="-----"
              inputStyle={mobOtpStyle}
              focusStyle={otpFocusedStyle}
            />
            {validateOtpError && (
            <span className="text-center" style={{ color: "#FF0000" }}>
              {validateOtpError?.data?.message}
            </span>
          )}
          </div>
          <BlockSpacer mt={20} />
          <div className={styles.msg}>
            Didn't get an OTP?{" "} <span className={styles.resend} onClick={()=>{
              resendOtp()
              toast.success("OTP Resent")
              }} >
              Resend
            </span>
          </div>
          <BlockSpacer mt={44} />
          <div className={styles.button}>
            <Button
              color={"tertiary"}
              width={164}
              height={41}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              color={"primary"}
              width={164}
              height={41}
              onClick={() => handleSuccessOpen()}
            >
              OK
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
