import React from "react";
import BlockSpacer from "../../BlockSpacer";
import EmployerDetailForm from "../../Forms/EmployerDetailForm";
import style from "./style.module.css";

function EmployerDetails({ setKycInfo }) {
  return (
    <div className={style.container}>
      <div className={style.title}>Employer Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <EmployerDetailForm setKycInfo={setKycInfo} />
      </div>
    </div>
  );
}

export default EmployerDetails;
