import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SuccessMessage from "../../Cards/SuccessMessages";
import { style } from "../OtpVerificationModal";
import ConfettiIcon from "../../IconSvgs/ConfettiIcon";
import { clearLoan } from "../../../redux/slices/getCreditSlice";
import { useDispatch } from "react-redux";

export default function SuccessModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(clearLoan());
    handleClose();
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-otp-title"
        aria-describedby="modal-otp-description"
      >
        <Box sx={style}>
          <SuccessMessage
            title={"Success!"}
            subTextStart={
              "Your Loan is being processed and you will be notified if accepted"
            }
            icon={<ConfettiIcon />}
            route={"/get-credit"}
            handleClose={close}
          />
        </Box>
      </Modal>
    </div>
  );
}
