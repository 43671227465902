import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetLeaseBrandsQuery = () => {
  const getLeaseBrands = async () => {
    const request = await axiosInstance.get(`/users/lease-brands`);
    const response = request.data;
    return response;
  };
  const query = useQuery(["LEASE_BRANDS"], getLeaseBrands);
  return query;
};

export default useGetLeaseBrandsQuery;
