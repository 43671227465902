import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileLayout from "../../../../../components/ProfileLayout";
import Accordion from "@mui/material/Accordion";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BlockSpacer from "../../../../../components/BlockSpacer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ArrowBackIosNew } from "@mui/icons-material";
import DirectorsInfo from "../../../../../components/DirectorInfo";
import AddDirector from "../../../../../components/DirectorInfo/addDirector";
import { Section, textheading } from "./style";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/Buttons";
import FailureIcon from "../../../../../components/IconSvgs/FailureIcon";
import { 
  getCreditDirector 
} from "../../../../../redux/slices/updateProfileSlice";
import { 
  getAllIdTypes,
  sendCompanyOtp,
  closeOtpModal,
  validateOtp, 
} from "../../../../../redux/slices/profileSlice";
import ConfirmDeleteDirector from "../../../../../components/Modals/ConfirmDeleteDirector";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import AddDirectorModal from "../../../../../components/Modals/AddDirectorModal";

function DirectCompanyProfile(props) {
  const dispatch = useDispatch();
  const [addDirector, setAddDirector] = useState(false);
  const [currentEdit, setCurrentEdit] = useState();
  const [currentDir, setCurrentDir] = useState();
  const [showEdit, setShowEdit] = useState([]);
  const [token, setToken] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { width } = useScreenSize();

  useEffect(() => {
    dispatch(getCreditDirector());
    dispatch(getAllIdTypes());
  },[])

  const { directors, deleteDirectorMsg } = useSelector(state => state.updateProfile);

  const { 
    users,
    kycData,
    showEmailOtpModal,
    otp,
    validateEmailOtp,
   } = useSelector(state => state.profile);
   
  useEffect(() => {
    dispatch(getCreditDirector());
  }, [deleteDirectorMsg, dispatch])

  const addEdit = (id) => {
    console.log("add")
    if(!(showEdit.includes(id))) {
      setShowEdit(current => [...current, id]);
    }
  };

  const removeEdit = (id) => {
    console.log("remove");
    if(showEdit.includes(id)) {
      setShowEdit(showEdit?.filter(item=>item !== id))
    }
  };

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleSendOtp = () => {
    dispatch(sendCompanyOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      addEdit(currentEdit);
    }
  }, [validateEmailOtp]);

  // const reset = () => {
  //   setformData({
  //     ...formData,
  //     firstName: "",
  //     lastName: "",
  //     middleName: "",
  //     address: "",
  //     email: "",
  //     phone: "",
  //     bvn: "",
  //     idTypeId: "",
  //     idNumber: "",
  //   });

  //   setBase64File({
  //     ...base64File,
  //     frontEncodedString: "",
  //     photoEncodedString: "",
  //   });
  // };

  
  return (
    <>
      <TopBar />
      <>
        <Section>
          <ProfileLayout>
          <Box 
            sx={{
              display:width>700?"none":"flex",
              mb:3,
              mt:3,
              pl:2,
              alignItems:"center",
              gap:2
            }} 
          >
            <Box>
              <Link to="/profile" >
                <ArrowBackIosNew />
              </Link>
            </Box>
            <Box>
              <Typography sx={textheading}>Director Details</Typography>
            </Box>
          </Box>
          {
            (directors && directors?.length > 0) ? directors?.slice(0)?.reverse()?.map((item, id)=> (
              <>
                <Accordion sx={{ outline: "none", boxShadow: "none" }} key={item?.id}>
                  <Box 
                    sx={{ 
                      display: "flex", 
                      justifyContent: "space-between", 
                      alignItems: "center",
                      paddingRight: 4,
                      paddingTop: 5
                    }}
                  >
                    <AccordionSummary
                      // sx={{ pr: { xs: "10rem", lg: "45rem" }  }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{fontWeight:700}}>Director {id+1}</Typography>
                    </AccordionSummary>
                    <Button 
                      color="neutral-blue" 
                      disabled={directors?.length < 2}
                      width={width<=700 ? "60px" :  "72px"}
                      height={width<=700? "auto":"42px"}
                      fontSize={width<=700 && "14px"}
                      onClick={()=>{
                        setCurrentDir(item?.id)
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  <AccordionDetails>
                    <DirectorsInfo 
                      existingDirector={true}
                      director={item}
                      id={id+1}
                      removeEdit={(id)=>removeEdit(id)}
                      handleSendOtp={()=>handleSendOtp()}
                      setCurrentEdit={setCurrentEdit}
                      showEdit={showEdit}
                    />
                  </AccordionDetails>
                </Accordion>
                {
                  ( id+1===directors?.length) && (
                    <Button
                      color="transparent-blue"
                      fontSize={width<=700 && "14px"}
                      onClick={()=>setAddDirector(!addDirector)}
                    >
                      {
                        addDirector ? "Close Director" : "Add Director"
                      }
                    </Button>
                  )
                }
                <BlockSpacer mt={50} />
              </>
            ))  : 
              (directors?.length<1 || addDirector) && (<>
              <Accordion sx={{ outline: "none", boxShadow: "none" }}>
                <AccordionSummary
                  sx={{ pr: { xs: "5rem", lg: "45rem" } }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography sx={{fontWeight:700}}>
                    Director 1
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AddDirector 
                      existingDirector={false}
                      idNum={directors?.length>0 ? directors?.length+1: 1}
                      toggleAddDirector={()=>setAddDirector(false)}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>)}
            {
              (addDirector) && width>700 ? (<>
                {/* <Accordion sx={{ outline: "none", boxShadow: "none" }}>
                  <AccordionSummary
                    sx={{ pr: { xs: "5rem", lg: "45rem" } }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={{fontWeight:700}}>
                      Director {directors?.length+1}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AddDirector 
                        existingDirector={false}
                        idNum={directors?.length>0 ? directors?.length+1: 1}
                        toggleAddDirector={()=>setAddDirector(false)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                <AddDirectorModal
                  open={addDirector}
                  handleClose={()=>setAddDirector(false)}
                >
                  <Box>
                    <Box>
                      <Typography sx={{fontWeight:700}}>
                        Director {directors?.length+1}
                      </Typography>
                    </Box>
                    <Box>
                      <AddDirector 
                        existingDirector={false}
                        idNum={directors?.length>0 ? directors?.length+1: 1}
                        toggleAddDirector={()=>setAddDirector(false)}
                      />
                    </Box>
                  </Box>
                </AddDirectorModal>
              </>) : 
              <Drawer
                anchor={"top"}
                open={addDirector}
                onClose={()=>setAddDirector(false)}
                variant={"temporary"}
              >
                <Box 
                  sx={{
                    padding:"2rem", 
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                    }} 
                  >
                  <Typography sx={{fontWeight:700, fontSize:24}}>
                    Director {directors?.length+1}
                  </Typography>
                  <IconButton onClick={()=>setAddDirector(false)} >
                    <ArrowBackIosNew />
                  </IconButton>
                </Box>
                <Box sx={{px:"1rem"}} >
                  <AddDirector 
                    existingDirector={false}
                    idNum={directors?.length>0 ? directors?.length+1: 1}
                    toggleAddDirector={()=>setAddDirector(false)}
                  />
                </Box>
              </Drawer>
            }
          </ProfileLayout>
          {showEmailOtpModal && (
          <OtpVerificationEmailModal
            open={showEmailOtpModal}
            handleClose={handleOTPModalClose}
            handleOtpChange={(otp) => createOtp(otp)}
            otp={token}
            resendOtp={() => {
              dispatch(sendCompanyOtp());
            }}
            otpToken={otp?.data}
            handleSuccessOpen={() => handleEmailOtpSubmit()}
          />
        )}
        {
          deleteModal && (
            <ConfirmDeleteDirector 
              open={deleteModal}
              handleClose={()=>setDeleteModal(false)}
              directorId={currentDir}
            />
          )
        }
        </Section>
      </>
    </>
  );
}

export default DirectCompanyProfile;
