import styled from "@emotion/styled";

export const Section = styled.section`
  @media (max-width: 1215px) {
    main {
      margin-top: 20%;
    }
  }
  position: relative;
  padding-top: 15%;
`;

export const inputError = {
  borderColor: "#fc8181",
};
