import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  PaidOff,
  Withdrawn,
  Declined,
  fixed,
} from "./style";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import { Link, useNavigate } from "react-router-dom";
import { Section } from "./style";
import BlockSpacer from "../../../components/BlockSpacer";
import TopBar from "../../../components/MyCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { closedTransactions } from "../../../redux/slices/myCreditSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ClosedTransaction() {
  const { closedTransactions: allClosedTransactions } = useSelector(
    (state) => state.myCredit
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(closedTransactions());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: 21,
              fontFamily: "Montserrat",
              mt: { xs: "-2", lg: "-2" },
            }}
          >
            Closed Transactions
          </Box>
          <Box sx={{ marginTop: "3.5rem" }}>
            <Grid container sx={{ overflow: "hidden" }}>
              <Grid item lg={12} xs={12} sm={12}>
                <Accordion
                  sx={{
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                    border: "none",
                  }}
                >
                  <Grid container sx={{ overflow: "hidden" }}>
                    {allClosedTransactions?.data?.content?.length > 0 ? (
                      allClosedTransactions?.data?.content?.map((loan) => (
                        <Grid item lg={6} xs={12} sm={12} key={loan?.id} px={5}>
                          <Paper
                            elevation={0}
                            sx={{
                              mt: 4,
                              width: { xs: "100%", lg: "90%" },
                              maxWidth: "100%",
                              height: { xs: "fit-content", lg: "213px" },
                              position: "relative",
                              boxShadow:
                                "0px 4px 30px rgba(196, 204, 221, 0.28)",
                            }}
                            //
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography sx={QuickNanoLoan}>
                                {loan?.product?.productName}
                              </Typography>
                              <Typography
                                style={{ color: "green", fontSize: "0.8rem" }}
                              >
                                {loan?.loanStatus}{" "}
                              </Typography>
                            </Box>
                            <Box sx={callToActionCard}>
                              <Box>
                                <NanoImage />
                              </Box>
                              <div>
                                {loan?.product?.productSummary
                                  ?.split(".")
                                  ?.map((detail, id) => (
                                    <Typography key={id} sx={paragraphTitle}>
                                      {detail}
                                    </Typography>
                                  ))}
                                <Link
                                  to={`/${loan?.loanStatus?.toLowerCase()}/${
                                    loan?.transactionId
                                  }`}
                                >
                                  <Button
                                    sx={{
                                      background: "#F2F2F2",
                                      marginTop: "1rem",
                                      width: { xs: "150px", lg: "186px" },
                                      borderRadius: "10px",
                                      height: "42px",
                                      color: "#111E6C",
                                      textTransform: "capitalize",
                                      fontFamily: "Montserrat",
                                      fontWeight: "500",
                                      fontSize: { xs: "10px", lg: "15px" },
                                    }}
                                  >
                                    View History
                                  </Button>
                                </Link>
                              </div>
                            </Box>
                          </Paper>
                        </Grid>
                      ))
                    ) : (
                      <Typography>No Loan Available</Typography>
                    )}
                  </Grid>
                </Accordion>
              </Grid>
            </Grid>
            <Grid container sx={{ overflow: "hidden" }} space={3}>
              {/* Second grid */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
              {/* Third grid */}
            </Grid>
          </Box>
        </Section>
        <BlockSpacer mt={320} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/my-credit">
              <Button
                sx={{
                  background: "#111E6C",
                  width: "328px",
                  height: "54px",
                  color: "white",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
          </Box>
        </Paper>
      </main>
    </>
  );
}

export default ClosedTransaction;

// {/* <Grid container sx={{ fixed, overflow: { xs: "hidden" } }}>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0.5}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   borderRadius: "8px",
//                   border: "1px #fff solid",
//                   boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
//                   overflow: "hidden",
//                   position: "relative",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={PaidOff}>Paid Off</Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/paid-off">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           fontSize: { xs: "10px", lg: "15px" },
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                         }}
//                       >
//                         View History
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0.5}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   position: "relative",
//                   boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={Withdrawn}>Withdrawn</Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/withdrawn">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                           fontSize: { xs: "10px", lg: "15px" },
//                         }}
//                       >
//                         View History
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
//               <Paper
//                 elevation={0.5}
//                 sx={{
//                   mt: 4,
//                   width: { xs: "448px", lg: "448px" },
//                   maxWidth: "100%",
//                   height: { xs: "200px", lg: "213px" },
//                   position: "relative",
//                   boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
//                 }}
//               >
//                 <Typography sx={QuickNanoLoan}>Quick Nano Loan</Typography>
//                 <Typography sx={Declined}>Declined</Typography>
//                 <Box sx={callToActionCard}>
//                   <Box>
//                     <NanoImage />
//                   </Box>
//                   <div>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Typography sx={paragraphTitle}>
//                       . Lorem ipsum dolor sit amet consectetur.
//                     </Typography>
//                     <Link to="/declined">
//                       <Button
//                         sx={{
//                           background: "#F2F2F2",
//                           marginTop: "1rem",
//                           width: { xs: "150px", lg: "186px" },
//                           borderRadius: "10px",
//                           height: "42px",
//                           color: "#111E6C",
//                           textTransform: "capitalize",
//                           fontFamily: "Montserrat",
//                           fontWeight: "500",
//                           fontSize: { xs: "10px", lg: "15px" },
//                         }}
//                       >
//                         View History
//                       </Button>
//                     </Link>
//                   </div>
//                 </Box>
//               </Paper>
//             </Grid>
//           </Grid> */}
