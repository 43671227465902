import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1215px) {
    margin-top: 30%;
    padding: 0 1rem;
  }
  position: relative;
  .ticketTopic {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #242424;
  }
  .ticketId,
  .ticketStatus,
  .ticketDate {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
  }
`;

export const Ticket = {
  fontFamily: "Montserrat",
  fontSize: "15px",
  fontWeight: "400",
  lineHeight: "20px",
};

export const exco = {
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "15px",
  color: "#111E6C",
  letterSpacing: "-0.03em",
  textDecoration: "underline",
};
