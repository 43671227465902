export const previous = {
  background: "#F2F2F2",
  borderRadius: "5px",
  border: "none",
  textTransform: "capitalize",
  width: "132.6px",
  height: "34px",
  fontFamily: "Montserrat",
  fontWeight: "500",
  fontSize: "13px",
  color: "#111E6C",
};

export const next = {
  background: "#111E6C",
  borderRadius: "5px",
  border: "none",
  textTransform: "capitalize",
  width: "132.6px",
  height: "34px",
  fontFamily: "Montserrat",
  fontWeight: "500",
  color: "#FFFFFF",
  "&:hover": {
    color: "#111E6C",
    background: "#F2F2F2",
  },
};
