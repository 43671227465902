import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import React, { useEffect ,useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
// import SummaryCard from "../../../components/Cards/VehicleSummaryCard";
import SummaryCard from "../../../components/Cards/PersonalCard";
import Buttons from "../../../components/Buttons";
import { Section, documentStyle, responsonive } from "./style";
import DocumentIcon from "../../../components/IconSvgs/Document";
import CheckBox from "../../../components/Checkboxs";
import document from "../../../components/Assets/images/document.png";
import SuccessLoan from "../../../components/Modals/Success";
import TopBar from "../../../components/WalletTopBar";
import { useDispatch, useSelector } from "react-redux";
import { submitLoanProcessing, getRequiredDoc } from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import Checkbox from "@mui/material/Checkbox";

function VehicleTermsCondition() {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess(true);
  const handleSuccessClose = () => setSuccess(false);
  const [term, setTerm] = useState(false);
  const navigate = useNavigate();

  const { initializedLoan, submitted, repayment, loading, requiredDocs } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (submitted) {
      dispatch(getRequiredDoc(submitted?.object?.body?.body?.transactionId))
      // dispatch(clearLoan)
    }
    // eslint-disable-next-line
  }, [submitted]);

  useEffect(() => {
    if (requiredDocs) {
      navigate("/upload-loan-documents");
      // dispatch(clearLoan)
    }
    // eslint-disable-next-line
  }, [requiredDocs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitLoanProcessing({
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  return (
    <>
      <TopBar />
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <SummaryCard
              title={"Vehicle Asset Cash Loan"}
              currencyRequested={"Accessible Loan Amount"}
              interestRate={"Interest Rate"}
              interestRatePercentage={"20.00%"}
              tenor={"Tenor"}
              dataNumber={"12/08/2022"}
              feeRate={"Processing Fee Rate"}
              currency={"2,000,000"}
              firstMonthRepayment={"First Monthly Repayment"}
              totalLoanAmount={"100,000"}
              interestPayable={"Interest Payable"}
              days={"Two Months"}
              dueDate={"Due Date"}
              totalLoanDepositWieght={"Insurance Payable"}
              infoPayable={"Interest Payable"}
              feePayable={"Processing Fee Payable"}
              feePayableCurrency={"2,000,000"}
              TotalRepayment={"Total Repayment"}
              LoanDueDate={"Loan Due Date"}
              LegalFeePayable={"Legal Fee Payable"}
              LegalFeeRate={"Legal Fee Rate"}
              totalPaymentAmount={"100,000"}
              StampDutyFeePayable={"Stamp Duty Fee Payable"}
              StampDutyFeeRate={"Stamp Duty Fee Rate"}
              secondMonthRepayment={"Second Monthly Repayment"}
              ManagementFeePayable={"Management Fee Payable"}
              ManagementFeeRate={"Management Fee Rate"}
              InsurancePayable={"Insurance Payable"}
              hundredThousand={"100,000"}
            /> */}
            <SummaryCard
              title={initializedLoan?.data?.body?.productName}
              currencyRequested={"Accessible Loan Amount"}
              currency={`₦${parseFloat(
                repayment?.object?.body?.body?.accessibleLoanAmount
              )}`}
              tenor={"Tenor"}
              days={`${repayment?.object?.body?.body?.tenor} Months`}
              interestRate={"Interest Rate"}
              interestRatePercentage={`${parseFloat(
                repayment?.object?.body?.body?.interestRate
              ).toFixed(4)}%`}
              interestPayable={"Interest Payable"}
              interestPayableCurrency={`₦${parseFloat(
                repayment?.object?.body?.body?.interestPayable
              )}`}
              FeeRate={"Fee Rate"}
              FeeRatePercentage={`${parseFloat(
                repayment?.object?.body?.body?.feeRate
              ).toFixed(4)}%`}
              feePayable={"Fee Payable"}
              feePayableCurrency={`₦${parseFloat(
                repayment?.object?.body?.body?.feePayable
              )}`}
              TotalRepayment={"Total Repayment"}
              totalPaymentAmount={`₦${parseFloat(
                repayment?.object?.body?.body?.totalRepayment
              )}`}
              TotalDebtWeight={"Total Debt Weight"}
              TotalDebtWeightPercentage={`₦${parseFloat(
                repayment?.object?.body?.body?.totalDebtWeight
              )}`}
              // LoanDueDate={"Loan Due Date"}
              // dueDate={"12/10/2022"}
              monthlyRepayments={
                repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={documentStyle}>
              <img src={document} alt="document" style={responsonive} />

              <BlockSpacer mt={20} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    marginLeft: "5rem",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  <Checkbox
                    size={"small"}
                    sx={{
                      color: "#111E6C",
                      "&.Mui-checked": {
                        color: "#111E6C",
                      },
                    }}
                    value={term}
                    checked={term}
                    onChange={()=>setTerm(!term)}
                    inputProps={{ "aria-label": "controlled" }}
                  />I agree to the
                </Typography>
                <div
                  style={{
                    color: "#111E6C",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Terms
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
        <BlockSpacer mt={150} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "89%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: { xs: "300px", lg: "328px" },
                height: "54px",
                color: "#111E6C",
                maxWidth: "100%",
                marginBottom: { xs: "1rem" },
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "22px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Buttons
              color="primary"
              width="328px"
              onClick={handleSubmit}
            >
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Process Loan"
              )}
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {success && (
        <SuccessLoan open={success} handleClose={handleSuccessClose} />
      )}
    </>
  );
}

export default VehicleTermsCondition;
