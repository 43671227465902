import React from "react";

function MoneyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      fill="none"
      viewBox="0 0 25 20"
    >
      <rect
        width="18.62"
        height="12.841"
        x="0.623"
        y="0.701"
        fill="#130F26"
        rx="2"
      ></rect>
      <rect
        width="18.62"
        height="12.841"
        x="5.758"
        y="6.159"
        fill="#242424"
        stroke="#fff"
        strokeWidth="1.2"
        rx="2"
      ></rect>
      <path
        fill="#fff"
        d="M14.943 17.368a.65.65 0 01-.483-.201.692.692 0 01-.198-.504v-.564a.08.08 0 01.134-.058c.055.05.017.14-.057.135a4.58 4.58 0 01-.924-.158 5.812 5.812 0 01-.788-.282.737.737 0 01-.365-.343.91.91 0 01-.079-.454.91.91 0 01.168-.423.64.64 0 01.354-.252c.151-.047.325-.027.523.06.131.075.328.152.591.232.27.074.601.111.995.111.276 0 .493-.027.65-.08.165-.054.28-.124.346-.212a.487.487 0 00.108-.302c0-.101-.026-.185-.079-.252a.548.548 0 00-.236-.192 1.95 1.95 0 00-.463-.16l-1.114-.243c-.611-.14-1.068-.376-1.37-.705-.296-.336-.444-.77-.444-1.3 0-.403.096-.763.286-1.079.197-.322.47-.584.818-.786.292-.165.623-.281.994-.347.056-.01.08.061.031.09a.052.052 0 01-.079-.046V8.47c0-.201.066-.37.198-.504a.65.65 0 01.482-.201.66.66 0 01.493.201.692.692 0 01.197.504v.555a.065.065 0 01-.1.055c-.056-.036-.03-.117.036-.11.183.02.375.059.577.115.289.074.542.178.759.312.15.081.256.192.315.333a.768.768 0 01.07.433.703.703 0 01-.159.383.63.63 0 01-.354.232c-.145.034-.32.007-.523-.08a2.569 2.569 0 00-.512-.162 2.938 2.938 0 00-.71-.07c-.348 0-.617.063-.808.191-.184.128-.276.292-.276.494 0 .134.053.249.158.343.111.087.312.164.6.232l1.124.252c.625.14 1.084.376 1.38.705.302.322.454.742.454 1.26 0 .403-.096.759-.286 1.068-.19.31-.457.561-.799.756-.29.17-.624.288-1 .356-.057.01-.093-.058-.053-.1a.062.062 0 01.107.042v.599c0 .201-.065.37-.197.504a.64.64 0 01-.492.201z"
      ></path>
    </svg>
  );
}

export default MoneyIcon;