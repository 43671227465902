import * as React from "react";
import { Typography, Grid, Paper, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Section } from "./style";
import { paperContainer, left, Account, Number } from "./style";
import TopBar from "../../../../components/MyCreditTopBar";
import useGetTransactionDetailsQuery from "../../../../api/services/transactions/get-transaction-details.api.js";

function WithDrawn() {
  const params = useParams();
  const { data } = useGetTransactionDetailsQuery({
    transactionId: params?.transactionId,
  });
  const transactionDetails = data?.data;
  console.log(transactionDetails);
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container>
            {/* First Card grid */}

            <Grid items xs={12} sm={12} lg={12}>
              <Box>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "0", lg: "-6px" },
                    width: { xs: "400px", lg: "300.41px" },
                    maxWidth: "100%",
                    height: { xs: "250px", lg: "242.56px" },
                    borderRadius: "8.94247px",
                    position: "relative",
                    paperContainer,
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                  }}
                >
                  <Box sx={left}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "300px",
                      }}
                    >
                      <Typography sx={Account}>Account Number</Typography>
                      <Typography
                        sx={Account}
                        style={{
                          color: " #EB5757",
                        }}
                      >
                        Withdrawn
                      </Typography>
                    </Box>

                    <Typography sx={Number}>
                      {transactionDetails?.bankAccount?.accountNumber}
                    </Typography>
                    <Typography
                      sx={Account}
                      style={{ marginTop: "-5px", padding: "1rem" }}
                    >
                      Tenor
                    </Typography>
                    <Typography
                      sx={Number}
                      style={{ marginTop: "-17px", padding: "1rem" }}
                    >
                      {transactionDetails?.tenor} days
                    </Typography>
                    <Typography
                      sx={Account}
                      style={{ marginTop: "-15px", padding: "1rem" }}
                    >
                      Reason For Loan
                    </Typography>
                    <Typography
                      sx={Number}
                      style={{
                        marginTop: "-7px",
                        padding: "1rem",
                        width: "217px",
                      }}
                    >
                      {transactionDetails?.loanReasonMgt?.loanReason ||
                        transactionDetails?.userLoanReason ||
                        "No Details provided"}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>

            <Grid items xs={12} sm={12} lg={12}>
              <Box style={{ position: "relative" }}>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "20px", lg: "20px" },
                    width: { xs: "400px", lg: "300.41px" },
                    maxWidth: "100%",
                    height: { xs: "271.16px", lg: "271.16px" },
                    borderRadius: "8.94247px",
                    paperContainer,
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                  }}
                >
                  <Box sx={left} style={{ position: "absolute" }}>
                    <Typography
                      sx={Number}
                      style={{ fontSize: "15.6493px", marginTop: "5px" }}
                    >
                      Quick Loan Summary
                    </Typography>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: { xs: "center", lg: "space-between" },
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={Account}>Request Loan Amount</Typography>
                      <Typography sx={Account} style={{ paddingLeft: "5rem" }}>
                        Tenor
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Number}
                        style={{ mt: { xs: "", lg: "" } }}
                      >
                        {transactionDetails?.loanSummary?.requestedLoanAmount}
                      </Typography>
                      <Typography sx={Number} style={{}}>
                        {transactionDetails?.loanSummary?.tenor} days
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "50px", lg: "383.41px" },
                        top: "-8px",
                      }}
                    ></Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Account}
                        style={{ mt: { xs: "0", lg: "200px" } }}
                      >
                        Request date
                      </Typography>
                      <Typography
                        sx={Account}
                        style={{
                          top: "100px",
                        }}
                      >
                        Date Declined
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={Number}>
                        {transactionDetails?.loanSummary?.createdAt
                          ?.slice(0, 10)
                          ?.replaceAll("-", "/")}
                      </Typography>
                      <Typography sx={Number} style={{ top: "150px" }}>
                      {transactionDetails?.loanSummary?.updatedAt
                          ?.slice(0, 10)
                          ?.replaceAll("-", "/")}                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
            maxwidth: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                color: "#111E6C",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              <Link to="/closed-transaction">Back</Link>
            </Button>
          </Box>
        </Paper>
      </main>
    </>
  );
}

export default WithDrawn;
