import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./style.module.css";
import Box from "@mui/material/Box";
import LogoAlternate from "../../../components/Logo";
import FormAligner from "../../../components/Hocs/FormAligner";
import { IconButton, TextField } from "@mui/material";
import FormLabel from "../../../components/Forms/Label";
import Button from "../../../components/Buttons";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BlockSpacer from "../../../components/BlockSpacer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

function ResetPasswordSuccess() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParam] = useSearchParams();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
    email: searchParam.get("email"),
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const router = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    submitResetPassword();
  };

  function validatePassword(values) {
    let errors = {};
    var re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;

    if (!values.newPassword) {
      errors.newPassword = "New Password is required";
    } else if (!re.test(values.newPassword)) {
      errors.newPassword =
        "Your password must contain at least one uppercase, one lowercase, a special character, and must be at least 8 characters";
    }
    if (values.confirmPassword !== values.newPassword) {
      errors.cNewPassword = "Password does not match";
    }

    return errors;
  }

  useEffect(() => {
    setErrors(validatePassword(formData));
  }, [formData]);

  const submitResetPassword = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://api-credit.optisoft.com.ng:8090/users/reset-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res?.data);
      if (res?.data) {
        toast.success("Password reset successful");
        localStorage.removeItem("resetPasswordMail");
        router("/");
      }
      setLoading(false);
      // setBvn("");
    } catch (error) {
      toast.error("Password reset failed");
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "newPassword") {
      setTouched({ ...touched, newPassword: true });
    } else if (name === "confirmPassword") {
      setTouched({ ...touched, confirmPassword: true });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <Box
          sx={{
            minWidth: 360,
            maxWidth: 888,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 30px rgba(225, 234, 254, 0.62)",
            borderRadius: "20px",
            paddingTop: "3.75rem",
          }}
        >
          <div className={style.logo}>
            <LogoAlternate />
          </div>
          <BlockSpacer mt={69} />
          <div className={style.title}>Reset Password</div>
          <BlockSpacer mt={18} />
          <div className={style.text}>
            Your password should include at least 8 characters and should
            include a combination of Upper-case, Lowercase and special
            characters (@$#%)
          </div>
          <BlockSpacer mt={29} />
          <div className={style.alignMiddle}>
            <form onSubmit={handleSubmit}>
              <FormAligner>
                <FormLabel text={"Password"} />
                <TextField
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.newPassword && touched?.newPassword && (
                  <span style={{ color: "#FF0000" }}>{errors.newPassword}</span>
                )}
              </FormAligner>
              <BlockSpacer />
              <FormAligner>
                <FormLabel text={"Confirm Password"} />
                <TextField
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {errors.cNewPassword && touched?.confirmPassword && (
                  <span style={{ color: "#FF0000" }}>
                    {errors.cNewPassword}
                  </span>
                )}
              </FormAligner>
              <BlockSpacer mt={35} />
              <div className={style.alignMiddle}>
                <Button
                  color={"primary"}
                  width={"328px"}
                  onClick={handleSubmit}
                  disabled={
                    !(
                      touched?.newPassword &&
                      touched?.confirmPassword &&
                      JSON.stringify(errors) === "{}"
                    )
                  }
                >
                  {loading ? "Loading" : "Reset Password"}
                </Button>
              </div>
              <BlockSpacer mt={60} />
            </form>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default ResetPasswordSuccess;
