import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import dateFormat, { masks } from "dateformat";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import {
  AVAILABLE_CORPORATE_TYPES,
  DEFAULT_COUNTRY_CODE,
  HOW_DID_YOU_HEAR_ABOUT_US,
} from "../../../constants";
import { useMediaQuery } from "../../../hooks/use-media-query";
import { setSignUpBusiness } from "../../../redux/reducers/auth";
import { handlePhoneOnChange } from "../../../utils/handlePhoneNumberChange";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import DoubleRegisterButtons from "../../GridFlex/DoubleRegisterButtons";
import SignupConditionalCheckBox from "../../GridFlex/SignupConditionalCheckBox";

import { useFormik } from "formik";
import { signUpBusinessValidation } from "../../../validation/signUpBusinessValidation";
import FormAligner from "../../Hocs/FormAligner";
import Title from "../../Texts/Title";
import FormLabel from "../Label";
import {
  PhoneTextField,
  styledField,
  StyledTextFieldBox,
  Wrapper,
} from "./style";
import style from "./style.module.css";
import { userSignup, getCorporateTypes } from "../../../redux/slices/authSlice";

function SignupCorporateForm({ individualSignup, setIndividualSignup }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [generatedPhoneNumber, setGeneratedPhoneNumber] = useState("");

  const GetsignUpBusiness = useSelector((state) => state.auth.signUpBusiness);
  const { isSignedUp, loading, corporateTypes } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const APP_URL = process.env.REACT_APP_API_KEY;
  const [error, setError] = useState(null);
  const [selectedSource, setSelectedSource] = useState("");

  const { sources } = useSelector((state) => state.profile);

  useEffect(() => {
    if (isSignedUp) {
      router("/auth/signup/success");
    }
  }, [isSignedUp]);

  useEffect(() => {
    dispatch(getCorporateTypes());
  }, []);

  const handleSourceType = (event) => {
    setSelectedSource(event.target.value);
  };

  const [defaultUserMethod, setDefaultUserMethod] = useState("");
  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };
  const [defaultHearAboutUsMethod, setDefaultHearAboutUsMethod] = useState("");
  const handlHearAboutUsTypeChange = (event) => {
    setDefaultHearAboutUsMethod(event.target.value);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const breakPoint = useMediaQuery("(min-width: 1215px)");

  const router = useNavigate();

  // const handleSubmit = () => {
  //   router("/auth/signup/success");

  const handleUserInputs = (e) => {
    dispatch(
      setSignUpBusiness({
        ...GetsignUpBusiness,
        [e.target.name]: e.target.value,
      })
    );
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      companyName: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmail: "",
      contactPersonphoneNumber: "",
      contactPersonDOB: "",
      password: "",
      confirmPassword: "",
      refferedBy: "",
      terms: false,
      newsLetters: false,
      sourceNotInTheList: "",
    },
    validationSchema: signUpBusinessValidation,
    onSubmit: (values) => {},
  });

  const checkedd = () => {
    setFieldValue("terms", !values.terms);
  };
  const toggleNewsLetter = () => {
    setFieldValue("newsLetters", !values.newsLetters);
  };

  const SignUpCorporate = async () => {
    const data = {
      assited: true,
      bvn: null,
      company: {
        address: null,
        businessType: defaultUserMethod,
        companyType: "CORPORATE_LIMITED",
        contactFirstName: values.contactPersonFirstName,
        contactLastName: values.contactPersonLastName,
        contactMiddleName: null,
        contactDOB: dateFormat(values.contactPersonDOB, "dd-mm-yyyy"),
        contactPersonEmail: values.contactPersonEmail.toLowerCase(),
        contactPersonPhoneNo:
          generatedPhoneNumber?.slice(0, 4) === "+234"
            ? generatedPhoneNumber?.slice(4, 15)
            : generatedPhoneNumber,
        // dateOfInco: dateFormat(
        //   GetsignUpBusiness.contactPersonDOB,
        //   "dd-mm-yyyy"
        // ),
        name: values.companyName,
        // natureOfBusiness: "string",
        // rcNumber: "string",
      },
      email: values.contactPersonEmail.toLowerCase(),
      newsLetters: values.newsLetters,
      password: values.password,
      phone: values.contactPersonphoneNumber,
      refferedBy:
        defaultHearAboutUsMethod !== "OTHER" ? values.refferedBy : null,
      role: "COMPANY",
      source: defaultHearAboutUsMethod,
      sourceOthersId:
        defaultHearAboutUsMethod === "OTHER"
          ? selectedSource === "Not in the list"
            ? null
            : sources?.find((item) => item.name === selectedSource)?.id
          : null,
      sourceNotInTheList:
        selectedSource === "Not in the list" ? values.sourceNotInTheList : null,
      usage: "CREDIT",
      userType: defaultUserMethod,
    };
    if (isValid) {
      dispatch(userSignup(data));
    }
  };

  const sourceNames = sources ? sources?.map((item) => item.name) : [];
  const sourceOption = [...sourceNames, "Not in the list"];

  const handleSubmit = () => {
    SignUpCorporate();
  };

  return (
    <>
      {!breakPoint && (
        <DoubleRegisterButtons
          individualSignup={individualSignup}
          setIndividualSignup={setIndividualSignup}
          wrap={!breakPoint}
        />
      )}
      <Wrapper className={style.form}>
        {breakPoint && <BlockSpacer mt={100} />}
        <div className={style.alignTitle}>
          <Title text={"Sign up"} />
        </div>

        <BlockSpacer mt={69} />
        <div>
          <FormAligner>
            <FormLabel text={"Business Type"} />
            <Select
              value={defaultUserMethod}
              onChange={handleUserTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              displayEmpty
              renderValue={
                defaultUserMethod !== ""
                  ? undefined
                  : () => <span>Select one</span>
              }
              style={{ width: "100%", borderRadius: "6px", outline: "none" }}
            >
              {corporateTypes?.map((option) => (
                <MenuItem key={option?.name} value={option?.name}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Company Name"} />
            <TextField
              placeholder="Optisoft Technologies"
              type="text"
              required
              name="companyName"
              value={values.companyName}
              onChange={(e) => {
                handleUserInputs(e);
                handleChange(e);
              }}
              error={touched.companyName && Boolean(errors.companyName)}
              helperText={touched.companyName && errors.companyName}
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />
          <div className={style.merge}>
            <FormAligner>
              <FormLabel text={"Contact Person First name"} />
              <TextField
                placeholder="John"
                type="text"
                required
                name="contactPersonFirstName"
                value={values.contactPersonFirstName}
                onChange={(e) => {
                  handleUserInputs(e);
                  handleChange(e);
                }}
                error={
                  touched.contactPersonFirstName &&
                  Boolean(errors.contactPersonFirstName)
                }
                helperText={
                  touched.contactPersonFirstName &&
                  errors.contactPersonFirstName
                }
                onBlur={handleBlur}
              />
            </FormAligner>

            <FormAligner>
              <FormLabel text={"Contact Person Last Name"} />
              <TextField
                placeholder="Doe"
                type="text"
                required
                name="contactPersonLastName"
                value={values.contactPersonLastName}
                onChange={(e) => {
                  handleUserInputs(e);
                  handleChange(e);
                }}
                error={
                  touched.contactPersonLastName &&
                  Boolean(errors.contactPersonLastName)
                }
                helperText={
                  touched.contactPersonLastName && errors.contactPersonLastName
                }
                onBlur={handleBlur}
              />
            </FormAligner>
          </div>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Contact Person Email Address"} />
            <TextField
              placeholder="ros@gmail.com"
              type="email"
              required
              name="contactPersonEmail"
              inputProps={{ style: { textTransform: "lowercase" } }}
              value={values.contactPersonEmail}
              onChange={(e) => {
                handleUserInputs(e);
                handleChange(e);
              }}
              error={
                touched.contactPersonEmail && Boolean(errors.contactPersonEmail)
              }
              helperText={
                touched.contactPersonEmail && errors.contactPersonEmail
              }
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />
          <StyledTextFieldBox>
            <FormAligner>
              <FormLabel text={"Contact Person Number"} />
              <TextField
                placeholder="e.g 080xxxxxxxx"
                type="number"
                required
                error={
                  errors.contactPersonphoneNumber &&
                  touched.contactPersonphoneNumber
                }
                helperText={
                  errors.contactPersonphoneNumber &&
                  touched.contactPersonphoneNumber &&
                  errors.contactPersonphoneNumber
                }
                name="contactPersonphoneNumber"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue(
                    "contactPersonphoneNumber",
                    e.target.value.toString()
                  );
                }}
                value={values.contactPersonphoneNumber}
              />
            </FormAligner>
          </StyledTextFieldBox>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Contact Person Date of Birth"} />
            <TextField
              id="date"
              type="date"
              defaultValue="1990-01-01"
              sx={{ width: "100%" }}
              // value={signUpIndividual.dateOfBirth}
              // onChange={(e) => {
              //   dispatch(
              //     setSignUp({
              //       ...signUpIndividual,
              //       dateOfBirth: e.target.value,
              //     })
              //   );
              // }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.contactPersonDOB}
              name="contactPersonDOB"
              onChange={(e) => {
                handleUserInputs(e);
                handleChange(e);
              }}
              error={
                touched.contactPersonDOB && Boolean(errors.contactPersonDOB)
              }
              helperText={touched.contactPersonDOB && errors.contactPersonDOB}
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />

          <FormAligner>
            <FormLabel text={"Password"} />
            <TextField
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="********"
              required
              name="password"
              value={values.password}
              onChange={(e) => {
                handleUserInputs(e);
                handleChange(e);
              }}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Confirm Password"} />
            <TextField
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="********"
              required
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={(e) => {
                handleUserInputs(e);
                handleChange(e);
              }}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"How did you hear about us?"} />
            <Select
              value={defaultHearAboutUsMethod}
              onChange={handlHearAboutUsTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              displayEmpty
              renderValue={
                defaultHearAboutUsMethod !== ""
                  ? undefined
                  : () => <span>Select one</span>
              }
              style={{ width: "100%", borderRadius: "6px", outline: "none" }}
            >
              {HOW_DID_YOU_HEAR_ABOUT_US?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormAligner>
          <BlockSpacer />
          {defaultHearAboutUsMethod === "OTHER" ? (
            <FormAligner>
              <FormLabel text={"Enter source name"} />
              <Select
                value={selectedSource}
                onChange={handleSourceType}
                inputProps={{ "aria-label": "Without label" }}
                fullWidth
                displayEmpty
                renderValue={
                  defaultHearAboutUsMethod !== ""
                    ? undefined
                    : () => <span>Select one</span>
                }
                style={{ width: "100%", borderRadius: "6px", outline: "none" }}
              >
                {sourceOption?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormAligner>
          ) : (
            <FormAligner>
              <TextField
                placeholder={"Input referral code"}
                type="text"
                required
                name="refferedBy"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.refferedBy}
                onBlur={handleBlur}
              />
            </FormAligner>
          )}
          {selectedSource === "Not in the list" &&
            defaultHearAboutUsMethod === "OTHER" && (
              <>
                <BlockSpacer />
                <FormAligner>
                  <FormLabel text={""} />
                  <TextField
                    placeholder="Enter source not listed"
                    type="text"
                    required
                    id="sourceNotInTheList"
                    error={
                      selectedSource === "Not in the list" &&
                      values.sourceNotInTheList === "" &&
                      touched.sourceNotInTheList
                    }
                    helperText={
                      errors.sourceNotInTheList &&
                      touched.sourceNotInTheList &&
                      errors.sourceNotInTheList
                    }
                    name="sourceNotInTheList"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.sourceNotInTheList}
                  />
                </FormAligner>
              </>
            )}
          <BlockSpacer mt={25} />
          <SignupConditionalCheckBox
            defaultChecked={true}
            check={values.newsLetters}
            name="newsLetters"
            title={"Yes, I want to receive newsletters of Promos and Offers"}
            handleCheck={toggleNewsLetter}
          />
          <SignupConditionalCheckBox
            defaultChecked={true}
            name="terms"
            check={values.terms}
            handleCheck={checkedd}
            modified
          />
          <div className={style.alignMiddle}>
            <Button
              color={"primary"}
              width={"328px"}
              onClick={() => handleSubmit()}
              disabled={
                !(
                  isValid &&
                  dirty &&
                  defaultUserMethod !== "" &&
                  (defaultHearAboutUsMethod === "OTHER"
                    ? selectedSource !== ""
                      ? true
                      : false
                    : values.refferedBy !== ""
                    ? true
                    : false) &&
                  (selectedSource === "Not in the list"
                    ? values.sourceNotInTheList !== ""
                      ? true
                      : false
                    : true)
                )
              }
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Sign Up"
              )}
            </Button>
          </div>
          <div className={style.haveAccount}>
            Already have an account?
            <Link to="/auth/login">
              <span className={style.signColor}>Sign in</span>
            </Link>
          </div>
        </div>
        <BlockSpacer mt={100} />
      </Wrapper>
    </>
  );
}

export default SignupCorporateForm;
