import dateFormat, { masks } from "dateformat";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import BlockSpacer from "../../../components/BlockSpacer";
import Button from "../../../components/Buttons";
import ContactCompany from "../../../components/Kyc/CompanyContact";
import CompanyDetails from "../../../components/Kyc/CompanyDetails";
import CoperateContact from "../../../components/Kyc/CoperateContact";
import KycSideScreen from "../../../components/Kyc/KycSideScreen";

import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import axios from "axios";
import { COMPANY_USER_TYPES } from "../../../constants";
import moment from "moment";
import {
  updateUserKyc,
  getUsers,
  getCountries,
  getGenders,
  getCompanyInclusion,
} from "../../../redux/slices/profileSlice";
import BvnSuccess from "../../../components/Modals/BvnSuccess";
import BvnSuccessVerifcation from "../../../components/Modals/BvnsucessVerifcation";
import { corporateKycValidation } from "../../../validation/corporateKycValidation";

function CoperateKyc() {
  const [defaultUserMethod, setDefaultUserMethod] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userkycerror, setUserkycerror] = useState("");
  const [verifed, setVerified] = useState({});
  const [openBvn, setOpenbvn] = useState(false);
  const [bvnSuccess, setBvnSuccess] = useState(false);
  const [verifedLoading, setVerifedLoading] = useState(false);
  const [error, setError] = useState("");
  const [bvnVerified, setBvnVerified] = useState(false);
  const dispatch = useDispatch();

  const BVNDATA = JSON.parse(localStorage.getItem("BVNDATA"));
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const handleBvnClose = () => {
    setOpenbvn(false);
    setBvnSuccess(true);
  };
  const closeBvnModal = () => setBvnSuccess(false);

  const { users: individualUser } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCountries());
    dispatch(getGenders());
    dispatch(getCompanyInclusion());
  }, []);

  const [kycInfo, setKycInfo] = useState({
    isAssited: false,
    isNewsLetters: false,
    phone: "",
    userType: "",
    role: "COMPANY",
    usage: "CREDIT",
    status: "ACTIVE",
    isKyc: true,
    company: {
      businessType: null,
      companyAddress: null,
      contactFirstName: null,
      contactLastName: null,
      contactMiddleName: null,
      dateOfInco: null,
      name: null,
      natureOfBusiness: null,
      rcNumber: null,
    },
    businessDetails: {
      address: null,
      businessType: null,
      city: null,
      countryId: 1,
      dateOfRegisteration: null,
      lgaId: null,
      name: null,
      registrationNumber: null,
      stateId: null,
    },
    contactPersonDetails: {
      address: null,
      annualTurnover: null,
      boardMemberGovernance: null,
      businessStatus: null,
      companySize: 0,
      countryId: 1,
      customerId: null,
      email: null,
      firstName: null,
      lastName: null,
      lgaId: null,
      middleName: null,
      operationStructure: null,
      ownershipStructure: null,
      phone: null,
      stateId: null,
      contactPersonBvn: null,
      contactPersonCity: null,
      contactPersonStateId: null,
      contactPersonAddress: null,
    },
  });

  const [bvn, setBvn] = useState({
    id: "",
    firstName: individualUser?.company?.contactFirstName,
    lastName: individualUser?.company?.contactLastName,
    isSubjectConsent: true,
    phoneNumber: individualUser?.phone,
  });

  React.useEffect(() => {
    setKycInfo({
      ...kycInfo,
      phone: individualUser?.phone,
      userType: individualUser?.userType,
      // dateOfBirth: individualUser?.company?.dateOfInco,
      company: {
        ...kycInfo.company,
        businessType: individualUser?.userType,
        contactFirstName: individualUser?.company?.contactFirstName,
        contactLastName: individualUser?.company?.contactLastName,
        name: individualUser?.company?.name,
      },
      businessDetails: {
        ...kycInfo.businessDetails,
        countryId: 1,
        businessType: individualUser?.userType,
        name: individualUser?.company?.name,
        lgaId: userDetails?.lgaId,
      },
      contactPersonDetails: {
        ...kycInfo.contactPersonDetails,
        firstName: individualUser?.company?.contactFirstName,
        lastName: individualUser?.company?.contactLastName,
        email: individualUser?.email,
        phone: individualUser?.phone,
        countryId: 1,
      },
    });
  }, [individualUser]);

  React.useEffect(() => {
    setBvn({
      ...bvn,
      phoneNumber: kycInfo.phone,
      isSubjectConsent: true,
      dateOfBirth: individualUser?.company?.contactDOB
        ? moment(
            individualUser.company?.contactDOB[0] +
              "-" +
              individualUser.company?.contactDOB[1] +
              "-" +
              individualUser.company?.contactDOB[2],
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD")
        : "",
      firstName: individualUser?.company?.contactFirstName,
      lastName: individualUser?.company?.contactLastName,
    });
  }, [individualUser, kycInfo.phone]);

  React.useEffect(() => {
    if (userDetails?.userType === "SOLE_PROPRIETOR_REGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[0]);
    } else if (userDetails?.userType === "SOLE_PROPRIETOR_UNREGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[1]);
    } else if (userDetails?.userType === "CORPORATE_LISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[2]);
    } else if (userDetails?.userType === "CORPORATE_UNLISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[3]);
    }
  }, [userDetails]);

  const router = useNavigate();

  const authenticated = JSON.parse(localStorage.getItem("user"));

  const SubmutKyc = async () => {
    let userType;
    if (
      individualUser?.userType === "SOLE_PROPRIETOR_REGISTERED" ||
      individualUser?.userType === "SOLE_PROPRIETOR_UNREGISTERED"
    ) {
      userType = "SOLE_PROPRIETORSHIP";
    } else if (
      individualUser?.userType === "CORPORATE_LISTED" ||
      individualUser?.userType === "CORPORATE_UNLISTED"
    ) {
      userType = "CORPORATE_LIMITED";
    }

    const formData = {
      ...kycInfo,
      company: {
        ...kycInfo.company,
        businessType: userType,
      },
    };
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.put(
        `https://api-credit.optisoft.com.ng:8090/auth/users`,
        formData,
        config
      );
      console.log(res);
      setLoading(false);
      const updateUser = {
        ...authenticated,
        fullName: `${formData.company.name}`,
        kyc: true,
      };
      localStorage.setItem("user", JSON.stringify(updateUser));
      router("/dashboard");
    } catch (err) {
      console.error(err);
      setLoading(false);
      setUserkycerror(err?.response?.data?.message);
    }
  };

  const verifyBvn = async () => {
    setVerifedLoading(true);
    try {
      const res = await axios.post(
        `https://api-credit.optisoft.com.ng:8090/auth/verify-bvn/credit`,
        bvn,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res?.data);
      localStorage.setItem("BVNDATA", JSON.stringify(res?.data));

      if (res?.data?.data?.status === "found") {
        if (res?.data?.isNameMatched) {
          setBvnSuccess(true);
        } else {
          setOpenbvn(true);
        }
        setBvnVerified(true);
      }

      setVerifedLoading(false);
      // setBvn("");
    } catch (error) {
      console.log(error);
      setVerifedLoading(false);
      setError(error?.response?.data?.message);
      setBvnVerified(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    SubmutKyc();
  };

  useEffect(() => {
    corporateKycValidation(kycInfo);
  }, [kycInfo]);

  useEffect(() => {
    toast.error(userkycerror);
  }, [userkycerror]);
  return (
    <>
      <ToastContainer />

      {loading2 ? (
        <div className="loader">
          <div className="loader__content">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.left}>
            <KycSideScreen />
          </div>
          <div className={style.right}>
            <div className={style.formWrap}>
              <div className={style.head}>
                Hello {individualUser?.company?.name},
              </div>
              <BlockSpacer mt={20} />
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {userkycerror}
              </p>

              <div className={style.subHead}>
                Kindly update your profile, it will only take a few minutes
              </div>
              <BlockSpacer mt={82} />
              <CompanyDetails
                defaultUserMethod={defaultUserMethod}
                setDefaultUserMethod={setDefaultUserMethod}
                info={kycInfo}
                setKycInfo={setKycInfo}
                itemOptions={COMPANY_USER_TYPES}
              />

              <BlockSpacer mt={82} />

              {(defaultUserMethod === COMPANY_USER_TYPES[0] ||
                defaultUserMethod === COMPANY_USER_TYPES[1]) && (
                <>
                  <ContactCompany
                    info={kycInfo}
                    setKycInfo={setKycInfo}
                    setBvn={setBvn}
                    verified={verifed}
                    setVerified={setVerified}
                    bvnVerified={bvnVerified}
                    verifyBvn={verifyBvn}
                    verifedLoading={verifedLoading}
                    error={error}
                  />
                </>
              )}

              <BlockSpacer mt={82} />
              {(defaultUserMethod === COMPANY_USER_TYPES[2] ||
                defaultUserMethod === COMPANY_USER_TYPES[3]) && (
                <>
                  <CoperateContact
                    kycInfo={kycInfo}
                    setKycInfo={setKycInfo}
                    setBvn={setBvn}
                    verified={verifed}
                    setVerified={setVerified}
                    bvnVerified={bvnVerified}
                    verifyBvn={verifyBvn}
                    verifedLoading={verifedLoading}
                    error={error}
                  />
                </>
              )}
              <BlockSpacer mt={82} />
            </div>
            <BlockSpacer mt={84} />
            <div className={style.bottomContainer}>
              {/* <Link to="/profile/company/info"> */}
              <Button
                color="primary"
                width={"328px"}
                // disabled={!bvnVerified && corporateKycValidation(kycInfo)}
                onClick={submitHandler}
                // disabled={BVNDATA.success !== true ? true : false}
              >
                {loading ? <CircularProgress /> : "Save and Continue"}
              </Button>
              {/* </Link> */}
            </div>
          </div>
          {openBvn && (
            <BvnSuccessVerifcation
              open={openBvn}
              handleClose={handleBvnClose}
              handleChange={(firstName, lastName) =>
                setKycInfo({
                  ...kycInfo,
                  company: {
                    ...kycInfo.company,
                    contactFirstName: firstName,
                    contactLastName: lastName,
                  },
                  contactPersonDetails: {
                    ...kycInfo.contactPersonDetails,
                    firstName: firstName,
                    lastName: lastName,
                  },
                })
              }
            />
          )}
          {bvnSuccess && (
            <BvnSuccess open={bvnSuccess} handleClose={closeBvnModal} />
          )}
        </div>
      )}
    </>
  );
}

export default CoperateKyc;
