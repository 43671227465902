import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1244px) {
    margin-top: 25%;
  }
`;

export const choosePayment = {
  padding: "1rem",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "22px",
  letterSpacing: "-0.5px",
  color: "#222222",
};

export const PaymantBox = {
  width: "405px",
  padding: "1rem",
};

export const CardStyle = {
  width: "503px",
  maxWidth: "100%",
  height: "789px",
  background: "rgba(28, 68, 141, 0.03)",
  position: "relative",
};

export const PayStyle = {
  fontFamily: "Montserrat",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "-0.03em",
  padding: "1.5rem",
};

export const Card = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "17px",
  lineHeight: "28px",
  letterSpacing: "-0.03em",
  margin: "2rem",
};

export const CardNum = {
  color: "#828282",
  fontFamily: "Montserrat",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "",
  paddingLeft: "2rem",
  paddingTop: "-4rem",
};
