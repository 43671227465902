import * as yup from "yup";

export const basicSchema = yup.object().shape({
  messageTitle: yup.string().required("Message Title is required"),
  messageDescription: yup.string().required("Message Description is required"),
});



export const feedbackSchema = yup.object().shape({
messageReply: yup.string().required("required")

});


export const editTicketSchema = yup.object().shape({
  messageTitle: yup.string().required("Message Title is required"),
  messageDescription: yup.string().required("Message Description is required"),
  status:yup.string().required("status is required"),
  ticketid: yup.string().required("Ticked ID is required").nullable()
})