import React, { Component } from "react";
import { Section } from "../../get-credit/style";
import paper from "../../../components/Assets/images/paper.png";
import Load from "../../../components/Assets/icons/load.svg";
import icon from "../../../components/Assets/icons/icon.svg";
import DocumentSuccessModal from "../../../components/Modals/DocumentSuccess";
import Buttons from "../../../components/Buttons/index.jsx";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/system";
import { nationalid } from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer";
import { Cancel, TroubleshootOutlined } from "@mui/icons-material/";
import { LinearProgress } from "@mui/material";
import { container1 } from "./style.js";
import LinearDeterminate from "../../../components/ProgressBar/progress";
import TopBar from "../../../components/MyCreditTopBar";

class RequiredDocument extends Component {
  constructor() {
    super();
    this.state = { openSuccessDocument: false };
    this.state = { data: false };
    this.state = { license: false };
    this.state = { bill: false };
    this.state = { form: false };
    this.state = { dataview: false };
    this.state = { licenseview: false };
    this.state = { billview: false };
    this.state = { formview: false };
    this.state = { buttonchange: false };
  }
  buttonChange() {
    this.setState({ buttonchange: true });
  }
  documentSuccess() {
    this.setState({ openSuccessDocument: true });
  }
  documentSuccessClose() {
    this.setState({ openSuccessDocument: false });
  }
  uploadData() {
    this.setState({ data: true });
  }
  handleData() {
    this.setState({ data: false });
  }
  handleLicense() {
    this.setState({ license: false });
  }
  handleBill() {
    this.setState({ bill: false });
  }
  handleForm() {
    this.setState({ form: false });
  }
  uploadLicense() {
    this.setState({ license: true });
  }
  uploadBill() {
    this.setState({ bill: true });
  }
  uploadForm() {
    this.setState({ form: true });
  }

  DataView() {
    this.setState({ dataview: true });
  }
  LicenseView() {
    this.setState({ licenseview: true });
  }
  BillView() {
    this.setState({ billview: true });
  }
  FormView() {
    this.setState({ formview: true });
  }

  render() {
    return (
      <>
        <TopBar />
        <Section style={{ overflow: { xs: "hidden" } }}>
          <Container style={{ overflow: { xs: "hidden" } }}>
            <Box
              sx={{
                fontWeight: 700,
                fontSize: 21,
                fontFamily: "Montserrat",
                mt: { xs: "-2", lg: "-2" },
                marginLeft: "-50px",
              }}
            >
              Required Documents
            </Box>

            <BlockSpacer sx={{ mt: "53px" }} />

            <Box sx={container1}>
              <Box sx={nationalid}>
                <img
                  src={paper}
                  alt="Paper Negative"
                  style={{ marginTop: "20px" }}
                />
                <Box sx={{ pt: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14.09px",
                    }}
                  >
                    National ID Card
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "11.75px",
                      width: "77px",
                      mt: "2",
                    }}
                  >
                    {!this.state.data && <div>jpg, pdf.2MB</div>}
                    {this.state.data && (
                      <div>
                        <img
                          src={Load}
                          alt="progress"
                          onClick={() => {
                            this.DataView();
                            this.handleData();
                            this.buttonChange();
                          }}
                        />
                      </div>
                    )}
                  </Typography>
                </Box>
              </Box>
              {this.state.dataview && (
                <img
                  src={icon}
                  alt=""
                  onClick={() => {
                    console.log("clicked");
                  }}
                />
              )}
              <Box>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: "none" }}
                    id="upload-file"
                    y
                    name="upload-file"
                    type="file"
                  />

                  <Button
                    sx={{
                      width: "89.22px",
                      height: "27px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontSize: "10.5706px",
                      background: "#E7E7E7",
                      color: "#828282",
                    }}
                    component="span"
                    onClick={() => {
                      this.uploadData();
                    }}
                  >
                    {!this.state.dataview && <div>Choose File</div>}
                    {this.state.dataview && <div>View</div>}
                  </Button>
                </label>

                {/* <Button
                  sx={{
                    width: "89.22px",
                    marginLeft: { xs: "75px", lg: "166.76px" },
                    height: "27px",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat",
                    fontSize: "10.5706px",
                    background: "#E7E7E7",
                    color: "#828282",
                  }}
                ></Button> */}
              </Box>
            </Box>

            <BlockSpacer sx={{ mt: "53px" }} />

            <Box sx={container1}>
              <Box sx={nationalid}>
                <img
                  src={paper}
                  alt="Paper Negative"
                  style={{ marginTop: "20px" }}
                />
                <Box sx={{ pt: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14.09px",
                    }}
                  >
                    Driver's License
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "11.75px",
                      width: "77px",
                      mt: "2",
                    }}
                  >
                    {!this.state.license && <div>jpg, pdf.2MB</div>}
                    {this.state.license && (
                      <div>
                        <img
                          src={Load}
                          alt="progress"
                          onClick={() => {
                            this.LicenseView();
                            this.handleLicense();
                            this.buttonChange();
                          }}
                        />
                      </div>
                    )}
                  </Typography>
                </Box>
              </Box>
              {this.state.licenseview && <img src={icon} alt="" />}
              <Box>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: "none" }}
                    id="upload-file"
                    name="upload-file"
                    type="file"
                  />

                  <Button
                    sx={{
                      width: "89.22px",
                      height: "27px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontSize: "10.5706px",
                      background: "#E7E7E7",
                      color: "#828282",
                    }}
                    component="span"
                    onClick={() => this.uploadLicense()}
                  >
                    {!this.state.licenseview && <div>Choose File</div>}
                    {this.state.licenseview && <div>View</div>}
                  </Button>
                </label>
              </Box>
            </Box>

            <BlockSpacer sx={{ mt: "53px" }} />

            <Box sx={container1}>
              <Box sx={nationalid}>
                <img
                  src={paper}
                  alt="Paper Negative"
                  style={{ marginTop: "20px" }}
                />
                <Box sx={{ pt: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14.09px",
                    }}
                  >
                    Utility Bill
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "11.75px",
                      width: "77px",
                      mt: "2",
                    }}
                  >
                    {!this.state.bill && <div>jpg, pdf.2MB</div>}
                    {this.state.bill && (
                      <div>
                        <img
                          src={Load}
                          alt="progress"
                          onClick={() => {
                            this.BillView();
                            this.handleBill();
                            this.buttonChange();
                          }}
                        />
                      </div>
                    )}
                  </Typography>
                </Box>
              </Box>
              {this.state.billview && <img src={icon} alt="" />}
              <Box>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: "none" }}
                    id="upload-file"
                    name="upload-file"
                    type="file"
                  />

                  <Button
                    sx={{
                      width: "89.22px",
                      height: "27px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontSize: "10.5706px",
                      background: "#E7E7E7",
                      color: "#828282",
                    }}
                    onClick={() => this.uploadBill()}
                    component="span"
                  >
                    {!this.state.billview && <div>Choose File</div>}
                    {this.state.billview && <div>View</div>}
                  </Button>
                </label>
              </Box>
            </Box>

            <BlockSpacer sx={{ mt: "53px" }} />

            <Box sx={container1}>
              <Box sx={nationalid}>
                <img
                  src={paper}
                  alt="Paper Negative"
                  style={{ marginTop: "20px" }}
                />
                <Box sx={{ pt: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14.09px",
                    }}
                  >
                    Company form CO7
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "11.75px",
                      width: "77px",
                      mt: "2",
                    }}
                  >
                    {!this.state.form && <div>jpg, pdf.2MB</div>}
                    {this.state.form && (
                      <div>
                        <img
                          src={Load}
                          alt="progress"
                          onClick={() => {
                            this.FormView();
                            this.handleForm();
                            this.buttonChange();
                          }}
                        />
                      </div>
                    )}
                  </Typography>
                </Box>
              </Box>
              {this.state.formview && <img src={icon} alt="" />}
              <Box>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: "none" }}
                    id="upload-file"
                    name="upload-file"
                    type="file"
                  />

                  <Button
                    sx={{
                      width: "89.22px",
                      height: "27px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontSize: "10.5706px",
                      background: "#E7E7E7",
                      color: "#828282",
                    }}
                    component="span"
                    onClick={() => this.uploadForm()}
                  >
                    {!this.state.formview && <div>Choose File</div>}
                    {this.state.formview && <div>View</div>}
                  </Button>
                </label>
              </Box>
            </Box>
          </Container>

          {this.state.openSuccessDocument && (
            <DocumentSuccessModal
              open={this.state.openSuccessDocument}
              handleClose={() => {
                this.documentSuccessClose();
              }}
            />
          )}
        </Section>
        <BlockSpacer mt={322} />
        <Paper
          elevation={0}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "87%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: " 8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/documents-upload">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
            {!this.state.buttonchange && (
              <Buttons color="primary" width="328px">
                Save
              </Buttons>
            )}
            {this.state.buttonchange && (
              <Buttons
                color="primary"
                width="328px"
                onClick={() => this.documentSuccess()}
              >
                Send
              </Buttons>
            )}
          </Box>
        </Paper>
      </>
    );
  }
}

export default RequiredDocument;
