import styled from "@emotion/styled";
import { borderRadius } from "@mui/system";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1224px) {
    margin-top: 30%;
  }
`;

export const titleContainer = {
  padding: "1rem",
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "21px",
};

export const contentContent = {
  padding: "1rem",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "148.4 %",
  color: "#4F4F4F",
};

export const paragraphtitle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineheight: "148.4%",
  display: "flex",
  letterSpacing: "-0.01em",
  color: "#4F4F4F",
};

// export const
export const requirements = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "22px",
  letterSpacing: "-0.5px",
  padding: "1rem",
};

export const previous = {
  border: 0,
  borderRadius: "5px",
  background: "#F2F2F2",
};
