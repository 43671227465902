import HttpService from "./HttpService";

export const getActiveTransactionsService = () => {
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/active`;
  return http.getData(url);
};

export const getPendingTransactionsService = () => {
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/pending`;
  return http.getData(url);
};

export const getClosedTransactionsService = () => {
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/closed`;
  return http.getData(url);
};

export const repayActiveLoanService = (formData) => {
  const { transactionId, ...data } = formData;
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/${transactionId}/repay-part-of-loan`;
  return http.postDataWithToken(data, url);
};

export const getLoanRepaymentHistoryService = (formData) => {
  const { transactionId } = formData;
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/${transactionId}/get-repayment-history`;
  return http.postDataWithToken({}, url);
};

export const payoffActiveLoanService = (formData) => {
  const { transactionId, ...data } = formData;
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/${transactionId}/pay-off-loan`;
  return http.postDataWithToken(data, url);
};

export const withdrawLoanRequestService = (formData) => {
  const { transactionId } = formData;
  const http = new HttpService();
  const url = `/auth/my-credit/withdraw-loan-request/${transactionId}`;
  return http.postDataWithToken({}, url);
};

export const requestRequiredDocService = (formData) => {
  const { transactionId } = formData;
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/required-docs/${transactionId}`;
  return http.getData(url);
};

export const submitUnderwriterDocService = (formData) => {
  const http = new HttpService();
  const url = `/auth/my-credit/transactions/submit-underwriter-docs`;
  return http.postDataWithToken(formData, url);
};