import React, { useEffect, useState, useRef } from "react";
import { Section, choosePayment, PaymantBox, Title, id, it } from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import SummaryCard from "../../../components/Cards/PersonalCard";
import { Typography, Box, Paper, Button } from "@mui/material";
import MasterCardIcon from "../../../components/IconSvgs/MasterCardIcon.jsx";
import MoneyTransferIcon from "../../../components/IconSvgs/MoneyTransfer.jsx";
import bankicon from "../../../components/Assets/icons/bankicon.svg";
import LineBreak from "../../../components/Hocs/LineBreak/index.jsx";
import Buttons from "../../../components/Buttons";
import Checkbox from "@mui/material/Checkbox";
import paper from "../../../components/Assets/images/paper.png";
import { Link } from "react-router-dom";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { uploadRequiredDoc, clearLoan } from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import Check from "../../../assets/checked.png";
import SuccessLoan from "../../../components/Modals/Success(pe)/index";
import { toast } from "react-toastify";

const UploadLoanDocs = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess(true);
  const [term, setTerm] = useState(false);
  const handleSuccessClose = () => setSuccess(false);
  const [base64File, setBase64File] = useState([]);

  const { initializedLoan, submitted, loanValue, loading, requiredDocs, uploadDocs } =
    useSelector((state) => state.getCredit);

  useEffect(() => {
    if(uploadDocs) {
      handleSuccess();
    }
    // eslint-disable-next-line
  }, [uploadDocs]);
  

  const handleFileChange = (e, name, id) => {
    const { files } = e.target;

    const encodedFileBase64 = (file) => {
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        console.log("first fil", file)
        reader.onload = () => {
          if (base64File?.some((item) => item?.docName === name)) {
            const updatedList = base64File?.map((item) => {
              if (item?.docName === name) {
                return { ...item, docName: name };
              }
              return item;
            });
            setBase64File(updatedList);
          } else {
            setBase64File([
              ...base64File,
              {
                checkListID: id,
                docName: name,
                filePathBase64: reader.result.split("base64,")[1],
                transID: `${submitted?.object?.body?.body?.transactionId}`,
                mimeType: `.${file["name"]?.split(".")[1]}`
              },
            ]);
          }
        };

        reader.onerror = (error) => {};
      }
    };

    if (
      files[0]?.size <= 2000000 &&
      (files[0]?.type === "image/jpeg" ||
        files[0]?.type === "application/pdf" ||
        files[0]?.type === "image/png")
    ) {
      encodedFileBase64(files[0]);
    } else {
      toast.error("File format should be .png, .jpeg or .pdf and less than 2mb")
    }
    e.target.value = null;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const bankstatement = requiredDocs?.find(
      (item) => item?.documentName === "6 months statement of account" ||
      item?.documentName === "Stamped Bank Statement - 6 Months (All Pages must be duly stamped and signed by bank)"
    );
    dispatch(
      uploadRequiredDoc({
        documentRequirement: [
          ...base64File,
          {
            checkListID: bankstatement?.documentID,
            docName: bankstatement?.documentName,
            // filePathBase64: loanValue?.object?.body?.body?.base64BankStatement,
            filePathBase64: "statement",
            transID: `${submitted?.object?.body?.body?.transactionId}`,
            mimeType: ".pdf",
          },
        ],
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  return (
    <>
      <TopBar />
      <Section>
        <BlockSpacer mt={20} />
        <Typography sx={Title}>Upload Requirements</Typography>
        <BlockSpacer mt={20} />
        {requiredDocs &&
          requiredDocs?.map(
            (reqDocument, id) =>
              (reqDocument?.documentName !== "6 months statement of account" &&
              reqDocument?.documentName !== "Stamped Bank Statement - 6 Months (All Pages must be duly stamped and signed by bank)") && (
                <Box
                  key={id}
                  display={"flex"}
                  sx={{
                    justifyContent: "space-between",
                    gap: "2rem",
                    textAlign: "center",
                    width: { xs: "90%", lg: "40%" },
                    maxWidth: "100%",
                    marginLeft: { lg: "1rem", xs: "1rem" },
                    marginBottom: "2rem",
                  }}
                >
                  <Box display={"flex"}>
                    <img src={paper} alt="docx." className="fileImg" />
                    <Box sx={{ marginTop: "1rem" }}>
                      <Typography sx={id}>
                        {reqDocument?.documentName}
                        {reqDocument?.ismandatory === "Yes" && (
                          <span style={{ fontSize: "18px", color: "red" }}>
                            *
                          </span>
                        )}{" "}
                      </Typography>
                      <Typography sx={it}>
                        jpg, png. PDF 2MB{" "}
                        {base64File?.some(
                          (doc) => doc?.docName === reqDocument?.documentName
                        ) ? (
                          <img
                            className="image-fluid position-absolute"
                            src={Check}
                            alt="check"
                            width="15"
                          />
                        ) : null}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "1rem", position: "relative" }}>
                    <label htmlFor="upload-file">
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          left: 0,
                          top: 0,
                          zIndex: 3,
                          cursor: "pointer",
                        }}
                        id={reqDocument?.documentName}
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            reqDocument?.documentName,
                            reqDocument?.documentID
                          )
                        }
                        name={reqDocument?.documentName}
                        type="file"
                      />

                      <Button
                        sx={{
                          width: "95px",
                          height: "34px",
                          textTransform: "capitalize",
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          background: "#E7E7E7",
                          color: "#828282",
                          letterSpacing: "-0.04em",
                          lineHeight: "16px",
                        }}
                        component="span"
                      >
                        Choose File
                      </Button>
                    </label>
                  </Box>
                </Box>
              )
          )}

        <BlockSpacer mt={150} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "100%", sm: "92%", lg: "87%" },
            margin: "1rem",
            marginLeft: { lg: "-1rem", xs: "auto" },
            marginRight: { xs: "auto" },
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/get-credit/loans">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: { lg: "328px", xs: "300px" },
                  maxWidth: "100%",
                  height: "54px",
                  marginLeft: { xs: "2rem" },
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Cancel
              </Button>
            </Link>
            <Buttons
              color="primary"
              width="300px"
              sx={{ maxWidth: "100%", marginLeft: { xs: "2rem" } }}
              marginLeft="2rem"
              onClick={handleSubmit}
            >
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Proceed"
              )}
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {success && (
        <SuccessLoan open={success} handleClose={handleSuccessClose} />
      )}
    </>
  );
};

export default UploadLoanDocs;
