import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { styled as style } from "@mui/system";

export const PhoneTextField = styled.div`
  width: 100%;
  .MuiFormControl-root.MuiTextField-root {
    border: 1px solid #e5e5e5 !important;
    &:hover {
    border: 1px solid #e5e5e5 !important;
    }
    &:focus {
    border: 2px solid #1F56C3 !important;
  }
  }
  .MuiInputBase-root.MuiInput-root {
    border-radius: 10px;
    font-size: 0.9rem;
    &::before {
      border-bottom: none;
      transition: none;
    }
    &::after {
      border-bottom: none;
    }
    :hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .MuiInputBase-input.MuiInput-input:-webkit-autofill {
      -webkit-box-shadow:  "0 0 0px 1000px #E8F0FE inset"};
      -webkit-text-fill-color: "#000";
      caret-color:"#000";
    }
    .phone-input {
      width: 100%;
      height: 54px;
      border: 1.5px solid #e0e0e0 !important;
      border-radius: 8px;
      padding: 15px 15px 15px 80px;
      position: relative;
      font-weight: 500;
      font-size: 17px;
      line-height: 16px;
      color: #333333;
      background: #ffffff !important;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding-right: 20px;
  overflow-x: hidden;
  input.input-error,
  select.input-error {
    border-color: #fc8181;
  }
  input.success,
  select.success {
    border-color: #1cbd67;
  }
  .suc {
    border-color: #fc8181;
  }
`;

export const styledField = {
  width: "100%",
  borderRadius: "5px",
  padding: ".9rem",
  border: "1px solid rgba(255, 255, 255, 0.23)",
};

export const StyledTextFieldBox = style(Box)({
  width: "100%",
  marginBottom: "1.2rem",
});

export const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;
