import React, { useEffect, useState, useRef } from "react";
import { Section, choosePayment, PaymantBox, Title, idx, it } from "./style.js";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import SummaryCard from "../../../../components/Cards/PersonalCard";
import { Typography, Box, Paper, Button } from "@mui/material";
import MasterCardIcon from "../../../../components/IconSvgs/MasterCardIcon.jsx";
import MoneyTransferIcon from "../../../../components/IconSvgs/MoneyTransfer.jsx";
import bankicon from "../../../../components/Assets/icons/bankicon.svg";
import LineBreak from "../../../../components/Hocs/LineBreak/index.jsx";
import Buttons from "../../../../components/Buttons";
import Checkbox from "@mui/material/Checkbox";
import paper from "../../../../components/Assets/images/paper.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";

import { ClipLoader } from "react-spinners";
import Check from "../../../../assets/checked.png";
import SuccessLoan from "../../../../components/Modals/Success(pe)/index";
import SuccessWithdraw from "../../../../components/Modals/WithdrawSuccessModal";
import { toast } from "react-toastify";
import useGetRequiredDocsQuery from "../../../../api/services/transactions/get-required-docs.api.js";
import useSubmitUnderWriterDocsMutation from "../../../../api/services/transactions/submit-underwriter-docs.api.js";

const UploadPendingDocs = () => {
  const { mutateAsync: submitUnderwriterDoc, isLoading: submittingDocs } =
    useSubmitUnderWriterDocsMutation();
  // const [success, setSuccess] = useState(false);
  // const handleSuccess = () => setSuccess(true);
  // const [term, setTerm] = useState(false);
  // const handleSuccessClose = () => setSuccess(false);
  const params = useParams();

  // const [openSuccessWithdraw, setOpenSuccessWithdraw] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const handleSuccessWithdraw = () => {
  //   setOpenSuccessWithdraw(true);
  // };

  // const hanldeSuccessWithdrawClose = () => {
  //   setOpenSuccessWithdraw(false);
  // };

  // const {
  //   initializedLoan,
  //   submitted,
  //   loanValue,
  //   loading,
  //   requiredDocs,
  //   uploadDocs,
  // } = useSelector((state) => state.getCredit);
  const navigate = useNavigate();
  const { data, isLoading } = useGetRequiredDocsQuery({
    transactionId: params?.transactionId,
  });
  const requestedDocs = data?.data;
  // console.log(requestedDocs);
  const requiredDocs = requestedDocs
    ?.filter((item) => !!item.mandatory)
    .map((item) => item.documentChecklistItem);

  // console.log(requiredDocs);
  const handleSubmit = (e) => {
    const selectedDocs = selectedFiles.map((item) => item.DocName);

    const isSelectedAllRequiredDocs = requiredDocs.every((item) =>
      selectedDocs.includes(item)
    );

    e.preventDefault();
    // console.log("required docs", requiredDocs.length);
    // console.log("selected required docs", selectedRequiredFiles.length);
    if (!isSelectedAllRequiredDocs)
      return toast.error("Upload all necessary document");
    const checklistItems = selectedFiles.map((item) => ({
      CheckListID: item.CheckListID,
      DocName: item?.DocName,
      filePathBase64: item.filePathBase64,
      mimeTye: item.mimeTye,
      transId: item.transId,
    }));
    submitUnderwriterDoc({
      checklistItems,
      transactionId: params?.transactionId,
    }).then((res) => {
      if (!res?.errorMessage) {
        toast.success(res?.message ?? "");
        navigate(-1);
      } else {
        toast.success(res?.message ?? res.errorMessage ?? "");
      }
    });
  };

  const handleFileChange = (data) => {
    const sizeInMb = (data.filePathBase64.size / (1024 * 1024)).toFixed(2);
    if (sizeInMb > 2.0) return toast.error("File size should not exceed 2MB");
    const reader = new FileReader();
    reader.readAsDataURL(data.filePathBase64);
    reader.onload = () => {
      if (selectedFiles.find((item) => item.DocName === data.DocName)) {
        setSelectedFiles((prev) =>
          prev.map((item) =>
            item.DocName === data.DocName
              ? { ...item, filePathBase64: reader.result }
              : item
          )
        );
      } else {
        setSelectedFiles((prev) => [
          ...prev,
          { ...data, filePathBase64: reader.result },
        ]);
      }
    };
  };

  return (
    <>
      <TopBar />
      <Section>
        <BlockSpacer mt={20} />
        <Typography sx={Title}>Upload Requirements</Typography>
        <BlockSpacer mt={20} />
        {isLoading ? (
          <ClipLoader color={"#00000"} loading={submittingDocs} size={20} />
        ) : (
          requestedDocs?.map((reqDocument, id) => (
            // reqDocument?.documentChecklistItem !==
            //   "6 months statement of account" &&
            // reqDocument?.documentChecklistItem !==
            //   "Stamped Bank Statement - 6 Months (All Pages must be duly stamped and signed by bank)" &&
            <Box
              key={id}
              display={"flex"}
              sx={{
                justifyContent: "space-between",
                gap: "2rem",
                textAlign: "center",
                width: { xs: "90%", lg: "40%" },
                maxWidth: "100%",
                marginLeft: { lg: "1rem", xs: "1rem" },
                marginBottom: "2rem",
              }}
            >
              <Box display={"flex"}>
                <img src={paper} alt="docx." className="fileImg" />
                <Box sx={{ marginTop: "1rem" }}>
                  <Typography sx={idx}>
                    {reqDocument?.documentChecklistItem}
                    {reqDocument?.mandatory === 1 && (
                      <span style={{ fontSize: "18px", color: "red" }}>*</span>
                    )}{" "}
                  </Typography>
                  <Typography sx={it}>
                    jpg, png. PDF 2MB{" "}
                    {selectedFiles?.some(
                      (doc) =>
                        doc?.DocName === reqDocument?.documentChecklistItem
                    ) ? (
                      <img
                        className="image-fluid position-absolute"
                        src={Check}
                        alt="check"
                        width="15"
                      />
                    ) : null}{" "}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "1rem", position: "relative" }}>
                <label htmlFor="upload-file">
                  <input
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      top: 0,
                      zIndex: 3,
                      cursor: "pointer",
                    }}
                    id={reqDocument?.documentChecklistItem}
                    onChange={(e) =>
                      handleFileChange({
                        CheckListID: reqDocument.itemId,
                        DocName: reqDocument?.documentChecklistItem,
                        filePathBase64: e.target.files[0],
                        mimeTye: ".pdf",
                        transId: reqDocument?.tranID,
                        mandatory: reqDocument.mandatory,
                      })
                    }
                    name={reqDocument?.documentChecklistItem}
                    type="file"
                  />

                  <Button
                    sx={{
                      width: "95px",
                      height: "34px",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                      background: "#E7E7E7",
                      color: "#828282",
                      letterSpacing: "-0.04em",
                      lineHeight: "16px",
                    }}
                    component="span"
                  >
                    Choose File
                  </Button>
                </label>
              </Box>
            </Box>
          ))
        )}

        <BlockSpacer mt={150} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "100%", sm: "92%", lg: "87%" },
            margin: "1rem",
            marginLeft: { lg: "-1rem", xs: "auto" },
            marginRight: { xs: "auto" },
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/pending-transaction">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: { lg: "328px", xs: "300px" },
                  maxWidth: "100%",
                  height: "54px",
                  marginLeft: { xs: "2rem" },
                  color: "#111E6C",
                  marginBottom: "1rem",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Back
              </Button>
            </Link>
            <Buttons
              color="primary"
              width="300px"
              sx={{ maxWidth: "100%", marginLeft: { xs: "2rem" } }}
              marginLeft="2rem"
              onClick={handleSubmit}
            >
              {submittingDocs ? (
                <ClipLoader color={"#fff"} loading={submittingDocs} size={20} />
              ) : (
                "Proceed"
              )}
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {/* {success && (
        <SuccessLoan open={success} handleClose={handleSuccessClose} />
      )} */}
      {/* {openSuccessWithdraw && (
        <SuccessWithdraw
          open={openSuccessWithdraw}
          handleClose={hanldeSuccessWithdrawClose}
        />
      )} */}
    </>
  );
};

export default UploadPendingDocs;
