import React from "react";
import {
  Section,
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  Title,
  subTitle,
  contentTitle,
} from "./style.js";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BlockSpacer from "../../../../components/BlockSpacer/index.jsx";
import NanoImage from "../../../../components/imageSvg/NanoImage.jsx";
import TopBar from "../../../../components/GetCreditTopBar/index.js";

function CorporateLease() {
  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={Title}>
          Lease
        </Typography>
        <Typography variant="p" sx={subTitle}>
          (Corporate Public Listed/UnListed)
        </Typography>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {" "}
                  <div>
                    <Typography sx={contentTitle}>
                      Vehicle Finance Lease
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <Paper
                    elevation={0.5}
                    sx={{
                      width: { xs: "448px", lg: "448px" },
                      maxWidth: "100%",
                      height: { sx: "400px", lg: "174px" },
                      boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                      borderRadius: "8px",
                      margin: "1rem",
                    }}
                  >
                    <Box sx={callToActionCard}>
                      <Box style={{ paddingTop: "1rem" }}>
                        <NanoImage />
                      </Box>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Link to="/finance-lease/corporate">
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              marginTop: "1rem",
                              width: "144px",
                              height: "42px",
                              color: "#111E6C",
                              textTransform: "capitalize",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            Request
                          </Button>
                        </Link>
                      </div>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
              <BlockSpacer mt={20} />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {" "}
                  <div>
                    <Typography sx={contentTitle}>Operating Lease</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <Paper
                    elevation={0.5}
                    sx={{
                      width: { xs: "448px", lg: "448px" },
                      maxWidth: "100%",
                      height: { sx: "400px", lg: "174px" },
                      boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                      borderRadius: "8px",
                      margin: "1rem",
                    }}
                  >
                    <Box sx={callToActionCard}>
                      <Box style={{ paddingTop: "1rem" }}>
                        <NanoImage />
                      </Box>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Typography sx={paragraphTitle}>
                          . Lorem ipsum dolor sit amet consectetur.
                        </Typography>
                        <Link to="/OPerating-lease/corporate">
                          <Button
                            sx={{
                              background: "#F2F2F2",
                              marginTop: "1rem",
                              width: "144px",
                              height: "42px",
                              color: "#111E6C",
                              textTransform: "capitalize",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            Request
                          </Button>
                        </Link>
                      </div>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </>
  );
}

export default CorporateLease;
