import React from "react";
import BlockSpacer from "../../BlockSpacer";
import CompanyContactForm from "../../Forms/ComapanyContactForm";
import style from "./style.module.css";

function CompanyContactdetail({
  defaultUserMethod,
  setDefaultUserMethod,
  info,
  setKycInfo,
  setBvn,
  verified,
  setVerified,
  bvnVerified,
  verifyBvn,
  verifedLoading,
  error,
}) {
  return (
    <div className={style.container}>
      <div className={style.title}>Contact Person Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <CompanyContactForm 
          info={info} 
          setKycInfo={setKycInfo} 
          setBvn={setBvn}
          verified={verified}
          setVerified={setVerified}
          bvnVerified={bvnVerified}
          verifyBvn={verifyBvn}
          verifedLoading={verifedLoading}
          error={error}
        />
      </div>
    </div>
  );
}

export default CompanyContactdetail;
