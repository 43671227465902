import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
import SummaryCard from "../../../components/Cards/SummaryCard";
import Buttons from "../../../components/Buttons";
import { Section, documentStyle, responsonive } from "./style";
import DocumentIcon from "../../../components/IconSvgs/Document";
import CheckBox from "../../../components/Checkboxs";
import document from "../../../components/Assets/images/document.png";
import SuccessModal from "../../../components/Modals/SuccessModal";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { submitLoanProcessing, clearLoan } from "../../../redux/slices/getCreditSlice";
import Checkbox from "@mui/material/Checkbox";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

function TermsCondition() {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleSucessOpen = () => setOpenSuccess(true);
  const handleSucessClose = () => setOpenSuccess(false);
  const [term, setTerm] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { initializedLoan, submitted, repayment, loading } = useSelector(state => state.getCredit);

  useEffect(() => {
    if(submitted) {
      if(submitted?.object?.body?.body?.message==="Loan submitted and is currently running!") {
        handleSucessOpen();
        dispatch(clearLoan)
      } else {
        toast.error(submitted?.object?.body?.body?.message)
      }
    }
    // eslint-disable-next-line
  }, [submitted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitLoanProcessing({
      loanId: initializedLoan?.object?.body?.body?.loanId
    }))
  }
  return (
    <>
      <TopBar />
      <>
        <Section>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SummaryCard
                title={initializedLoan?.data?.body?.productName}
                currencyRequested={"Requested Loan Amount"}
                tenor={"Tenor"}
                currency={repayment?.object?.body?.body?.requestLoanAmount}
                days={`${repayment?.object?.body?.body?.tenor} days`}
                infoPayable={"Interest Payable"}
                feePayable={"Fee Payable"}
                interestpayableCurrency={repayment?.object?.body?.body?.interestPayable}
                feePayableCurrency={repayment?.object?.body?.body?.feePayable}
                TotalRepayment={"Total Repayment"}
                LoanDueDate={"Loan Due Date"}
                totalPaymentAmount={repayment?.object?.body?.body?.totalRepayment}
                totalPaymentDate={repayment?.object?.body?.body?.loanDueDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box sx={documentStyle}>
                <img src={document} alt="document" style={responsonive} />

                <BlockSpacer mt={20} />
                <Typography sx={{ marginLeft: "5rem" }}>
                  <Checkbox
                    size={"small"}
                    sx={{
                      color: "#111E6C",
                      "&.Mui-checked": {
                        color: "#111E6C",
                      },
                    }}
                    value={term}
                    checked={term}
                    onChange={()=>setTerm(!term)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {""}I agree to the Terms
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <BlockSpacer mt={150} />
          <Paper
            elevation={1}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "89%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "0px 4px 20px rgba(196, 204, 221, 0.38)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  color: "#111E6C",
                  marginBottom: { xs: "1rem" },
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Buttons
                color="primary"
                width="328px"
                onClick={handleSubmit}
                disabled={!term}
              >
                {
                  loading ? (
                    <ClipLoader color={"#fff"} loading={loading} size={20} />
                  ) : "Process Loan"
                }
              </Buttons>
            </Box>
          </Paper>

          {openSuccess && (
            <SuccessModal open={openSuccess} handleClose={handleSucessClose} />
          )}
        </Section>
      </>
    </>
  );
}

export default TermsCondition;
