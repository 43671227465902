import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import styles from "./style.module.css";
import BlockSpacer from "../../../BlockSpacer";
import FormAligner from "../../../Hocs/FormAligner";
import FormLabel from "../../../Forms/Label";
import { Backdrop } from "@mui/material";
import SelectField from "../../../Core/SelectField";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getBankDetails } from "../../../../redux/slices/profileSlice.js";
import {
  generateBankStatement,
  generateMonoAccId,
  fetchMonoAccDetails,
  fetchMonoAccStatement,
} from "../../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import MonoConnect from "@mono.co/connect.js";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  maxWidth: "100%",
  height: "374px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function LoanValueModal({
  open,
  handleClose,
  handleNoteTicketOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading,
    initializedLoan,
    generatedBankStatement,
    generatedBankStatementErr,
    generatedMonoAccId,
    monoAccDetails,
    monoAccStatement,
    monoWebhook,
    reauthorizeMono
  } = useSelector((state) => state.getCredit);

  const { bankDetails, bankDetailsError } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getBankDetails());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(generatedBankStatement?.object?.body?.body?.message==="Redirecting to MONO") {
      toast(generatedBankStatement?.object?.body?.body?.message)
      monoConnect.open();
    } else if(generatedBankStatement) {
      handleNoteTicketOpen();
      toast.success(generatedBankStatement?.object?.body?.body?.message)
    } else if(generatedBankStatementErr?.object?.body?.body?.message==="Redirecting to MONO") {
      toast(generatedBankStatementErr?.object?.body?.body?.message)
      monoConnect.open();
    }
    // if (generatedBankStatement) {
    //   toast(generatedBankStatement?.object?.body?.body?.message);
    //   monoConnect.open();
    // }
    // eslint-disable-next-line
  }, [generatedBankStatement, generatedBankStatementErr]);

  useEffect(() => {
    if(generatedMonoAccId?.statusCode==="OK") {
      dispatch(fetchMonoAccDetails({
        loanId: initializedLoan?.object?.body?.body?.loanId,
        accountId: generatedMonoAccId?.data
      }))
    };
    // eslint-disable-next-line
  }, [generatedMonoAccId]);

  useEffect(() => {
    if(monoAccDetails?.statusCode==="OK") {
      dispatch(fetchMonoAccStatement({
        loanId: initializedLoan?.object?.body?.body?.loanId,
      }))
    };
    // eslint-disable-next-line
  }, [monoAccDetails]);

  useEffect(() => {
    if(monoAccStatement?.message==="bank statement retrieved") {
      navigate("/lease/choose-payment")
      toast.success(monoAccStatement?.message)
    };
    // eslint-disable-next-line
  }, [monoAccStatement])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      generateBankStatement({
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => console.log("Widget closed"),
      onLoad: () => console.log("Widget loaded successfully"),
      onSuccess: ({ code }) => {
        console.log(`Linked successfully: ${code}`);
        dispatch(
          generateMonoAccId({
            loanId: initializedLoan?.object?.body?.body?.loanId,
            code,
          })
        );
      },
      key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    });

    monoInstance.setup();

    return monoInstance;
  }, []);
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Source Account</div>
            <BlockSpacer mt={50} />
            <FormAligner>
              <FormLabel text={"Bank Account"} />
              <TextField
                placeholder="Bank Account"
                type="text"
                required
                name="Bank Account"
                value={
                  bankDetails
                    ? `${bankDetails?.accountName} - ${bankDetails?.accountNumber}`
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
              {bankDetailsError && (
                <Typography sx={{ color: "#ff0000", fontSize: "10px" }}>
                  Bank Account is not available for this user
                </Typography>
              )}
            </FormAligner>
            <BlockSpacer mt={100} />
            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}
                onClick={handleSubmit}
              >
                {loading ? (
                  <ClipLoader color={"#fff"} loading={loading} size={20} />
                ) : (
                  "Request"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
