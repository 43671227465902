import React from "react";
import LoginForm from "../../../components/Forms/Login";
import SplitScreenLeft from "../../../components/UniqueScreens/SplitScreenLeft";
import style from "./style.module.css";

function Login() {
  return (
    <div className={style.container}>
      <div className={style.ssl}>
        <SplitScreenLeft />
      </div>

      <div className={style.login}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
