import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../../BlockSpacer";
import FormAligner from "../../../Hocs/FormAligner";
import FormLabel from "../../../Forms/Label";
import { TextField, Backdrop } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "../../../Buttons";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  maxWidth: "100%",
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function ApproveBankModal({
  open,
  handleClose,
  handleApprovalOpen,
}) {
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Approve Bank Statement Call</div>
            <BlockSpacer mt={20} />
            <FormAligner>
              <FormLabel text={"Ticket ID"} />
              <TextField
                placeholder="Enter Ticket ID"
                type="email"
                required
                name="email"
                InputProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                  },
                }}
              />
            </FormAligner>
            <BlockSpacer mt={10} />

            <FormAligner>
              <FormLabel text={"Password"} />
              <TextField
                placeholder="Enter password"
                type="password"
                required
                name="password"
                InputProps={{
                  style: {
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "15px",
                  },
                }}
              />
            </FormAligner>
            <BlockSpacer mt={50} />

            <div className={styles.button}>
              <Link to="/land-asset/choose-payment">
                <Button
                  color={"primary"}
                  width={240}
                  height={41}
                  onClick={() => handleApprovalOpen()}
                >
                  Submit
                </Button>
              </Link>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
