import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerTransService,
  verifyPaymentService
} from "../../services/paymentServices";

const initialState = {
  loading: false,
  paySuccess: null,
  payError: null,
  verify_paystack: null,
  verify_paystackError: null,
  reg_transaction: null,
  reg_transactionError: null,
};

export const registerTransaction = createAsyncThunk('payment/registerTransaction', (formData, {
  rejectWithValue
}) => {
  return registerTransService(formData)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
})

export const verifyPayment = createAsyncThunk('payment/verifyPayment', 
(transactionRef, {
  rejectWithValue
}) => {
  return verifyPaymentService(transactionRef)
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
})

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearReferences(state) {
      state.reg_transaction = null
      state.verify_paystack = null
    }
  },
  extraReducers: builder => {
    // register transaction pending
    builder.addCase(registerTransaction.pending, (state) => {
      state.loading = true
    }) 
    // register transaction success
    builder.addCase(registerTransaction.fulfilled, (state, action) => {
      state.loading = false
      state.reg_transaction = action.payload
      state.reg_transactionError = null
    })
    // register transaction failed
    builder.addCase(registerTransaction.rejected, (state, action) => {
      state.loading = false
      state.reg_transaction = null
      state.reg_transactionError = action.error
    })
    // verify payment pending
    builder.addCase(verifyPayment.pending, (state) => {
      state.loading = true
    }) 
    // verify payment success
    builder.addCase(verifyPayment.fulfilled, (state, action) => {
      state.loading = false
      state.verify_paystack = action.payload
      state.verify_paystackError = null
    })
    // verify payment failed
    builder.addCase(verifyPayment.rejected, (state, action) => {
      state.loading = false
      state.verify_paystack = null
      state.verify_paystackError = action.error
    })
  }
})

export default paymentSlice.reducer
export const { clearReferences } = paymentSlice.actions;