export const nationalid = {
  display: "flex",
  alignItems: "center",
};

export const container1 = {
  display: "flex",
  alignItems: "center",
  maxWidth: "500px",
  marginLeft: "-50px",
  justifyContent: { xs: "space-evenly", lg: "space-between" },
};
//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;
