import React from "react";

function TombupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      fill="none"
      viewBox="0 0 20 22"
    >
      <path
        fill="#130F26"
        stroke="#fff"
        strokeWidth="1.3"
        d="M9.846 1.38l-5.25 7.75v9.874s1.875 1.75 2.5 1.75h6.375c.875 0 5.25-7.5 5.25-7.5 1.437-3.437-.584-3.875-1.75-4.125h-5.375l1-5c.2-3-1.75-3.083-2.75-2.75z"
      ></path>
      <path
        fill="#130F26"
        stroke="#fff"
        d="M3.596 8.254H1.783a1 1 0 00-1 1v9.625a1 1 0 001 1h1.813a1 1 0 001-1V9.254a1 1 0 00-1-1z"
      ></path>
    </svg>
  );
}

export default TombupIcon;