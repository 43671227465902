import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../../Forms/Label";
import { TextField, Backdrop } from "@mui/material";
import Button from "../../Buttons";
import { Link } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { verifyPayment, clearReferences } from "../../../redux/slices/paymentSlice";
import { fundByDebitCard, getWalletBalance } from "../../../redux/slices/walletSlice";

export const style = {
  position: "absolute",
  top: {xs:"30%",md:"50%", lg:"50%"},
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:"300px",sm:"300px",md:"419.24px",lg:"419.24px"},
  maxWidth: "100%",
  height: "200px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

export default function PaystackPayModal({
  open,
  handleClose,
  // referenceKey,
  onSubmitAction,
  amount
}) {
  const dispatch = useDispatch();
  const { verify_paystack, reg_transaction } = useSelector(state => state.payment)
  const user = JSON.parse(localStorage.getItem("user"))
  const config = {
    reference: reg_transaction?.transactionReference,
    email: user?.email,
    amount: String(amount)+"00",
    publicKey: "pk_test_7e6134abc3ba34cad1566cc35a02fd4cc427b067",
  };
  const initializePay = usePaystackPayment(config);

  const success = async () => {
    await dispatch(
      verifyPayment(reg_transaction?.transactionReference)
    );
  };

  React.useEffect(() => {
    if(verify_paystack) {
      // dispatch(fundByDebitCard({
      //   amount: parseInt(amount),
      //   useraccountId: user?.id
      // }));
      onSubmitAction();
      // toast.success("Wallet Funded");
      dispatch(clearReferences());
      handleClose();
    }
  },[verify_paystack])

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    success();
  };

  // you can call this function anything
  const onClose = () => {
    console.log("closed");
  };
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div>
              <div className={styles.header}>Proceed to pay with PayStack</div>
              <BlockSpacer mt={20} />
              <div className={styles.button}>
                <Button
                  color={"primary"}
                  width={240}
                  height={41}
                  onClick={() => {initializePay(onSuccess, onClose);console.log("ploa",amount)}}
                >
                  Pay
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
