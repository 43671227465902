import React from "react";
// import DashboardLayout from "../../components/FeedbackDashboardLayout";
import { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { Section, inputError } from "./style";
import { FormControl } from "@mui/material";
import FormAligner from "../../components/Hocs/FormAligner";
import {
  feedbackPostService,
  feedbackCategoryService,
} from "../../services/feedbackServices";
import { Link, useNavigate } from "react-router-dom";
import Select from "../../components/Core/Select/index.jsx";
import FormLabel from "../../components/Forms/Label";
import Unauthorised from "./ticket-details";
import OfferSuccessModal from "../../components/Modals/FeedbackModal/index.js";
import { putEditTIcket } from "../../services/feedbackServices";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Formik, Form } from "formik";
import { Box } from "@mui/system";
import BlockSpacer from "../../components/BlockSpacer";
import { useDispatch, useSelector } from "react-redux";

import { editTicketActions } from "../../redux/reducers/edit-ticket";
import { useFormik } from "formik";
import { editTicketSchema } from "../../components/schemas";
import TopBar from "../../components/FeedBackTopBar";

function EditTicket() {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      messageTitle: "",
      messageDescription: "",
      status: "",
      ticketid: null,
    },
    validationSchema: editTicketSchema,

    onSubmit: async (values, actions) => {
      console.log("Submitted");
      console.log("Form data", values);
      console.log("Submit props", actions);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      actions.resetForm();
    },
  });

  const [categories, setCategories] = React.useState([]);

  const [method, setMethod] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const dispatch = useDispatch();
  const { EditTicket } = useSelector((state) => state.editTicket);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };

  const handleUserInputs = (e) => {
    dispatch(
      editTicketActions({ ...EditTicket, [e.target.name]: e.target.value })
    );
  };
  const [val, setVal] = useState(" ");
  const handleNumber = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setVal(result);
  };

  console.log(val);

  const getCategories = async () => {
    try {
      const response = await feedbackCategoryService();

      // "http://localhost:5000/feedback"

      console.log(response.data, "This is the response");
      let opts = response.data.map((opt) => ({
        name: opt.name,
        value: opt.id,
      }));

      console.log(opts);
      localStorage.setItem("categories", JSON.stringify(response.data));
      setCategories(opts);
    } catch (error) {
      console.log("This is the Error", error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const formData = {
    categoryId: method,
    content: EditTicket.messageDescription,
    id: val,
    status: EditTicket.status,
    title: EditTicket.messageTitle,
  };

  const EditFeedBack = async () => {
    setLoading(true);

    try {
      const response = await putEditTIcket(formData);

      if (response) {
        console.log(response.data, "This is the put response");
        handleOpenModal();
        setLoading(false);
      }
    } catch (error) {
      console.log("This is the post error", error.message);
      setLoading(false);
      setError(error?.message);
    }
  };

  const handleSubmitForm = () => {
    EditFeedBack();
  };

  return (
    <div>
      <TopBar />
      <>
        <main>
          <Section>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "21px",
              }}
            >
              Edit Tickets
            </Typography>

            <BlockSpacer mt={40} />

            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <Select
                        method={
                          categories?.filter(({ value }) => value === method)[0]
                            ?.name
                        }
                        name={"Feedback Category"}
                        items={categories}
                        changeAction={changeAction}
                      />
                    </FormAligner>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Title of Message"} />
                      <TextField
                        id="title"
                        name="messageTitle"
                        type="text"
                        value={values.messageTitle}
                        onChange={(e) => {
                          handleChange(e);
                          handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter your message Title"
                        InputProps={{
                          style: {
                            color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                          },
                        }}
                        error={
                          errors.messageTitle && touched.messageTitle
                            ? inputError
                            : ""
                        }
                        helperText={
                          errors.messageTitle &&
                          touched.messageTitle &&
                          errors.messageTitle
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
              </Grid>
              <BlockSpacer mt={40} />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Status"} />
                      <TextField
                        id="stat"
                        name="status"
                        type="text"
                        value={values.status}
                        onChange={(e) => {
                          handleChange(e);
                          handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        placeholder={"OPENED OR CLOSED"}
                        InputProps={{
                          style: {
                            color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "16px",
                          },
                        }}
                        error={
                          errors.status && touched.status ? inputError : ""
                        }
                        helperText={
                          errors.status && touched.status && errors.status
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Ticket ID"} />
                      <TextField
                        id="id"
                        name="ticketid"
                        type="number"
                        value={val}
                        onChange={(e) => {
                          handleChange(e);
                          handleNumber(e);
                          // handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="ID"
                        InputProps={{
                          style: {
                            color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "16px",
                          },
                        }}
                        error={
                          errors.ticketid && touched.ticketid ? inputError : ""
                        }
                        helperText={
                          errors.ticketid && touched.ticketid && errors.ticketid
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
              </Grid>
              <BlockSpacer mt={40} />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Description"} />
                      <TextField
                        placeholder="Enter your message"
                        id="description"
                        name="messageDescription"
                        value={values.messageDescription}
                        onChange={(e) => {
                          handleChange(e);
                          handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        multiline
                        rows={8}
                        colummns={12}
                        InputProps={{
                          style: {
                            color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                          },
                        }}
                        error={
                          errors.messageDescription &&
                          touched.messageDescription
                            ? inputError
                            : ""
                        }
                        helperText={
                          errors.messageDescription &&
                          touched.messageDescription &&
                          errors.messageDescription
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
              </Grid>

              <BlockSpacer mt={300} />

              <Paper
                  elevation={0.5}
                  sx={{
                    height: { xs: "15%", lg: "112px" },
                    width: { xs: "92%", sm: "100%", lg: "100%" },
                    maxwidth: "100%",
                    boxShadow: " 8px 0px 18px rgba(173, 173, 173, 0.25)",
                    background: "#FFFFF",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: { xs: "120px", lg: "112px" },
                  }}
              
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "95%",
                  }}
                >
                  {!loading && (
                    <Button
                      type="submit"
                      sx={{
                        background: "#111E6C",
                        width: "328px",
                        height: "54px",
                        color: "#FFFFFF",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        marginTop: "1.5rem",
                        marginLeft: {xs:"1.3rem", lg:"5.5rem"},
                        textTransform: "capitalize",
                        fontSize: "18px",
                        borderRadius: "10px",
                        "&:hover": {
                          background: "#FFFFFF",
                          color: "#111E6C",
                        },
                      }}
                      onClick={() => {
                        handleSubmit();
                        handleSubmitForm();
                      }}
                      disabled={!isValid || isSubmitting}
                    >
                      Submit
                    </Button>
                  )}

                  {loading && (
                    <Button
                      type="submit"
                      sx={{
                        background: "#111E6C",
                        width: "328px",
                        height: "54px",
                        color: "#FFFFFF",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        marginTop: "1.5rem",
                        marginLeft: "5.5rem",
                        textTransform: "capitalize",
                        fontSize: "18px",
                        borderRadius: "10px",
                      }}
                    >
                      <CircularProgress />
                    </Button>
                  )}
                </Box>
              </Paper>
            </form>
          </Section>
        </main>
      </>
      {openModal && (
        <OfferSuccessModal open={openModal} handleClose={handleModalClose} />
      )}
    </div>
  );
}

export default EditTicket;
