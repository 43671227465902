import React from "react";
import styles from "./style.module.css";

function FormLabel({ text, asterisk = false }) {
  return (
    <div className={styles.text}>
      {text}&nbsp;{asterisk && <span className={styles.red}>*</span>}
    </div>
  );
}

export default FormLabel;
