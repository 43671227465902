import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DocumenSuccessMessage from "../../Cards/DocumentSuccess";
import { style } from "../OtpVerificationModal";
import GreenIcon from "../../IconSvgs/GreenIcon";

export default function DocumentSuccessModal({ open, handleClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-otp-title"
        aria-describedby="modal-otp-description"
      >
        <Box sx={style}>
          <DocumenSuccessMessage
            subTextStart={"Your documents have been updated successfully "}
            icon={<GreenIcon />}
            route={'/'}
          />
        </Box>
      </Modal>
    </div>
  );
}
