import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import {
  EMPLOYER_INDUSTRY,
  EMPLOYER_NAMES,
  EMPLOYER_SECTORS,
  PAYROLL_HANDLERS,
} from "../../../constants";
import BlockSpacer from "../../BlockSpacer";
import SelectField from "../../Core/SelectField";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../Label";
import style from "./style.module.css";
import { useSelector } from "react-redux";

function EmployerDetailForm({ setKycInfo }) {
  const { erpEmployers, erpIndustries } = useSelector(
    (state) => state.updateProfile
  );

  const { erpSectors } = useSelector((state) => state.profile);

  const industries = erpSectors ? erpSectors?.map((item) => item?.sector) : [];

  const employeeNames = erpEmployers
    ? erpEmployers?.map((item) => item?.employerName)
    : [];
  const employerNamesOptions = [...employeeNames, "Others"];

  const industryOptions = [...industries];

  //Employer Name
  const [defaultEmployerNameMethod, setDefaultEmployerNameMethod] =
    useState("");

  const [selectedEmployer, setSelectedEmployer] = useState(null);

  const handleEmployerNameChange = (event) => {
    setDefaultEmployerNameMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          employerName:
            event.target.value === "Others" ? "" : event.target.value,
          employerId: erpEmployers?.find(
            (item) => event.target.value === item?.employerName
          )?.idErp,
        },
      },
    }));

    setSelectedEmployer(
      erpEmployers?.find((item) => event.target.value === item?.employerName)
    );
  };

  // const getallCompany = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://api-credit.optisoft.com.ng:8090/users/get-all-group-company",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     setEmployersNames(
  //       res.data.content?.filter((item) => item?.status === "ACTIVE")
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   getallCompany();
  // }, []);

  // const employerOptions = [...employersnames, "Others"];
  // const Industry = async () => {
  //   try {
  //     const res = await axios.get(
  //       "https://api-credit.optisoft.com.ng:8090/users/get-all-industry-management",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     console.log(res.data.content, "Eployers Details");
  //     setEmployersSectors(res.data.content);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   Industry();
  // }, []);
  // const industryOptions = [...employerssectors, "Others"];
  //Employer Sector
  const [defaultEmployerSectorMethod, setDefaultEmployerSectorMethod] =
    useState("");
  const handleEmployerSectorChange = (event) => {
    setDefaultEmployerSectorMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          sector:
            event.target.value === "Private"
              ? "PRIVATE_SECTOR"
              : "PUBLIC_SECTOR",
        },
      },
    }));
  };

  //Payroll handler
  const [defaultPayrollHandlerMethod, setDefaultPayrollHandlerMethod] =
    useState("");
  const handlePayrollHandlerChange = (event) => {
    setDefaultPayrollHandlerMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          payrollHandler:
            event.target.value === "Others" ? null : event.target.value,
        },
      },
    }));
  };

  const [dafaultEmployerIndutry, setDefaultEmployerIndutry] = useState("");

  const handleEployerIndustryChange = (e) => {
    setDefaultEmployerIndutry(e.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          erpSectorId: erpSectors?.find(
            (sector) => sector?.sector === e.target.value
          )?.id,
        },
      },
    }));
  };

  const handleUserInputs = (e) => {
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        employmentDetail: {
          ...prev.individualUser.employmentDetail,
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  console.log(selectedEmployer);
  return (
    <>
      <div>
        <div>
          <BlockSpacer />
          <Grid container rowSpacing={5} columnSpacing={5}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <SelectField
                  name={"Employer Name"}
                  method={defaultEmployerNameMethod}
                  changeAction={handleEmployerNameChange}
                  items={employerNamesOptions}
                  asterisk
                  maxWidth={"100%"}
                />
              </FormAligner>
            </Grid>
            {defaultEmployerNameMethod === "Others" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormAligner>
                  <FormLabel text={"Employer Name Details"} />
                  <TextField
                    name="employerName"
                    type="text"
                    payroll="Employer Name"
                    required
                    onChange={handleUserInputs}
                  />
                </FormAligner>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <SelectField
                  name={"Employer Sector"}
                  method={defaultEmployerSectorMethod}
                  changeAction={handleEmployerSectorChange}
                  items={EMPLOYER_SECTORS}
                  asterisk
                  maxWidth={"100%"}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {defaultEmployerSectorMethod === "Public" && (
                <FormAligner>
                  <SelectField
                    name={"Employer Payroll Handler"}
                    method={defaultPayrollHandlerMethod}
                    changeAction={handlePayrollHandlerChange}
                    items={PAYROLL_HANDLERS}
                    asterisk
                    maxWidth={"100%"}
                  />
                </FormAligner>
              )}
              {defaultEmployerSectorMethod === "Private" && (
                <FormAligner>
                  <FormLabel text={"Employment ID"} />
                  <TextField
                    placeholder="12345"
                    type="number"
                    required
                    name="employmentId"
                    value={selectedEmployer?.idErp}
                    // onChange={handleUserInputs}
                  />
                </FormAligner>
              )}
            </Grid>
            {defaultEmployerSectorMethod === "Public" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {defaultEmployerSectorMethod === "Public" &&
                  defaultPayrollHandlerMethod === "Others" && (
                    <FormAligner>
                      <FormLabel text={"Payroll Handler Details"} />
                      <TextField
                        placeholder=""
                        type="text"
                        required
                        fullWidth
                        name="payrollHandlerDetails"
                        onChange={handleUserInputs}
                      />
                    </FormAligner>
                  )}
                {defaultEmployerSectorMethod === "Public" &&
                  defaultPayrollHandlerMethod === "IPPIS" && (
                    <FormAligner>
                      <FormLabel text={"IPPIS Number"} />
                      <TextField
                        placeholder="ex-> RC 01234-****"
                        type="text"
                        required
                        name="ippisNumber"
                        onChange={handleUserInputs}
                      />
                    </FormAligner>
                  )}
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <SelectField
                  name={"Employer Industry"}
                  method={dafaultEmployerIndutry}
                  changeAction={handleEployerIndustryChange}
                  items={industryOptions}
                  asterisk
                  maxWidth={"100%"}
                />
              </FormAligner>
            </Grid>
            {dafaultEmployerIndutry === "Others" && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormAligner>
                  <FormLabel text={"Employer Industry Details"} />
                  <TextField
                    placeholder="Industry Details"
                    type="text"
                    required
                    name="industryDetails"
                    onChange={handleUserInputs}
                    sx={{ maxWidth: "785px" }}
                  />
                </FormAligner>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormAligner>
                <FormLabel text={"Employer Address"} />
                <TextField
                  placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                  type="text"
                  required
                  name="employerAddress"
                  onChange={handleUserInputs}
                  sx={{ maxWidth: "785px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Official email address"} />
                <TextField
                  placeholder="info@optisoft.com"
                  type="email"
                  required
                  name="officeEmailAddress"
                  onChange={handleUserInputs}
                />
              </FormAligner>
            </Grid>
          </Grid>

          <BlockSpacer />
        </div>
      </div>
    </>
  );
}

export default EmployerDetailForm;
