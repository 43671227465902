export const notifcationCard = {
  maxWidth: { sx: "100%", lg: "405px" },
  marginLeft: { lg: "6rem", xs: "0%" },
  height: "350px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
  px: "1rem",
  borderRadius: "10px",
  wordWrap: "break-word",
};

export const notificationTitle = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "17px",
  lineHeight: "20px",
  letterSpacing: "-0.04em",
  color: "#242424",
};

export const titleContainer = {
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem",
};

export const markAllContainer = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "20px",
  letterSpacing: "-0.04em",
  textDecorationLine: "no-underline",
  pointer: "cursor",
  color: "#111E6C",
};
export const notificationContainer = {
  marginTop: "2rem",
  display: "flex",
  justifyContent: "space-between",
};

export const notifacationBox = {
  height: "52px",
  width: "286px",
};

export const notificationSucces = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "171.5%",
  letterSpacing: "-0.15px",
  color: "#111E6C",
};

export const notificationSuccesfirst = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "171.5%",
  letterSpacing: "-0.15px",
};

export const notificationDateSucess = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "11px",
  lineHeight: "20px",
  letterSpacing: "-0.02em",
  color: "#4F4F4F",
};
