import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  height: 100%;
  @media (max-width: 1215px) {
    margin-top: 25%;
    padding: 1rem;
  }
`;
export const AccountNumber = {
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "300",
  position: "absolute",
};
export const processingStage = {
  position: "absolute",
  top: "20px",
  left: { xs: "192px", lg: "290px" },
  // top: { xs: "210px", lg: "150px" },

  color: "#F2C94C",
  fontSize: "10px",
  fontFamily: "Montserrat",
  fontWeight: "500",
};
export const Requested = {
  fontFamily: "Montserrat",
  position: "absolute",
  top: "65px",
  fontWeight: "300",
  height: "16px",
  fontSize: "12.9px",
  padding: "1rem",
};
export const Stenor = {
  fontFamily: "Montserrat",
  fontWeight: "300",
  height: "16px",
  fontSize: "12.9px",
  padding: "1rem",
  position: "absolute",
  left: { xs: "220px", lg: "350px" },
  top: "65px",
};

export const Fee = {
  fontFamily: "Montserrat",
  fontWeight: "300",
  height: "16px",
  fontSize: "12.9px",
  top: "140px",
  padding: "1rem",
  position: "absolute",
  left: { xs: "185px", lg: "315.78px" },
};
export const Digit = {
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "12.904px",
  color: "#242424",
  fontWeight: "600",
  width: "85px",
  position: "absolute",
  left: { xs: "200px", lg: "300px" },
  top: "160px",
};

export const figure = {
  position: "absolute",
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "12.904px",
  color: "#242424",
  fontWeight: "600",
  top: "90px",
  width: "65px",
};
export const Repay = {
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "300",
  position: "absolute",
  top: "240px",
};
export const Loan = {
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "300",
  position: "absolute",
  top: { xs: "200px", lg: "240px" },
  left: { xs: "150px", lg: "290.78px" },
};
export const Integer = {
  fontFamily: "Montserrat",
  padding: "1rem",
  position: "absolute",
  fontSize: "12.904px",
  color: " #111E6C",
  fontWeight: "600",
  top: "270px",
};
export const Date = {
  fontFamily: "Montserrat",
  padding: "1rem",
  position: "absolute",
  fontSize: "12.904px",
  color: " #111E6C",
  fontWeight: "600",
  top: "270px",
  left: { xs: "190px", lg: "315px" },
};
export const Interest = {
  fontFamily: "Montserrat",
  fontWeight: "300",
  height: "16px",
  fontSize: "12.9px",
  padding: "1rem",
  position: "absolute",
  top: "140px",
};
export const Value = {
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "12.904px",
  color: "#242424",
  position: "absolute",
  top: "160.44px",
  fontWeight: "600",
  width: "65px",
};

export const days = {
  fontFamily: "Montserrat",
  padding: "1rem 1rem 1rem 0",
  position: "absolute",
  fontSize: "12.904px",
  color: "#242424",
  fontWeight: "600",
  top: "90px",
  left: { xs: "220px", lg: "315px" },
  whiteSpace: "nowrap",
};

export const Number = {
  position: "absolute",

  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.7404px",
  color: "#242424",
  fontWeight: "600",
  lineHeight: "17px",
  top: "40px",
};

export const Tenor = {
  position: "absolute",
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "300",
  top: "90px",
};

export const Two = {
  position: "absolute",
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "600",
  top: "120px",
};

export const Reason = {
  position: "Absolute",
  top: "160px",
  fontFamily: "Montserrat",
  padding: "1rem",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "300",
};
export const button = {
  display: "flex",
  justifyContent: { xs: "flex-start", lg: "center" },
  marginLeft: "-3rem",
};

export const Detail = {
  position: "absolute",
  fontFamily: "Montserrat",
  padding: "1rem",
  width: { xs: "400px", lg: "320px" },
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "600",
  top: "190px",
};
export const summaryTitle = {
  fontFamily: "Montserrat",
  fontSize: "17px",
  fontWeight: "600",
  lineHeight: "27px",
  padding: "1.2rem",
};

export const Quickloan = {
  fontFamily: "Montserrat",
  padding: "1rem",
  position: "absolute",
  fontSize: "13.404px",
  color: "#242424",
  fontWeight: "600",
};
