import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../../BlockSpacer";
import FormAligner from "../../../Hocs/FormAligner";
import FormLabel from "../../../Forms/Label";
import { TextField, Backdrop } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Buttons";
import { getLoanValue } from "../../../../redux/slices/getCreditSlice";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  maxWidth: "100%",
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function ApproveBankModal({
  open,
  handleClose,
  handleApprovalOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ticketId: "",
    password: "",
  });

  const { initializedLoan, loanValue, generatedBankStatement, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (loanValue?.statusCode === "OK") {
      // handleLoanOpen();
      navigate("/lease/choose-payment")
    } 
    // else if (loanValueErr) {
    //   handleEligableOpen();
    // }
    // eslint-disable-next-line
  }, [loanValue]);

  const handleInput = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getLoanValue({
        loanId: initializedLoan?.object?.body?.body?.loanId,
        password: formData.password,
        requestId: generatedBankStatement?.object?.body?.body?.loanRequestId,
        ticketID: formData.ticketId,
      })
    );
  };
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Approve Bank Statement Call</div>
            <BlockSpacer mt={20} />
            <FormAligner>
              <FormLabel text={"Ticket ID"} />
              <TextField
                placeholder="Enter Ticket ID"
                type="text"
                required
                name="ticketId"
                value={formData.ticketId}
                onChange={handleInput}
              />
            </FormAligner>
            <BlockSpacer mt={10} />

            <FormAligner>
              <FormLabel text={"Password"} />
              <TextField
                placeholder="Enter password"
                type="password"
                required
                name="password"
                value={formData.password}
                onChange={handleInput}
              />
            </FormAligner>
            <BlockSpacer mt={50} />

            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}
                // onClick={() => handleApprovalOpen()}
                onClick={handleSubmit}
                disabled={formData.ticketId === "" || formData.password === ""}
              >
                {loading ? (
                  <ClipLoader color={"#fff"} loading={loading} size={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
