import React, { useState, useEffect } from "react";
import SignupCorporateForm from "../../../components/Forms/SignupCorporate";
import SignupIndividualForm from "../../../components/Forms/SignupIndividual";
import SplitScreenLeft from "../../../components/UniqueScreens/SplitScreenLeft";
import style from "./style.module.css";
import { getAllSources } from "../../../redux/slices/profileSlice";
import { useDispatch } from "react-redux";

function Signup() {
  const dispatch = useDispatch();
  const [individualSignup, setIndividualSignup] = useState(true);

  useEffect(() => {
    dispatch(getAllSources());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetCorporateSignup = () => {
    setIndividualSignup(!individualSignup);
  };

  return (
    <div className={style.container}>
      <div className={style.ssl}>
        <SplitScreenLeft
          allowBtns={true}
          individualSignup={individualSignup}
          setIndividualSignup={handleSetCorporateSignup}
        />
      </div>

      <div className={style.login}>
        {individualSignup ? (
          <SignupIndividualForm
            individualSignup={individualSignup}
            setIndividualSignup={handleSetCorporateSignup}
          />
        ) : (
          <SignupCorporateForm
            individualSignup={individualSignup}
            setIndividualSignup={handleSetCorporateSignup}
          />
        )}
      </div>
    </div>
  );
}

export default Signup;
