import React, { useState } from "react";
import axios from "axios";
import style from "./style.module.css";
import Box from "@mui/material/Box";
import LogoAlternate from "../../../components/Logo";
import FormAligner from "../../../components/Hocs/FormAligner";
import { TextField, FormHelperText } from "@mui/material";
import FormLabel from "../../../components/Forms/Label";
import Button from "../../../components/Buttons";
import GeneralFormInfo from "../../../components/GridFlex/GeneralFormInfo";
import BlockSpacer from "../../../components/BlockSpacer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordEmail } from "../../../redux/slices/authSlice";
import { toast } from "react-toastify";

function ForgotPassword() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleBlur = (e) => {
    if (!emailRegex.test(e.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const submitForgotPassword = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://api-credit.optisoft.com.ng:8090/users/${email}/forgot-password`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(forgotPasswordEmail(email));
      if (res?.data) {
        localStorage.setItem("resetPasswordMail", email);
        router("/auth/reset-link-success");
      }
      setLoading(false);
      // setBvn("");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForgotPassword();
  };

  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <Box
          sx={{
            minWidth: 360,
            maxWidth: 888,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 30px rgba(225, 234, 254, 0.62)",
            borderRadius: "20px",
            paddingTop: "3.75rem",
          }}
        >
          <div className={style.logo}>
            <LogoAlternate />
          </div>
          <BlockSpacer mt={69} />
          <div className={style.title}>Forgot Password</div>
          <BlockSpacer mt={18} />
          <div className={style.text}>
            Please, enter your email address. You will receive a link to create
            a new password via email.
          </div>
          <BlockSpacer mt={29} />
          <div className={style.alignMiddle}>
            <form className={style.formWrap}>
              <FormAligner>
                <FormLabel text={"Email Address"} />
                <TextField
                  placeholder="Infooptisoft.ng"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleBlur}
                  error={error}
                  name="email"
                />
                {error && (
                  <FormHelperText error>
                    Please enter a valid email address
                  </FormHelperText>
                )}
              </FormAligner>
              <BlockSpacer mt={35} />
              <div className={style.alignMiddle}>
                <Button
                  color={"primary"}
                  width={"328px"}
                  disabled={!emailRegex.test(email)}
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? "loading..." : "Send"}
                </Button>
              </div>
              <BlockSpacer mt={20} />
              <GeneralFormInfo
                title={"Remember your password?"}
                linkName={"Sign in"}
                route={"/auth/login"}
              />
              <BlockSpacer mt={60} />
            </form>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default ForgotPassword;
