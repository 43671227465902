import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import dateFormat, { masks } from "dateformat";
import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { GENDER, MARITAL_STATUS } from "../../../constants";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import DateField from "../../Core/DateField";

import SelectField from "../../Core/SelectField";
import FormAligner from "../../Hocs/FormAligner";
import BvnSuccessVerifcation from "../../Modals/BvnsucessVerifcation";
import OtpSuccessVerificationModal from "../../Modals/OtpSuccessVerificationModal";
import OtpVerificationModal from "../../Modals/OtpVerificationModal";
import FormLabel from "../Label";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  verifyPhone,
  closeModal,
} from "../../../redux/slices/updateProfileSlice";

function PersonalDetailForm({
  defaultUserMethod,
  setDefaultUserMethod,
  setKycInfo,
  setBvn,
  verifyBvn,
  verifedLoading,
  error,
  itemOptions,
  verified,
  setVerified,
  usersDetails,
  info,
  bvnVerified,
}) {
  const BVNData = JSON.parse(localStorage.getItem("BVNDATA"));
  const [disabled, setDisabled] = useState(false);
  const { gender } = useSelector((state) => state.profile);
  const [closePhoneModal, setClosePhoneModal] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const {
    showPhoneOtpModal,
    phoneMsg,
    loading: phoneLoad,
    validatePhone,
  } = useSelector((state) => state.updateProfile);

  const dispatch = useDispatch();

  // console.log(setKycInfo, "usersDetails");
  const [loading, setLoading] = useState(false);

  const submitBvnHandler = (e) => {
    e.preventDefault();
    verifyBvn();
  };

  const verifyPhoneNumber = () => {
    let secondPhone =
      info?.individualUser?.contactAddress?.secondaryPhoneNumber;
    dispatch(verifyPhone(secondPhone));
  };

  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };

  useEffect(() => {
    if (validatePhone?.status && showPhoneOtpModal) {
      // setPhoneVerified(true);
      setClosePhoneModal(true);
    }
  }, [validatePhone]);

  const updateBVNdata = (firstName, lastName) => {
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev.individualUser,
        firstName,
        lastName,
      },
    }));
  };

  const handleUserInputs = (e) => {
    if (e.target.name === "phone") {
      setKycInfo((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.name === "secondaryPhoneNumber") {
      setKycInfo((prev) => ({
        ...prev,
        individualUser: {
          ...prev.individualUser,
          contactAddress: {
            ...prev.individualUser.contactAddress,
            [e.target.name]: e.target.value,
          },
        },
      }));
    } else {
      setKycInfo((prev) => ({
        ...prev,
        individualUser: {
          ...prev.individualUser,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  // const handleDOB = (e) => {
  //   setKycInfo((prev) => ({
  //     ...prev,
  //     individualUser: {
  //       ...prev?.individualUser,
  //       dateOfBirth: moment(e.target.value).format("YYYY-MM-DD")
  //     }
  //   }))
  // }

  const BVNInput = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setBvn((prev) => ({
        ...prev,
        id: e.target.value,
      }));
      setKycInfo((prev) => ({
        ...prev,
        individualUser: {
          ...prev?.individualUser,
          bvn: e.target.value,
        },
      }));
    }
  };

  const genderOptions = gender ? gender?.map((item) => item?.gender) : [];

  //Gender
  const [defaultGenderMethod, setDefaultGenderMethod] = useState("");
  const handleGenderChange = (event) => {
    setDefaultGenderMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        genderId: gender?.find((item) => item.gender === event.target.value)
          ?.id,
      },
    }));
  };

  //Marital status
  const [defaultMaritalStatusMethod, setDefaultMaritalStatusMethod] =
    useState("");
  const handleMaritalStatusChange = (event) => {
    setDefaultMaritalStatusMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        maritalStatus: event.target.value?.toUpperCase(),
      },
    }));
  };

  //DOB
  // const [value, setValue] = React.useState();
  // setValue(usersDetails?.individualUser?.dateOfBirth);
  // const handleChange = (newValue) => {
  //   setValue(newValue);
  //   setKycInfo((prev) => ({
  //     ...prev,
  //     individualUser: {
  //       ...prev.individualUser,
  //       dateOfBirth: dateFormat(newValue, "dd-mm-yyyy"),
  //     },
  //   }));
  // };
  //OTP
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [otp, setOtp] = React.useState("");
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  const handleOpen = () => {
    setOpen(true);
    setOpenSuccess(false);
  };

  //OTP Success
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  const handleSuccessOpen = () => {
    setOpenSuccess(true);
    setOpen(false);
  };

  // localStorage.setItem("kycInfo", JSON.stringify(kycInfo));
  // console.log(kycInfo, "This are my KYC Info");

  const verifySecPhone = () => {
    dispatch(verifyPhone(info));
  };

  const handleOTPModalClose = () => {
    dispatch(closeModal());
    setOtp("");
  };

  const closeSuccessModal = () => {
    dispatch(closeModal());
    setClosePhoneModal(false);
    setVerified(true);
    setPhoneVerified(true);
  };

  const handlePhoneInputs = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "phone") {
        setKycInfo((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
      } else if (e.target.name === "secondaryPhoneNumber") {
        setVerified(false);
        setKycInfo((prev) => ({
          ...prev,
          individualUser: {
            ...prev.individualUser,
            contactAddress: {
              ...prev.individualUser.contactAddress,
              [e.target.name]: e.target.value,
            },
          },
        }));
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"User Type"} />
                <TextField
                  type="text"
                  required
                  name="usertype"
                  sx={{ input: { background: "#e9ecef" } }}
                  value={info?.userType ?? ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"First Name"} />
                <TextField
                  placeholder="ekiyee"
                  type="text"
                  sx={{ input: { background: "#e9ecef" } }}
                  required
                  // disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={(e) => handleUserInputs(e)}
                  name="firstName"
                  value={info?.individualUser?.firstName}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Middle Name"} />
                <TextField
                  placeholder="bilaowei"
                  type="text"
                  required
                  onChange={(e) => handleUserInputs(e)}
                  name="middleName"
                  value={info?.individualUser?.middleName}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Last Name"} />
                <TextField
                  placeholder="bilaowei"
                  sx={{ input: { background: "#e9ecef" } }}
                  type="text"
                  // disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={(e) => handleUserInputs(e)}
                  required
                  name="lastName"
                  value={info?.individualUser?.lastName}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          {/* second Grid */}
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <SelectField
                name={"Gender"}
                method={defaultGenderMethod}
                changeAction={handleGenderChange}
                items={genderOptions}
                asterisk
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <SelectField
                name={"Marital Status"}
                method={defaultMaritalStatusMethod}
                changeAction={handleMaritalStatusChange}
                items={MARITAL_STATUS}
                asterisk
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Date of Birth"} />
                <TextField
                  id="date"
                  type="date"
                  required
                  name="dateOfBirth"
                  sx={{ input: { background: "#e9ecef" } }}
                  // onChange={(e)=>handleDOB(e)}
                  value={moment(
                    info?.individualUser?.dateOfBirth,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD")}
                  // disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          {/* third Grid */}
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 4, md: 8 }}
            columnSpacing={8}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Primary Phone Number"} />
                <TextField
                  placeholder="07088957111"
                  type="text"
                  required
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 11,
                  }}
                  name="phone"
                  value={info?.phone}
                  onChange={handlePhoneInputs}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid
                container
                rowSpacing={{ xs: 0, sm: 2, md: 2 }}
                columnSpacing={2}
              >
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <FormAligner>
                    <FormLabel text={"Secondary Phone Number"} asterisk />
                    <ReactTooltip />
                    <TextField
                      placeholder="07088957111"
                      type="text"
                      required
                      data-tip="Please fill your primary phone number as your secondary phone number if it's your active phone number"
                      name="secondaryPhoneNumber"
                      onChange={handlePhoneInputs}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "d*",
                        maxLength: 11,
                      }}
                      value={
                        info?.individualUser?.contactAddress
                          ?.secondaryPhoneNumber
                      }
                      // onChange={handleUserInputs}
                      // value={usersDetails?.phone}
                      style={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "5px",
                      }}
                    />
                  </FormAligner>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{ mt: { xs: -2, md: 0 } }}
                >
                  <FormAligner>
                    <FormLabel text={""} />
                    <Button
                      color={verified ? "tertiary" : "primary"}
                      width={"83px"}
                      onClick={verifyPhoneNumber}
                      disabled={verified}
                      marginBottom={0}
                    >
                      {phoneLoad ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </FormAligner>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid
            container
            rowSpacing={{ xs: 0, sm: 2, md: 2 }}
            columnSpacing={2}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Bank Verification Number (BVN)"} asterisk />
                <TextField
                  placeholder="22038475940"
                  type="text"
                  required
                  name="id"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  sx={{ input: { background: bvnVerified && "#e9ecef" } }}
                  InputProps={{
                    readOnly: bvnVerified,
                  }}
                  onChange={BVNInput}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                  value={info.individualUser.bvn}
                />
              </FormAligner>
              {!bvnVerified && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {error}
                </p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{ mt: { xs: -2, md: 0 } }}
            >
              <FormAligner>
                <FormLabel text={""} />
                <Button
                  color={bvnVerified ? "tertiary" : "primary"}
                  width={"83px"}
                  onClick={submitBvnHandler}
                  disabled={bvnVerified}
                  marginBottom={0}
                >
                  {verifedLoading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    "Verify"
                  )}
                </Button>
              </FormAligner>
            </Grid>
          </Grid>
          {/* <div className={style.merge}>
            <FormAligner>
              <FormLabel text={"Bank verification number (BVN)"} asterisk />
              <TextField
                placeholder="22038475940"
                type="number"
                required
                name="id"
                onChange={BVNInput}
                style={{ border: "1px solid green", borderRadius: "5px" }}
              />
            </FormAligner>
            <FormAligner>
              <FormLabel text={""} />
              <Button
                color={"primary"}
                width={"83px"}
                onClick={submitBvnHandler}
                // disabled={disabled === false ? true : false}
              >
                {verifedLoading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  "Verify"
                )}
              </Button>
            </FormAligner>
          </div> */}

          <BlockSpacer />
          {/* <div className={style.merge}>
            <FormAligner>
              <FormLabel text={"Email address"} />
              <TextField
                placeholder="info@optisoft.com"
                type="text"
                required
                // name="officeEmailAddress"
                value={usersDetails?.email}
                // onChange={handleUserInputs}
                disabled
              />
            </FormAligner>
          </div> */}
        </div>
      </div>

      <OtpVerificationModal
        open={showPhoneOtpModal}
        handleClose={handleOTPModalClose}
        secondPhone={info?.individualUser?.contactAddress?.secondaryPhoneNumber}
        handleOtpChange
        otpData={phoneMsg?.data?.otp}
        otp={otp}
        // handleSuccessOpen={}
      />
      <OtpSuccessVerificationModal
        open={closePhoneModal}
        handleClose={closeSuccessModal}
      />
    </>
  );
}

export default PersonalDetailForm;
