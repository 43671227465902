import { MenuItem, Select as MUISelect } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import React from "react";
import FormLabel from "../../Forms/Label";
import FormAligner from "../../Hocs/FormAligner";

function Select({
  name,
  method,
  changeAction,
  disabled,
  categoryName,
  label,
  items,
  select,
  asterisk = false,
  maxWidth,
  handleSelect,
}) {
  const SelectOne = () => {
    return <span>{method ? method : "Select One"}</span>;
  };
  return (
    <FormAligner>
      <FormLabel text={name} asterisk={asterisk} />

      <MUISelect
        value={method}
        onChange={changeAction}
        inputProps={{ "aria-label": "Without label" }}
        fullWidth
        disabled={disabled}
        displayEmpty
        renderValue={SelectOne}
        style={{
          width: "100%",
          borderRadius: "8px",
          outline: "none",
          boxShadow: 'none',
          border: "none",
          ...(maxWidth && {
            maxWidth,
          }),
        }}
      >
        {items?.map((option) => (
          <MenuItem
            // onClick={() => {
            //   setId(option.id);
            //   setName(option.name);
            // }}
            key={option.value ? option.value : option}
            value={option.value ? option.value : option}
          >
            {option.name ? option.name : option}
          </MenuItem>
        ))}
      </MUISelect>
    </FormAligner>
  );
}

export default Select;
