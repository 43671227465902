import { useQuery } from "react-query";

import { getMyTicketsById } from "../services/feedbackServices";




const fetchMyTicketDetails = (ticketid) => {
    return getMyTicketsById(ticketid)
}

export const useMyTicketsData = (ticketid) => {


    return useQuery(['my-tickets', ticketid],()=> fetchMyTicketDetails(ticketid))
}


