import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetLeaseTypeQuery = ({ productId, yearOfManufacture }) => {
  const getLeaseType = async () => {
    const request = await axiosInstance.get(
      `/lease-type/${productId}/${yearOfManufacture}`
    );
    const response = request.data;
    return response;
  };
  // ​/lease-type​/${productId}​/${yearOfManufacture}
  const query = useQuery(
    ["LEASE_TYPE", productId, yearOfManufacture],
    getLeaseType
  );
  return query;
};

export default useGetLeaseTypeQuery;
