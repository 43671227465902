import React, { useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../components/Buttons";
import BlockSpacer from "../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../components/Cards/ConsumerLoanCard";
import { Link } from "react-router-dom";
import OfferSuccessModal from "../../../components/Modals/ConsumerOfferModal/index.jsx";
import SourceAccountModal from "../../../components/Modals/CollateralLoan/SourceAccountMode";
import Note from "../../../components/Modals/CollateralLoan/consumer-notice/index";

import BusinessNote from "../../../components/Modals/CollateralLoan/Business-note/index";

import ApproveBankModal from "../../../components/Modals/AproveBankModel/index.jsx";
import ApproveBusinessBankModal from "../../../components/Modals/ApproveBankModal(consumer-business)/index";
import LoanValueModal from "../../../components/Modals/CollateralLoan/SorceAccount(B)";
import TopBar from "../../../components/GetCreditTopBar";

function ConsumerloanSummary(props) {
  // Acceptance Loan Offer
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const handleOfferOpen = () => setOpenOfferModal(true);
  const handleOfferClose = () => setOpenOfferModal(false);

  //Source Account Modal
  const [openSource, setOpenSource] = useState(false);
  const handleSource = () => {
    setOpenSource(true);
    setOpenOfferModal(false);
  };
  const handleSourceClose = () => setOpenSource(false);

  //Note Ticket

  const [openTicket, setOpenTicket] = useState(false);
  const handleNoteTicketOpen = () => {
    setOpenTicket(true);
    setOpenSource(false);
  };

  const handleOpenTicketClose = () => {
    setOpenTicket(false);
  };

  // Open Approve Bank
  const [openApprove, setOpenApprove] = useState(false);
  const handleApprovalOpen = () => {
    setOpenApprove(true);
    setOpenTicket(false);
  };
  const handleApprovalClose = () => {
    setOpenApprove(false);
  };

  // Business Source Account Modal

  const [openBusiness, setOpenBusiness] = useState(false);

  const handleBusiness = () => {
    setOpenBusiness(true);
    setOpenSource(false);
    setOpenApprove(false);
  };

  const handleBusinessClose = () => {
    setOpenBusiness(false);
  };
  //Note Ticket for Business Account

  const [noteTicket, setNoteTicket] = useState(false);
  const handleNoteTicket = () => {
    setNoteTicket(true);
    setOpenBusiness(false);
  };

  const handleNoteTicketClose = () => {
    setNoteTicket(false);
  };

  //Approve Bank Statement for Business Account
  const [openBusinessApproval, setOpenBusinessApproval] = useState(false);

  const handleOPenBusinessApproval = () => {
    setOpenBusinessApproval(true);
    setNoteTicket(false);
  };

  const handleCloseBusinessApproval = () => {
    setOpenBusinessApproval(false);
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={5} />

          <Typography variant="h5" component="h5" sx={contentTitle}>
            Loan Summary
          </Typography>

          <SummaryCard
            title={"Consumer Business Support Loan"}
            currencyRequested={"Accessible Loan Amount"}
            currency={"2,000,000"}
            tenor={"Tenor"}
            days={"Two Months"}
            interestRate={"Interest Rate"}
            interestRatePercentage={"20.00%"}
            interestPayable={"Interest Payable"}
            feePayableCurrency={"2,000,000"}
            feeRate={"Fee Rate"}
            feePayable={"Fee Payable"}
            TotalRepayment={"Total Repayment"}
            totalPaymentAmount={"100,000"}
            totalLoanDepositWieght={"Total Debt Weight"}
            totalLoanAmount={"20.00%"}
            LoanDueDate={"Loan Due Date"}
            dueDate={"12/10/2022"}
            firstMonthRepayment={"First Monthly Repayment"}
            secondMonthRepayment={"Second Monthly Repayment"}
            DueDate={"Due Date"}
            hundredThousand={"100,000"}
          />
        </Section>
        <BlockSpacer mt={40} />
        <Paper
          elevation={1}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "100%" },
            margin: "1rem",
            marginLeft: { lg: "-2rem" },
            marginRight: { lg: "auto" },
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/consumer-business">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: { xs: "300px", lg: "328px" },
                  height: "54px",
                  color: "#111E6C",
                  maxWidth: "100%",
                  marginBottom: { xs: "1rem" },
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "22px",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
            </Link>

            <Buttons
              color="primary"
              width="328px"
              onClick={() => handleOfferOpen()}
            >
              Accept Offer
            </Buttons>
          </Box>
        </Paper>
        {openOfferModal && (
          <OfferSuccessModal
            open={openOfferModal}
            handleClose={handleOfferClose}
            handleSource={handleSource}
          />
        )}
        {openSource && (
          <SourceAccountModal
            open={openSource}
            handleClose={handleSourceClose}
            handleNoteTicketOpen={handleNoteTicketOpen}
          />
        )}
        {openTicket && (
          <Note
            open={openTicket}
            handleClose={handleOpenTicketClose}
            handleApprovalOpen={handleApprovalOpen}
          />
        )}

        {openApprove && (
          <ApproveBankModal
            open={openApprove}
            handleClose={handleApprovalClose}
            handleBusiness={handleBusiness}
          />
        )}
        {openBusiness && (
          <LoanValueModal
            open={openBusiness}
            handleClose={handleBusinessClose}
            handleNoteTicket={handleNoteTicket}
          />
        )}
        {noteTicket && (
          <BusinessNote
            open={noteTicket}
            handleClose={handleNoteTicketClose}
            handleOPenBusinessApproval={handleOPenBusinessApproval}
          />
        )}
        {openBusinessApproval && (
          <ApproveBusinessBankModal
            open={openBusinessApproval}
            handleClose={handleCloseBusinessApproval}
          />
        )}
      </>
    </>
  );
}

export default ConsumerloanSummary;
