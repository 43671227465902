import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
import SummaryCard from "../../../components/Cards/ProprietorCard";
import Buttons from "../../../components/Buttons";
import { Section, documentStyle, responsonive } from "./style";
import DocumentIcon from "../../../components/IconSvgs/Document";
import CheckBox from "../../../components/Checkboxs";
import document from "../../../components/Assets/images/document.png";
import SuccessLoan from "../../../components/Modals/Success(p)";
import TopBar from "../../../components/GetCreditTopBar";

function ProprietorTermsCondition() {
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess(true);
  const handleSuccessClose = () => setSuccess(false);
  return (
    <>
      <TopBar />
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {" "}
            <SummaryCard
              title={"Sole Proprietor Business Support Loan"}
              currencyRequested={"Accessible Loan Amount"}
              currency={"2,000,000"}
              tenor={"Tenor"}
              days={"Two Months"}
              interestRate={"Interest Rate"}
              interestRatePercentage={"20.00%"}
              interestPayable={"Interest Payable"}
              feePayableCurrency={"2,000,000"}
              feeRate={"Fee Rate"}
              feePayable={"Fee Payable"}
              TotalRepayment={"Total Repayment"}
              hundredThousand={"100,000"}
              TotalDebtWeight={"Total Debt Weight"}
              TotalDebtPercentage={"20.00%"}
              LoanDueDate={"Loan Due Date"}
              Date={"12/10/2022"}
              firstMonthRepayment={"First Monthly Repayment"}
              firstMonthFee={"100,000"}
              DueDate={"Due Date"}
              dueDate={"12/10/2022"}
              secondMonthRepayment={"Second Monthly Repayment"}
              secondMonthFee={"100,000"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {" "}
            <Box sx={documentStyle}>
              <img src={document} alt="document" style={responsonive} />

              <BlockSpacer mt={20} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    marginLeft: "5rem",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  <CheckBox size={"small"} />I agree to the
                </Typography>
                <div
                  style={{
                    color: "#111E6C",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Terms
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
        <BlockSpacer mt={150} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "89%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: { xs: "300px", lg: "328px" },
                height: "54px",
                color: "#111E6C",
                maxWidth: "100%",
                marginBottom: { xs: "1rem" },
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "22px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Buttons
              color="primary"
              width="328px"
              onClick={() => handleSuccess()}
            >
              Process Loan
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {success && (
        <SuccessLoan open={success} handleClose={handleSuccessClose} />
      )}
    </>
  );
}

export default ProprietorTermsCondition;
