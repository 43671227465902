import React from "react";
import BlockSpacer from "../../BlockSpacer";
import CoperateCompanyForm from "../../Forms/CoperateCompanyForm";
import style from "./style.module.css";

function CoperateCompanyDetail({
  defaultUserMethod,
  setDefaultUserMethod,
  setKycInfo,
  kycInfo,
  setBvn,
  verified,
  setVerified,
  bvnVerified,
  verifyBvn,
  verifedLoading,
  error,
}) {
  return (
    <div className={style.container}>
      <div className={style.title}>Contact Person Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <CoperateCompanyForm 
          setKycInfo={setKycInfo} 
          info={kycInfo}
          setBvn={setBvn}
          verified={verified}
          setVerified={setVerified}
          bvnVerified={bvnVerified}
          verifyBvn={verifyBvn}
          verifedLoading={verifedLoading}
          error={error}
        />
      </div>
    </div>
  );
}

export default CoperateCompanyDetail;
