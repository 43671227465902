import React, { useState } from "react";
import { Nav, ProfileContain } from "./style";
import DehazeIcon from "@mui/icons-material/Dehaze";
import MobileAlternate from "../Logo/MobleAlternate";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import MailIcon from "@mui/icons-material/Mail";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "../Assets/icons/Category.svg";
import CreditIcon from "../IconSvgs/CreditIcon.jsx";
import HelpmeIcon from "../IconSvgs/helpmeIcon.jsx";
import MoneyIcon from "../IconSvgs/MoneyIcon.jsx";
import TombupIcon from "../IconSvgs/TombupIcons.jsx";
import WalletIcon from "../IconSvgs/WalletIcon.jsx";
import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Logout } from "@mui/icons-material";
import { userLogout } from "../../redux/slices/authSlice";

function MobileTopbar() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openFeedback, setOpenFeedback] = useState(false);

  const { documents, users, companyDocs } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerRoutes = [
    {
      name: "Home",
      link: "/dashboard",
      icon: HomeIcon,
    },
    {
      name: "Get Credit",
      link: "/get-credit",
      icon: <CreditIcon />,
    },
    {
      name: "My Credit",
      link: "/my-credit",
      icon: <MoneyIcon />,
    },
    {
      name: "Wallet",
      link: "/wallet",
      icon: <WalletIcon />,
    },
    {
      name: "Feedback",
      link: "/feedback",
      icon: <TombupIcon />,
    },

    // {
    //     name:"My-Tickets",
    //     link:'feedback/my-tickets'
    // },
    // {
    //     name:"Open-Tickets",
    //     link:'feedback/open-tickets'
    // },
    // {
    //     name:"Closed-Tickets",
    //     link:'feedback/closed-tickets'
    // },
    {
      name: "Help",
      link: "/help",
      icon: <HelpmeIcon />,
    },
    // {
    //     name: 'Profile',
    //     link: 'profile/personal-info'
    // },
    // {
    //     name: 'Company profile',
    //     link: '/profile/company/info'
    // }
  ];

  const feedBackRoutes = [
    {
      name: "Create Ticket",
      link: "/feedback",
    },
    {
      name: "My-Tickets",
      link: "/feedback/my-tickets",
    },
    {
      name: "Open-Tickets",
      link: "/feedback/open-tickets",
    },
    {
      name: "Closed-Tickets",
      link: "/feedback/closed-tickets",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logout = (anchor) => {
    toggleDrawer(anchor, false)
    localStorage.clear();
    navigate("/");
    dispatch(userLogout());
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <Box
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <ProfileContain>
          <Avatar
            sx={{
              marginTop: "1rem",
            }}
            alt={users?.role === "INDIVIDUAL_USER"
            ? `${users?.individualUser?.firstName} ${users?.individualUser?.lastName}`
            : users?.company?.name}
            src={
              users?.role === "INDIVIDUAL_USER"
                ? documents?.data?.passportPhotographImage?.imageUrl
                : users?.role === "COMPANY"
                ? companyDocs?.contactPersonPhotographImage?.imageUrl
                : null
            }
          />
          <div className="profile-detail">
            <p style={{ padding: 0, margin: 0, fontWeight: 600 }}>
              {users?.role === "INDIVIDUAL_USER"
                ? `${users?.individualUser?.firstName} ${users?.individualUser?.lastName}`
                : users?.company?.name}
            </p>
            {users?.role === "INDIVIDUAL_USER" ? (
              <Link to="/profile">
                <p className="profile">View Profile</p>
              </Link>
            ) : (
              <Link to="/profile">
                <p className="profile">View Company Profile</p>
              </Link>
            )}
          </div>
        </ProfileContain>
      </Box>
      <List>
        {drawerRoutes.map((drawer, index) =>
          drawer.name === "Feedback" ? (
            <>
              <Accordion
                sx={{
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                }}
                style={{
                  boxShadow: "none",
                  marginTop: "-12px",
                  padding: 0,
                  marginBottom: "-14px",
                }}
                disableGutters
              >
                <AccordionSummary
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: "100%",
                    boxShadow: "none",
                    border: "none",
                  }}
                  aria-controls="panel1a-content"
                  style={{ boxShadow: "none", margin: 0, padding: 0 }}
                  id="panel1a-header"
                >
                  <ListItem
                    key={index}
                    disablePadding
                    style={{ margin: 0 }}
                    component={"div"}
                  >
                    <ListItemButton>
                      <ListItemIcon>{drawer.icon}</ListItemIcon>
                      <ListItemText primary={drawer.name} />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ paddingLeft: 7, marginLeft: 0, boxShadow: "none" }}
                  style={{ boxShadow: "none", marginTop: "-14px" }}
                >
                  {feedBackRoutes.map((route, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      component={Link}
                      to={route.link}
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                      disableGutters
                    >
                      <ListItemButton>
                        <ListItemText primary={route.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <ListItem
              key={index}
              disablePadding
              component={Link}
              to={drawer.link}
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <ListItemButton>
                <ListItemIcon>
                  {drawer.name === "Home" ? (
                    <img src={drawer.icon} alt="home" />
                  ) : (
                    drawer.icon
                  )}
                </ListItemIcon>
                <ListItemText primary={drawer.name} />
              </ListItemButton>
            </ListItem>
          )
        )}
        <ListItem
          disablePadding
          component={"div"}
          onClick={()=>logout(anchor)}
          onKeyDown={()=>logout(anchor)}
        >
          <ListItemButton>
            <ListItemIcon>
              <Logout style={{color:"#000"}} />
            </ListItemIcon >
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Nav>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: 2, fleGrow: 1, width: "100px" }}>
            <MobileAlternate />
          </Box>
          <Box sx={{ padding: 2 }}>
            <div>
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}>
                    <DehazeIcon sx={{ color: "#222222" }} />
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    variant={"temporary"}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </Box>
        </Box>
      </Nav>
    </>
  );
}
export default MobileTopbar;
