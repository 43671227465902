import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  overflow: hidden;
  @media (max-width: 1244px) {
    margin-top: 25%;
  }
`;

export const choosePayment = {
  padding: "1rem",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "22px",
  letterSpacing: "-0.5px",
  color: "#222222",
  marginLeft: { lg: "-1rem", xs: "1rem" },
};

export const PaymantBox = {
  width: { xs: "90%", lg: "405px" },
  padding: "1rem",
};

export const Title = {
  fontFamily: "Montserrat",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "22px",
  letterSpacing: "-0.5px",
  marginLeft: "1rem",
  marginTop: { xs: "8rem", lg: "0rem" },
};

export const id = {
  fontFamily: "Montserrat",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "-0.04em",
  color: "#828282",
  textTransform: "capitalize",
};

export const it = {
  fontFamily: "Montserrat",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "18px",
  letterSpacing: "-0.04em",
  color: "#828282",
  textTransform: "capitalize",
};
