import HttpService from "./HttpService";

export const getAuthUsersService = () => {
  const http = new HttpService();
  const url = "auth/users";

  return http.getData(url);
};

export const getAuthUserService = (email) => {
  const http = new HttpService();
  const url = `auth/${email}/users`;

  return http.getData(url);
};

export const sendOtpService = () => {
  const http = new HttpService();
  const url = `auth/individual-user/send-otp`;
  return http.getData(url);
};

export const validateOtpService = (otp) => {
  const http = new HttpService();
  const url = `auth/validate-otp/${otp}`;
  return http.postDataWithToken(null, url);
};

export const getBanksService = () => {
  const http = new HttpService();
  const url = `bank-account/get-all-banks`;
  return http.getData(url);
};

export const getAllGenderService = () => {
  const http = new HttpService();
  const url = `auth/admin/gender?status=ACTIVE`;
  return http.getData(url);
};

export const getCountriesService = () => {
  const http = new HttpService();
  const url = `country`;

  return http.getData(url);
};

export const getStatesService = (countryId) => {
  const http = new HttpService();
  const url = `state/${countryId}`;

  return http.getData(url);
};

export const updateUserKycService = (formData) => {
  const http = new HttpService();
  const url = `auth/users`;

  return http.putData(formData, url);
};

export const verifyBvnService = (formData) => {
  const http = new HttpService();
  const url = `auth/verify-bvn`;

  return http.postDataWithToken(formData, url);
};

export const getLgasService = (stateId) => {
  const http = new HttpService();
  const url = `lga/${stateId}`;

  return http.getData(url);
};


export const sendCompanyOtpService = () => {
  const http = new HttpService();
  const url = `auth/company/company-document/send-otp`;
  return http.getData(url);
};

export const getCompanyDocsService = () => {
  const http = new HttpService();
  const url = `auth/company/company-document`;
  return http.getData(url);
};

export const getBankDetailsService = () => {
  const http = new HttpService();
  const url = `auth/individual-user/bank-account?platform=CREDIT`;
  return http.getData(url);
};

export const getBusinessBankDetailsService = () => {
  const http = new HttpService();
  const url = `/auth/individual-userbank-account-business?platform=CREDIT`;
  return http.getData(url);
};

export const getWithdrawReasonService = () => {
  const http = new HttpService();
  const url = `auth/trwithdrawal`;
  return http.getData(url);
};

export const getUserDocsService = () => {
  const http = new HttpService();
  const url = `auth/individual-user/my-document/get-by-user/credit`;
  return http.getData(url);
};

export const getAllSourcesService = () => {
  const http = new HttpService();
  const url = `auth/admin/sources?status=ACTIVE`;
  return http.getDataWithoutToken(url);
};

export const getAllIdTypesService = () => {
  const http = new HttpService();
  const url = `auth/admin/identification-type?status=ACTIVE`;
  return http.getData(url);
};

export const getAllProfessionalOccupationService = () =>{
  const http = new HttpService();
  const url = `users/get-all-professional-occupation`;
  return http.getData(url);
}

export const getAllCompanyGroupService = () =>{
  const http = new HttpService();
  const url = `users/get-all-group-company`;
  return http.getData(url);
}

export const getIndustryManagementService = () =>{
  const http = new HttpService();
  const url = `users/get-all-industry-management`;
  return http.getData(url);
}

export const getKycDetailsService = () =>{
  const http = new HttpService();
  const url = `auth/users/get-kyc-details`;
  return http.getData(url);
}

export const getCompanyInclusionService = () => {
  const http = new HttpService();
  const url = `users/get-all-company-incl`;
  return http.getData(url);
}

export const getErpSectorService = () => {
  const http = new HttpService();
  const url = "user/get-all-erp-sectors";

  return http.getData(url);
};
