import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  cardBox,
  summaryTitle,
  currencyStyle,
  secondCurrencyStyle,
  firstCurrencyStyle,
  currencyBlue,
  Cardstyle,
} from "./style";
import DotLine from "../../Hocs/DotLine";
import BlockSpacer from "../../BlockSpacer";

export default function SummaryCard({
  title,
  currencyRequested,
  tenor,
  currency,
  days,
  TotalRepayment,
  hundredThousand,
  UpfrontPayment,
  firstMonthRepayment,
  secondMonthRepayment,
  secondMonthFee,
  PercentageOfleaseValue,
  DebtAmount,
  dueDate,
  DueDate,
  RepaymentAmount,
  TotalDebtWeight,
  Percentagelease,
  firstMonthFee,
  monthlyRepayments,
}) {
  return (
    <>
      <Paper elevation={0.5} sx={cardBox}>
        <Typography variant="h6" style={summaryTitle}>
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{currencyRequested}</Typography>
            <Typography sx={firstCurrencyStyle}>{currency}</Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{UpfrontPayment}</Typography>
            <Typography sx={secondCurrencyStyle}>{hundredThousand}</Typography>
          </Box>
        </Box>
        <DotLine />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{PercentageOfleaseValue}</Typography>
            <Typography sx={secondCurrencyStyle}>{Percentagelease}</Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{tenor}</Typography>
            <Typography sx={secondCurrencyStyle}>{days}</Typography>
          </Box>
        </Box>
        <DotLine />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{TotalRepayment}</Typography>
            <Typography sx={currencyBlue}>{RepaymentAmount}</Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{TotalDebtWeight}</Typography>
            <Typography sx={currencyBlue}>{DebtAmount}</Typography>
          </Box>
        </Box>
        <DotLine />
        {monthlyRepayments &&
            monthlyRepayments?.map((repayment, id) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
                key={id}
              >
                <Box>
                  <Typography sx={currencyStyle}>
                    {id === 0
                      ? `1st Monthly Payment`
                      : id === 1
                      ? `2nd Monthly Payment`
                      : id === 2
                      ? `3rd Monthly Payment`
                      : `${id + 1}th Monthly Payment`}
                  </Typography>
                  <Typography sx={currencyBlue}>
                    ₦
                    {parseFloat(repayment?.amount)?.toLocaleString("en-Us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>

                <Box>
                  <Typography sx={currencyStyle}>
                    {id === 0
                      ? `1st Due Date`
                      : id === 1
                      ? `2nd Due Date`
                      : id === 2
                      ? `3rd Due Date`
                      : `${id + 1}th Due Date`}
                  </Typography>

                  <Typography sx={currencyBlue}>
                    {repayment?.dueDate}
                  </Typography>
                </Box>
              </Box>
            ))}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{firstMonthRepayment}</Typography>
            <Typography sx={currencyBlue}>{firstMonthFee}</Typography>
          </Box>

          <Box>
            <Typography sx={currencyStyle}>{DueDate}</Typography>
            <Typography sx={currencyBlue}>{dueDate}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem",
          }}
        >
          <Box>
            <Typography sx={currencyStyle}>{secondMonthRepayment}</Typography>
            <Typography sx={currencyBlue}>{secondMonthFee}</Typography>
          </Box>
          <Box>
            <Typography sx={currencyStyle}>{DueDate}</Typography>
            <Typography sx={currencyBlue}>{dueDate}</Typography>
          </Box>
        </Box> */}
      </Paper>
    </>
  );
}
