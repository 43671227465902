import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFaqService } from "../../services/helpServices";

const initialState = {
  loading: false,
  faqs: null,
  faqError: null,
};

export const getFaq = createAsyncThunk('help/getFaq', (formData, { rejectWithValue }) => {
  return getFaqService()
  .then(response => response.data)
  .catch(error => rejectWithValue(error.response))
});

const helpSlice = createSlice({
  name: 'help',
  initialState,
  extraReducers: builder => {
    // faq pending
    builder.addCase(getFaq.pending, state => {
      state.loading = true
    })
    // faq success
    builder.addCase(getFaq.fulfilled, (state, action) => {
      state.loading = true
      state.faqs = action.payload
      state.faqError = null
    })
    // faq fail
    builder.addCase(getFaq.rejected, (state, action) => {
      state.loading = false
      state.faqs = null
      state.faqError = action.error
    })
  }
});

export default helpSlice.reducer
