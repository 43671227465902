
export const corporateKycValidation = (propsData) => {
  const validate = 
  propsData?.company?.name === "" ||
  propsData?.company?.name === null ||
  propsData?.company?.rcNumber === "" ||
  propsData?.company?.rcNumber === null ||
  propsData?.company?.companyAddress === "" ||
  propsData?.company?.companyAddress === null ||
  propsData?.businessDetails?.city === null ||
  propsData?.businessDetails?.city === "" ||
  propsData?.phone === "" || 
  propsData?.contactPersonDetails?.address === null ||
  propsData?.contactPersonDetails?.address === "" ||
  propsData?.contactPersonDetails?.phone === "" ||
  propsData?.contactPersonDetails?.phone === null ||
  propsData?.contactPersonDetails?.stateId === 0 ||
  propsData?.contactPersonDetails?.stateId === null ||
  propsData?.contactPersonDetails?.contactPersonCity === null ||
  propsData?.contactPersonDetails?.contactPersonCity === ""


  return validate;
}
