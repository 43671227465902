import React from "react";
import { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { Section, inputError } from "./style";
import { FormControl } from "@mui/material";
import FormAligner from "../../components/Hocs/FormAligner";
import {
  feedbackPostService,
  feedbackCategoryService,
} from "../../services/feedbackServices";
import { Link, useNavigate } from "react-router-dom";
import Select from "../../components/Core/Select/index.jsx";
import FormLabel from "../../components/Forms/Label";
import Unauthorised from "./ticket-details";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import BlockSpacer from "../../components/BlockSpacer";
import OfferSuccessModal from "../../components/Modals/FeedbackModal/index.jsx";
import axios from "axios";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../../redux/reducers/feedbackpost";
import { feedbackActions } from "../../redux/reducers/feedbackpost";
import OpenTickets from "../Open-Ticket";
import { fetchId } from "../../redux/slices/CategoryIdSlice";
// import { categoryIdActions } from "../../redux/slices/CategoryIdSlice";
//import { setMessageTitle } from "../../redux/reducers/feedbackpost";
import { basicSchema } from "../../components/schemas";
import TopBar from "../../components/FeedBackTopBar";
import SelectField from "../../components/Core/SelectField";

function FeedBack() {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const { reset } = useForm();
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [res, setRes] = useState([]);
  const dispatch = useDispatch();
  const { feedBack } = useSelector((state) => state.feedback);
  const { Id } = useSelector((state) => state.id);

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      messageTitle: "",
      messageDescription: "",
    },
    validationSchema: basicSchema,
    onSubmit: async (values, actions) => {
      console.log("submitted");
      console.log("Form data", values);
      console.log("Submit props", actions);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      actions.resetForm();
    },
  });

  console.log(errors);
  const [auth, setAuth] = useState(false);
  const handleAuth = () => {
    setAuth(true);
  };

  const router = useNavigate();

  const [categories, setCategories] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [method, setMethod] = React.useState("");

  const [id, setId] = useState(null);

  const changeAction = (e) => {
    setMethod(e.target.value);
  };
  const getCategories = async () => {
    try {
      const response = await feedbackCategoryService();

      // "http://localhost:5000/feedback"

      console.log(response.data, "This is the response");
      const activeCategories = response.data?.filter(
        (item) => item?.status === "ACTIVE"
      );
      let opts = activeCategories.map((opt) => ({
        name: opt.name,
        value: opt.id,
      }));
      let options = activeCategories.map((opt) => opt.name);
      console.log("pos",)

      console.log(opts);
      localStorage.setItem("categories", JSON.stringify(response.data));
      setCategories(opts);
      setCategoryOptions(options);
    } catch (error) {
      console.log("This is the Error", error.message);
    }
  };

  const getCategoryid = () => {
    dispatch(fetchId({ ...Id }));
  };

  useEffect(() => {
    getCategories();
    getCategoryid();
  }, []);

  const formData = {
    categoryId: method,
    content: feedBack.messageDescription,
    images: null,
    platform: "CREDIT",
    title: feedBack.messageTitle,
  };

  // {
  //   category: categories,
  //   content: feedBack.messageDescription,
  //   platform: "CREDIT",
  //   title: feedBack.messageTitle,
  // };
  const PostFeedBack = async () => {
    setLoading(true);
    try {
      const response = await feedbackPostService({
        ...formData,
        categoryId: categories?.find((item) => item.name === method)?.value,
        ds: "eui"
      });

      if (response) {
        console.log(response.data, "This is the post response");

        handleOpenModal();

        setLoading(false);
      }
    } catch (error) {
      console.log("this is the error", error.message);
      setLoading(false);
      setError(error?.message);
    }
  };

  const handleUserInputs = (e) => {
    dispatch(feedbackActions({ ...feedBack, [e.target.name]: e.target.value }));
  };

  const handleSubmitForm = () => {
    PostFeedBack();
  };

  return (
    <div>
      <TopBar />
      <>
        <main>
          <Section>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "21px",
              }}
            >
              Create Tickets
            </Typography>
            <BlockSpacer mt={40} />
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <SelectField
                        method={method}
                        name={"Select Feedback Category to begin with"}
                        items={categoryOptions}
                        changeAction={changeAction}
                      />
                      {/* <Select
                        method={
                          categories?.filter(({ value }) => value === method)[0]
                            ?.name
                        }
                        name={"Select Feedback Category to begin with"}
                        items={categories}
                        changeAction={changeAction}
                      /> */}
                    </FormAligner>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Title of Message"} />
                      <TextField
                        id="title"
                        name="messageTitle"
                        type="text"
                        value={values.messageTitle}
                        onChange={(e) => {
                          handleChange(e);
                          handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter your message Title"
                        InputProps={{
                          style: {
                            color: "grey",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                          },
                        }}
                        error={
                          errors.messageTitle && touched.messageTitle
                            ? inputError
                            : ""
                        }
                        helperText={
                          errors.messageTitle &&
                          touched.messageTitle &&
                          errors.messageTitle
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
              </Grid>
              <BlockSpacer mt={50} />

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Box
                    sx={{
                      width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" },
                      maxWidth: "100%",
                      padding: "1rem",
                      marginLeft: { xs: "1rem", lg: "0rem" },
                    }}
                  >
                    <FormAligner>
                      <FormLabel text={"Description"} />
                      <TextField
                        placeholder="Enter your message"
                        id="description"
                        name="messageDescription"
                        value={values.messageDescription}
                        onChange={(e) => {
                          handleChange(e);
                          handleUserInputs(e);
                        }}
                        onBlur={handleBlur}
                        multiline
                        rows={8}
                        colummns={4}
                        InputProps={{
                          style: {
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                          },
                        }}
                        error={
                          errors.messageDescription &&
                          touched.messageDescription
                            ? inputError
                            : ""
                        }
                        helperText={
                          errors.messageDescription &&
                          touched.messageDescription &&
                          errors.messageDescription
                        }
                      />
                    </FormAligner>
                  </Box>
                </Grid>
              </Grid>

              <BlockSpacer mt={80} />
              <Paper
                elevation={0.5}
                sx={{
                  height: { xs: "130px", lg: "112px" },
                  width: { xs: "92%", sm: "100%", lg: "100%" },
                  maxwidth: "100%",
                  boxShadow: " 8px 0px 18px rgba(173, 173, 173, 0.25)",
                  background: "#FFFFF",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "95%",
                  }}
                >
                  {!loading && (
                    <Button
                      type="submit"
                      sx={{
                        background:
                          !isValid || isSubmitting ? "#EDEDED" : "#111E6C",
                        width: "328px",
                        height: "54px",
                        color: "#FFFFFF",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        marginTop: "1.5rem",
                        marginLeft: { xs: "1.3rem", lg: "5.5rem" },
                        textTransform: "capitalize",
                        fontSize: "18px",
                        borderRadius: "10px",
                        "&:hover": {
                          background: "#FFFFFF",
                          color: "#111E6C",
                        },
                      }}
                      onClick={() => {
                        handleSubmit();
                        handleSubmitForm();
                      }}
                      disabled={!isValid || isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                  {loading && (
                    <Button
                      type="submit"
                      sx={{
                        background: "#111E6C",
                        width: "328px",
                        height: "54px",
                        color: "#FFFFFF",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        marginTop: "1.5rem",
                        marginLeft: "5.5rem",
                        textTransform: "capitalize",
                        fontSize: "18px",
                        borderRadius: "10px",
                      }}
                    >
                      <CircularProgress />
                    </Button>
                  )}
                </Box>
              </Paper>
              {/* <BlockSpacer mt={50}/> */}
            </form>
          </Section>
        </main>
      </>

      {openModal && (
        <OfferSuccessModal open={openModal} handleClose={handleModalClose} />
      )}
    </div>
  );
}

export default FeedBack;
