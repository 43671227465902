import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import checked from "../../Assets/icons/checked1.svg";
import { Backdrop } from "@mui/material";
import Button from "../../Buttons";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:310, sm:310, md:416},
  maxWidth: "100%",
  height: 380,
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function NoteModal({
  open,
  handleClose,
  handleCheckinfo,
  handleLoanOpen,
}) {
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <BlockSpacer mt={30} />
            <div className={styles.image}>
              <img src={checked} alt="failed" />
            </div>

            <BlockSpacer mt={50} />

            <div className={styles.text}>
              You are not eligible for this loan, you can try again later
            </div>
            <BlockSpacer mt={10} />

            <BlockSpacer mt={50} />

            <div className={styles.button}>
              <Button
                color={"primary"}
                width={240}
                height={41}
                onClick={() => handleLoanOpen()}
              >
                ok
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
