import React, { useEffect ,useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../../components/Buttons";
import { Link } from "react-router-dom";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Section, Title } from "./style";
// import SummaryCard from "../../../../components/Cards/VehicleSummaryCard";
import SummaryCard from "../../../../components/Cards/PersonalCard";
import SuccessModalOffer from "../../../../components/Modals/SuccessModalOffer";
import SourceAccountModal from "../../../../components/Modals/CollateralLoan/SourceAccountMode";
import Note from "../../../../components/Modals/CollateralLoan/Note";
import ApproveBankModal from "../../../../components/Modals/CollateralLoan/ApproveVehicleBank";
import TopBar from "../../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { acceptLoanOffer } from "../../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function VehicleloanSummary(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleSuccessClose = () => setOpen(false);

  const dispatch = useDispatch();

  const { initializedLoan, repayment, acceptLoan, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (acceptLoan) {
      handleOpen();
    }
  }, [acceptLoan]);

  //Source Account Modal

  const [openSourceAccount, setOPenSourceAccount] = useState(false);

  const handleSourceAccount = () => {
    setOPenSourceAccount(true);
    setOpen(false);
  };

  const handleSourceAccountClose = () => setOPenSourceAccount(false);
  // Note Ticket ID

  const [openNoteTicket, setNoteOpenTicket] = useState(false);
  const handleNoteTicketOpen = () => {
    setNoteOpenTicket(true);
    setOPenSourceAccount(false);
  };
  const handleNoteTicketClose = () => {
    setNoteOpenTicket(false);
  };
  // Ticket Note

  const [openTicketId, setOpenTicketId] = useState(false);

  const handleOpenTicket = () => {
    setOpenTicketId(true);
    setNoteOpenTicket(false);
  };

  const handleCloseTicket = () => {
    setOpenTicketId(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      acceptLoanOffer({
        action: "accept",
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={5} />
          <Typography variant="h5" sx={Title}>
            Loan Summary
          </Typography>
          {/* <SummaryCard
            title={"Vehicle Asset Cash Loan"}
            currencyRequested={"Accessible Loan Amount"}
            interestRate={"Interest Rate"}
            interestRatePercentage={"20.00%"}
            tenor={"Tenor"}
            dataNumber={"12/08/2022"}
            feeRate={"Processing Fee Rate"}
            currency={"2,000,000"}
            firstMonthRepayment={"First Monthly Repayment"}
            totalLoanAmount={"100,000"}
            interestPayable={"Interest Payable"}
            days={"Two Months"}
            dueDate={"Due Date"}
            totalLoanDepositWieght={"Insurance Payable"}
            infoPayable={"Interest Payable"}
            feePayable={"Processing Fee Payable"}
            feePayableCurrency={"2,000,000"}
            TotalRepayment={"Total Repayment"}
            LoanDueDate={"Loan Due Date"}
            LegalFeePayable={"Legal Fee Payable"}
            LegalFeeRate={"Legal Fee Rate"}
            totalPaymentAmount={"100,000"}
            StampDutyFeePayable={"Stamp Duty Fee Payable"}
            StampDutyFeeRate={"Stamp Duty Fee Rate"}
            secondMonthRepayment={"Second Monthly Repayment"}
            ManagementFeePayable={"Management Fee Payable"}
            ManagementFeeRate={"Management Fee Rate"}
            InsurancePayable={"Insurance Payable"}
            hundredThousand={"100,000"}
          /> */}
          <SummaryCard
          title={initializedLoan?.data?.body?.productName}
          currencyRequested={"Accessible Loan Amount"}
          currency={`₦${parseFloat(
            repayment?.object?.body?.body?.accessibleLoanAmount
          )}`}
          tenor={"Tenor"}
          days={`${repayment?.object?.body?.body?.tenor} Months`}
          interestRate={"Interest Rate"}
          interestRatePercentage={`${parseFloat(
            repayment?.object?.body?.body?.interestRate
          ).toFixed(4)}%`}
          interestPayable={"Interest Payable"}
          interestPayableCurrency={`₦${parseFloat(
            repayment?.object?.body?.body?.interestPayable
          )}`}
          FeeRate={"Fee Rate"}
          FeeRatePercentage={`${parseFloat(
            repayment?.object?.body?.body?.feeRate
          ).toFixed(4)}%`}
          feePayable={"Fee Payable"}
          feePayableCurrency={`₦${parseFloat(
            repayment?.object?.body?.body?.feePayable
          )}`}
          TotalRepayment={"Total Repayment"}
          totalPaymentAmount={`₦${parseFloat(
            repayment?.object?.body?.body?.totalRepayment
          )}`}
          TotalDebtWeight={"Total Debt Weight"}
          TotalDebtWeightPercentage={`₦${parseFloat(
            repayment?.object?.body?.body?.totalDebtWeight
          )}`}
          // LoanDueDate={"Loan Due Date"}
          // dueDate={"12/10/2022"}
          monthlyRepayments={
            repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
          }
        />

          <BlockSpacer mt="200" />

          <Paper
            elevation={1}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "92%", sm: "100%", lg: "89%" },
              margin: "1rem",
              maxwidth: "100%",
              boxShadow: "0px 4.47504px 22.3752px rgba(196, 204, 221, 0.23)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Link to="/vehicle-asset-cash-loan">
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: { xs: "300px", lg: "328px" },
                    height: "54px",
                    color: "#111E6C",
                    maxWidth: "100%",
                    marginBottom: { xs: "1rem" },
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "22px",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Button>
              </Link>

              <Buttons
                color="primary"
                width="328px"
                onClick={handleSubmit}
              >
                {
                  loading ? (<ClipLoader color={"#fff"} loading={loading} size={20} />) :
                  "Accept Offer"
                }
              </Buttons>
            </Box>
          </Paper>
          {open && (
            <SuccessModalOffer
              open={open}
              handleClose={handleSuccessClose}
              handleSourceAccount={handleSourceAccount}
            />
          )}
          {openSourceAccount && (
            <SourceAccountModal
              open={openSourceAccount}
              handleClose={handleSourceAccountClose}
              handleNoteTicketOpen={handleNoteTicketOpen}
            />
          )}
          {openNoteTicket && (
            <Note
              open={openNoteTicket}
              handleClose={handleNoteTicketClose}
              handleOpenTicket={handleOpenTicket}
            />
          )}
          {openTicketId && (
            <ApproveBankModal
              open={openTicketId}
              handleClose={handleCloseTicket}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default VehicleloanSummary;
