import React, { useEffect } from "react";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Section } from "./style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import Divider from "@mui/material/Divider";
import BlockSpacer from "../../components/BlockSpacer";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchBar from "./Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../redux/slices/helpSlice";
import TopBar from "../../components/HelpTopBar";
import useScreenSize from "../../components/ScreenSize";

function Help() {
  const dispatch = useDispatch();
  const filterData = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => d.toLowerCase().includes(query));
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getFaq());
  }, [])

  const data = [];
  const dataFiltered = filterData(searchQuery, data);
  const { width } = useScreenSize();

  const { faqs } = useSelector((state) => state.help);
  const faqMessages = faqs?.data?.body ? faqs?.data?.body : [];
  const activeFaqs = faqMessages.filter((data) => data.status === "ACTIVE");
  
  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Box
            display={"flex"}
            style={{
              justifyContent: width>700?"space-between":"center",
              maxWidth: width>700?"85%":"100%",
              alignItems: "center",
              flexWrap: "wrap",
              marginTop: width>700&&"-2rem",
            }}
          >
            <Box
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "17px",
                mt: { xs: "-2", lg: "-2" },
                textAlign: {xs:"center",sm:"center"},
                mb: {xs:2,sm:2}
              }}
            >
              Frequently Asked Questions
            </Box>
            <Box>
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 0,
                }}
              >
                <div className="searchAlign" >
                  <SearchBar
                    searchQuery={searchQuery}
                    clearText={()=>setSearchQuery("")}
                    setSearchQuery={setSearchQuery}
                  />
                </div>
                <div style={{ padding: 3 }}>
                  {dataFiltered.map((d) => (
                    <div
                      className="text"
                      style={{
                        padding: 5,
                        justifyContent: "normal",
                        fontSize: 20,
                        color: "blue",
                        margin: 1,
                        width: width>700?"250px":"auto",
                        BorderColor: "green",
                        borderWidth: "10px",
                      }}
                      key={d.id}
                    >
                      {d}
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </Box>
          <BlockSpacer mt={70} />
          
          {
            activeFaqs.map(data => (
              <div key={data.id}>
                <Accordion
                  sx={{
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                    border: 0,
                    maxWidth: width>700?"70%":"100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {" "}
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      {data?.name}
                    </Typography>
                  </AccordionSummary>
                  {
                    data?.faqs?.map(faq => faq?.status==="ACTIVE" && (
                      <AccordionDetails>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            fontWeight: "600",
                            lineHeight: "16px",
                            letterSpacing: "-0.01em",
                          }}
                        >
                          {faq?.question}
                        </Typography>
                        <Typography
                          style={{
                            paddingTop: "1rem",
                            paddingLeft: "1.5rem",
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "146.9%",
                            letterSpacing: "-0.01em",
                            width: "60%",
                          }}
                        >
                          {faq?.answer}
                        </Typography>
                      </AccordionDetails>
                    ))
                  }
                </Accordion>
                <BlockSpacer mt={40} />
              </div>
            ))
          }
        </Section>
      </main>
    </>
  );
}

export default Help;
