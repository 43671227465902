import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getActiveTransactionsService,
  getPendingTransactionsService,
  getClosedTransactionsService,
  getLoanRepaymentHistoryService,
  repayActiveLoanService,
  withdrawLoanRequestService,
  payoffActiveLoanService,
  requestRequiredDocService,
  submitUnderwriterDocService,
} from "../../services/myCreditServices";

const initialState = {
  loading: false,
  activeTransactions: null,
  activeTransactionsErr: null,
  pendingTransactions: null,
  pendingTransactionsErr: null,
  closedTransactions: null,
  closedTransactionsErr: null,
  repaymentHistory: null,
  repaymentHistoryErr: null,
  toppedUp: null,
  toppedUpErr: null,
  paidOff: null,
  paidOffErr: null,
  repayActiveLoan: null,
  repayActiveLoanErr: null,
  withdrawRequest: null,
  withdrawRequestErr: null,
  requestedDocs: null,
  requestedDocsErr: null,
  submitUnderwriter: null,
  submitUnderwriterErr: null,
};

export const activeTransactions = createAsyncThunk(
  "getCredit/activeTransactions",
  (payload, { rejectWithValue }) => {
    return getActiveTransactionsService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const pendingTransactions = createAsyncThunk(
  "getCredit/pendingTransactions",
  (payload, { rejectWithValue }) => {
    return getPendingTransactionsService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const closedTransactions = createAsyncThunk(
  "getCredit/closedTransactions",
  (payload, { rejectWithValue }) => {
    return getClosedTransactionsService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getLoanRepaymentHistory = createAsyncThunk(
  "getCredit/getLoanRepaymentHistory",
  (payload, { rejectWithValue }) => {
    return getLoanRepaymentHistoryService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const repayLoan = createAsyncThunk(
  "getCredit/repayLoan",
  (payload, { rejectWithValue }) => {
    return repayActiveLoanService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const payOffLoan = createAsyncThunk(
  "getCredit/payOffLoan",
  (payload, { rejectWithValue }) => {
    return payoffActiveLoanService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const withdrawLoanRequest = createAsyncThunk(
  "getCredit/withdrawLoanRequest",
  (payload, { rejectWithValue }) => {
    return withdrawLoanRequestService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const requestRequiredDoc = createAsyncThunk(
  "getCredit/requestRequiredDoc",
  (payload, { rejectWithValue }) => {
    return requestRequiredDocService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const submitUnderwriterDoc = createAsyncThunk(
  "getCredit/submitUnderwriterDoc",
  (payload, { rejectWithValue }) => {
    return submitUnderwriterDocService(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

const myCreditSlice = createSlice({
  name: "myCredit",
  initialState,
  reducers: {
    clearRepayLoanInfo(state) {
      state.repayActiveLoan = null;
      state.repayActiveLoanErr = null;
    },
    clearPayOff(state) {
      state.paidOff = null;
      state.paidOffErr = null;
    },
    clearWithdraw(state) {
      state.withdrawRequest = null;
      state.withdrawRequestErr = null;
    },
    clearSubmitUnderwriterDoc(state) {
      state.submitUnderwriter = null;
      state.submitUnderwriterErr = null;
    },
  },
  extraReducers: (builder) => {
    // fetch active loans
    builder.addCase(activeTransactions.pending, (state) => {
      state.loading = true;
    });
    // fetch product by target market success
    builder.addCase(activeTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.activeTransactions = action.payload;
      state.activeTransactionsErr = null;
    });
    // fetch product by target market fail
    builder.addCase(activeTransactions.rejected, (state, action) => {
      state.loading = false;
      state.activeTransactions = null;
      state.activeTransactionsErr = action.payload?.data;
    });
    // fetch pending loans
    builder.addCase(pendingTransactions.pending, (state) => {
      state.loading = true;
    });
    // fetch pending loans success
    builder.addCase(pendingTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.pendingTransactions = action.payload;
      state.pendingTransactionsErr = null;
    });
    // fetch product by target market fail
    builder.addCase(pendingTransactions.rejected, (state, action) => {
      state.loading = false;
      state.pendingTransactions = null;
      state.pendingTransactionsErr = action.payload?.data;
    });
    // fetch closed loans
    builder.addCase(closedTransactions.pending, (state) => {
      state.loading = true;
    });
    // fetch closed loans success
    builder.addCase(closedTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.closedTransactions = action.payload;
      state.closedTransactionsErr = null;
    });
    // fetch closed loans fail
    builder.addCase(closedTransactions.rejected, (state, action) => {
      state.loading = false;
      state.closedTransactions = null;
      state.closedTransactionsErr = action.payload?.data;
    });
    // get loan repayment history
    builder.addCase(getLoanRepaymentHistory.pending, (state) => {
      state.loading = true;
    });
    // get loan repayment history success
    builder.addCase(getLoanRepaymentHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.repaymentHistory = action.payload;
      state.repaymentHistoryErr = null;
    });
    // get loan repayment history fail
    builder.addCase(getLoanRepaymentHistory.rejected, (state, action) => {
      state.loading = false;
      state.repaymentHistory = null;
      state.repaymentHistoryErr = action.payload?.data;
    });
    // repay active loan
    builder.addCase(repayLoan.pending, (state) => {
      state.loading = true;
    });
    // repay active loan success
    builder.addCase(repayLoan.fulfilled, (state, action) => {
      state.loading = false;
      state.repayActiveLoan = action.payload;
      state.repayActiveLoanErr = null;
    });
    // repay active loan fail
    builder.addCase(repayLoan.rejected, (state, action) => {
      state.loading = false;
      state.repayActiveLoan = null;
      state.repayActiveLoanErr = action.payload?.data;
    });
    // pay off active loan
    builder.addCase(payOffLoan.pending, (state) => {
      state.loading = true;
    });
    // pay off active loan success
    builder.addCase(payOffLoan.fulfilled, (state, action) => {
      state.loading = false;
      state.paidOff = action.payload;
      state.paidOffErr = null;
    });
    // pay off active loan fail
    builder.addCase(payOffLoan.rejected, (state, action) => {
      state.loading = false;
      state.paidOff = null;
      state.paidOffErr = action.payload?.data;
    });
    // withdraw loan request
    builder.addCase(withdrawLoanRequest.pending, (state) => {
      state.loading = true;
    });
    // withdraw loan request success
    builder.addCase(withdrawLoanRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.withdrawRequest = action.payload;
      state.withdrawRequestErr = null;
    });
    // withdraw loan request fail
    builder.addCase(withdrawLoanRequest.rejected, (state, action) => {
      state.loading = false;
      state.withdrawRequest = null;
      state.withdrawRequestErr = action.payload?.data;
    });
    // request required documents
    builder.addCase(requestRequiredDoc.pending, (state) => {
      state.loading = true;
    });
    // request required documents success
    builder.addCase(requestRequiredDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.requestedDocs = action.payload;
      state.requestedDocsErr = null;
    });
    // request required documents fail
    builder.addCase(requestRequiredDoc.rejected, (state, action) => {
      state.loading = false;
      state.requestedDocs = null;
      state.requestedDocsErr = action.payload?.data;
    });
    // submit underwriter documents
    builder.addCase(submitUnderwriterDoc.pending, (state) => {
      state.loading = true;
    });
    // submit underwriter documents success
    builder.addCase(submitUnderwriterDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.submitUnderwriter = action.payload;
      state.submitUnderwriterErr = null;
    });
    // submit underwriter documents fail
    builder.addCase(submitUnderwriterDoc.rejected, (state, action) => {
      state.loading = false;
      state.submitUnderwriter = null;
      state.submitUnderwriterErr = action.payload?.data;
    });
  },
});

export default myCreditSlice.reducer;
export const {
  clearRepayLoanInfo,
  clearPayOff,
  clearWithdraw,
  clearSubmitUnderwriterDoc,
} = myCreditSlice.actions;
