import React, { useState, useEffect } from "react";
import { Nav } from "./style.js";
import { Typography } from "@mui/material";
import LogoAlternate from "../Logo";
import Avatar from "@mui/material/Avatar";
import user from "../Assets/images/user.jpg";
import BlockSpacer from "../BlockSpacer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Badge from "@mui/material/Badge";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/slices/authSlice.js";
import MobileTopbar from "../MoblieTobar/index.js";

function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const role = JSON.parse(localStorage.getItem("user"))?.role?.name ?
  JSON.parse(localStorage.getItem("user"))?.role?.name :
  JSON.parse(localStorage.getItem("user"))?.role;
  const name = JSON.parse(localStorage.getItem("user"))?.fullName;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {documents, users, companyDocs} = useSelector((state) => state.profile);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/");
    dispatch(userLogout());
  }

  if(matches) {
    return (
      <Nav>
        <div className="top">
          <div className="brand">
            <LogoAlternate />
          </div>
          <div className="content">
            <div>
              <h4>FAQ</h4>
            </div>
  
            <div className="userAvatar">
              <Badge
                // sx={{ marginTop: 4 }}
                badgeContent={4}
                color="secondary"
              >
                <NotificationsActiveIcon />
              </Badge>
  
              {/* <BlockSpacer mt={10} /> */}
              <Button
                sx={{ color: "#333333" }}
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disableElevation
                endIcon={<KeyboardArrowDownIcon />}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  {users?.role==="INDIVIDUAL_USER" ? 
                  `${users?.individualUser?.firstName} ${users?.individualUser?.lastName}`
                    : users?.company?.name}
                </p>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "down",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "down",
                  horizontal: "left",
                }}
                sx={{marginTop:"3.5rem"}}
              >
                {
                  role==="COMPANY" ? (
                    <Link to="/profile/company/info">
                      <MenuItem onClick={handleClose}>Company Profile</MenuItem>
                    </Link>
                  ) : (
                    <Link to="/profile/personal-info">
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                    </Link>
                  )
                }
                <MenuItem onClick={()=>{
                  handleClose();
                  logout();
                }}
                style={{marginRight: 35}}
                >Logout</MenuItem>
              </Menu>
              {/* <BlockSpacer mt={20} /> */}
              <Avatar
                // sx={{
                //   marginTop: "1rem",
                // }}
                alt={name}
                src={
                  users?.role==="INDIVIDUAL_USER"? 
                  documents?.data?.passportPhotographImage?.imageUrl : 
                  users?.role==="COMPANY" ? 
                  companyDocs?.contactPersonPhotographImage?.imageUrl :
                  null
                }
              />
            </div>
          </div>
        </div>
      </Nav>
    );
  } else {
    return (
      <MobileTopbar />
    )
  }
  
}

export default TopBar;
