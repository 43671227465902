import { MenuItem, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import React from "react";
import FormLabel from "../../Forms/Label";
import FormAligner from "../../Hocs/FormAligner";

function SelectField({
  name,
  method,
  changeAction,
  disabled,
  categoryName,
  label,
  items,
  select,
  asterisk = false,
  maxWidth,
  handleSelect,
  variant,
  color,
  required
}, props) {
  const SelectOne = () => {
    return <span>{method ? method : "Select One"}</span>;
  };
  return (
    <FormAligner>
      <FormLabel text={name} asterisk={asterisk} />

      <Select
        value={method}
        variant={variant}
        onChange={changeAction}
        inputProps={{ "aria-label": "Without label" }}
        fullWidth
        required={required??false}
        disabled={disabled}
        displayEmpty
        renderValue={SelectOne}
        style={{
          width: "100%",
          borderRadius: "8px",
          outline: "none",
          border: "1px solid #E0E0E0",
          ...(maxWidth && {
            maxWidth,
          }),
          color: color
        }}
        {...props}
      >
        {items?.map((option) => (
          <MenuItem
            // onClick={() => {
            //   setId(option.id);
            //   setName(option.name);
            // }}
            key={option.id ? option.id : option}
            value={option.name ? option.name : option}
          >
            {option.name ? option.name : option}
          </MenuItem>
        ))}
      </Select>
    </FormAligner>
  );
}

export default SelectField;
