import React, { useState } from "react";
import { Section } from "./style.js";
import { Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { AVAILABLE_USER_TYPES } from "../../../constants/index.js";

function ProfileSidebar() {
  const [currentLink, setCurrentLink] = useState(1);
  const { users } = useSelector(state => state.profile);
  return (
    <>
      <Section>
        <div className="top">
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "26px",
              letterSpacing: "-0.04em",
              ml: "2.1rem",
              color: "#222222",
            }}
          >
            Profile
          </Typography>
          <div className="toggle"></div>
          <div className="links">
            <ul>
              <NavLink to="/profile/personal-info" className="nav-list">
                <span>Personal information</span>
              </NavLink>
              <NavLink to="/profile/my-document" className="nav-list">
                <span>My documents</span>
              </NavLink>
              <NavLink to="/profile/bank-details" className="nav-list">
                <span>My Bank Details</span>
              </NavLink>
              {
                (users?.userType === AVAILABLE_USER_TYPES[1]) && (
                  <NavLink to="/profile/business-bank-details" className="nav-list">
                    <span>Business Bank Details</span>
                  </NavLink>
                )
              }
              <NavLink to="/profile/personal-detail/change-password" className="nav-list">
                <span>Change Password</span>
              </NavLink>
            </ul>
          </div>
        </div>
      </Section>
    </>
  );
}

export default ProfileSidebar;
