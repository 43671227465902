import HttpService from "./HttpService";

export const updateCreditUserTypeService = (formData) => {
  const http = new HttpService();

  const url = `/users/update-credit-user-type`;
  return http.postDataWithToken(formData, url);
}

export const switchPlatformService = () => {
  const http = new HttpService();

  const url = `/users/switch-platform`;
  return http.postDataWithToken({}, url);
}