export function numberValidity(inputString, checkName, checkValue, comparison) {
  // Check if the inputString is a valid non-empty string
  if (typeof inputString !== "string" || inputString.trim() === "") {
    return false;
  }

  // Use a regular expression to check if the string starts with a number and a subtraction symbol
  const regex = /^-?[0-9]+$/;
  let checkText = "";
  let checkBool = false;
  if(inputString&&checkName) {
    if(!(regex.test(inputString))) {
      checkText = "Enter a valid number"
      checkBool = true;
    } else if(comparison==="max") {
      if(inputString>comparison) {
        checkText = `Your ${checkName} cannot be greater than ₦${checkValue}`
        checkBool = true;
      }
    } else if(comparison==="min") {
      if(inputString<comparison) {
        checkText = `Your ${checkName} cannot be lesser than ₦${checkValue}`
        checkBool = true;
      }
    } else if(inputString < 0) {
      checkText = `Your ${checkName} cannot be lesser than 0`;
      checkBool = true;
    }
  }
  return {
    checkText: checkText,
    checkBool: checkBool
  };
}

export const formatInputWithCommas = (value) => {
  const cleanedValue = value?.replace(/[^0-9]/g,'');
  return Number(cleanedValue)?.toLocaleString();
}