import React from "react";

function FileuploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="48"
      fill="none"
      viewBox="0 0 51 48"
    >
      <path
        stroke="#E0E0E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M31.2 6.309H17.343C13.01 6.29 9.457 9.538 9.355 13.61v21.246c-.073 4.015 3.329 7.326 7.602 7.397.127 0 .256 0 .385-.004h16.642c4.31-.041 7.777-3.341 7.77-7.393V16.645L31.202 6.309z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#E0E0E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M30.652 6.285v5.697c0 2.78 2.394 5.035 5.352 5.043h5.738"
      ></path>
    </svg>
  );
}

export default FileuploadIcon;