import React, { useEffect, useState } from "react";
import { Section, choosePayment, PaymantBox, Title, id, it } from "./style.js";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import SummaryCard from "../../../components/Cards/PersonalCard";
import { Typography, Box, Paper, Button } from "@mui/material";
import MasterCardIcon from "../../../components/IconSvgs/MasterCardIcon.jsx";
import MoneyTransferIcon from "../../../components/IconSvgs/MoneyTransfer.jsx";
import bankicon from "../../../components/Assets/icons/bankicon.svg";
import LineBreak from "../../../components/Hocs/LineBreak/index.jsx";
import Buttons from "../../../components/Buttons";
import Checkbox from "@mui/material/Checkbox";
import paper from "../../../components/Assets/images/paper.png";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  testDebitCard,
  loanRepaymentSetup,
} from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";

const VehiclePay = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const [paySetup, setPaySetup] = useState("");
  const [refund, setRefund] = useState(false);
  const [CardName, setCardName] = useState("");
  const navigate = useNavigate();

  const {
    initializedLoan,
    repayment,
    loading,
    products,
    repaymentSetup,
    testCard,
  } = useSelector((state) => state.getCredit);

  const handleUpdate = (e) => {
    const { value } = e.target;
    if (value === "3" && !paySetup.includes("3")) {
      const cardName = new Date().getTime().toString();
      setCardName(cardName);
      dispatch(testDebitCard({ cardName: cardName }));
      setRefund(true);
    }
    const payList = paySetup?.split("%");
    if (payList.includes(value?.toString())) {
      let index = payList.indexOf(value?.toString());
      payList.splice(index, 1);
    } else {
      payList.push(value?.toString());
    }
    let pays = payList?.join("%");
    pays = pays?.startsWith("%") ? pays?.substring(1) : pays;
    pays = pays?.slice(-1) !== "%" ? pays?.concat("%") : pays;
    console.log("pas", pays);
    setPaySetup(payList?.join("%"));
  };

  useEffect(() => {
    if (repaymentSetup) {
      navigate("/vehicle-asset-terms");
    }
    // eslint-disable-next-line
  }, [repaymentSetup]);

  useEffect(() => {
    if (testCard) {
      setRefund(true);
    }
    // eslint-disable-next-line
  }, [testCard]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      loanRepaymentSetup({
        loanId: initializedLoan?.object?.body?.body?.loanId,
        loanRepaymentMethodsString: paySetup?.startsWith("%")
          ? paySetup?.length > 2 ? paySetup?.substring(1) : paySetup?.substring(1)?.concat("%")
          : paySetup?.concat("%"),
        transactionReference: testCard?.transactionReference,
        cardName: CardName,
      })
    );
  };
  return (
    <>
      <TopBar />
      <>
        <Section>
          <BlockSpacer mt={20} />
          <Typography variant="h5" component="h5" sx={choosePayment}>
          Loan Summary
        </Typography>
        <SummaryCard
          title={initializedLoan?.data?.body?.productName}
          currencyRequested={"Accessible Loan Amount"}
          currency={`₦${parseFloat(
            repayment?.object?.body?.body?.accessibleLoanAmount
          )}`}
          tenor={"Tenor"}
          days={`${repayment?.object?.body?.body?.tenor} Months`}
          interestRate={"Interest Rate"}
          interestRatePercentage={`${parseFloat(
            repayment?.object?.body?.body?.interestRate
          ).toFixed(4)}%`}
          interestPayable={"Interest Payable"}
          interestPayableCurrency={`₦${parseFloat(
            repayment?.object?.body?.body?.interestPayable
          )}`}
          FeeRate={"Fee Rate"}
          FeeRatePercentage={`${parseFloat(
            repayment?.object?.body?.body?.feeRate
          ).toFixed(4)}%`}
          feePayable={"Fee Payable"}
          feePayableCurrency={`₦${parseFloat(
            repayment?.object?.body?.body?.feePayable
          )}`}
          TotalRepayment={"Total Repayment"}
          totalPaymentAmount={`₦${parseFloat(
            repayment?.object?.body?.body?.totalRepayment
          )}`}
          TotalDebtWeight={"Total Debt Weight"}
          TotalDebtWeightPercentage={`₦${parseFloat(
            repayment?.object?.body?.body?.totalDebtWeight
          )}`}
          // LoanDueDate={"Loan Due Date"}
          // dueDate={"12/10/2022"}
          monthlyRepayments={
            repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
          }
        />

          <BlockSpacer mt={120} />

          <Typography sx={choosePayment}>Choose Payment Type</Typography>

          <Box sx={PaymantBox}>
          {products && (
            <Box sx={PaymantBox}>
              {initializedLoan?.data?.body?.repaymentTypeMgts?.map((item) => (
                <div key={item?.id}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "170px",
                        marginTop: ".5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      {item?.repaymentName === "Debit Cards-Banks" ? (
                        <MasterCardIcon />
                      ) : (
                        <img src={bankicon} alt="bank" width={"25px"} />
                      )}
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          letterSpacing: "-0.03em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.repaymentName}
                      </Typography>
                    </Box>

                    <Checkbox
                      size={"small"}
                      sx={{
                        color: "#111E6C",
                        "&.Mui-checked": {
                          color: "#111E6C",
                        },
                      }}
                      value={item?.repaymentId}
                      checked={paySetup
                        ?.split("%")
                        ?.includes(item?.repaymentId?.toString())}
                      onChange={handleUpdate}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                  <LineBreak />
                </div>
              ))}
            </Box>
          )}
        </Box>

          <BlockSpacer mt={150} />
          <Paper
            elevation={0.5}
            sx={{
              height: { xs: "15%", lg: "10%" },
              width: { xs: "100%", sm: "92%", lg: "87%" },
              margin: "1rem",
              marginLeft: { lg: "-1rem", xs: "auto" },
              marginRight: { xs: "auto" },
              maxwidth: "100%",
              boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
                padding: "1rem",
                maxWidth: "100%",
              }}
            >
              <Link to="/vehicle/loan/summary">
                <Button
                  sx={{
                    background: "#F2F2F2",
                    width: { lg: "328px", xs: "300px" },
                    maxWidth: "100%",
                    height: "54px",
                    marginLeft: { xs: "2rem" },
                    color: "#111E6C",
                    marginBottom: "1rem",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Back
                </Button>
              </Link>
                <Buttons
                  color="primary"
                  width="300px"
                  sx={{ maxWidth: "100%", marginLeft: { xs: "2rem" } }}
                  marginLeft="2rem"
                  // onClick={onClick}
                  // disabled={!paySetup.includes("3") && refund === false}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <ClipLoader color={"#fff"} loading={loading} size={20} />
                  ) : (
                    "Proceed"
                  )}
                </Buttons>
            </Box>
          </Paper>
        </Section>
      </>
    </>
  );
};

export default VehiclePay;
