import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { textheading, smallText } from "./style";
import BlockSpacer from "../../../BlockSpacer";
import SelectField from "../../../Core/SelectField";
import FormAligner from "../../../Hocs/FormAligner";
import Buttons from "../../../Buttons";
import FormLabel from "../../Label";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../../redux/slices/profileSlice";
import useScreenSize from "../../../ScreenSize";


function NextOfKinDetailForm({ 
  profileForm, 
  handleForm,
  handleSendOtp,
  setCurrentEdit,
  showEdit,
  removeEdit,
  handleSubmit
}) {
  const dispatch = useDispatch();
  const [defaultState, setDefaultState] = useState(profileForm?.individualUser?.nokDetail?.state);
  const { width } = useScreenSize();

  useEffect(() => {
    dispatch(getStates(1));
  },[])

  const { states } = useSelector(state => state.profile);

  const statesOptions = states ? states?.map((item) => (item?.name)) : []

  const handleStateChange = (e) => {
    setDefaultState(e.target.value)
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        nokDetail: {
          ...profileForm?.individualUser?.nokDetail,
          state: e.target.value
        }
      }
    });
  };

  const handleUserInput = (e) => {
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        nokDetail: {
          ...profileForm?.individualUser?.nokDetail,
          [e.target.name]: e.target.value
        }
      }
    })
  };

  const nokDet = profileForm?.individualUser?.nokDetail;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography sx={textheading}>Next Of Kin Details</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            {!(showEdit.includes(3)) ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      handleSendOtp();
                      setCurrentEdit(3);
                    }}
                  >
                    Edit
                  </Buttons>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="tertiary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      removeEdit(3);
                      handleSubmit();
                    }}
                  >
                    Save
                  </Buttons>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
      <BlockSpacer />

      <BlockSpacer />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Next of Kin First Name"} asterisk />
            <TextField
              placeholder="First Name"
              type="text"
              required
              disabled={!(showEdit.includes(3))}
              name="firstName"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.firstName}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Next of Kin Last Name"} asterisk />
            <TextField
              placeholder="Last Name"
              type="text"
              required
              disabled={!(showEdit.includes(3))}
              name="lastName"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.lastName}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Next of Kin Email"} asterisk />
            <TextField
              placeholder="Email"
              type="email"
              required
              disabled={!(showEdit.includes(3))}
              name="email"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.email}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer />
      <Grid conatainer spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormLabel text={"Next of Kin Address"} asterisk />
          <FormAligner>
            <TextField
              placeholder="Enter Next of Kin Address"
              type="text"
              required
              disabled={!(showEdit.includes(3))}
              name="address"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.address}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Next of Kin city"} asterisk />
            <TextField
              placeholder="City"
              type="text"
              required
              disabled={!(showEdit.includes(3))}
              name="city"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.city}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <SelectField
              name={"Next of Kin State"}
              method={defaultState}
              disabled={!(showEdit.includes(3))}
              changeAction={handleStateChange}
              placeholder="Select State"
              items={statesOptions}
              asterisk
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Relationship"} asterisk />
            <TextField
              placeholder="Relationship"
              type="text"
              required
              disabled={!(showEdit.includes(3))}
              name="relationship"
              onChange={(e)=>handleUserInput(e)}
              value={nokDet?.relationship}
            />
          </FormAligner>
        </Grid>
      </Grid>
    </>
  );
}

export default NextOfKinDetailForm;
