import React, { useState, useEffect } from "react";
import BlockSpacer from "../../../../../components/BlockSpacer";
import Buttons from "../../../../../components/Buttons";
import { Box, Typography, Grid, TextField, Tooltip } from "@mui/material";
import FormAligner from "../../../../../components/Hocs/FormAligner";
import { LIST_0F_BANK } from "../../../../../constants";
import SelectField from "../../../../../components/Core/SelectField";
import PersonalDetalLayout from "../../../../../components/PersonalDetailLayout";
// import SuccessModal from '../../../../../components/Modals/SuccessModal';
import OtpVerificationModal from "../../../../../components/Modals/IndividualProfileModal/OtpVerificationModal";
import FormLabel from "../../../../../components/Forms/Label";
import SuccessModal from "../../../../../components/Modals/SuccessModal";
import SuccessMessage from "../../../../../components/Cards/SuccessMessages";
import FooterCheckout from "../../../../../components/FooterCheckout";
import { Section, container, headingText, smallText } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  verifyAccountNo,
  updateBankdetails,
} from "../../../../../redux/slices/updateProfileSlice";
import {
  sendOtp,
  closeOtpModal,
  validateOtp,
} from "../../../../../redux/slices/profileSlice";
import {
  getBanks,
  getBankDetails,
} from "../../../../../redux/slices/profileSlice";
import OtpVerificationEmailModal from "../../../../../components/Modals/OtpVerificationEmailModal";
import TopBar from "../../../../../components/TopBar";
import useScreenSize from "../../../../../components/ScreenSize";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

function MyBankDetail(props) {
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [showEdit, setShowEdit] = useState(true);
  const handleOpenSuccessMesage = () => setOpenSuccess(true);
  const [token, setToken] = useState("");
  const handleSuceesMessageClose = () => setOpenSuccess(false);

  useEffect(() => {
    dispatch(getBanks());
    dispatch(getBankDetails());
  }, []);

  const {
    banks,
    bankDetails,
    showEmailOtpModal,
    otp,
    otpError,
    validateEmailOtp,
  } = useSelector((state) => state.profile);
  const { accountDetail, loading, bankUpdateError, verifyloading } = useSelector(
    (state) => state.updateProfile
  );

  const bankOptions = banks ? banks?.data?.map((item) => item.name) : [];

  const data = {
    bankName: "",
    accountNumber: "",
    accountName: "",
    phoneNumber: "",
  };

  useEffect(() => {
    if (accountDetail && !showEdit) {
      setFormData({
        ...formData,
        accountName: accountDetail?.data?.account_name,
      });
    }
  }, [accountDetail]);

  useEffect(() => {
    if (bankDetails) {
      setBankNames(bankDetails?.bank?.name);
      setFormData({
        accountNumber: bankDetails?.accountNumber,
        bankName: bankDetails?.bank?.code,
        accountName: bankDetails?.accountName,
        phoneNumber: bankDetails?.phoneNumber,
      });
    }
  }, [bankDetails]);

  //OTP
  const [formData, setFormData] = useState(data);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [bankNames, setBankNames] = useState("");
  const { width } = useScreenSize();

  const handleOpen = () => {
    setOpen(true);
    setOpenSuccess(false);
  };

  const toggleEdit = (e) => {
    setShowEdit(!showEdit);
  };

  const createOtp = (otp) => {
    setToken(otp);
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(sendOtp());
  };

  const handleOTPModalClose = () => {
    dispatch(closeOtpModal());
  };

  const handleEmailOtpSubmit = async () => {
    dispatch(validateOtp(token));
  };

  useEffect(() => {
    if (validateEmailOtp) {
      handleOTPModalClose();
      toggleEdit();
    }
  }, [validateEmailOtp]);

  const handleBankNames = (e) => {
    setBankNames(e.target.value);
    setFormData({
      ...formData,
      bankName: banks?.data?.find((item) => e.target.value === item.name)?.code,
    });
  };

  const handleBankVerify = (e) => {
    e.preventDefault();
    const data = {
      accountNumber: formData?.accountNumber || bankDetails?.accountNumber,
      bankCode: formData?.bankName || bankDetails?.bank?.code,
    };
    dispatch(verifyAccountNo(data));
  };

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // OTP Success
  //   const [openSuccess, setOpenSuccess] = React.useState(false);
  //   const handleSuccessClose = () => {
  //     setOpenSuccess(false);
  //   };

  const handleSuccessOpen = () => {
    setOpenSuccess(true);
    setOpen(false);
  };

  const handleUpdateDetails = () => {
    const { accountName, accountNumber, phoneNumber } = formData;
    let data = {
      accountName,
      accountNumber,
      bankCode: formData.bankName,
      phoneNumber,
    };
    dispatch(updateBankdetails(data));
  };

  return (
    <>
      <TopBar />
      <>
        <ToastContainer />
        <Section>
          <PersonalDetalLayout>
            <Box sx={container}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  container
                  sx={{
                    mb: 3,
                    mt: 3,
                    display: { md: "none", lg: "none", xs: "flex", sm: "flex" },
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box>
                    <Link to="/profile">
                      <ArrowBackIosNew />
                    </Link>
                  </Box>
                  <Box>
                    <Typography
                      sx={headingText}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      My Bank Details
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={headingText}
                  variant={"h6"}
                  style={{ display: width <= 700 && "none" }}
                >
                  My Bank Details
                </Typography>
                <Box>
                  {showEdit ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { sm: 2, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                        <Buttons
                          color="primary"
                          width={width <= 700 ? "60px" : "72px"}
                          fontSize={width <= 700 && "14px"}
                          onClick={handleSendOtp}
                        >
                          Edit
                        </Buttons>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box sx={{ ml: { sm: 2, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                        <Buttons
                          color="primary"
                          width={width <= 700 ? "60px" : "72px"}
                          fontSize={width <= 700 && "14px"}
                          onClick={toggleEdit}
                        >
                          Cancel
                        </Buttons>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
              <BlockSpacer />
              <Grid container rowSpacing={{ sm: 0, lg: 2 }} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectField
                    name={"Select Bank"}
                    asterisk
                    method={bankNames}
                    disabled={showEdit}
                    changeAction={(e) => handleBankNames(e)}
                    items={bankOptions}
                    maxWidth={"785px"}
                  />
                  {bankUpdateError && (
                    <p style={{ color: "#FF0000", fontSize: 14 }}>
                      {bankUpdateError}{" "}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <FormLabel text={"Phone Number"} />
                    <TextField
                      placeholder=""
                      type="tel"
                      // required
                      disabled={showEdit}
                      value={formData?.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInput}
                    />
                  </FormAligner>
                  <Typography sx={smallText}>
                    Enter Phone number registered with selected bank account
                  </Typography>
                </Grid>
              </Grid>
              <BlockSpacer />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"Account Number"} asterisk />
                    <TextField
                      placeholder=""
                      type="text"
                      required
                      disabled={showEdit}
                      name="accountNumber"
                      value={formData?.accountNumber}
                      onChange={handleInput}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  {width > 700 && <BlockSpacer mt={31} />}
                  <Buttons
                    color="primary"
                    onClick={handleBankVerify}
                    fontSize={width <= 700 && "14px"}
                    disabled={showEdit}
                  >
                    {
                      verifyloading ? (
                        <Box sx={{margin:"0 1rem"}} >
                          <ClipLoader color={"#fff"} loading={verifyloading} size={16} />
                        </Box>
                      ) : "Verify"
                    }
                  </Buttons>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <FormLabel text={"Acount Name"} />
                    <TextField
                      placeholder=""
                      type="text"
                      required
                      disabled={showEdit}
                      value={formData?.accountName}
                      name="accountName"
                      InputProps={{ readOnly: true }}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
            </Box>
            <BlockSpacer mt={width > 700 ? 200 : 60} />
            <FooterCheckout
              proceedInfoButton={"Save"}
              handleClose={() => handleUpdateDetails()}
              isLoading={loading}
              disabled={showEdit}
            />
          </PersonalDetalLayout>

          {openSuccess && (
            <SuccessModal
              open={SuccessModal}
              handleClose={handleSuceesMessageClose}
            />
          )}

          {showEmailOtpModal && (
            <OtpVerificationEmailModal
              open={showEmailOtpModal}
              handleClose={handleOTPModalClose}
              handleOtpChange={(otp) => createOtp(otp)}
              otp={token}
              resendOtp={() => {
                dispatch(sendOtp());
              }}
              otpToken={otp?.data}
              handleSuccessOpen={() => handleEmailOtpSubmit()}
            />
          )}
          {openSuccess && (
            <SuccessModal
              open={openSuccess}
              handleClose={handleSuceesMessageClose}
            />
          )}
        </Section>
      </>
    </>
  );
}

export default MyBankDetail;
