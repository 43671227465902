import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CardContent from "@material-ui/core/CardContent";
import Buttons from "../../../../components/Buttons/index.jsx";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import BlockSpacer from "../../../../components/BlockSpacer";
import { Link, useParams } from "react-router-dom";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import { paperContainer, left, right, Account, Number, Section } from "./style";
import TopBar from "../../../../components/MyCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { getLoanRepaymentHistory } from "../../../../redux/slices/myCreditSlice.js";
import { getBankDetails } from "../../../../redux/slices/profileSlice.js";

function ViewHistory() {
  const dispatch = useDispatch();
  const [showTopUp, setshowTopUp] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const { id } = useParams();
  const handleToggle = () => {
    setshowTopUp(true);
    setShowButton(false);
    console.log("toggle");
  };
  const { activeTransactions: allActiveTransactions, repaymentHistory } =
    useSelector((state) => state.myCredit);
  const { bankDetails } = useSelector((state) => state.profile);
  const closeToggle = () => {
    setshowTopUp(false);
    setShowButton(true);
    console.log("untoggle");
  };
  const selectedLoan = allActiveTransactions?.data?.content?.find(
    (loan) => loan?.id === parseInt(id)
  );

  useEffect(() => {
    dispatch(
      getLoanRepaymentHistory({ transactionId: selectedLoan?.transactionId })
    );
    dispatch(getBankDetails());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container>
            <Grid items xs={12} sm={12} lg={12}>
              <Box>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "0", lg: "-6px" },
                    width: { xs: "300px", lg: "300.41px" },
                    maxWidth: "100%",
                    height: { xs: "250px", lg: "242.56px" },
                    borderRadius: "8.94247px",
                    position: "relative",
                    paperContainer,
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                  }}
                >
                  {showTopUp && (
                    <List
                      sx={{
                        width: { xs: "", lg: "80%" },
                        height: "60%",
                        maxWidth: 106,
                        bgcolor: "white",
                        borderRadius: "5px",
                        zIndex: { xs: 5, lg: 1 },
                        display: { xs: "inline-block", lg: "block" },
                        boxShadow: " 0px 4px 10px rgba(199, 199, 199, 0.25)",
                        position: "absolute",
                        marginLeft: "12rem",
                        // top: "-50px",
                        marginTop: "1.5rem",
                        opacity: 40,
                      }}
                    >
                      <ListItem alignItems="flex-start">
                        <ListItemText>
                          <Link to="/repayment">
                            <Button
                              sx={{
                                marginTop: "-2.5rem",
                                fontWeight: "500",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                textTransform: "capitalize",
                                color: "#333333",
                              }}
                            >
                              Repay
                            </Button>
                          </Link>
                        </ListItemText>
                      </ListItem>
                      <Divider
                        variant="inset"
                        component="li"
                        style={{
                          marginTop: "-1.5rem",
                          width: "90%",
                          marginLeft: ".5rem",
                          position: "absolute",
                        }}
                      />
                      <ListItem alignItems="flex-start">
                        <ListItemText>
                          <Link to="/top-up">
                            <Button
                              sx={{
                                marginTop: "-4rem",
                                fontWeight: "500",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                textTransform: "capitalize",
                                color: "#333333",
                              }}
                            >
                              Top-up
                            </Button>
                          </Link>
                        </ListItemText>
                      </ListItem>
                      <Divider
                        variant="inset"
                        component="li"
                        style={{
                          width: "90%",
                          marginLeft: ".5rem",
                          position: "absolute",
                          marginTop: "-2.5rem",
                        }}
                      />
                      <ListItem alignItems="flex-start">
                        <ListItemText>
                          <Link to="/extension">
                            <Button
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                textTransform: "capitalize",
                                color: "#333333",
                                marginTop: "-6rem",
                              }}
                            >
                              Extend
                            </Button>
                          </Link>
                        </ListItemText>
                      </ListItem>

                      <Divider
                        variant="inset"
                        component="li"
                        style={{
                          marginTop: "-3.5rem",
                          width: "90%",
                          marginLeft: ".5rem",
                          position: "absolute",
                        }}
                      />
                      <ListItem alignItems="flex-start">
                        <ListItemText>
                          <Link to="/pay-off">
                            <Button
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                textTransform: "capitalize",
                                color: "#333333",
                                marginTop: "-8rem",
                              }}
                            >
                              Pay-off
                            </Button>
                          </Link>
                        </ListItemText>
                      </ListItem>
                    </List>
                  )}
                  <Box sx={left}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      <Typography sx={Account}>Account Number</Typography>
                    </Box>
                    <Typography sx={Number}>
                      {bankDetails?.accountNumber}
                    </Typography>
                    <Typography
                      sx={Account}
                      style={{ marginTop: "-5px", padding: "1rem" }}
                    >
                      Tenor
                    </Typography>
                    <Typography
                      sx={Number}
                      style={{ marginTop: "-17px", padding: "1rem" }}
                    >
                      {selectedLoan?.loanSummary?.tenor}{" "}
                      {selectedLoan?.product?.feePaymentType === "WEEKLY"
                        ? "Day(s)"
                        : "Month(s)"}
                    </Typography>
                    <Typography
                      sx={Account}
                      style={{ marginTop: "-15px", padding: "1rem" }}
                    >
                      Reason For Loan
                    </Typography>
                    <Typography
                      sx={Number}
                      style={{
                        marginTop: "-7px",
                        padding: "1rem",
                        width: "100%",
                      }}
                    >
                      {selectedLoan?.loanReasonMgt
                        ? selectedLoan?.loanReasonMgt?.loanReason
                        : selectedLoan?.userLoanReason}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid items xs={12} sm={12} lg={12}>
              <Box style={{ position: "relative" }}>
                <Paper
                  elevation={0.5}
                  sx={{
                    mt: { xs: "20px", lg: "20px" },
                    width: { xs: "300px", lg: "300.41px" },
                    maxWidth: "100%",
                    height: { xs: "489px", lg: "489px" },
                    borderRadius: "8.94247px",
                    paperContainer,
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                  }}
                  onClick={closeToggle}
                >
                  <Box sx={left} style={{ position: "absolute" }}>
                    <Typography
                      sx={Number}
                      style={{ fontSize: "15.6493px", marginTop: "5px" }}
                    >
                      {selectedLoan?.product?.productName} Summary
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: { xs: "center", lg: "space-between" },
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={Account}>Request Loan Amount</Typography>
                      <Typography sx={Account} style={{ marginLeft: "4rem" }}>
                        Tenor
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Number}
                        style={{ mt: { xs: "", lg: "" } }}
                      >
                        &#8358;
                        {parseFloat(
                          selectedLoan?.loanSummary?.requestedLoanAmount
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography sx={Number} style={{}}>
                        {selectedLoan?.loanSummary?.tenor}{" "}
                        {selectedLoan?.product?.feePaymentType === "WEEKLY"
                          ? "Day(s)"
                          : "Month(s)"}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "60%", lg: "80%" },
                        top: "-0.3rem",
                      }}
                    ></Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={Account}
                        style={{ mt: { xs: "0", lg: "200px" } }}
                      >
                        Request date
                      </Typography>
                      <Typography
                        sx={Account}
                        style={{
                          top: "100px",
                        }}
                      >
                        Total Repayment made
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={Number}>
                        {selectedLoan?.loanSummary?.createdAt
                          ?.slice(0, 10)
                          ?.replaceAll("-", "/")}
                      </Typography>
                      <Typography sx={Number} style={{ top: "150px" }}>
                        {repaymentHistory?.data?.content?.length < 1 &&
                          "No repayment made"}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        border: " 0.894247px dashed #E0E0E0",
                        position: "relative",
                        width: { xs: "60%", lg: "80%" },
                        top: "-0.5rem",
                      }}
                    ></Box>
                    {repaymentHistory?.data?.content?.map((loan, id) => (
                      <Box key={id}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography sx={Account}>
                            Individual Payment
                          </Typography>
                          <Typography sx={Account}>Date Paid</Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography sx={Number}>
                            &#8358;{loan?.amount?.toLocaleString("en-US")}{" "}
                          </Typography>
                          <Typography sx={Number}>
                            {loan?.createdAt
                              ?.slice(0, 10)
                              ?.replaceAll("-", "/")}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />

        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          }}
        >
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/active-transaction">
              <Button
                sx={{
                  background: "#F2F2F2",
                  width: "328px",
                  height: "54px",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "#111E6C",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
          </Box>
        </Paper>
      </main>
    </>
  );
}

export default ViewHistory;
