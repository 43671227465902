import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetNotificationsQuery = () => {
  const getNotifications = async () => {
    const request = await axiosInstance.get(`/notification`, {
      params: {
        platform: "CREDIT",
      },
    });

    const response = request.data;
    return response;
  };
  const query = useQuery(["NOTIFICATIONS"], getNotifications);
  return query;
};

export default useGetNotificationsQuery;
