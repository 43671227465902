import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { textheading, smallText } from "./style";
import BlockSpacer from "../../../BlockSpacer";
import SelectField from "../../../Core/SelectField";
import FormAligner from "../../../Hocs/FormAligner";
import Buttons from "../../../Buttons";
import FormLabel from "../../Label";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../../redux/slices/profileSlice";
import useScreenSize from "../../../ScreenSize";

function SchoolDetailsForm({ 
  profileForm, 
  handleForm,
  handleSendOtp,
  setCurrentEdit,
  showEdit,
  removeEdit,
  handleSubmit
}) {
  const dispatch = useDispatch();
  const [defaultState, setDefaultState] = useState(profileForm?.individualUser?.occupation?.state);
  const handleUserInput = (e) => {
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        occupation: {
          ...profileForm?.individualUser?.occupation,
          [e.target.name]: e.target.value
        }
      }
    })
  };
  const { width } = useScreenSize();

  React.useEffect(() => {
    dispatch(getStates(parseInt(1)));
  }, []);

  const { states } = useSelector(state => state.profile)


  const statesOptions = states ? states?.map((item) => (item?.name)) : []

  //State Changes
  const handleStateChange = (event) => {
    setDefaultState(event.target.value)
    handleForm({
      ...profileForm,
      individualUser: {
        ...profileForm?.individualUser,
        occupation: {
          ...profileForm?.individualUser?.occupation,
          state: event.target.value
        }
      }
    });
  };

  const occupationDet = profileForm?.individualUser?.occupation;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography sx={textheading}>School Details</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            {!(showEdit.includes(5)) ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      handleSendOtp();
                      setCurrentEdit(5);
                    }}
                  >
                    Edit
                  </Buttons>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      removeEdit(5);
                      handleSubmit();
                    }}
                  >
                    Save
                  </Buttons>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
      <BlockSpacer />

      <BlockSpacer />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"School Name"} asterisk />
            <TextField
              placeholder="School of the People"
              type="text"
              required
              disabled={!(showEdit.includes(5))}
              name="schoolName"
              value={occupationDet?.schoolName}
              onChange={(e)=>handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"City"} asterisk />
            <TextField
              placeholder="Public"
              type="text"
              required
              disabled={!(showEdit.includes(5))}
              name="city"
              value={occupationDet?.city}
              onChange={(e)=>handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <SelectField
              name={"State"}
              method={defaultState}
              disabled={!(showEdit.includes(5))}
              changeAction={handleStateChange}
              placeholder="Select State"
              items={statesOptions}
              asterisk
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer />
      <Grid conatainer spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormLabel text={"School Address"} asterisk />
          <FormAligner>
            <TextField
              placeholder="Address"
              type="text"
              required
              disabled={!(showEdit.includes(5))}
              name="schoolAddress"
              value={occupationDet?.schoolAddress}
              onChange={(e)=>handleUserInput(e)}
            />
          </FormAligner>
        </Grid>
      </Grid>
    </>
  );
}

export default SchoolDetailsForm;
