import React from "react";
import style from "./style.module.css";
import Box from "@mui/material/Box";
import SuccessMessage from "../../../components/Cards/SuccessMessages";
import EmailIcon from "../../../components/IconSvgs/EmailIcon";

function SignupEmailVerification() {
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <Box
          sx={{
            minWidth: 400,
            maxWidth: 792,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 30px rgba(225, 234, 254, 0.62)",
            borderRadius: "20px",
          }}
        >
          <SuccessMessage
            title="Congratulations!"
            subTextStart={"Your email has been verified successfully"}
            icon={<EmailIcon />}
            route={"/user/kyc"}
          />
        </Box>
      </div>
    </div>
  );
}

export default SignupEmailVerification;
