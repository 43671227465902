import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import style from "./style.module.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function SignupConditionalCheckBox({ title, defaultChecked, modified, handleCheck, check }) {
  
  let itemCheck = typeof check==="undefined" ? false : check;
  return (
    <div className={style.container}>
      {defaultChecked ? (
        <Checkbox
          {...label}
          checked={itemCheck}
          value={itemCheck}
          onChange={handleCheck}
          style={{
            color: "#1C448D",
          }}
        />
      ) : (
        <Checkbox
        {...label}
          checked={itemCheck}
          value={itemCheck}
          onChange={handleCheck}
          style={{
            color: "#1C448D",
          }}
        />
      )}

      <div className={style.forgotPassword}>{title}</div>
      {modified && (
        <div className={style.forgotPassword}>
          I agree to the <span className={style.coloredText}>Terms</span> and{" "}
          <span className={style.coloredText}>Privacy Policy</span>
        </div>
      )}
    </div>
  );
}

export default SignupConditionalCheckBox;
