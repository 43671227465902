import styled from "@emotion/styled";

export const Section = styled.section`
position: fixed;
left: 0;
max-width: 225px;
height: 100vh;
background: #FFFFFF;
box-shadow: 4px 0px 4px rgba(196, 204, 221, 0.12);
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 2rem 0;
gap: 0.4rem;

.top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 30%;
} 

.toggle {
    display: none;
}

.brand {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.links {
    display: flex;
    justify-content: center;
    //  margin-left: 4rem;

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        li {
            padding: 0.6 1rem
            border-radius: 0.6rem;

            a{
                display: flex;
                gap: 1rem;
                text-decoration: none;
                color: #242424;
                font-family: Montserrat;
                font-weight: 500;
                // font-size: 15;
                letter-spacing: -3%;
                line-height: 18.29px;

            }
        }

        .active {
            background: #E7E7E7;
            height: 42px;
        //    width: 100%;
          border-radius: 5px;
         


        }

    }
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 200px;
    padding: 0rem 0 0rem 10px;
    margin: 1rem 0 1rem 26px;

    // &:hover{
    //     background: #E7E7E7;
    // }
}

.feedback-nav-item {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 200px;
    padding: 0 0 0 10px;
    margin: 0 0 0 26px;
}

`;
