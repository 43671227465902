import React, { useState,useEffect } from "react";
import useSreenSize from "../../../../components/ScreenSize";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";
import style from "./style.module.css";
import { getUsers } from "../../../../redux/slices/profileSlice";
import TopBar from "../../../../components/TopBar";
import { COMPANY_USER_TYPES, AVAILABLE_USER_TYPES } from "../../../../constants";

const individualUserRoute = [
  {
    name: "Personal information",
    link: "/profile/personal-info"
  },
  {
    name: "My documents",
    link: "/profile/my-document"
  },
  {
    name: "My Bank Details",
    link: "/profile/bank-details"
  },
  {
    name: "Business Bank Details",
    link: "/profile/business-bank-details"
  },
  {
    name: "Change Password",
    link: "/profile/personal-detail/change-password"
  },
];

const companyRoutes = [
  {
    name: "Company information",
    link: "/profile/company/info"
  },
  {
    name: "Director Details",
    link: "/profile/company/director"
  },
  {
    name: "Company Documents",
    link: "/profile/company/business-document"
  },
  // {
  //   name: "Bank Details",
  //   link: "/profile/company/bank-details"
  // },
  {
    name: "Business Bank Details",
    link: "/profile/company/business-bank-details"
  },
  {
    name: "Change Password",
    link: "/profile/company/change-password"
  },
];

const ProfilePage = () => {
  const { width } = useSreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defaultUserMethod, setDefaultUserMethod] = useState();

  const { documents, users, companyDocs } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch])

  useEffect(() => {
    if (users?.userType === "SOLE_PROPRIETOR_REGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[0]);
    } else if (users?.userType === "SOLE_PROPRIETOR_UNREGISTERED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[1]);
    } else if (users?.userType === "CORPORATE_LISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[2]);
    } else if (users?.userType === "CORPORATE_UNLISTED") {
      setDefaultUserMethod(COMPANY_USER_TYPES[3]);
    }
  }, [users]);

  useEffect(() => {
    if(width>700) {
      if(users?.role === "INDIVIDUAL_USER") {
        navigate("/profile/personal-info")
      } else {
        navigate("/profile/company/info")
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[users, width])
  
  return (
    <>
      <TopBar />
      <div className={style.container}>
        <Box >
          <Box
            sx={{
              display: "flex",
              gap: 2
            }}
          >
            <Box>
              <Avatar 
                sx={{width:"48px",height:"48px"}}
                src={
                  users?.role === "INDIVIDUAL_USER"
                  ? documents?.data?.passportPhotographImage?.imageUrl
                  : users?.role === "COMPANY"
                  ? companyDocs?.contactPersonPhotographImage?.imageUrl
                  : null
                }
                alt={
                  users?.role === "INDIVIDUAL_USER"
                  ? `${users?.individualUser?.firstName} ${users?.individualUser?.lastName}`
                  : users?.company?.name
                }
              />
            </Box>
            <Box sx={{pt:1}}>
              <Typography sx={{fontWeight:600}}>
                {
                  users?.role === "INDIVIDUAL_USER"
                  ? `${users?.individualUser?.firstName} ${users?.individualUser?.lastName}`
                  : users?.company?.name
                }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{paddingTop:2}} >
          <List>
            {
              users?.role === "INDIVIDUAL_USER" ? individualUserRoute.map((route, id) => (
                <ListItem
                  key={id}
                  disablePadding
                  component={Link}
                  to={route.link}
                  style={{
                    display: (users?.userType!==AVAILABLE_USER_TYPES[1] &&
                      route.name==="Business Bank Details") && "none"
                  }}
                  disableGutters
                >
                  <ListItemButton>
                    <ListItemText primary={route.name} />
                  </ListItemButton>
                </ListItem>
              )) : companyRoutes.map((route, id) => (
                <ListItem
                  key={id}
                  disablePadding
                  component={Link}
                  to={route.link}
                  disableGutters
                  style={{
                    display: (!([COMPANY_USER_TYPES[0],COMPANY_USER_TYPES[1]].includes(
                      defaultUserMethod)) &&
                      route.name==="Business Bank Details") && "none"
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={route.name} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </div>
    </>
  );
};

export default ProfilePage;
