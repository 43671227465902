import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Backdrop } from "@mui/material";

export const style = {
  position: "absolute",
  top: "100%",
  marginTop: "6rem",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  maxWidth: "100%",
  height: "auto",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "20px",
  p: 4,
};

export default function AddDirectorModal({
  open,
  handleClose,
  children,
}) {
  return (
    <div>
      <Box
        sx={{
          maxWidth: "100%",
          padding: "1rem",
          overflowY: "auto"
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          sx={{overflowY:"scroll"}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>{children}</Box>
        </Modal>
      </Box>
    </div>
  );
}
