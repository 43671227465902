import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import Buttons from "../../../components/Buttons";
import BlockSpacer from "../../../components/BlockSpacer";
import { Section, contentTitle } from "./style";
import SummaryCard from "../../../components/Cards/PersonalCard";
import OfferSuccessModal from "../../../components/Modals/OfferSuccessPersonal/index";
import LoanValueModal from "../../../components/Modals/CollateralLoan/SourceAccount(Pe)/index";
import NoteModal from "../../../components/Modals/NoteModal(pe)/index";
import ApproveBankModal from "../../../components/Modals/CollateralLoan/ApproveBank(pe)";
import TopBar from "../../../components/GetCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptLoanOffer,
  cancelInitialization,
  cancelRepayments,
} from "../../../redux/slices/getCreditSlice";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function PersonalLoanSummary(props) {
  //Open Accept Offer
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOfferOpen = () => setOpen(true);
  const handleOfferClose = () => setOpen(false);
  // Open Source Account
  const dispatch = useDispatch();

  const { initializedLoan, repayment, acceptLoan, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (acceptLoan) {
      handleOfferOpen();
    }
  }, [acceptLoan]);

  const [openSource, setOpenSource] = useState(false);
  const handleSource = () => {
    setOpenSource(true);
    setOpen(false);
  };
  const handleSourceClose = () => {
    setOpenSource(false);
  };
  //Open Note on Ticket ID

  const [openNote, setOpenNote] = useState(false);
  const handleNoteOpen = () => {
    setOpenNote(true);
    setOpenSource(false);
  };
  const handleNoteClose = () => setOpenNote(false);

  //Open Approve Bank Statement

  const [openApprove, setOpenApprove] = useState(false);
  const handleApproveOpen = () => {
    setOpenApprove(true);
    setOpenNote(false);
  };

  const handleApproveClose = () => setOpenApprove(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      acceptLoanOffer({
        action: "accept",
        loanId: initializedLoan?.object?.body?.body?.loanId,
      })
    );
  };

  const cancelLoan = () => {
    navigate(-1);
    dispatch(cancelInitialization());
    dispatch(cancelRepayments());
  };

  return (
    <>
      <TopBar />
      <Section>
        <BlockSpacer mt={5} />
        <Typography variant="h5" component="h5" sx={contentTitle}>
          Loan Summary
        </Typography>
        <SummaryCard
          loanType={initializedLoan?.data?.body?.productCode}
          title={initializedLoan?.data?.body?.productName}
          currencyRequested={"Requested Loan Amount"}
          currency={repayment?.object?.body?.body?.accessibleLoanAmount}
          tenor={"Tenor"}
          days={`${repayment?.object?.body?.body?.tenor} Months`}
          interestRate={"Interest Rate"}
          interestRatePercentage={
            parseFloat(repayment?.object?.body?.body?.interestRate??0) * 100
          }
          interestPayable={"Interest Payable"}
          interestPayableCurrency={
            repayment?.object?.body?.body?.interestPayable??0
          }
          FeeRate={"Fee Rate"}
          FeeRatePercentage={
            (repayment?.object?.body?.body?.feeRate ?? 0) * 100
          }
          feePayable={"Fee Payable"}
          feePayableCurrency={repayment?.object?.body?.body?.feePayable??0}
          ProcessingFeeRate={"Processing Fee Rate"}
          ProcessingFeeRatePercentage={
            (repayment?.object?.body?.body?.processingFeeRate ?? 0) * 100
          }
          ProcessingFeePayable={"Processing Fee Payable"}
          ProcessingFeePayableCurrency={repayment?.object?.body?.body?.processingFeePayable??0}
          LegalFeeRate={"Legal Fee Rate"}
          LegalFeeRatePercentage={(repayment?.object?.body?.body?.legalFeeRate??0)*100}
          LegalFeePayable={"Legal Fee Payable"}
          LegalFeePayableCurrency={repayment?.object?.body?.body?.legalFeePayable}
          StampFeeRate={"Stamp Fee Rate"}
          StampFeeRatePercentage={(repayment?.object?.body?.body?.stampDutyFeeRate??0)*100}
          StampFeePayable={"Stamp Fee Payable"}
          StampFeePayableCurrency={repayment?.object?.body?.body?.stampDutyPayable}
          ManagementFeeRate={"Management Fee Rate"}
          ManagementFeeRatePercentage={(repayment?.object?.body?.body?.managementFeeRate??0)*100}
          ManagementFeePayable={"Management Fee Payable"}
          ManagementFeePayableCurrency={repayment?.object?.body?.body?.managementFeePayable}
          InsuranceFeeRate={"Insurance Fee Rate"}
          InsuranceFeeRatePercentage={(repayment?.object?.body?.body?.insuranceRate??0)*100}
          InsuranceFeePayable={"Insurance Fee Payable"}
          InsuranceFeePayableCurrency={repayment?.object?.body?.body?.interestPayable}
          TotalRepayment={"Total Repayment"}
          totalPaymentAmount={repayment?.object?.body?.body?.totalRepayment}
          TotalDebtWeight={"Total Debt Weight"}
          TotalDebtWeightPercentage={
            parseFloat(repayment?.object?.body?.body?.totalDebtWeight ?? 0) *
            100
          }
          // LoanDueDate={"Loan Due Date"}
          // dueDate={"12/10/2022"}
          monthlyRepayments={
            repayment?.object?.body?.body?.repaymentDueDatesAndAmounts
          }
        />
        <BlockSpacer mt="200" />

        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "89%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: { xs: "1rem" },
                textTransform: "capitalize",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "18px",
              }}
              type="button"
              onClick={cancelLoan}
            >
              Cancel
            </Button>
            <Buttons color="primary" width="328px" onClick={handleSubmit}>
              {loading ? (
                <ClipLoader color={"#fff"} loading={loading} size={20} />
              ) : (
                "Accept Offer"
              )}
            </Buttons>
          </Box>
        </Paper>
      </Section>
      {open && (
        <OfferSuccessModal
          open={open}
          handleClose={handleOfferClose}
          handleSource={handleSource}
        />
      )}
      {openSource && (
        <LoanValueModal
          open={openSource}
          handleClose={handleSourceClose}
          handleNoteOpen={handleNoteOpen}
        />
      )}
      {openNote && (
        <NoteModal
          open={openNote}
          handleClose={handleNoteClose}
          handleApproveOpen={handleApproveOpen}
        />
      )}

      {openApprove && (
        <ApproveBankModal open={openApprove} handleClose={handleApproveClose} />
      )}
    </>
  );
}

export default PersonalLoanSummary;
