import * as React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";

function GeneralFormInfo({ title, linkName, route }) {
  return (
    <div className={style.container}>
      {title}&nbsp;
      <Link to={route ? route : "/"}>
        <span className={style.link}>{linkName}</span>
      </Link>
    </div>
  );
}

export default GeneralFormInfo;
