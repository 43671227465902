import styled from "@emotion/styled";

export const Section = styled.section`
   margin-top: 10%;
   padding: 0 1rem;
    
   @media(max-width: 1244px){
    margin-top: 25%;
   }
   .file {
      display: none;
  }

  
  .file-input {
   position: absolute;
   z-index: -1;
   }
   .camera {
   position: absolute;
   bottom: 5px;
   left: 26px;
   color: #828282;
   cursor: pointer;
   }
   .banner {
      position: absolute;
      bottom: 0;
      left: 5px;
      height: 105px;
      width: 105px;
      border-radius: 100%;
      background: linear-gradient(to bottom, transparent 70%, #c6c6c6 30%)
   }

   @media (max-width:700px) {
      .banner {
          background: transparent;
          height: 72px;
          width: 68px;
      }
      .camera {
         font-size: 14px;
         left: 20px;
         bottom: 10px;
      }
  }
`

export const textheading = {
   fontFamily: 'Montserrat',
   fontStyle: 'normal',
   fontWight: 600,
   fontSize: '20px',
   lineHeight: '24px',
   textTransform: 'capitalize',
   color: '#222222'
}

export const container = {
   marginLeft: {xs: '0'} ,
   margin: {xs:'1rem',sm:"1rem",md:"2rem",lg:"2rem"}
}

export const textStyle = {
   fontFamily: 'Montserrat',
   fontStyle: 'normal',
   fontWeight: 500,
   fontSize: '13px',
   lineHeight: '16px',
   letterSpacing: '-0.04em',
   color: '#4F4F4F'

}

export const profileText = {
   fontFamily: 'Montserrat',
   fontStyle: 'normal',
   fontWeight: 600,
   fontSize: '20px',
   lineHeight: '24px',
   letterSpacing: '-0.04em',
   color: '#333333'
}