import React from "react";

function CreditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <circle cx="12.1" cy="13" r="10.5" fill="#130F26" stroke="#fff"></circle>
      <path
        fill="#3C3C3C"
        d="M23.285 11.71a.616.616 0 01.693.566 12 12 0 11-8.061-10.619.616.616 0 01.359.82.673.673 0 01-.843.371 10.716 10.716 0 107.268 9.574.673.673 0 01.584-.713z"
      ></path>
      <mask
        id="path-3-outside-1_2503_50038"
        width="9"
        height="9"
        x="15.5"
        y="0.6"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M15.5 0.6H24.5V9.6H15.5z"></path>
        <path
          fillRule="evenodd"
          d="M20.2 2.1a.5.5 0 10-1 0v2.4H17a.5.5 0 000 1h2.2v2.2a.5.5 0 001 0V5.5h2.4a.5.5 0 000-1h-2.4V2.1z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#130F26"
        fillRule="evenodd"
        d="M20.2 2.1a.5.5 0 10-1 0v2.4H17a.5.5 0 000 1h2.2v2.2a.5.5 0 001 0V5.5h2.4a.5.5 0 000-1h-2.4V2.1z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M19.2 4.5v1h1v-1h-1zm0 1h1v-1h-1v1zm1 0v-1h-1v1h1zm0-1h-1v1h1v-1zm-.5-1.9a.5.5 0 01-.5-.5h2A1.5 1.5 0 0019.7.6v2zm.5-.5a.5.5 0 01-.5.5v-2a1.5 1.5 0 00-1.5 1.5h2zm0 2.4V2.1h-2v2.4h2zm-3.2 1h2.2v-2H17v2zm.5-.5a.5.5 0 01-.5.5v-2A1.5 1.5 0 0015.5 5h2zm-.5-.5a.5.5 0 01.5.5h-2A1.5 1.5 0 0017 6.5v-2zm2.2 0H17v2h2.2v-2zm1 3.2V5.5h-2v2.2h2zm-.5-.5a.5.5 0 01.5.5h-2a1.5 1.5 0 001.5 1.5v-2zm-.5.5a.5.5 0 01.5-.5v2a1.5 1.5 0 001.5-1.5h-2zm0-2.2v2.2h2V5.5h-2zm3.4-1h-2.4v2h2.4v-2zm-.5.5a.5.5 0 01.5-.5v2A1.5 1.5 0 0024.1 5h-2zm.5.5a.5.5 0 01-.5-.5h2a1.5 1.5 0 00-1.5-1.5v2zm-2.4 0h2.4v-2h-2.4v2zm-1-3.4v2.4h2V2.1h-2z"
        mask="url(#path-3-outside-1_2503_50038)"
      ></path>
      <path
        fill="#fff"
        d="M11.941 19.3a.61.61 0 01-.47-.199.634.634 0 01-.183-.463v-.901a6.052 6.052 0 01-1.425-.252 6.376 6.376 0 01-1.058-.464.754.754 0 01-.38-.464.929.929 0 01.04-.556.64.64 0 01.353-.371c.165-.07.361-.04.588.093.244.141.562.278.954.41.4.124.88.186 1.438.186.653 0 1.124-.115 1.411-.344.288-.239.431-.535.431-.888 0-.292-.1-.53-.3-.716-.2-.185-.558-.335-1.072-.45l-1.385-.305c-1.577-.344-2.366-1.184-2.366-2.517 0-.742.248-1.365.745-1.868.505-.513 1.18-.822 2.026-.928v-.94c0-.186.06-.34.183-.464a.61.61 0 01.47-.199c.183 0 .336.066.458.199.13.123.196.278.196.463v.928c.348.035.714.12 1.098.252.392.132.732.313 1.02.543a.725.725 0 01.287.464.708.708 0 01-.092.477.616.616 0 01-.379.291c-.165.044-.361 0-.588-.132a3.823 3.823 0 00-1.974-.504c-.557 0-.997.128-1.32.384-.313.248-.47.574-.47.98 0 .301.096.544.287.73.192.176.519.317.98.423l1.425.318c.837.186 1.451.482 1.843.888.392.398.588.923.588 1.577 0 .742-.252 1.351-.758 1.828-.496.468-1.146.764-1.947.888v.94c0 .186-.061.34-.183.464a.61.61 0 01-.47.199z"
      ></path>
    </svg>
  );
}

export default CreditIcon;