import { Box, Typography, Grid, TextField, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { LIST_OF_STATE } from "../../../constants";
import BlockSpacer from "../../BlockSpacer";

import { ClipLoader, DotLoader } from "react-spinners";
import Button from "../../Buttons";
import DateField from "../../Core/DateField";
import SelectField from "../../Core/SelectField";
import FormAligner from "../../Hocs/FormAligner";
import BvnSuccessVerifcation from "../../Modals/BvnsucessVerifcation";
import OtpSuccessVerificationModal from "../../Modals/OtpSuccessVerificationModal";
import OtpVerificationModal from "../../Modals/OtpVerificationModal";
import FormLabel from "../Label";
import style from "./style.module.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../redux/slices/profileSlice";

function CompanyPersonalDetailForm({ 
  info, 
  setKycInfo,
  setBvn,
  verified,
  setVerified,
  bvnVerified,
  verifyBvn,
  verifedLoading,
  error,
}) {
  console.log(info, "selectedOption");
  const [value, setValue] = React.useState();

  const localState = JSON.parse(localStorage.getItem("states"));
  const [defaultState, setDefaultState] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [otp, setOtp] = React.useState("");
  const [openBvn, setOpenbvn] = React.useState(false);
  const handleBvnOpen = () => setOpenbvn(true);
  const handleBvnClose = () => setOpenbvn(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);

  const dispatch = useDispatch();
  const { countries, states, users } = useSelector(state => state.profile);

  React.useEffect(() => {
    dispatch(getStates(1));
  }, [dispatch]);

  const countryOptions = countries ? countries?.map((item) => (item?.name)) : []

  const statesOptions = states ? states?.map((item) => (item?.name)) : []

  //custom styles for react select

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#E0E0E0" : "#E0E0E0",
      height: "55px",
      width: "200px",
      // marginTop: "30px",
      border: "2px solid #E0E0E0",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#E0E0E0" : "#E0E0E0",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: "160px",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      width: "160px",
    }),
  };

  // const Options = localState.map((item) => {
  //   return { value: item.id, label: item.name };
  // });

  const handleStateChange = (event) => {
    setDefaultState(event.target.value)
    setKycInfo((prev) => ({
      ...prev,
      contactPersonDetails: {
        ...prev.contactPersonDetails,
        stateId: states?.find(item=> item?.name===event.target.value)?.id,
        contactPersonStateId: states?.find(item=> item?.name===event.target.value)?.id,
      }
    }));
  };

  const handleUserInputs = (e) => {
    const { name, value } = e.target;
    if(name==="firstName") {
      setKycInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          contactFirstName: value
        },
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
        },
      }));
      setBvn((prev) => ({
        ...prev,
        firstName: value,
      }));
    } else if(name==="lastName") {
      setKycInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          contactLastName: value
        },
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
        },
      }));
      setBvn((prev) => ({
        ...prev,
        lastName: value,
      }));
    }  else if(name==="contactPersonBvn") {
      setKycInfo((prev) => ({
        ...prev,
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
        },
      }));
      setBvn((prev) => ({
        ...prev,
        id: value,
      }));
    } else if(name==="phone" && e.target.value?.length <= 11) {
      setKycInfo((prev) => ({
        ...prev,
        phone: value,
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
        },
      }));
      setBvn((prev) => ({
        ...prev,
        phoneNumber: value,
      }));
    } else if(name==="middleName") {
      setKycInfo((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          contactMiddleName: value
        },
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
        },
      }));
    } else if(name==="contactPersonAddress") {
      setKycInfo((prev) => ({
        ...prev,
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [name]: value,
          address: value
        },
      }));
    } else {
      setKycInfo((prev) => ({
        ...prev,
        contactPersonDetails: {
          ...prev.contactPersonDetails,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  const submitBvnHandler = (e) => {
    e.preventDefault();
    console.log("bvnnnn ooo ", verifyBvn)
    verifyBvn();
  };

  const dateOfBirth = users?.company?.contactDOB ? users?.company?.contactDOB[0]+"/"+
  users?.company?.contactDOB[1]+"/"+users?.company?.contactDOB[2] : ""

  // React.useEffect(() => {
  //   setBvn({
  //     ...bvn,
  //     firstName: info.contactPersonDetails.firstName,
  //     lastName: info.contactPersonDetails.lastName,
  //     phoneNumber: info.contactPersonDetails.phone,
  //     isSubjectConsent: true,
  //     dateOfBirth: moment(dateOfBirth, "DD/MM/YYYY").format("YYYY/MM/DD")
  //   });
  // }, [info]);

  const BVNInput = (e) => {
    setBvn((prev) => ({
      ...prev,
      id: e.target.value,
    }));
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        bvn: e.target.value
      }
    }));
  };

  //OTP

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };
  const handleOpen = () => {
    setOpen(true);
    setOpenSuccess(false);
  };

  //OTP Success
  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  const handleSuccessOpen = () => {
    setOpenSuccess(true);
    setOpen(false);
  };
 
  return (
    <>
      <div>
        <div>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"First Name"} />
                <TextField
                  placeholder="First Name"
                  type="text"
                  required
                  onChange={handleUserInputs}
                  name="firstName"
                  sx={{ input: {background: bvnVerified&&'#e9ecef'} }}
                  value={info?.contactPersonDetails?.firstName}
                  InputProps={{
                    readOnly: bvnVerified,
                  }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Middle Name"} />
                <TextField
                  placeholder="Middle Name"
                  type="text"
                  required
                  name="middleName"
                  value={info?.contactPersonDetails?.middleName}
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Last Name"} />
                <TextField
                  placeholder="Doe"
                  type="text"
                  required
                  onChange={handleUserInputs}
                  sx={{ input: {color: bvnVerified&&'#999999'} }}
                  name="lastName"
                  value={info?.contactPersonDetails?.lastName}
                  InputProps={{
                    readOnly: bvnVerified,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"Email"} />
                <TextField
                  placeholder="Email"
                  type="email"
                  required
                  sx={{ input: {background: '#e9ecef'} }}
                  name="email"
                  value={info?.contactPersonDetails?.email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {/* <DateField
                name={"Date of Birth"}
                asterisk={true}
                type="date"
                // value={value}
                // value={dateFormat(info?.dateOfBirth, "dd/mm/yyyy")}
                value={`${dateOfBirth}`}
                // handleChange={handleChange}
                disabled={true}
              /> */}
              <FormAligner>
                <FormLabel text={"Date of Birth"} />
                <TextField
                  type="text"
                  required
                  sx={{ input: {background: '#e9ecef'} }}
                  name="date of birth"
                  value={`${dateOfBirth}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {/* <FormAligner>
                <SelectField
                  name={"Country of Residence"}
                  method={"Nigeria"}
                  items={countryOptions}
                  asterisk
                />
              </FormAligner> */}
              <FormAligner>
                <FormLabel text={"Country of Residence"} />
                <TextField
                  type="text"
                  required
                  sx={{ input: {background: '#e9ecef'} }}
                  name="country of residence"
                  value={`Nigeria`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Primary phone number"} />
                <TextField
                  placeholder="+234 7088957111"
                  type="text"
                  required
                  onChange={handleUserInputs}
                  value={info?.phone}
                  name="phone"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <SelectField
                  name={"State"}
                  method={defaultState}
                  changeAction={handleStateChange}
                  placeholder="Select State"
                  styles={customStyles}
                  items={statesOptions}
                  asterisk
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:0, sm:2, md:2}} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Bank Verification Number (BVN)"} asterisk />
                <TextField
                  placeholder="22038475940"
                  type="text"
                  required
                  sx={{ input: {background: bvnVerified&&'#e9ecef'} }}
                  InputProps={{
                    readOnly: bvnVerified,
                  }}
                  name="contactPersonBvn"
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                  value={info?.contactPersonDetails?.contactPersonBvn}
                  onChange={handleUserInputs}
                />
                {
                  !bvnVerified && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {error}
                    </p>
                  )
                }
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{mt:{xs:-2,md:0}}}>
              <FormAligner>
                <FormLabel text={""} />
                <Button
                  color={bvnVerified ? "tertiary" : "primary"}
                  width={"83px"}
                  // onClick={() => handleBvnOpen()}
                  disabled={bvnVerified}
                  onClick={submitBvnHandler}
                  // onClick={() => console.log("verifiiiiieed",bvnVerified)}
                >
                  {verifedLoading ? <CircularProgress size={20} color="secondary" /> : "Verify"}
                </Button>
              </FormAligner>
            </Grid>
          </Grid>
          
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FormAligner>
                <FormLabel text={"Address"} />
                <TextField
                  placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                  type="text"
                  required
                  name="contactPersonAddress"
                  // value={info?.contactPersonDetails?.address}
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormAligner>
                <FormLabel text={"City"} asterisk />
                <TextField
                  placeholder=""
                  type="text"
                  required
                  name="contactPersonCity"
                  onChange={handleUserInputs}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
        </div>
      </div>

      {open && (
        <OtpVerificationModal
          open={open}
          handleClose={handleClose}
          handleOtpChange={handleOtpChange}
          handleOpen={handleOpen}
          otp={otp}
          handleSuccessOpen={handleSuccessOpen}
        />
      )}
      {openSuccess && (
        <OtpSuccessVerificationModal
          open={openSuccess}
          handleClose={handleSuccessClose}
        />
      )}

      {openBvn && (
        <BvnSuccessVerifcation open={openBvn} handleClose={handleBvnClose} />
      )}
    </>
  );
}

export default CompanyPersonalDetailForm;
