import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  AVAILABLE_SCHOOLS,
  AVAILABLE_USER_TYPES,
  BUSINESS_STATUS,
  LIST_OF_STATE,
  PROFESSIONAL_OCCUPATION,
} from "../../../constants";
import BlockSpacer from "../../BlockSpacer";
import SelectField from "../../Core/SelectField";
import SelectField2 from "../../Core/SelectField2";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../Label";
import style from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import { getStates } from "../../../redux/slices/profileSlice";

function OccupationDetailForm({
  enableSideBusiness,
  isStudentOccupation,
  isProfessional,
  setKycInfo,
  info,
}) {
  //State
  const [defaultStateMethod, setDefaultStateMethod] = useState("");
  const [defaultUserMethod, setDefaultUserMethod] = useState("");
  const [proffesionalocuppation, setProffesionalocuppation] = useState([]);
  const { professionalOccupations } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const professions = professionalOccupations
    ? professionalOccupations?.content?.map(
        (item) => item?.professionalOccupation
      )
    : [];

  const professionOptions = [...professions, "Others"];

  console.log("prod", professionOptions);

  const getProfessionalOccupation = async () => {
    try {
      const response = await axios.get(
        "https://api-credit.optisoft.com.ng:8090/users/get-all-professional-occupation",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.content, "Professional Occupation");
      setProffesionalocuppation(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProfessionalOccupation();
  }, []);

  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    setKycInfo((prevState) => ({
      ...prevState,
      individualUser: {
        ...prevState?.individualUser,
        occupation: {
          ...prevState.individualUser?.occupation,
          [name]: value,
        },
      },
    }));
  };

  useEffect(() => {
    dispatch(getStates(1));
  }, []);

  const { states } = useSelector((state) => state.profile);

  const statesOptions = states ? states?.map((item) => item?.name) : [];

  //Occupation
  const [defaultOccupationMethod, setDefaultOccupationMethod] = useState("");
  const handleOccupationChange = (event) => {
    setDefaultOccupationMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        employmentDetail: {
          ...prev?.individualUser?.employmentDetail,
          occupation: event.target.value === "Others" ? "" : event.target.value,
        },
        occupation: {
          ...prev?.individualUser?.occupation,
          occupation: event.target.value === "Others" ? "" : event.target.value,
        },
      },
    }));
  };

  //Business Status
  const [defaultBusinessStatusMethod, setDefaultBusinessStatusMethod] =
    useState("");
  const handleBusinessStatusChange = (event) => {
    setDefaultBusinessStatusMethod(event.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        occupation: {
          ...prev?.individualUser?.occupation,
          businessStatus: event.target.value?.toUpperCase(),
        },
      },
    }));
  };

  const handleStateChange = (e) => {
    setDefaultStateMethod(e.target.value);
    setKycInfo((prev) => ({
      ...prev,
      individualUser: {
        ...prev?.individualUser,
        occupation: {
          ...prev?.individualUser?.occupation,
          state: e.target.value,
        },
      },
    }));
  };

  return (
    <>
      <div>
        <div>
          <div className={style.merge}>
            {isStudentOccupation ? (
              <>
                <Grid
                  container
                  rowSpacing={{ xs: 5, sm: 5, md: 8 }}
                  columnSpacing={8}
                >
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <FormAligner>
                      <FormLabel text={"School Name"} />
                      <TextField
                        placeholder="Please Enter your School name"
                        type="text"
                        required
                        name="schoolName"
                        value={info?.individualUser?.occupation?.schoolName}
                        onChange={handleUserInput}
                      />
                    </FormAligner>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  rowSpacing={{ xs: 5, sm: 5, md: 5 }}
                  columnSpacing={4}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectField
                      name={"Professional Occupation"}
                      method={defaultOccupationMethod}
                      asterisk
                      changeAction={handleOccupationChange}
                      items={professionOptions}
                      maxWidth={"100%"}
                    />
                  </Grid>
                  {enableSideBusiness && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <SelectField
                        name={"Side Business status"}
                        method={defaultBusinessStatusMethod}
                        changeAction={handleBusinessStatusChange}
                        items={Object.values(BUSINESS_STATUS)}
                        maxWidth={"785px"}
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </div>
          <BlockSpacer />
          {isStudentOccupation && (
            <>
              <Grid
                container
                rowSpacing={{ xs: 5, sm: 5, md: 8 }}
                columnSpacing={8}
              >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormAligner>
                    <FormLabel text={"School Address"} />
                    <TextField
                      placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                      type="text"
                      required
                      name="schoolAddress"
                      value={info?.individualUser?.occupation?.schoolAddress}
                      onChange={handleUserInput}
                    />
                  </FormAligner>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <FormLabel text={"City"} />
                    <TextField
                      placeholder="Lagos"
                      type="text"
                      required
                      name="city"
                      value={info?.individualUser?.occupation?.city}
                      onChange={handleUserInput}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
              <BlockSpacer />
              <Grid
                container
                rowSpacing={{ xs: 5, sm: 5, md: 8 }}
                columnSpacing={8}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormAligner>
                    <SelectField
                      name={"State"}
                      method={defaultStateMethod}
                      changeAction={handleStateChange}
                      placeholder="Select State"
                      items={statesOptions}
                      asterisk
                    />
                  </FormAligner>
                </Grid>
              </Grid>
            </>
          )}

          {defaultOccupationMethod === "Others" && (
            <>
              <Grid
                container
                rowSpacing={{ xs: 5, sm: 5, md: 8 }}
                columnSpacing={8}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormAligner>
                    <FormLabel text={"Professional Occupation Details"} />
                    <TextField
                      placeholder="Please Enter other type of Proesssion"
                      type="text"
                      required
                      name="occupationDetails"
                      value={
                        info?.individualUser?.occupation?.occupationDetails
                      }
                      onChange={handleUserInput}
                    />
                  </FormAligner>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default OccupationDetailForm;
