import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { InputBase } from "@mui/material";
import "../my-credit/active-transaction/Extension/stye.css";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import useScreenSize from "../../components/ScreenSize";
const SearchBar = ({ setSearchQuery, clearText, searchQuery }) => {
  const { width } = useScreenSize();
  return(
    <form>
      <Box 
        style={{ position: "relative" }} 
        sx={{
          display:"flex",
          justifyContent: "center",
          alignItems: "center",
        }} 
      >
        <InputBase
          id="pley"
          style={{
            width: width>700?"440px":"300px",
            height: "50px",
            background: "#F9F9F9",
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            paddingLeft: width>700?"5.5rem": "2.4rem",
          }}
          value={searchQuery}
          className="text"
          onInput={(e) => {
            setSearchQuery(e.target.value);
          }}
          variant="outlined"
          placeholder="Search using keywords"
          size="small"
        />
        {/* <IconButton type="submit" aria-label="search"></IconButton> */}
        <IconButton 
          // type="submit" 
          style={{ 
            position: "absolute",
            left: width>700?"0.5rem":"0.2rem"
          }}
        >
          <SearchIcon style={{ fill: "black", }}/>
        </IconButton>
        <IconButton 
          // type="submit" 
          style={{ 
            position: "absolute",
            right: width>700?"0.5rem":"0.2rem"
          }}
        >
          {
            searchQuery && searchQuery?.length>0 && (
              <ClearIcon style={{ fill: "black", }} onClick={clearText?()=>clearText():()=>{}} />
            )
          }
        </IconButton>
      </Box>
    </form>
  )
};

export default SearchBar;
