import React from 'react';
import {
    Section,
    AccountHightTittle,
    LeaseTitle,
    LeaseContent,
    transactionStyle,
    transactionblack
} from './style.js';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import LineBreak from '../Hocs/LineBreak/index.jsx';

function AccountHight() {
    return (
        <Section>
            <Typography sx={AccountHightTittle}>Account Highlight</Typography>
            <Box sx={{
                marginTop: 0,
                padding: '1rem'
            }}>
                <Accordion sx={{padding:"1rem 0"}} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div>
                            <Typography sx={LeaseTitle}>Lease</Typography>
                            {/* <Typography sx={LeaseContent}>Choose from a fixed savings plan</Typography> */}
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom:8 }}>
                            <Typography component="p" sx={transactionblack}>Status</Typography>
                            <Box
                                style={{
                                    padding: "6px 9px",
                                    backgroundColor: "rgba(205, 41, 0, 0.74)",
                                    borderRadius: 10
                                }}
                            >
                                <Typography 
                                    sx={{
                                        color:"rgba(255, 255, 255, 0.74)",
                                        fontSize: 9,
                                        fontWeight: 700
                                    }} 
                                    component="p" 
                                >
                                    Not Performing
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component="p" sx={transactionblack}>Total Transaction Value</Typography>
                            <Typography component="p" sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Expected Repyment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={transactionStyle}>Total Payment Due</Typography>
                            <Typography sx={transactionStyle}>₦22,346.00</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Actual Repayment Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Extended Interest Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Value Paid</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Outstanding payment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>

                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{padding:"1rem 0"}} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Box>
                            <Typography sx={LeaseTitle}>Collateral Loans</Typography>
                            {/* <Typography sx={LeaseContent}>Choose from a Target savings plan</Typography> */}
                        </Box>


                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom:8 }}>
                            <Typography component="p" sx={transactionblack}>Status</Typography>
                            <Box
                                style={{
                                    padding: "6px 9px",
                                    backgroundColor: "rgba(205, 41, 0, 0.74)",
                                    borderRadius: 10
                                }}
                            >
                                <Typography 
                                    sx={{
                                        color:"rgba(255, 255, 255, 0.74)",
                                        fontSize: 9,
                                        fontWeight: 700
                                    }} 
                                    component="p" 
                                >
                                    Not Performing
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component="p" sx={transactionblack}>Total Transaction Value</Typography>
                            <Typography component="p" sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Expected Repyment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={transactionStyle}>Total Payment Due</Typography>
                            <Typography sx={transactionStyle}>₦22,346.00</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Actual Repayment Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Extended Interest Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Value Paid</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Outstanding payment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>

                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{padding:"1rem 0"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Box>
                            <Typography sx={LeaseTitle}>Non-Collateral Loans</Typography>
                            {/* <Typography sx={LeaseContent}>Choose from a Target Income plan</Typography> */}
                        </Box>

                    </AccordionSummary>


                    <AccordionDetails>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom:8 }}>
                            <Typography component="p" sx={transactionblack}>Status</Typography>
                            <Box
                                style={{
                                    padding: "6px 9px",
                                    backgroundColor: "rgba(205, 41, 0, 0.74)",
                                    borderRadius: 10
                                }}
                            >
                                <Typography 
                                    sx={{
                                        color:"rgba(255, 255, 255, 0.74)",
                                        fontSize: 9,
                                        fontWeight: 700
                                    }} 
                                    component="p" 
                                >
                                    Not Performing
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component="p" sx={transactionblack}>Total Transaction Value</Typography>
                            <Typography component="p" sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Expected Repyment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={transactionStyle}>Total Payment Due</Typography>
                            <Typography sx={transactionStyle}>₦22,346.00</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Actual Repayment Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Extended Interest Due</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <LineBreak />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Value Paid</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
                            <Typography sx={transactionblack}>Total Outstanding payment</Typography>
                            <Typography sx={transactionblack}>₦22,346.00</Typography>
                        </Box>

                    </AccordionDetails>
                </Accordion>
            </Box>

        </Section>
    );
}

export default AccountHight;
