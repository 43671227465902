import React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import Buttons from "../../../../components/Buttons/index.jsx";
import { Box } from "@mui/system";

import { Link } from "react-router-dom";
import NoteModal from "../../../../components/Modals/SignOfferModal";
import SuccessOfferModal from "../../../../components/Modals/SuccessOfferModal";
import WithdrawRequestModal from "../../../../components/Modals/WithdrawRequestModal/WithdrawRequestModal";
import SuccessWithdraw from "../../../../components/Modals/WithdrawSuccessModal";
import {
  Section,
  AccountNumber,
  Number,
  Tenor,
  Two,
  Reason,
  Detail,
  Quickloan,
  Requested,
  Stenor,
  figure,
  days,
  Interest,
  Value,
  Fee,
  Digit,
  Repay,
  Loan,
  Integer,
  Date,
  signOffer,
  button,
} from "./style";
import BlockSpacer from "../../../../components/BlockSpacer";
import TopBar from "../../../../components/MyCreditTopBar";

// Sign Offer Upload

function SignOffer({ title }) {
  const [openNote, setNoteOpen] = useState(false);
  const handleNoteOpen = () => setNoteOpen(true);
  const handleNoteClose = () => setNoteOpen(false);

  // Successful Upload
  const [openSuccessNote, setSuccessNoteOpen] = useState(false);
  const handleSuccessInfoOpen = () => setSuccessNoteOpen(true);
  const handleSuccessInfoClose = () => setSuccessNoteOpen(false);

  // Withdraw request

  const [openWithdrawNote, setWithdrawNoteOpen] = useState(false);
  const handleWithdrawInfoOpen = () => setWithdrawNoteOpen(true);
  const handleWithdrawInfoClose = () => setWithdrawNoteOpen(false);

  //Withdraw success

  const [openSuccessWithdraw, setOpenSuccessWithdraw] = useState(false);

  const handleSuccessWithdraw = () => {
    setOpenSuccessWithdraw(true);
    setWithdrawNoteOpen(false);
  };

  const hanldeSuccessWithdrawClose = () => {
    setOpenSuccessWithdraw(false);
  };

  return (
    <>
      <TopBar />
      <main>
        <Section>
          <Grid container spacing={9} sx={{ overflow: { xs: "hidden" } }}>
            {/* First Card grid */}
            <Grid item xs={12} xxs={12} lg={6} xlg={6}>
              <Box>
                <Paper
                  elevation={1.5}
                  sx={{
                    mt: { xs: "0", lg: "-8" },
                    width: { xs: "400px", lg: "428px" },
                    maxWidth: "100%",
                    height: { xs: "250px", lg: "293px" },
                    borderRadius: "10px",
                    overflow: "hidden",
                    position: "relative",
                    boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
                  }}
                >
                  <Typography sx={AccountNumber}>Account Number</Typography>
                  <Typography sx={Number}>01234567890</Typography>
                  <Typography sx={Tenor}>Tenor</Typography>
                  <Typography sx={Two}>Two Months</Typography>
                  <Typography sx={Reason}>Reason For Loan</Typography>
                  <Typography sx={signOffer}>Sign Offer</Typography>
                  <Typography sx={Detail}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eum, cupiditate.
                  </Typography>
                </Paper>
              </Box>
            </Grid>

            {/* Second Card grid */}
            <Grid
              item
              xs={12}
              xxs={12}
              lg={6}
              xlg={6}
              style={{
                background: "rgba(28, 68, 141, 0.03)",
                width: "503px",
                height: "789px",
              }}
            >
              <Card
                sx={{
                  width: { xs: "380px", md: "350px", lg: "428.84px" },
                  height: "328.5px",
                  background: "#fff",
                  position: "relative",
                  overflow: "hidden",
                  boxShadow: " 8px 0px 18px rgba(173, 173, 173, 0.25)",
                  border: 0,
                }}
              >
                <CardContent>
                  <Typography sx={Quickloan}>
                    Quick Nano Loan Summary
                  </Typography>
                  <Typography sx={Requested}>Requested Loan Amount</Typography>
                  <Typography sx={Stenor}>Tenor</Typography>
                  <Typography sx={figure}>2,000,000</Typography>
                  <Typography sx={days}>7 days</Typography>
                  <Box
                    style={{
                      border: "1.2px dashed#EFEFEF",
                      position: "relative",
                      top: "118px",
                    }}
                  ></Box>
                  <Typography sx={Interest}>Interest Payable</Typography>
                  <Typography sx={Value}>2,000,000</Typography>
                  <Typography sx={Fee}>Fee Payable</Typography>
                  <Typography sx={Digit}>20000</Typography>
                  <Box
                    style={{
                      border: "1.2px dashed#EFEFEF",
                      position: "relative",
                      top: "210px",
                    }}
                  ></Box>
                  <Typography sx={Repay}>Total Repayment</Typography>
                  <Typography sx={Loan}>Loan Due Date</Typography>
                  <Typography sx={Integer}>100,000</Typography>
                  <Typography sx={Date}>12/03/2022</Typography>
                </CardContent>
              </Card>
              <BlockSpacer />
              <Box sx={button}>
                <Buttons
                  color="primary"
                  width="328px"
                  onClick={() => handleNoteOpen()}
                >
                  Sign offer Letter
                </Buttons>
              </Box>
            </Grid>
          </Grid>
        </Section>
        <BlockSpacer mt={80} />
        <Paper
          elevation={0.5}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "8px 0px 18px rgba(173, 173, 173, 0.25)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              onClick={() => handleWithdrawInfoClose()}
            >
              <Link to="/pending-transaction">Back</Link>
            </Button>

            <Button
              sx={{
                background: "#F2F2F2",
                width: "328px",
                height: "54px",
                color: "#111E6C",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              onClick={() => handleWithdrawInfoOpen()}
            >
              Withdraw Request
            </Button>
          </Box>
        </Paper>
        {openNote && (
          <NoteModal
            open={openNote}
            handleClose={handleNoteClose}
            handleSuccessInfoOpen={handleSuccessInfoOpen}
          />
        )}
        {openSuccessNote && (
          <SuccessOfferModal
            open={openSuccessNote}
            handleClose={handleSuccessInfoClose}
          />
        )}
        {openWithdrawNote && (
          <WithdrawRequestModal
            open={openWithdrawNote}
            handleClose={handleWithdrawInfoClose}
            handleSuccessWithdraw={handleSuccessWithdraw}
          />
        )}

        {openSuccessWithdraw && (
          <SuccessWithdraw
            open={openSuccessWithdraw}
            handleClose={hanldeSuccessWithdrawClose}
          />
        )}
      </main>
    </>
  );
}

export default SignOffer;
