import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import BlockSpacer from "../../BlockSpacer";
import SelectField from "../../Core/SelectField";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../Label";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../redux/slices/profileSlice";

function ContactDetailForm({ setKycInfo, info, usersDetails }) {
  const dispatch = useDispatch();
  //Cuuntry
  // const countries = Country.getAllCountries().map((country) => country.name);
  const [defaultCountryMethod, setDefaultCountryMethod] = useState("Nigeria");

  const { countries, states } = useSelector(state => state.profile)

  React.useEffect(() => {
    // let country = countries?.find(item=> item?.name===defaultCountryMethod)?.id
    dispatch(getStates(parseInt(1)));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryMethod]);


  const countryOptions = countries ? countries?.map((item) => (item?.name)) : []

  const statesOptions = states ? states?.map((item) => (item?.name)) : []

  const handleUserInput = (e) => {
    const { name, value } = e.target;
    setKycInfo((prevState) => ({
      ...prevState,
      individualUser: {
        ...prevState.individualUser,
        contactAddress: {
          ...prevState.individualUser.contactAddress,
          [name]: value,
        },
      },
    }));
  };
  //Country
  const handleCountryChange = (event) => {
    setDefaultCountryMethod(event.target.value);
    setKycInfo((prevState) => ({
      ...prevState,
      individualUser: {
        ...prevState.individualUser,
        countryId: countries?.find(item=>item.name===event.target.value)?.id,
        contactAddress: {
          ...prevState.individualUser.contactAddress,
          country: event.target.value,
        },
      },
    }));

    setKycInfo((prevState) => ({
      ...prevState,
      individualUser: {
        ...prevState.individualUser,
        coutryOfResidence: countries?.find(item=> item?.name===event.target.value)
      },
    }));
  };


  const [defaultState, setDefaultState] = useState("")
  //State Changes
  const handleStateChange = (event) => {
    setDefaultState(event.target.value)
    setKycInfo({
      ...info,
      individualUser: {
        ...info.individualUser,
        contactAddress: {
          ...info.individualUser.contactAddress,
          state: event.target.value,
        },
      },
    });
  };

  // console.log(info, "info");
  return (
    <>
      <div>
        <div>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormAligner>
                <FormLabel text={"Home Address"} />
                <TextField
                  placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                  type="text"
                  required
                  name="homeAddress"
                  onChange={handleUserInput}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* <FormAligner>
                <SelectField
                  name={"Country of Residence"}
                  method={defaultCountryMethod}
                  // changeAction={handleCountryChange}
                  // items={countryOptions}
                  // disabled
                  asterisk
                />
              </FormAligner> */}
              <FormAligner>
                <FormLabel text={"Country of Residence"} />
                <TextField
                  type="text"
                  required
                  sx={{ input: {background: '#e9ecef'} }}
                  name="country of residence"
                  value={`Nigeria`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormAligner>
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormAligner>
                <FormLabel text={"Street Address"} />
                <TextField
                  placeholder="808 Mandilas Mall, Marina, Lagos Nigeria."
                  type="text"
                  required
                  name="streetAddress"
                  onChange={handleUserInput}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SelectField
                name={"State"}
                // method={defaultStateMethod}
                changeAction={handleStateChange}
                method={defaultState}
                items={statesOptions}
                asterisk
              />
            </Grid>
          </Grid>
          <BlockSpacer />
          <Grid container rowSpacing={{xs:5, sm:4, md:8}} columnSpacing={8}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormAligner>
                <FormLabel text={"City"} />
                <TextField
                  placeholder="City"
                  type="text"
                  required
                  name="city"
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                  onChange={handleUserInput}
                />
              </FormAligner>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormAligner>
                <FormLabel text={"Nationality"} />
                <TextField
                  placeholder="Nigeria"
                  type="text"
                  required
                  name="nationality"
                  onChange={handleUserInput}
                  style={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
                />
              </FormAligner>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default ContactDetailForm;
