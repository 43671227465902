import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  @media (max-width: 1215px) {
    margin-top: 30%;
    padding: 0 1rem;
    .searchAlign {
      width: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

`;
