import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedBack: {
    messageTitle: "",
    messageDescription: "",
  },
};

const feedbackSlice = createSlice({
  name: "feedback",

  initialState,

  reducers: {
    feedbackActions: (state, action) => {
      state.feedBack = action.payload;
    },
  },
});

export const { feedbackActions } = feedbackSlice.actions;

export default feedbackSlice.reducer;
