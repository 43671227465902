import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNotificationService } from "../../services/notificationServices";

const initialState = {
  loading: false,
  allNotifications: null,
  allNotificationsErr: null,
};

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  (formData, { rejectWithValue }) => {
    return getNotificationService(formData)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  extraReducers: builder => {
    // get all credit notifications pending
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true
    }) 
    // get all credit notifications success
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false
      state.allNotifications = action.payload
      state.allNotificationsErr = null
    })
    // get all credit notifications failed
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false
      state.allNotifications = null
      state.allNotificationsErr = action.error
    })
  }
});

export default notificationSlice.reducer;