import React from "react";
import {
  Section,
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  Loan,
  loan,
} from "./style.js";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import BlockSpacer from "../../../components/BlockSpacer/index.jsx";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import TopBar from "../../../components/GetCreditTopBar";

function CorporateCredit() {
  //Vehicle Asset Cash Loan
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  //Landed Property Asset Cash Loan

  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(true);
  };

  const handleCollapseClose = () => {
    setCollapse(false);
  };

  // Cash Back Loan

  const [swift, setSwift] = useState(false);

  const handleSwift = () => {
    setSwift(true);
  };

  const handleSwiftClose = () => {
    setSwift(false);
  };

  return (
    <>
      <TopBar />
      <Section>
        <Typography variant="h6" sx={Loan}>
          Loan
        </Typography>
        <Typography variant="p">
          (Corporate Public Listed / Unlisted)
        </Typography>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {" "}
                  <div>
                    {" "}
                    <Typography variant={"h6"} sx={loan}>
                      Collateral Loans
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{ fontSize: "14px", marginTop: "1rem" }}
                    >
                      Request a Collateral Loan
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {!show && (
                    <Box
                      display={"flex"}
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >
                        Vehicle Asset Cash Loan
                      </Typography>
                      {/* <Link to="/vehicle-asset-cash-loan"> */}
                      <AddIcon
                        sx={{
                          color: "#111E6C",
                          cursor: "pointer",
                          width: "16px",
                        }}
                        onClick={handleShow}
                      />
                    </Box>
                  )}
                  {show && (
                    <Paper
                      elevation={0.5}
                      sx={{
                        width: { xs: "448px", lg: "448px" },
                        maxWidth: "100%",
                        height: { sx: "400px", lg: "213px" },
                        boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "95%",
                        }}
                      >
                        <Box>
                          <Typography sx={QuickNanoLoan}>
                            Vehicle Asset Cash Loan
                          </Typography>
                        </Box>
                        <Box>
                          <RemoveIcon
                            sx={{
                              color: "#111E6C",
                              cursor: "pointer",
                              width: "16px",
                            }}
                            onClick={handleShowClose}
                          />
                        </Box>
                      </Box>
                      <Box sx={callToActionCard}>
                        <Box>
                          <NanoImage />
                        </Box>
                        <div>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Link to="/vehicle-asset-loan/corporate">
                            <Button
                              sx={{
                                background: "#F2F2F2",
                                marginTop: "1rem",
                                width: "144px",
                                height: "42px",
                                color: "#111E6C",
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "21px",
                                letterSpacing: "-0.03em",
                                textTransform: "capitalize",
                              }}
                            >
                              Request
                            </Button>
                          </Link>
                        </div>
                      </Box>
                    </Paper>
                  )}
                </AccordionDetails>
                <BlockSpacer mt={20} />
                <AccordionDetails>
                  {!collapse && (
                    <Box
                      display={"flex"}
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >
                        Landed Property Asset Cash Loan
                      </Typography>
                      {/* <Link to="/landed-asset-loan"> */}
                      <AddIcon
                        sx={{
                          color: "#111E6C",
                          cursor: "pointer",
                          width: "16px",
                        }}
                        onClick={handleCollapse}
                      />
                    </Box>
                  )}

                  {collapse && (
                    <Paper
                      elevation={0.5}
                      sx={{
                        width: { xs: "448px", lg: "448px" },
                        maxWidth: "100%",
                        height: { sx: "400px", lg: "213px" },
                        boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "95%",
                        }}
                      >
                        <Box>
                          <Typography sx={QuickNanoLoan}>
                            Landed Property Asset Cash Loan
                          </Typography>
                        </Box>
                        <Box>
                          <RemoveIcon
                            sx={{
                              color: "#111E6C",
                              cursor: "pointer",
                              width: "16px",
                            }}
                            onClick={handleCollapseClose}
                          />
                        </Box>
                      </Box>
                      <Box sx={callToActionCard}>
                        <Box>
                          <NanoImage />
                        </Box>
                        <div>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Link to="/corporate-loan/landed-asset">
                            <Button
                              sx={{
                                background: "#F2F2F2",
                                marginTop: "1rem",
                                width: "144px",
                                height: "42px",
                                color: "#111E6C",
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "21px",
                                letterSpacing: "-0.03em",
                                textTransform: "capitalize",
                              }}
                            >
                              Request
                            </Button>
                          </Link>
                        </div>
                      </Box>
                    </Paper>
                  )}
                </AccordionDetails>
                <BlockSpacer mt={20} />
                <AccordionDetails>
                  {!swift && (
                    <Box
                      display={"flex"}
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "17px",
                          letterSpacing: "-0.01em",
                        }}
                      >
                        Cashed Backed Loan
                      </Typography>
                      {/* <Link to="/cash-back"> */}
                      <AddIcon
                        sx={{
                          color: "#111E6C",
                          cursor: "pointer",
                          width: "16px",
                        }}
                        onClick={handleSwift}
                      />
                    </Box>
                  )}

                  {swift && (
                    <Paper
                      elevation={0.5}
                      sx={{
                        width: { xs: "448px", lg: "448px" },
                        maxWidth: "100%",
                        height: { sx: "400px", lg: "213px" },
                        boxShadow: " 0px 4px 30px rgba(196, 204, 221, 0.28)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "95%",
                        }}
                      >
                        <Box>
                          <Typography sx={QuickNanoLoan}>
                            Cash Back Loan
                          </Typography>
                        </Box>
                        <Box>
                          <RemoveIcon
                            sx={{
                              color: "#111E6C",
                              cursor: "pointer",
                              width: "16px",
                            }}
                            onClick={handleSwiftClose}
                          />
                        </Box>
                      </Box>
                      <Box sx={callToActionCard}>
                        <Box>
                          <NanoImage />
                        </Box>
                        <div>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Typography sx={paragraphTitle}>
                            . Lorem ipsum dolor sit amet consectetur.
                          </Typography>
                          <Link to="/cash-back-corporate">
                            <Button
                              sx={{
                                background: "#F2F2F2",
                                marginTop: "1rem",
                                width: "144px",
                                height: "42px",
                                color: "#111E6C",
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "21px",
                                letterSpacing: "-0.03em",
                                textTransform: "capitalize",
                              }}
                            >
                              Request
                            </Button>
                          </Link>
                        </div>
                      </Box>
                    </Paper>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </>
  );
}

export default CorporateCredit;
