import styled from "@emotion/styled";

export const Section = styled.section`
  margin-top: 15%;
  min-height: 50vh;
  @media (max-width: 1215px) {
    margin-top: 30%;
    padding: 1rem;
    min-height: auto;
  }
  .text1 {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: #828282;
  }
  .text2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    color: #242424;
  }
  .text3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.15px;
    color: #BDBDBD;
  }
  .text4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #242424;
  }
`;

export const previous = {
  background: "#F2F2F2",
  borderRadius: "5px",
  border: "none",
  textTransform: "capitalize",
  width: "132.6px",
  height: "34px",
  fontFamily: "Montserrat",
  fontWeight: "500",
  fontSize: "13px",
  color: "#111E6C",
};

export const next = {
  background: "#111E6C",
  borderRadius: "5px",
  border: "none",
  textTransform: "capitalize",
  width: "132.6px",
  height: "34px",
  fontFamily: "Montserrat",
  fontWeight: "500",
  color: "#FFFFFF",
  "&:hover": {
    color: "#111E6C",
    background: "#F2F2F2",
  },
};

export const headingText = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineheight: '24px',
  textAlign: 'right',
  textTransform: 'capitalize',
  mt: {xs: 0, lg: 5}
}
