import React from "react";
import BlockSpacer from "../../BlockSpacer";
import OccupationDetailForm from "../../Forms/OccupationDetailForm";
import style from "./style.module.css";

function OccupationDetails({
  enableSideBusiness,
  isStudentOccupation,
  isProfessional,
  setKycInfo,
  info
}) {
  return (
    <div className={style.container}>
      <div className={style.title}>Occupation Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <OccupationDetailForm
          isProfessional={isProfessional}
          enableSideBusiness={enableSideBusiness}
          isStudentOccupation={isStudentOccupation}
          setKycInfo={setKycInfo}
          info={info}
        />
      </div>
    </div>
  );
}

export default OccupationDetails;
