import React, { useEffect, useState } from "react";
import { TextField, Typography, Grid, Box, Button } from "@mui/material";
import BlockSpacer from "../../../../BlockSpacer";
import FormAligner from "../../../../Hocs/FormAligner";
import Buttons from "../../../../Buttons";
import { textheading } from "./style";
import FormLabel from "../../../Label";
import FileuploadIcon from "../../../../IconSvgs/FileuploadIcon";
import OtpVerificationModal from "../../../../Modals/OtpVerificationModal";
import SelectField from "../../../../Core/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { getStates } from "../../../../../redux/slices/profileSlice";
import useScreenSize from "../../../../ScreenSize";

function CompanyPersonalForm({
  handleSendOtp,
  setCurrentEdit,
  showEdit,
  removeEdit,
  formData,
  setFormData,
  submitHandler
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const [ defaultState, setDefaultState ] = useState("")
  const dispatch = useDispatch();
  const { width } = useScreenSize();

  useEffect(() => {
    dispatch(getStates(1));
  }, [])

  const { states, users } = useSelector(state => state.profile);

  useEffect(() => {
    setDefaultState(users?.company?.contactPersonState?.name)
  },[users])


  const statesOptions = states ? states?.map((item) => (item?.name)) : []

  const handleStateChange = (event) => {
    setDefaultState(event.target.value)
    setFormData((prev) => ({
      ...prev,
      contactPersonDetails: {
        ...prev.contactPersonDetails,
        stateId: states?.find(item=> item?.name===event.target.value)?.id,
        contactPersonStateId: states?.find(item=> item?.name===event.target.value)?.id
      },
    }));
  };


  const handleUserInput = (e) => {
    const { name, value } = e.target;
    if(name==="firstName") {
      setFormData({
        ...formData,
        company: {
          ...formData.company,
          contactFirstName: value
        },
        contactPersonDetails: {
          ...formData.contactPersonDetails,
          [name]: value
        }
      })
    } else if(name==="lastName") {
      setFormData({
        ...formData,
        company: {
          ...formData.company,
          contactLastName: value
        },
        contactPersonDetails: {
          ...formData.contactPersonDetails,
          [name]: value
        }
      })
    } else if(name==="middleName") {
      setFormData({
        ...formData,
        company: {
          ...formData.company,
          contactMiddleName: value
        },
        contactPersonDetails: {
          ...formData.contactPersonDetails,
          [name]: value
        }
      })
    } else {
      setFormData({
        ...formData,
        contactPersonDetails: {
          ...formData.contactPersonDetails,
          [name]: value
        }
      })
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography sx={textheading}>Contact Person Details</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            {!(showEdit.includes(2)) ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="primary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      handleSendOtp();
                      setCurrentEdit(2);
                    }}
                  >
                    Edit
                  </Buttons>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ ml: { xs: 5, lg: 40 }, mt: { sx: 2, lg: 6 } }}>
                  <Buttons
                    color="tertiary"
                    width={width<=700 ? "60px" :  "72px"}
                    fontSize={width<=700 && "14px"}
                    onClick={()=>{
                      removeEdit(2);
                      submitHandler();
                    }}
                  >
                    Save
                  </Buttons>
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>

      <BlockSpacer mt={50} />

      <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"First Name"} asterisk />
            <TextField 
              placeholder="ekiyee" 
              type="text" 
              required 
              InputProps={{
                readOnly: true,
              }}
              disabled={!(showEdit.includes(2))}
              name="firstName"
              value={formData.contactPersonDetails.firstName}
              onChange={handleUserInput} 
              sx={{ input: {background: showEdit.includes(2)&&'#e9ecef'} }}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Middle Name"} asterisk />
            <TextField
              placeholder="bilaowei"
              type="text"
              disabled={!(showEdit.includes(2))}
              required
              InputProps={{
                readOnly: true,
              }}
              name="middleName"
              value={formData.contactPersonDetails.middleName}
              onChange={handleUserInput}
              sx={{ input: {background: showEdit.includes(2)&&'#e9ecef'} }}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Last Name"} asterisk />
            <TextField
              placeholder="bilaowei"
              type="text"
              required
              disabled={!(showEdit.includes(2))}
              InputProps={{
                readOnly: true,
              }}
              name="lastName"
              value={formData.contactPersonDetails.lastName}
              onChange={handleUserInput}
              sx={{ input: {background: showEdit.includes(2)&&'#e9ecef'} }}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={35} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormLabel text={"Email Address"} />
          <FormAligner>
            <TextField
              placeholder="Info@optisoft.ng"
              type="email"
              required
              disabled={!(showEdit.includes(2))}
              InputProps={{
                readOnly: true,
              }}
              name="email"
              value={formData.contactPersonDetails.email}
              onChange={handleUserInput}
              sx={{ input: {background: showEdit.includes(2)&&'#e9ecef'} }}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormLabel text={"Phone Number"} />
          <FormAligner>
            <TextField
              placeholder="+2347088957111"
              type="text"
              required
              disabled={!(showEdit.includes(2))}
              name="phone"
              value={formData.phone}
              onChange={handleUserInput}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={50} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"BVN"} />
            <TextField
              placeholder="4457768689"
              type="number"
              required
              InputProps={{
                readOnly: true
              }}
              value={formData.contactPersonDetails.contactPersonBvn}
              disabled={!(showEdit.includes(2))}
              name="bvn"
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"Country of Residence"} />
            <TextField 
              placeholder="Nigeria" 
              type="text" 
              required 
              name="name" 
              disabled={!(showEdit.includes(2))}
              InputProps={{
                readOnly: true,
              }}
              value={"Nigeria"} 
              sx={{ input: {background: showEdit.includes(2)&&'#e9ecef'} }}
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <SelectField
              name={"State"}
              method={defaultState}
              changeAction={handleStateChange}
              disabled={!(showEdit.includes(2))}
              placeholder="Select State"
              items={statesOptions}
              asterisk
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={35} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <FormAligner>
            <FormLabel text={"Address"} />
            <TextField 
              placeholder="Lagos" 
              type="text" 
              required 
              disabled={!(showEdit.includes(2))}
              name="contactPersonAddress"
              value={formData.contactPersonDetails.contactPersonAddress}
              onChange={handleUserInput} 
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormAligner>
            <FormLabel text={"City"} />
            <TextField 
              placeholder="Lagos" 
              type="text" 
              disabled={!(showEdit.includes(2))}
              required 
              value={formData?.contactPersonDetails?.contactPersonCity}
              name="contactPersonCity" 
              onChange={handleUserInput}
            />
          </FormAligner>
        </Grid>
      </Grid>

      <BlockSpacer mt={35} />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormAligner>
            <FormLabel text={"ID Type"} />
            <TextField
              placeholder="National ID"
              type="text"
              required
              disabled={!(showEdit.includes(2))}
              name="name"
            />
          </FormAligner>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormAligner>
            <FormLabel text={"ID Type"} />
            <TextField placeholder="0123456" type="text" required name="name" />
          </FormAligner>
        </Grid>
      </Grid> */}

      {/* <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: "flex" }}>
            <FileuploadIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Upload ID (front)
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                jpg, png. PDF 2 MB
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
            <Button
              sx={{
                background: "#F2F2F2",
                width: "150px",
                height: "34px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                textAlign: "right",
                letterSpacing: "-0.04em",
                color: "#828282",
              }}
            >
              Choose file
            </Button>
          </Box>
        </Grid>
      </Grid> */}

      {/* <BlockSpacer mt={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: "flex" }}>
            <FileuploadIcon />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "18px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                Upload ID (Back)
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "-0.04em",
                  textTransform: "capitalize",
                  color: "#828282",
                }}
              >
                jpg, png. PDF 2 MB
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ ml: { xs: "2%", lg: "57%" } }}>
            <Button
              sx={{
                background: "#F2F2F2",
                width: "150px",
                height: "34px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                textAlign: "right",
                letterSpacing: "-0.04em",
                color: "#828282",
              }}
            >
              Choose file
            </Button>
          </Box>
        </Grid>
      </Grid> */}

      <BlockSpacer mt={100} />
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Buttons
          color="primary"
          width="214.77px"
          onClick={() => handleClickOpen()}
        >
          Verifiy ID
        </Buttons>
      </Box> */}
      {open && (
        <OtpVerificationModal open={open} handleClose={handleClickClose} />
      )}
    </>
  );
}

export default CompanyPersonalForm;

{
  /* <BlockSpacer mt={50} />
<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box sx={{ display: 'flex' }}>
            <FileuploadIcon />
            <Box>
                <Typography>Upload ID (front)</Typography>
                <Typography>jpg, png. PDF  2 MB</Typography>
            </Box>

        </Box>
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box sx={{ ml: { xs: '2%', lg: '57%' } }}>
            <Button
                sx={{
                    background: '#F2F2F2',
                    width: '150px',
                    height: '34px',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '16px',
                    textAlign: 'right',
                    letterSpacing: '-0.04em',
                    color: '#828282'
                }}

            >
                Choose file
            </Button>

        </Box>


    </Grid> */
}
