import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetLeaseStatusQuery = ({ productId }) => {
  const getLeaseStatus = async () => {
    const request = await axiosInstance.get(`/users${productId}/lease_status`);
    const response = request.data;
    return response;
  };
  const query = useQuery(["LEASE_STATUS", productId], getLeaseStatus, {
    enabled: !!productId,
  });
  return query;
};

export default useGetLeaseStatusQuery;
