import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockSpacer from "../../../components/BlockSpacer";
import Button from "../../../components/Buttons";
import ContactDetails from "../../../components/Kyc/ContactDetails";
import EmployerlDetails from "../../../components/Kyc/EmployerlDetails";
import KycSideScreen from "../../../components/Kyc/KycSideScreen";
import OccupationDetails from "../../../components/Kyc/OccupationDetails";
import PersonalDetails from "../../../components/Kyc/PersonalDetails";
import BvnSuccessVerifcation from "../../../components/Modals/BvnsucessVerifcation";
import { AVAILABLE_USER_TYPES } from "../../../constants";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserKyc,
  getUsers,
  getCountries,
  getGenders,
  getAllProfessionalOccupation,
  getErpSectors,
} from "../../../redux/slices/profileSlice";
import {
  getErpEmployers,
  getErpIndustries,
} from "../../../redux/slices/updateProfileSlice";
import moment from "moment";
import { userKycValidation } from "../../../validation/userKycValidation";
import BvnSuccess from "../../../components/Modals/BvnSuccess";

function UserKyc() {
  const [defaultUserMethod, setDefaultUserMethod] = useState();
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [verifedLoading, setVerifedLoading] = useState(false);
  const Phone = JSON.parse(localStorage.getItem("phone"));
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [userkycerror, setUserKycError] = useState("");
  const [openBvn, setOpenbvn] = useState(false);
  const [bvnSuccess, setBvnSuccess] = useState(false);
  const FirstName = userDetails?.fullName.split(" ")[0];
  const LastName = userDetails?.fullName.split(" ")[1];
  const [error, setError] = useState("");
  const [verifed, setVerified] = useState(false);
  const [bvnVerified, setBvnVerified] = useState(false);
  // const [individualUser, setIndividualUser] = useState({});
  const [loading2, setLoading2] = useState(false);
  const router = useNavigate();
  const handleBvnOpen = () => setOpenbvn(true);
  const handleBvnClose = () => {
    setOpenbvn(false);
    setBvnSuccess(true);
  };
  const closeBvnModal = () => setBvnSuccess(false);
  const dispatch = useDispatch();
  // const getIndividualUser = async () => {
  //   try {
  //     setLoading2(true);
  //     const res = await axios.get(
  //       `https://api-credit.optisoft.com.ng:8090/auth/users`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     console.log(res?.data, "names");
  //     setIndividualUser(res?.data);
  //     setLoading2(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading2(false);
  //   }
  // };
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCountries());
    dispatch(getGenders());
    dispatch(getErpEmployers());
    dispatch(getErpIndustries());
    dispatch(getAllProfessionalOccupation());
    dispatch(getErpSectors());
    // eslint-disable-next-line
  }, []);

  const { users: individualUser } = useSelector((state) => state.profile);

  const [bvn, setBvn] = useState({
    id: "",
    firstName: individualUser?.individualUser?.firstName,
    lastName: individualUser?.individualUser?.lastName,
    isSubjectConsent: true,
    phoneNumber: individualUser?.phone,
  });

  const [kycInfo, setKycInfo] = useState({
    isAssited: null,
    isKyc: true,
    isNewsLetters: null,
    phone: null,
    role: "INDIVIDUAL_USER",
    source: null,
    sourceOthers: null,
    status: "ACTIVE",
    usage: "CREDIT",
    userType: null,
    individualUser: {
      bvn: null,
      contactAddress: {
        city: null,
        country: "Nigeria",
        homeAddress: null,
        houseNoAddress: null,
        secondaryPhoneNumber: null,
        nationality: null,
        postCode: null,
        state: null,
        streetAddress: null,
      },
      employmentDetail: {
        employerAddress: null,
        employerName: "",
        industry: null,
        employmentId: null,
        industryDetails: "",
        ippisNumber: null,
        occupation: "",
        officeEmailAddress: null,
        payrollHandler: null,
        payrollHandlerDetails: "",
        sector: null,
        employmentConfirmed: "YES",
      },
      occupation: {
        businessStatus: null,
        businessType: null,
        city: null,
        occupation: "",
        occupationDetails: "",
        schoolAddress: null,
        schoolName: null,
        state: null,
      },
      dateOfBirth: null,
      countryId: 1,
      firstName: null,
      genderId: null,
      lastName: null,
      maritalStatus: null,
      middleName: null,
    },
  });

  React.useEffect(() => {
    setBvn({
      ...bvn,
      firstName: individualUser?.individualUser?.firstName,
      lastName: individualUser?.individualUser?.lastName,
      phoneNumber: kycInfo.phone,
      isSubjectConsent: true,
      dateOfBirth: kycInfo.individualUser.dateOfBirth
        ? moment(kycInfo.individualUser.dateOfBirth, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
        : moment(
            individualUser?.individualUser?.dateOfBirth,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD"),
    });
  }, [individualUser, kycInfo.phone]);

  React.useEffect(() => {
    setKycInfo({
      ...kycInfo,
      isAssited: individualUser?.isAssited,
      isNewsLetters: individualUser?.isNewsLetters,
      phone: individualUser?.phone,
      source: individualUser?.source,
      sourceOthers: individualUser?.sourceOthers,
      individualUser: {
        ...kycInfo.individualUser,
        dateOfBirth: individualUser?.individualUser?.dateOfBirth,
        firstName: individualUser?.individualUser?.firstName,
        lastName: individualUser?.individualUser?.lastName,
      },
      userType: individualUser?.userType,
    });
  }, [individualUser]);

  // React.useEffect(() => {
  //   if (userDetails?.kyc === true) {
  //     window.location.href = "/dashboard";
  //   }
  // }, [userDetails?.kyc]);

  // console.log(kycInfo, "kycInfo");
  React.useEffect(() => {
    if (userDetails?.userType === "Student (Post-secondary)") {
      setDefaultUserMethod(AVAILABLE_USER_TYPES[0]);
    } else if (
      userDetails?.userType === "Salary Earner (Owns a Side Business)"
    ) {
      setDefaultUserMethod(AVAILABLE_USER_TYPES[1]);
    } else if (
      userDetails?.userType === "Salary Earner (With No Side Business)"
    ) {
      setDefaultUserMethod(AVAILABLE_USER_TYPES[2]);
    } else if (userDetails?.userType === "Pensioner") {
      setDefaultUserMethod(AVAILABLE_USER_TYPES[3]);
    }
  }, [userDetails?.userType]);

  const authenticated = JSON.parse(localStorage.getItem("user"));
  const SubmutKyc = async () => {
    let data;
    data = {
      ...kycInfo,
      individualUser: {
        ...kycInfo.individualUser,
        employmentDetail:
          kycInfo.userType === "Student (Post-secondary)"
            ? null
            : kycInfo.individualUser.employmentDetail,
      },
    };
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.put(
        `https://api-credit.optisoft.com.ng:8090/auth/users`,
        data,
        config
      );
      console.log(res);
      setLoading(false);
      const updateUser = {
        ...authenticated,
        fullName: `${data.individualUser.firstName} ${data.individualUser.lastName}`,
        kyc: true,
      };
      localStorage.setItem("user", JSON.stringify(updateUser));
      router("/dashboard");
    } catch (err) {
      console.error(err);
      setLoading(false);
      setUserKycError(err?.response?.data?.message);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    SubmutKyc();
  };

  const verifyBvn = async () => {
    setVerifedLoading(true);
    try {
      const res = await axios.post(
        `https://api-credit.optisoft.com.ng:8090/auth/verify-bvn/credit`,
        bvn,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res?.data);
      localStorage.setItem("BVNDATA", JSON.stringify(res?.data));

      if (res?.data?.data?.status === "found") {
        if (res?.data?.isNameMatched) {
          setBvnSuccess(true);
        } else {
          setOpenbvn(true);
        }
        setBvnVerified(true);
      }

      setVerifedLoading(false);
      // setBvn("");
    } catch (error) {
      console.log(error);
      setVerifedLoading(false);
      setError(error?.response?.data?.message);
      setBvnVerified(false);
    }
  };

  useEffect(() => {
    userKycValidation(kycInfo);
  }, [kycInfo]);

  return (
    <>
      {loading2 ? (
        <div className="loader">
          <div className="loader__content">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <div className={style.container}>
            <div className={style.left}>
              <KycSideScreen />
            </div>
            <div className={style.right}>
              <div className={style.formWrap}>
                <br />
                <div className={style.head}>
                  Hello{" "}
                  {`${kycInfo.individualUser.firstName} ${kycInfo.individualUser.lastName}`}
                  ,
                </div>
                <BlockSpacer mt={20} />
                <div className={style.subHead}>
                  Kindly update your profile, it will only take a few minutes
                </div>
                <BlockSpacer mt={82} />

                <p
                  style={{
                    color: "red",
                  }}
                >
                  {userkycerror}
                </p>
                <PersonalDetails
                  defaultUserMethod={defaultUserMethod}
                  setDefaultUserMethod={setDefaultUserMethod}
                  setKycInfo={setKycInfo}
                  setBvn={setBvn}
                  verified={verifed}
                  setVerified={setVerified}
                  bvnVerified={bvnVerified}
                  verifyBvn={verifyBvn}
                  verifedLoading={verifedLoading}
                  error={error}
                  itemOptions={AVAILABLE_USER_TYPES}
                  usersDetails={individualUser}
                  info={kycInfo}
                />
                <BlockSpacer mt={82} />
                {(defaultUserMethod === AVAILABLE_USER_TYPES[1] ||
                  defaultUserMethod === AVAILABLE_USER_TYPES[2]) && (
                  <>
                    <ContactDetails
                      setKycInfo={setKycInfo}
                      info={kycInfo}
                      usersDetails={individualUser}
                    />
                    <BlockSpacer mt={82} />
                    <EmployerlDetails setKycInfo={setKycInfo} />
                    <BlockSpacer mt={82} />
                    <OccupationDetails
                      enableSideBusiness={
                        defaultUserMethod !== AVAILABLE_USER_TYPES[2]
                      }
                      info={kycInfo}
                      setKycInfo={setKycInfo}
                    />
                  </>
                )}
                <BlockSpacer mt={82} />
                {defaultUserMethod === AVAILABLE_USER_TYPES[3] && (
                  <>
                    <ContactDetails
                      setKycInfo={setKycInfo}
                      info={kycInfo}
                      usersDetails={individualUser}
                    />
                  </>
                )}
                <BlockSpacer mt={82} />
                {defaultUserMethod === AVAILABLE_USER_TYPES[0] && (
                  <>
                    <ContactDetails
                      setKycInfo={setKycInfo}
                      info={kycInfo}
                      usersDetails={individualUser}
                    />
                    <BlockSpacer mt={82} />
                    <OccupationDetails
                      isStudentOccupation
                      info={kycInfo}
                      setKycInfo={setKycInfo}
                    />
                  </>
                )}
              </div>

              <BlockSpacer mt={84} />
              <div className={style.bottomContainer}>
                {/* <Link to="/profile/personal-info"> */}
                <Button
                  color="primary"
                  width={"328px"}
                  onClick={submitHandler}
                  disabled={!verifed || !bvnVerified}
                  // disabled={true}
                >
                  {loading ? "Updating.... Please wait" : "Save & Continue"}
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
          {openBvn && (
            <BvnSuccessVerifcation
              open={openBvn}
              handleClose={handleBvnClose}
              handleChange={(firstName, lastName) =>
                setKycInfo({
                  ...kycInfo,
                  individualUser: {
                    ...kycInfo.individualUser,
                    firstName,
                    lastName,
                  },
                })
              }
            />
          )}
          {bvnSuccess && (
            <BvnSuccess open={bvnSuccess} handleClose={closeBvnModal} />
          )}
        </Suspense>
      )}
    </>
  );
}

export default UserKyc;
