import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import dateFormat, { masks } from "dateformat";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number-2";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  AVAILABLE_USER_TYPES,
  DEFAULT_COUNTRY_CODE,
  HOW_DID_YOU_HEAR_ABOUT_US,
} from "../../../constants";
import { useMediaQuery } from "../../../hooks/use-media-query";
// import { setSignUp } from "../../../redux/reducers/auth";
import { userSignup, getUserTypes } from "../../../redux/slices/authSlice";
import { handlePhoneOnChange } from "../../../utils/handlePhoneNumberChange";
import { signUpIndividualValidation } from "../../../validation/signUpIndividualValidation";
import BlockSpacer from "../../BlockSpacer";
import Button from "../../Buttons";
import DoubleRegisterButtons from "../../GridFlex/DoubleRegisterButtons";
import SignupConditionalCheckBox from "../../GridFlex/SignupConditionalCheckBox";
import FormAligner from "../../Hocs/FormAligner";
import Title from "../../Texts/Title";
import FormLabel from "../Label";
import PhoneInput from "react-phone-input-2";
import {
  Error,
  PhoneTextField,
  styledField,
  StyledTextFieldBox,
  Wrapper,
} from "./style";
import style from "./style.module.css";

function SignupIndividualForm({ individualSignup, setIndividualSignup }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [generatedPhoneNumber, setGeneratedPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { isSignedUp, loading, userTypes } = useSelector((state) => state.auth);
  const { sources } = useSelector((state) => state.profile);
  const APP_URL = process.env.REACT_APP_API_KEY;

  const [defaultUserMethod, setDefaultUserMethod] = useState("");
  const handleUserTypeChange = (event) => {
    setDefaultUserMethod(event.target.value);
  };

  const [defaultHearAboutUsMethod, setDefaultHearAboutUsMethod] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const handlHearAboutUsTypeChange = (event) => {
    setDefaultHearAboutUsMethod(event.target.value);
  };

  const handleSourceType = (event) => {
    setSelectedSource(event.target.value);
  };

  useEffect(() => {
    dispatch(getUserTypes());
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // const handleUserInputs = (e) => {
  //   dispatch(
  //     setSignUp({ ...signUpIndividual, [e.target.name]: e.target.value })
  //   );
  // };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const breakPoint = useMediaQuery("(min-width: 1215px)");
  const router = useNavigate();

  useEffect(() => {
    if (isSignedUp) {
      router("/auth/signup/success");
    }
  }, [isSignedUp]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    dirty,
    isValid,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      userType: "",
      dateOfBirth: "",
      refferedBy: "",
      terms: false,
      newsLetters: false,
      sourceNotInTheList: "",
    },
    validationSchema: signUpIndividualValidation,
    onSubmit: (values) => {
      SignUpIndividual();
    },
  });

  const checkedd = () => {
    setFieldValue("terms", !values.terms);
  };
  const toggleNewsLetter = () => {
    setFieldValue("newsLetters", !values.newsLetters);
  };

  const SignUpIndividual = async () => {
    const data = {
      assited: true,
      userType: defaultUserMethod,
      email: values.email.toLowerCase(),

      individualUser: {
        // coutryOfResidence: {
        //   name: "Nigeria",
        // },
        dateOfBirth: dateFormat(values.dateOfBirth, "dd-mm-yyyy"),
        firstName: values.firstName,
        // gender: "MALE",
        lastName: values.lastName,
      },
      newsLetters: values.newsLetters,
      password: values.password,
      phone: values.phone,
      refferedBy:
        defaultHearAboutUsMethod !== "OTHER" ? values.refferedBy : null,
      role: "INDIVIDUAL_USER",
      source: defaultHearAboutUsMethod,
      sourceOthersId:
        defaultHearAboutUsMethod === "OTHER"
          ? selectedSource === "Not in the list"
            ? null
            : sources?.find((item) => item.name === selectedSource)?.id
          : null,
      sourceNotInTheList:
        selectedSource === "Not in the list" ? values.sourceNotInTheList : null,
      usage: "CREDIT",
    };

    dispatch(userSignup(data));
  };

  const sourceNames = sources ? sources?.map((item) => item.name) : [];
  const sourceOption = [...sourceNames, "Not in the list"];
  console.log(values.phone);
  return (
    <>
      {!breakPoint && (
        <DoubleRegisterButtons
          individualSignup={individualSignup}
          setIndividualSignup={setIndividualSignup}
          wrap={!breakPoint}
        />
      )}
      <Wrapper className={style.form}>
        {breakPoint && <BlockSpacer mt={100} />}
        <div className={style.alignTitle}>
          <Title text={"Sign up"} />
        </div>

        <BlockSpacer mt={69} />

        <form onSubmit={handleSubmit}>
          <Error>{error}</Error>
          <FormAligner>
            <FormLabel text={"User Type"} />
            <Select
              value={defaultUserMethod}
              onChange={handleUserTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              displayEmpty
              renderValue={
                defaultUserMethod !== ""
                  ? undefined
                  : () => <span>Select one</span>
              }
              style={{ width: "100%", borderRadius: "6px", outline: "none" }}
            >
              {userTypes?.map((option) => (
                <MenuItem key={option?.name} value={option?.name}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </FormAligner>
          <BlockSpacer />
          <div className={style.merge}>
            <FormAligner>
              <FormLabel text={"First Name"} />
              <TextField
                placeholder="John"
                type="text"
                error={errors.firstName && touched.firstName}
                helperText={
                  errors.firstName && touched.firstName && errors.firstName
                }
                required
                name="firstName"
                id="firstName"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.firstName}
              />
            </FormAligner>
            <FormAligner>
              <FormLabel text={"Last Name"} />
              <TextField
                placeholder="Doe"
                type="text"
                required
                name="lastName"
                id="lastName"
                error={errors.lastName && touched.lastName}
                helperText={
                  errors.lastName && touched.lastName && errors.lastName
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.lastName}
              />
            </FormAligner>
          </div>

          <BlockSpacer />

          {/* //DOB - DO NOT DELETE */}
          <FormAligner>
            <FormLabel text={"Date of Birth"} />
            <TextField
              id="date"
              type="date"
              defaultValue="1990-01-01"
              sx={{ width: "100%" }}
              value={values.dateOfBirth}
              name="dateOfBirth"
              onChange={(e) => {
                handleChange(e);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.dateOfBirth && touched.dateOfBirth}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              onBlur={handleBlur}
            />
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Email Address"} />
            <TextField
              placeholder="ros@gmail.com"
              type="email"
              required
              id="email"
              inputProps={{ style: { textTransform: "lowercase" } }}
              error={errors.email && touched.email}
              helperText={errors.email && touched.email && errors.email}
              name="email"
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              value={values.email}
            />
          </FormAligner>
          <BlockSpacer />
          <StyledTextFieldBox>
            <FormAligner>
              <FormLabel text={"Phone Number"} />
              <Tooltip
                title="Enter Phone number registered with user BVN"
                placement="top"
              >
                <TextField
                  placeholder="e.g 080xxxxxxxx"
                  type="number"
                  required
                  id="phone"
                  error={errors.phone && touched.phone}
                  helperText={errors.phone && touched.phone && errors.phone}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("phone", e.target.value.toString());
                  }}
                  value={values.phone}
                />
              </Tooltip>
            </FormAligner>
          </StyledTextFieldBox>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Password"} />
            <TextField
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              id="password"
              error={errors.password && touched.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              name="password"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.password}
              className={`${
                values.password.length > 2 && !errors.password && "suc"
              }
            ${errors.password && "input-error"}}`}
            />
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"Confirm Password"} />
            <TextField
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              id="confirmPassword"
              error={errors.confirmPassword && touched.confirmPassword}
              helperText={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              name="confirmPassword"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.confirmPassword}
              className={`${
                values.confirmPassword.length > 2 &&
                !errors.confirmPassword &&
                "suc"
              }
            ${errors.confirmPassword && "input-error"}}`}
            />
          </FormAligner>
          <BlockSpacer />
          <FormAligner>
            <FormLabel text={"How did you hear about us?"} />
            <Select
              value={defaultHearAboutUsMethod}
              onChange={handlHearAboutUsTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              displayEmpty
              renderValue={
                defaultHearAboutUsMethod !== ""
                  ? undefined
                  : () => <span>Select one</span>
              }
              style={{ width: "100%", borderRadius: "6px", outline: "none" }}
            >
              {HOW_DID_YOU_HEAR_ABOUT_US?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormAligner>
          <BlockSpacer />
          {defaultHearAboutUsMethod === "OTHER" ? (
            <FormAligner>
              <FormLabel text={"Enter source name"} />
              <Select
                value={selectedSource}
                onChange={handleSourceType}
                inputProps={{ "aria-label": "Without label" }}
                fullWidth
                displayEmpty
                renderValue={
                  defaultHearAboutUsMethod !== ""
                    ? undefined
                    : () => <span>Select one</span>
                }
                style={{ width: "100%", borderRadius: "6px", outline: "none" }}
              >
                {sourceOption?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormAligner>
          ) : (
            <FormAligner>
              <TextField
                placeholder={"Input referral code"}
                type="text"
                required
                name="refferedBy"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.refferedBy}
                onBlur={handleBlur}
              />
            </FormAligner>
          )}
          {selectedSource === "Not in the list" &&
            defaultHearAboutUsMethod === "OTHER" && (
              <>
                <BlockSpacer />
                <FormAligner>
                  <FormLabel text={""} />
                  <TextField
                    placeholder="Enter source not listed"
                    type="text"
                    required
                    id="sourceNotInTheList"
                    error={
                      selectedSource === "Not in the list" &&
                      values.sourceNotInTheList === "" &&
                      touched.sourceNotInTheList
                    }
                    helperText={
                      errors.sourceNotInTheList &&
                      touched.sourceNotInTheList &&
                      errors.sourceNotInTheList
                    }
                    name="sourceNotInTheList"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.sourceNotInTheList}
                  />
                </FormAligner>
              </>
            )}

          <BlockSpacer mt={25} />
          <SignupConditionalCheckBox
            defaultChecked={true}
            name="newsLetters"
            check={values.newsLetters}
            title={"Yes, I want to receive newsletters of Promos and Offers"}
            handleCheck={toggleNewsLetter}
          />
          <SignupConditionalCheckBox
            name="terms"
            check={values.terms}
            defaultChecked={true}
            handleCheck={checkedd}
            modified
          />
          <div className={style.alignMiddle}>
            <Button
              color={"primary"}
              width={"328px"}
              // onClick={() => handleSubmit()}
              type={"submit"}
              disabled={
                !(
                  isValid &&
                  dirty &&
                  defaultUserMethod !== "" &&
                  (defaultHearAboutUsMethod === "OTHER"
                    ? selectedSource !== ""
                      ? true
                      : false
                    : values.refferedBy !== ""
                    ? true
                    : false) &&
                  (selectedSource === "Not in the list"
                    ? values.sourceNotInTheList !== ""
                      ? true
                      : false
                    : true)
                )
              }
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Sign up"
              )}
            </Button>
          </div>
          <div className={style.haveAccount}>
            Already have an account?
            <Link to="/auth/login">
              <span className={style.signColor}>Sign in</span>
            </Link>
          </div>
        </form>
        <BlockSpacer mt={100} />
      </Wrapper>
    </>
  );
}

export default SignupIndividualForm;
