import React from "react";
import BlockSpacer from "../../BlockSpacer";
import ContactDetailForm from "../../Forms/ContactDetailForm";
import style from "./style.module.css";

function ContactDetails({ setKycInfo, info, usersDetails }) {
  return (
    <div className={style.container}>
      <div className={style.title}>Contact Details</div>
      <BlockSpacer mt={26.91} />
      <div>
        <ContactDetailForm
          setKycInfo={setKycInfo}
          info={info}
          usersDetails={usersDetails}
        />
      </div>
    </div>
  );
}

export default ContactDetails;
