import styled from "@emotion/styled";

export const Section = styled.section`

   .file-input {
        position: absolute;
        z-index: -1;
   }
   .camera {
    position: absolute;
    bottom: 5px;
    left: 26px;
    color: #828282;
    cursor: pointer;
   }
   .banner {
        position: absolute;
        bottom: 0;
        left: 5px;
        height: 105px;
        width: 105px;
        border-radius: 100%;
        background: linear-gradient(to bottom, transparent 70%, #c6c6c6 30%);
    }

    .file {
        display: none;
    }
    @media (max-width:700px) {
        .banner {
            background: transparent;
            height: 72px;
            width: 68px;
        }
        .camera {
            font-size: 16px;
            left: 20px;
            bottom: 8px;
        }
    }
`

export const smallText = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '-0.04em',
    color: '#4F4F4F',

}