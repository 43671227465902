import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
} from "@mui/material";

import { Box } from "@mui/system";
import BlockSpacer from "../../../components/BlockSpacer";
import NanoImage from "../../../components/imageSvg/NanoImage.jsx";
import { Link, useNavigate } from "react-router-dom";
import {
  QuickNanoLoan,
  callToActionCard,
  paragraphTitle,
  // processingStage,
  // documentsUpload,
  // signOffer,
  Section,
  // fixed,
} from "./style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopBar from "../../../components/MyCreditTopBar";
import { useDispatch, useSelector } from "react-redux";
import { activeTransactions } from "../../../redux/slices/myCreditSlice";
import { MoreHoriz } from "@mui/icons-material";

function ActiveTransaction() {
  const { activeTransactions: allActiveTransactions } = useSelector(
    (state) => state.myCredit
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedLoan, setSelectedLoan] = useState(null);

  useEffect(() => {
    dispatch(activeTransactions());
    // eslint-disable-next-line
  }, []);

  //Landed Property Asset Cash Loan

  // Cash Back Loan

  //Quick Loan

  //Quick Payday Loan

  //Personal Loan

  // Business Support Loan

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoan(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedLoan(null);
  };

  const leaseList = allActiveTransactions?.data?.content?.filter(
    (loan) => loan?.product?.pricingLeaseList.length
  );
  return (
    <>
      <TopBar />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/repayment/${selectedLoan}`);
          }}
        >
          Repay
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/top-up/${selectedLoan}`);
          }}
          disabled
        >
          Top-up
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/extension/${selectedLoan}`);
          }}
          disabled
        >
          Extend
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/pay-off/${selectedLoan}`);
          }}
        >
          Pay-off
        </MenuItem>
      </Menu>
      <main>
        <Section>
          <Box
            sx={{
              fontWeight: 700,
              fontSize: 21,
              fontFamily: "Montserrat",
              mt: { xs: "-2", lg: "-2" },
            }}
          >
            Active Transactions
          </Box>
          <Box sx={{ marginTop: "3.5rem" }}>
            <Grid container sx={{ overflow: "hidden" }}>
              <Grid item lg={12} xs={12} sm={12}>
                <Accordion
                  sx={{
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: { xs: "100%", lg: "50%" } }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Collateral Loan
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container sx={{ overflow: "hidden" }}>
                      {allActiveTransactions?.data?.content?.filter(
                        (loan) =>
                          loan?.product?.productSetupType === "COLLATERAL_SETUP"
                      )?.length > 0 ? (
                        allActiveTransactions?.data?.content
                          ?.filter(
                            (loan) =>
                              loan?.product?.productSetupType ===
                              "COLLATERAL_SETUP"
                          )
                          ?.map((loan) => (
                            <Grid item lg={6} xs={12} sm={12} key={loan?.id}>
                              <Paper
                                elevation={0}
                                sx={{
                                  mt: 4,
                                  width: { xs: "100%", lg: "90%" },
                                  maxWidth: "100%",
                                  height: { xs: "fit-content", lg: "213px" },
                                  position: "relative",
                                  boxShadow:
                                    "0px 4px 30px rgba(196, 204, 221, 0.28)",
                                }}
                                //
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography sx={QuickNanoLoan}>
                                    {loan?.product?.productName}
                                  </Typography>
                                  <Button
                                    onClick={(e) => handleClick(e, loan?.id)}
                                    sx={{
                                      color: "#333333",
                                      fontWeight: "800",
                                      letterSpacing: "5px",
                                      fontSize: "12px",
                                      display: "inline",
                                    }}
                                  >
                                    <MoreHoriz />
                                  </Button>
                                </Box>
                                <Box sx={callToActionCard}>
                                  <Box>
                                    <NanoImage />
                                  </Box>
                                  <div>
                                    {loan?.product?.productSummary
                                      ?.split(".")
                                      ?.map((detail, id) => (
                                        <Typography
                                          key={id}
                                          sx={paragraphTitle}
                                        >
                                          {detail}
                                        </Typography>
                                      ))}
                                    <Link to={`/view-history/${loan?.id}`}>
                                      <Button
                                        sx={{
                                          background: "#F2F2F2",
                                          marginTop: "1rem",
                                          width: { xs: "150px", lg: "186px" },
                                          borderRadius: "10px",
                                          height: "42px",
                                          color: "#111E6C",
                                          textTransform: "capitalize",
                                          fontFamily: "Montserrat",
                                          fontWeight: "500",
                                          fontSize: { xs: "10px", lg: "15px" },
                                        }}
                                      >
                                        View History
                                      </Button>
                                    </Link>
                                  </div>
                                </Box>
                              </Paper>
                            </Grid>
                          ))
                      ) : (
                        <Typography>No Loan Available</Typography>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ width: { xs: "100%", lg: "50%" } }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Non-Collateral Loan
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container sx={{ overflow: "hidden" }}>
                      {allActiveTransactions?.data?.content?.filter(
                        (loan) =>
                          loan?.product?.productSetupType ===
                          "NON_COLLATERAL_SETUP"
                      )?.length > 0 ? (
                        allActiveTransactions?.data?.content
                          ?.filter(
                            (loan) =>
                              loan?.product?.productSetupType ===
                              "NON_COLLATERAL_SETUP"
                          )
                          ?.map((loan) => (
                            <Grid item lg={6} xs={12} sm={12} key={loan?.id}>
                              <Paper
                                elevation={0}
                                sx={{
                                  mt: 4,
                                  width: { xs: "100%", lg: "90%" },
                                  maxWidth: "100%",
                                  height: { xs: "fit-content", lg: "213px" },
                                  position: "relative",
                                  boxShadow:
                                    "0px 4px 30px rgba(196, 204, 221, 0.28)",
                                }}
                                //
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Typography sx={QuickNanoLoan}>
                                    {loan?.product?.productName}
                                  </Typography>
                                  <Button
                                    onClick={(e) => handleClick(e, loan?.id)}
                                    sx={{
                                      color: "#333333",
                                      fontWeight: "800",
                                      letterSpacing: "5px",
                                      fontSize: "12px",
                                      display: "inline",
                                    }}
                                  >
                                    <MoreHoriz />
                                  </Button>
                                </Box>
                                <Box sx={callToActionCard}>
                                  <Box>
                                    <NanoImage />
                                  </Box>
                                  <Box sx={{}}>
                                    {loan?.product?.productSummary
                                      ?.split(".")
                                      ?.map((detail, id) => (
                                        <Typography
                                          key={id}
                                          sx={paragraphTitle}
                                        >
                                          {detail}
                                        </Typography>
                                      ))}
                                    <Link to={`/view-history/${loan?.id}`}>
                                      <Button
                                        sx={{
                                          background: "#F2F2F2",
                                          marginTop: "1rem",
                                          width: { xs: "150px", lg: "186px" },
                                          borderRadius: "10px",
                                          height: "42px",
                                          color: "#111E6C",
                                          textTransform: "capitalize",
                                          fontFamily: "Montserrat",
                                          fontWeight: "500",
                                          fontSize: { xs: "10px", lg: "15px" },
                                        }}
                                      >
                                        View History
                                      </Button>
                                    </Link>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                          ))
                      ) : (
                        <Typography>No Loan Available</Typography>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid container sx={{ overflow: "hidden" }} space={3}>
              {/* Second grid */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
              {/* Third grid */}
            </Grid>
          </Box>
          <Box sx={{ marginTop: "7rem" }}>
            <Grid container>
              <Grid item xs={12} sm={12} lg={6}>
                <Accordion
                  sx={{
                    boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                    border: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Lease
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {leaseList?.map((loan) => (
                      <Grid item lg={6} xs={12} sm={12} key={loan?.id}>
                        <Paper
                          elevation={0}
                          sx={{
                            mt: 4,
                            width: { xs: "100%", lg: "90%" },
                            maxWidth: "100%",
                            height: { xs: "fit-content", lg: "213px" },
                            position: "relative",
                            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
                          }}
                          //
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography sx={QuickNanoLoan}>
                              {loan?.product?.productName}
                            </Typography>
                            <Button
                              onClick={(e) => handleClick(e, loan?.id)}
                              sx={{
                                color: "#333333",
                                fontWeight: "800",
                                letterSpacing: "5px",
                                fontSize: "12px",
                                display: "inline",
                              }}
                            >
                              <MoreHoriz />
                            </Button>
                          </Box>
                          <Box sx={callToActionCard}>
                            <Box>
                              <NanoImage />
                            </Box>
                            <Box sx={{}}>
                              {loan?.product?.productSummary
                                ?.split(".")
                                ?.map((detail, id) => (
                                  <Typography key={id} sx={paragraphTitle}>
                                    {detail}
                                  </Typography>
                                ))}
                              <Link to={`/view-history/${loan?.id}`}>
                                <Button
                                  sx={{
                                    background: "#F2F2F2",
                                    marginTop: "1rem",
                                    width: { xs: "150px", lg: "186px" },
                                    borderRadius: "10px",
                                    height: "42px",
                                    color: "#111E6C",
                                    textTransform: "capitalize",
                                    fontFamily: "Montserrat",
                                    fontWeight: "500",
                                    fontSize: { xs: "10px", lg: "15px" },
                                  }}
                                >
                                  View History
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                    {!leaseList?.length && "No Lease"}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <BlockSpacer mt={120} />
        <Paper
          elevation={0}
          sx={{
            height: { xs: "15%", lg: "10%" },
            width: { xs: "92%", sm: "100%", lg: "98%" },
            margin: "1rem",
            maxwidth: "100%",
            boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.28)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginLeft: { xs: "auto", sm: "2rem", md: "auto", lg: "auto" },
              padding: "1rem",
              maxWidth: "100%",
            }}
          >
            <Link to="/my-credit">
              <Button
                sx={{
                  background: "#111E6C",
                  width: "328px",
                  height: "54px",
                  marginBottom: "1rem",
                  fontFamily: "Montserrat",
                  color: "white",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontSize: "18px",
                }}
              >
                Back
              </Button>
            </Link>
          </Box>
        </Paper>
      </main>
    </>
  );
}
// {showTopUp && (
//   <List
//     sx={{
//       width: { xs: "", lg: "80%" },
//       height: "60%",
//       maxWidth: 106,
//       bgcolor: "white",
//       borderRadius: "5px",
//       zIndex: { xs: 5, lg: 1 },
//       display: { xs: "inline-block", lg: "block" },
//       boxShadow:
//         " 0px 4px 10px rgba(199, 199, 199, 0.25)",
//       position: "absolute",
//       left: { xs: "280px", lg: "350px" },
//       // top: "-50px",
//       marginTop: "1.5rem",
//       opacity: 40,
//     }}
//   >
//     <ListItem alignItems="flex-start">
//       <ListItemText>
//         <Link to="/repayment">
//           <Button
//             sx={{
//               marginTop: "-2.5rem",
//               fontWeight: "500",
//               fontSize: "12px",
//               fontFamily: "Montserrat",
//               textTransform: "capitalize",
//               color: "#333333",
//             }}
//           >
//             Repay
//           </Button>
//         </Link>
//       </ListItemText>
//     </ListItem>
//     <Divider
//       variant="inset"
//       component="li"
//       style={{
//         marginTop: "-1.5rem",
//         width: "90%",
//         marginLeft: ".5rem",
//         position: "absolute",
//       }}
//     />
//     <ListItem alignItems="flex-start">
//       <ListItemText>
//         <Link to="/top-up">
//           <Button
//             sx={{
//               marginTop: "-4rem",
//               fontWeight: "500",
//               fontSize: "12px",
//               fontFamily: "Montserrat",
//               textTransform: "capitalize",
//               color: "#333333",
//             }}
//           >
//             Top-up
//           </Button>
//         </Link>
//       </ListItemText>
//     </ListItem>
//     <Divider
//       variant="inset"
//       component="li"
//       style={{
//         width: "90%",
//         marginLeft: ".5rem",
//         position: "absolute",
//         marginTop: "-2.5rem",
//       }}
//     />
//     <ListItem alignItems="flex-start">
//       <ListItemText>
//         <Link to="/extension">
//           <Button
//             sx={{
//               fontWeight: "500",
//               fontSize: "12px",
//               fontFamily: "Montserrat",
//               textTransform: "capitalize",
//               color: "#333333",
//               marginTop: "-6rem",
//             }}
//           >
//             Extend
//           </Button>
//         </Link>
//       </ListItemText>
//     </ListItem>

//     <Divider
//       variant="inset"
//       component="li"
//       style={{
//         marginTop: "-3.5rem",
//         width: "90%",
//         marginLeft: ".5rem",
//         position: "absolute",
//       }}
//     />
//     <ListItem alignItems="flex-start">
//       <ListItemText>
//         <Link to="/pay-off">
//           <Button
//             sx={{
//               fontWeight: "500",
//               fontSize: "12px",
//               fontFamily: "Montserrat",
//               textTransform: "capitalize",
//               color: "#333333",
//               marginTop: "-8rem",
//             }}
//           >
//             Pay-off
//           </Button>
//         </Link>
//       </ListItemText>
//     </ListItem>
//   </List>
// )}

export default ActiveTransaction;
