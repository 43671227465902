import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./style.module.css";
import BlockSpacer from "../../BlockSpacer";
import FormAligner from "../../Hocs/FormAligner";
import FormLabel from "../../Forms/Label";
import { TextField, Backdrop } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Buttons";
import { useDispatch, useSelector } from "react-redux";
import { generateRepayment } from "../../../redux/slices/getCreditSlice.js";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import {
  formatInputWithCommas,
  numberValidity,
} from "../../../functions/numberValidity";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "430px",
  maxWidth: "100%",
  height: "469px",
  bgcolor: "#fff",
  boxShadow: "0px 4px 30px rgba(196, 204, 221, 0.62)",
  borderRadius: "8px",
  p: 4,
};

export default function Leasevalue({ open, handleClose, handleEligableOpen }) {
  const [myLease, setMyLease] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loanValue, initializedLoan, repayment, loading } = useSelector(
    (state) => state.getCredit
  );

  useEffect(() => {
    if (repayment) {
      navigate("/finance-lease-summary");
    }
    // eslint-disable-next-line
  }, [repayment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      generateRepayment({
        accessibleLeaseValue:
          initializedLoan?.object?.body?.body?.loanValueTotalDebtWeight
            ?.accessibleLoanValue,
        accessibleLoanValue:
          initializedLoan?.object?.body?.body?.loanValueTotalDebtWeight
            ?.accessibleLoanValue,
        customerLeaseValue: parseFloat(myLease?.replace(/,/g, "")),
        customerLoanValue: 0,
        loanId: initializedLoan?.object?.body?.body?.loanId,
        totalDebtWeight:
          initializedLoan?.object?.body?.body?.loanValueTotalDebtWeight
            ?.totalDebtWeight,
      })
    );
  };
  return (
    <div>
      <Box
        sx={{
          maxWidth: "90%",
          padding: "1rem",
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <div className={styles.header}>Lease Value</div>
            <BlockSpacer mt={40} />
            <FormAligner>
              <FormLabel text={"Accessible Lease Value"} />
              <TextField
                placeholder=" "
                type="number"
                disabled
                value={
                  initializedLoan?.object?.body?.body?.loanValueTotalDebtWeight
                    ?.accessibleLoanValue
                }
                required
                name="accessible loan"
              />
            </FormAligner>
            <BlockSpacer mt={10} />
            {/* <FormAligner>
              <FormLabel text={"Total Debt Weight"} />
              <TextField
                placeholder=" "
                type="number"
                disabled
                value={
                  (initializedLoan?.object?.body?.body?.loanValueTotalDebtWeight
                    ?.totalDebtWeight)*100
                }
                required
                name="total debt weight"
              />
            </FormAligner> */}
            {/* <div className={styles.text}>Check bank number for ticket ID and password sent by your bank</div> */}

            <BlockSpacer mt={10} />
            <FormAligner>
              <FormLabel text={"My Lease Value"} />
              <TextField
                placeholder="Input lease value Needed"
                type="tel"
                required
                name="myLease"
                value={formatInputWithCommas(myLease)}
                onChange={(e) => setMyLease(e.target.value)}
              />
            </FormAligner>
            <BlockSpacer mt={20} />

            <div className={styles.button}>
              <Link to={"/finance-lease-summary"}>
                <Button
                  color={"primary"}
                  width={240}
                  height={41}
                  onClick={(e) => {
                    parseFloat(myLease?.replace(/,/g, "")) >
                    initializedLoan?.object?.body?.body
                      ?.loanValueTotalDebtWeight?.accessibleLoanValue
                      ? toast.error(
                          "Your loan Value should not be greater than accessible loan"
                        )
                      : handleSubmit(e);
                  }}
                  //   onClick={() => handleEligableOpen()}
                >
                  {loading ? (
                    <ClipLoader color={"#fff"} loading={loading} size={20} />
                  ) : (
                    "Generate Repayment"
                  )}
                </Button>
              </Link>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
