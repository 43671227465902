import axiosInstance from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
const useGetPendingTransactionQuery = (id) => {
  const getPendingTransaction = async () => {
    const request = await axiosInstance.get(
      `/auth/my-credit/transactions/get-pending-transaction-details/${id}`
    );

    const response = request.data;
    return response;
  };
  const query = useQuery(["PENDING_TRANSACTION"], getPendingTransaction, {
    enabled: !!id,
  });
  return query;
};

export default useGetPendingTransactionQuery;
