import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    signUpIndividual: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      refferedBy: "",
      dateOfBirth: "",
      sourceOthers: "",
    },
    signUpBusiness: {
      companyName: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonEmail: "",
      contactPersonphoneNumber: "",
      contactPersonDOB: "",
      password: "",
      confirmPassword: "",
      // otherInfo: "",
      // referalCode: "",
    },
    Authorization: {
      authorize: "",
    },
  },
  reducers: {
    setSignUp: (state, action) => {
      state.signUpIndividual = action.payload;
    },
    setSignUpBusiness: (state, action) => {
      state.signUpBusiness = action.payload;
    },
  },
});

export const { setSignUp, setSignUpBusiness } = authSlice.actions;

export default authSlice.reducer;
