import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  cardBox,
  summaryTitle,
  currencyStyle,
  secondCurrencyStyle,
  firstCurrencyStyle,
  currencyBlue,
  currencyBlueRight,
} from "./style";
import DotLine from "../../../components/Hocs/DotLine";

export default function SummaryCard({
  title,
  loanType,
  currencyRequested,
  tenor,
  infoPayable,
  feePayable,
  currency,
  days,
  interestpayableCurrency,
  totalPaymentDate,
  totalPaymentAmount,
  interestPayableCurrency,
  feePayableCurrency,
  hundredThousand,
  TotalRepayment,
  TotalDebtWeight,
  FeeRate,
  ProcessingFeeRatePercentage,
  ProcessingFeeRate,
  ProcessingFeePayableCurrency,
  ProcessingFeePayable,
  LegalFeeRatePercentage,
  LegalFeeRate,
  LegalFeePayableCurrency,
  LegalFeePayable,
  ManagementFeeRatePercentage,
  ManagementFeeRate,
  ManagementFeePayableCurrency,
  ManagementFeePayable,
  InsuranceFeeRatePercentage,
  InsuranceFeeRate,
  InsuranceFeePayableCurrency,
  InsuranceFeePayable,
  StampFeeRatePercentage,
  StampFeeRate,
  StampFeePayableCurrency,
  StampFeePayable,
  interestRate,
  FeeRatePercentage,
  interestRatePercentage,
  interestPayable,
  TotalDebtWeightPercentage,
  firstMonthRepayment,
  secondMonthRepayment,
  dueDate,
  DueDate,
  LoanDueDate,
  monthlyRepayments,
}) {
  return (
    <>
      <Paper elevation={0.5} sx={cardBox}>
        <Typography variant="h6" style={summaryTitle}>
          {title}
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{currencyRequested}</Typography>
              <Typography sx={firstCurrencyStyle}>
                ₦
                {parseFloat(currency)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={currencyStyle}>{tenor}</Typography>
              <Typography sx={secondCurrencyStyle}>{days}</Typography>
            </Box>
          </Box>
          <DotLine />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.2rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{interestRate}</Typography>
              <Typography sx={firstCurrencyStyle}>
                {parseFloat(interestRatePercentage)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{interestPayable}</Typography>
              <Typography sx={secondCurrencyStyle}>
                ₦
                {parseFloat(interestPayableCurrency)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>
          </Box>

          {loanType && (loanType?.split("_")?.includes("VEHICLE") ||
              loanType?.split("_")?.includes("LANDED")) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1.2rem",
              }}
            >
              <Box>
                <Typography sx={currencyStyle}>{ProcessingFeeRate}</Typography>
                <Typography sx={firstCurrencyStyle}>
                  {parseFloat(ProcessingFeeRatePercentage)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={currencyStyle}>
                  {ProcessingFeePayable}
                </Typography>
                <Typography sx={secondCurrencyStyle}>
                  ₦
                  {parseFloat(ProcessingFeePayableCurrency)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {loanType &&
            (loanType?.split("_")?.includes("VEHICLE") ||
              loanType?.split("_")?.includes("LANDED")) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1.2rem",
                }}
              >
                <Box>
                  <Typography sx={currencyStyle}>{LegalFeeRate}</Typography>
                  <Typography sx={firstCurrencyStyle}>
                    {parseFloat(LegalFeeRatePercentage)?.toLocaleString(
                      "en-Us",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                    %
                  </Typography>
                </Box>

                <Box>
                  <Typography sx={currencyStyle}>{LegalFeePayable}</Typography>
                  <Typography sx={secondCurrencyStyle}>
                    ₦
                    {parseFloat(LegalFeePayableCurrency)?.toLocaleString(
                      "en-Us",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Box>
              </Box>
            )}

          {loanType && (loanType?.split("_")?.includes("VEHICLE") ||
              loanType?.split("_")?.includes("LANDED")) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1.2rem",
              }}
            >
              <Box>
                <Typography sx={currencyStyle}>{StampFeeRate}</Typography>
                <Typography sx={firstCurrencyStyle}>
                  {parseFloat(StampFeeRatePercentage)?.toLocaleString("en-Us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={currencyStyle}>{StampFeePayable}</Typography>
                <Typography sx={secondCurrencyStyle}>
                  ₦
                  {parseFloat(StampFeePayableCurrency)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {loanType && (loanType?.split("_")?.includes("VEHICLE") ||
              loanType?.split("_")?.includes("LANDED")) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1.2rem",
              }}
            >
              <Box>
                <Typography sx={currencyStyle}>{ManagementFeeRate}</Typography>
                <Typography sx={firstCurrencyStyle}>
                  {parseFloat(ManagementFeeRatePercentage)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={currencyStyle}>
                  {ManagementFeePayable}
                </Typography>
                <Typography sx={secondCurrencyStyle}>
                  ₦
                  {parseFloat(ManagementFeePayableCurrency)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {loanType && loanType?.split("_")?.includes("VEHICLE") && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1.2rem",
              }}
            >
              <Box>
                <Typography sx={currencyStyle}>{InsuranceFeeRate}</Typography>
                <Typography sx={firstCurrencyStyle}>
                  {parseFloat(InsuranceFeeRatePercentage)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={currencyStyle}>
                  {InsuranceFeePayable}
                </Typography>
                <Typography sx={secondCurrencyStyle}>
                  ₦
                  {parseFloat(InsuranceFeePayableCurrency)?.toLocaleString(
                    "en-Us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {(!loanType || !(loanType?.split("_")?.includes("VEHICLE") ||
              loanType?.split("_")?.includes("LANDED"))) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1.2rem",
              }}
            >
              <Box>
                <Typography sx={currencyStyle}>{FeeRate}</Typography>
                <Typography sx={firstCurrencyStyle}>
                  {parseFloat(FeeRatePercentage)?.toLocaleString("en-Us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={currencyStyle}>{feePayable}</Typography>
                <Typography sx={secondCurrencyStyle}>
                  ₦
                  {parseFloat(feePayableCurrency)?.toLocaleString("en-Us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            </Box>
          )}
          <DotLine />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.2rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{TotalRepayment}</Typography>
              <Typography sx={currencyBlue}>
                ₦
                {parseFloat(totalPaymentAmount)?.toLocaleString("en-Us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{TotalDebtWeight}</Typography>
              <Typography sx={currencyBlueRight}>
                {parseFloat(TotalDebtWeightPercentage)?.toLocaleString(
                  "en-Us",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
                %
              </Typography>
            </Box>
          </Box>
          {/* <Box sx={{ padding: "1.2rem" }}>
            <Typography sx={currencyStyle}>{LoanDueDate}</Typography>
            <Typography sx={currencyBlue}>{dueDate}</Typography>
          </Box> */}
          <DotLine />
          {monthlyRepayments &&
            monthlyRepayments?.map((repayment, id) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
                key={id}
              >
                <Box>
                  <Typography sx={currencyStyle}>
                    {id === 0
                      ? `1st Monthly Payment`
                      : id === 1
                      ? `2nd Monthly Payment`
                      : id === 2
                      ? `3rd Monthly Payment`
                      : `${id + 1}th Monthly Payment`}
                  </Typography>
                  <Typography sx={currencyBlue}>
                    ₦
                    {parseFloat(repayment?.amount)?.toLocaleString("en-Us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Box>

                <Box>
                  <Typography sx={currencyStyle}>
                    {id === 0
                      ? `1st Due Date`
                      : id === 1
                      ? `2nd Due Date`
                      : id === 2
                      ? `3rd Due Date`
                      : `${id + 1}th Due Date`}
                  </Typography>

                  <Typography sx={currencyBlue}>
                    {repayment?.dueDate}
                  </Typography>
                </Box>
              </Box>
            ))}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{firstMonthRepayment}</Typography>
              <Typography sx={currencyBlue}>{hundredThousand}</Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{DueDate}</Typography>

              <Typography sx={currencyBlue}>{dueDate}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography sx={currencyStyle}>{secondMonthRepayment}</Typography>
              <Typography sx={currencyBlue}>{hundredThousand}</Typography>
            </Box>

            <Box>
              <Typography sx={currencyStyle}>{DueDate}</Typography>

              <Typography sx={currencyBlue}>{dueDate}</Typography>
            </Box>
          </Box> */}
        </Box>
      </Paper>
    </>
  );
}
